<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title >
	<ng-container *ngIf="this._empr != null">{{this._empr.nmEmpr}} | </ng-container>Detalhamento da Empresa
	<div *ngIf="this._empr != null && this._empr.nmAbrvEmpr != null" class="subTitle">
		({{this._empr.nmAbrvEmpr}})
	</div>
	<mat-progress-bar *ngIf="this._controlMain.isShowProgressBar" [mode]="this._controlMain.modeProgressBar" value="this._controlMain.valueProgressBar"></mat-progress-bar>
</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Dados Básicos:
			</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlDadosBasicos.isShowProgressBar" [mode]="this._controlDadosBasicos.modeProgressBar" value="this._controlDadosBasicos.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_empr_detail_dadosBasicos">
			<div class="grid_item_empr_detail_dadosBasicos_empr_label">
				<div class="label_key">
					Empresa:
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_empr_value">
				<label *ngIf="this._empr != null" class="label_value">{{this._empr.nmEmpr}}</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_nmAbrvEmpr_label">
				<div class="label_key">
					Nome Fantasia:
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_nmAbrvEmpr_value">
				<label *ngIf="this._empr != null" class="label_value">{{this._empr.nmAbrvEmpr}}</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_incFnc_label">
				<div class="label_key">
					Início do Funcionamento:
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_incFnc_value">
				<label *ngIf="this._empr != null" class="label_value">{{this._dtIncFncEmpr | date:'dd/MM/yyyy':'+0000'}}</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_sitEmpr_label">
				<div class="label_key">
					Situação da Empresa:
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_sitEmpr_value">
				<label *ngIf="this._empr != null" class="label_value">{{this._empr.tipSitEmpr.nmTipSitEmpr}}</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_cnpj_label">
				<div class="label_key">
					CNPJ:
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_cnpj_value">
				<label *ngIf="this._empr != null" class="label_value">{{ this._empr.cnpj | cnpjNumber:'' }}</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_mc_label">
				<div class="label_key">
					Market Cap (MC):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_mc_value">
				<label *ngIf="this._empr != null && this._empr.infComplEmpr != null && this._empr.infComplEmpr.mc != null" class="label_value">
					R$ {{ ( this._empr.infComplEmpr.mc.vlInfComplEmpr ) | shortNumber:'1.2-2' }}
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_ev_label">
				<div class="label_key">
					Enterprise Value (EV):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_ev_value">
				<label *ngIf="this._empr != null && this._empr.infComplEmpr != null && this._empr.infComplEmpr.ev != null" class="label_value">
					R$ {{ ( this._empr.infComplEmpr.ev.vlInfComplEmpr ) | shortNumber:'1.2-2' }}
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_pl_label">
				<div class="label_key">
					Patrimônio Líquido (PL):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_pl_value">
				<label *ngIf="this._empr != null && this._empr.rstdEmpr.pl != null && this._empr.rstdEmpr.pl.vlRstdEmpr != null" class="label_value">
					<div *ngIf="this._empr.rstdEmpr.pl.vlRstdEmpr >= 0; then plThenBlock else plElseBlock" ></div>
					<ng-template #plThenBlock>
						<span class="label-number-positive">R$ {{ this._empr.rstdEmpr.pl.vlRstdEmpr | shortNumber:'1.2-2' }}</span>
					</ng-template>
					<ng-template #plElseBlock>
						<span class="label-number-negative">R$ {{ this._empr.rstdEmpr.pl.vlRstdEmpr | shortNumber:'1.2-2' }}</span>
					</ng-template>
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_vpa_label">
				<div class="label_key">
					Valor Patrimonial da Ação (VPA):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_vpa_value">
				<label *ngIf="this._empr != null && this._empr.infComplEmpr != null && this._empr.infComplEmpr.vpa != null" class="label_value">
					<div *ngIf="this._empr.infComplEmpr.vpa.vlInfComplEmpr >= 0; then vpaThenBlock else vpaElseBlock" ></div>
					<ng-template #vpaThenBlock>
						<span class="label-number-positive">R$ {{ this._empr.infComplEmpr.vpa.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
					</ng-template>
					<ng-template #vpaElseBlock>
						<span class="label-number-negative">R$ {{ this._empr.infComplEmpr.vpa.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
					</ng-template>
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_lp_label">
				<div class="label_key">
					Lucro/Prej. (L/P):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_lp_value">
				<label *ngIf="this._empr != null && this._empr && this._empr.rstdEmpr != null && this._empr.rstdEmpr.lpAtrbdSocCntr != null && this._empr.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr != null" class="label_value">
					<div *ngIf="this._empr.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr >= 0; then lucroThenBlock else prejElseBlock" ></div>
					<ng-template #lucroThenBlock>
						<span class="label-number-positive">R$ {{ this._empr.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr | shortNumber:'1.2-2' }}</span>
					</ng-template>
					<ng-template #prejElseBlock>
						<span class="label-number-negative">R$ {{ this._empr.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr | shortNumber:'1.2-2' }}</span>
					</ng-template>
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_la_label">
				<div class="label_key">
					Lucro/Ação (L/A):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_la_value">
				<label *ngIf="this._empr != null && this._empr.infComplEmpr != null && this._empr.infComplEmpr.la != null" class="label_value">
					<div *ngIf="this._empr.infComplEmpr != null && this._empr.infComplEmpr.la.vlInfComplEmpr >= 0; then laThenBlock else laElseBlock" ></div>
					<ng-template #laThenBlock>
						<span class="label-number-positive">R$ {{ ( this._empr.infComplEmpr.la.vlInfComplEmpr ) | number:'1.2-2' }}</span>
					</ng-template>
					<ng-template #laElseBlock>
						<span class="label-number-negative">R$ {{ ( this._empr.infComplEmpr.la.vlInfComplEmpr ) | number:'1.2-2' }}</span>
					</ng-template>
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_roa_label">
				<div class="label_key">
					Retorno s/ Ativo Total (ROA):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_roa_value">
				<label *ngIf="this._empr != null && this._empr.infComplEmpr != null && this._empr.infComplEmpr.roa != null &&this._empr.infComplEmpr.roa.vlInfComplEmpr != null" class="label_value">
					<div *ngIf="this._empr.infComplEmpr.roa.vlInfComplEmpr >= 0; then roaThenBlock else roaElseBlock" ></div>
					<ng-template #roaThenBlock>
						<span class="label-number-positive">{{ ( this._empr.infComplEmpr.roa.vlInfComplEmpr ) | number:'1.2-2' }}%</span>
					</ng-template>
					<ng-template #roaElseBlock>
						<span class="label-number-negative">{{ ( this._empr.infComplEmpr.roa.vlInfComplEmpr ) | number:'1.2-2' }}%</span>
					</ng-template>
				</label>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_roe_label">
				<div class="label_key">
					Retorno s/ Patr. Líq. (ROE):
				</div>
			</div>

			<div class="grid_item_empr_detail_dadosBasicos_roe_value">
				<label *ngIf="this._empr != null && this._empr.infComplEmpr != null && this._empr.infComplEmpr.roe != null && this._empr.infComplEmpr.roe.vlInfComplEmpr != null" class="label_value">
					<div *ngIf="this._empr.infComplEmpr.roe.vlInfComplEmpr >= 0; then roeThenBlock else roeElseBlock" ></div>
					<ng-template #roeThenBlock>
						<span class="label-number-positive">{{ ( this._empr.infComplEmpr.roe.vlInfComplEmpr ) | number:'1.2-2' }}%</span>
					</ng-template>
					<ng-template #roeElseBlock>
						<span class="label-number-negative">{{ ( this._empr.infComplEmpr.roe.vlInfComplEmpr ) | number:'1.2-2' }}%</span>
					</ng-template>
				</label>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Classificação Setorial:
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlSetor.isShowProgressBar" [mode]="this._controlSetor.modeProgressBar" value="this._controlSetor.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_empr_detail_setorEcnm">
			<div class="grid_item_empr_detail_setorEcnm_setor_label">
				<div class="label_key">
					Setor:
				</div>
			</div>

			<div class="grid_item_empr_detail_setorEcnm_setor_value">
				<a *ngIf="this._empr != null" [routerLink]="" routerLinkActive="active" (click)="detailSetorEcnm()"> 
					{{this._empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
				</a> 
			</div>

			<div class="grid_item_empr_detail_setorEcnm_subSetor_label">
				<div class="label_key">
					Sub-setor:
				</div>
			</div>

			<div class="grid_item_empr_detail_setorEcnm_subSetor_value">
				<a *ngIf="this._empr != null" [routerLink]="" routerLinkActive="active" (click)="detailSubSetorEcnm()"> 
					{{this._empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm}}
				</a> 
			</div>

			<div class="grid_item_empr_detail_setorEcnm_segmento_label">
				<div class="label_key">
					Segmento:
				</div>
			</div>

			<div class="grid_item_empr_detail_setorEcnm_segmento_value">
				<a *ngIf="this._empr != null" [routerLink]="" routerLinkActive="active" (click)="detailSegmEcnm()"> 
					{{this._empr.segmEcnm.nmSegmEcnm}}
				</a> 
			</div>

			<div class="grid_item_empr_detail_setorEcnm_atvd_label">
				<div class="label_key">
					Setor da Atividade CVM:
				</div>
			</div>

			<div class="grid_item_empr_detail_setorEcnm_atvd_value">
				<a *ngIf="this._empr != null" [routerLink]="" routerLinkActive="active" (click)="detailSetorAtvdd()"> 
					{{this._empr.setorAtvdd.nmSetorAtvdd}}
				</a> 
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" (opened)="onOpenedPanelInvList()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Ativos (Ações):
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlInvList.isShowProgressBar" [mode]="this._controlInvList.modeProgressBar" value="this._controlInvList.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_empr_detail_inv">
			<div class="grid_item_empr_detail_inv_invList_acoes_title">
				Lista de Ativos da empresa que sejam Ação ou Fundo.
			</div>

			<div class="grid_item_empr_detail_inv_invList_acoes_value">
				<table mat-table [dataSource]="_invDemaisDataSource">
					<ng-container matColumnDef="inv" sticky>
						<th mat-header-cell *matHeaderCellDef> Ticker </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailCrctInv(element.cdInv)" class="linkInTable"> 
								{{element.nmInv}}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="tipInv">
						<th mat-header-cell *matHeaderCellDef> Tipo/Espécie </th>
						<td mat-cell *matCellDef="let element"> 
							<div class="label_value">	
								<label *ngIf="element.tipInv.cdTipInv == 7">
									{{element.tipInv.nmTipInv}} {{element.crctInvAcao.espcAcao.nmEspcAcao}}
									<mat-icon class="icon_help" matTooltip="{{element.crctInvAcao.espcAcao.descrEspcAcao}}">help</mat-icon>
								</label>
								<label *ngIf="element.tipInv.cdTipInv != 7">
									{{element.tipInv.nmTipInv}}
								</label>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="negc">
						<th mat-header-cell *matHeaderCellDef> Período de Negociação </th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao.negc != null && element.crctInvAcao.negc.dtIni && element.crctInvAcao.negc.dtIni != null && element.crctInvAcao.negc.dtFim && element.crctInvAcao.negc.dtFim != null" class="label_value">	
								{{element.crctInvAcao.negc.dtIni | date:'dd/MM/yyyy':'+0000'}} a {{element.crctInvAcao.negc.dtFim | date:'dd/MM/yyyy':'+0000'}}
							</div>
							<div *ngIf="element.crctInvAcao.negc != null && element.crctInvAcao.negc.dtIni && element.crctInvAcao.negc.dtIni != null && ( !element.crctInvAcao.negc.dtFim || element.crctInvAcao.negc.dtFim == null )" class="label_value">	
								Desde {{element.crctInvAcao.negc.dtIni | date:'dd/MM/yyyy':'+0000'}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="volNegc">
						<th mat-header-cell *matHeaderCellDef> 
							Vol. Neg. 
							<mat-icon class="icon_help" matTooltip="Volume médio de negócios nos últimos 3 meses">help</mat-icon>
						</th>
						<td mat-cell *matCellDef="let element"> 
							<div class="label_value">	
								{{element.crctInvAcao.volNegcMed | shortNumber:'1.0-0'}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="lastCotc">
						<th mat-header-cell *matHeaderCellDef> 
							Últ. Cotação
						</th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao.hstCotcAcao != null" class="label_value">	
								{{element.crctInvAcao.hstCotcAcao[0].prcFech | number:'1.2-2'}}
								<mat-icon class="icon_help" 
										  matTooltip="Cotação de: {{element.crctInvAcao.hstCotcAcao[0].dtCotc | date:'dd/MM/yyyy':'+0000'}}
										              Abert.: {{element.crctInvAcao.hstCotcAcao[0].prcAbert | number:'1.2-2'}} 
													  Mín./Máx.: {{element.crctInvAcao.hstCotcAcao[0].prcMin | number:'1.2-2'}} - {{element.crctInvAcao.hstCotcAcao[0].prcMax | number:'1.2-2'}}
													  Var.: {{element.crctInvAcao.hstCotcAcao[0].varPrcFech | number:'1.2-2'}}%"
										  [matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="options">
					<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #appMenu="matMenu">
								<button mat-menu-item (click)="detailCrctInv( element.cdInv )" type="submit">Detalhar Ticker</button>
							</mat-menu>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_invDemaisDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _invDemaisDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="this.isInvDemaisListEmpty" class="grid_item_empr_detail_inv_invList_acoes_empty">
				<label class="label_list_empty">Não há tickers vinculados à empresa.</label>
			</div>

			<div class="grid_item_empr_detail_inv_acoes_on_label" *ngIf="this._qtdAcoesOn != null">
				<div class="label_key">
					Ações Ordinárias:
				</div>
			</div>

			<div class="grid_item_empr_detail_inv_acoes_on_value" *ngIf="this._qtdAcoesOn != null">
				<label class="label_value">{{ this._qtdAcoesOn | number:'1.0-0' }}</label>
			</div>

			<div class="grid_item_empr_detail_inv_acoes_pn_label" *ngIf="this._qtdAcoesPn != null">
				<div class="label_key">
					Ações Preferenciais:
				</div>
			</div>

			<div class="grid_item_empr_detail_inv_acoes_pn_value" *ngIf="this._qtdAcoesPn != null">
				<label class="label_value">{{ this._qtdAcoesPn | number:'1.0-0' }}</label>
			</div>

			<div class="grid_item_empr_detail_inv_acoes_label" *ngIf="this._qtdTtlAcoes != null">
				<div class="label_key">
					Total de Ações/Cotas:
				</div>
			</div>

			<div class="grid_item_empr_detail_inv_acoes_value" *ngIf="this._qtdTtlAcoes != null">
				<label class="label_value">{{ this._qtdTtlAcoes | number:'1.0-0' }}</label>
			</div>

			<div class="grid_item_empr_detail_inv_acionistas_label" *ngIf="this._qtdAcionistas != null">
				<div class="label_key">
					Total de Acionistas/Cotistas:
				</div>
			</div>

			<div class="grid_item_empr_detail_inv_acionistas_value" *ngIf="this._qtdAcionistas != null">
				<label class="label_value">{{ this._qtdAcionistas | number:'1.0-0' }}</label>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" (opened)="onOpenedPanelOpcaoList()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Ativos (Opções):
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlOpcaoList.isShowProgressBar" [mode]="this._controlOpcaoList.modeProgressBar" value="this._controlOpcaoList.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_empr_detail_inv">
			<div *ngIf="this.isInvOpcaoListEmpty == false" class="grid_item_empr_detail_inv_invList_opcoes_filters">
				<div class="grid_container_empr_detail_inv_invList_opcoes_filters">
					<div class="grid_item_empr_detail_inv_invList_opcoes_filters_espc">
						<mat-form-field>
							<mat-label>Espécie da Opção:</mat-label>
								<mat-select matNativeControl (selectionChange)="applyFilterEspcOpcaoSelected( $event )" formControlName="espcOpcaoSelected">
									<mat-option *ngFor="let espcAcao of _espcOpcaoList" [value]="espcAcao.value" >{{ espcAcao.viewValue }}</mat-option>
								</mat-select>
						</mat-form-field>
					</div>

					<div class="grid_item_empr_detail_inv_invList_opcoes_filters_estl">
						<mat-form-field>
							<mat-label>Estilo da Opção:</mat-label>
								<mat-select matNativeControl (selectionChange)="applyFilterEstlOpcaoSelected( $event )" formControlName="estlOpcaoSelected">
									<mat-option *ngFor="let estlAcao of _estlOpcaoList" [value]="estlAcao.value" >{{ estlAcao.viewValue }}</mat-option>
								</mat-select>
						</mat-form-field>
					</div>

					<div class="grid_item_empr_detail_inv_invList_opcoes_filters_dtFimNegc">
						<mat-form-field>
							<mat-label>Vencimento:</mat-label>
								<mat-select matNativeControl (selectionChange)="applyFilterDtFimNegcOpcaoSelected( $event )" formControlName="dtFimNegcOpcaoSelected">
									<mat-option *ngFor="let dtFimNegc of this._dtFimNegcOpcaoList" [value]="dtFimNegc.value" >
										<span *ngIf="dtFimNegc.value >  0">{{ dtFimNegc.objectValue | date:'dd/MM/yyyy':'+0000' }}</span>
										<span *ngIf="dtFimNegc.value == 0">{{ dtFimNegc.viewValue }}</span>
									</mat-option>
								</mat-select>
						</mat-form-field>
					</div>

					<div class="grid_item_empr_detail_inv_invList_opcoes_filters_lastCotcExist">
						<mat-checkbox (change)="applyFilterLastCotcExistOpcaoSelected($event)" formControlName="isLastCotcExistOpcaoSelected" >
							Tickers com cotação
						</mat-checkbox>
					</div>

					<div class="grid_item_empr_detail_inv_invList_opcoes_filters_strike">
						<mat-label>Strike Entre:</mat-label>
						<ngx-slider [options]="this._optionsStrikeRangeSliderOpcao"
									(userChangeEnd)="applyFilterStrikeRangeOpcaoSelected($event)" 
									formControlName="strikeOpcaoSelected"></ngx-slider>
					</div>
				</div>
			</div>

			<div class="grid_item_empr_detail_inv_invList_opcoes_value">
				<table mat-table [dataSource]="_invOpcaoDataSource" matSort>
					<ng-container matColumnDef="invOpcaoNmInv" sticky>
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Ticker </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailCrctInv(element.cdInv)" class="linkInTable"> 
								{{element.nmInv}}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoNmEspc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> 
							Espécie (CALL/PUT)
							<mat-icon class="icon_help" matTooltip="CALL: Direito de compra; PUT: Direito de venda.">help</mat-icon> 
						</th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao != null && element.crctInvAcao.espcAcao != null" class="label_value">
								{{element.crctInvAcao.espcAcao.nmEspcAcao}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoEstl">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> 
							Estilo (A/E)
							<mat-icon class="icon_help" matTooltip="[A]mericano ou [E]uropeu">help</mat-icon> 
						</th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao != null && element.crctInvAcao.estlOpcao != null" class="label_value">
								{{element.crctInvAcao.estlOpcao}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoPrcExerc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Strike </th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao.negc != null && element.crctInvAcao.negc.dtFim && element.crctInvAcao.negc.prcExerc != null" class="label_value">	
								{{element.crctInvAcao.negc.prcExerc | number:'1.2-2'}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoDtFimNegc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Vencimento </th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao.negc != null && element.crctInvAcao.negc.dtFim && element.crctInvAcao.negc.dtFim != null" class="label_value">	
								{{element.crctInvAcao.negc.dtFim | date:'dd/MM/yyyy':'+0000'}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoVolNegcMed">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> 
							Vol. Neg. 
							<mat-icon class="icon_help" matTooltip="Volume médio de negócios nos últimos 3 meses">help</mat-icon>
						</th>
						<td mat-cell *matCellDef="let element"> 
							<div class="label_value">	
								{{element.crctInvAcao.volNegcMed | shortNumber:'1.0-0'}}
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoLastCotc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> 
							Últ. Cotação
						</th>
						<td mat-cell *matCellDef="let element"> 
							<div *ngIf="element.crctInvAcao.hstCotcAcao != null" class="label_value">	
								{{element.crctInvAcao.hstCotcAcao[0].prcFech | number:'1.2-2'}}
								<mat-icon class="icon_help" 
										  matTooltip="Cotação de: {{element.crctInvAcao.hstCotcAcao[0].dtCotc | date:'dd/MM/yyyy':'+0000'}}
										              Abert.: {{element.crctInvAcao.hstCotcAcao[0].prcAbert | number:'1.2-2'}} 
													  Mín./Máx.: {{element.crctInvAcao.hstCotcAcao[0].prcMin | number:'1.2-2'}} - {{element.crctInvAcao.hstCotcAcao[0].prcMax | number:'1.2-2'}}
													  Var.: {{element.crctInvAcao.hstCotcAcao[0].varPrcFech | number:'1.2-2'}}%"
										  [matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="invOpcaoOptions">
					<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #appMenu="matMenu">
								<button mat-menu-item (click)="detailCrctInv( element.cdInv )" type="submit">Detalhar Ticker</button>
							</mat-menu>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_invOpcaoDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _invOpcaoDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this.paginatorTickersOpcaoPageSize"
								[pageIndex]="this.paginatorTickersOpcaoPageIndex"
								(page)="changeTickersOpcaoPageSize($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div *ngIf="this.isInvOpcaoListEmpty" class="grid_item_empr_detail_inv_invList_opcoes_empty">
				<label class="label_list_empty">Não há opções vinculadas à empresa.</label>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Resultados Contábeis:
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlRstdCntb.isShowProgressBar" [mode]="this._controlRstdCntb.modeProgressBar" value="this._controlRstdCntb.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_empr_detail_rstdCntb">
			<div class="grid_item_empr_detail_rstdCntb_tipInfRstdList_filter_perdc">
				<mat-form-field>
					<mat-label>Periodicidade:</mat-label>
						<mat-select matNativeControl (selectionChange)="updateTipInfRstdEmprListByPerdc( $event )" formControlName="sglPerdc">
							<mat-option value="TRMST">Trimestral</mat-option>
							<mat-option value="SMST">Semestral</mat-option>
							<mat-option value="ANUAL">Anual</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_empr_detail_rstdCntb_tipInfRstdList_filter_aaRef">
				<mat-form-field>
					<mat-label>Desde:</mat-label>
						<mat-select matNativeControl (selectionChange)="updateTipInfRstdEmprList( $event )" formControlName="aaRef">
							<mat-option *ngFor="let aaRef of _aaRefList" [value]="aaRef.value">
								{{aaRef.viewValue}}
							</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div *ngIf="isSglPerdcSelectedNecessitaNrRef()" class="grid_item_empr_detail_rstdCntb_tipInfRstdList_filter_nrRef">
				<mat-form-field>
					<mat-label>{{_nmColumnNrRef}}</mat-label>
						<mat-select matNativeControl (selectionChange)="updateTipInfRstdEmprList( $event )" formControlName="nrRef">
							<mat-option *ngFor="let nrRef of _nrRefList" [value]="nrRef.value">
								{{nrRef.viewValue}}
							</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_empr_detail_rstdCntb_tipInfRstdList_value">
				<table mat-table [dataSource]="_tipInfRstdDataSource">
					<ng-container matColumnDef="tipInfRstdEmpr" sticky>
						<th mat-header-cell *matHeaderCellDef> Informação </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{element.tipInfRstdEmpr.nmTipInfRstdEmpr}}
								<mat-icon class="icon_help" matTooltip="{{element.tipInfRstdEmpr.txDcrUsuTipInfRstdEmpr}}">help</mat-icon>
							</label>
						</td>
					</ng-container>

					<ng-container *ngFor="let disCol of _tipInfRstdPerdcDisplayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
						<th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
						<td mat-cell *matCellDef="let element" class="mat-cell-number">
							<div *ngIf="element.infRstdEmprArray[colIndex] != null && element.infRstdEmprArray[colIndex].vlRstdEmpr != null; then thenBlock" ></div>
							<ng-template #thenBlock>
								<div *ngIf="element.infRstdEmprArray[colIndex].vlRstdEmpr >= 0; then vlrPosThenBlock else vlrNegElseBlock" ></div>
								<ng-template #vlrPosThenBlock>
									<span class="mat-cell-value-positive">{{(element.infRstdEmprArray[colIndex].vlRstdEmpr / 1000) | number:'1.0-0' }}</span>
								</ng-template>
								<ng-template #vlrNegElseBlock>
									<span class="mat-cell-value-negative">{{(element.infRstdEmprArray[colIndex].vlRstdEmpr / 1000) | number:'1.0-0' }}</span>
								</ng-template>
							</ng-template>

							<div *ngIf="element.infRstdEmprArray[colIndex].getVarPercVlRstdEmpr() != null; then varVlRstdThenBlock" ></div>
							<ng-template #varVlRstdThenBlock>
								<div *ngIf="element.infRstdEmprArray[colIndex].getVarPercVlRstdEmpr() > 0; then varPosVlRstdThenBlock else varNegVlRstdThenBlock" ></div>
								<ng-template #varPosVlRstdThenBlock>
									<span class="mat-cell-value-positive">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{ element.infRstdEmprArray[colIndex].getVarPercVlRstdEmpr() | number:'1.0-0' }}%
									</span>
								</ng-template>
								<ng-template #varNegVlRstdThenBlock>
									<span class="mat-cell-value-negative">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_down" >arrow_downward</mat-icon> {{ element.infRstdEmprArray[colIndex].getVarPercVlRstdEmpr()*(-1) | number:'1.0-0' }}%
									</span>
								</ng-template>
							</ng-template>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_tipInfRstdDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _tipInfRstdDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="isRstdListEmpty === true; then thenBlockRstd else elseBlockRstd" ></div>
			<ng-template #thenBlockRstd>
				<div class="grid_item_empr_detail_rstdCntb_tipInfRstdList_empty">
					<label class="label_list_empty">Não há resultados disponíveis.</label>
				</div>
			</ng-template>
			<ng-template #elseBlockRstd>
				<div class="grid_item_empr_detail_rstdCntb_tipInfRstdList_comment_1">
					<label class="label_comment">Valores em milhares.</label>
				</div>
				<div class="grid_item_empr_detail_rstdCntb_tipInfRstdList_comment_2">
					<label class="label_comment">Os percentuais são em relação ao mesmo período do ano anterior.</label>
				</div>
			</ng-template>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" (opened)="onOpenedPanelDocList()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Documentos:
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlDocList.isShowProgressBar" [mode]="this._controlDocList.modeProgressBar" value="this._controlDocList.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_empr_detail_ddc">
			<div class="grid_item_empr_detail_ddc_ddcList_value">
				<table mat-table [dataSource]="_ddcDataSource">
					<ng-container matColumnDef="dtPblccDocDmntCntb" sticky>
						<th mat-header-cell *matHeaderCellDef> Data Publicação </th>
						<td mat-cell *matCellDef="let element"> {{element.dtPblccDocDmntCntb | date:'dd/MM/yyyy':'+0000'}} </td>
					</ng-container>

					<ng-container matColumnDef="sglDocDmntCntb">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.sglDocDmntCntb}} </td>
					</ng-container>

					<ng-container matColumnDef="options">
					<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #appMenu="matMenu">
								<button mat-menu-item (click)="detailDdc( element.sglDocDmntCntb, element.dtPblccDocDmntCntb, element.nrSeqDocDmntCntb )" type="submit">Detalhar Documento</button>
							</mat-menu>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_ddcDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _ddcDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="this.isDdcListEmpty == true" class="grid_item_empr_detail_ddc_ddcList_empty">
				<label class="label_list_empty">Não há documentos disponíveis.</label>
			</div>
		</div>
	</mat-expansion-panel>
</form>
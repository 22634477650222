import { Component                    } from '@angular/core';
import { DomSanitizer                 } from '@angular/platform-browser';
import { ActivatedRoute, Router       } from '@angular/router';
import { FormBuilder                  } from '@angular/forms';
import { MatDialog                    } from '@angular/material/dialog';

import { CarteiraListComponent } from '../carteira-list.component';

import { AplicationStateService       } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService        } from '../../../../_services/authentication/authentication.service';
import { InvService                   } from '../../../../_services/inv/inv.service';
import { DtqService } from 'src/app/_services/dtq/dtq.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ShortNumberPipe } from 'src/app/_pipes/short-number/short-number.pipe';
import { GrCrctInvService } from 'src/app/_services/grCrctInv/grCrctInv.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-carteira-list-mobile',
  templateUrl: '../desktop/carteira-list-desktop.component.html',
  styleUrls: ['./carteira-list-mobile.component.css']
})
export class CarteiraListMobileComponent extends CarteiraListComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected datepipe              : DatePipe              ,
               protected decimalPipe           : DecimalPipe           ,
               protected shortNumberPipe       : ShortNumberPipe       ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService            ,
               protected grCrctInvService      : GrCrctInvService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, datepipe, decimalPipe, shortNumberPipe, aplicationStateService, authenticationService, prmUsrService, snackBar, invService, grCrctInvService );
  }
}

import { User 							} from '../user';
import { CrctInv 						} from '../inv';
import { Moeda 							} from '../moeda';
import { ControlShowItemGrCrctInvAcao 	} from '@components/carteira/mvtc/models/controlShowItemGr';
import { UltAcaoAssist } from '@models/assist/assist';

export class GrupoCrctInvAcao{
	cdGrCrctInvAcao      : number;
	usr                  : User;
	nmGrCrctInvAcao      : string;
	txDescrGrCrctInvAcao : string;
	tipGrCrctInvAcao	 : TipGrCrctInvAcao;
	vlSdoCptl			 : number;
	vlSdoRlzd			 : number;
	vlSdoCx  			 : number;
	vlSdoInvt			 : number;
	vlSdoInvtAtlzd       : number; //Saldo investido atualizado pela cotação mais recente.
	vlSdoRstd			 : number;
	vlRstdGrCrctInvAcao  : number; 
	inGrCrctInvAcaoAtivo : boolean;
	itemGrCrctInvAcaoList: ItemGrupoCrctInvAcao[];
	vlCptlTtl			 : number; //Soma de todas as movimentacoes do tipo CAPITAL.
	vlResgTtl			 : number; //Soma de todas as movimentacoes do tipo RESGATE.
	dtRefMvtcLess		 : Date;
	dtRefMvtcGreater	 : Date;
	dtRefMvtcBefore  	 : Date;

	qtAssist			 : number; //Quantidade de assistentes ativos vinculados ao grupo.
}


export class UsrComptGrCrctInvAcao{
	grCrctInvAcao           : GrupoCrctInvAcao;
	usr   	                : User;
	tsIniComptGrCrctInvAcao: Date;
	tsFimComptGrCrctInvAcao: Date;
}


export class ItemGrupoCrctInvAcao{
	grCrctInvAcao           : GrupoCrctInvAcao;
	crctInv                 : CrctInv;
	tsInclItemGrCrctInvAcao : Date;
	nrOrdemItemGrCrctInvAcao: number;
	qtdUndNegc              : number;
	vlSdoRlzd               : number;
	vlSdoInvt               : number;
	prcMedAtu               : number;
	inItemGrCrctInvAcaoAtivo: boolean;
	vlTtlUndNegc			: number;
	vlSdoRstd               : number;
	tipTndc		  : TipTndc;

	ultAcaoAssistList       : UltAcaoAssist[];	//Ultima acao sugerida por cada um dos assistentes vinculados à Carteira.

	controlShow             : ControlShowItemGrCrctInvAcao;
}


export class HistoricoContabilGrupoCrctInvAcao{
	grCrctInvAcao      : GrupoCrctInvAcao;
	dtRefHstCntb   	   : Date;
	dtRefMvtc    	   : Date;
	vlSdoCptl		   : number;
	vlSdoRlzd		   : number;
	vlSdoCx 	       : number;
	vlSdoInvt	       : number;
	vlSdoInvtAtlzd     : number; //Saldo investido atualizado pela cotação mais recente.
	vlSdoRstd	       : number; //Valor do resultado no periodo avaliado.

	//As variacoes numericas dos valores.
	vlVarSdoCptl	  : number;
	vlVarSdoRlzd	  : number;
	vlVarSdoCx 	      : number;
	vlVarSdoInvt	  : number;
	vlVarSdoInvtAtlzd : number;
	vlVarSdoRstd	  : number;

	//As variacoes percentuais dos valores.
	vlVarPercSdoCptl     : number;
	vlVarPercSdoRlzd     : number;
	vlVarPercSdoCx 	     : number;
	vlVarPercSdoInvt     : number;
	vlVarPercSdoInvtAtlzd: number;
	vlVarPercSdoRstd     : number;

	//Os ativos emque constavam no grupo com pelo menos uma qtdUndNegc, no periodo.
	itemGrupoCrctInvAcaoList: ItemGrupoCrctInvAcao[];
}


export class MovimentacaoGrupoCrctInvAcao{
	grCrctInvAcao       : GrupoCrctInvAcao;
	tsInclMvtc			: Date;
	dtRefMvtc			: Date;
	tipMvtc				: TipMvtcGrupoCrctInvAcao;
	crctInv				: CrctInv;
	itemGrCrctInvAcao   : ItemGrupoCrctInvAcao;
	tipMoeda			: Moeda;
	qtdUndNegc		    : number;
	prcUndNegc			: number;
	vlTaxaPercRentAaNegc: number;
	vlTtlEncg			: number;
	vlTtlMvtc			: number;
	inMvtcAutm          : boolean;
	inMvtcDesft         : boolean;
	tsMvtcDesft         : Date; //Timestamp da movimentação que foi desfeita (estornada).
	tipEstMvtc			: TipEstMvtcGrupoCrctInvAcao;
	txObsMvtc			: string;
}


export class TipMvtcGrupoCrctInvAcao{
	sglTipMvtcGrCrctInvAcao    : string;
	nmTipMvtcGrCrctInvAcao     : string;
	txDescrTipMvtcGrCrctInvAcao: string;
	nmIcon                     : string;
	vlMultEncgMvtc			   : number;
	vlMultQtdUndNegc		   : number;
	inNcddCdInv		           : boolean;
	inNcddQtdUndNegc           : boolean;
	inNcddVlTaxaPercRentAaNegc : boolean;
}


export class TipEstMvtcGrupoCrctInvAcao{
	sglTipEstMvtcGrCrctInvAcao    : string;
	nmTipEstMvtcGrCrctInvAcao     : string;
	txDescrTipEstMvtcGrCrctInvAcao: string;
}


export class TipGrCrctInvAcao{
	sglTipGrCrctInvAcao    : string;
	nmTipGrCrctInvAcao     : string;
	txDescrTipGrCrctInvAcao: string;
	nmIcon                 : string;
}


export class TipEncg{
	sglTipEncg: string;
	nmTipEncg: string;
	txDescrTipEncg: string;
	inTipEncgAtivo: boolean;

	rgrEncgMvtcSelected: RgrEncgMvtc;
}


export class TipTndc{
	sglTipTndc: string;
	nmTipTndc: string;
}


export class EncgMvtcGrCrctInvAcao{
	mvtcGrCrctInvAcao: MovimentacaoGrupoCrctInvAcao;
	tipEncg: TipEncg;
	vlEncg: number;
	txObsEncg: string;
}


export class RgrEncgMvtc{
	nrRgrEncgMvtc: number;
	usr: User;
	tipMvtcGrupoCrctInvAcao: TipMvtcGrupoCrctInvAcao;
	tipEncg: TipEncg;
	crctInv: CrctInv;
	dtIniNegc: Date;
	dtFimNegc: Date;
	qtMinDiasNegc: number;
	qtMaxDiasNegc: number;
	vlTxPercEncgMvtcPadr: number;
	vlTxPercEncgMvtcMin : number;
	vlTxPercEncgMvtcMax : number;
	vlEncgMvtcPadr      : number;
	vlEncgMvtcMin       : number;
	vlEncgMvtcMax       : number;
	inRgrEncgMvtcAtivo  : number;
}

import { Empr } from './empr';
import { HstCotcAcao } from './cotc';
import { HstIndTcn, IndTcnWithHst } from './indTcn';

export class CrctInvDetail{
	crctInv: CrctInv;
}

export class CrctInv{
	cdInv: number;
	nmInv: string;
	tipInv: TipInv;
	empr: Empr;
	crctInvAcao: CrctInvAcao;
}

export class CrctInvAcao{
	cdInv: number;
	cdBlsVlrs: number;
	nmPregao: string;
	espcAcao: EspcAcao;
	inPermtAtlzCotc: boolean;
	cotcAcao: HstCotcAcao;
	hstCotcAcao: HstCotcAcao[];
	hstIndTcn: HstIndTcn[];
	indTcnList: IndTcnWithHst[];
	qtdNegcMed: number;
	volNegcMed: number;
	volFincNegcMed: number;
	relc: Relc;  		//Periodo de listagem do tickers.
	negc: Negc;	 		//Periodo de negociacao do ticker.
	emissor: string;
	estlOpcao: string;	//Estilo da opcao.
}

export class TipInv{
	cdTipInv: number;
	nmTipInv: string;
}

export class EspcAcao{
	cdEspcAcao   : number;
	nmEspcAcao   : string;
	descrEspcAcao: string;
	tipInv		 : TipInv;
}

export class Relc{
	dtIni   : Date;
	dtFim   : Date;
}

export class Negc{
	dtIni   : Date;
	dtFim   : Date;
	prcExerc: number;
}


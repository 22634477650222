import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { DomSanitizer                            } from '@angular/platform-browser';
import { ActivatedRoute, Router                  } from '@angular/router';
import { FormBuilder, FormControl                } from '@angular/forms';

import { MatDialog                               } from '@angular/material/dialog';
import { MatTableDataSource                      } from '@angular/material/table';

import { MatSnackBar                             } from '@angular/material/snack-bar';

import { CrctInv, TipInv, CrctInvAcao, EspcAcao  } from '@models/inv';
import { HstCotcAcao                             } from '@models/cotc';

import { AplicationStateService                  } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService                   } from '@services/authentication/authentication.service';
import { CotcService                             } from '@services/cotc/cotc.service';
import { IndTcnService                           } from '@services/indTcn/indTcn.service';
import { InvService                              } from '@services/inv/inv.service';
import { PrmUsrService                           } from '@services/prmUsr/prmUsr.service';

import { ChartType, Column, GoogleChartComponent, ScriptLoaderService, getPackageForChart, ChartWrapperComponent, ChartErrorEvent } from 'angular-google-charts';

import { AbstractMainComponent              } from '../../abstract-main.component';
import { DatePipe, DecimalPipe              } from '@angular/common';

import { MatPaginator                       } from '@angular/material/paginator';
import { MatSidenav                         } from '@angular/material/sidenav';

import { TickerDialogSelectDesktopComponent } from '../dialog/select/desktop/ticker-dialog-select-desktop.component';

import { SetorEcnm, SubSetorEcnm, SegmEcnm  } from 'src/app/_models/setorEcnm';
import { SetorAtvdd                         } from 'src/app/_models/setorAtvdd';

import { InfCrctInvService                                      } from 'src/app/_services/infCrctInv/infCrctInv.service';
import { MatSelectChange                                        } from '@angular/material/select';
import { ControlLifeCycleCrctInv                                } from 'src/app/_models/infCrctInv/controlLifeCycleCrctInv';
import { InfCrctInvInterface, TipInfCrctInv, TipViewInfCrctInv, InfCrctInvPk  } from 'src/app/_models/infCrctInv/infCrctInv';
import { InfCrctInvIndTcn                                       } from 'src/app/_models/infCrctInv/infCrctInvIndTcn';
import { ManagerLifeCycleCrctInv                                } from 'src/app/_models/infCrctInv/managerLifeCycleCrctInv';
import { HstIndTcn                                              } from 'src/app/_models/indTcn';
import { InfCrctInvCotc                                         } from 'src/app/_models/infCrctInv/infCrctInvCotc';
import { GrupoCrctInvAcao, TipTndc, ItemGrupoCrctInvAcao                                       } from 'src/app/_models/grCrctInv/grCrctInv';
import { GrCrctInvService                                       } from 'src/app/_services/grCrctInv/grCrctInv.service';
import { CarteiraDialogEditDesktopComponent                     } from '../../carteira/dialog/edit/desktop/carteira-dialog-edit-desktop.component';
import { CarteiraDialogAddDesktopComponent                      } from '../../carteira/dialog/add/desktop/carteira-dialog-add-desktop.component';
import { Empr, TipSitEmpr                                       } from 'src/app/_models/empr';
import { ShortNumberPipe                                        } from 'src/app/_pipes/short-number/short-number.pipe';
import { TickerDialogSelectMobileComponent                      } from '../dialog/select/mobile/ticker-dialog-select-mobile.component';
import { TickerDialogSelectComponent                            } from '../dialog/select/ticker-dialog-select.component';
import { ComponentType } from '@angular/cdk/portal';
import { MatSort } from '@angular/material/sort';

export abstract class TickerChartComponent extends AbstractMainComponent{
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_COTC_DT_INICIO       = 'InvChart_cotcDtInicio';
  private PRM_USR_CD_INV_LIST          = 'InvChart_cdInvList';
  private PRM_USR_INF_CRCT_INV_LIST    = 'InvChart_infCrctInvList';
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'InvChar_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'InvChart_paginatorPageIndex';

  private managerLifeCycleCrctInv: ManagerLifeCycleCrctInv;

  //Tickers a serem apresentados no grafico.
  public _tickerListDisplayedColumns: string[] = [ 'ticker', 'tipInv', 'empr', 'setor', 'options' ];
  public _tickerListDataSource      : MatTableDataSource< ControlLifeCycleCrctInv >;
  @ViewChild( MatPaginator, {static: true} ) _tickerListPaginator: MatPaginator;
  @ViewChild( 'itemGrCrctInvAcaoListSort', {static: true} ) _itemGrCrctInvAcaoListSort: MatSort;
  public _tickerListPaginatorPageSize  = 20;
  public _tickerListPaginatorPageIndex = 0;

  public _form;

  private readonly chartPackage = getPackageForChart( ChartType.BarChart );

  @ViewChild( 'divCotcChart', { read: ElementRef } ) 
  _divCotcChartElementView: ElementRef< GoogleChartComponent >;

  public _cotcChart = {
    title: 'Cotação',
    type: ChartType.ColumnChart,
    data: [], //Os dados a serem apresentados no grafico
    columns: [],
    options: { legend: 'none',
               crosshair    : { trigger: 'both' },  //Linha fina vertical e horizontal centralizada no ponto focado.
               titlePosition: 'in',
               titleTextStyle: { fontSize: '0.8em', italic: true },
               candlestick: { fallingColor: { strokeWidth: 0, fill: '#a52714' }, // red
                              risingColor : { strokeWidth: 0, fill: '#0f9d58' }  // green
                            },
               width: '0',
               height: '600',
               isStacked: true,
               tooltip: { isHtml: true },
               interpolateNulls: true,
               hAxis: { title: 'Data', 
                        format: 'MM/yyyy', 
                        gridlines: { count: 4     },
                        minorGridlines: { count: 0 }, 
                        textStyle: { fontSize: '0.7em',
                                     bold: false,
                                     italic: true },
                        slantedText: true,
                        slantedTextAngle: 30 },
               vAxes: {},
               seriesType: "bars",
               series: {},
               chartArea: { left: '8%', right: '5%', top: '2%', bottom: '15%', width: '92%' }
             }
  };

  @ViewChild('sidenav') sidenav: MatSidenav;

  public _infCrctInvList        : InfCrctInvInterface< any >[];
  public _infCrctInvPerdcList   : number[];
  public _infCrctInvTipViewList : TipViewInfCrctInv[];
  public _infCrctInvSelectedList: InfCrctInvInterface< any >[];

  public _grCrctInvAcaoList     : GrupoCrctInvAcao[];
  public _cdGrCrctInvSelected   : number;

  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected datepipe              : DatePipe              ,
               protected decimalPipe           : DecimalPipe           ,
               protected shortNumber           : ShortNumberPipe       ,
               protected loaderService         : ScriptLoaderService   ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService            ,
               protected cotcService           : CotcService           ,
               protected indTcnService         : IndTcnService         ,
               protected infCrctInvService     : InfCrctInvService     ,
               protected grCrctInvService      : GrCrctInvService      ,
               protected dialogSelectTicker    : ComponentType< TickerDialogSelectComponent > ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnChanges( changes: SimpleChanges ){
  }

  ngOnInit() {
    //console.log( 'TickerChartComponent.ngOnInit()...' );

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pLoader = this.initLoader();
    promises.push( pLoader );

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitInfCrctInv = this.initInfCrctInv();
    promises.push( pInitInfCrctInv );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    //Busca lista de grupos de ativos do usuario.
    const pInitGrCrctInv = this.initGrCrctInv();
    promises.push( pInitGrCrctInv );

    this.monitoryInit( promises );
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          //console.log( 'Finalizadas ' + promisesFinish + '/' + qtPromises + ' tarefas.' );
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        //this.setCdGrCrctInvSelected( Number( this.getCdGrCrctInvSelected() ) );
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private initLoader(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this.loaderService.loadChartPackages( _this.chartPackage ).subscribe(() => {
        resolve();
      });

    } );

    return p;
  }

  private verifyInfCrctInvListOfPrmUsr(): void{
    //console.log( 'verifyInfCrctInvListOfPrmUsr()...' );

    //Recupera as infCrctInv que estao salvas como prmUsr.
    const infCrctInvListOfPrmUsr = this.prmUsrService.getValueJsonList( this.PRM_USR_INF_CRCT_INV_LIST );

    if( infCrctInvListOfPrmUsr != null ){
      //console.log( 'Ha ' + infCrctInvListOfPrmUsr.length + ' infCrctInv armazenadas.' );

      infCrctInvListOfPrmUsr.forEach( infCrctInvIterate => {
        const infCrctInvPk = infCrctInvIterate as InfCrctInvPk;

        if( infCrctInvPk != null ){
          //Marca a informacao como se estivesse sido selecionada e depois solicita que seja adicionada na lista de selecionadas.
          this.setInfCrctInvSelected( infCrctInvPk.nmInfCrctInv );
          this.onSelectedInfCrctInvSelected( new MatSelectChange( null, infCrctInvPk.nmInfCrctInv ) );
          this.setInfCrctInvPerdcSelected( infCrctInvPk.perdcInfCrctInv );

          if( infCrctInvPk.tipViewInfCrctInv && infCrctInvPk.tipViewInfCrctInv != null ){
            this.setInfCrctInvTipViewSelected( TipViewInfCrctInv.getTipViewOfCode( infCrctInvPk.tipViewInfCrctInv.code ) );
          }

          this.onAddInfCrctInvSelected();
        }
      } );
    }
    else{
      //console.log( 'Nao ha infCrctInv armazenadas no prmUsr.' );
    }
  }

  ngAfterViewChecked(): void{
    //console.log( 'TickerDetailComponent.ngAfterViewChecked()...' );

    if( this._divCotcChartElementView != null ){
      const elRef: ElementRef = this._divCotcChartElementView;
      const widthOfDivCotcChart = elRef.nativeElement.offsetWidth;

      //Altera o tamanho do grafico
      this._cotcChart.options.width = widthOfDivCotcChart;
    }
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Verifica se usuario possui parametros armazenados.
      const cotcDtInicioOfPrmUsr  = _this.prmUsrService.getValue( _this.PRM_USR_COTC_DT_INICIO );

      //Define valores iniciais.
      let dtCotcFrom = cotcDtInicioOfPrmUsr == null ? new Date() : new Date( cotcDtInicioOfPrmUsr );
      dtCotcFrom.setDate( dtCotcFrom.getDate() - ( cotcDtInicioOfPrmUsr == null ? 90 : 0 ) );

      let dtCotcEnd = new Date();
      dtCotcEnd.setDate( dtCotcEnd.getDate() - 0 );

      _this._form = _this.formBuilder.group(
        { nmInv                    : new FormControl( { value: '', disabled: true } ),
          dtCotcFrom               : dtCotcFrom,
          dtCotcEnd                : dtCotcEnd ,
          idTypeInfoCotc           : 0         ,
          infCrctInvSelected       : null      ,
          infCrctInvPerdcSelected  : null      ,
          infCrctInvTipViewSelected: null
        }
      );

      //console.log( 'form criado.' );

      //Inicializa o manager, responsavel por coordenar todos os controls das informacoes dos tickers.
      _this.managerLifeCycleCrctInv = new ManagerLifeCycleCrctInv();
      _this.managerLifeCycleCrctInv.setDtFromValues( _this.getDtCotcFromSelected() );
      _this.managerLifeCycleCrctInv.setDtEndValues( _this.getDtCotcEndSelected() );

      resolve();
    } );

    return p;
  }

  private initInfCrctInv(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._infCrctInvList = [];

      //Obtem a lista de informacoes que podem ser obtidas de um determinado ticker.
      _this.infCrctInvService.getValues()
        .then( infCrctInvList => {
          const qtInfCrctInv = infCrctInvList != null ? infCrctInvList.length : 0;

          //console.log( 'Foram localizadas ' + qtInfCrctInv + ' informacoes possiveis.' );

          for( let i = 0; i < qtInfCrctInv; i++ ){
            const infCrctInv = infCrctInvList[i];
            _this._infCrctInvList.push( infCrctInv );
          }

          _this.verifyInfCrctInvListOfPrmUsr();

          resolve();
        }
      );

      _this._infCrctInvSelectedList = [];
    } );

    return p;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Recupera os ativos que estao salvos como prmUsr.
      const cdInvListOfPrmUsr = _this.prmUsrService.getValueNumberList( _this.PRM_USR_CD_INV_LIST );

      if( cdInvListOfPrmUsr != null ){
        cdInvListOfPrmUsr.forEach( cdInvIterate => {
          _this.detail( cdInvIterate );
        } );

        resolve();
      }
      else{
        resolve();
      }
    } );

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Verifica se houve o recebimento de algum parm.
      _this.activatedRoute.queryParams.subscribe( params => {
          const cdInv           = params.cdInv;

          if( cdInv != null ){
            _this.detail( cdInv );

            resolve();
          }
          else{
            const cdGrCrctInvAcao = params.cdGrCrctInvAcao;

            if( cdGrCrctInvAcao != null ){
              _this.reloadTickersOnGrCrctInvAcaoSelected( cdGrCrctInvAcao )
                .then( response => {
                  //console.log( 'reloadTickersOnGrCrctInvAcaoSelected finalizado.' );
                  _this.setCdGrCrctInvSelected( cdGrCrctInvAcao );
                  resolve();
                } );
            }
          }
        }
      );
    } );

    return p;
  }

  private initGrCrctInv(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._infCrctInvList = [];

      const cdUsr: number = _this.authenticationService.getSessionService().currentUserValue != null ? _this.authenticationService.getSessionService().currentUserValue.id : null;

      if( cdUsr != null ){
        _this._grCrctInvAcaoList = [];

        //Obtem a lista de informacoes que podem ser obtidas de um determinado ticker.
        _this.grCrctInvService.listGrupos( true, false, false, false, false, false, false )
          .then( grCrctInvList => {
            const qtGrCrctInv = grCrctInvList != null ? grCrctInvList.length : 0;

            //console.log( 'Foram localizadas ' + qtGrCrctInv + ' grupos de ativos do usuario.' );

            const grCrctInvNaoSelecionado = new GrupoCrctInvAcao();
            grCrctInvNaoSelecionado.cdGrCrctInvAcao = null;
            grCrctInvNaoSelecionado.nmGrCrctInvAcao = '';

            _this._grCrctInvAcaoList.push( grCrctInvNaoSelecionado );

            for( let i = 0; i < qtGrCrctInv; i++ ){
              const grCrctInv = grCrctInvList[i];
              _this._grCrctInvAcaoList.push( grCrctInv );
            }

            //console.log( '_grCrctInvAcaoList loaded.' );

            resolve();
          }
        );
      }
      else{
        resolve();
      }
    } );

    return p;
  }

  private getDtCotcFromSelected(){
    return this._form.controls[ 'dtCotcFrom' ].value;
  }

  private setDtCotcFromSelected( dtCotcFrom: string ){
    this._form.patchValue( { dtCotcFrom: dtCotcFrom } );
  }

  private getDtCotcEndSelected(){
    return this._form.controls[ 'dtCotcEnd' ].value;
  }

  private setDtCotcEndSelected( dtCotcEnd: string ){
    this._form.patchValue( { dtCotcEnd: dtCotcEnd } );
  }

  private getInfCrctInvSelected(): string{
    return this._form.controls[ 'infCrctInvSelected' ].value;
  }

  private setInfCrctInvSelected( nmInf: string ){
    this._form.patchValue( { infCrctInvSelected: nmInf } );
  }

  private getInfCrctInvPerdcSelected(): number{
    return this._form.controls[ 'infCrctInvPerdcSelected' ].value;
  }

  private setInfCrctInvPerdcSelected( perdc: number ){
    this._form.patchValue( { infCrctInvPerdcSelected: perdc } );
  }

  private getInfCrctInvTipViewSelected(): TipViewInfCrctInv{
    return this._form.controls[ 'infCrctInvTipViewSelected' ].value;
  }

  private setInfCrctInvTipViewSelected( tipView: TipViewInfCrctInv ){
    this._form.patchValue( { infCrctInvTipViewSelected: tipView } );
  }

  public getCdGrCrctInvSelected(): number{
    return this._cdGrCrctInvSelected;
    //return this._form.controls[ 'cdGrCrctInvSelected' ].value;
  }

  private setCdGrCrctInvSelected( cdGrCrctInv: number ): void{
    //console.log( 'setCdGrCrctInvSelected( ' + cdGrCrctInv + ' )' );

    //console.log( 'value atual: ' + this._cdGrCrctInvSelected );

    this._cdGrCrctInvSelected = cdGrCrctInv != null ? Number( cdGrCrctInv ) : null;
    //this._form.patchValue( { cdGrCrctInvSelected: cdGrCrctInv } );
  }

  onSubmit(){
  }

  protected detail( cdInv: number ){
    //console.log( 'detail( ' + cdInv + ' )...' );

    if( cdInv && cdInv != null ){
      const _this = this;

      this.detailCrctInv( cdInv )
        .then( ticker => {
          if( ticker != null ){
            //console.log( 'ticker != null' );

            const itemGr = new ItemGrupoCrctInvAcao();
            itemGr.crctInv = ticker;

            _this.addCrctInvList( [ itemGr ] );
          }
          else{
            //console.log( 'ticker is null.' );
          }
        } );
    }
  }

  private detailCrctInv( cdInv: number ): Promise< CrctInv >{
    //console.log( 'detailCrctInv( ' + cdInv + ' )...' );

    const _this = this;

    const p = new Promise< CrctInv >( function( resolve, reject ){
      if( cdInv && cdInv != null ){
        _this.invService.detail( cdInv )
          .then( ticker => {
            if( ticker != null ){
              resolve( ticker.crctInv );
            }
            else{
              resolve( null );
            }
          } )
          .catch( error => {
            reject( error );
          } );
      }
      else{
        resolve( null );
      }
    } );

    return p;
  }

  public isExistGrCrctInvSelected(): boolean{
    const cdGrCrctInvSelected = this.getCdGrCrctInvSelected();
    const isSelected = ( cdGrCrctInvSelected && cdGrCrctInvSelected != null ) ? true : false;

    return isSelected;
  }

  public isExistTickerToChart(): boolean{
    const qtCrctInv = this._tickerListDataSource != null && this._tickerListDataSource.data != null ? this._tickerListDataSource.data.length : 0;

    return qtCrctInv > 0;
  }

  public isShowChart(): boolean{
    //console.log( 'isShowChart()...' );

    const qtCrctInv    = this.managerLifeCycleCrctInv.getQtControlEnabled();
    const qtInfCrctInv = this.getQtInfCrctInv();

    const isState = qtCrctInv > 0 && qtInfCrctInv > 0;

    //console.log( 'isShowChart? ' + isState );

    return isState;
  }


  public isAllowedAddGrCrctInv(): boolean{
    return this.authenticationService.getSessionService().isUserLogged();
  }


  private refreshAllInfCrctInvOfAllTickers(){
    this.managerLifeCycleCrctInv.setDtFromValues( this.getDtCotcFromSelected() );
    this.managerLifeCycleCrctInv.setDtEndValues( this.getDtCotcEndSelected() );
    this.managerLifeCycleCrctInv.setQtLimitValues( 1000 );

    //console.log( 'refreshAllInfCrctInvOfAllTickers - managerLifeCycleCrctInv.refreshValues()...' );
    const promise = this.managerLifeCycleCrctInv.refreshValues( null, null );

    this.managerResponseToRefreshInfCrctInv( promise );
  }

  /**
   * Atualiza as informacoes dos tickers especificados.
   */
  private refreshAllInfCrctInvOfTickers( controlList: ControlLifeCycleCrctInv[] ){
    const promise = this.managerLifeCycleCrctInv.refreshValues( controlList, null );

    this.managerResponseToRefreshInfCrctInv( promise );
  }

  private refreshInfCrctInvOfAllTickers( infCrctInv: InfCrctInvInterface< any > ){
    const infCrctInvList: InfCrctInvInterface< any >[] = [];
    infCrctInvList.push( infCrctInv );

    const promise = this.managerLifeCycleCrctInv.refreshValues( null, infCrctInvList );

    this.managerResponseToRefreshInfCrctInv( promise );
  }

  private managerResponseToRefreshInfCrctInv( promise: Promise< any > ): void{
    if( promise != null ){
      //Passa a escutar o observable.
      promise
        .then( response => {
          this.updateChartNow();
        } )
        .catch( error => {
          console.log( error );
          window.alert( 'Erro na atualização das informações do gráfico: ' + error );
        } );
    }
  } 

  private updateChartNow(){
    //console.log( 'updateChartNow()...' );

    //Obtem a lista de ativos que serao apresentados no grafico.
    const controlList  = this.managerLifeCycleCrctInv != null ? this.managerLifeCycleCrctInv.getControlList() : null;

    const qtCrctInv    = controlList != null ? controlList.length : 0         //Quantidade de tickers ativos.
    const qtInfCrctInv = this.getQtInfCrctInv();                              //Quantidade de informacoes que cada ticker deve possuir.
    const qtSeries     = qtCrctInv * qtInfCrctInv;                            //Quantidade de series de dados a serem apresentados no grafico.

    //console.log( 'qt de CrctInv/Inf/Series: ' + qtCrctInv + '/' + qtInfCrctInv + '/' + qtSeries );

    let titleOfChart      = '';   //O titulo do grafico.
    const columnsOfChart  = [];   //
    const seriesOfChart   = {};   //Os tipos (como line, bars e candlesticks), lineDashStyle e targetAxisIndex de cada serie do grafico a ser apresentada.
    let dataValuesOfChart = [];   //Os dados a serem apresentados no grafico.
    let vAxesOfChart      = {};   //Definicao dos eixos verticais do grafico.

    let vAxesValueMin     = [];   //O valor minimo de cada eixo.
    let vAxesValueMax     = [];   //O valor maximo de cada eixo.

    let valueInicialOfCrctInf: number[][] = [];   //O valor inicial de cada ticker/informacao.

    //console.log( 'qtCrctInv/Inf: ' + qtCrctInv + '/' + qtInfCrctInv );

    if( qtCrctInv > 0 && qtInfCrctInv > 0 ){
      //console.log( 'Ha pelo menos um ticker e uma informacao.' );

      let isContinue = true;
      let dtAtual: Date = null;

      //Antes de iniciar, reseta todos ponteiros para o valor maximo, pois serao processados de tras para frente.
      this.managerLifeCycleCrctInv.resetAllPointerToMax();

      const colorList = [];
      let isFirstIteration = true;

      //Itera por cada uma das datas, da mais antiga para a mais atual.
      //Para cada data, obtem todas as informacoes de cada um dos tickers.
      while( isContinue === true ){
        //console.log( '------------------' );

        //Obtem a menor data considerando as informacoes do ponteiro atual.
        let dateRef   : Date   = this.managerLifeCycleCrctInv.getMinDateOfAllPointers();
        const dtRefStr: string = dateRef != null ? this.datepipe.transform( dateRef, 'dd/MM/yyyy', '+0000' ) : null;

        //console.log( 'Data de Ref.: ' + dateRef + '; ' + dtRefStr );

        let isAllInfAreNull = true;         //Inicialmente pressupoe que todas informacoes serao NULL.
        let dataValuesOfChartThisDate = [];

        //Tomando essa data como base, itera por cada uma das informacoes de cada um dos controls.
        controlList.forEach( ( control, indexOfControl ) => {
          //console.log( 'control: ' + control.getCrctInv().nmInv );

          //Na primeira iteracao, realiza algumas definicoes.
          if( isFirstIteration === true ){
            //console.log( 'isFirstIteration === true' );

            //Define a cor de cada ticker.
            const colorRgb = this.getColorRgbToChart( qtCrctInv, qtInfCrctInv, indexOfControl, 0 );
            colorList.push( colorRgb );

            //Define o titulo do grafico.
            titleOfChart += ( ( indexOfControl === 0 ? ' ' : ', ' ) + control.getCrctInv().nmInv );

            if( indexOfControl === 0 ){
              columnsOfChart.push( 'Data' );
            }

            valueInicialOfCrctInf.push( new Array( qtInfCrctInv ).fill(0) );
          }

          if( indexOfControl === 0 ){
            dataValuesOfChartThisDate.push( dateRef );
          }

          const infList = control.getInfCrctInvList();

          //console.log( 'infList: ' + ( infList != null ? infList.length : 0 ) );

          //Itera por cada uma das informacoes associadas ao ticker.
          infList.forEach( ( inf, indexOfInf ) => {
            const indexOfSerie = ( indexOfControl * qtInfCrctInv ) + indexOfInf;
            const value = inf.getValueOfPointerIfEqualDateRefAndMovePointerToPrev( dateRef );

            //console.log( 'Inf.: ' + ( value != null ? ( Object.keys( value ) + '; ' + Object.values( value ) ) : 'is null' ) );

            if( isAllInfAreNull === true && value != null ){
              isAllInfAreNull = false;
            }

            let titleOfAxle = '';

            switch( inf.getTipInfCrctInv() ){
              case TipInfCrctInv.COTC:
                //console.log( 'TipInfCrctInv.COTC' );

                const tipViewInfCrctInv = inf.getTipViewSelectedInfCrctInv();

                const isCandle       = ( tipViewInfCrctInv !== null && tipViewInfCrctInv instanceof TipViewInfCrctInv && tipViewInfCrctInv.isEqual( TipViewInfCrctInv.CANDLE          ) );
                const isVolNegc      = ( tipViewInfCrctInv !== null && tipViewInfCrctInv instanceof TipViewInfCrctInv && tipViewInfCrctInv.isEqual( TipViewInfCrctInv.VOL_NEGC        ) );
                const isPercent      = ( tipViewInfCrctInv !== null && tipViewInfCrctInv instanceof TipViewInfCrctInv && tipViewInfCrctInv.isEqual( TipViewInfCrctInv.PERCENT         ) );
                const isTxPercRentAa = ( tipViewInfCrctInv !== null && tipViewInfCrctInv instanceof TipViewInfCrctInv && tipViewInfCrctInv.isEqual( TipViewInfCrctInv.TX_PERC_RENT_AA ) );

                if( isFirstIteration === true ){
                  //console.log( 'First Iteration is true.' );
                  let nmColumns;
                  let typeOfSerie;
                  let lineDashStyleOfSerie;

                  //Define as columns do grafico.
                  if( isCandle === true ){
                    columnsOfChart.push( 'Mín', 'Abert.', 'Máx', 'Fech' );
                    typeOfSerie = 'candlesticks';
                    lineDashStyleOfSerie = [indexOfInf, indexOfInf/2];
                    titleOfAxle = 'R$';
                  }
                  else{
                    if( isVolNegc === true ){
                      columnsOfChart.push( 'Vol. Neg.' );
                      typeOfSerie = 'bars';
                      lineDashStyleOfSerie = [1, 0];
                      titleOfAxle = 'Vol.';
                    }
                    else{
                      if( isPercent === true ){
                        columnsOfChart.push( '%' );
                        typeOfSerie = 'line';
                        lineDashStyleOfSerie = [indexOfInf, indexOfInf/2];
                        titleOfAxle = '%';
                      }
                      else{
                        if( isTxPercRentAa === true ){
                          columnsOfChart.push( '%/Ano' );
                          typeOfSerie = 'line';
                          lineDashStyleOfSerie = [indexOfInf, indexOfInf/2];
                          titleOfAxle = '% / Ano';
                        }
                        else{
                          columnsOfChart.push( 'Preço Fech.' );
                          typeOfSerie = 'line';
                          lineDashStyleOfSerie = [1*indexOfInf, 1.6*indexOfInf];
                          titleOfAxle = 'R$';
                        }
                      }
                    }
                  }

                  columnsOfChart.push( { role: 'style' } );
                  columnsOfChart.push( { type: 'string', role: 'tooltip', 'p': {'html': true} } );
                  seriesOfChart[ ( indexOfSerie ).toString() ] = { type: typeOfSerie, lineDashStyle: lineDashStyleOfSerie, targetAxisIndex: indexOfInf };
                }

                const valueCotc: HstCotcAcao = value != null ? value as HstCotcAcao : null;

                if( value == null ){
                  if( isCandle === true ){
                    dataValuesOfChartThisDate.push( null, null, null, null );
                  }
                  else{
                    dataValuesOfChartThisDate.push( null );
                  }

                  dataValuesOfChartThisDate.push( null );
                  dataValuesOfChartThisDate.push( null );
                }
                else{
                  let valueMin;
                  let valueMax;
                  let valueViewed;

                  if( isCandle === true  ){
                    valueMin    = valueCotc.prcMin;
                    valueMax    = valueCotc.prcMax;
                    valueViewed = valueCotc.prcFech;

                    dataValuesOfChartThisDate.push( valueMin, valueCotc.prcAbert, valueCotc.prcFech, valueMax );
                    dataValuesOfChartThisDate.push( 'stroke-opacity: 0.4;' );
                  }
                  else{
                    const styleCotc = 'stroke-opacity: 0.4; color: ' + colorList[ indexOfControl ] + ';';
                    const colorOfStyle = ( valueCotc === null || valueCotc.varPrcFech === null || valueCotc.varPrcFech >= 0 ) ? 'green' : 'red';
                    const styleVol  = 'color: ' + colorOfStyle + '; opacity: 0.4; stroke-opacity: 0.6; stroke-width: 2';

                    valueMax = this.getValueViewCotcToChart( inf, valueCotc );

                    if( isFirstIteration === true ){
                      valueInicialOfCrctInf[ indexOfControl ][ indexOfInf ] = valueMax;
                    }

                    if( isVolNegc === true ){
                      valueMin = 0;
                      dataValuesOfChartThisDate.push( valueMax * 0.20 );
                    }
                    else{
                      if( isPercent === true ){
                        valueMax = ( (valueMax - valueInicialOfCrctInf[indexOfControl][ indexOfInf ])/valueInicialOfCrctInf[indexOfControl][ indexOfInf ]*100 );
                        valueMin = valueMax;
                        dataValuesOfChartThisDate.push( valueMax );
                      }
                      else{
                        if( isTxPercRentAa === true ){
                          valueMin = valueMax;
                          dataValuesOfChartThisDate.push( valueMax );
                        }
                        else{
                          valueMin = valueMax;
                          dataValuesOfChartThisDate.push( valueMax );
                        }
                      }
                    }

                    dataValuesOfChartThisDate.push( isVolNegc === true ? styleVol : styleCotc );
                    valueViewed = valueMax;
                  }

                  const tooltipInfoCotc = this.getTooltipOfInfCotc( control.getCrctInv(), valueCotc, valueViewed, inf.getTipViewSelectedInfCrctInv() );
                  dataValuesOfChartThisDate.push( tooltipInfoCotc );

                  vAxesValueMin[ indexOfInf ] = isFirstIteration === true ? valueMin : Math.min( vAxesValueMin[ indexOfInf ], valueMin );
                  vAxesValueMax[ indexOfInf ] = isFirstIteration === true ? valueMax : Math.max( vAxesValueMax[ indexOfInf ], valueMax );
                }

                break;

              case TipInfCrctInv.IND_TCN:
                //console.log( 'TipInfCrctInv.IND_TCN' );

                if( isFirstIteration === true ){
                  const valueIndTcn: HstIndTcn = value as HstIndTcn;
                  const infIndTcn: InfCrctInvIndTcn = inf as InfCrctInvIndTcn;

                  const isRsiPeakConc = infIndTcn.getIndTcn().sgIndTcn.toUpperCase().trim() === 'PEAK_CONC' && infIndTcn.getIndTcn().snlTcn.sgSnlTcn.toUpperCase().trim() === 'RSI'; 

                  const serieType = isRsiPeakConc === true ? 'scatter' : 'line';

                  //console.log( 'First Iteration is true.' );
                  //console.log( 'sgIndTcn/isRsiPeakConc/serieType: ' + ( valueIndTcn != null && valueIndTcn.indTcn != null ? valueIndTcn.indTcn.sgIndTcn : 'null' ) + '/' + isRsiPeakConc + '/' + serieType );

                  //Define as columns do grafico.
                  columnsOfChart.push( inf.getNmInfCrctInv() );
                  columnsOfChart.push( { type: 'string', role: 'style' } );
                  columnsOfChart.push( { type: 'string', role: 'tooltip', 'p': {'html': true} } );

                  let serieOfChart = { type: serieType, lineDashStyle: [1*indexOfInf, 1.6*indexOfInf], targetAxisIndex: indexOfInf };

                  if( isRsiPeakConc === true ){
                    //serieOfChart.pointShape = { type: 'circle', sides: 5, dent: 0.05 };
                  }

                  seriesOfChart[ ( indexOfSerie ).toString() ] = serieOfChart;
                  titleOfAxle = '';
                }

                if( value == null ){
                  const tooltipInfoIndTcn = this.getTooltipOfInfIndTcn( control.getCrctInv(), inf, null );

                  dataValuesOfChartThisDate.push( 0 );
                  dataValuesOfChartThisDate.push( null );
                  dataValuesOfChartThisDate.push( tooltipInfoIndTcn );
                }
                else{
                  const valueIndTcn: HstIndTcn = value as HstIndTcn;
                  const isRsiPeakConc = ( valueIndTcn != null && valueIndTcn.indTcn != null && valueIndTcn.indTcn.snlTcn.sgSnlTcn === 'RSI' && valueIndTcn.indTcn.sgIndTcn === 'PEAK_CONC' ) ? true : false;

                  let styleIndTcn = 'stroke-opacity: 0.4; color: ' + colorList[ indexOfControl ] + '; ';

                  if( isRsiPeakConc === true ){
                    let shapeRotation = valueIndTcn.vlIndTcn != null && valueIndTcn.vlIndTcn < 0 ? '180' : '0';
                    styleIndTcn = 'point { shape-type: triangle; fill-color: ' + colorList[ indexOfControl ] + '; shape-rotation: ' + shapeRotation + '; } ';
                  }

                  const tooltipInfoIndTcn = this.getTooltipOfInfIndTcn( control.getCrctInv(), inf, valueIndTcn );

                  dataValuesOfChartThisDate.push( valueIndTcn.vlIndTcn );
                  dataValuesOfChartThisDate.push( styleIndTcn );
                  dataValuesOfChartThisDate.push( tooltipInfoIndTcn );

                  vAxesValueMin[ indexOfInf ] = isFirstIteration === true ? valueIndTcn.vlIndTcn : Math.min( vAxesValueMin[ indexOfInf ], valueIndTcn.vlIndTcn );
                  vAxesValueMax[ indexOfInf ] = isFirstIteration === true ? valueIndTcn.vlIndTcn : Math.max( vAxesValueMax[ indexOfInf ], valueIndTcn.vlIndTcn );

                  if( isFirstIteration === true ){
                    valueInicialOfCrctInf[indexOfControl][ indexOfInf ] = valueIndTcn.vlIndTcn;
                  }
                }

                break;
              
              default:
                break;
            }

            if( isFirstIteration === true ){
              const textPositionOfAxle = ( indexOfSerie === 0 ? 'left' : ( indexOfSerie === 1 ? 'right' : 'none' ) );
              //console.log( 'textPositionOfAxle: ' + indexOfSerie + '-' + textPositionOfAxle );

              //Define os eixos do grafico.
              vAxesOfChart[ ( indexOfSerie ).toString() ] = { logScale    : false, 
                                                              title       : ( indexOfSerie <= 1 ? titleOfAxle : '' ), 
                                                              format      : 'decimal', 
                                                              viewWindow  : { min: 0, max: 0 }, 
                                                              textPosition: textPositionOfAxle, 
                                                              gridlines   : { count: (indexOfSerie === 0 ? -1 : 0) } };
            }
          } );
        } );

        dataValuesOfChart.push( dataValuesOfChartThisDate );
        isFirstIteration = false;
        isContinue = !isAllInfAreNull;  //Se todas informacoes tiverem retornado NULL, entao para de iterar.
      }
    }

    //console.log( 'qtInfCrctInv: ' + qtInfCrctInv );

    //Altera os valores minimo e maximo de cada eixo.
    for( let indexAxle = 0; indexAxle < qtInfCrctInv && qtSeries > 0; indexAxle++ ){
      const vAxleValueRange = vAxesValueMax[ indexAxle ] - vAxesValueMin[ indexAxle ];

      const vAxleValueMax = Math.ceil( vAxesValueMax[ indexAxle ] + vAxleValueRange*0.03 );
      const vAxleValueMin = Math.floor(  vAxesValueMin[ indexAxle ] - vAxleValueRange*0.03 );

      //console.log( 'vAxleValue Min/Max: ' + vAxleValueMin + ' / ' + vAxleValueMax );

      if( vAxesOfChart[ indexAxle.toString() ] && Number.isNaN( vAxleValueMin ) == false && Number.isNaN( vAxleValueMax ) == false ){
        vAxesOfChart[ indexAxle.toString() ].viewWindow.min = vAxleValueMin;
        vAxesOfChart[ indexAxle.toString() ].viewWindow.max = vAxleValueMax;
        //console.log( 'vAxleValueMin/Max: ' + vAxleValueMin + '/' + vAxleValueMax );
      }
    }

    //Após definidos os limites minimo e maximo de cada eixo, identifica InfCrctInv que sejam do mesmo Snl/IndTcn, para iguala-los.
    const infCrctInvList = this.managerLifeCycleCrctInv.getInfCrctInvList();
    const DIFF_MAX_BETWEEN_INF_CRCT_INV: number = 0.70;

    for( let indexInf = 1; indexInf < qtInfCrctInv && qtSeries > 0;  ){
      let isInfCrctInvPrevChanged = false;
      let indexInfPrev = indexInf - 1;

      if( vAxesOfChart[ indexInf.toString() ] ){
        const infCrctInvIterate: InfCrctInvInterface< any > = infCrctInvList[ indexInf ];
        const vAxleValueMinIterate = vAxesOfChart[ indexInf.toString() ].viewWindow.min;
        const vAxleValueMaxIterate = vAxesOfChart[ indexInf.toString() ].viewWindow.max;

        //Procura InfCrctInv anteriores que tenham mesmo nome e tipView.
        if( Number.isNaN( vAxleValueMinIterate ) == false && Number.isNaN( vAxleValueMaxIterate ) == false ){
          for( ; indexInfPrev >= 0 && isInfCrctInvPrevChanged === false;  ){
            const infCrctInvPrev: InfCrctInvInterface< any > = infCrctInvList[ indexInfPrev ];

            if( vAxesOfChart[ indexInfPrev.toString() ] ){
              const isSameNmAndTipViewOfInfCrctInvIterateAndPrev = infCrctInvIterate.isSameNmAndTipView( infCrctInvPrev );
              //console.log( 'Comparando ' + indexInf + ' (' + infCrctInvIterate.getNmInfCrctInv() + '/' + infCrctInvIterate.getTipViewSelectedInfCrctInv() + ') com ' + indexInfPrev + ' (' + infCrctInvPrev.getNmInfCrctInv() + '/' + infCrctInvPrev.getTipViewSelectedInfCrctInv() + '): ' + isSameNmAndTipViewOfInfCrctInvIterateAndPrev );

              const vAxleValueMinPrev = vAxesOfChart[ indexInfPrev.toString() ].viewWindow.min;
              const vAxleValueMaxPrev = vAxesOfChart[ indexInfPrev.toString() ].viewWindow.max;

              let isAxleValuesMinClose = false;
              let isAxleValuesMaxClose = false;

              let diffValueMinIterateAndPrev = null;
              let diffValueMaxIterateAndPrev = null;

              if( Number.isNaN( vAxleValueMinPrev ) == false ){
                const biggerValue  = Math.max( vAxleValueMinIterate, vAxleValueMinPrev );
                const smallerValue = Math.min( vAxleValueMinIterate, vAxleValueMinPrev );

                diffValueMinIterateAndPrev = Math.abs( ( biggerValue - smallerValue ) / smallerValue );

                if( isSameNmAndTipViewOfInfCrctInvIterateAndPrev || diffValueMinIterateAndPrev < DIFF_MAX_BETWEEN_INF_CRCT_INV ){
                  if( vAxleValueMinIterate !== vAxleValueMinPrev ){
                    isAxleValuesMinClose = true;
                  }
                }
              }

              if( Number.isNaN( vAxleValueMaxPrev ) == false ){
                const biggerValue  = Math.max( vAxleValueMaxIterate, vAxleValueMaxPrev );
                const smallerValue = Math.min( vAxleValueMaxIterate, vAxleValueMaxPrev );

                diffValueMaxIterateAndPrev = Math.abs( ( biggerValue - smallerValue ) / smallerValue );

                if( isSameNmAndTipViewOfInfCrctInvIterateAndPrev || diffValueMaxIterateAndPrev < DIFF_MAX_BETWEEN_INF_CRCT_INV ){
                  if( vAxleValueMaxIterate !== vAxleValueMaxPrev ){
                    isAxleValuesMaxClose = true;
                  }
                }
              }

              //Se os valores MIN e MAX de cada InfCrctInv forem proximos, entao altera-os.
              if( ( isAxleValuesMinClose === true && isAxleValuesMaxClose === true ) || 
                  ( isSameNmAndTipViewOfInfCrctInvIterateAndPrev === true && ( vAxleValueMinIterate !== vAxleValueMinPrev || vAxleValueMaxIterate !== vAxleValueMaxPrev ) ) ||
                  ( vAxleValueMinIterate === vAxleValueMinPrev && vAxleValueMaxIterate === vAxleValueMaxPrev && seriesOfChart[ indexInf.toString() ].targetAxisIndex !== seriesOfChart[ indexInfPrev.toString() ].targetAxisIndex ) ){
                //console.log( 'Ajustando min/max e nr. do eixo ' + indexInfPrev + ' em relacao ao eixo ' + indexInf + '. Diffs min/max: ' + diffValueMinIterateAndPrev + '/' + diffValueMaxIterateAndPrev );
                //console.log( 'Min Atual/Prev: ' + vAxleValueMinIterate + '/' + vAxleValueMinPrev + ' - Max Atual/Prev: ' + vAxleValueMaxIterate + '/' + vAxleValueMaxPrev );
                isInfCrctInvPrevChanged = true;

                const valueMin = Math.min( vAxleValueMinIterate, vAxleValueMinPrev );
                vAxesOfChart[ indexInfPrev.toString() ].viewWindow.min = valueMin;
                vAxesOfChart[ indexInf.toString()     ].viewWindow.min = valueMin;

                const valueMax = Math.max( vAxleValueMaxIterate, vAxleValueMaxPrev );
                vAxesOfChart[ indexInfPrev.toString() ].viewWindow.max = valueMax;
                vAxesOfChart[ indexInf.toString()     ].viewWindow.max = valueMax;

                //Altera o numero do eixo da InfCrctInv para que ambos fiquem iguais.
                seriesOfChart[ indexInf.toString()     ].targetAxisIndex = seriesOfChart[ indexInfPrev.toString()     ].targetAxisIndex;
              }
            }

            indexInfPrev = ( isInfCrctInvPrevChanged == false ) ? ( indexInfPrev - 1 ) : ( indexInfPrev );
          }
        }
      }

      //Se alguma InfCrctInv anterior sofreu alteração, retorna a ela, senao, incrementa o indexador.
      indexInf = isInfCrctInvPrevChanged === false ? indexInf + 1 : indexInfPrev;
    }


    for( let indexInfAxle = 0; indexInfAxle < qtInfCrctInv && qtSeries > 0; indexInfAxle++ ){
      //Obtem o numero do eixo desta InfCrctInv.
      const indexAxleIterate = seriesOfChart[ indexInfAxle.toString() ].targetAxisIndex;

      //Se o numero do eixo desta InfCrctInv eh menor que o valor deste index pelo qual se esta iterando, entao este eixo nao esta sendo utilizado.
      if( indexAxleIterate < indexInfAxle ){
        let indexAxleNextOld = null;

        //Itera pelas proximas InfCrctInv, tentando encontrar alguma que possa ter o numero do eixo alterado.
        for( let indexInfNextAxle = indexInfAxle + 1; indexInfNextAxle < qtInfCrctInv; indexInfNextAxle++ ){
          //Obtem o numero do eixo desta InfCrctInv.
          const indexAxleNextIterate = seriesOfChart[ indexInfNextAxle.toString() ].targetAxisIndex;

          if( ( indexAxleNextIterate > indexAxleIterate ) && 
              ( indexAxleNextIterate > indexInfAxle     ) && 
              ( indexAxleNextOld === null || indexAxleNextIterate === indexAxleNextOld ) ){
            indexAxleNextOld = indexAxleNextIterate;

            seriesOfChart[ indexInfNextAxle.toString() ].targetAxisIndex = indexInfAxle;
            vAxesOfChart[ indexInfAxle.toString() ].viewWindow.min   = vAxesOfChart[ indexAxleNextOld.toString() ].viewWindow.min;
            vAxesOfChart[ indexInfAxle.toString() ].viewWindow.max   = vAxesOfChart[ indexAxleNextOld.toString() ].viewWindow.max;
          }
        }
      }
    }


    //console.log( '------------------' );

    /*
    //Mostra os limites minimo/maximo de cada eixo.
    for( let indexAxle = 0; indexAxle < qtInfCrctInv && qtSeries > 0; indexAxle++ ){
      if( vAxesOfChart[ indexAxle.toString() ] ){
        const vAxleValueMin = vAxesOfChart[ indexAxle.toString() ].viewWindow.min;
        const vAxleValueMax = vAxesOfChart[ indexAxle.toString() ].viewWindow.max;

        console.log( indexAxle + ': ' + vAxleValueMin + '/' + vAxleValueMax );
      }
      else{
        console.log( indexAxle + ': Eixo sem valores definidos.' );
      }
    }
    */

    /*
    Object.values( vAxesOfChart ).forEach( vAxle => {
      console.log( 'vAxle: ' + Object.keys(vAxle) + ';' + Object.values(vAxle) );
    } );
    */

    //console.log( 'columnsOfChart..: ' + columnsOfChart );

    /*
    Object.values( seriesOfChart ).forEach( serie => {
      console.log( 'serie...: ' + Object.keys( serie ) + '; ' + Object.values( serie ) );
    } );
    */

    //console.log( 'dataValuesOfChart: ' + dataValuesOfChart );

    //Calcula numero de meses.
    const qtMonths = this.calculateMonthDiff( this.getDtCotcFromSelected(), this.getDtCotcEndSelected() );
    //console.log( 'qtMonths: ' + qtMonths );

    this._cotcChart.title   = titleOfChart;
    this._cotcChart.data    = dataValuesOfChart;
    this._cotcChart.options.hAxis.gridlines.count = qtMonths;
    this._cotcChart.options = { legend          : this._cotcChart.options.legend,
                                crosshair       : { trigger: 'both' },
                                titlePosition   : this._cotcChart.options.titlePosition,
                                titleTextStyle  : this._cotcChart.options.titleTextStyle,
                                candlestick     : this._cotcChart.options.candlestick,
                                width           : this._cotcChart.options.width,
                                height          : this._cotcChart.options.height,
                                isStacked       : this._cotcChart.options.isStacked,
                                tooltip         : this._cotcChart.options.tooltip,
                                interpolateNulls: this._cotcChart.options.interpolateNulls,
                                hAxis           : this._cotcChart.options.hAxis,
                                vAxes           : vAxesOfChart,
                                seriesType      : this._cotcChart.options.seriesType,
                                series          : seriesOfChart,
                                chartArea       : this._cotcChart.options.chartArea
                              };
    this._cotcChart.columns = columnsOfChart;

    //console.log( 'updateChartNow().' );
  }

  private calculateMonthDiff( dateMajor: Date, dateMinor: Date ): number{
    let months = 0;

    if( dateMajor != null && dateMinor != null ){
      months = (dateMinor.getFullYear() - dateMajor.getFullYear()) * 12;
      months -= dateMajor.getMonth();
      months += dateMinor.getMonth();
    }

    return months;
  }

  private getTooltipOfInfCotc( ticker: CrctInv, cotc: HstCotcAcao, valueViewed: number, tipViewInfCrctInv: TipViewInfCrctInv ){
    const cotcDtStr     : string = cotc        != null ? ( this.datepipe.transform( cotc.dtCotc, 'dd/MM/yyyy', '+0000' ) ) : '';
    let   valueViewedStr: string = valueViewed != null ? this.decimalPipe.transform( valueViewed, '1.2-2' ) : '';
    let   colorOfValueViewed: string = valueViewed != null && valueViewed >= 0 ? 'blue' : 'red';

    if( tipViewInfCrctInv instanceof TipViewInfCrctInv && tipViewInfCrctInv.isEqual( TipViewInfCrctInv.PERCENT ) ){
      valueViewedStr += ' %';
    }

    let tooltipStr = '<div>' +
                     '  <b>' + ticker.nmInv + '</b>&nbsp;&nbsp;-&nbsp;&nbsp;' + cotcDtStr + '<br/>' +
                     '  <span style="font-weight:bold; color:' + colorOfValueViewed + '">' + valueViewedStr + '</span>' +
                     '</div>';

    if( cotc != null ){
      const prcAbertStr   = cotc.prcAbert   != null ? this.decimalPipe.transform( cotc.prcAbert  , '1.2-2' ) : '';
      const prcMinStr     = cotc.prcMin     != null ? this.decimalPipe.transform( cotc.prcMin    , '1.2-2' ) : '';
      const prcMaxStr     = cotc.prcMax     != null ? this.decimalPipe.transform( cotc.prcMax    , '1.2-2' ) : '';
      const prcFechStr    = cotc.prcFech    != null ? this.decimalPipe.transform( cotc.prcFech   , '1.2-2' ) : '';
      const volNegcStr    = cotc.volNegc    != null ? this.decimalPipe.transform( cotc.volNegc   , '1.0-0' ) : '';
      const varPrcFech    = cotc.varPrcFech != null ? cotc.varPrcFech : 0;
      const varPrcFechStr = cotc.varPrcFech != null ? this.decimalPipe.transform( cotc.varPrcFech, '1.2-2' ) : '';

      tooltipStr += '<div>' +
                    '  Preço Abert.: '    + prcAbertStr + '<br/>' +
                    '  Preço Mín./Máx.: ' + prcMinStr   + ' - ' + prcMaxStr + '<br/>' +
                    '  Preço Fech.: '     + prcFechStr  + '<br/>' +
                    '  Volume de Neg.: '  + volNegcStr  + '<br/>' +
                    '  Variação: <span style="color:' + (varPrcFech < 0 ? 'red' : 'blue') + '">' + varPrcFechStr + '</span> %' +
                    '</div>';
    }

    return tooltipStr;
  }

  private getTooltipOfInfIndTcn( ticker: CrctInv, infCrctInv: InfCrctInvInterface< any >, indTcn: HstIndTcn ){
    const indTcnDtStr: string = indTcn != null ? ( this.datepipe.transform( indTcn.dtIndTcn, 'dd/MM/yyyy', '+0000' ) ) : infCrctInv.getNmInfCrctInvViewed();

    let tooltipStr =  '<div>' +
                      ' <b>' + ticker.nmInv + '</b>&nbsp;&nbsp;-&nbsp;&nbsp;' + indTcnDtStr +
                      '</div>';

    if( indTcn != null ){
      const sgSnlTcn = indTcn.indTcn.snlTcn.sgSnlTcn;
      const sgIndTcn = indTcn.indTcn.sgIndTcn;

      let nmIndTcn = sgSnlTcn != null ? sgSnlTcn : '';

      if( sgIndTcn != null ){
        nmIndTcn += ( sgSnlTcn != null && sgSnlTcn !== sgIndTcn ) ? ( '/' + sgIndTcn ) : '';
      }

      if( indTcn.perdc != null ){
        if( indTcn.perdc.perdcIndTcn != null ){
          nmIndTcn += ' (' + indTcn.perdc.perdcIndTcn + ')';
        }
      }

      let vlIndTcnStr = '';

      if( indTcn.vlIndTcn != null ){
        vlIndTcnStr = this.decimalPipe.transform( indTcn.vlIndTcn, '1.2-2' );
      }

      tooltipStr  +=  '<div>' +
                        nmIndTcn + ': ' + vlIndTcnStr + '<br/>' +
                      '</div>';
    }
    else{
      tooltipStr  +=  '<div>' +
                        'Não há valor associado a este indicador.' + '<br/>' +
                      '</div>';
    }

    return tooltipStr;
  }

  public getTooltipOfControl( control: ControlLifeCycleCrctInv ){
    const crctInv: CrctInv = control.getCrctInv();
    const nmInv: string = crctInv != null ? crctInv.nmInv : null;
    const tipInv: TipInv = crctInv != null ? crctInv.tipInv : null;
    const nmTipInv: string = tipInv != null ? tipInv.nmTipInv : null;
    const crctInvAcao: CrctInvAcao = crctInv != null ? crctInv.crctInvAcao : null;
    const nmPregao: string = crctInvAcao != null ? crctInvAcao.nmPregao : null;
    const espcAcao: EspcAcao = crctInvAcao != null ? crctInvAcao.espcAcao : null;
    const nmEspcAcao: string = espcAcao != null ? espcAcao.nmEspcAcao : null;
    const empr: Empr = crctInv != null ? crctInv.empr : null;
    const nmEmpr: string = empr != null ? ( ( empr.nmAbrvEmpr != null && empr.nmAbrvEmpr.trim().length > 0 ) ? empr.nmAbrvEmpr.trim() : empr.nmEmpr ) : '';
    const tipSitEmpr: TipSitEmpr = empr != null ? empr.tipSitEmpr : null;
    const nmTipSitEmpr: string = tipSitEmpr != null ? tipSitEmpr.nmTipSitEmpr : null;
    const segmEcnmEmpr: SegmEcnm = empr != null ? empr.segmEcnm : null;
    const nmSegmEcnmEmpr: string = segmEcnmEmpr != null ? segmEcnmEmpr.nmSegmEcnm : null;
    const subSetorEcnmEmpr: SubSetorEcnm = segmEcnmEmpr != null ? segmEcnmEmpr.subSetorEcnm : null;
    const nmSubSetorEcnmEmpr: string = subSetorEcnmEmpr != null ? subSetorEcnmEmpr.nmSubSetorEcnm : null;
    const setorEcnmEmpr: SetorEcnm = subSetorEcnmEmpr != null ? subSetorEcnmEmpr.setorEcnm : null;
    const nmSetorEcnmEmpr: string = setorEcnmEmpr != null ? setorEcnmEmpr.nmSetorEcnm : null;
    const volMedNegc: number = crctInvAcao != null ? crctInvAcao.volNegcMed : null;
    const volMedNegcStr: string = volMedNegc != null ? this.shortNumber.transform( volMedNegc, '' ) : '';

    let tooltipStr = nmInv;
    tooltipStr += ( nmTipInv   != null ? ( ' | ' + nmTipInv ) : '' );
    tooltipStr += ( nmEspcAcao != null ? ( ' ' + nmEspcAcao ) : '' );
    tooltipStr += ( nmPregao   != null ? ( ' | ' + nmPregao ) : '' );
    tooltipStr += '\n\n';
    tooltipStr += nmEmpr             != null ? nmEmpr : '';
    tooltipStr += nmTipSitEmpr       != null ? ( '\nSituação: ' + nmTipSitEmpr ) : '';
    tooltipStr += nmSetorEcnmEmpr    != null ? ( '\nSetor: ' + nmSetorEcnmEmpr ) : '';
    tooltipStr += nmSubSetorEcnmEmpr != null ? ( ' / ' + nmSubSetorEcnmEmpr ) : '';
    tooltipStr += nmSegmEcnmEmpr     != null ? ( ' / ' + nmSegmEcnmEmpr     ) : '';
    tooltipStr += volMedNegc         != null ? ( '\n\nVolume Médio de Negócios: ' + volMedNegcStr ) : '';

    return tooltipStr;
  }

  private getColorRgbToChart( qtTotalTickers, qtTotalInfo, indexThisTicker, indexThisInfo ){
    const r = Math.round( (indexThisTicker + 1) / qtTotalTickers * 200 ).toString(16);
    const g = (40).toString(16);
    const b = Math.round( indexThisInfo / qtTotalInfo * 150 ).toString(16);

    return '#' + ( ( r.length === 1 ? '0' : '' ) + r ) + ( ( g.length === 1 ? '0' : '' ) + g ) + ( ( b.length === 1 ? '0' : '' ) + b );
  }

  private getValueViewCotcToChart( inf: InfCrctInvInterface< any >, hst: HstCotcAcao ): number{
    let valueToView = 0;

    const hstCotcAcao = hst as HstCotcAcao;
    const tipView = inf.getTipViewSelectedInfCrctInv();
    const tipViewCode = tipView != null && tipView.code ? tipView.code : 0;

    //console.log( 'tipView: ' + tipView.getCode() );

    switch( tipViewCode ){
      case 2:
        valueToView = hstCotcAcao.prcAbert;
        break;

      case 3:
        valueToView = hstCotcAcao.prcMin;
        break;

      case 4:
        valueToView = hstCotcAcao.prcMax;
        break;

      case 5:
        valueToView = hstCotcAcao.prcFech;
        break;

      case 8:
        //console.log( 'VOL_NEGC' );
        valueToView = hstCotcAcao.volNegc;
        break;

      case 9:
        //console.log( 'TX_PERC_RENT_AA' );
        valueToView = hstCotcAcao.txPercRentAa;
        break;

      default:
        valueToView = hstCotcAcao.prcFech;
        break;
    }

    //console.log( 'valueToView: ' + valueToView );

    return valueToView;
  }

  public updateDtCotcFrom( event: Event ): void{
    let dtCotcFromSelected = this.getDtCotcFromSelected();
    this.prmUsrService.setValue( this.PRM_USR_COTC_DT_INICIO, dtCotcFromSelected );

    this.updateDtCotc( event );
  }

  public updateDtCotcEnd( event: Event ): void{
    this.updateDtCotc( event );
  }

  private updateDtCotc( event: Event ){
    let dtCotcFromSelected = this.getDtCotcFromSelected();
    let dtCotcEndSelected = this.getDtCotcEndSelected();

    //Verifica se a data de inicio eh maior que a data de termino.
    if( dtCotcFromSelected != null && dtCotcEndSelected != null ){
      let diff = Math.round( dtCotcEndSelected.getTime() - dtCotcFromSelected.getTime() );
      let diffDays = Math.ceil( diff / (1000 * 3600 * 24) );

      if( diffDays < 0 ){
        this.setDtCotcFromSelected( dtCotcEndSelected );
        this.setDtCotcEndSelected( dtCotcFromSelected );

        dtCotcFromSelected = this.getDtCotcFromSelected();
        dtCotcEndSelected  = this.getDtCotcEndSelected();
      }
    }

    this.refreshAllInfCrctInvOfAllTickers();

    this.prmUsrService.setValue( this.PRM_USR_COTC_DT_INICIO, dtCotcFromSelected );
    //this.prmUsrService.setValue( this.PRM_USR_COTC_DT_FIM   , dtCotcEndSelected );
  }

  public getColorTipInfTicker( infTicker: InfCrctInvInterface< any > ){
    const tipInfTicker = infTicker.getTipInfCrctInv();
    return tipInfTicker === TipInfCrctInv.COTC ? "primary" : "accent";
  }

  public onChangeTickerListPageSize( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }

  public onChangeGrCrctInvSelected( event ){
    //console.log( 'onChangeGrCrctInvSelected()...' );

    //Obtem o codigo do grupo que foi selecionado.
    const cdGrCrctInv: number = this.getCdGrCrctInvSelected();

    //Atualiza a lista de tickers baseado na Carteira selecionada.
    this.reloadTickersOnGrCrctInvAcaoSelected( cdGrCrctInv );

    //console.log( 'onChangeGrCrctInvSelected().' );
  }

  private reloadTickersOnGrCrctInvAcaoSelected( cdGrCrctInv: number ): Promise< any >{
    //console.log( 'reloadTickersOnGrCrctInvAcaoSelected( ' + cdGrCrctInv + ' )...' );

    const _this = this;

    const pReturn = new Promise( function( resolve, reject ){
      if( cdGrCrctInv != null && cdGrCrctInv > 0 ){
        //const cdUsr: number = _this.authenticationService.getSessionService().currentUserValue != null ? _this.authenticationService.getSessionService().currentUserValue.id : null;

        //console.log( 'cdUsr/cdGrCrctInv: ' + cdUsr + '/' + cdGrCrctInv );

        //Obtem os itens do grupo selecionado.
        _this.grCrctInvService.listItensGrupo( cdGrCrctInv, null, true, null, false, true, false, false, false, true )
          .then( itemGrCrctInvList => {
            const qtItemGrCrctInv = itemGrCrctInvList != null ? itemGrCrctInvList.length : 0;

            console.log( 'Carteira possui ' + qtItemGrCrctInv + ' ativos.' );

            _this.changeCrctInvList( itemGrCrctInvList );
            resolve();
          }
        );
      }
      else{
        resolve();
      }
    } );

    return pReturn;
  }

  public toggleInfosOfTicker(){
    this.sidenav.toggle();
  }

  public onCloseInfosOfTickers(){
    this.sidenav.close();
  }

  public onSelectedInfCrctInvSelected( event: MatSelectChange ){
    //console.log( 'onSelectedInfCrctInvSelected...' );

    const nmInfCrctInvSelected = event.value;

    if( nmInfCrctInvSelected != null ){
      //console.log( 'onSelectedInfCrctInvSelected - nmInfCrctInvSelected: ' + nmInfCrctInvSelected );

      //Procura a informacao na lista dos possiveis selecionaveis.
      const qtInfCrctInv = this._infCrctInvList != null ? this._infCrctInvList.length : 0;
      let infCrctInv = null;

      //console.log( 'onSelectedInfCrctInvSelected - qtInfCrctInv: ' + qtInfCrctInv );

      for( let indexInfCrctInv = 0; indexInfCrctInv < qtInfCrctInv; indexInfCrctInv++ ){
        const nmInfCrctInvIterate = this._infCrctInvList[ indexInfCrctInv ].getNmInfCrctInv();

        //console.log( indexInfCrctInv + ' - nmInfCrctInvIterate: ' + nmInfCrctInvIterate );

        if( nmInfCrctInvIterate === nmInfCrctInvSelected ){
          infCrctInv = this._infCrctInvList[ indexInfCrctInv ];
          break;
        }
      }

      if( infCrctInv != null ){
        //console.log( 'Atualizando as listas de periodicidade e tipView de selecionaveis.' );

        this._infCrctInvPerdcList   = infCrctInv.getPerdcListInfCrctInv();
        this._infCrctInvTipViewList = infCrctInv.getTipViewListInfCrctInv();

        if( this._infCrctInvPerdcList == null || this._infCrctInvPerdcList.length === 0 ){
          this.setInfCrctInvPerdcSelected( null );
        }

        if( this._infCrctInvTipViewList == null || this._infCrctInvTipViewList.length === 0 ){
          this.setInfCrctInvTipViewSelected( null );
        }
      }
      else{
        //console.log( 'infCrctInv is null.' );
      }
    }
  }

  public onClickTndcAltaCrctInvSelected(){
    this.setTndcCrctInvSelected( 'A' )
      .then( resp => {
      } )
      .catch( err => {
        window.alert( 'Erro na atualização da tendência dos Ativos selecionados: ' + err );
      } );
  }

  public onClickTndcBaixaCrctInvSelected(){
    this.setTndcCrctInvSelected( 'B' )
      .then( resp => {
      } )
      .catch( err => {
        console.error( err );
        window.alert( 'Erro na atualização da tendência dos Ativos selecionados: ' + err );
      } );
  }

  public onClickTndcLateralidadeCrctInvSelected(){
    this.setTndcCrctInvSelected( 'N' )
      .then( resp => {
      } )
      .catch( err => {
        window.alert( 'Erro na atualização da tendência dos Ativos selecionados: ' + err );
      } );
  }

  public setTndcCrctInvSelected( sglTipTndc: string ): Promise< any >{
    const _this = this;

    const pReturn = new Promise( function( resolve, reject ){
      const cdGrCrctInv = _this.getCdGrCrctInvSelected();

      //Itera por todos os controls dos tickers.
      const controls = _this._tickerListDataSource.data;

      const qtTickers = controls != null ? controls.length : 0;

      const promises: Promise< any >[] = [];

      for( let indexControl = 0; indexControl < qtTickers; indexControl++ ){
        const control = controls[ indexControl ];

        if( control.isControlEnabled() === true ){
          const itemGr = control.getItemGrupoCrctInvAcao();
          itemGr.tipTndc.sglTipTndc = sglTipTndc;

          const p = _this.grCrctInvService.setTndcCrctInvAcao( cdGrCrctInv, itemGr.crctInv.cdInv, sglTipTndc );

          promises.push( p );
        }
      }

      //Monitora a finalizacao de todas as tarefas.
      Promise.all( promises )
        .then( resp => {
          resolve( resp );
        } )
        .catch( err => {
          reject( err );
        } );
    });

    return pReturn;
  }

  public isItemGrupoCrctInvAcaoSelectedOfTndc( sglTipTndc: string ): boolean{
    //Obtem o item selecionado.
    const itemGrSelected = this.getItemGrupoCrctInvAcaoSelected();

    return itemGrSelected != null ? itemGrSelected.tipTndc.sglTipTndc === sglTipTndc : false;
  }

  /**
   * Retorna o itemGrCrctInvAcao selecionado, desde que seja unico. Se houver mais de um selecionado,
   * ou nenhum, retorna null.
   */
  private getItemGrupoCrctInvAcaoSelected(): ItemGrupoCrctInvAcao{
    //Os controls de todos os itens da Carteira. Cada item está associado a um ticker.
    const controlsList: ControlLifeCycleCrctInv[] = this._tickerListDataSource.data;
    const qtControl = controlsList != null ? controlsList.length : 0;

    const itemGrCrctInvAcaoSelectedList: ItemGrupoCrctInvAcao[] = [];

    for( let indexControl = 0; indexControl < qtControl; indexControl++ ){
      const controlIterate = controlsList[ indexControl ];

      if( controlIterate.isControlEnabled() === true ){
        itemGrCrctInvAcaoSelectedList.push( controlIterate.getItemGrupoCrctInvAcao() );
      }
    }

    return itemGrCrctInvAcaoSelectedList.length === 1 ? itemGrCrctInvAcaoSelectedList[0] : null;
  }

  public onAddInfCrctInvSelected(){
    //console.log( 'onAddInfCrctInvSelected()...' );

    const nmInfCrctInvSelected      = this.getInfCrctInvSelected();
    const perdcInfCrctInvSelected   = this.getInfCrctInvPerdcSelected();
    const tipViewInfCrctInvSelected = this.getInfCrctInvTipViewSelected();

    //console.log( 'nmInfCrctInvSelected: ' + nmInfCrctInvSelected + ' (' + perdcInfCrctInvSelected + ', ' + tipViewInfCrctInvSelected + ')' );

    //Obtem a quantidade de elementos que ha na lista de periodicidade.
    const qtPerdcInfCrctInv = this._infCrctInvPerdcList != null ? this._infCrctInvPerdcList.length : 0;

    //console.log( 'Ha ' + qtPerdcInfCrctInv + ' periodicidades na lista.' );

    //Obtem a quantidade de elementos que ha na lista de tipView.
    const qtTipViewInfCrctInv = this._infCrctInvTipViewList != null ? this._infCrctInvTipViewList.length : 0;

    //console.log( 'Ha ' + qtTipViewInfCrctInv + ' tipView na lista.' );

    if( nmInfCrctInvSelected != null && ( qtPerdcInfCrctInv === 0 || ( qtPerdcInfCrctInv > 0 && perdcInfCrctInvSelected != null ) ) ){
      //Busca a informacao da lista dos possiveis selecionaveis
      const qtInfCrctInv = this._infCrctInvList != null ? this._infCrctInvList.length : 0;
      let infCrctInv = null;
      let indexInfCrctInv = 0;

      for( ; indexInfCrctInv < qtInfCrctInv; indexInfCrctInv++ ){
        if( this._infCrctInvList[ indexInfCrctInv ].getNmInfCrctInv() === nmInfCrctInvSelected ){
          infCrctInv = this._infCrctInvList[ indexInfCrctInv ];
          break;
        }
      }

      if( infCrctInv != null ){
        //console.log( 'InfCrctInv encontrada.' );

        //Cria uma copia do objeto.
        let infCrctInvCopy: InfCrctInvInterface< any > = infCrctInv.copy();
        infCrctInvCopy.setDtFromValues( this.getDtCotcFromSelected() );
        infCrctInvCopy.setDtEndValues( this.getDtCotcEndSelected() );

        //Se foi selecionada uma periodicidade, marca-a na copia.
        if( perdcInfCrctInvSelected != null ){
          infCrctInvCopy.setPerdcSelectedInfCrctInv( perdcInfCrctInvSelected );
        }

        //Se foi selecionada uma tipView, marca-a na copia.
        if( tipViewInfCrctInvSelected != null ){
          infCrctInvCopy.setTipViewSelectedInfCrctInv( tipViewInfCrctInvSelected );
        }

        //Se nao ha mais periodicidades e tipView a serem apresentadas, exclui infCrctInv da lista de selecionaveis.
        if( qtPerdcInfCrctInv <= 1 && qtTipViewInfCrctInv <= 1 ){
          this._infCrctInvList.splice( indexInfCrctInv, 1 );
        }

        //Excluir periodicidade da lista de selecionaveis.
        if( qtPerdcInfCrctInv > 0 ){
          for( let indexPerdc = 0; indexPerdc < qtPerdcInfCrctInv; indexPerdc++ ){
            if( this._infCrctInvPerdcList[ indexPerdc ] === perdcInfCrctInvSelected ){
              this._infCrctInvPerdcList.splice( indexPerdc, 1 );
              break;
            }
          }
        }

        //Excluir tipView da lista de selecionaveis.
        if( qtTipViewInfCrctInv > 0 ){
          for( let indexTipView = 0; indexTipView < qtTipViewInfCrctInv; indexTipView++ ){
            const infCrctInvTipViewIterate = this._infCrctInvTipViewList[ indexTipView ];

            if( infCrctInvTipViewIterate instanceof TipViewInfCrctInv && infCrctInvTipViewIterate.isEqual( tipViewInfCrctInvSelected ) ){
              this._infCrctInvTipViewList.splice( indexTipView, 1 );
              break;
            }
          }
        }

        //Adiciona a informacao na lista dos selecionados
        this._infCrctInvSelectedList.push( infCrctInvCopy );
        this.addInfCrctInvAllTickers( infCrctInvCopy );

        this.refreshInfCrctInvOfAllTickers( infCrctInvCopy );

        this.refreshPrmUsrInfCrctInvSelectedList();
      }
      else{
        //console.log( 'InfCrctInv nao encontrada.' );
      }
    }
    else{
      //console.log( 'else' );
    }
  }

  public onRemoveInfCrctInvSelected( infCrctInvSelected: InfCrctInvInterface< any > ){
    //console.log( 'onRemoveInfCrctInvSelected()...' );
    //console.log( 'infCrctInvSelected: ' + infCrctInvSelected.getNmInfCrctInv() + ' (' + infCrctInvSelected.getPerdcSelectedInfCrctInv() + ', ' + infCrctInvSelected.getTipViewSelectedInfCrctInv() + ')' );

    //Obtem a quantidade de informacoes selecionadas.
    const qtInfCrctInvSelected = this._infCrctInvSelectedList != null ? this._infCrctInvSelectedList.length : 0;
    let isFound = false;

    //Remove a informacao da lista das informacoes selecionadas.
    for( let indexTipInfTicker = 0; indexTipInfTicker < qtInfCrctInvSelected; indexTipInfTicker++ ){
      const infCrctInvSelectedIterate = this._infCrctInvSelectedList[ indexTipInfTicker ];

      if( infCrctInvSelectedIterate.isEqual( infCrctInvSelected ) ){
        this._infCrctInvSelectedList.splice( indexTipInfTicker, 1 );
        this.removeInfCrctInvAllTickers( infCrctInvSelected );
        isFound = true;
        break;
      }
    }

    if( isFound === true ){
      //console.log( 'Informacao foi encontrada na lista de selecionadas.' );

      //Verifica se infCrctInv ja existe na lista de selecionaveis.
      const qtInfCrctInv = this._infCrctInvList != null ? this._infCrctInvList.length : 0;
      let infCrctInv: InfCrctInvInterface< any > = null;

      for( let indexInfCrctInv = 0; indexInfCrctInv < qtInfCrctInv; indexInfCrctInv++ ){
        const infCrctInvIterate = this._infCrctInvList[ indexInfCrctInv ];

        if( infCrctInvIterate.getTipInfCrctInv() === infCrctInvSelected.getTipInfCrctInv() ){
          if( infCrctInvIterate instanceof InfCrctInvIndTcn ){
            const infCrctInvIndTcnIterate: InfCrctInvIndTcn  = infCrctInvIterate  as InfCrctInvIndTcn;
            const infCrctInvIndTcnSelected: InfCrctInvIndTcn = infCrctInvSelected as InfCrctInvIndTcn;

            if( infCrctInvIndTcnIterate.isEqualIndTcn( infCrctInvIndTcnSelected ) === true ){
              infCrctInv = infCrctInvIterate;
              break;
            }
          }
          else{
            if( infCrctInvIterate instanceof InfCrctInvCotc ){
              const infCrctInvCotcIterate : InfCrctInvCotc = infCrctInvIterate  as InfCrctInvCotc;
              const infCrctInvCotcSelected: InfCrctInvCotc = infCrctInvSelected as InfCrctInvCotc;

              if( infCrctInvCotcIterate.isEqualCotc( infCrctInvCotcSelected ) === true ){
                infCrctInv = infCrctInvIterate;
                break;
              }
            }
          }
        }
      }

      if( infCrctInv == null ){
        //console.log( 'Informacao nao existe na lista de selecionaveis.' );

        //Como ainda nao existe na lista de selecionaveis, adiciona.
        infCrctInvSelected.addPerdcListInfCrctInv( infCrctInvSelected.getPerdcSelectedInfCrctInv() );

        if( infCrctInvSelected.getTipViewSelectedInfCrctInv() != null ){
          infCrctInvSelected.addTipViewListInfCrctInv( infCrctInvSelected.getTipViewSelectedInfCrctInv() );
        }

        infCrctInvSelected.setPerdcSelectedInfCrctInv( null );
        infCrctInvSelected.setTipViewSelectedInfCrctInv( null );

        this._infCrctInvList.push( infCrctInvSelected );
      }
      else{
        //console.log( 'Informacao ja existe na lista de selecionaveis.' );

        //Como ja existe, adiciona apenas a periodicidade.
        infCrctInv.addPerdcListInfCrctInv( infCrctInvSelected.getPerdcSelectedInfCrctInv() );

        if( infCrctInvSelected.getTipViewSelectedInfCrctInv() != null ){
          infCrctInv.addTipViewListInfCrctInv( infCrctInvSelected.getTipViewSelectedInfCrctInv() );
        }
      }

      this.refreshAllInfCrctInvOfAllTickers();
      this.refreshPrmUsrInfCrctInvSelectedList();
    }
  }

  public onErrorChart( event: ChartErrorEvent ): void{
    /*
    console.log( 'onErrorChart: ' + event );
    console.log( 'onErrorChart - id.............: ' + event.id );
    console.log( 'onErrorChart - message........: ' + event.message );
    console.log( 'onErrorChart - detailedMessage: ' + event.detailedMessage );
    console.log( 'onErrorChart - options........: ' + event.options );
    console.log( 'onErrorChart - keys/valueses..: ' + Object.keys(event) + '; ' + Object.values(event) );
    */
  }

  private addInfCrctInvAllTickers( infCrctInv: InfCrctInvInterface< any > ): void{
    //console.log( 'addInfCrctInvAllTickers( ' + infCrctInv.getNmInfCrctInvViewed() + ' )...' );

    this.managerLifeCycleCrctInv.addInfCrctInvAllControl( infCrctInv );
  }

  private addAllInfCrctInvInControl( control: ControlLifeCycleCrctInv ): void{
    //console.log( 'addAllInfCrctInvInControl( ' + control.getCrctInv().cdInv + ' )...' );

    //Obtem a quantidade de informacoes selecionadas.
    const qtInfCrctInv = this._infCrctInvSelectedList != null ? this._infCrctInvSelectedList.length : 0;

    if( qtInfCrctInv > 0 ){
      this._infCrctInvSelectedList.forEach( infCrctInv => {
        const infCrctInvCopy = infCrctInv.copy();
        infCrctInvCopy.setCrctInv( control.getCrctInv() );
        control.addInfCrctInv( infCrctInvCopy );
      } );
    }
  }

  private removeInfCrctInvAllTickers( infCrctInv: InfCrctInvInterface< any > ): void{
    //Obtem a quantidade de tickers.
    const qtTickers = this._tickerListDataSource != null && this._tickerListDataSource.data != null ? this._tickerListDataSource.data.length : 0;

    if( qtTickers > 0 ){
      this._tickerListDataSource.data.forEach( ticker => {
        ticker.removeInfCrctInv( infCrctInv );
      } );
    }
  }

  public openDialogAddTicker(): void{
    const dialogRef = this.dialog.open( this.dialogSelectTicker, { disableClose: false, width: '80%' } );

    const _this = this;

    dialogRef.afterClosed().subscribe( tickers => {
      const qtTickers = tickers != null ? tickers.length : 0;
      const itemGrList: ItemGrupoCrctInvAcao[] = [];

      for( let indexTicker = 0; indexTicker < qtTickers; indexTicker++ ){
        const ticker = tickers[ indexTicker ];
        const itemGr = new ItemGrupoCrctInvAcao();
        itemGr.crctInv = ticker;

        itemGrList.push( itemGr );
      }

      _this.addCrctInvList( itemGrList );
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'backdropClick' );
    });
  }

  public openDialogEditGrCrctInv(): void{
    const cdGrCrctInvSelected = this.getCdGrCrctInvSelected();

    //console.log( 'Informando a carteira ' + cdGrCrctInvSelected );

    const dialogRef = this.dialog.open( CarteiraDialogEditDesktopComponent, { disableClose: false, width: '80%', data: { cdGrCrctInv: cdGrCrctInvSelected } } );

    const _this = this;

    dialogRef.afterClosed().subscribe( tickers => {
      //console.log( 'editGrCrctInv - afterClosed().' );
      _this.onChangeGrCrctInvSelected( null );
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'editGrCrctInv - backdropClick' );
    });
  }

  public openDialogAddGrCrctInv(): void{
    const dialogRef = this.dialog.open( CarteiraDialogAddDesktopComponent, { disableClose: false, width: '80%' } );

    const _this = this;

    dialogRef.afterClosed().subscribe( grCrctInvAdded => {
      //console.log( 'addGrCrctInv - afterClosed().' );
      _this.initGrCrctInv()
        .then( result => {
          if( result != null ){
            const cdGrCrctInvAdded = grCrctInvAdded.cdGrCrctInvAcao;

            _this.setCdGrCrctInvSelected( cdGrCrctInvAdded );
          }
        } );
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'addGrCrctInv - backdropClick' );
    });
  }

  private addCrctInvList( itemGrCrctInvList: ItemGrupoCrctInvAcao[] ){
      let controlList: ControlLifeCycleCrctInv[] = [];
      const countTickers = itemGrCrctInvList != null ? itemGrCrctInvList.length : 0;

      for( let i = 0; i < countTickers; i++ ){
        const itemGr: ItemGrupoCrctInvAcao = itemGrCrctInvList[ i ];

        const control = this.addTickerOfList( itemGr );

        if( control != null ){
          controlList.push( control );
        }
      }

      this.refreshPrmUsrCdInvSelectedList();
      this.refreshAllInfCrctInvOfTickers( controlList );
  }

  private changeCrctInvList( tickerList: ItemGrupoCrctInvAcao[] ){
    //console.log( 'changeCrctInvList()...' );

    //Remove todos tickers existentes na lista de selecionados.
    this.removeAllTickerOfList();

    let controlList: ControlLifeCycleCrctInv[] = [];
    const countTickers = tickerList != null ? tickerList.length : 0;

    for( let i = 0; i < countTickers; i++ ){
      const ticker = tickerList[ i ];

      //console.log( 'Ticker: ' + ticker.nmInv );

      const control = this.addTickerOfList( ticker );

      if( control != null ){
        controlList.push( control );
      }
    }

    this.refreshAllInfCrctInvOfTickers( controlList );

    //console.log( 'changeCrctInvList().' );
  }

  private addTickerOfList( itemGrupoCrctInvAcao: ItemGrupoCrctInvAcao ): ControlLifeCycleCrctInv{
    let control = null;
    const indexOfTicker = this.getIndexOfTickerOfList( itemGrupoCrctInvAcao.crctInv );

    //Se ticker nao existir na lista, adiciona-o.
    if( indexOfTicker < 0 ){
      if( this._tickerListDataSource == null ){
        this._tickerListDataSource = new MatTableDataSource< ControlLifeCycleCrctInv >( [] );
        this._tickerListDataSource.paginator = this._tickerListPaginator;

        this._tickerListDataSource.sortingDataAccessor = ( item, property ) => {
          switch( property ) {
            case 'ticker'      : return item.getCrctInv().tipInv.cdTipInv === 4 ? item.getCrctInv().crctInvAcao.nmPregao : item.getCrctInv().nmInv;
            case 'tipInv'      : return item.getCrctInv().tipInv.nmTipInv;
            case 'empr'        : return item.getCrctInv().empr.nmAbrvEmpr ? item.getCrctInv().empr.nmAbrvEmpr : item.getCrctInv().empr.nmEmpr;
            case 'setor'       : return item.getCrctInv().empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm;

            default: return item[ property ];
          }
        };

        this._tickerListDataSource.sort = this._itemGrCrctInvAcaoListSort;
      }

      let data = this._tickerListDataSource.data;

      //Cria o control.
      control = new ControlLifeCycleCrctInv( itemGrupoCrctInvAcao, this.cotcService, this.indTcnService );
      control.setDtFromValues( this.getDtCotcFromSelected() );
      control.setDtEndValues( this.getDtCotcEndSelected() );

      //Adiciona as informacoes ja selecionadas no control.
      this.addAllInfCrctInvInControl( control );

      //Adiciona o control no manager.
      this.managerLifeCycleCrctInv.addControl( control );

      data.push( control );

      this._tickerListDataSource.data = data;
    }

    return control;
  }

  public removeTickerOfList( control: ControlLifeCycleCrctInv ){
    const ticker = control.getCrctInv();
    const indexOfTicker = this.getIndexOfTickerOfList( ticker );

    if( indexOfTicker >= 0 ){
      let tickers = this._tickerListDataSource.data;
      tickers.splice( indexOfTicker, 1 );
      this._tickerListDataSource.data = tickers;

      //Remove o control da coordenacao do manager.
      this.managerLifeCycleCrctInv.removeControl( control );

      this.updateChartNow();
    }
  }

  private removeAllTickerOfList(): void{
    if( this._tickerListDataSource ){
      this._tickerListDataSource.data = [];
      this.managerLifeCycleCrctInv.removeAllControl();
    }

    this.updateChartNow();
  }

  /**
   * Esconde todos os ticker's, mostrando-o na lista de selecionados, porém não no gráfico.
   */
  public hiddenAllTickerOfList( isUpdateChart: boolean ){
    //Remove o control da coordenacao do manager.
    this.managerLifeCycleCrctInv.disableAllControl();

    if( isUpdateChart && isUpdateChart == true ){
      this.updateChartNow();
    }
  }

  /**
   * Esconde o ticker, mostrando-o na lista de selecionados, porém não no gráfico.
   */
  public hiddenTickerOfList( control: ControlLifeCycleCrctInv ){
    //Remove o control da coordenacao do manager.
    this.managerLifeCycleCrctInv.disableControl( control );

    this.updateChartNow();
  }

  /**
   * Mostrar todos os tickers no grafico.
   */
  public showAllTickerOfList(){
    this.managerLifeCycleCrctInv.enableAllControl();

    this.updateChartNow();
  }

  /**
   * Mostrar o ticker, mostrando-o na lista de selecionados e no gráfico.
   */
  public showTickerOfList( control: ControlLifeCycleCrctInv ){
    //Adiciona o control da coordenacao do manager.
    this.managerLifeCycleCrctInv.enableControl( control );

    this.updateChartNow();
  }

  public onSelectedControlCrctInv( controlCrctInvSelected: ControlLifeCycleCrctInv ): void{
    this.hiddenAllTickerOfList( false );
    this.showTickerOfList( controlCrctInvSelected );
  }

  private getIndexOfTickerOfList( ticker: CrctInv ){
    let indexFound = -1;

    if( this._tickerListDataSource != null ){
      const tickers = this._tickerListDataSource.data;
      const countTickers = tickers.length;

      for( let iTicker = 0; iTicker < countTickers; iTicker++ ){
        const controlIterate = tickers[ iTicker ];
        const tickerIterate: CrctInv = controlIterate.getCrctInv();

        if( ticker.cdInv === tickerIterate.cdInv ){
          indexFound = iTicker;
          break;
        }
      }
    }

    return indexFound;
  }

  private getQtTickersOfList(){
    return this._tickerListDataSource != null && this._tickerListDataSource.data != null ? this._tickerListDataSource.data.length : 0;
  }

  public isTickerExistInManager( control: ControlLifeCycleCrctInv ): boolean{
    return this.managerLifeCycleCrctInv.isExistControl( control );
  }

  private refreshPrmUsrCdInvSelectedList(): void{
    //console.log( 'refreshPrmUsrCdInvSelectedList()...' );

    //Obtem a lista de tickers selecionados.
    const tickersList: ControlLifeCycleCrctInv[] = this._tickerListDataSource != null && this._tickerListDataSource.data != null ? this._tickerListDataSource.data : null;

    if( tickersList != null ){
      //Monta uma lista de cdInv.
      let cdInvList: number[] = [];

      tickersList.forEach( tickerIterate => {
        const cdInvIterate = tickerIterate.getCrctInv().cdInv;

        cdInvList.push( cdInvIterate );
      } );

      this.prmUsrService.setValueNumberList( this.PRM_USR_CD_INV_LIST, cdInvList );
    }
    else{
      //console.log( 'refreshPrmUsrCdInvSelectedList - nao ha ticker a ser persistido como prmUsr.' );
    }

    //console.log( 'refreshPrmUsrCdInvSelectedList().' );
  }

  private refreshPrmUsrInfCrctInvSelectedList(): void{
    //console.log( 'refreshPrmUsrInfCrctInvSelectedList()...' );

    //Obtem a lista de informacoes selecionadas.
    const infCrctInvList: InfCrctInvInterface< any >[] = this._infCrctInvSelectedList != null ? this._infCrctInvSelectedList : null;

    if( infCrctInvList != null ){
      let infCrctInvPkList: InfCrctInvPk[] = [];

      infCrctInvList.forEach( infCrctInvIterate =>{
        const pk = infCrctInvIterate.getPk();
        infCrctInvPkList.push( pk );
      } );

      this.prmUsrService.setValueJsonList( this.PRM_USR_INF_CRCT_INV_LIST, infCrctInvPkList );
    }
    else{
      //console.log( 'refreshPrmUsrInfCrctInvSelectedList - nao ha informacoes a serem persistidas no prmUsr.' );
    }

    //console.log( 'refreshPrmUsrInfCrctInvSelectedList().' );
  }

  private getQtInfCrctInv(){
    return this._infCrctInvSelectedList != null ? this._infCrctInvSelectedList.length : 0;
  }

  detailCotcAcao( ticker: CrctInv, dtCotc: Date ){
    this.goToPage( '/cotc-detail', { 'cdInv': ticker.cdInv, 'dtCotc': dtCotc }, true );
  }

  public detailTicker( cdInv: number ){
    this.goToPage( '/inv-detail', { 'cdInv': cdInv }, true );
  }

  public detailEmpr( cdEmpr: number ){
    this.goToPage( '/empr-detail', { 'cdEmpr': cdEmpr }, true );
  }

  public detailSetorEcnm( setorEcnm: SetorEcnm ){
    this.goToDetailSegmEcnm( setorEcnm.cdSetorEcnm, null, null );
  }

  detailSubSetorEcnm( subSetorEcnm: SubSetorEcnm ){
    this.goToDetailSegmEcnm( subSetorEcnm.setorEcnm.cdSetorEcnm, subSetorEcnm.cdSubSetorEcnm, null );
  }

  detailSegmEcnm( segmEcnm: SegmEcnm ){
    this.goToDetailSegmEcnm( segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, segmEcnm.subSetorEcnm.cdSubSetorEcnm, segmEcnm.cdSegmEcnm );
  }

  detailSetorAtvdd( setorAtvdd: SetorAtvdd ){
    const cdSetorAtvdd = setorAtvdd == null ? null : setorAtvdd.cdSetorAtvdd;

    this.goToDetailSetorAtvdd( cdSetorAtvdd );
  }

  private goToDetailSegmEcnm( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ){
    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm, 'cdSubSetorEcnm': cdSubSetorEcnm, 'cdSegmEcnm': cdSegmEcnm }, true );
  }

  private goToDetailSetorAtvdd( cdSetorAtvdd: number ){
    this.goToPage( '/setor-detail', { 'cdSetorAtvdd': cdSetorAtvdd }, true );
  }

  private getObjOfPropertie( objList, propTarget: string, valueTarget: string ){
    let objFound = null;

    //Obtem a quantidade de objetos.
    const qtProperties = objList == null || !objList ? 0 : objList.length;

    for( let index = 0; index < qtProperties; index++ ){
      const obj = objList[ index ];

      const propertie = obj[ propTarget ];

      if( propertie === valueTarget ){
        objFound = obj;
        break;
      }
    }

    return objFound;
  }
}

<!-- ================================= CONTENT ================================= -->

<form [formGroup]="this._generalForm">
	<mat-dialog-content>
		<div class="grid_container_general_dialog_confirm_desktop">
			<div class="grid_item_general_dialog_confirm_desktop_title">
				<h2 mat-dialog-title>Confirmação</h2>
			</div>

			<div class="grid_item_general_dialog_confirm_desktop_buttonClose">
				<button mat-icon-button color="accent" (click)="this.onCancel()">
					<mat-icon class="material-icons-round" matTooltip="Fechar janela.">close</mat-icon>
				</button>
			</div>

			<div class="grid_item_general_dialog_confirm_desktop_msgConfirm" [innerHTML]="this._msgConfirm">
			</div>

			<div class="grid_item_general_dialog_confirm_desktop_txObs" *ngIf="this._isReturnMsgObs === true">
				<mat-form-field>
					<mat-label>Observação:</mat-label>
					<input matInput formControlName="txObsSelected"
									matTooltip="Informe uma observação a ser retornada." >
				</mat-form-field>
			</div>

			<div class="grid_item_general_dialog_confirm_desktop_buttons">
				<button mat-raised-button class="buttonConfirmYes" (click)="onConfirmYes()">
					Sim
				</button>

				<button mat-raised-button class="buttonConfirmNo"  (click)="onConfirmNo()">
					Não
				</button>
			</div>
		</div>
	</mat-dialog-content>
</form>


import { Component, SimpleChanges       } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Router                         } from '@angular/router';
import { HomeComponent                  } from '../home.component';
import { FormBuilder                    } from '@angular/forms';

import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { SearchGeneralService } from 'src/app/_services/search-general/search-general.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';

@Component({
  selector: 'app-home-mobile',
  templateUrl: '../desktop/home-desktop.component.html',
  styleUrls: ['./home-mobile.component.css']
})
export class HomeMobileComponent extends HomeComponent {
  constructor( protected router: Router,
               protected sanitizer: DomSanitizer,
			         protected formBuilder: FormBuilder,
               protected aplicationStateService : AplicationStateService,
               protected prmUsrService          : PrmUsrService,
			         protected searchGeneralService: SearchGeneralService ) {
    super( router, sanitizer, formBuilder, aplicationStateService, prmUsrService, searchGeneralService );
  }

  ngOnInit(){
  	super.ngOnInit();
  }

  ngOnChanges( changes: SimpleChanges ) {
    // when inputs are updated

    for( let propName in changes ){
      let chng = changes[propName];
      let cur  = JSON.stringify(chng.currentValue);
      let prev = JSON.stringify(chng.previousValue);
      console.log( `${propName}: currentValue = ${cur}, previousValue = ${prev}` );
    }
  }

  ngAfterContentInit() {
    // after content was projected
  }

  ngAfterViewInit() {
    // after the view was created
  }

  ngAfterViewChecked(){
  }
}

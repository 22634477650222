import { Component, ViewEncapsulation     } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TickerDialogSelectComponent              } from '../ticker-dialog-select.component';
import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';


@Component({
  selector: 'app-ticker-dialog-select-desktop',
  templateUrl: './ticker-dialog-select-desktop.component.html',
  styleUrls: ['./ticker-dialog-select-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TickerDialogSelectDesktopComponent extends TickerDialogSelectComponent {
  constructor( public dialogRef: MatDialogRef< TickerDialogSelectDesktopComponent >,
               protected formBuilder           : FormBuilder            ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService               ){
    super( dialogRef, formBuilder, aplicationStateService, authenticationService, prmUsrService, invService );
  }

  ngOnInit() {
    console.log( 'TickerDialogSelectDesktopComponent.ngOnInit()...' );
    super.ngOnInit();

    //console.log( 'TickerDialogSelectDesktopComponent.ngOnInit().' );
  }

}

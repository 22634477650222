import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { RstdEmpr, RstdEmprJoinInfRstdEmpr, TipInfRstdEmprJoinInfRstdEmpr, TipInfRstdEmpr, InfRstdEmpr } from 'src/app/_models/rstdEmpr';


@Injectable({
  providedIn: 'root'
})
export class RstdEmprService extends HttpBasicService< RstdEmprJoinInfRstdEmpr, RstdEmprJoinInfRstdEmpr >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista TODOS resultados da empresa.
   */
  list( cdEmpr: number ): Promise< RstdEmprJoinInfRstdEmpr[] >{
    return super.listGeneric( '/RSTD_EMPR/LIST', { cd_empr: cdEmpr }, false );
  }

  /**
   * Lista resultados da empresa por periodicidade.
   */
  listByPerdc( cdEmpr: number, sglPerdc: string, aaRefPerdc: number, nrRefPerdc: number, limit: number, returnCols: any[] ): Promise< RstdEmprJoinInfRstdEmpr[] >{
    const parms = { cd_empr: cdEmpr };

    if( sglPerdc != null && sglPerdc != "" ){
      parms[ 'sgl_perdc' ] = sglPerdc;
    }

    if( aaRefPerdc != null && aaRefPerdc > 0 ){
      parms[ 'aa_ref_perdc' ] = aaRefPerdc;
    }

    if( nrRefPerdc != null && nrRefPerdc > 0 ){
      parms[ 'nr_ref_perdc' ] = nrRefPerdc;
    }

    if( limit != null && limit > 0 ){
      parms[ 'limit' ] = limit;
    }

    //Adiciona as colunas a serem retornadas.
    const amountReturnCols = returnCols == null ? 0 : returnCols.length;

    for( let indexReturnCols = 0; indexReturnCols < amountReturnCols; indexReturnCols++ ){
      const returnCol = returnCols[ indexReturnCols ];

      parms[ 'return_' + Object.keys(returnCol) ] = Object.values(returnCol);
    }

    return super.listGeneric( '/RSTD_EMPR/LIST', parms, false );
  }

  /**
   * Lista tipos de informacao com diversas informacoes de resultado da empresa.
   * 
   * Informacoes opcionais que podem ser retornadas:
   *  'lpAtrbdSocCntr'
   *  'divCp'
   *  'divLp'
   *  'disp'
   *  'pl'
   *  'divLq'
   *  'ativoTotal'
   *  'deprecAmort'
   *  'resultFinanc'
   */
  listTipInfWithVariousInfRstd( cdEmpr: number, sglPerdc: string, aaRefPerdc: number, nrRefPerdc: number, limit: number, returnCols: any[] ): Promise< TipInfRstdEmprJoinInfRstdEmpr[] >{
    let p = this.listByPerdc( cdEmpr, sglPerdc, aaRefPerdc, nrRefPerdc, limit, returnCols );

    const _this = this;

    return new Promise< TipInfRstdEmprJoinInfRstdEmpr[] >( function( resolve, reject ){
      p.then( rstdEmprArrayDR => {
        const tipInfArray = new Array< TipInfRstdEmprJoinInfRstdEmpr >();

        //Transforma o resultado da empresa num array de tipos de informacao, em que cada item
        //contem um array de informacoes.
        let qtdElem = rstdEmprArrayDR == null ? 0 : rstdEmprArrayDR.length;
        const qtdElemReturnCols = returnCols == null || returnCols.length == 0 ? 0 : returnCols.length;

        for( let indexRstd = 0; indexRstd < qtdElem; indexRstd++ ){
          const rstdEmpr = rstdEmprArrayDR[ indexRstd ];

          for( let indexReturnCols = 0; indexReturnCols < qtdElemReturnCols; indexReturnCols++ ){
            const col = returnCols[ indexReturnCols ];
            const nmCol = Object.keys( col )[0];
            const vlCol = Object.values( col )[0];

            if( vlCol === true ){
              const rstdEmprOfIterate = rstdEmpr[ nmCol ];

              _this.addTipInfElementAtArray( Object.assign( new InfRstdEmpr, rstdEmprOfIterate ), tipInfArray );
            }
          }
        }

        resolve( tipInfArray );
      });
    });
  }

  /**
   * Lista tipos de informacao com diversas informacoes de resultado da empresa.
   */
  listAllAaRefRstdEmpr( cdEmpr: number ): Promise< RstdEmprJoinInfRstdEmpr[] >{
    return super.listGeneric( '/RSTD_EMPR/LIST_AA_REF', { cd_empr: cdEmpr }, false );
  }

  /**
   * Lista o ultimo resultado contabil da empresa.
   */
  listLastRstdEmpr( cdEmpr: number ): Promise< RstdEmprJoinInfRstdEmpr[] >{
    return super.listGeneric( '/RSTD_EMPR/LIST', { cd_empr: cdEmpr, limit: 1 }, false );
  }

  private addTipInfElementAtArray( infRstdEmpr: InfRstdEmpr, tipInfArray: Array< TipInfRstdEmprJoinInfRstdEmpr > ){
    const sglTipInfRstdEmpr = ( !infRstdEmpr || infRstdEmpr === null || !infRstdEmpr.tipInfRstdEmpr || infRstdEmpr.tipInfRstdEmpr === null ) ? null : infRstdEmpr.tipInfRstdEmpr.sglTipInfRstdEmpr;

    //Busca o tipo de informacao no array.
    let qtdElemTipInf = tipInfArray == null ? 0 : tipInfArray.length;

    let tipInfSelected: TipInfRstdEmprJoinInfRstdEmpr = null;

    for( let indexTipInf = 0; indexTipInf < qtdElemTipInf; indexTipInf++ ){
      const tipInfElem = tipInfArray[ indexTipInf ];

      const sglTipInfElem = tipInfElem.tipInfRstdEmpr.sglTipInfRstdEmpr

      if( sglTipInfRstdEmpr === sglTipInfElem ){
        tipInfSelected = tipInfElem;
        break;
      }
    }

    //Verifica se o tipo de informacao foi encontrado no array. Se nao foi, cria um novo.
    if( tipInfSelected == null ){
      tipInfSelected = new TipInfRstdEmprJoinInfRstdEmpr();

      tipInfSelected.tipInfRstdEmpr = new TipInfRstdEmpr();
      tipInfSelected.tipInfRstdEmpr.sglTipInfRstdEmpr      = sglTipInfRstdEmpr;
      tipInfSelected.tipInfRstdEmpr.nmTipInfRstdEmpr       = ( !infRstdEmpr.tipInfRstdEmpr || infRstdEmpr.tipInfRstdEmpr == null ? null : infRstdEmpr.tipInfRstdEmpr.nmTipInfRstdEmpr );
      tipInfSelected.tipInfRstdEmpr.txDcrTipInfRstdEmpr    = ( !infRstdEmpr.tipInfRstdEmpr || infRstdEmpr.tipInfRstdEmpr == null ? null : infRstdEmpr.tipInfRstdEmpr.txDcrTipInfRstdEmpr );
      tipInfSelected.tipInfRstdEmpr.txDcrUsuTipInfRstdEmpr = ( !infRstdEmpr.tipInfRstdEmpr || infRstdEmpr.tipInfRstdEmpr == null ? null : infRstdEmpr.tipInfRstdEmpr.txDcrUsuTipInfRstdEmpr );

      tipInfSelected.infRstdEmprArray = new Array< InfRstdEmpr >();

      tipInfArray.push( tipInfSelected );
    }

    //Adiciona a informacao ao array de tipos de informacao.
    tipInfSelected.infRstdEmprArray.push( infRstdEmpr );
  }
}

import { Component, OnInit              } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { SessionService } from '../../_services/session/session.service';
import { AuthenticationService } from '../../_services/authentication/authentication.service';

export abstract class LogoutComponent implements OnInit {
  public logoutForm: FormGroup;
  public returnUrl : string;

  public _isEnableChangePassword: FormControl = new FormControl();
  public _actualPwdForm   : FormControl = new FormControl( '', [ Validators.required, Validators.minLength(1) ] );
  public _newPwdForm      : FormControl = new FormControl( '', [ Validators.required, Validators.minLength(1) ] );
  public _newPwdRepeatForm: FormControl = new FormControl( '', [ Validators.required, Validators.minLength(1) ] );
  public _isHidePassword  : boolean = true;

  constructor( public formBuilder: FormBuilder,
               public route: ActivatedRoute,
               public router: Router,
               public sessionService: SessionService,
               public authenticationService: AuthenticationService ){
    // redirect to home if not already logged in
    if( !this.sessionService.currentUserValue ) {
      //console.log( 'this.sessionService.currentUserValue is false' );
      this.router.navigate(['/']);
    }
    else{
      //console.log( 'this.authenticationService.currentUserValue is true' );
    }
  }

  ngOnInit() {
    this.logoutForm = this.formBuilder.group({
        username: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams[ 'returnUrl' ] || '/';

    this._isEnableChangePassword.setValue( false );
  }

  onSubmit() {
  }

  public onEnableChangePassword(): void{
    this._isEnableChangePassword.setValue( true );
  }

  public onCancelChangePassword(): void{
    this._isEnableChangePassword.setValue( false );
  }

  public onChangePassword(): void{
    const cdUsr = this.sessionService.currentUserValue.id;

    //Obtem as senhas digitadas.
    const passActual   : string = this._actualPwdForm.value;
    const passNew      : string = this._newPwdForm.value;
    const passNewRepeat: string = this._newPwdRepeatForm.value;

    const countPassActual    = passActual    != null ? passActual.length : 0;
    const countPassNew       = passNew       != null ? passNew.length : 0;
    const countPassNewRepeat = passNewRepeat != null ? passNewRepeat.length : 0;

    //Verifica se todas as senhas foram preenchidas.
    if( countPassActual === 0 || countPassNew === 0 || countPassNewRepeat === 0 ){
      window.alert( 'Todas os campos de senha devem ser preenchidos.' );
      return;
    }

    //Verifica se a nova senha digitada e a repetida sao iguais.
    if( passNew != passNewRepeat ){
      window.alert( 'A nova senha informada é diferente da senha repetida.' );
      return;
    }

    //Verifica se a nova senha digitada é diferente da atual.
    if( passActual === passNew ){
      window.alert( 'A nova senha informada é igual à atual.' );
      return;
    }

    //Chama servico para solicitar a alteracao da senha.
    this.authenticationService.changePassword( cdUsr, passActual, passNew )
      .then( data => {
        window.alert( 'Senha alterada com sucesso.' );

        this._isEnableChangePassword.setValue( false );
      } )
      .catch( error => {
        window.alert( error );
      } );
  }

  public onLogout(): void{
    try{
      this.authenticationService.logout();

      this.router.navigate( [this.returnUrl] );
    }
    catch( error ){
      console.log( 'error: ' + error );
    }
    finally{
      console.log( 'authenticationService.logout().' );
    }
  }
}

import { Component, ViewEncapsulation, Inject     } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { CarteiraDialogEditComponent } from '../carteira-dialog-edit.component';
import { GrCrctInvService } from 'src/app/_services/grCrctInv/grCrctInv.service';


@Component({
  selector: 'app-carteira-dialog-edit-desktop',
  templateUrl: './carteira-dialog-edit-desktop.component.html',
  styleUrls: ['./carteira-dialog-edit-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CarteiraDialogEditDesktopComponent extends CarteiraDialogEditComponent {
  constructor( public dialogRef: MatDialogRef< CarteiraDialogEditDesktopComponent >,
               protected formBuilder           : FormBuilder            ,
               protected dialog                : MatDialog              ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService             ,
               protected grCrctInvService      : GrCrctInvService       ,
               @Inject( MAT_DIALOG_DATA ) public data: any ){
    super( dialogRef, formBuilder, dialog, aplicationStateService, authenticationService, prmUsrService, invService, grCrctInvService, data );
  }

  ngOnInit() {
    //console.log( 'CarteiraDialogEditDesktopComponent.ngOnInit()...' );
    super.ngOnInit();

    //console.log( 'CarteiraDialogEditDesktopComponent.ngOnInit().' );
  }

}

import { Component, OnInit, ViewChild   } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { MatSnackBar            } from '@angular/material/snack-bar';

import { InvListComponent       } from '../inv-list.component';

import { AplicationStateService } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '@services/authentication/authentication.service';
import { InvService             } from '@services/inv/inv.service';
import { PrmUsrService          } from '@services/prmUsr/prmUsr.service';


@Component({
  selector: 'app-inv-list-mobile',
  templateUrl: '../desktop/inv-list-desktop.component.html',
  styleUrls: ['./inv-list-mobile.component.css']
})
export class InvListMobileComponent extends InvListComponent {
  constructor( protected router: Router                                ,
               protected formBuilder: FormBuilder                      ,
               protected dialog                : MatDialog             ,
               sanitizer: DomSanitizer                                 ,
               aplicationStateService          : AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService ) { 
    super( router, formBuilder, dialog, sanitizer, aplicationStateService, authenticationService, prmUsrService, snackBar, invService );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

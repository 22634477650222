export class Estrtg{
  public dtRefEstrtg: Date;
  public cdInv: number;
  public sglEttgst: string;
  public tipOrdem: TipOrdem;
  public vlStart: number;
  public vlStopPerda: number;
  public vlStopGanho: number;
  public qtdMaxDias: number;
  public sglTipRsltdOrdem: string;
  public dtRsltdFnlOrdem: Date;
  public txInfoEttgst: string;
  public txInfoAvldr: string;
}

export class TipOrdem{
  public cdTipOrdem: number;
  public nmTipOrdem: string;
}


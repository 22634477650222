import { IndTcn, HstIndTcn 				} from 'src/app/_models/indTcn';
import { ControlLifeCycleCrctInv } from './controlLifeCycleCrctInv';
import { BasicInfCrctInvAbstract, InfCrctInvInterface, TipInfCrctInv } from './infCrctInv';
import { IndTcnService } from 'src/app/_services/indTcn/indTcn.service';
import { Observable } from 'rxjs';
import { CrctInv } from '../inv';

export class InfCrctInvIndTcn extends BasicInfCrctInvAbstract< HstIndTcn >{
	private indTcnService: IndTcnService;
	private indTcn: IndTcn;
	private perdcList: number[];
	private perdcSelected: number;

	constructor( ticker: CrctInv, indTcnService: IndTcnService, indTcn: IndTcn, perdcList: number[] ){
		super( ticker );

		this.indTcnService = indTcnService;

		this.indTcn = indTcn;
		this.perdcList = perdcList;
		this.perdcSelected = null;
	}

	public copy(): InfCrctInvInterface< HstIndTcn >{
		let copyObj = new InfCrctInvIndTcn( super.getTicker(), this.indTcnService, this.indTcn, this.perdcList );
		copyObj.setDtFromValues( this.getDtFromValues() );
		copyObj.setDtEndValues( this.getDtEndValues() );
		copyObj.setQtLimitValues( this.getQtLimitValues() );
		copyObj.setPerdcSelectedInfCrctInv( this.perdcSelected );
		copyObj.setTipViewSelectedInfCrctInv( this.getTipViewSelectedInfCrctInv() );
		copyObj.setIndexOfPointer( this.getIndexOfPointer() );

		return copyObj;
	}

	public isEqualIndTcn( infCrctInv: InfCrctInvIndTcn ): boolean{
		if( infCrctInv === null || infCrctInv instanceof InfCrctInvIndTcn ){
			const infCrctInv2: InfCrctInvIndTcn = infCrctInv as InfCrctInvIndTcn;
			const indTcn2 = infCrctInv2.getIndTcn();
			const indTcn1 = this.getIndTcn();

			return indTcn1.sgIndTcn === indTcn2.sgIndTcn && indTcn1.snlTcn.sgSnlTcn === indTcn2.snlTcn.sgSnlTcn;
		}
		else{
			return false;
		}
	}

	public getNmInfCrctInv(){
		let nm = this.getSnlTcn().sgSnlTcn;

		if( nm !== this.indTcn.sgIndTcn ){
			nm += ' / ' + this.indTcn.sgIndTcn;
		}

		return nm;
	}

	public getNmInfCrctInvViewed(){
		let nm = this.getNmInfCrctInv();

		const perdc = this.getPerdcSelectedInfCrctInv();

		if( perdc != null ){
			nm += ' (' + perdc + ')';
		}

		return nm;
	}

	public getTipInfCrctInv(): TipInfCrctInv{
		return TipInfCrctInv.IND_TCN;
	}

	public getPerdcListInfCrctInv(): number[]{
		return this.perdcList;
	}

	public setPerdcListInfCrctInv( perdcList: number[] ): void{
		this.perdcList = perdcList;
	}

	public addPerdcListInfCrctInv( perdc: number ): void{
		if( this.perdcList == null ){
			this.perdcList = [];
		}

		//Adiciona o elemento na posicao correta, pressupondo que a lista esteja ordenada.
		const qtPerdc = this.perdcList != null ? this.perdcList.length : 0;
		let indexPerdc = 0;

		for( ; indexPerdc < qtPerdc; indexPerdc++ ){
			const perdcIterate = this.perdcList[ indexPerdc ];

			if( perdcIterate > perdc ){
				break;
			}
		}

		this.perdcList.splice( indexPerdc, 0, perdc );
	}

	public getPerdcSelectedInfCrctInv(): number{
		return this.perdcSelected;
	}

	public setPerdcSelectedInfCrctInv( perdc: number ): void{
		this.perdcSelected = perdc;
	}

	public getSnlTcn(){
		return this.indTcn.snlTcn;
	}

	public getIndTcn(){
		return this.indTcn;
	}

	public getDateRefOfValueOfIndex( index: number ): Date{
		const value: HstIndTcn = this.getValueOfIndex( index );

		return value != null ? value.dtIndTcn : null;
	}

	public nextValueOfPointerIfEqualDateRef( dateRef: Date ): HstIndTcn{
		const maxIndexOfPointer = super.getQtValues();
		const indexOfPointer    = super.getIndexOfPointer();

		if( indexOfPointer < maxIndexOfPointer ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer + 1 );
			const dateOfValue    = valueInPointer.dtIndTcn;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue != null && dateRef != null && dateOfValue.getTime() === dateRef.getTime() ) ){
				return super.nextValueOfPointer();
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public getValueOfPointerIfEqualDateRefAndMovePointerToNext( dateRef: Date ): HstIndTcn{
		const maxIndexOfPointer = super.getQtValues();
		const indexOfPointer    = super.getIndexOfPointer();

		if( indexOfPointer <= maxIndexOfPointer ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer );
			const dateOfValue    = valueInPointer.dtIndTcn;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue !== null && dateRef !== null && dateOfValue.getTime() === dateRef.getTime() ) ){
				super.incrPointer( false );
				return valueInPointer;
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public prevValueOfPointerIfEqualDateRef( dateRef: Date ): HstIndTcn{
		const indexOfPointer = super.getIndexOfPointer();

		if( indexOfPointer > 0 ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer - 1 );
			const dateOfValue    = valueInPointer.dtIndTcn;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue !== null && dateRef !== null && dateOfValue.getTime() === dateRef.getTime() ) ){
				return super.prevValueOfPointer();
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public getValueOfPointerIfEqualDateRefAndMovePointerToPrev( dateRef: Date ): HstIndTcn{
		const indexOfPointer = super.getIndexOfPointer();

		if( indexOfPointer >= 0 ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer );
			const dateOfValue    = valueInPointer != null ? valueInPointer.dtIndTcn : null;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue !== null && dateRef !== null && dateOfValue.getTime() === dateRef.getTime() ) ){
				super.decrPointer( false );
				return valueInPointer;
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public refreshValues(): Promise< HstIndTcn[] >{
		super.setValues( null );

		const ticker 		= super.getTicker();
		const sgSnlTcn 		= this.getSnlTcn().sgSnlTcn;
		const sgIndTcn 		= this.getIndTcn().sgIndTcn;
		const perdc 		= this.getPerdcSelectedInfCrctInv();
		const dtFromValues 	= super.getDtFromValues();
		const dtEndValues 	= super.getDtEndValues();
		const qtLimitValues = super.getQtLimitValues();

		const _this = this;

		return new Promise( function( resolve, reject ){
			_this.indTcnService.listHst( ticker.cdInv, sgSnlTcn, sgIndTcn, perdc, dtFromValues, dtEndValues, qtLimitValues )
				.then( indTcnList => {
					indTcnList.forEach( ( indTcn, indexIndTcn ) => {
						if( indTcn != null && ( typeof indTcn.dtIndTcn === 'string' || indTcn.dtIndTcn instanceof String ) ){
							indTcnList[indexIndTcn].dtIndTcn = new Date( indTcn.dtIndTcn );
						}
					} );

					_this.setValues( indTcnList );
					resolve( indTcnList );
				})
				.catch( error => {
					reject( error );
				})
		});
	}
}

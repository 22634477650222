<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Cadastrar Nova Senha</h2>

<form [formGroup]="this._form">
  <div class="grid_container_login">
    <div class="grid_item_login_header">
			Informe uma nova senha.
    </div>

		<div class="grid_item_login_nmUsr">
			<label class="label_value">Usuário: {{this._nmUsr}}</label>
		</div>

		<div class="grid_item_login_email">
			<label class="label_value">E-mail: {{this._email}}</label>
		</div>

    <div class="grid_item_login_pass">
			<mat-form-field appearance="fill">
				<mat-label>Senha:</mat-label>
				<input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._pwdForm" required>
				<button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
					<mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
    </div>

    <div class="grid_item_login_passRepeat">
			<mat-form-field appearance="fill">
				<mat-label>Confirmar Senha:</mat-label>
				<input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._pwdRepeatForm" required>
				<button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
					<mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
    </div>

    <div class="grid_item_login_button">
			<button (click)="this.onChangePassword()" class="button">Cadastrar Nova Senha</button>
    </div>
  </div>
</form>
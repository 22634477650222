import { Component, OnInit, ViewChild         } from '@angular/core';
import { DomSanitizer                         } from '@angular/platform-browser';
import { ActivatedRoute, Router               } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { MatDialog                            } from '@angular/material/dialog';

import { AplicationStateService } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '../../../_services/authentication/authentication.service';
import { AbstractMainComponent  } from '../../abstract-main.component';
import { UsrService             } from 'src/app/_services/usr/usr.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class LoginAddComponent extends AbstractMainComponent{
  public _form;

  public _nameForm     : FormControl = new FormControl( '', [ Validators.required ] );
  public _usernameForm : FormControl = new FormControl( '', [ Validators.required ] );
  public _emailForm    : FormControl = new FormControl( '', [ Validators.required, Validators.email ] );
  public _pwdForm      : FormControl = new FormControl( '', [ Validators.required ] );
  public _pwdRepeatForm: FormControl = new FormControl( '', [ Validators.required ] );

  public _isHidePassword: boolean = true;

  constructor( protected router: Router,
               protected activatedRoute: ActivatedRoute,
               protected sanitizer: DomSanitizer,
               protected formBuilder: FormBuilder,
               protected dialog: MatDialog,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected usrService: UsrService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    this._form = this.createForm();
  }

  private createForm(){
    let dtCotcFrom = new Date();
    dtCotcFrom.setDate( dtCotcFrom.getDate() - 90 );

    return this.formBuilder.group(
      {  }
    );
  }

  public getErrorMessageOfEmailForm() {
    if( this._emailForm.hasError( 'required' ) ){
      return 'Informe um e-mail.';
    }

    return this._emailForm.hasError( 'email' ) ? 'E-mail informado não é válido' : '';
  }

  onSubmit(){
    console.log( 'onSubmit()...' );

    console.log( 'onSubmit().' );
  }

  protected refresh(){
  }

  public onAddUser(): void{
    const nmPss     = this._nameForm.value;
    const nmUsr     = this._usernameForm.value;
    const email     = this._emailForm.value;
    const pwd       = this._pwdForm.value;
    const pwdRepeat = this._pwdRepeatForm.value;

    if( nmPss != null && nmUsr != null && email != null && pwd != null && pwdRepeat != null ){
      if( this._nameForm.valid && this._usernameForm.valid && this._emailForm.valid && this._pwdForm.valid && this._pwdRepeatForm.valid ){
        if( pwd === pwdRepeat ){
          const _this = this;

          this.usrService.addUsr( nmPss, nmUsr, email, pwd )
            .then( response => {
              console.log( 'usrService.addUsr - response: ' + Object.keys( response ) );

              window.alert( 'Usuário adicionado com sucesso.' );

              _this.authenticationService.login( nmUsr, pwd );
            } )
            .catch( error => {
              window.alert( error.msgErr );
            } );
        }
        else{
          window.alert( 'Senhas informadas estão diferentes.' );
        }
      }
    }
  }
}

<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Empresas do Setor</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Setor
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_setor_detail_setorEcnm">
			<ng-template [ngIf]="this._segmEcnm != null && this._segmEcnm.subSetorEcnm.setorEcnm != null">
				<div class="grid_item_setor_detail_setorEcnm_setor_label">
					<div class="label_key">
						Setor:
					</div>
				</div>

				<div class="grid_item_setor_detail_setorEcnm_setor_value">
					<ng-template *ngIf="this._segmEcnm.subSetorEcnm.cdSubSetorEcnm != null; then thenSetorBlock else elseSetorBlock" ></ng-template>
					<ng-template #thenSetorBlock>
						<a *ngIf="this._segmEcnm != null" [routerLink]="" routerLinkActive="active" (click)="detailSetorEcnm( $event, this._segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm )"> 
							{{this._segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
						</a>
					</ng-template>
					<ng-template #elseSetorBlock>
						{{this._segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
					</ng-template>
				</div>

				<ng-template [ngIf]="this._segmEcnm != null && this._segmEcnm.subSetorEcnm.cdSubSetorEcnm != null">
					<div class="grid_item_setor_detail_setorEcnm_subSetor_label">
						<div class="label_key">
							Sub-setor:
						</div>
					</div>

					<div class="grid_item_setor_detail_setorEcnm_subSetor_value">
						<ng-template *ngIf="this._segmEcnm.cdSegmEcnm != null; then thenSubsetorBlock else elseSubsetorBlock" ></ng-template>
						<ng-template #thenSubsetorBlock>
							<a *ngIf="this._segmEcnm != null" [routerLink]="" routerLinkActive="active" (click)="detailSubsetorEcnm( $event, this._segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, this._segmEcnm.subSetorEcnm.cdSubSetorEcnm )"> 
								{{this._segmEcnm.subSetorEcnm.nmSubSetorEcnm}}
							</a> 
						</ng-template>
						<ng-template #elseSubsetorBlock>
							{{this._segmEcnm.subSetorEcnm.nmSubSetorEcnm}}
						</ng-template>
					</div>

					<ng-template [ngIf]="this._segmEcnm.cdSegmEcnm != null">
						<div class="grid_item_setor_detail_setorEcnm_segmento_label">
							<div class="label_key">
								Segmento:
							</div>
						</div>

						<div class="grid_item_setor_detail_setorEcnm_segmento_value">
							<label *ngIf="this._segmEcnm != null" class="label_value">{{this._segmEcnm.nmSegmEcnm}}</label>
						</div>
					</ng-template>
				</ng-template>
			</ng-template>

			<ng-template [ngIf]="this._setorAtvdd != null && this._setorAtvdd.cdSetorAtvdd != null">
				<div class="grid_item_setor_detail_setorEcnm_setorAtvdd_label">
					<div class="label_key">
						Setor da Atividade (CVM):
					</div>
				</div>

				<div class="grid_item_setor_detail_setorEcnm_setorAtvdd_value">
					{{this._setorAtvdd.nmSetorAtvdd}}
				</div>
			</ng-template>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Empresas
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_setor_detail_emprList">
			<div class="grid_item_setor_detail_mostrarOcultar">
				<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false">
					<mat-expansion-panel-header class="label_title_panel_right">
						<mat-panel-title>
							Mostrar/Ocultar Colunas:
						</mat-panel-title>
					</mat-expansion-panel-header>

					<div class="grid_container_setor_detail_emprList_mostrarOcultar">
						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnSetor label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnSetor" >
								Setor/Subsetor/Segmento
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnTicker label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnTicker" >
								Ticker Principal
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnVarPercFrom label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnVarPercFrom" >
								<mat-form-field>
									<mat-label>&Delta; % Desde</mat-label>

									<input matInput 
										[matDatepicker]="picker" 
										formControlName="dtVarPercFrom"
										(ngModelChange)="updateDtVarPercFrom( $event )" >
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnUltPerdCntb label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnUltPerdCntb" >
								Últ. Resultado Divulgado
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnLp label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnLp" >
								Lucro/Prejuízo (L/P)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnPl label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnPl" >
								Patrimônio Líquido (PL)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnVpa label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnVpa" >
								Valor Patrimonial por Ação (VPA)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnMc label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnMc" >
								Valor de Mercado ou <i>Market Cap</i> (MC)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnEv label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnEv" >
								Valor da Firma (EV)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnEbitda label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnEbitda" >
								EBITDA
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnLa label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnLa" >
								Lucro por Ação (L/A)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnRoa label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnRoa" >
								Retorno sobre Ativo (ROA)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnRoe label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnRoe" >
								Retorno sobre Patrimônio Líquido (ROE)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnPe label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnPe" >
								Preço sobre Lucro (P/E)
							</mat-checkbox>
						</div>

						<div class="grid_item_setor_detail_mostrarOcultar_isShowColumnPvpa label_showColumn">
							<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnPvpa" >
								Preço sobre VPA (P/VPA)
							</mat-checkbox>
						</div>
					</div>
				</mat-expansion-panel>
			</div>

			<div class="grid_item_setor_detail_checkIsOnlyMonitored">
				<mat-checkbox (change)="updateOnlyEmprMonitored()" formControlName="isOnlyEmprMonitored" >
					Apenas as empresas monitoradas
				</mat-checkbox>
			</div>

			<div class="grid_item_setor_detail_emprList_value">
				<table mat-table [dataSource]="_emprDataSource" matSort >
					<ng-container matColumnDef="nmEmpr" sticky>
						<th mat-header-cell *matHeaderCellDef mat-sort-header > Empresa </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailEmpr( $event, element.cdEmpr )" class="linkInTable">
								<div *ngIf="element.nmAbrvEmpr != null; then thenBlock else elseBlock" ></div>
								<ng-template #thenBlock>
									<span>{{element.nmAbrvEmpr}}</span>
								</ng-template>
								<ng-template #elseBlock>
									<span>{{element.nmEmpr}}</span>
								</ng-template>
							</a>
							<mat-icon class="icon_help" 
								matTooltip="Cód.: {{element.cdEmpr}}
											Situação: {{element.tipSitEmpr.nmTipSitEmpr}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="setorEcnm">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > Setor </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailSetorEcnm( $event, element.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm )" class="linkInTable"> 
								{{element.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="subSetorEcnm">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > Subsetor </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailSubsetorEcnm( $event, element.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, element.segmEcnm.subSetorEcnm.cdSubSetorEcnm )" class="linkInTable"> 
								{{element.segmEcnm.subSetorEcnm.nmSubSetorEcnm}}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="segmEcnm">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > Segmento </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailSegmEcnm( $event, element.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, element.segmEcnm.subSetorEcnm.cdSubSetorEcnm, element.segmEcnm.cdSegmEcnm )" class="linkInTable"> 
								{{ element.segmEcnm.nmSegmEcnm }}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="ticker">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > Ticker </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.invList != null && element.invList[0].crctInvAcao != null" routerLinkActive="active" (click)="detailInv( $event, element.invList[0].cdInv )" class="linkInTable"> 
								{{element.invList[0].nmInv}}: 
								<label *ngIf="element.invList[0].crctInvAcao.hstCotcAcao != null">
									{{element.invList[0].crctInvAcao.hstCotcAcao[0].prcFech | number:'1.2-2'}}
									<mat-icon class="icon_help" 
										matTooltip="Cotação de: {{element.invList[0].crctInvAcao.hstCotcAcao[0].dtCotc | date:'dd/MM/yyyy':'+0000'}}
													Abert.: {{element.invList[0].crctInvAcao.hstCotcAcao[0].prcAbert | number:'1.2-2'}} 
													Mín./Máx.: {{element.invList[0].crctInvAcao.hstCotcAcao[0].prcMin | number:'1.2-2'}} - {{element.invList[0].crctInvAcao.hstCotcAcao[0].prcMax | number:'1.2-2'}}
													Var.: {{element.invList[0].crctInvAcao.hstCotcAcao[0].varPrcFech | number:'1.2-2'}}%"
										[matTooltipClass]="'multline-tooltip'" >info
									</mat-icon>
								</label>
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="varPercFrom">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > &Delta; % </th>
						<td mat-cell *matCellDef="let element"> 
							<label *ngIf="element.invList != null && element.invList[0].crctInvAcao != null && element.invList[0].crctInvAcao.hstCotcAcao != null && element.invList[0].crctInvAcao.hstCotcAcao.length == 2 && element.invList[0].crctInvAcao.hstCotcAcao[0] != null && element.invList[0].crctInvAcao.hstCotcAcao[1] != null">
								{{ ( ( element.invList[0].crctInvAcao.hstCotcAcao[0].prcFech - element.invList[0].crctInvAcao.hstCotcAcao[1].prcAbert ) / element.invList[0].crctInvAcao.hstCotcAcao[1].prcAbert * 100.00 ) | number:'1.2-2'}}
								<mat-icon class="icon_help" 
									matTooltip="Cotação de: {{element.invList[0].crctInvAcao.hstCotcAcao[1].dtCotc | date:'dd/MM/yyyy':'+0000'}}
												Abert.: {{element.invList[0].crctInvAcao.hstCotcAcao[1].prcAbert | number:'1.2-2'}} 
												Mín./Máx.: {{element.invList[0].crctInvAcao.hstCotcAcao[1].prcMin | number:'1.2-2'}} - {{element.invList[0].crctInvAcao.hstCotcAcao[1].prcMax | number:'1.2-2'}}
												Var.: {{element.invList[0].crctInvAcao.hstCotcAcao[1].varPrcFech | number:'1.2-2'}}%"
									[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</label>
						</td>
					</ng-container>

					<ng-container matColumnDef="ultPerdCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							Período Ref.
							<mat-icon class="icon_help" matTooltip="Período do último resultado contábil divulgado pela empresa">help</mat-icon>
						</th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.rstdEmpr.rstdEmpr != null && element.rstdEmpr.rstdEmpr.aaRefPerdc > 0">
								{{element.rstdEmpr.rstdEmpr.aaRefPerdc}}
								<span *ngIf="element.rstdEmpr.rstdEmpr.nrRefPerdc > 0">
									/ {{element.rstdEmpr.rstdEmpr.nrRefPerdc}}
								</span>
								<br>
								<mat-icon *ngIf="element.rstdEmpr.rstdEmpr.perdc && element.rstdEmpr.rstdEmpr.perdc != null" class="icon_help" 
									matTooltip="{{element.rstdEmpr.rstdEmpr.perdc.nmPerdc}}"
									[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="varPercLp">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							&Delta; % L/P
							<mat-icon class="icon_help" matTooltip="Variação percentual do lucro/prejuízo">help</mat-icon>
						</th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.tipInfRstdList && element.tipInfRstdList != null">
								<div *ngIf="element.tipInfRstdList[0].infRstdEmprArray[0].getVarPercVlRstdEmpr() > 0; then varPosVlRstdThenBlock else varNegVlRstdThenBlock" ></div>
								<ng-template #varPosVlRstdThenBlock>
									<span class="mat-cell-value-positive">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.tipInfRstdList[0].infRstdEmprArray[0].getVarPercVlRstdEmpr() | number:'1.0-0'}}%
									</span>
								</ng-template>
								<ng-template #varNegVlRstdThenBlock>
									<span class="mat-cell-value-negative">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_down" >arrow_downward</mat-icon> {{element.tipInfRstdList[0].infRstdEmprArray[0].getVarPercVlRstdEmpr()*(-1) | number:'1.0-0'}}%
									</span>
								</ng-template>
								<mat-icon class="icon_help" 
									matTooltip="Lucro/Prejuízo nos períodos ({{element.tipInfRstdList[0].infRstdEmprArray[0].perdc.nmPerdc}})
												{{element.tipInfRstdList[0].infRstdEmprArray[0].aaRefPerdc}}/{{element.tipInfRstdList[0].infRstdEmprArray[0].nrRefPerdc}}: R$ {{element.tipInfRstdList[0].infRstdEmprArray[0].vlRstdEmpr | shortNumber:'1.2-2'}}
												{{element.tipInfRstdList[0].infRstdEmprArray[1].aaRefPerdc}}/{{element.tipInfRstdList[0].infRstdEmprArray[1].nrRefPerdc}}: R$ {{element.tipInfRstdList[0].infRstdEmprArray[1].vlRstdEmpr | shortNumber:'1.2-2'}}"
									[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="rstdEmprLp">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							L/P
							<mat-icon class="icon_help" matTooltip="Lucro/Prejuízo acumulado nos últimos 12 meses.">help</mat-icon>
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.rstdEmpr.lpAtrbdSocCntr != null" class="linkInTable"> 
								<div *ngIf="element.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr >= 0; then lpThenBlock else lpElseBlock" ></div>
								<ng-template #lpThenBlock>
									<span class="label-number-positive">{{ element.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #lpElseBlock>
									<span class="label-number-negative">{{ element.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.rstdEmpr.lpAtrbdSocCntr != null && element.rstdEmpr.lpAtrbdSocCntr.dtPblccRef != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.rstdEmpr.lpAtrbdSocCntr.dtPblccRef | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="rstdEmprPl">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							PL 
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailSegmEcnm( $event, element.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, element.segmEcnm.subSetorEcnm.cdSubSetorEcnm, element.segmEcnm.cdSegmEcnm )" class="linkInTable"> 
								{{ element.rstdEmpr.pl.vlRstdEmpr | shortNumber:'1.2-2' }}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprVpa">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							VPA 
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.vpa != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.vpa.vlInfComplEmpr >= 0; then vpaThenBlock else vpaElseBlock" ></div>
								<ng-template #vpaThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.vpa.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #vpaElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.vpa.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr.vpa != null && element.infComplEmpr.vpa.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.vpa.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprMc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							MC <br>
							(R$)
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.mc != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.mc.vlInfComplEmpr >= 0; then mcThenBlock else mcElseBlock" ></div>
								<ng-template #mcThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.mc.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #mcElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.mc.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr != null && element.infComplEmpr.mc != null && element.infComplEmpr.mc.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.mc.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprEv">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							EV <br>
							(R$)
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.ev != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.ev.vlInfComplEmpr >= 0; then evThenBlock else evElseBlock" ></div>
								<ng-template #evThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.ev.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #evElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.ev.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr != null && element.infComplEmpr.ev != null && element.infComplEmpr.ev.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.ev.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprEbitda">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							EBITDA <br>
							(R$)
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.ebitda != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.ebitda.vlInfComplEmpr >= 0; then ebitdaThenBlock else ebitdaElseBlock" ></div>
								<ng-template #ebitdaThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.ebitda.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #ebitdaElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.ebitda.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr != null && element.infComplEmpr.ebitda != null && element.infComplEmpr.ebitda.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.ebitda.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprLa">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							L/A<br>
							(R$)
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.la != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.la.vlInfComplEmpr >= 0; then laThenBlock else laElseBlock" ></div>
								<ng-template #laThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.la.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #laElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.la.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr != null && element.infComplEmpr.la != null && element.infComplEmpr.la.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.la.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprRoa">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							ROA<br>
							(%)
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.roa != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.roa.vlInfComplEmpr >= 0; then roaThenBlock else roaElseBlock" ></div>
								<ng-template #roaThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.roa.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #roaElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.roa.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr.roa != null && element.infComplEmpr.roa.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.roa.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="infCompleEmprRoe">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							ROE<br>
							(%)
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" *ngIf="element.infComplEmpr != null && element.infComplEmpr.roe != null" class="linkInTable"> 
								<div *ngIf="element.infComplEmpr.roe.vlInfComplEmpr >= 0; then roeThenBlock else roeElseBlock" ></div>
								<ng-template #roeThenBlock>
									<span class="label-number-positive">{{ element.infComplEmpr.roe.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
								<ng-template #roeElseBlock>
									<span class="label-number-negative">{{ element.infComplEmpr.roe.vlInfComplEmpr | shortNumber:'1.2-2' }}</span>
								</ng-template>
							</a> 
							<mat-icon *ngIf="element.infComplEmpr.roe != null && element.infComplEmpr.roe.dtRefInfComplEmpr != null" class="icon_help" 
								matTooltip="Data Ref.: {{element.infComplEmpr.roe.dtRefInfComplEmpr | date:'dd/MM/yyyy':'+0000'}}"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="indTcnEmprPe">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							P/E
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" class="linkInTable"> 
								{{ this.getVlIndTcn( element, 'P', 'P/E' ) | shortNumber:'1.2-2' }}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="indTcnEmprPvpa">
						<th mat-header-cell *matHeaderCellDef mat-sort-header > 
							P/VPA
						</th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" class="linkInTable"> 
								{{ this.getVlIndTcn( element, 'P', 'P/VPA' ) | shortNumber:'1.2-2' }}
							</a> 
						</td>
					</ng-container>

					<ng-container matColumnDef="options">
					<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #appMenu="matMenu">
								<button mat-menu-item (click)="detailEmpr( $event, element.cdEmpr )" type="submit">Detalhar Ticker</button>
							</mat-menu>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="this._emprColumnsDisplayed"></tr>
					<tr mat-row *matRowDef="let row; columns: this._emprColumnsDisplayed;"></tr>
				</table>

				<mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
			</div>

			<div *ngIf="isEmprListEmpty" class="grid_item_setor_detail_emprList_empty">
				<label class="label_list_empty">Não há empresas vinculadas ao setor/sub-setor/segmento.</label>
			</div>
		</div>
	</mat-expansion-panel>
</form>
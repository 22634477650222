import { Component                      } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router         } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';

import { MatDialog                      } from '@angular/material/dialog';
import { MatSnackBar                    } from '@angular/material/snack-bar';

import { ScriptLoaderService            } from 'angular-google-charts';

import { DatePipe, DecimalPipe          } from '@angular/common';

import { ShortNumberPipe                } from 'src/app/_pipes/short-number/short-number.pipe';

import { TickerChartComponent           } from '../ticker-chart.component';

import { AplicationStateService         } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService          } from '@services/authentication/authentication.service';
import { InvService                     } from '@services/inv/inv.service';
import { CotcService                    } from '@services/cotc/cotc.service';
import { IndTcnService                  } from '@services/indTcn/indTcn.service';
import { PrmUsrService                  } from '@services/prmUsr/prmUsr.service';
import { InfCrctInvService              } from '@services/infCrctInv/infCrctInv.service';
import { GrCrctInvService               } from '@services/grCrctInv/grCrctInv.service';
import { TickerDialogSelectDesktopComponent } from '@components/ticker/dialog/select/desktop/ticker-dialog-select-desktop.component';
import { TickerDialogSelectMobileComponent } from '@components/ticker/dialog/select/mobile/ticker-dialog-select-mobile.component';

@Component({
  selector: 'app-ticker-chart-mobile',
  templateUrl: '../desktop/ticker-chart-desktop.component.html',
  styleUrls: ['./ticker-chart-mobile.component.css']
})
export class TickerChartMobileComponent extends TickerChartComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected datepipe              : DatePipe              ,
               protected decimalPipe           : DecimalPipe           ,
               protected shortNumber           : ShortNumberPipe       ,
               protected loaderService         : ScriptLoaderService   ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService            ,
               protected cotcService           : CotcService           ,
               protected indTcnService         : IndTcnService         ,
               protected infCrctInvService     : InfCrctInvService     ,
               protected grCrctInvService      : GrCrctInvService        ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, datepipe, decimalPipe, shortNumber, loaderService, aplicationStateService, authenticationService, prmUsrService, snackBar, invService, cotcService, indTcnService, infCrctInvService, grCrctInvService, TickerDialogSelectMobileComponent );
  }
}

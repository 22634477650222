export class Perdc {
  public sglPerdc: string;
  public nmPerdc: string;

  public constructor( sglPerdc?: string, nmPerdc?: string ){
    this.sglPerdc = sglPerdc ? sglPerdc : null;
    this.nmPerdc  = nmPerdc ? nmPerdc : null;
  }
}

export class PerdcRef {
  public aaRefPerdc: number;
  public nrRefPerdc: number;
  public perdc     : Perdc;
}

export class PerdcIndTcn {
  public perdcIndTcn: number;
}

export class PerdcSelectionChange{
  public perdc: Perdc;
  public isSelected: boolean;

  public constructor( perdc?: Perdc, isSelected?: boolean ){
    this.perdc      = perdc ? perdc : null;
    this.isSelected = isSelected ? isSelected : null;
  }
}
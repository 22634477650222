import { Component, ChangeDetectorRef                    } from '@angular/core';
import { DomSanitizer                 } from '@angular/platform-browser';
import { ActivatedRoute, Router       } from '@angular/router';
import { FormBuilder                  } from '@angular/forms';
import { MatDialog                    } from '@angular/material/dialog';

import { CarteiraMvtcComponent        } from '../carteira-mvtc.component';

import { AplicationStateService       } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService        } from '../../../../_services/authentication/authentication.service';
import { InvService                   } from '../../../../_services/inv/inv.service';
import { DtqService                   } from 'src/app/_services/dtq/dtq.service';
import { PrmUsrService                              } from 'src/app/_services/prmUsr/prmUsr.service';
import { DatePipe, DecimalPipe                      } from '@angular/common';
import { ShortNumberPipe                            } from 'src/app/_pipes/short-number/short-number.pipe';
import { GrCrctInvService                           } from '@services/grCrctInv/grCrctInv.service';
import { MvtcGrCrctInvService                       } from '@services/mvtcGrCrctInv/mvtcGrCrctInv.service';
import { MatSnackBar                                } from '@angular/material/snack-bar';
import { CarteiraMvtcDialogAddDesktopComponent      } from '../dialog/add/desktop/carteira-mvtc-dialog-add-desktop.component';
import { GeneralDialogConfirmDesktopComponent       } from '@components/general/dialog/confirm/desktop/general-dialog-confirm-desktop.component';
import { GeneralDialogInputMessageDesktopComponent  } from '@components/general/dialog/inputMessage/desktop/general-dialog-inputMessage-desktop.component';
import { AssistService } from '@services/assist/assist.service';

@Component({
  selector: 'app-carteira-mvtc-desktop',
  templateUrl: './carteira-mvtc-desktop.component.html',
  styleUrls: ['./carteira-mvtc-desktop.component.css']
})
export class CarteiraMvtcDesktopComponent extends CarteiraMvtcComponent {
  constructor( protected router                : Router                               ,
               protected activatedRoute        : ActivatedRoute                       ,
               protected sanitizer             : DomSanitizer                         ,
               protected formBuilder           : FormBuilder                          ,
               protected dialog                : MatDialog                            ,
               protected datepipe              : DatePipe                             ,
               protected decimalPipe           : DecimalPipe                          ,
               protected shortNumberPipe       : ShortNumberPipe                      ,
               protected aplicationStateService: AplicationStateService               ,
               protected authenticationService : AuthenticationService                ,
               protected prmUsrService         : PrmUsrService                        ,
               protected snackBar              : MatSnackBar                          ,
               protected invService            : InvService                           ,
               protected grCrctInvService      : GrCrctInvService                     ,
               protected mvtcGrCrctInvService  : MvtcGrCrctInvService                 ,
               protected assistService         : AssistService                        ,
               protected cdr: ChangeDetectorRef ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, datepipe, decimalPipe, shortNumberPipe, aplicationStateService, authenticationService, prmUsrService, snackBar, GeneralDialogConfirmDesktopComponent, GeneralDialogInputMessageDesktopComponent, CarteiraMvtcDialogAddDesktopComponent, invService, grCrctInvService, mvtcGrCrctInvService, assistService, cdr );
  }
}
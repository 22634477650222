import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

/**
 * 
 */
@Pipe({
  name: 'cnpjNumber'
})
export class CnpjNumberPipe implements PipeTransform {
  constructor( protected decimalPipe: DecimalPipe ){
  }

  /**
   * 12.345.678/9012-34
   * @param value 
   * @param args
   */
  transform( valueAny: any, args?: any ): String {
    if( valueAny === null ){
      return null;
    }

    let value = valueAny.toString();

    let cnpjStr = value.replace( /[^0-9]/, '' )       //Exclui qualquer caracter que não seja dígito.
                       .padStart( 14, '0' )           //Completa com zeros a esquerda. 
                       .substr( 0, 14 )               //Obtem apenas os primeiros 14 dígitos.
                       .replace( /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpjStr;
  }
}

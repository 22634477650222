import { Component, OnInit, ViewChild   } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router         } from '@angular/router';
import { FormBuilder, FormControl       } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { AplicationStateService } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { AbstractMainComponent } from '../../abstract-main.component';
import { Empr, InfComplEmpr, InfComplEmprList } from 'src/app/_models/empr';
import { EmprService } from 'src/app/_services/empr/empr.service';
import { MatTableDataSource } from '@angular/material/table';
import { SegmEcnm } from 'src/app/_models/setorEcnm';
import { SetorService } from 'src/app/_services/setor/setor.service';
import { MatPaginator } from '@angular/material/paginator';
import { SetorAtvdd } from 'src/app/_models/setorAtvdd';
import { SetorAtvddService } from 'src/app/_services/setor-atvdd/setor-atvdd.service';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InvService } from 'src/app/_services/inv/inv.service';
import { CotcService } from 'src/app/_services/cotc/cotc.service';
import { HstCotcAcao } from 'src/app/_models/cotc';
import { IceService } from 'src/app/_services/ice/ice.service';
import { IndTcnService } from 'src/app/_services/indTcn/indTcn.service';
import { HstIndTcn } from 'src/app/_models/indTcn';
import { PdcService } from 'src/app/_services/pdc/pdc.service';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { InfRstdEmpr, RstdEmpr, TipInfRstdEmprJoinInfRstdEmpr } from 'src/app/_models/rstdEmpr';
import { PerdcRef, Perdc } from 'src/app/_models/perdc';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class SetorDetailComponent extends AbstractMainComponent{
  public _segmEcnm: SegmEcnm;
  public _setorAtvdd: SetorAtvdd;

  public _emprColumnsDisplayed: string[] = [];
  public _emprColumnsDefinition: any[] = [];
  public _emprDataSource: MatTableDataSource< Empr >;
  @ViewChild( MatPaginator, {static: true} ) paginator: MatPaginator;
  @ViewChild( MatSort, {static: true} ) sort: MatSort;

  public isEmprListEmpty: boolean;

  public _form;

  constructor( protected router: Router,
               protected activatedRoute: ActivatedRoute,
               protected sanitizer: DomSanitizer,
               protected formBuilder: FormBuilder,
               protected dialog: MatDialog,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected setorService: SetorService,
               protected setorAtvddService: SetorAtvddService,
               protected emprService: EmprService,
               protected invService: InvService,
               protected cotcService: CotcService,
               protected iceService: IceService,
               protected indTcnService: IndTcnService,
               protected rstdEmprService: RstdEmprService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    this._form = this.createForm();

    this.activatedRoute.queryParams.subscribe( params => {
        const cdSetorEcnm    = params.cdSetorEcnm;
        const cdSubSetorEcnm = params.cdSubSetorEcnm;
        const cdSegmEcnm     = params.cdSegmEcnm;
        const cdSetorAtvdd   = params.cdSetorAtvdd;

        this.createNameOfDisplayedColumns( cdSetorEcnm, cdSubSetorEcnm, cdSegmEcnm );
        this.refresh( cdSetorEcnm, cdSubSetorEcnm, cdSegmEcnm, cdSetorAtvdd );
      }
    );
  }

  private createForm(){
    let dtCotcFrom = new Date();
    dtCotcFrom.setDate( dtCotcFrom.getDate() - 90 );

    return this.formBuilder.group(
      { isOnlyEmprMonitored    : new FormControl( true ),
        isShowColumnSetor      : new FormControl( false ),
        isShowColumnTicker     : new FormControl( false ),
        isShowColumnVarPercFrom: new FormControl( false ),
        dtVarPercFrom          : new FormControl( dtCotcFrom ),
        isShowColumnUltPerdCntb: new FormControl( false ),
        isShowColumnLp         : new FormControl( true ),
        isShowColumnPl         : new FormControl( true ),
        isShowColumnEbitda     : new FormControl( true ),
        isShowColumnPe         : new FormControl( true ),
        isShowColumnPvpa       : new FormControl( true ) ,
        isShowColumnVpa        : new FormControl( false ),
        isShowColumnMc         : new FormControl( false ),
        isShowColumnEv         : new FormControl( false ),
        isShowColumnLa         : new FormControl( false ),
        isShowColumnRoa        : new FormControl( false ),
        isShowColumnRoe        : new FormControl( false )  }
    );
  }

  private isOnlyEmprMonitoredSelected(){
    return this._form.controls[ 'isOnlyEmprMonitored' ].value;
  }

  private isShowColumnSetor(){
    return this._form.controls[ 'isShowColumnSetor' ].value;
  }

  private isShowColumnTicker(){
    return this._form.controls[ 'isShowColumnTicker' ].value;
  }

  private isShowColumnVarPercFrom(){
    return this._form.controls[ 'isShowColumnVarPercFrom' ].value;
  }

  private getDtVarPercFrom(){
    return this._form.controls[ 'dtVarPercFrom' ].value;
  }

  private setDtVarPercFrom( dt: Date ){
    return this._form.controls[ 'dtVarPercFrom' ].value = dt;
  }

  private isShowColumnUltPerdCntb(){
    return this._form.controls[ 'isShowColumnUltPerdCntb' ].value;
  }

  private isShowColumnLp(){
    return this._form.controls[ 'isShowColumnLp' ].value;
  }

  private isShowColumnPl(){
    return this._form.controls[ 'isShowColumnPl' ].value;
  }

  private isShowColumnVpa(){
    return this._form.controls[ 'isShowColumnVpa' ].value;
  }

  private isShowColumnMc(){
    return this._form.controls[ 'isShowColumnMc' ].value;
  }

  private isShowColumnEv(){
    return this._form.controls[ 'isShowColumnEv' ].value;
  }

  private isShowColumnEbitda(){
    return this._form.controls[ 'isShowColumnEbitda' ].value;
  }

  private isShowColumnLa(){
    return this._form.controls[ 'isShowColumnLa' ].value;
  }

  private isShowColumnRoa(){
    return this._form.controls[ 'isShowColumnRoa' ].value;
  }

  private isShowColumnRoe(){
    return this._form.controls[ 'isShowColumnRoe' ].value;
  }

  private isShowColumnPe(){
    return this._form.controls[ 'isShowColumnPe' ].value;
  }

  private isShowColumnPvpa(){
    return this._form.controls[ 'isShowColumnPvpa' ].value;
  }

  private setOnlyEmprMonitoredSelected( isOnlyEmprMonitored: Boolean ){
    this._form.patchValue( { isOnlyEmprMonitored: isOnlyEmprMonitored } );
  }

  private refreshDisplayedColumns(){
    const cdSetorEcnm = ( this._segmEcnm == null || this._segmEcnm.subSetorEcnm == null ) ? null : this._segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm;
    const cdSubSetorEcnm = ( this._segmEcnm == null || this._segmEcnm.subSetorEcnm == null ) ? null : this._segmEcnm.subSetorEcnm.cdSubSetorEcnm;
    const cdSegmEcnm = ( this._segmEcnm == null ) ? null : this._segmEcnm.cdSegmEcnm;

    this.createNameOfDisplayedColumns( cdSetorEcnm, cdSubSetorEcnm, cdSegmEcnm );
  }

  private createNameOfDisplayedColumns( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ){
    this._emprColumnsDisplayed = [];

    this._emprColumnsDisplayed.push( 'nmEmpr' );

    if( this.isShowColumnSetor() && ( cdSetorEcnm == null || cdSetorEcnm == 0 ) ){
      this._emprColumnsDisplayed.push( 'setorEcnm' );
    }

    if( this.isShowColumnSetor() && ( cdSubSetorEcnm == null || cdSubSetorEcnm == 0 ) ){
      this._emprColumnsDisplayed.push( 'subSetorEcnm' );
    }

    if( this.isShowColumnSetor() && ( cdSegmEcnm == null || cdSegmEcnm == 0 ) ){
      this._emprColumnsDisplayed.push( 'segmEcnm' );
    }

    if( this.isShowColumnTicker() ){
      this._emprColumnsDisplayed.push( 'ticker' );
    }

    if( this.isShowColumnVarPercFrom() ){
      this._emprColumnsDisplayed.push( 'varPercFrom' );
    }

    if( this.isShowColumnUltPerdCntb() ){
      this._emprColumnsDisplayed.push( 'ultPerdCntb' );
    }

    if( this.isShowColumnUltPerdCntb() ){
      this._emprColumnsDisplayed.push( 'varPercLp' );
    }

    if( this.isShowColumnLp() ){
      this._emprColumnsDisplayed.push( 'rstdEmprLp' );
    }

    if( this.isShowColumnPl() ){
      this._emprColumnsDisplayed.push( 'rstdEmprPl' );
    }

    if( this.isShowColumnVpa() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprVpa' );
    }

    if( this.isShowColumnMc() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprMc' );
    }

    if( this.isShowColumnEv() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprEv' );
    }

    if( this.isShowColumnEbitda() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprEbitda' );
    }

    if( this.isShowColumnLa() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprLa' );
    }

    if( this.isShowColumnRoa() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprRoa' );
    }

    if( this.isShowColumnRoe() ){
      this._emprColumnsDisplayed.push( 'infCompleEmprRoe' );
    }

    if( this.isShowColumnPe() ){
      this._emprColumnsDisplayed.push( 'indTcnEmprPe' );
    }

    if( this.isShowColumnPvpa() ){
      this._emprColumnsDisplayed.push( 'indTcnEmprPvpa' );
    }

    this._emprColumnsDisplayed.push( 'options' );
  }

  onSubmit(){
    console.log( 'onSubmit()...' );

    console.log( 'onSubmit().' );
  }

  protected refresh( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number, cdSetorAtvdd: number ){
    this._segmEcnm = null;
    this._setorAtvdd = null;

    if( cdSetorAtvdd == null ){
      this.setorService.detail( cdSetorEcnm, cdSubSetorEcnm, cdSegmEcnm )
        .then( detailObj => {
          this._segmEcnm = detailObj;

          this.loadEmprList( this._segmEcnm, null );
        });
    }
    else{
      this.setorAtvddService.detail( cdSetorAtvdd )
        .then( detailObj => {
          this._setorAtvdd = detailObj;

          this.loadEmprList( null, this._setorAtvdd );
        });
    }
  }

  private loadEmprList( segmEcnm: SegmEcnm, setorAtvdd: SetorAtvdd ){
    let inPermtAtlzCotc = this.isOnlyEmprMonitoredSelected();

    if( inPermtAtlzCotc == null || inPermtAtlzCotc === false ){
      inPermtAtlzCotc = null;
    }

    this.emprService.list( null, segmEcnm, setorAtvdd, inPermtAtlzCotc )
      .then( emprList => {
        this.isEmprListEmpty = ( emprList.length === 0 );

        this._emprDataSource = new MatTableDataSource< Empr >( emprList );
        this._emprDataSource.paginator = this.paginator;

        this._emprDataSource.sortingDataAccessor = ( item, property ) => {
          switch( property ) {
            case 'nmEmpr'           : return item.nmAbrvEmpr;
            case 'setorEcnm'        : return item.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm;
            case 'subSetorEcnm'     : return item.segmEcnm.subSetorEcnm.nmSubSetorEcnm;
            case 'segmEcnm'         : return item.segmEcnm.nmSegmEcnm;
            case 'ticker'           : return ( item.invList != null && item.invList.length > 0 ) ? item.invList[0].nmInv : null;

            case 'varPercFrom':
              const hstCotcAcao = ( item.invList == null || item.invList.length == 0 ) ? null : item.invList[0].crctInvAcao.hstCotcAcao;
              const prcFech = ( hstCotcAcao == null || hstCotcAcao.length != 2 || hstCotcAcao[0] == null ) ? null : hstCotcAcao[0].prcFech;
              const prcAbert = ( hstCotcAcao == null || hstCotcAcao.length != 2 || hstCotcAcao[1] == null ) ? null : hstCotcAcao[1].prcAbert;

              return ( prcFech === null || prcAbert == null ) ? null : ( ( prcFech - prcAbert ) / prcAbert );

            case 'ultPerdCntb':
              return !item.rstdEmpr.rstdEmpr || item.rstdEmpr.rstdEmpr == null ? null : item.rstdEmpr.rstdEmpr.perdc.sglPerdc + item.rstdEmpr.rstdEmpr.aaRefPerdc + '/' + item.rstdEmpr.rstdEmpr.nrRefPerdc;

            case 'varPercLp':
              let varPerc = null;

              if( item.tipInfRstdList && item.tipInfRstdList != null && item.tipInfRstdList.length > 0 && item.tipInfRstdList[0].infRstdEmprArray.length === 2 ){
                varPerc = item.tipInfRstdList[0].infRstdEmprArray[0].getVarPercVlRstdEmpr();
              }

              return varPerc;

            case 'rstdEmprLp'         : return item.rstdEmpr.lpAtrbdSocCntr != null ? item.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr : null;
            case 'rstdEmprPl'         : return item.rstdEmpr.pl             != null ? item.rstdEmpr.pl.vlRstdEmpr             : null;

            case 'infCompleEmprEbitda': return item.infComplEmpr != null && item.infComplEmpr.ebitda != null ? item.infComplEmpr.ebitda.vlInfComplEmpr : null;
            case 'infCompleEmprVpa'   : return item.infComplEmpr != null && item.infComplEmpr.vpa    != null ? item.infComplEmpr.vpa.vlInfComplEmpr : null;
            case 'infCompleEmprMc'    : return item.infComplEmpr != null && item.infComplEmpr.mc     != null ? item.infComplEmpr.mc.vlInfComplEmpr  : null;
            case 'infCompleEmprEv'    : return item.infComplEmpr != null && item.infComplEmpr.ev     != null ? item.infComplEmpr.ev.vlInfComplEmpr  : null;
            case 'infCompleEmprLa'    : return item.infComplEmpr != null && item.infComplEmpr.la     != null ? item.infComplEmpr.la.vlInfComplEmpr  : null;
            case 'infCompleEmprRoa'   : return item.infComplEmpr != null && item.infComplEmpr.roa    != null ? item.infComplEmpr.roa.vlInfComplEmpr : null;
            case 'infCompleEmprRoe'   : return item.infComplEmpr != null && item.infComplEmpr.roe    != null ? item.infComplEmpr.roe.vlInfComplEmpr : null;

            case 'indTcnEmprPe'       : return this.getVlIndTcn( item, 'P', 'P/E' );
            case 'indTcnEmprPvpa'     : return this.getVlIndTcn( item, 'P', 'P/VPA' );
            default: return item[ property ];
          }
        };

        this._emprDataSource.sort = this.sort;

        //Itera por cada empresa, para obter algumas informacoes.
        for( let empr of emprList ){
          this.loadInvMain( empr );

          this.loadRstdEmprLp( empr );

          this.loadInfComplEmprVpa( empr );
          this.loadInfComplEmprMc( empr );
          this.loadInfComplEmprEv( empr );
          this.loadInfComplEmprEbitda( empr );
          this.loadInfComplEmprLa( empr );
          this.loadInfComplEmprRoa( empr );
          this.loadInfComplEmprRoe( empr );

          this.loadUltPerdCntb( empr );
        }
      } );
  }

  /**
   * Carrega o ticker de maior volume negociado vinculado aa empresa.
   */
  private loadInvMain( empr: Empr ){
    const cdEmprRespInv = empr.cdEmpr;

    this.invService.listInvOfEmpr( cdEmprRespInv, 1, 7 )
      .then( invList => {
        const amountOfInv = invList != null ? invList.length : 0;

        if( amountOfInv > 0 ){
          empr.invList = invList;

          const cdInv = invList == null ? null : invList[ 0 ].cdInv;

          if( cdInv != null ){
            //Obtem a ultima cotacao do ticker.
            this.cotcService.listLatest( cdInv, 1 )
              .then( cotcList => {
                const amountOfCotc = cotcList != null ? cotcList.length : 0;

                empr.invList[0].crctInvAcao.hstCotcAcao = amountOfCotc > 0 ? cotcList : null;

                if( amountOfCotc > 0 ){
                  //Obtem a cotacao na data informada para calculo da variacao percentual.
                  const dtCotcFrom = this.getDtVarPercFrom();

                  if( dtCotcFrom != null ){
                    this.cotcService.listBetweenDatesWithLimit( cdInv, dtCotcFrom, null, 1, 'ASC' )
                      .then( cotcList => {
                        const amountOfCotc = cotcList != null ? cotcList.length : 0;

                        empr.invList[0].crctInvAcao.hstCotcAcao.push( amountOfCotc > 0 ? cotcList[0] : null );
                      } );
                  }
                }
              } );

            //Carrega os indicadores tecnicos.
            this.loadIndTcn( empr, 'P', 'P/E' );
            this.loadIndTcn( empr, 'P', 'P/VPA' );
          }
        }
      } );
  }

  /**
   * Carrega o ultimo resultado contabil divulgado pela empresa.
   */
  private loadUltPerdCntb( empr: Empr ){
    const cdEmpr = empr.cdEmpr;

    //Chama o servico para buscar os periodos.
    this.rstdEmprService.listLastRstdEmpr( cdEmpr )
      .then( rstdEmprList => {
        const qtRstdEmpr = rstdEmprList === null ? 0 : rstdEmprList.length;

        if( qtRstdEmpr > 0 ){
          //Obtem o primeiro, e provavel que unico, resultado da empresa.
          const rstdEmpr = rstdEmprList[ 0 ];

          if( !empr.rstdEmpr.rstdEmpr ){
            empr.rstdEmpr.rstdEmpr = new RstdEmpr();
          }

          empr.rstdEmpr.rstdEmpr = rstdEmpr.rstdEmpr;

          //Monta a periodicidade de referencia do periodo do resultado divulgado.
          const perdcRef = new PerdcRef();
          perdcRef.aaRefPerdc = rstdEmpr.rstdEmpr.aaRefPerdc;
          perdcRef.nrRefPerdc = rstdEmpr.rstdEmpr.nrRefPerdc;
          perdcRef.perdc      = rstdEmpr.rstdEmpr.perdc;

          this.loadVarLp( empr, perdcRef );
        }
    } );
  }

  /**
   * Busca o lucro/prejuizo do periodo atual e do ano anterior, para calcular a variação percentual do Lucro/Prejuízo.
   */
  private loadVarLp( empr: Empr, perdcRef: PerdcRef ){
    const cdEmpr = empr.cdEmpr;

    const returnCols = [ { 'lpAtrbdSocCntr': true } ];

    //Chama o servico para buscar os periodos.
    this.rstdEmprService.listTipInfWithVariousInfRstd( cdEmpr, perdcRef.perdc.sglPerdc, (perdcRef.aaRefPerdc - 1), perdcRef.nrRefPerdc, 2, returnCols )
      .then( tipInfList => {
        //Deve retornar apenas 1 elemento no array, referente ao lucro/prejuizo.
        const sizeTipInfList = tipInfList == null ? 0 : tipInfList.length;

        if( sizeTipInfList > 0 ){
          //Espera-se que sejam retornados 2 elementos, referentes ao ano atual e anterior.
          const sizeInfRstdEmprList = tipInfList[0].infRstdEmprArray && tipInfList[0].infRstdEmprArray != null ? tipInfList[0].infRstdEmprArray.length : 0;

          if( sizeInfRstdEmprList === 2 ){
            empr.tipInfRstdList = new Array< TipInfRstdEmprJoinInfRstdEmpr >();
            empr.tipInfRstdList.push( tipInfList[0] );
          }
          else{
            empr.tipInfRstdList = null;
          }
        }
    } );
  }

  /**
   * Carrega o VPA da empresa.
   */
  private loadInfComplEmprVpa( empr: Empr ){
    this.loadInfComplEmpr( empr, 'VPA', 'vpa' );
  }

  /**
   * Carrega o MC da empresa.
   */
  private loadInfComplEmprMc( empr: Empr ){
    this.loadInfComplEmpr( empr, 'MC', 'mc' );
  }

  private loadInfComplEmprEv( empr: Empr ){
    this.loadInfComplEmpr( empr, 'EV', 'ev' );
  }

  /**
   * Carrega o lucro/prejuizo da empresa nos ultimos 12 meses.
   */
  private loadRstdEmprLp( empr: Empr ){
    this.loadRstdEmpr( empr, 'L/P_12M', 'lpAtrbdSocCntr' );
  }

  /**
   * Carrega o EBITDA da empresa nos ultimos 12 meses.
   */
  private loadInfComplEmprEbitda( empr: Empr ){
    this.loadInfComplEmpr( empr, 'EBITDA_12M', 'ebitda' );
  }

  private loadInfComplEmprLa( empr: Empr ){
    this.loadInfComplEmpr( empr, 'L/A', 'la' );
  }

  private loadInfComplEmprRoa( empr: Empr ){
    this.loadInfComplEmpr( empr, 'ROA', 'roa' );
  }

  private loadInfComplEmprRoe( empr: Empr ){
    this.loadInfComplEmpr( empr, 'ROE', 'roe' );
  }

  /**
   * Carrega a informacao complementar da empresa.
   */
  private loadInfComplEmpr( empr: Empr, sglTipInf: string, nmPropRstdEmpr: string ){
    const cdEmpr = empr.cdEmpr;

    this.iceService.listVlByCotc( cdEmpr, sglTipInf, 1 )
      .then( iceList => {
        const amountOfReg = iceList != null ? iceList.length : 0;

        if( !empr.infComplEmpr ){
          empr.infComplEmpr = new InfComplEmprList();
        }

        if( !empr.infComplEmpr[ nmPropRstdEmpr ] ){
          empr.infComplEmpr[ nmPropRstdEmpr ] = new InfComplEmpr();
        }

        if( amountOfReg > 0 ){
          const ice = iceList == null ? null : iceList[ 0 ].infComplEmpr;

          if( ice != null ){
            empr.infComplEmpr[ nmPropRstdEmpr ].vlInfComplEmpr = ice.vlInfComplEmpr;
            empr.infComplEmpr[ nmPropRstdEmpr ].dtRefInfComplEmpr = ice.dtRefInfComplEmpr;
          }
        }
        else{
          empr.infComplEmpr[ nmPropRstdEmpr ] = null;
        }
      } );
  }

  /**
   * Carrega resultado da empresa.
   */
  private loadRstdEmpr( empr: Empr, sglTipInf: string, nmPropRstdEmpr: string ){
    const cdEmpr = empr.cdEmpr;

    this.iceService.listVlByCotc( cdEmpr, sglTipInf, 1 )
      .then( iceList => {
        const amountOfReg = iceList != null ? iceList.length : 0;

        if( amountOfReg > 0 ){
          const ice = iceList == null ? null : iceList[ 0 ].infComplEmpr;

          if( ice != null ){
            if( !empr.rstdEmpr[ nmPropRstdEmpr ] ){
              empr.rstdEmpr[ nmPropRstdEmpr ] = new InfRstdEmpr();
            }

            empr.rstdEmpr[ nmPropRstdEmpr ].vlRstdEmpr = ice.vlInfComplEmpr;
            empr.rstdEmpr[ nmPropRstdEmpr ].dtPblccRef = ice.dtRefInfComplEmpr;
          }
        }
        else{
          empr.rstdEmpr[ nmPropRstdEmpr ] = null;
        }
      } );
  }

  /**
   * Carrega o indicador tecnico especificado.
   */
  private loadIndTcn( empr: Empr, sgSnlTcn: string, sgIndTcn: string ){
    const cdInv: number = empr == null || empr.invList == null || empr.invList.length === 0 ? null : empr.invList[0].cdInv;

    if( cdInv != null ){
      this.indTcnService.listHst( cdInv, sgSnlTcn, sgIndTcn, 1, null, null, 1 )
        .then( hitList => {
          const amountOfReg = hitList != null ? hitList.length : 0;

          if( amountOfReg > 0 ){
            const hit = hitList == null ? null : hitList[ 0 ];

            if( hit != null ){
              if( !empr.invList[0].crctInvAcao.hstIndTcn || empr.invList[0].crctInvAcao.hstIndTcn === null ){
                empr.invList[0].crctInvAcao.hstIndTcn = new Array< HstIndTcn >();
              }

              empr.invList[0].crctInvAcao.hstIndTcn.push( hit );
            }
          }
        } );
    }
  }

  private getEmpr( cdEmpr: number ): Empr{
    const emprList = this._emprDataSource.data;

    let emprFound: Empr = null;

    //Itera por todas as empresas da lista
    for( let emprIter of emprList ){
      //Verifica se encontrou a empresa desejada.
      if( cdEmpr === emprIter.cdEmpr ){
        emprFound = emprIter;
        break;
      }
    }

    return emprFound;
  }

  public updateOnlyEmprMonitored(){
    this.loadEmprList( this._segmEcnm, this._setorAtvdd );
  }

  public updateShowColumn( event: MatCheckboxChange ){
    this.refreshDisplayedColumns();
  }

  public updateDtVarPercFrom( event: Event ){
    if( this.isShowColumnVarPercFrom() === true ){
      const cdSetorEcnm = this._segmEcnm == null ? null : this._segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm;
      const cdSubSetorEcnm = this._segmEcnm == null ? null : this._segmEcnm.subSetorEcnm.cdSubSetorEcnm;
      const cdSegmEcnm = this._segmEcnm == null ? null : this._segmEcnm.cdSegmEcnm;
      const cdSetorAtvdd = this._setorAtvdd == null ? null : this._setorAtvdd.cdSetorAtvdd;

      this.refresh( cdSetorEcnm, cdSubSetorEcnm, cdSegmEcnm, cdSetorAtvdd );
    }
  }

  detailEmpr( event, cdEmpr: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/empr-detail', { 'cdEmpr': cdEmpr }, isOpenNewTab );
  }

  detailInv( event, cdInv: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/inv-detail', { 'cdInv': cdInv }, isOpenNewTab );
  }

  detailSetorEcnm( event, cdSetorEcnm: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm }, isOpenNewTab );
  }

  detailSubsetorEcnm( event, cdSetorEcnm: number, cdSubsetorEcnm: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm, 'cdSubSetorEcnm': cdSubsetorEcnm }, isOpenNewTab );
  }

  detailSegmEcnm( event, cdSetorEcnm: number, cdSubsetorEcnm: number, cdSegmEcnm: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm, 'cdSubSetorEcnm': cdSubsetorEcnm, 'cdSegmEcnm': cdSegmEcnm }, isOpenNewTab );
  }

  public getVlIndTcn( empr: Empr, sglSnlTcn: string, sglIndTcn: string ): number{
    const cia = empr.invList == null || empr.invList.length === 0 ? null : empr.invList[ 0 ].crctInvAcao;
    const qtyHstIndTcn = cia == null || cia.hstIndTcn == null ? 0 : cia.hstIndTcn.length;
    let vlIndTcn: number = null;

    //Itera por cada um dos indicadores
    for( let indexHit = 0; indexHit < qtyHstIndTcn; indexHit++ ){
      const hit = cia.hstIndTcn[ indexHit ];
      const sglSnlTcnStr = hit.indTcn == null ? '' : hit.indTcn.snlTcn.sgSnlTcn;
      const sglIndTcnStr = hit.indTcn == null ? '' : hit.indTcn.sgIndTcn;

      if( sglSnlTcn === sglSnlTcnStr && sglIndTcn === sglIndTcnStr ){
        vlIndTcn = hit.vlIndTcn;
        break;
      }
    }

    return vlIndTcn;
  }
}

import { Component } from '@angular/core';
import { AppComponentModel              } from './app.component.model';

import { SessionService } from '../../_services/session/session.service';
import { AplicationStateService } from '../../_services/aplication-state/aplication-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  private model: AppComponentModel;
  public isMobileResolution: boolean;

  constructor( private aplicationStateService: AplicationStateService,
               public sessionService        : SessionService ) {
    //console.log( "Construtor 'AppComponent()'..." );

    this.model = new AppComponentModel();

    this.isMobileResolution = this.aplicationStateService.isMobileResolution();

    //console.log( "Construtor 'AppComponent()'." );
  }
}
import { Empr } from './empr';
import { Moeda } from './moeda';
import { Perdc } from './perdc';

export class RstdEmpr {
  public empr: Empr;
  public aaRefPerdc: number;
  public nrRefPerdc: number;
  public perdc: Perdc;
  public tipMoeda: Moeda;
  public dtPblccRef: Date;
}

export class InfRstdEmpr {
  public empr: Empr;
  public aaRefPerdc: number;
  public nrRefPerdc: number;
  public perdc: Perdc;
  public tipInfRstdEmpr: TipInfRstdEmpr;
  public tsAtlzReg: Date;
  public vlRstdEmpr: number;
  public vlRstdEmprAaRefAnt: number;
  public dtPblccRef: Date;
  public getVarPercVlRstdEmpr(): number {
    if( this.vlRstdEmprAaRefAnt == null || this.vlRstdEmprAaRefAnt == 0 ){
      return null;
    }
    else{
      return ( ( this.vlRstdEmpr - this.vlRstdEmprAaRefAnt ) / Math.abs( this.vlRstdEmprAaRefAnt ) * 100 );
    }
  }
}

export class TipInfRstdEmpr {
  public sglTipInfRstdEmpr: string;
  public nmTipInfRstdEmpr: string;
  public txDcrTipInfRstdEmpr: string;
  public txDcrUsuTipInfRstdEmpr: string;
}

export class RstdEmprJoinInfRstdEmpr {
  public rstdEmpr: RstdEmpr;
  public lpAtrbdSocCntr: InfRstdEmpr;
  public ebitda: InfRstdEmpr;
  public disp: InfRstdEmpr;
  public divCp: InfRstdEmpr;
  public divLp: InfRstdEmpr;
  public divLq: InfRstdEmpr;
  public pl: InfRstdEmpr;
  public ativoTotal: InfRstdEmpr;
  public deprecAmort: InfRstdEmpr;
  public resultFinanc: InfRstdEmpr;
}

/**
 * Contem um tipo de informacao de resultado associado
 * um array de informações de diversos resultados.
 */
export class TipInfRstdEmprJoinInfRstdEmpr {
  public tipInfRstdEmpr: TipInfRstdEmpr;
  public infRstdEmprArray: InfRstdEmpr[];
}

import { Injectable                             } from '@angular/core';
import { HttpClient                             } from '@angular/common/http';
import { DatePipe                               } from '@angular/common';

import { HttpBasicService                       } from '../http-basic.service';
import { SessionService                         } from '../session/session.service';

import { Destaque, DestaquesBySeq                               } from 'src/app/_models/dtq/dtq';

@Injectable({
  providedIn: 'root'
})
export class DtqService extends HttpBasicService< Destaque, Destaque >{
  constructor( protected http: HttpClient,
               public sessionService: SessionService,
               protected datePipe   : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista as datas de referencia disponiveis.
   */
  public listDtRef( dtRefMin: Date, limit: number ): Promise< Destaque[] >{
    let reqData = {};

    if( dtRefMin && dtRefMin != null ){
      const dtRefMinStr = this.datePipe.transform( dtRefMin, 'yyyy-MM-dd' );
      reqData[ 'dt_ref_min' ] = dtRefMinStr;
    }

    if( limit != null ){
      reqData[ 'limit' ] = limit;
    }

    return super.listGenericX< Destaque >( '/DTQ/LIST_DT_REF', reqData, false );
  }

  /**
   * 
   */
  public listDtqMaioresVarPrcFechDiario( dtRefFrom: Date, dtRefEnd: Date, limit: number ): Promise< Destaque[] >{
    let reqData = {};

    if( dtRefFrom && dtRefFrom != null ){
      const dtRefFromStr = this.datePipe.transform( dtRefFrom, 'yyyy-MM-dd', '+0000' );
      reqData[ 'dt_ref_from' ] = dtRefFromStr;
    }

    if( dtRefEnd && dtRefEnd != null ){
      const dtRefEndStr = this.datePipe.transform( dtRefEnd, 'yyyy-MM-dd', '+0000' );
      reqData[ 'dt_ref_end' ] = dtRefEndStr;
    }

    if( limit != null ){
      reqData[ 'limit' ] = limit;
    }

    return super.listGenericX< Destaque >( '/DTQ/LIST_DTQ_MAVPPFA_DIARIA', reqData, false );
  }


  public listDtqMaioresVarPrcFechDiarioBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MAVPPFA_DIARIA', datesRef, qtMaxSeq, volNegcMin );
  }

  public listDtqMaioresVarPrcFechNaSmnBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MAVPPFA_NA_SMN', datesRef, qtMaxSeq, volNegcMin );
  }

  public listDtqMaioresVarPrcFechMensalBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MAVPPFA_MENSAL', datesRef, qtMaxSeq, volNegcMin );
  }


  public listDtqMenoresVarPrcFechDiarioBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MEVPPFA_DIARIA', datesRef, qtMaxSeq, volNegcMin );
  }
  
  public listDtqMenoresVarPrcFechNaSmnBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MEVPPFA_NA_SMN', datesRef, qtMaxSeq, volNegcMin );
  }

  public listDtqMenoresVarPrcFechMensalBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MEVPPFA_MENSAL', datesRef, qtMaxSeq, volNegcMin );
  }


  public listDtqMaioresVarVolNegcDiarioBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MAVPVNA_DIARIA', datesRef, qtMaxSeq, volNegcMin );
  }

  public listDtqMaioresVarVolNegcMensalBySeq( datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    return this.listDtqBySeq( '/DTQ/LIST_DTQ_MAVPVNA_MENSAL', datesRef, qtMaxSeq, volNegcMin );
  }

  
  /**
   * 
   */
  private listDtqBySeq( serviceAddress: string, datesRef: Date[], qtMaxSeq: number, volNegcMin: number ): Promise< DestaquesBySeq[] >{
    //console.log( 'tipDtq()...' );

    const qtDates = datesRef != null ? datesRef.length : 0;
    const dtRefFrom = datesRef[ qtDates - 1 ];
    const dtRefEnd  = datesRef[ 0 ];

    //console.log( 'Informadas ' + qtDates + ' datas de referencia.' );

    let reqData = {};

    if( dtRefFrom && dtRefFrom != null ){
      const dtRefFromStr = this.datePipe.transform( dtRefFrom, 'yyyy-MM-dd', '+0000' );
      reqData[ 'dt_ref_from' ] = dtRefFromStr;
    }

    if( dtRefEnd && dtRefEnd != null ){
      const dtRefEndStr = this.datePipe.transform( dtRefEnd, 'yyyy-MM-dd', '+0000' );
      reqData[ 'dt_ref_end' ] = dtRefEndStr;
    }

    if( volNegcMin && volNegcMin != null ){
      reqData[ 'vol_negc_min' ] = volNegcMin;
    }

    //reqData[ 'nr_seq_dtq_max'  ] = nrSeqDtqMax;
    reqData[ 'is_order_by_seq' ] = false;
    reqData[ 'return_cotc'     ] = true;

    //Chama o servico para obter a lista dos Destaques.
    const promiseList = super.listGenericX< Destaque >( serviceAddress, reqData, false );

    const promiseReturn = new Promise< DestaquesBySeq[] >( function( resolve, reject ) {
      //Aguarda o servico ser respondido.
      promiseList
        .then( dtqList => {
          const qtDtq = dtqList != null ? dtqList.length : 0;

          const dtqBySeqList: DestaquesBySeq[] = new Array( qtMaxSeq ).fill( null );

          for( let indexSeq = 0; indexSeq < qtMaxSeq; indexSeq++ ){
            dtqBySeqList[ indexSeq ] = new DestaquesBySeq();
            dtqBySeqList[ indexSeq ].nrSeqDtq = indexSeq + 1;
            dtqBySeqList[ indexSeq ].dtqList = new Array( qtDates ).fill( null );
          }

          let iDtq = 0;

          let qtRegOfDate: number = 0;
          let timeRefPrev: number = null;
          let indexTimeRefPrev: number = null;

          for( let indexDtq = 0; indexDtq < qtDtq; indexDtq++ ){
            const dtqIterate = dtqList[ indexDtq ];

            const dtRefIterate = new Date( dtqIterate.dtRef );
            const timeRefIterate = dtRefIterate != null ? dtRefIterate.getTime() : null;

            const nrSeqIterate = dtqIterate.nrSeqDtq;

            //console.log( 'Destaque( ' + indexDtq + ' ): ' + nrSeqIterate + '-' + dtRefIterate );

            if( timeRefIterate != null ){
              if( timeRefPrev != null && timeRefPrev === timeRefIterate ){
              }
              else{
                indexTimeRefPrev = null;
                qtRegOfDate = 0;
              }

              if( qtRegOfDate < qtMaxSeq ){
                //Se ainda nao determinou o index da dtRef, define-o.
                if( indexTimeRefPrev === null ){
                  for( let indexDtRef = 0; indexDtRef < qtDates; indexDtRef++ ){
                    if( datesRef[ indexDtRef ].getTime() === timeRefIterate ){
                      indexTimeRefPrev = indexDtRef;
                      break;
                    }
                  }
                }

                const dtqBySeq = dtqBySeqList[ qtRegOfDate ];
                dtqBySeq.dtqList[ indexTimeRefPrev ] = dtqIterate;

                qtRegOfDate++;
              }
            }

            timeRefPrev = timeRefIterate;
          }

          resolve( dtqBySeqList );
        })
        .catch( error => {
          reject( error );
        } );
    });

    return promiseReturn;
  }
}
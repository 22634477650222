import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { PgmtPrvt } from '@models/prvt';


@Injectable({
  providedIn: 'root'
})
export class PrvtService extends HttpBasicService< PgmtPrvt, PgmtPrvt >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista os pagamentos de proventos (rendimentos e amortizações).
   */
  list( cdInv: number ): Promise< PgmtPrvt[] >{
    return super.listGeneric( '/PRVT/LIST', { cd_inv: cdInv }, false );
  }
}
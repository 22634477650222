import { MAT_DIALOG_DATA, MatDialog                       } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild             } from '@angular/core';
import { DomSanitizer                                     } from '@angular/platform-browser';
import { ActivatedRoute                                   } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';

import { MatDialogRef                                     } from '@angular/material/dialog';

import { GeneralDialogInputMessageDesktopComponent        } from './desktop/general-dialog-inputMessage-desktop.component';

import { AplicationStateService                           } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService                            } from '@services/authentication/authentication.service';
import { PrmUsrService                                    } from '@services/prmUsr/prmUsr.service';
import { InvService                                       } from '@services/inv/inv.service';

import { InputMessageDataParm       } from './models/inputMessageDataParm';
import { InputMessageReturnDataParm } from './models/inputMessageReturnDataParm';


export abstract class GeneralDialogInputMessageComponent implements OnInit {
  public _isReturnMsgObs: boolean;

  //Mensagem a ser apresentada
  public _msgConfirm: string = 'Confirma?';

  public _generalForm;

  constructor( public dialogRef: MatDialogRef< GeneralDialogInputMessageComponent >,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected invService            : InvService            ,
               @Inject( MAT_DIALOG_DATA ) public data: InputMessageDataParm ){
  }

  backdropClick(){
    console.log( 'backdropClick.' );
  }

  ngOnInit() {
    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    this.monitoryInit( promises );
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const msgConfirm = _this.data.msgDisplayed;

      if( msgConfirm != null ){
        _this._msgConfirm = msgConfirm;
      }

      resolve();
    } );

    return p;
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          //console.log( 'Finalizadas ' + promisesFinish + '/' + qtPromises + ' tarefas.' );
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._generalForm = _this.formBuilder.group(
        { txInformed: new FormControl( {value: null, disabled: false} ) }
      );

      resolve();
    });

    return p;
  }

  private getTxInformed(): string{
    return this._generalForm.controls[ 'txInformed' ].value;
  }

  private setTxInformed( txObs: string ): void{
    this._generalForm.patchValue( { txInformed: txObs } );
  }

  onSubmit(){
    // stop here if form is invalid
    if( this._generalForm.invalid ){
      console.log( '_generalForm.invalid!' );

      return;
    }
  }

  public onConfirmYes(): void{
    this.closeDialog( true );
  }

  public onConfirmNo(): void{
    this.closeDialog( false );
  }

  public onCancel(): void{
    this.closeDialog( false );
  }

  private closeDialog( isConfirm: boolean ){
    const returnDataParm = new InputMessageReturnDataParm();
    returnDataParm.txInformed = this.getTxInformed();
    returnDataParm.isConfirm   = isConfirm;

    this.dialogRef.close( returnDataParm );
  }
}

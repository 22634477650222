import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { PageNotFoundComponent          } from '../page-not-found.component';
import { FormBuilder } from '@angular/forms';
import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.desktop.component.html',
  styleUrls: ['./page-not-found.desktop.component.css']
})
export class PageNotFoundDesktopComponent extends PageNotFoundComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar            ,
               protected invService            : InvService              ) {
    super( router, activatedRoute, sanitizer, formBuilder, aplicationStateService, authenticationService, prmUsrService, snackBar, invService );
  }
}

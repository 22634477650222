import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpBasicService } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { CrctInv, CrctInvDetail, TipInv, EspcAcao } from 'src/app/_models/inv';

@Injectable({
  providedIn: 'root'
})
export class InvService extends HttpBasicService< CrctInvDetail, CrctInv >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService ) {
    super( http, sessionService );
  }


  /**
   * Detalha um investimento.
   */
  detail( cdInv: number ) : Promise< CrctInvDetail > {
    return super.detailGeneric( '/INV/DET', { 'cd_inv': cdInv }, false );
  }


  /**
   * Detalha um investimento pelo seu nome.
   */
  detailTicker( nmInv: string ) : Promise< CrctInvDetail > {
    return super.detailGeneric( '/INV/DET', { 'nm_inv': nmInv }, false );
  }

  /**
   * Lista investimentos em acoes.
   */
  listAcoes(): Promise< CrctInv[] >{
    return super.listGeneric( '/INV/LIST', { cd_tip_inv: 7 }, false );
  }

  /**
   * Lista investimentos da empresa que sejam do tipo 7-ACAO e 2-FUNDO.
   */
  listInvAcaoFundoOfEmpr( cdEmprRespInv: number, limit: number, isOrderByVolNegcMed?: boolean ): Promise< CrctInv[] >{
    const _this = this;

    const acaoFundoList: CrctInv[] = [];

    const p = new Promise< CrctInv[] >( function( resolve, reject ){
      let promises: Promise< any >[] = [];

      const pAcaoList: Promise< CrctInv[] >  = _this.listInvOfEmpr( cdEmprRespInv, limit, 7, isOrderByVolNegcMed );
      const pFundoList: Promise< CrctInv[] >  = _this.listInvOfEmpr( cdEmprRespInv, limit, 2, isOrderByVolNegcMed );

      pAcaoList
        .then( acaoList => {
          const qtdItens = acaoList != null ? acaoList.length : 0;

          for( let index = 0; index < qtdItens; index++ ){
            acaoFundoList.push( acaoList[ index ] );
          }
        } )
        .catch( acaoError => {
          reject( acaoError );
        } );

      pFundoList
        .then( fundoList => {
          const qtdItens = fundoList != null ? fundoList.length : 0;

          for( let index = 0; index < qtdItens; index++ ){
            acaoFundoList.push( fundoList[ index ] );
          }
        } )
        .catch( fundoError => {
          reject( fundoError );
        } );

      promises.push( pAcaoList );
      promises.push( pFundoList );

      Promise.all( promises )
          .then( response => {
            resolve( acaoFundoList );
          } )
          .catch( error => {
            reject( error );
          } );
    });

    return p;
  }

  /**
   * Lista investimentos da empresa.
   */
  listInvOfEmpr( cdEmprRespInv: number, limit: number, cdTipInv: number, isOrderByVolNegcMed?: boolean ): Promise< CrctInv[] >{
    let reqData = { cd_empr_resp_inv: cdEmprRespInv, 'return_negc': true, 'return_prc_exerc': true, 'return_emissor': true, 'return_estl_opcao': true };

    if( limit != null ){
      reqData[ 'limit' ] = limit;
    }

    if( cdTipInv != null ){
      reqData[ 'cd_tip_inv' ] = cdTipInv;
    }

    if( isOrderByVolNegcMed != null ){
      reqData[ 'order_by_vol_negc_med' ] = isOrderByVolNegcMed;
    }

    return super.listGeneric( '/INV/LIST', reqData, false );
  }

  /**
   * Lista investimentos.
   */
  list( inPermtAtlzCotc: Boolean, cdTipInv: number, cdEspcAcao: number ): Promise< CrctInv[] >{
    //console.log( 'list( inPermtAtlzCotc/tipInv/espc ): ' + inPermtAtlzCotc + '/' + cdTipInv + '/' + cdEspcAcao );

    let reqData = {};

    if( inPermtAtlzCotc != null && inPermtAtlzCotc === true ){
      reqData[ 'in_permt_atlz_cotc' ] = true;
    }

    if( cdTipInv && cdTipInv != null ){
      reqData[ 'cd_tip_inv' ] = cdTipInv;
    }

    if( cdEspcAcao && cdEspcAcao != null ){
      reqData[ 'cd_espc_acao' ] = cdEspcAcao;
    }

    return super.listGeneric( '/INV/LIST', reqData, false );
  }

  /**
   * Lista tipos de investimento (tickers).
   */
  listTipInv(): Promise< TipInv[] >{
    let reqData = {};

    return super.listGenericX< TipInv >( '/TIP_INV/LIST', reqData, false );
  }

  /**
   * Lista tipos de espécie dos investimentos.
   */
  listTipEspc( cdTipInv: number ): Promise< EspcAcao[] >{
    let reqData = { 'cd_tip_inv': cdTipInv };

    return super.listGenericX< EspcAcao >( '/TIP_INV/LIST_ESPC', reqData, false );
  }
}
import { Component, ViewEncapsulation, Inject     } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { GrCrctInvService } from 'src/app/_services/grCrctInv/grCrctInv.service';
import { CarteiraDialogAddComponent } from '../carteira-dialog-add.component';


@Component({
  selector: 'app-carteira-dialog-add-desktop',
  templateUrl: './carteira-dialog-add-desktop.component.html',
  styleUrls: ['./carteira-dialog-add-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CarteiraDialogAddDesktopComponent extends CarteiraDialogAddComponent {
  constructor( public dialogRef: MatDialogRef< CarteiraDialogAddDesktopComponent >,
               protected formBuilder           : FormBuilder            ,
               protected dialog                : MatDialog              ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService             ,
               protected grCrctInvService      : GrCrctInvService ){
    super( dialogRef, formBuilder, dialog, aplicationStateService, authenticationService, prmUsrService, invService, grCrctInvService );
  }

  ngOnInit() {
    super.ngOnInit();
  }

}

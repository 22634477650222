import { CrctInv } from '../inv';

/**
 * Tipos de informacao existentes.
 */
export enum TipInfCrctInv{
	COTC    = 1,
	IND_TCN = 2,
}

/**
 * Tipos de informacao a ser apresentada ao usuário.
 */
export class TipViewInfCrctInv{
	static readonly VLR_INF         = new TipViewInfCrctInv( 1, 'Valor da Informação' );
	static readonly PRC_ABERT       = new TipViewInfCrctInv( 2, 'Preço de Abertura' );
	static readonly PRC_MIN         = new TipViewInfCrctInv( 3, 'Preço Mínimo' );
	static readonly PRC_MAX         = new TipViewInfCrctInv( 4, 'Preço Máximo' );
	static readonly PRC_FECH        = new TipViewInfCrctInv( 5, 'Preço de Fechamento' );
	static readonly CANDLE          = new TipViewInfCrctInv( 6, 'Candle' );
	static readonly PERCENT         = new TipViewInfCrctInv( 7, '%' );
	static readonly VOL_NEGC        = new TipViewInfCrctInv( 8, 'Vol. Negócios' );
	static readonly TX_PERC_RENT_AA = new TipViewInfCrctInv( 9, 'Taxa Rentabilidade Anual' );

	private constructor( public code: number, public nmView: string ){
	}

	public getCode(): number{
		return this.code;
	}

	public toString(): string{
		return this.nmView;
	}

	public isEqual( otherTipView: TipViewInfCrctInv ): boolean{
		const thisCode = this.code;
		const otherCode = otherTipView.code;

		return otherTipView != null && thisCode && otherCode && thisCode === otherCode;
	}

	public static getTipViewOfCode( code: number ): TipViewInfCrctInv{
		let tipViewList: TipViewInfCrctInv[] = [];
		tipViewList.push( TipViewInfCrctInv.VLR_INF );
		tipViewList.push( TipViewInfCrctInv.PRC_ABERT );
		tipViewList.push( TipViewInfCrctInv.PRC_MIN );
		tipViewList.push( TipViewInfCrctInv.PRC_MAX );
		tipViewList.push( TipViewInfCrctInv.PRC_FECH );
		tipViewList.push( TipViewInfCrctInv.CANDLE );
		tipViewList.push( TipViewInfCrctInv.PERCENT );
		tipViewList.push( TipViewInfCrctInv.VOL_NEGC );
		tipViewList.push( TipViewInfCrctInv.TX_PERC_RENT_AA );

		const qtTipViewList = tipViewList.length;

		let tipViewFound = null;

		for( let indexTipView = 0; indexTipView < qtTipViewList; indexTipView++ ){
			const tipViewIterate = tipViewList[ indexTipView ];

			if( code === tipViewIterate.code ){
				tipViewFound = tipViewIterate;
				break;
			}
		}

		return tipViewFound;
	}
}

export class InfCrctInvPk{
	readonly nmInfCrctInv: string;
	readonly perdcInfCrctInv: number;
	readonly tipViewInfCrctInv: TipViewInfCrctInv;

	public constructor( nmInfCrctInv: string, perdcInfCrctInv: number, tipViewInfCrctInv: TipViewInfCrctInv ){
		this.nmInfCrctInv = nmInfCrctInv;
		this.perdcInfCrctInv = perdcInfCrctInv;
		this.tipViewInfCrctInv = tipViewInfCrctInv;
	}
}

export interface InfCrctInvInterface< T >{
	setCrctInv( ticker: CrctInv ): void;
	copy()                 : InfCrctInvInterface< T >;
	getNmInfCrctInv()      : string;
	getNmInfCrctInvViewed(): string;
	getTipInfCrctInv()     : TipInfCrctInv;

	getPerdcSelectedInfCrctInv(): number;
	setPerdcSelectedInfCrctInv( perdc: number ): void;
	addPerdcListInfCrctInv( perdc: number ): void;
	getPerdcListInfCrctInv(): number[];
	setPerdcListInfCrctInv( perdcList: number[] ): void;

	getTipViewSelectedInfCrctInv(): TipViewInfCrctInv;
	setTipViewSelectedInfCrctInv( tipView: TipViewInfCrctInv ): void;
	addTipViewListInfCrctInv( tipView: TipViewInfCrctInv ): void;
	getTipViewListInfCrctInv(): TipViewInfCrctInv[];
	setTipViewListInfCrctInv( tipViewList: TipViewInfCrctInv[] ): void;

	isEqual( otherInfCrctInv: InfCrctInvInterface< T > ): boolean;
	isSameNmAndTipView( otherInfCrctInv: InfCrctInvInterface< T > ): boolean;

	//Retorna um objeto que representa unicamente uma InfCrctInv.
	getPk(): InfCrctInvPk;

	getDtFromValues(): Date;
	setDtFromValues( dt: Date ): void;
	getDtEndValues(): Date;
	setDtEndValues( dt: Date ): void;
	getQtLimitValues(): number;
	setQtLimitValues( qtLimit: number ): void;

	//Solicita a atualizacao dos valores da informacao. Após a finalizacao da atualizacao, chame 'getValues()' para obter os valores.
	refreshValues(): Promise< T[] >;

	//Obtem os ultimos valores da informacao armazenados.
	getValues(): T[];
	getQtValues(): number;
	getValueOfIndex( index: number ): T;
	getDateRefOfValueOfIndex( index: number ): Date;

	resetPointerToMin(): void;
	resetPointerToMax(): void;
	incrPointer( isValidate: boolean  ): boolean;
	decrPointer( isValidate: boolean ): boolean;
	nextValueOfPointer(): T;
	nextValueOfPointerIfEqualDateRef( dateRef: Date ): T;
	getValueOfPointerIfEqualDateRefAndMovePointerToNext( dateRef: Date ): T;
	prevValueOfPointer(): T;
	prevValueOfPointerIfEqualDateRef( dateRef: Date ): T;
	getValueOfPointerIfEqualDateRefAndMovePointerToPrev( dateRef: Date ): T;
	getIndexOfPointer(): number;
	setIndexOfPointer( index: number ): void;
}

export abstract class BasicInfCrctInvAbstract< T > implements InfCrctInvInterface< T >{
	private ticker: CrctInv;
	private dtFromValues: Date;
	private dtEndValues: Date;
	private qtLimitValues: number;
	private tipViewSelectedInfCrctInv: TipViewInfCrctInv;
	private tipViewListInfCrctInv    : TipViewInfCrctInv[];
	private values: T[];
	private indexOfPointer: number;

	constructor( ticker: CrctInv ){
		this.ticker = ticker;
		this.values = null;
		this.indexOfPointer = 0;
		this.tipViewListInfCrctInv = [];
		this.tipViewSelectedInfCrctInv = null;
	}

	protected getTicker(): CrctInv{
		return this.ticker;
	}

	protected getCrctInv(): CrctInv{
		return this.getTicker();
	}

	public setCrctInv( ticker: CrctInv ): void{
		this.ticker = ticker;
	}

	abstract copy(): InfCrctInvInterface< T >;
	abstract getNmInfCrctInv(): string;
	abstract getNmInfCrctInvViewed(): string;
	abstract getTipInfCrctInv(): TipInfCrctInv;

	abstract getPerdcListInfCrctInv(): number[];
	abstract setPerdcListInfCrctInv( perdcList: number[] ): void;
	abstract addPerdcListInfCrctInv( perdc: number ): void;
	abstract getPerdcSelectedInfCrctInv(): number;
	abstract setPerdcSelectedInfCrctInv( perdc: number ): void;

	public getTipViewSelectedInfCrctInv(): TipViewInfCrctInv{
		return this.tipViewSelectedInfCrctInv;
	}

	public setTipViewSelectedInfCrctInv( tipView: TipViewInfCrctInv ): void{
		this.tipViewSelectedInfCrctInv = tipView;
	}

	public addTipViewListInfCrctInv( tipView: TipViewInfCrctInv ): void{
		if( this.tipViewListInfCrctInv === null ){
			this.tipViewListInfCrctInv = [];
		}

		this.tipViewListInfCrctInv.push( tipView );
	}

	public getTipViewListInfCrctInv(): TipViewInfCrctInv[]{
		return this.tipViewListInfCrctInv;
	}

	public setTipViewListInfCrctInv( tipViewList: TipViewInfCrctInv[] ): void{
		this.tipViewListInfCrctInv = tipViewList;
	}

	abstract refreshValues(): Promise< T[] >;

	public getPk(): InfCrctInvPk{
		const pk = new InfCrctInvPk( this.getNmInfCrctInv(), this.getPerdcSelectedInfCrctInv(), this.getTipViewSelectedInfCrctInv() );

		return pk;
	}

	public isEqual( otherInfCrctInv: InfCrctInvInterface< T > ): boolean{
		if( otherInfCrctInv != null ){
			const perdcSelectedInfCrctInv1 	 	 = this.getPerdcSelectedInfCrctInv();
			const perdcSelectedInfCrctInv2 	 	 = otherInfCrctInv.getPerdcSelectedInfCrctInv();

			const isEqualNmAndTipView = this.isSameNmAndTipView( otherInfCrctInv );

			return isEqualNmAndTipView === true && ( perdcSelectedInfCrctInv1 === perdcSelectedInfCrctInv2 );
		}
		else{
			return false;
		}
	}

	public isSameNmAndTipView( otherInfCrctInv: InfCrctInvInterface< T > ): boolean{
		if( otherInfCrctInv != null ){
			const nmInfCrctInv1 			 	 = this.getNmInfCrctInv();
			const tipViewSelectedInfCrctInv1 	 = this.getTipViewSelectedInfCrctInv();
			const tipViewCodeSelectedInfCrctInv1 = tipViewSelectedInfCrctInv1 != null ? tipViewSelectedInfCrctInv1.code : null;

			const nmInfCrctInv2 			 	 = otherInfCrctInv.getNmInfCrctInv();
			const tipViewSelectedInfCrctInv2 	 = otherInfCrctInv.getTipViewSelectedInfCrctInv();
			const tipViewCodeSelectedInfCrctInv2 = tipViewSelectedInfCrctInv2 != null ? tipViewSelectedInfCrctInv2.code : null;

			return nmInfCrctInv1                        === nmInfCrctInv2                        && 
				   ( ( tipViewSelectedInfCrctInv1           === null && tipViewSelectedInfCrctInv2 === null ) ||
				     ( tipViewSelectedInfCrctInv1 !== null && tipViewSelectedInfCrctInv2 !== null && tipViewCodeSelectedInfCrctInv1 && tipViewCodeSelectedInfCrctInv2 && tipViewCodeSelectedInfCrctInv1 === tipViewCodeSelectedInfCrctInv2 ) );
		}
		else{
			return false;
		}
	}

	public getValues(): T[]{
		return this.values;
	}

	public getQtValues(): number{
		return this.values != null ? this.values.length : 0;
	}

	public getValueOfIndex( index: number ): T{
		const qtValues = this.values != null ? this.values.length : 0;

		return index >= 0 && index < qtValues ? this.values[ index ] : null;
	}

	abstract getDateRefOfValueOfIndex( index: number ): Date;

	protected setValues( values: T[] ): void{
		this.values = values;
	}

	public getDtFromValues(): Date{
		return this.dtFromValues;
	}

	public setDtFromValues( dt: Date ): void{
		this.dtFromValues = dt;
	}

	public getDtEndValues(): Date{
		return this.dtEndValues;
	}

	public setDtEndValues( dt: Date ): void{
		this.dtEndValues = dt;
	}

	public getQtLimitValues(): number{
		return this.qtLimitValues;
	}

	public setQtLimitValues( qtLimit: number ): void{
		this.qtLimitValues = qtLimit;
	}

	public resetPointerToMin(): void{
		this.setIndexOfPointer( 0 );
	}

	public resetPointerToMax(): void{
		this.setIndexOfPointer( this.values != null ? (this.values.length-1) : 0 );
	}

	public incrPointer( isValidate: boolean ): boolean{
		const maxIndexOfPointer = ( isValidate === true && this.values != null ) ? (this.values.length - 1) : 0;

		if( isValidate === false || this.indexOfPointer < maxIndexOfPointer ){
			this.indexOfPointer++;
			return true;
		}
		else{
			return false;
		}
	}

	public decrPointer( isValidate: boolean ): boolean{
		if( isValidate === false || this.indexOfPointer > 0 ){
			this.indexOfPointer--;
			return true;
		}
		else{
			return false;
		}
	}

	public nextValueOfPointer(): T{
		const maxIndexOfPointer = this.values != null ? (this.values.length - 1) : 0;
		return this.indexOfPointer < maxIndexOfPointer ? this.values[ ++this.indexOfPointer ] : null;
	}

	abstract nextValueOfPointerIfEqualDateRef( dateRef: Date ): T;
	abstract getValueOfPointerIfEqualDateRefAndMovePointerToNext( dateRef: Date ): T;

	public prevValueOfPointer(): T{
		return this.indexOfPointer > 0 ? this.values[ --this.indexOfPointer ] : null;
	}

	abstract prevValueOfPointerIfEqualDateRef( dateRef: Date ): T;
	abstract getValueOfPointerIfEqualDateRefAndMovePointerToPrev( dateRef: Date ): T;

	public getIndexOfPointer(): number{
		return this.indexOfPointer;
	}

	public setIndexOfPointer( index: number ): void{
		this.indexOfPointer = index;
	}
}

<!-- ================================= CONTENT ================================= -->

<form [formGroup]="this._form">
	<mat-dialog-content>
		<div class="grid_container_carteira_mvtc_dialog_add">
			<div class="grid_item_carteira_mvtc_dialog_add_title">
				<h2 mat-dialog-title>Incluir Movimentação</h2>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_buttonClose">
				<button mat-icon-button color="accent" (click)="this.closeDialog()">
					<mat-icon class="material-icons-round" matTooltip="Fecha a janela, cancelando a inclusão da movimentação.">close</mat-icon>
				</button>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_dtRef">
				<mat-form-field>
					<mat-label>Data:</mat-label>

					<input matInput 
						[matDatepicker]="pickerDtRef"
						matTooltip="Data em que ocorreu a movimentação" 
						formControlName="dtRefSelected"
						(ngModelChange)="onChangeDtRefSelected( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerDtRef"></mat-datepicker-toggle>
					<mat-datepicker #pickerDtRef></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_tipMvtc">
				<mat-form-field>
					<mat-label>Tipo:</mat-label>
					<mat-select matNativeControl 
								(selectionChange)="onChangeTipMvtcGrCrctInvAcaoSelected( $event )"
								matTooltip="Selecione o tipo de movimentação/ordem a ser realizada na Carteira." 
								[(value)]="_tipMvtcGrCrctInvAcaoSelected">
						<mat-option *ngFor="let tipMvtc of this._tipMvtcGrCrctInvAcaoList" 
									[value]="tipMvtc" >
							{{ tipMvtc.nmTipMvtcGrCrctInvAcao }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_inv" *ngIf="this._tipMvtcGrCrctInvAcaoSelected.inNcddCdInv">
				<mat-form-field>
					<mat-label>Ativo:</mat-label>
					<mat-select matNativeControl 
								(selectionChange)="onChangeCrctInvSelected( $event )"
								matTooltip="Selecione o ativo da Carteira a ser movimentado." 
								[(value)]="_cdCrctInvSelected">
						<mat-option *ngFor="let crctInv of this._crctInvList" 
									[value]="crctInv.cdInv" >
							{{ crctInv.nmInv }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_prcUnd">
				<mat-form-field>
					<mat-label>Preço:</mat-label>
					<input matInput type="number" 
						   step="0.01"
						   (input)="this.onChangePrcUndSelected( $event )"  
						   matTooltip="Informe o preço unitário de cada ação/cota movimentada." 
						   formControlName="prcUndSelected" >
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_qtdUnd" *ngIf="this._tipMvtcGrCrctInvAcaoSelected.inNcddCdInv">
				<mat-form-field>
					<mat-label>Quantidade:</mat-label>
					<input matInput 
						   type="number" 
					       step="1"
						   (input)="this.onChangeQtdUndSelected( $event )"  
						   matTooltip="Informe a quantidade de ações/cotas movimentadas." 
						   formControlName="qtdUndSelected" >
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_taxaPercRentAaNegc" *ngIf="this.isShowTxPercRentAaNegc() === true">
				<mat-form-field>
					<mat-label>Taxa Percentual Anual Negociada:</mat-label>
					<input matInput 
						   type="number" 
					       step="1"
						   (input)="this.onChangeQtdUndSelected( $event )"  
						   matTooltip="Informe a taxa percentual anual de rentabilidade que foi negociada." 
						   formControlName="vlTaxaPercRentAaNegcSelected" >
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_vlTtlCrctInv_value">
				= {{ this.getVlTtlCrctInvSelected() | number:'1.2-2' }}
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_cotcAcao">
				<span *ngIf="this._controlCotc.isDataLoaded === false then prcCotcUpdating else prcCotc"></span>
				<ng-template #prcCotcUpdating>
					<mat-progress-spinner *ngIf="this._controlCotc.isShowProgressBar" [mode]="this._controlCotc.modeProgressBar" value="this._controlCotc.valueProgressBar" [diameter]="24"></mat-progress-spinner>
				</ng-template>
				<ng-template #prcCotc>
					<ng-container *ngIf="this._cotcAcao != null then prcCotcExist else prcCotcNonExist"></ng-container>
				</ng-template>
				<ng-template #prcCotcExist>
					Cotação mínima no dia foi de {{ this._cotcAcao.prcMin | number:'1.2-2' }}, e a máxima de {{ this._cotcAcao.prcMax | number:'1.2-2' }}.
				</ng-template>
				<ng-template #prcCotcNonExist>
					Não há cotação disponível para a data e Ativo selecionados.
				</ng-template>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_encgList_label">
				Encargos:
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_encgList_value">
				<table mat-table [dataSource]="_tipEncgMvtcGrCrctInvAcaoListDataSource">
					<ng-container matColumnDef="nmTipEncg" sticky>
						<td mat-cell *matCellDef="let element">
							<span>
								{{element.nmTipEncg}}:
								<mat-icon class="icon_help" 
									[matTooltip]="this.getTooltipToTipEncg( element )"
									[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlTipEncg">
						<td mat-cell *matCellDef="let element">
							<mat-form-field>
								<input matInput 
									type="number"
									(blur)="this.onChangeVlEncgSelected( element, $event.target.valueAsNumber )" 
									step="0.01"
									matTooltip="{{element.txDescrTipEncg}}" 
									formControlName="{{'tipEncg_' + element.sglTipEncg}}" >
							</mat-form-field>
						</td>
					</ng-container>

					<ng-container matColumnDef="optionsTipEncg">
						<td mat-cell *matCellDef="let element">
							<span class="column_options">
								<button mat-icon-button *ngIf="this.isEditableTipEncgSelected( element ) == false" color="accent" (click)="this.onChangeEditableTipEncg( element, true )">
									<mat-icon matTooltip="Habilitar a edição do valor do encargo.">edit</mat-icon>
								</button>

								<button mat-icon-button *ngIf="this.isEditableTipEncgSelected( element ) == true" color="accent" (click)="this.onChangeEditableTipEncg( element, false )">
									<mat-icon matTooltip="Desabilitar a edição do valor do encargo.">cancel</mat-icon>
								</button>
							</span>
						</td>
					</ng-container>

					<tr mat-row        *matRowDef="let row; columns: _tipEncgMvtcGrCrctInvAcaoListDisplayedColumns;"></tr>
				</table>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_vlTtlEncg_value">
				= {{ this.getVlTtlEncgSelected() | number:'1.2-2' }}
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_vlTtlMvtc_label">
				VALOR TOTAL:
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_vlTtlMvtc_value">
				{{ this.getVlTtlMvtcSelected() | number:'1.2-2' }}
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_txObs">
				<mat-form-field>
					<mat-label>Observação:</mat-label>
					<input matInput (change)="onChangeTxObsSelected( $event )" 
									formControlName="txObsSelected"
									matTooltip="Informe uma observação sobre a movimentação." >
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_mvtc_dialog_add_buttons">
				<button mat-raised-button class="buttonConfirmYes" matTooltip="Incluir nova movimentação." (click)="onAddMvtcGrCrctInv()">
					Incluir
				</button>
			</div>
		</div>
	</mat-dialog-content>
</form>


<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Ativos</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<div class="grid_container_inv_list">
		<div class="grid_item_inv_list_filters">
			<div class="grid_container_inv_list_filters">
				<div class="grid_item_inv_list_filters_tipInv">
					<mat-form-field>
						<mat-label>Tipo de Investimento:</mat-label>
							<mat-select matNativeControl (selectionChange)="updateCdTipInvSelected( $event )" formControlName="cdTipInvSelected">
								<mat-option *ngFor="let tipInv of tipInvList" [value]="tipInv.cdTipInv" >{{ tipInv.nmTipInv }}</mat-option>
							</mat-select>
					</mat-form-field>
				</div>

				<div class="grid_item_inv_list_filters_espc">
					<mat-form-field>
						<mat-label>Espécie:</mat-label>
							<mat-select matNativeControl (selectionChange)="updateCdEspcSelected( $event )" formControlName="cdEspcSelected">
								<mat-option *ngFor="let tipEspc of tipEspcList" [value]="tipEspc.cdEspcAcao" >{{ tipEspc.nmEspcAcao }}</mat-option>
							</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>

		<div class="grid_item_inv_list_ticker">
			<mat-form-field>
				<mat-label>Filtrar Lista de Ativos:</mat-label>

				<input matInput type="text" (keyup)="applyFilterNmTicker( $event )" placeholder="petr4, banco, índice, materiais..." formControlName="nmTicker" >
			</mat-form-field>
		</div>

		<div class="grid_item_inv_list_checkIsOnlyMonitored">
			<mat-checkbox (change)="updateOnlyEmprMonitored()" formControlName="isOnlyEmprMonitored" >
				Mostrar apenas ativos monitorados
			</mat-checkbox>
		</div>

		<div class="grid_item_inv_list_invList">
			<table mat-table [dataSource]="dataSourceInvList">
				<ng-container matColumnDef="inv" sticky>
					<th mat-header-cell *matHeaderCellDef> Ticker </th>
					<td mat-cell *matCellDef="let element">
						<a routerLinkActive="active" (click)="detail( $event, element.cdInv )" class="linkInTable">
							{{element.nmInv}}
						</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="tipInv">
					<th mat-header-cell *matHeaderCellDef> Tipo </th>
					<td mat-cell *matCellDef="let element"> {{element.tipInv.nmTipInv}} </td>
				</ng-container>

				<ng-container matColumnDef="empr">
					<th mat-header-cell *matHeaderCellDef> Empresa </th>
					<td mat-cell *matCellDef="let element"> 
						<a [routerLink]="" routerLinkActive="active" (click)="detailEmpr( $event, element.empr.cdEmpr )" class="label_value linkInTable"> 
							<ng-container *ngIf="element.empr.nmAbrvEmpr;else nmEmprTemplate">
								{{element.empr.nmAbrvEmpr}}
							</ng-container>
							<ng-template #nmEmprTemplate>
								{{element.empr.nmEmpr}}
							</ng-template>
						</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="setor">
					<th mat-header-cell *matHeaderCellDef> Setor </th>
					<td mat-cell *matCellDef="let element"> 
						<a [routerLink]="" routerLinkActive="active" (click)="detailSetorEcnm( $event, element.empr.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm )" class="label_value linkInTable"> 
							{{element.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
						</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="options">
				<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<span class="column_options">
							<button mat-icon-button color="accent" (click)="detail( $event, element.cdInv )" ><mat-icon matTooltip="Detalhar ativo">description</mat-icon></button>
							<button mat-icon-button color="accent" (click)="compareTickers( element.cdInv )" ><mat-icon matTooltip="Comparar ativos">compare_arrows</mat-icon></button>
						</span>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>

			<mat-paginator 	[pageSizeOptions]="[20, 50, 100, 200]" 
							[pageSize]="this.paginatorPageSize"
							[pageIndex]="this.paginatorPageIndex"
							(page)="changePageSize($event)" 
							showFirstLastButtons></mat-paginator>
		</div>
	</div>
</form>


import { MAT_DIALOG_DATA, MatDialog                      } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild            } from '@angular/core';
import { DomSanitizer                                    } from '@angular/platform-browser';
import { ActivatedRoute                                  } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { MatDialogRef                                    } from '@angular/material/dialog';

import { AplicationStateService             } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService              } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService                      } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService                         } from 'src/app/_services/inv/inv.service';
import { MatSelectChange                    } from '@angular/material/select';
import { CrctInv, CrctInvAcao               } from 'src/app/_models/inv';
import { MatPaginator                       } from '@angular/material/paginator';
import { MatTableDataSource                 } from '@angular/material/table';
import { MatCheckboxChange                  } from '@angular/material/checkbox';
import { CarteiraDialogEditDesktopComponent } from './desktop/carteira-dialog-edit-desktop.component';
import { GrCrctInvService                   } from 'src/app/_services/grCrctInv/grCrctInv.service';
import { GrupoCrctInvAcao                   } from 'src/app/_models/grCrctInv/grCrctInv';
import { TickerDialogSelectDesktopComponent } from 'src/app/_components/ticker/dialog/select/desktop/ticker-dialog-select-desktop.component';


export abstract class CarteiraDialogEditComponent implements OnInit {
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'CarteiraDialogEdit_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'CarteiraDialogEdit_paginatorPageIndex';

  //Carteira que está sendo detalhada.
  private grCrctInv: GrupoCrctInvAcao;

  //Lista de ativos da carteira.
  public _invListDisplayedColumns: string[] = [ 'inv', 'tipInv', 'empr', 'setor', 'options' ];
  public _invListDataSource: MatTableDataSource< CrctInv >;
  @ViewChild( 'invListPaginator', {static: true} ) paginator: MatPaginator;

  public _carteiraForm;

  public _isEditingTxDescrGrCrctInv: boolean = false;

  public _paginatorPageSize;
  public _paginatorPageIndex;

  constructor( public dialogRef: MatDialogRef< CarteiraDialogEditDesktopComponent >,
               protected formBuilder           : FormBuilder            ,
               protected dialog                : MatDialog              ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService             ,
               protected grCrctInvService      : GrCrctInvService       ,
               @Inject( MAT_DIALOG_DATA ) public data: any ){
  }

  backdropClick(){
    console.log( 'backdropClick.' );
  }

  ngOnInit() {
    //console.log( 'TickerChartComponent.ngOnInit()...' );

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitDetailGrCrctInv = this.initDetailGrCrctInv();
    promises.push( pInitDetailGrCrctInv );

    const pInitListItemGrCrctInv = this.initListItemCrctInv();
    promises.push( pInitListItemGrCrctInv );

    this.monitoryInit( promises );
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          //console.log( 'Finalizadas ' + promisesFinish + '/' + qtPromises + ' tarefas.' );
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._carteiraForm = _this.formBuilder.group(
        { nmGrCrctInv         : '',
          txDescrGrCrctInv    : new FormControl(),
          txDescrGrCrctInvLast: new FormControl() }
      );

      resolve();
    });

    return p;
  }

  private getNmGrCrctInv(){
    return this._carteiraForm.controls[ 'nmGrCrctInv' ].value;
  }

  private setNmGrCrctInv( nmGrCrctInvAcao: string ){
    this.grCrctInv.nmGrCrctInvAcao = nmGrCrctInvAcao;

    this._carteiraForm.patchValue( { nmGrCrctInv: nmGrCrctInvAcao } );
  }

  public getTxDescrGrCrctInv(){
    return this._carteiraForm.controls[ 'txDescrGrCrctInv' ].value;
  }

  private setTxDescrGrCrctInv( txDescrGrCrctInvAcao: string ){
    this.grCrctInv.txDescrGrCrctInvAcao = txDescrGrCrctInvAcao;

    this._carteiraForm.patchValue( { txDescrGrCrctInv: txDescrGrCrctInvAcao } );
  }

  private getTxDescrGrCrctInvLast(){
    return this._carteiraForm.controls[ 'txDescrGrCrctInvLast' ].value;
  }

  private setTxDescrGrCrctInvLast( txDescrGrCrctInv: string ){
    this._carteiraForm.patchValue( { txDescrGrCrctInvLast: txDescrGrCrctInv } );
  }

  public isNmGrCrctInvFilled(): boolean{
    const nmGrCrctInv = this.getNmGrCrctInv();
    const isFilled = ( nmGrCrctInv && nmGrCrctInv != null && nmGrCrctInv.trim().length > 0 ) ? true : false

    return isFilled;
  }

  public isTxDescrGrCrctInvFilled(): boolean{
    const value = this.getTxDescrGrCrctInv()
    const isFilled = ( value && value != null && value.trim().length > 0 ) ? true : false

    return isFilled;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initDetailGrCrctInv(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const idUsr = _this.authenticationService.getSessionService().currentUserValue.id;
      const cdGrCrctInv = _this.data.cdGrCrctInv;

      //console.log( 'Usr/Gr: ' + idUsr + '/' + cdGrCrctInv );
      //console.log( Object.keys( cdGrCrctInv ) + '; ' + Object.values( cdGrCrctInv ) );

      _this.grCrctInvService.detailGrupo( cdGrCrctInv )
        .then( grCrctInv => {
          //console.log( 'nmGrCrctInvAcao: ' + grCrctInv.nmGrCrctInvAcao );
          _this.grCrctInv = grCrctInv;

          _this.setNmGrCrctInv( grCrctInv.nmGrCrctInvAcao );
          _this.setTxDescrGrCrctInv( grCrctInv.txDescrGrCrctInvAcao );

          resolve();
        } )
        .catch( error => {
          console.log( error );
        } );
    } );

    return p;
  }

  private initListItemCrctInv(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //const idUsr = _this.authenticationService.getSessionService().currentUserValue.id;
      const cdGrCrctInv = _this.data.cdGrCrctInv;

      //console.log( 'Usr/Gr: ' + idUsr + '/' + cdGrCrctInv );
      //console.log( Object.keys( cdGrCrctInv ) + '; ' + Object.values( cdGrCrctInv ) );

      _this.grCrctInvService.listItensGrupo( cdGrCrctInv, null, true, null, true, true, true, true, true, false )
        .then( itemGrCrctInvList => {
          const qtItemGrCrctInv = itemGrCrctInvList != null ? itemGrCrctInvList.length : 0;

          //console.log( 'Carteira possui "' + qtItemGrCrctInv + '" ativos vinculados.' );

          const crctInvList: CrctInv[] = [];
          const promisesCrctInv: Promise< CrctInv >[] = [];

          for( let indexItem = 0; indexItem < qtItemGrCrctInv; indexItem++ ){
            const itemGrCrctInvIterate = itemGrCrctInvList[ indexItem ];
            const cdInv = itemGrCrctInvIterate.crctInv.cdInv;

            //Detalha o ativo.
            const pDetailCrctInv = _this.detailCrctInv( cdInv );

            promisesCrctInv.push( pDetailCrctInv );

            pDetailCrctInv
              .then( crctInv => {
                crctInvList.push( crctInv );
              } )
              .catch( error => {
                console.log( error );
                window.alert( error );
              } );
          }

          Promise.all( promisesCrctInv )
            .then( response => {
              _this.addTickerList( crctInvList, false );

              resolve();
            } )
            .catch( error => {
              console.log( error );
              window.alert( error );
            } );
        } );
    } );

    return p;
  }

  private detailCrctInv( cdInv: number ): Promise< CrctInv >{
    //console.log( 'detailCrctInv( ' + cdInv + ' )...' );

    const _this = this;

    const p = new Promise< CrctInv >( function( resolve, reject ){
      if( cdInv && cdInv != null ){
        _this.invService.detail( cdInv )
          .then( ticker => {
            if( ticker != null ){
              resolve( ticker.crctInv );
            }
            else{
              resolve( null );
            }
          } )
          .catch( error => {
            console.log( error );
            reject( error );
          } );
      }
      else{
        resolve( null );
      }
    } );

    return p;
  }

  onSubmit(){
    // stop here if form is invalid
    if( this._carteiraForm.invalid ){
      console.log( '_carteiraForm.invalid!' );

      return;
    }
    else{
      //const nmTipItemOrcm = this._tickerForm.controls.nmTipItemOrcm.value;

      //this.insertTio( nmTipItemOrcm );
    }
  }

  private addTicker( ticker: CrctInv, isUpdateCarteira: boolean ){
    this.addTickerList( [ ticker ], isUpdateCarteira );
  }

  private addTickerList( tickerList: CrctInv[], isUpdateCarteira: boolean ): Promise< void >{
    if( this._invListDataSource == null ){
      this._invListDataSource = new MatTableDataSource< CrctInv >( [] );
      this._invListDataSource.paginator = this.paginator;
    }

    let data = this._invListDataSource.data;
    const crctInvToUpdateList: CrctInv[] = [];

    if( tickerList != null ){
      tickerList.forEach( ticker => {
        const indexOfTicker = this.getIndexOfTicker( ticker );

        if( indexOfTicker < 0 ){
          data.push( ticker );
          crctInvToUpdateList.push( ticker );
        }
      } );
    }

    this._invListDataSource.data = data;

    const _this = this;

    const p = new Promise< void >( function( resolve, reject ){
      //Se isUpdateCarteira igual a true, entao chama servico de atualizacao dos itens da carteira.
      if( isUpdateCarteira === true ){
        const cdUsr = _this.authenticationService.getSessionService().currentUserValue.id;
        const cdGrCrctInv = _this.grCrctInv.cdGrCrctInvAcao;
        const promises: Promise< void >[] = [];

        crctInvToUpdateList.forEach( crctInvIterate => {
          const cdInvIterate = crctInvIterate.cdInv;

          const pAddItem = _this.grCrctInvService.addItem( cdGrCrctInv, cdInvIterate );

          pAddItem
            .then( response => {
              //console.log( 'Ativo adicionado.' );
            } )
            .catch( error => {
              console.log( error );
              window.alert( error );
            } );

          promises.push( pAddItem );
        } );

        Promise.all( promises )
          .then( response => {
            //console.log( 'Novos itens adicionados à Carteira.' );

            resolve();
          } )
          .catch( error => {
            console.log( error );
            window.alert( error );
            reject();
          } );
      }
      else{
        resolve();
      }
    } );

    return p;
  }

  public onRemoveTickerOfGrCrctInv( ticker: CrctInv ){
    //console.log( 'onRemoveTickerOfGrCrctInv( ' + ticker.cdInv + ' )...' );

    const indexOfTicker = this.getIndexOfTicker( ticker );

    if( indexOfTicker >= 0 ){
      let data = this._invListDataSource.data;
      data.splice( indexOfTicker, 1 );
      this._invListDataSource.data = data;

      const cdUsr = this.authenticationService.getSessionService().currentUserValue.id;
      const cdGrCrctInv = this.grCrctInv.cdGrCrctInvAcao;

      this.grCrctInvService.removeItem( cdGrCrctInv, ticker.cdInv )
        .then( response => {
          window.alert( 'Ativo excluído da Carteira.' );
        } )
        .catch( error => {
          window.alert( error );
        } );
    }
  }

  public onChangeNmGrCrctInv( nmGrCrctInv: string ): void{
    this.setNmGrCrctInv( nmGrCrctInv );
    this.setGrCrctInv( nmGrCrctInv, null, null );
  }

  public onClickEditTxDescrGrCrctInv(): void{
    this.setTxDescrGrCrctInvLast( this.getTxDescrGrCrctInv() );
    this._isEditingTxDescrGrCrctInv = true;
  }

  public onClickCancelTxDescrGrCrctInv(): void{
    this.setTxDescrGrCrctInv( this.getTxDescrGrCrctInvLast() );
    this._isEditingTxDescrGrCrctInv = false;
  }

  public onClickSaveTxDescrGrCrctInv(): void{
    this._isEditingTxDescrGrCrctInv = false;
    this.onChangeTxDescrGrCrctInv( this.getTxDescrGrCrctInv() );
  }

  public onChangeTxDescrGrCrctInv( txDescrGrCrctInv: string ): void{
    this.setTxDescrGrCrctInv( txDescrGrCrctInv );
    this.setGrCrctInv( null, txDescrGrCrctInv, null );
  }

  private setGrCrctInv( nmGrCrctInv: string, txDescrGrCrctInv: string, inGrCrctInvAtivo: boolean ): void{
    const cdGrCrctInv = this.grCrctInv.cdGrCrctInvAcao;

    this.grCrctInvService.setGrupo( cdGrCrctInv, nmGrCrctInv, txDescrGrCrctInv, inGrCrctInvAtivo )
      .then( response => {
        //console.log( 'Informações da Carteira alteradas.' );
      } )
      .catch( error => {
        window.alert( error );
      } );
  }

  public getIndexOfTicker( ticker: CrctInv ){
    let indexFound = -1;

    if( this._invListDataSource != null ){
      const tickers = this._invListDataSource.data;
      const countTickers = tickers.length;

      for( let iTicker = 0; iTicker < countTickers; iTicker++ ){
          const tickerIterate: CrctInv = tickers[ iTicker ];

          if( ticker.cdInv === tickerIterate.cdInv ){
            indexFound = iTicker;
            break;
          }
      }
    }

    return indexFound;
  }

  public openDialogAddTicker(): void{
    const dialogRef = this.dialog.open( TickerDialogSelectDesktopComponent, { disableClose: false, width: '80%' } );

    const _this = this;

    dialogRef.afterClosed().subscribe( tickers => {
      const pAddTickers = _this.addTickerList( tickers, true );

      pAddTickers
        .then( response => {
          const qtTickers = tickers != null ? tickers.length : 0;

          window.alert( 'Adicionado(s) ' + qtTickers + ' Ativo(s) à Carteira.' );
          console.log( 'Ativos adicionados.' );
        } )
        .catch( error => {
          console.log( 'Erro na inclusao dos ativos à Carteira.' );
          window.alert( error );
        } );
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'backdropClick' );
    });
  }

  public closeDialog(){
    this.dialogRef.close( this.grCrctInv );
  }

  public changePageSize( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }
}

/**
 * Controle da carga (load) e apresentação dos dados.
 */
export class ControlLoad{
  //Um nome para este objeto.
  public nm                   : string;

  //Usuário quer ver?
  public isCanShow            : boolean = false;

  //Permitido mostrar?
  public isAllowedShow        : boolean = false;

  //Dados foram carregados?
  public isDataLoaded		      : boolean = false;

  public isShowProgressBar    : boolean = false;
  public valueProgressBar     : number = 0;
  public modeProgressBar      : string = "indeterminate";

  public constructor( nm: string ){
    this.nm    = nm;
  }

  public initializingLoad(): void{
    this.valueProgressBar  = 0;
    this.isShowProgressBar = true;
    this.isDataLoaded      = false;
  }

  public isLoading(): boolean{
    return this.valueProgressBar < 100 && this.isShowProgressBar === true && this.isDataLoaded === false;
  }

  public loaded(): void{
    this.valueProgressBar  = 100;
    this.isShowProgressBar = false;
    this.isDataLoaded      = true;
  }
}

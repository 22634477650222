import { Component, OnInit, ViewChild         } from '@angular/core';
import { DomSanitizer                         } from '@angular/platform-browser';
import { ActivatedRoute, Router               } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { MatDialog                            } from '@angular/material/dialog';

import { AplicationStateService } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '../../../_services/authentication/authentication.service';
import { AbstractMainComponent  } from '../../abstract-main.component';
import { UsrService             } from 'src/app/_services/usr/usr.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class LoginRecoverComponent extends AbstractMainComponent{
  public _form;

  public _pwdForm      : FormControl = new FormControl( '', [ Validators.required ] );
  public _pwdRepeatForm: FormControl = new FormControl( '', [ Validators.required ] );

  public _nmUsr  : string;
  public _email  : string;
  public _tokenId: string;

  public _isHidePassword: boolean = true;

  constructor( protected router: Router,
               protected activatedRoute: ActivatedRoute,
               protected sanitizer: DomSanitizer,
               protected formBuilder: FormBuilder,
               protected dialog: MatDialog,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected usrService: UsrService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    this.monitoryInit( promises );
  }

  private createForm(){
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._form = _this.formBuilder.group( {  } );

      resolve();
    });

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Verifica se houve o recebimento do e-mail e do token do pedido de recuperacao da senha.
      _this.activatedRoute.queryParams.subscribe( params => {
          _this._nmUsr   = params.nmUsr;
          _this._email   = params.email;
          _this._tokenId = params.id;

          //console.log( 'Usuario/E-mail: ' + _this._nmUsr + '/' + _this._email );
          //console.log( 'Token.: ' + _this._tokenId );

          resolve();
        } );
    } );

    return p;
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          //console.log( 'Finalizadas ' + promisesFinish + '/' + qtPromises + ' tarefas.' );
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  onSubmit(){
    console.log( 'onSubmit()...' );

    console.log( 'onSubmit().' );
  }

  protected refresh(){
  }

  public onChangePassword(): void{
    const nmUsr     = this._nmUsr;
    const email     = this._email;
    const tokenId   = this._tokenId;
    const pwd       = this._pwdForm.value;
    const pwdRepeat = this._pwdRepeatForm.value;

    if( nmUsr != null && nmUsr.length > 0 && tokenId != null && tokenId.length > 0 && email != null && email.length > 0 ){
      if( pwd != null && pwd.length > 0 && pwdRepeat != null && pwdRepeat.length > 0 && pwd === pwdRepeat ){
        this.usrService.registerNewPassword( nmUsr, email, tokenId, pwd )
          .then( response => {
            window.alert( 'Senha alterada com sucesso.' );

            super.goToPage( 'login', null, false );
          } )
          .catch( error => {
            console.error( error );
            window.alert( error.msgErr );
          } );
      }
      else{
        window.alert( 'As senhas informadas não são iguais. Favor repetir a mesma senha nos dois campos.' );
      }
    }
    else{
      window.alert( 'Não houve o recebimento das informações necessárias à alteração da senha.' );
    }
  }
}

<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Destaques</h2>
<div *ngIf="this._isShowProgressBarMain">
	<mat-progress-bar mode="determinate" [value]="this._valueProgressBarMain"></mat-progress-bar>
</div>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Filtros:
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_dtq_list_filters">
			<div class="grid_item_dtq_list_filters_qtMaxDtq">
				<mat-form-field>
					<mat-label>Retornar os Primeiros:</mat-label>
						<mat-select matNativeControl (selectionChange)="this.onChangeQtMaxDtqSelected( $event )" formControlName="qtMaxDtqSelected">
							<mat-option *ngFor="let qtMax of this._qtMaxDtqList" [value]="qtMax" >{{ qtMax }} Destaques</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_dtq_list_filters_dtRef">
				<mat-form-field>
					<mat-label>Data de Referência:</mat-label>
						<mat-select matNativeControl (selectionChange)="this.onChangeDtRefSelected( $event )" formControlName="dtRefSelected">
							<mat-option *ngFor="let dtRef of this._dtRefList" [value]="dtRef" >{{ dtRef.getTime() | date:'dd/MM/yyyy':'+0000' }}</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_dtq_list_filters_volNegcMin">
				<mat-form-field appearance="fill">
					<mat-label>Volume de Negócios Médio de no Mínimo:</mat-label>
					<input matInput type="number" step="100000" (input)="this.onChangeVolNegcMinSelected( $event )" formControlName="volNegcMinSelected">
				</mat-form-field>
			</div>

			<div class="grid_item_dtq_list_filters_perdcList">
				<mat-form-field appearance="fill">
					<mat-label>Periodicidades:</mat-label>
					<mat-select  multiple [formControl]="this._sglPerdcListSelectedFC">
						<mat-option *ngFor="let perdc of this._perdcList" (onSelectionChange)="onChangePerdcSelected($event)" [value]="perdc.sglPerdc">{{perdc.nmPerdc}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</mat-expansion-panel>


	<!-- Mostra cada um dos paineis -->

	<li *ngFor="let dtqControlPanel of this._dtqControlPanels; index as iPanel; first as isFirstPanel">
		<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true" (opened)="onOpenedPanel( dtqControlPanel.nm )">
			<mat-expansion-panel-header class="label_title">
				<mat-panel-title>
					{{dtqControlPanel.title}}:
				</mat-panel-title>
			</mat-expansion-panel-header>

			<li *ngFor="let dtq of dtqControlPanel.dtqControlList; index as iDtq; first as isFirstDtq">
				<div *ngIf="dtq.isAllowedShowDtq" class="grid_container_dtq_list_dtqGeral">
					<div *ngIf="dtq.isAllowedShowDtq" class="grid_item_dtq_list_dtqGeral_label">
						{{dtq.perdc.nmPerdc}}:
					</div>

					<div *ngIf="dtq.isAllowedShowDtq && dtq.isShowProgressBar" class="grid_item_dtq_list_dtqGeral_progress">
						<mat-progress-bar mode="indeterminate" value="this._valueProgressBarMaioresVarPercPrcFechDiario"></mat-progress-bar>
					</div>

					<div *ngIf="dtq.isAllowedShowDtq && dtq.isDtqLoaded" class="grid_item_dtq_list_dtqGeral_value">
						<table mat-table [dataSource]="dtq.dataSource">
							<ng-container matColumnDef="nrSeqDtq" sticky>
								<th mat-header-cell *matHeaderCellDef> # </th>
								<td mat-cell *matCellDef="let element">
									<label>
										{{element.nrSeqDtq}}º
									</label>
								</td>
							</ng-container>

							<ng-container *ngFor="let disCol of dtq.datesDisplayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
								<th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
								<td mat-cell *matCellDef="let element" class="mat-cell-number">
									<span *ngIf="element.dtqList && element.dtqList != null && element.dtqList[colIndex] != null">
										<a [routerLink]="" routerLinkActive="active" (click)="detailCrctInv( element.dtqList[colIndex].crctInv.cdInv )" class="linkInTable"> 
											{{element.dtqList[colIndex].crctInv.nmInv}}: <span [ngClass]="{ 'mat-cell-value-positive': element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.varPrcFech >= 0, 'mat-cell-value-negative': element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.varPrcFech < 0 }">{{(element.dtqList[colIndex].vlDtq) | number:'1.2-2' }}%</span>
										</a> 
										<mat-icon class="icon_help" 
											matTooltip="{{element.dtqList[colIndex].crctInv.crctInvAcao.nmPregao}}

														Posição no ranking geral: {{element.dtqList[colIndex].nrSeqDtq}}º
														Período compreendido de {{element.dtqList[colIndex].dtIniPerd | date:'dd/MM/yyyy':'+0000'}} a {{element.dtqList[colIndex].dtFimPerd | date:'dd/MM/yyyy':'+0000'}}
														Volume de negócios médio nos últimos 3 meses: {{element.dtqList[colIndex].crctInv.crctInvAcao.volNegcMed | shortNumber:'1.0-0' }}

														Cotação no dia {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.dtCotc | date:'dd/MM/yyyy':'+0000'}}:
														&nbsp;&nbsp;&nbsp; Preço Abert.: {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.prcAbert | number:'1.2-2'}}
														&nbsp;&nbsp;&nbsp; Preço Mín./Máx.: {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.prcMin | number:'1.2-2'}} - {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.prcMax | number:'1.2-2'}}
														&nbsp;&nbsp;&nbsp; Preço Fech.: {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.prcFech | number:'1.2-2'}}
														&nbsp;&nbsp;&nbsp; Volume de Neg.: {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.volNegc | shortNumber:''}}
														&nbsp;&nbsp;&nbsp; Variação: {{element.dtqList[colIndex].crctInv.crctInvAcao.cotcAcao.varPrcFech | number:'1.2-2'}}%"
											[matTooltipClass]="'multline-tooltip'" >info
										</mat-icon>
									</span>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="dtq.displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: dtq.displayedColumns;"></tr>
						</table>
					</div>
				</div>
			</li>
		</mat-expansion-panel>
	</li>
</form>
import { MAT_DIALOG_DATA, MatDialog                          } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild     } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl       } from '@angular/forms';

import { MatDialogRef                             } from '@angular/material/dialog';

import { CarteiraMvtcDialogAddDesktopComponent } from './desktop/carteira-mvtc-dialog-add-desktop.component';

import { AplicationStateService             } from '../../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { GrCrctInvService } from 'src/app/_services/grCrctInv/grCrctInv.service';

import { CrctInv, TipInv } from 'src/app/_models/inv';
import { MovimentacaoGrupoCrctInvAcao, TipMvtcGrupoCrctInvAcao, RgrEncgMvtc, TipEncg, EncgMvtcGrCrctInvAcao, GrupoCrctInvAcao } from 'src/app/_models/grCrctInv/grCrctInv';
import { ControlLoad } from 'src/app/_models/control/controlLoad';
import { CarteiraMvtcAddDataParm } from './models/carteiraMvtcAddDataParm';
import { DateSimpleService } from 'src/app/_services/date-simple/date-simple.service';
import { MvtcGrCrctInvService } from 'src/app/_services/mvtcGrCrctInv/mvtcGrCrctInv.service';
import { GeneralDialogConfirmDesktopComponent } from 'src/app/_components/general/dialog/confirm/desktop/general-dialog-confirm-desktop.component';
import { ConfirmDataParm } from 'src/app/_components/general/dialog/confirm/models/confirmDataParm';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CotcService } from '@services/cotc/cotc.service';
import { HstCotcAcao } from '@models/cotc';


export abstract class CarteiraMvtcDialogAddComponent implements OnInit {
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'CarteiraDialogEdit_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'CarteiraDialogEdit_paginatorPageIndex';

  //Controls de cada seção da pagina.
  public _controlMain: ControlLoad;
  public _controlParm: ControlLoad;
  public _controlCrctInvList: ControlLoad;
  public _controlCotc: ControlLoad;
  public _controlTipMvtcGrCrctInvList: ControlLoad;
  public _controlTipEncgMvtcList: ControlLoad;
  public _controlRgrEncgMvtcList: ControlLoad;

  public _form;

  private _cdGrCrctInvAcaoSelected: number;
  public _cdCrctInvSelected: number;
  public _tipMvtcGrCrctInvAcaoSelected: TipMvtcGrupoCrctInvAcao;
  public _txObsSelected    : string = null;

  //Lista de ativos da carteira.
  public _crctInvList: CrctInv[];

  //Cotacao do ticker na data selecionada.
  public _cotcAcao: HstCotcAcao;

  //Lista os tipos de movimentacao da carteira.
  public _tipMvtcGrCrctInvAcaoList: TipMvtcGrupoCrctInvAcao[];

  //Lista dos tipos de encargo possiveis para a movimentacao da carteira.
  public _tipEncgMvtcGrCrctInvAcaoListDisplayedColumns: string[] = [ 'nmTipEncg', 'vlTipEncg', 'optionsTipEncg' ];
  public _tipEncgMvtcGrCrctInvAcaoListDataSource      : MatTableDataSource< TipEncg >;

  //Lista das movimentacoes adicionadas.
  private _mvtGrCrctInvAcaoAddedList: MovimentacaoGrupoCrctInvAcao[] = [];

  constructor( public dialogRef: MatDialogRef< CarteiraMvtcDialogAddComponent >,
               protected formBuilder           : FormBuilder            ,
               protected dialog                : MatDialog              ,
               protected aplicationStateService: AplicationStateService ,
               protected dateSimpleService     : DateSimpleService      ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService             ,
               protected grCrctInvService      : GrCrctInvService       ,
               protected mvtcGrCrctInvService  : MvtcGrCrctInvService   ,
               protected cotcService           : CotcService            ,
               @Inject( MAT_DIALOG_DATA ) public dataParm: CarteiraMvtcAddDataParm ){
  }

  backdropClick(){
    console.log( 'backdropClick.' );
  }

  ngOnInit() {
    this._controlMain                 = new ControlLoad( 'main' );
    this._controlParm                 = new ControlLoad( 'parm' );
    this._controlCrctInvList          = new ControlLoad( 'crctInvList' );
    this._controlCotc                 = new ControlLoad( 'cotc' );
    this._controlTipMvtcGrCrctInvList = new ControlLoad( 'tipMvtcGrCrctInvList' );
    this._controlTipEncgMvtcList      = new ControlLoad( 'tipEncgMvtcList' );
    this._controlRgrEncgMvtcList      = new ControlLoad( 'rgrEncgMvtcList' );

    this._controlMain.initializingLoad();

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitGeneral = this.initGeneral();
    promises.push( pInitGeneral );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    this.monitoryInit( promises );
  }

  private initGeneral(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initParm(): Promise< any >{
    this._controlParm.initializingLoad();

    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao      = _this.dataParm != null ? _this.dataParm.cdGrCrctInvAcao : null;
      const cdCrctInv            = _this.dataParm != null && _this.dataParm.crctInv != null ? _this.dataParm.crctInv.cdInv : null;
      const tipMvtcGrCrctInvAcao = _this.dataParm != null ? _this.dataParm.tipMvtcGrCrctInvAcao : null;

      //console.log( 'cdGrCrctInvAcao: ' + cdGrCrctInvAcao );

      _this._cdGrCrctInvAcaoSelected      = cdGrCrctInvAcao;
      _this._cdCrctInvSelected            = cdCrctInv;
      _this._tipMvtcGrCrctInvAcaoSelected = tipMvtcGrCrctInvAcao;

      const promises: Promise< any >[] = [];

      //Carrega a lista de tickers.
      const pLoadCrctInv = _this.loadCrctInvList( cdCrctInv );
      promises.push( pLoadCrctInv );

      if( cdCrctInv != null ){
        pLoadCrctInv
          .then( response => {
            _this.onChangeCrctInvSelected( null );
          } );
      }

      //Carrega a lista de tipos de movimentação.
      const sglTipMvtcGrCrctInvAcao = tipMvtcGrCrctInvAcao != null ? tipMvtcGrCrctInvAcao.sglTipMvtcGrCrctInvAcao : null;

      const pLoadTipMvtc = _this.loadTipMvtcGrCrctInvList( null, null, sglTipMvtcGrCrctInvAcao );
      promises.push( pLoadTipMvtc );

      //Monitora a finalizacao de todas as tarefas.
      Promise.all( promises )
        .then( response => {
          _this._controlParm.loaded();

          resolve();
        } );
    } );

    return p;
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    const _this = this;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          _this._controlMain.valueProgressBar = promisesFinish / qtPromises * 100;
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        _this._controlMain.loaded();

        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._form = _this.formBuilder.group(
        { dtRefSelected           : null,
          qtdUndSelected          : new FormControl( {value: 1   , disabled: false}, Validators.required ),
          prcUndSelected          : new FormControl( {value: 0   , disabled: false}, Validators.required ),
          vlTaxaPercRentAaNegcSelected: new FormControl( {value: null, disabled: false}, Validators.required ),
          vlTtlCrctInvSelected    : new FormControl( {value: 0   , disabled: true }, Validators.required ),
          vlTtlEncgSelected       : new FormControl( {value: 0   , disabled: true }, Validators.required ),
          vlTtlMvtcSelected       : new FormControl( {value: 0   , disabled: true }, Validators.required ),
          txObsSelected           : new FormControl( {value: null, disabled: false} )
        } );

      //Define a data de referencia inicial a ser apresentada ao usuario.
      _this.setDtRefSelected( _this.dateSimpleService.getLastBusinessDay( new Date() ) );

      resolve();
    });

    return p;
  }

  private addAttributeInForm( name: string, value: FormControl ): void{
    //console.log( 'addAttributeInForm( ' + name + ', ' + value + ' )...' );
    this._form.addControl( name, value );
  }

  private getCdGrCrctInvAcaoSelected(): number{
    return this._cdGrCrctInvAcaoSelected;
  }

  private getCdCrctInvSelected(): number{
    return this._cdCrctInvSelected;
  }

  private setCdCrctInvSelected( cdCrctInv: number ): void{
    this._cdCrctInvSelected = cdCrctInv;
  }

  private getCrctInvSelected(): CrctInv{
    let crctInvSelected: CrctInv = null;

    const cdCrctInvSelected = this.getCdCrctInvSelected();

    //console.log( 'getCrctInvSelected - cdCrctInvSelected: ' + cdCrctInvSelected );

    const qtElements = ( cdCrctInvSelected != null && this._crctInvList != null ) ? this._crctInvList.length : 0;

    //console.log( 'qtElements: ' + qtElements );

    for( let indexElement = 0; indexElement < qtElements; indexElement++ ){
      const crctInvIterate = this._crctInvList[ indexElement ];

      if( crctInvIterate.cdInv == cdCrctInvSelected ){
        crctInvSelected = crctInvIterate;
        break;
      }
    }

    return crctInvSelected;
  }

  private getTipInvOfCrctInvSelected(): TipInv{
    const crctInvSelected: CrctInv = this.getCrctInvSelected();
    const tipInvSelected: TipInv = crctInvSelected != null ? crctInvSelected.tipInv : null;

    return tipInvSelected;
  }

  private getDtRefSelected(): Date{
    return this._form.controls[ 'dtRefSelected' ].value;
  }

  private setDtRefSelected( dtRef: Date ): void{
    this._form.patchValue( { dtRefSelected: dtRef } );
    this.onLoadCotc();
  }

  private getTipMvtcGrCrctInvSelected(): TipMvtcGrupoCrctInvAcao{
    return this._tipMvtcGrCrctInvAcaoSelected;
  }

  private getSglTipMvtcGrCrctInvSelected(): string{
    const tipMvtcGrupoCrctInvAcao: TipMvtcGrupoCrctInvAcao = this.getTipMvtcGrCrctInvSelected();

    return tipMvtcGrupoCrctInvAcao != null ? tipMvtcGrupoCrctInvAcao.sglTipMvtcGrCrctInvAcao : null;
  }

  private getTxObsSelected(): string{
    return this._form.controls[ 'txObsSelected' ].value;
  }

  private getQtdUndSelected(): number{
    return this._form.controls[ 'qtdUndSelected' ].value;
  }

  private getPrcUndSelected(): number{
    return this._form.controls[ 'prcUndSelected' ].value;
  }

  private getVlTaxaPercRentAaNegcSelected(): number{
    return this._form.controls[ 'vlTaxaPercRentAaNegcSelected' ].value;
  }

  public isEditableTipEncgSelected( tipEncg: TipEncg ): boolean{
    const nameAttr = 'tipEncg_' + tipEncg.sglTipEncg;

    return !this._form.controls[ nameAttr ].disabled;
  }

  private setEditableTipEncg( tipEncg: TipEncg, isEditable: boolean ): void{
    const nameAttr = 'tipEncg_' + tipEncg.sglTipEncg;
    const control: FormControl = this._form.controls[ nameAttr ];

    if( isEditable == true ){
      control.enable();
    }
    else{
      control.disable();
    }
  }

  private getValueTipEncgSelected( tipEncg: TipEncg ): number{
    const nameAttr = 'tipEncg_' + tipEncg.sglTipEncg;
    const value = Number( this._form.controls[ nameAttr ].value );

    //console.log( 'value: ' + value );

    return value;
  }

  private setValueTipEncgSelected( tipEncg: TipEncg, value: number ): void{
    const nameAttr = 'tipEncg_' + tipEncg.sglTipEncg;

    //console.log( 'setValueTipEncgSelected( ' + nameAttr + ', ' + value + ' )...' );

    const value2Digits = Number( value.toFixed( 2 ) );

    this._form.controls[ nameAttr ].setValue( value2Digits );
  }

  public getVlTtlCrctInvSelected(): number{
    return this._form.controls[ 'vlTtlCrctInvSelected' ].value;
  }

  private setVlTtlCrctInvSelected( vl: number ){
    //console.log( 'setVlTtlCrctInvSelected( ' + vl + ' )...' );

    this._form.patchValue( { vlTtlCrctInvSelected: vl } );
  }

  private getEncgMvtcGrCrctInvAcaoList(): EncgMvtcGrCrctInvAcao[]{
    const encgMvtcList: EncgMvtcGrCrctInvAcao[] = [];

    const tipEncgMvtcList = this._tipEncgMvtcGrCrctInvAcaoListDataSource !=null ? this._tipEncgMvtcGrCrctInvAcaoListDataSource.data : null;

    if( tipEncgMvtcList != null ){
      tipEncgMvtcList.forEach( tipEncgMvtcIterate => {
        const encgMvtc = new EncgMvtcGrCrctInvAcao();
        encgMvtc.mvtcGrCrctInvAcao = new MovimentacaoGrupoCrctInvAcao();
        encgMvtc.tipEncg           = tipEncgMvtcIterate;
        encgMvtc.vlEncg            = this.getValueTipEncgSelected( tipEncgMvtcIterate );
        encgMvtc.txObsEncg         = null;

        encgMvtcList.push( encgMvtc );
      } );
    }

    return encgMvtcList;
  }

  public getVlTtlEncgSelected(): number{
    return this._form.controls[ 'vlTtlEncgSelected' ].value;
  }

  private setVlTtlEncgSelected( vl: number ){
    //console.log( 'setVlTtlEncgSelected( ' + vl + ' )...' );

    this._form.patchValue( { vlTtlEncgSelected: vl } );
  }

  public getVlTtlMvtcSelected(): number{
    return this._form.controls[ 'vlTtlMvtcSelected' ].value;
  }

  private setVlTtlMvtcSelected( vl: number ){
    this._form.patchValue( { vlTtlMvtcSelected: vl } );
  }

  public isShowTxPercRentAaNegc(): boolean{
    let isShow: boolean = this._tipMvtcGrCrctInvAcaoSelected != null && this._tipMvtcGrCrctInvAcaoSelected.inNcddVlTaxaPercRentAaNegc ? true : false;

    if( isShow === true ){
      const tipInvSelected: TipInv = this.getTipInvOfCrctInvSelected();

      //Verifica se trata-se de tipo de investimento 4-Tesouro Direto.
      isShow = tipInvSelected != null && tipInvSelected.cdTipInv === 4;
    }

    return isShow;
  }

  public getTooltipToTipEncg( tipEncg: TipEncg ): string{
    let tooltipStr = "";

    const txDescrEncgMvtc = tipEncg.txDescrTipEncg != null ? tipEncg.txDescrTipEncg.trim() : null;
    const rgrEncgMvtc = tipEncg.rgrEncgMvtcSelected;
    const vlTxPercEncgMvtc = rgrEncgMvtc != null ? rgrEncgMvtc.vlTxPercEncgMvtcPadr : null;
    const vlEncgMvtcMin = rgrEncgMvtc != null ? rgrEncgMvtc.vlEncgMvtcMin : null;
    const vlEncgMvtcMax = rgrEncgMvtc != null ? rgrEncgMvtc.vlEncgMvtcMax : null;

    if( txDescrEncgMvtc != null ){
      tooltipStr += ( txDescrEncgMvtc + "\n" );
    }

    if( vlTxPercEncgMvtc != null ){
      tooltipStr += ( "Taxa: " + vlTxPercEncgMvtc + " %" + "\n" );
    }

    if( vlEncgMvtcMin != null && vlEncgMvtcMin != 0 ){
      tooltipStr += ( "Valor Mínimo: " + vlEncgMvtcMin + "\n" );
    }

    if( vlEncgMvtcMax != null && vlEncgMvtcMax != 0 ){
      tooltipStr += ( "Valor Máximo: " + vlEncgMvtcMax + "\n" );
    }

    return tooltipStr;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  onSubmit(){
    // stop here if form is invalid
    if( this._form.invalid ){
      console.log( '_form.invalid!' );

      return;
    }
    else{
      //const nmTipItemOrcm = this._tickerForm.controls.nmTipItemOrcm.value;

      //this.insertTio( nmTipItemOrcm );
    }
  }

  private loadCrctInvList( cdInv: number ): Promise< any >{
    const _this = this;

    _this._controlCrctInvList.initializingLoad();

    const p = new Promise< any >( function( resolve, reject ){
      _this.grCrctInvService.listItensGrupo( _this._cdGrCrctInvAcaoSelected, cdInv, null, null, true, true, false, false, false, false )
        .then( itemGrCrctInvList => {
          if( itemGrCrctInvList != null ){
            _this._crctInvList = [];

            itemGrCrctInvList.forEach( itemGrCrctInv => {
              _this._crctInvList.push( itemGrCrctInv.crctInv );
            } );
          }

          resolve();
        } )
        .catch( error => {
          console.error( error );

          reject();
        } )
        .finally( () => {
          _this._controlCrctInvList.loaded();
        } );
    } );

    return p;
  }


  private onLoadCotc(): Promise< any >{
    const promises = [];

    if( this._controlCotc.isCanShow === true ){
      const cdInv  = this.getCdCrctInvSelected();
      const dtCotc = this.getDtRefSelected();

      if( cdInv != null && cdInv > 0 ){
        promises.push( this.loadCotc( cdInv, dtCotc ) );
      }
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          console.error( err );
          reject();
        } );
    } );

    return p;
  }

  private loadCotc( cdInv: number, dtCotc: Date ): Promise< any >{
    const _this = this;

    _this._controlCotc.initializingLoad();

    const p = new Promise< any >( function( resolve, reject ){
      _this.cotcService.detail( cdInv, dtCotc )
        .then( respCotc => {
          _this._cotcAcao = respCotc;

          resolve();
        } )
        .catch( error => {
          reject();
        } )
        .finally( () => {
          _this._controlCotc.loaded();
        } );
    } );

    return p;
  }

  private loadTipMvtcGrCrctInvList( cdTipInv: number, cdEspcAcao: number, sglTipMvtcGrCrctInvAcao: string ): Promise< any >{
    const _this = this;

    _this._controlTipMvtcGrCrctInvList.initializingLoad();

    const p = new Promise< any >( function( resolve, reject ){
      _this.mvtcGrCrctInvService.listTipMvtc( cdTipInv, cdEspcAcao, sglTipMvtcGrCrctInvAcao )
        .then( tipMvtcGrCrctInvList => {
          _this._tipMvtcGrCrctInvAcaoList = tipMvtcGrCrctInvList;

          //Se houver apenas 1 elemento na lista, seleciona-o.
          if( tipMvtcGrCrctInvList != null && tipMvtcGrCrctInvList.length === 1 ){
            _this._tipMvtcGrCrctInvAcaoSelected = tipMvtcGrCrctInvList[0];
          }
        } )
        .catch( error => {
          console.error( error );
        } )
        .finally( () => {
          _this._controlTipMvtcGrCrctInvList.loaded();
        } );
    } );

    return p;
  }

  private loadTipEncgMvtcList( sglTipMvtcGrCrctInvAcao: string, cdTipInv: number, cdEspcAcao: number, dtRefMvtc: Date ): Promise< TipEncg >{
    const _this = this;

    _this._controlTipEncgMvtcList.initializingLoad();

    const p = new Promise< any >( function( resolve, reject ){
      _this.mvtcGrCrctInvService.listTipEncgMvtc( sglTipMvtcGrCrctInvAcao, cdTipInv, cdEspcAcao )
        .then( tipEncgMvtcList => {
          const promises: Promise< any >[] = [];

          if( _this._tipEncgMvtcGrCrctInvAcaoListDataSource == null ){
            _this._tipEncgMvtcGrCrctInvAcaoListDataSource = new MatTableDataSource< TipEncg >( [] );
          }

          //Para cada tipo de encargo, carrega a regra a ser aplicada.
          tipEncgMvtcList.forEach( tipEncgMvtc => {
            const sglTipEncg = tipEncgMvtc.sglTipEncg;

            //console.log( tipEncgMvtc.sglTipEncg + '/' + tipEncgMvtc.nmTipEncg + '/' + tipEncgMvtc.txDescrTipEncg + '/' + tipEncgMvtc.inTipEncgAtivo );

            const pIterate = _this.mvtcGrCrctInvService.listRgrEncgMvtc( sglTipMvtcGrCrctInvAcao, sglTipEncg, cdTipInv, cdEspcAcao, dtRefMvtc, 1 );
            promises.push( pIterate );

            pIterate
              .then( rgrEncgMvtcList => {
                const rgrEncgMvtc = rgrEncgMvtcList != null && rgrEncgMvtcList.length > 0 ? rgrEncgMvtcList[ 0 ] : null;

                let vlEncgMvtcPadr: number = 0;

                if( rgrEncgMvtc != null ){
                  vlEncgMvtcPadr = rgrEncgMvtc.vlEncgMvtcPadr != null ? rgrEncgMvtc.vlEncgMvtcPadr : 0.00;

                  //console.log( 'nrRgrEncgMvtc: ' + rgrEncgMvtc.nrRgrEncgMvtc );
                  //console.log( 'vlEncgMvtcMin/Padr/Max: ' + rgrEncgMvtc.vlEncgMvtcMin + '/' + rgrEncgMvtc.vlEncgMvtcPadr + '/' + rgrEncgMvtc.vlEncgMvtcMax );
                }

                tipEncgMvtc.rgrEncgMvtcSelected = rgrEncgMvtc;

                let validatorsAttrForm = [];

                if( rgrEncgMvtc.vlEncgMvtcMin != null ){
                  validatorsAttrForm.push( Validators.min( rgrEncgMvtc.vlEncgMvtcMin ) );
                }

                if( rgrEncgMvtc.vlEncgMvtcMax != null ){
                  validatorsAttrForm.push( Validators.max( rgrEncgMvtc.vlEncgMvtcMax ) );
                }

                const vlAttrForm = new FormControl( {value: vlEncgMvtcPadr, disabled: true}, validatorsAttrForm );

                const nmAttrForm = ( 'tipEncg_' + tipEncgMvtc.sglTipEncg );
                _this.addAttributeInForm( nmAttrForm, vlAttrForm );
              } )
              .catch( error => {
                console.error( error );
              } );
          } );

          //Monitora a finalizacao de todas as tarefas.
          Promise.all( promises )
            .then( response => {
              _this._tipEncgMvtcGrCrctInvAcaoListDataSource.data = tipEncgMvtcList;
              _this.updateVlTtlEncg();
              _this._controlTipEncgMvtcList.loaded();

              resolve();
            } );
        } )
        .catch( error => {
          console.error( error );

          reject();
        } )
    } );

    return p;
  }

  private updateVlTtlCrctInv(): void{
    const qtdUndSelected = this.getQtdUndSelected();
    const prcUndSelected = this.getPrcUndSelected();

    const vlTtl = qtdUndSelected * prcUndSelected;

    this.setVlTtlCrctInvSelected( vlTtl );
    this.updateVlTtlMvtc();
  }

  private updateVlTtlEncg(): void{
    let vlTtl: number = 0.00;

    const vlTtlCrctInvSelected = this.getVlTtlCrctInvSelected();

    //console.log( 'vlTtlCrctInvSelected: ' + vlTtlCrctInvSelected );

    const tipEncgList = this._tipEncgMvtcGrCrctInvAcaoListDataSource != null ? this._tipEncgMvtcGrCrctInvAcaoListDataSource.data : null;
    const qtTipEncg = tipEncgList != null ? tipEncgList.length : 0;

    for( let indexTipEncg = 0; indexTipEncg < qtTipEncg; indexTipEncg++ ){
      const tipEncgIterate = tipEncgList[ indexTipEncg ];
      const isEditableTipEncgIterate = this.isEditableTipEncgSelected( tipEncgIterate );

      //Se campo está aberto para edição, obtem o valor informado pelo usuario, senao calcula o valor.
      let vlTipEncgIterate: number = 0;

      if( isEditableTipEncgIterate == true ){
        vlTipEncgIterate = this.getValueTipEncgSelected( tipEncgIterate );
      }
      else{
        const rgrEncgMvtcIterate = tipEncgIterate.rgrEncgMvtcSelected;
        const vlTxPercEncgMvtcPadrIterate = rgrEncgMvtcIterate != null ? rgrEncgMvtcIterate.vlTxPercEncgMvtcPadr : 0;
        const vlEncgMvtcPadrIterate       = rgrEncgMvtcIterate != null ? rgrEncgMvtcIterate.vlEncgMvtcPadr : 0;

        vlTipEncgIterate = vlTxPercEncgMvtcPadrIterate != null ? ( Math.floor( vlTtlCrctInvSelected * vlTxPercEncgMvtcPadrIterate ) / 100 ) : vlEncgMvtcPadrIterate;

        //console.log( 'vlTtlCrctInvSelected: ' + vlTtlCrctInvSelected + ', vlTxPercEncgMvtcPadrIterate: ' + vlTxPercEncgMvtcPadrIterate + '%' );

        this.setValueTipEncgSelected( tipEncgIterate, vlTipEncgIterate );
        //tipEncgIterate.vlTipEncgSelected = vlTipEncgIterate;
      }

      //Adiciona o valor do encargo ao valor total.
      vlTtl += vlTipEncgIterate;

      //console.log( 'tipEncgIterate - ' + tipEncgIterate.sglTipEncg + ': ' + vlTipEncgIterate );
    }

    this.setVlTtlEncgSelected( vlTtl );
    this.updateVlTtlMvtc();
  }

  private updateVlTtlMvtc(): void{
    const vlTtlCrctInv      = this.getVlTtlCrctInvSelected();
    const vlTtlEncg         = this.getVlTtlEncgSelected();
    const tipMvtcGrSelected = this.getTipMvtcGrCrctInvSelected();

    let vlTtlMvtc = 0;

    if( tipMvtcGrSelected != null ){
      vlTtlMvtc = vlTtlCrctInv + vlTtlEncg * tipMvtcGrSelected.vlMultEncgMvtc;
    }

    this.setVlTtlMvtcSelected( vlTtlMvtc );
  }

  public onChangeCrctInvSelected( event: Event ): void{
    const crctInv: CrctInv = this.getCrctInvSelected();

    if( crctInv != null && crctInv.cdInv != null ){
      this._controlCotc.isCanShow = true;
    }
    else{
      this._controlCotc.isCanShow = false;
    }

    const tipMvtcGrCrctInv: TipMvtcGrupoCrctInvAcao = this.getTipMvtcGrCrctInvSelected();

    const cdTipInv                = crctInv != null && crctInv.tipInv != null ? crctInv.tipInv.cdTipInv : null;
    const cdEspcAcao              = crctInv != null && crctInv.crctInvAcao != null && crctInv.crctInvAcao.espcAcao != null ? crctInv.crctInvAcao.espcAcao.cdEspcAcao : null;
    const sglTipMvtcGrCrctInvAcao = tipMvtcGrCrctInv != null ? tipMvtcGrCrctInv.sglTipMvtcGrCrctInvAcao : null;
    const dtRefSelected           = this.getDtRefSelected();

    //console.log( 'onChangeCrctInvSelected: ' + cdTipInv + '/' + cdEspcAcao + '/' + sglTipMvtcGrCrctInvAcao + '/' + dtRefSelected );

    this.loadTipEncgMvtcList( sglTipMvtcGrCrctInvAcao, cdTipInv, cdEspcAcao, dtRefSelected );
    this.onLoadCotc();
  }

  public onChangeDtRefSelected( event: Event ): void{
    const crctInv: CrctInv = this.getCrctInvSelected();
    const tipMvtcGrCrctInv: TipMvtcGrupoCrctInvAcao = this.getTipMvtcGrCrctInvSelected();

    const cdTipInv                = crctInv != null && crctInv.tipInv != null ? crctInv.tipInv.cdTipInv : null;
    const cdEspcAcao              = crctInv != null && crctInv.crctInvAcao != null && crctInv.crctInvAcao.espcAcao != null ? crctInv.crctInvAcao.espcAcao.cdEspcAcao : null;
    const sglTipMvtcGrCrctInvAcao = tipMvtcGrCrctInv != null ? tipMvtcGrCrctInv.sglTipMvtcGrCrctInvAcao : null;
    const dtRefSelected           = this.getDtRefSelected();

    //console.log( 'onChangeDtRefSelected: ' + cdTipInv + '/' + cdEspcAcao + '/' + sglTipMvtcGrCrctInvAcao + '/' + dtRefSelected );

    this.loadTipEncgMvtcList( sglTipMvtcGrCrctInvAcao, cdTipInv, cdEspcAcao, dtRefSelected );
    this.onLoadCotc();
  }

  public onChangeTipMvtcGrCrctInvAcaoSelected( event: Event ): void{
    this.updateVlTtlEncg();
  }

  public onChangeQtdUndSelected( event: Event ): void{
    this.updateVlTtlCrctInv();
    this.updateVlTtlEncg();
  }

  public onChangePrcUndSelected( event: Event ): void{
    this.updateVlTtlCrctInv();
    this.updateVlTtlEncg();
  }

  public onChangeVlEncgSelected( tipEncg: TipEncg, value: number ): void{
    //console.log( 'onChangeVlEncgSelected( ' + tipEncg.sglTipEncg + ', ' + value + ' )...' );

    this.setValueTipEncgSelected( tipEncg, value );
    //tipEncg.vlTipEncgSelected = value;

    this.updateVlTtlEncg();
  }

  public onChangeEditableTipEncg( tipEncg: TipEncg, isEditable: boolean ): void{
    //console.log( 'onChangeEditableTipEncg( ' + tipEncg.sglTipEncg + ', ' + isEditable + ' )...' );

    this.setEditableTipEncg( tipEncg, isEditable );
  }

  public onChangeTxObsSelected( event: Event ): void{
  }

  public onAddMvtcGrCrctInv(): void{
    const vlTaxaPercRentAaNegc = this.getVlTaxaPercRentAaNegcSelected();

    console.log( 'vlTaxaPercRentAaNegc: ' + vlTaxaPercRentAaNegc );

    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.msgConfirm = 'Confirma a inclusão da movimentação?';

    if( this._cotcAcao != null ){
      const prcUnd = this.getPrcUndSelected();

      if( prcUnd < this._cotcAcao.prcMin || prcUnd > this._cotcAcao.prcMax ){
        dataParm.msgConfirm += '<br /><br />ATENÇÃO: Foi informado um preço para o Ativo fora da faixa negociada no dia.'
      }
    }

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm == true ){
        _this.addMvtcGrCrctInv()
          .then( mvtcGrCrctInvList => {
            window.alert( 'Movimentação incluída com sucesso.' );

            const qtMvtc = mvtcGrCrctInvList != null ? mvtcGrCrctInvList.length : 0;

            console.log( 'qtMvtc: ' + qtMvtc );

            for( let indexMvtc = 0; indexMvtc < qtMvtc; indexMvtc++ ){
              _this._mvtGrCrctInvAcaoAddedList.push( mvtcGrCrctInvList [ indexMvtc ] );
            }
          } )
          .catch( error => {
            window.alert( 'Ocorreu um erro na inclusão da movimentação: ' + error.codErr + ' - ' + error.msgErr );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onAddMvtcGrCrctInv - backdropClick' );
    });
  }

  private addMvtcGrCrctInv(): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    const dtRef           = this.getDtRefSelected();
    const sglTipMvtc      = this.getSglTipMvtcGrCrctInvSelected();
    const cdGrCrctInvAcao = this.getCdGrCrctInvAcaoSelected();
    const cdCrctInv       = this.getCdCrctInvSelected();
    const qtdUnd          = this.getQtdUndSelected();
    const prcUnd          = this.getPrcUndSelected();
    const vlTaxaPercRentAaNegc = this.getVlTaxaPercRentAaNegcSelected();
    const encgMvtcList    = this.getEncgMvtcGrCrctInvAcaoList();
    const vlTtlEncg       = this.getVlTtlEncgSelected();
    const txObsMvtc       = this.getTxObsSelected();

    const p = this.mvtcGrCrctInvService.addWithMoedaRealAndTipEstMvtcAceita( cdGrCrctInvAcao, dtRef, sglTipMvtc, cdCrctInv, qtdUnd, prcUnd, encgMvtcList, vlTtlEncg, txObsMvtc, vlTaxaPercRentAaNegc );

    return p;
  }

  public closeDialog(){
    this.dialogRef.close( this._mvtGrCrctInvAcaoAddedList );
  }
}

import { Component                      } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router         } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';

import { MatDialog                      } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginRecoverComponent } from '../login-recover.component';

import { AplicationStateService } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '@services/authentication/authentication.service';
import { UsrService             } from '@services/usr/usr.service';

@Component({
  selector: 'app-login-recover-mobile',
  templateUrl: '../desktop/login-recover-desktop.component.html',
  styleUrls: ['./login-recover-mobile.component.css']
})
export class LoginRecoverMobileComponent extends LoginRecoverComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected usrService            : UsrService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, aplicationStateService, authenticationService, snackBar, usrService );
  }
}

import { ControlShow } from '@models/control/controlShow';

/**
 * Controle da apresentacao (show) de uma coluna da lista de itens de grupo.
 */
export class ControlShowColumnItemGr extends ControlShow{
  public constructor( nm: string, nmDisplayable: string, isAllowedShow: boolean, isAllowedChange: boolean ){
    super( nm );

    super.nmDisplayable   = nmDisplayable;
    super.isAllowedShow   = isAllowedShow;
    super.isAllowedChange = isAllowedChange;
  }
}

import { InfCrctInvInterface 	} from 'src/app/_models/infCrctInv/infCrctInv';
import { ControlLifeCycleCrctInv } from './controlLifeCycleCrctInv';

/**
 * Controla diversos ControlLifeCycleCrctInv.
 */
export class ManagerLifeCycleCrctInv{
	private controlCrctInvList: ControlLifeCycleCrctInv[];

	constructor(){
		this.controlCrctInvList = [];
	}

	/**
	 * Retorna uma lista de Control's que estejam ativos (isEnabled = true).
	 */
	public getControlList(): ControlLifeCycleCrctInv[]{
		const controlList = [];

		if( this.controlCrctInvList != null ){
			this.controlCrctInvList.forEach( controlIterate => {
				if( controlIterate.isControlEnabled() === true ){
					controlList.push( controlIterate );
				}
			} );
		}

		return controlList;
	}

	/**
	 * Retorna a quantidade total de Controls, independente de estarem ou não ativados.
	 */
	public getQtControl(): number{
		return this.controlCrctInvList.length;
	}

	/**
	 * Retorna a quantidade total de Controls, independente de estarem ou não ativados.
	 */
	public getQtControlEnabled(): number{
		let qtControl: number = 0;

		const qtControls = this.getQtControl();

		for( let indexControl = 0; indexControl < qtControls; indexControl++ ){
			const controlIterate = this.controlCrctInvList[ indexControl ];

			if( controlIterate.isControlEnabled() === true ){
				qtControl++;
			}
		}

		return qtControl;
	}

	/**
	 * Verifica se o Control especificado é administrado por este Manager. Se existir, o 
	 * Control deverá estar com a propriedade isEnabled igual a true.
	 * @param control 
	 */
	public isExistControl( control: ControlLifeCycleCrctInv ): boolean{
		let isExist = false;

		const qtControls = this.getQtControl();

		for( let indexControl = 0; indexControl < qtControls; indexControl++ ){
			const controlIterate = this.controlCrctInvList[ indexControl ];

			if( controlIterate.isControlEnabled() === true && control.isEqual( controlIterate ) ){
				isExist = true;
				break;
			}
		}

		return isExist;
	}

	public getMaxQtInfCrctInv(): number{
		let qtMax = 0;

		this.controlCrctInvList.forEach( control => {
			const qtInfCrctInvIterate = control.getQtInfCrctInv();

			if( qtInfCrctInvIterate > qtMax ){
				qtMax = qtInfCrctInvIterate;
			}
		} );

		return qtMax;
	}

	public addControl( control: ControlLifeCycleCrctInv ){
		this.controlCrctInvList.push( control );
	}

	public removeControl( control: ControlLifeCycleCrctInv ){
		//Obtem a quantidade de controls.
		const qtControl = this.controlCrctInvList.length;

		for( let indexControl = (qtControl-1); indexControl >= 0; indexControl-- ){
			const controlIterate = this.controlCrctInvList[ indexControl ];

			if( controlIterate.isEqual( control ) === true ){
				this.controlCrctInvList.splice( indexControl, 1 );
				break;
			}
		}
	}

	public removeAllControl(): void{
		this.controlCrctInvList = [];
	}

	/**
	 * Ativa todos os Control's.
	 */
	public enableAllControl(): void{
		this.setEnableAllControl( true );
	}

	/**
	 * Ativa o Control.
	 * @param control 
	 */
	public enableControl( control: ControlLifeCycleCrctInv ): void{
		this.setEnableControl( control, true );
	}

	/**
	 * Desativa todos os Control's.
	 */
	public disableAllControl(): void{
		this.setEnableAllControl( false );
	}

	/**
	 * Desativa o Control.
	 * @param control 
	 */
	public disableControl( control: ControlLifeCycleCrctInv ): void{
		this.setEnableControl( control, false );
	}

	/**
	 * 
	 * @param control 
	 */
	private setEnableControl( control: ControlLifeCycleCrctInv, isState: boolean ): void{
		const qtControl = this.getQtControl();

		for( let indexControl = 0; indexControl < qtControl; indexControl++ ){
			const controlIterate: ControlLifeCycleCrctInv = this.controlCrctInvList[ indexControl ];

			if( control.isEqual( controlIterate ) ){
				controlIterate.setEnable( isState );
				break;
			}
		}
	}

	/**
	 *  
	 */
	private setEnableAllControl( isState: boolean ): void{
		const qtControl = this.getQtControl();

		for( let indexControl = 0; indexControl < qtControl; indexControl++ ){
			const controlIterate: ControlLifeCycleCrctInv = this.controlCrctInvList[ indexControl ];

			controlIterate.setEnable( isState );
		}
	}

	/**
	 * Adiciona uma copia da informacao especificada em todos os controls.
	 * @param infCrctInv 
	 */
	public addInfCrctInvAllControl( infCrctInv: InfCrctInvInterface< any > ){
		this.controlCrctInvList.forEach( control => {
			const infCrctInvCopy = infCrctInv.copy();
			infCrctInvCopy.setCrctInv( control.getCrctInv() );

			control.addInfCrctInv( infCrctInvCopy );

			//console.log( 'Adicionada a informacao "' + infCrctInvCopy.getNmInfCrctInvViewed() + '" no ticker "' + control.getCrctInv().nmInv + '".' );
		} );
	}

	public getInfCrctInvList(): InfCrctInvInterface< any >[]{
		let infCrctInvList = [];

		if( this.getQtControl() > 0 ){
			const anyControl = this.controlCrctInvList[0];

			infCrctInvList = anyControl.getInfCrctInvList();
		}
		else{
			infCrctInvList = null;
		}

		return infCrctInvList;
	}

	public setDtFromValues( dt: Date ): void{
		this.controlCrctInvList.forEach( control => {
			control.setDtFromValues( dt );
		} );
	}

	public setDtEndValues( dt: Date ): void{
		this.controlCrctInvList.forEach( control => {
			control.setDtEndValues( dt );
		} );
	}

	public setQtLimitValues( qtLimit: number ): void{
		this.controlCrctInvList.forEach( control => {
			control.setQtLimitValues( qtLimit );
		} );
	}

	public resetAllPointerToMin(): void{
		this.controlCrctInvList.forEach( control => {
			control.resetAllPointerToMin();
		} );
	}

	public resetAllPointerToMax(): void{
		this.controlCrctInvList.forEach( control => {
			control.resetAllPointerToMax();
		} );
	}

	/**
	 * Itera por todos os controls ativos, retornando a menor data.
	 */
	public getMinDateOfAllPointers(): Date{
		let minDate: Date = null;

		this.controlCrctInvList.forEach( control => {
			if( control.isControlEnabled() === true ){
				const dtIterate = control.getMinDateOfAllPointers();

				//Compara as datas.
				if( ( dtIterate != null ) && ( ( minDate === null ) || ( dtIterate.getTime() < minDate.getTime() ) ) ){
					minDate = dtIterate;
				}
			}
		} );

		return minDate;
	}

	/**
	 * Solicita uma atualizacao dos valores das informacoes de todos controls.
	 */
	public refreshValues( controlList?: ControlLifeCycleCrctInv[], infCrctInvList?: InfCrctInvInterface< any >[] ): Promise< any >{
		const _this = this;

		return new Promise( function( resolve, reject ){
			try{
				let promises  : Promise< any >[] = [];

				//Solicita a atualizacao dos valores de cada umas das informacoes.
				_this.controlCrctInvList.forEach( ( control, indexControl ) => {
					const qtControlSpecific = controlList && controlList != null ? controlList.length : 0;

					let isRefresh = ( qtControlSpecific === 0 );

					for( let indexControlSpecific = 0; indexControlSpecific < qtControlSpecific; indexControlSpecific++ ){
						const controlSpecificIterate = controlList[ indexControlSpecific ];

						if( control.isEqual( controlSpecificIterate ) ){
							isRefresh = true;
							break;
						}
					}

					if( isRefresh === true ){
						const promiseIterate = control.refreshValues( infCrctInvList );

						promises.push( promiseIterate );
					}
				} );

				//Aguarda a finalizacao de todas requisicoes.
				Promise.all( promises )
					.then( response => {
						resolve();
					} );
			}
			catch( error ){
				reject( error );
			}
		});
	}
}

import { Component, OnInit      } from '@angular/core';
import { Router                 } from '@angular/router';
import { DomSanitizer           } from '@angular/platform-browser';
import { MatToolbarModule       } from '@angular/material/toolbar';
import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { SessionService } from 'src/app/_services/session/session.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { HeaderComponent } from '../header.component';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header.mobile.component.html',
  styleUrls: ['./header.mobile.component.css']
})
export class HeaderMobileComponent extends HeaderComponent implements OnInit {
  constructor( protected sanitizer: DomSanitizer,
               aplicationStateService: AplicationStateService,
               public sessionService: SessionService,
               authenticationService: AuthenticationService ){
    super( sanitizer, aplicationStateService, sessionService, authenticationService );
  }

  ngOnInit(){
  }
}

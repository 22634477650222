import { Component, OnInit                    } from '@angular/core';
import { DomSanitizer                         } from '@angular/platform-browser';
import { ActivatedRoute, Router               } from '@angular/router';
import { FormBuilder, FormControl             } from '@angular/forms';

import { DatePipe, DecimalPipe                } from '@angular/common';

import { MatDialog                            } from '@angular/material/dialog';
import { MatTableDataSource                   } from '@angular/material/table';
import { MatSelectChange, MatSelect           } from '@angular/material/select';
import { MatOptionSelectionChange, MatOption  } from '@angular/material/core';

import { AbstractMainComponent                } from '../../abstract-main.component';

import { AplicationStateService               } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService                } from '../../../_services/authentication/authentication.service';
import { InvService                           } from '../../../_services/inv/inv.service';
import { DtqService                           } from '../../../_services/dtq/dtq.service';
import { PrmUsrService                        } from '../../../_services/prmUsr/prmUsr.service';

import { ShortNumberPipe                      } from '../../../_pipes/short-number/short-number.pipe';

import { Perdc, PerdcSelectionChange          } from '../../../_models/perdc';
import { DestaquesBySeq, Destaque             } from '../../../_models/dtq/dtq';
import { DestaqueControl                      } from './models/dtqControl';
import { DestaqueControlPanel } from './models/dtqControlPanel';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class DestaquesListComponent extends AbstractMainComponent{
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_QT_MAX_DTQ         = 'DtqList_qtMaxDtq';
  private PRM_USR_DT_REF             = 'DtqList_dtRef';
  private PRM_USR_VOL_NEGC_MEDIO_MIN = 'DtqList_volNegcMedioMin';
  private PRM_USR_SGL_PERDC_LIST     = 'DtqList_sglPerdcList';

  private QT_DAYS_REF = 5;

  public _dtRefList: Date[];
  public _qtMaxDtqList: number[];

  public _isShowProgressBarMain: boolean = false;
  public _valueProgressBarMain : number = 0;

  //Lista dos paineis a serem apresentados ao usuario.
  public _dtqControlPanels: DestaqueControlPanel[];

  private _perdcDiaria = new Perdc( 'DIARIA', 'Diária'    );
  private _perdcNaSmn  = new Perdc( 'NA_SMN', 'Na Semana' );
  private _perdcMensal = new Perdc( 'MENSAL', 'Mensal'    );

  public _perdcList: Perdc[] = [ this._perdcDiaria, this._perdcNaSmn, this._perdcMensal ];

  public _dtqControls = { maioresVarPercPrcFechDIARIA: new DestaqueControl( 'maioresVarPercPrcFechDIARIA', 'listDtqMaioresVarPrcFechDiarioBySeq', this._perdcDiaria ),
                          maioresVarPercPrcFechNA_SMN: new DestaqueControl( 'maioresVarPercPrcFechNA_SMN', 'listDtqMaioresVarPrcFechNaSmnBySeq' , this._perdcNaSmn  ),
                          maioresVarPercPrcFechMENSAL: new DestaqueControl( 'maioresVarPercPrcFechMENSAL', 'listDtqMaioresVarPrcFechMensalBySeq', this._perdcMensal ),

                          menoresVarPercPrcFechDIARIA: new DestaqueControl( 'menoresVarPercPrcFechDIARIA', 'listDtqMenoresVarPrcFechDiarioBySeq', this._perdcDiaria ),
                          menoresVarPercPrcFechNA_SMN: new DestaqueControl( 'menoresVarPercPrcFechNA_SMN', 'listDtqMenoresVarPrcFechNaSmnBySeq' , this._perdcNaSmn  ),
                          menoresVarPercPrcFechMENSAL: new DestaqueControl( 'menoresVarPercPrcFechMENSAL', 'listDtqMenoresVarPrcFechMensalBySeq', this._perdcMensal ),

                          maioresVarPercVolNegcDIARIA: new DestaqueControl( 'maioresVarPercVolNegcDIARIA', 'listDtqMaioresVarVolNegcDiarioBySeq', this._perdcDiaria )  };

  public _form;

  public _sglPerdcListSelectedFC: FormControl = new FormControl();

  constructor( protected router                : Router,
               protected activatedRoute        : ActivatedRoute,
               protected sanitizer             : DomSanitizer,
               protected formBuilder           : FormBuilder,
               protected dialog                : MatDialog,
               protected datepipe              : DatePipe              ,
               protected decimalPipe           : DecimalPipe           ,
               protected shortNumberPipe       : ShortNumberPipe       ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected dtqService            : DtqService,
               protected invService            : InvService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    this._isShowProgressBarMain = true;

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitGeneral = this.initGeneral();
    promises.push( pInitGeneral );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    //Busca lista das datas de referencia dos Destaques.
    const pInitDtRefList = this.initDtRefList();
    promises.push( pInitDtRefList );

    //
    const pInitFilters = this.initFilters();
    promises.push( pInitFilters );

    const pInitPanels = this.initPanels();
    promises.push( pInitPanels );

    this.monitoryInit( promises );
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    const _this = this;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          _this._valueProgressBarMain = promisesFinish / qtPromises * 100;
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        _this._isShowProgressBarMain = false;
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._form = _this.formBuilder.group(
        { dtRefDtqSelected    : null,
          qtMaxDtqSelected    : 5,
          dtRefSelected       : null,
          volNegcMinSelected  : 0,
          sglPerdcListSelected: new FormControl()
        }
      );

      resolve();
    } );

    return p;
  }

  private initGeneral(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const dtNow = new Date();
      const dayOfWeek = dtNow.getDay();

      //console.log( 'dayOfWeek: ' + dayOfWeek );

      if( dayOfWeek >= 1 && dayOfWeek <= 2 ){
        const dtqControlsProp = Object.keys( _this._dtqControls );

        dtqControlsProp.forEach( dtqControlProp => {
          //console.log( 'dtqControlProp: ' + dtqControlProp );

          if( dtqControlProp.includes( 'NaSmn' ) ){
            const dtqControl: DestaqueControl = _this._dtqControls[ dtqControlProp ];

            dtqControl.isAllowedShowDtq = false;
          }
        } );
      }

      resolve();
    } );

    return p;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const volNegcMedioMinOfPrmUsr: number   = _this.prmUsrService.getValueInt( _this.PRM_USR_VOL_NEGC_MEDIO_MIN );
      const dtRefOfPrmUsr          : Date     = _this.prmUsrService.getValueDate( _this.PRM_USR_DT_REF );
      const qtMaxDtqOfPrmUsr       : number   = _this.prmUsrService.getValueInt( _this.PRM_USR_QT_MAX_DTQ );
      const sglPerdcListOfPrmUsr   : string[] = _this.prmUsrService.getValueStringList( _this.PRM_USR_SGL_PERDC_LIST );

      if( volNegcMedioMinOfPrmUsr != null ){
        _this.setVolNegcMinSelected( volNegcMedioMinOfPrmUsr );
      }

      if( dtRefOfPrmUsr != null ){
        //console.log( 'dtRefOfPrmUsr: ' + dtRefOfPrmUsr );

        _this.setDtRefSelected( dtRefOfPrmUsr );
      }

      if( qtMaxDtqOfPrmUsr != null ){
        _this.setQtMaxDtqSelected( qtMaxDtqOfPrmUsr );
      }

      if( sglPerdcListOfPrmUsr != null && sglPerdcListOfPrmUsr.length > 0 ){
        const sglPerdcSelecteds = [];

        sglPerdcListOfPrmUsr.forEach( sglPerdc => {
          const sglPerdcSelected = sglPerdc.trim();

          sglPerdcSelecteds.push( sglPerdcSelected );

          _this.enableDtqControlsOfPerdc( sglPerdcSelected );
        } );

        _this._sglPerdcListSelectedFC.setValue( sglPerdcSelecteds );
      }

      resolve();
    } );

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initDtRefList(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._dtRefList = [];

      //Obtem a lista das ultimas datas de referencia.
      _this.dtqService.listDtRef( null, null )
        .then( dtqList => {
          const qtDtq = dtqList != null ? dtqList.length : 0;

          for( let i = 0; i < qtDtq; i++ ){
            const dtq = dtqList[i];

            _this._dtRefList.push( new Date( dtq.dtRef ) );
          }

          if( _this.getDtRefSelected() === null ){
            const lastDtRef = _this._dtRefList != null ? _this._dtRefList[0] : new Date();
            _this.setDtRefSelected( lastDtRef );
          }

          //Manda atualizar todos Destaques que estejam com o isShowDtq igual a true.
          _this.refreshAll();

          resolve();
        } );
    } );

    return p;
  }

  private initFilters(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._qtMaxDtqList = [];

      for( let i = 0; i < 8; i++ ){
        _this._qtMaxDtqList.push( i + 3 );
      }

      resolve();
    } );

    return p;
  }

  private initPanels(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._dtqControlPanels = [];

      //Maiores altas percentuais na variação do preço de fechamento.
      const controlPanelAltasPreco = new DestaqueControlPanel();
      controlPanelAltasPreco.nm    = 'maioresVarPercPrcFech';
      controlPanelAltasPreco.title = 'Maiores Altas Percentuais de Preço';
      controlPanelAltasPreco.dtqControlList = [ new DestaqueControl( 'maioresVarPercPrcFechDIARIA', 'listDtqMaioresVarPrcFechDiarioBySeq', _this._perdcDiaria ),
                                                new DestaqueControl( 'maioresVarPercPrcFechNA_SMN', 'listDtqMaioresVarPrcFechNaSmnBySeq' , _this._perdcNaSmn  ),
                                                new DestaqueControl( 'maioresVarPercPrcFechMENSAL', 'listDtqMaioresVarPrcFechMensalBySeq', _this._perdcMensal )  ];

      _this._dtqControlPanels.push( controlPanelAltasPreco );


      //Maiores baixas percentuais na variação do preço de fechamento.
      const controlPanelBaixasPreco = new DestaqueControlPanel();
      controlPanelBaixasPreco.nm    = 'menoresVarPercPrcFech';
      controlPanelBaixasPreco.title = 'Maiores Baixas Percentuais de Preço';
      controlPanelBaixasPreco.dtqControlList = [ new DestaqueControl( 'menoresVarPercPrcFechDIARIA', 'listDtqMenoresVarPrcFechDiarioBySeq', _this._perdcDiaria ),
                                                 new DestaqueControl( 'menoresVarPercPrcFechNA_SMN', 'listDtqMenoresVarPrcFechNaSmnBySeq' , _this._perdcNaSmn  ),
                                                 new DestaqueControl( 'menoresVarPercPrcFechMENSAL', 'listDtqMenoresVarPrcFechMensalBySeq', _this._perdcMensal )  ];

      _this._dtqControlPanels.push( controlPanelBaixasPreco );

      //Maiores altas percentuais na variação do volume de negocios.
      const controlPanelAltasVolNegc = new DestaqueControlPanel();
      controlPanelAltasVolNegc.nm    = 'maioresVarPercVolNegc';
      controlPanelAltasVolNegc.title = 'Maiores Altas Percentuais no Volume de Ações Negociadas';
      controlPanelAltasVolNegc.dtqControlList = [ new DestaqueControl( 'maioresVarPercVolNegcDIARIA', 'listDtqMaioresVarVolNegcDiarioBySeq', _this._perdcDiaria )  ];

      _this._dtqControlPanels.push( controlPanelAltasVolNegc );


      resolve();
    } );

    return p;
  }

  private getQtMaxDtqSelected(){
    return this._form.controls[ 'qtMaxDtqSelected' ].value;
  }

  private setQtMaxDtqSelected( qtMaxDtqSelected: number ){
    this._form.patchValue( { qtMaxDtqSelected: qtMaxDtqSelected } );
  }

  private getDtRefSelected(){
    return this._form.controls[ 'dtRefSelected' ].value;
  }

  private setDtRefSelected( dtRefSelected: Date ){
    this._form.patchValue( { dtRefSelected: dtRefSelected } );
  }

  private getVolNegcMinSelected(){
    return this._form.controls[ 'volNegcMinSelected' ].value;
  }

  private setVolNegcMinSelected( volNegcMinSelected: number ){
    this._form.patchValue( { volNegcMinSelected: volNegcMinSelected } );
  }

  private getSglPerdcListSelected(): string[]{
    return this._form.controls[ 'sglPerdcListSelected' ].value;
  }

  private setSglPerdcListSelected( sglPerdcListSelected: string[] ){
    //console.log( 'setSglPerdcListSelected()...' );

    const fc: FormControl = new FormControl( sglPerdcListSelected );

    let isWait: boolean = true;

    {
      if( this._form && this._form != null ){
        this._form.patchValue( { 'sglPerdcListSelected': fc } );
        isWait = false;
      }
    }
    while( isWait );

    //console.log( 'setSglPerdcListSelected().' );
  }

  onSubmit(){
    console.log( 'onSubmit()...' );

    console.log( 'onSubmit().' );
  }

  private refreshAll(): void{
    const _this = this;

    _this._dtqControlPanels.forEach( dtqControlPanel => {
      dtqControlPanel.dtqControlList.forEach( dtqControl => {
        if( dtqControl.isShowDtq === true ){
          dtqControl.isShowProgressBar = true;
          const p = _this.refreshDtq( dtqControl );

          p.finally( function(){ 
            dtqControl.isShowProgressBar = false;
          });
        }
      } );
    } );
  }

  private refreshDtqMaioresVarPercPrcFechDiarioList(): Promise< any >{
    return this.refreshDtq( this._dtqControls.maioresVarPercPrcFechDIARIA );
  }

  private refreshDtqMenoresVarPercPrcFechDiarioList(): Promise< any >{
    return this.refreshDtq( this._dtqControls.menoresVarPercPrcFechDIARIA );
  }

  private refreshDtqMaioresVarPercVolNegcDiarioList(): Promise< any >{
    return this.refreshDtq( this._dtqControls.maioresVarPercVolNegcDIARIA );
  }

  private refreshDtq( dtqControl: DestaqueControl ): Promise< any >{
    //console.log( 'refreshDtq()...' );
    //console.log( 'fcn: ' + dtqControl.nmFcn );

    const _this     = this;
    const nameFcn   = dtqControl.nmFcn;
    const _datePipe = new DatePipe( 'pt-BR' );

    //Obtem as quantidades maximas de data e sequencial que sera possivel apresentar ao usuario.
    const qtDtRef     = this._dtRefList != null ? this._dtRefList.length : 0;
    const qtMaxSeqDtq = this.getQtMaxDtqSelected();
    const dtRefFrom   = this.getDtRefOffSet( this.QT_DAYS_REF );
    const timeFrom    = dtRefFrom != null ? dtRefFrom.getTime() : null;
    const dtRefEnd    = this.getDtRefSelected();
    const timeEnd     = dtRefEnd != null ? dtRefEnd.getTime() : null;
    const volNegcMin  = this.getVolNegcMinSelected();

    //Inicializa as variaveis.
    dtqControl.dataSource = new MatTableDataSource< DestaquesBySeq >();

    const p = new Promise( function( resolve, reject ){
      if( _this._dtRefList != null ){
        //Obtem a lista de datas a serem apresentadas.
        const dtRefRangeList = _this.getDtRefListOfRange( dtRefFrom, dtRefEnd );
        const qtDtRefRange = dtRefRangeList != null ? dtRefRangeList.length : 0;

        //console.log( 'dtRefRangeList: ' + dtRefRangeList );
        //console.log( 'qtMaxSeqDtq...: ' + qtMaxSeqDtq );
        //console.log( 'volNegcMin....: ' + volNegcMin );

        if( qtDtRefRange > 0 ){
        //Obtem os destaques diario para a data especificada.
        _this.dtqService[ nameFcn ]( dtRefRangeList, qtMaxSeqDtq, volNegcMin )
          .then( dtqBySeqList => {
            const qtDtq = dtqBySeqList != null ? dtqBySeqList.length : 0;
            dtqControl.datesDisplayedColumns = [];
            dtqControl.displayedColumns      = [];

            const qtDtRef = _this._dtRefList != null ? _this._dtRefList.length : 0;

            for( let indexDtRef = 0; indexDtRef < qtDtRef; indexDtRef++ ){
              const dtRefIterate = _this._dtRefList[ indexDtRef ];

              if( dtRefIterate.getTime() <= timeEnd ){
                if( dtRefIterate.getTime() >= timeFrom ){
                  const dtRefStr = _datePipe.transform( dtRefIterate, 'dd/MM/yyyy', '+0000' );

                  dtqControl.datesDisplayedColumns.push( dtRefStr );
                  dtqControl.displayedColumns.push( dtRefStr );
                }
                else{
                  break;
                }
              }
            }

            dtqControl.displayedColumns.unshift( 'nrSeqDtq' );

            const qtDatesDisplayedColumns = dtqControl.datesDisplayedColumns.length;
            //console.log( 'qtDatesDisplayedColumns: ' + qtDatesDisplayedColumns );

            dtqControl.dataSource.data  = dtqBySeqList;
            dtqControl.isDtqLoaded = qtDatesDisplayedColumns > 0;

            resolve();
          } )
          .catch( error => {
            reject( error );
          } );
        }
      }
      else{
        resolve();
      }
    } );

    return p;
  }

  private getTooltipOfDtq( dtq: Destaque ){
    const nrSeqDtq     : number = dtq.nrSeqDtq;
    const dtIniPerdStr : string = this.datepipe.transform( dtq.dtIniPerd, 'dd/MM/yyyy', '+0000' );
    const dtFimPerdStr : string = this.datepipe.transform( dtq.dtFimPerd, 'dd/MM/yyyy', '+0000' );
    const volNegcMedStr: string = this.shortNumberPipe.transform( dtq.crctInv.crctInvAcao.volNegcMed, '1.0-0' );
    const dtCotcStr    : string = this.datepipe.transform( dtq.crctInv.crctInvAcao.cotcAcao.dtCotc, 'dd/MM/yyyy', '+0000' );
    const prcAbertStr  : string = this.decimalPipe.transform( dtq.crctInv.crctInvAcao.cotcAcao.prcAbert, '1.2-2' );
    const prcMinStr    : string = this.decimalPipe.transform( dtq.crctInv.crctInvAcao.cotcAcao.prcMin, '1.2-2' );
    const prcMaxStr    : string = this.decimalPipe.transform( dtq.crctInv.crctInvAcao.cotcAcao.prcMax, '1.2-2' );
    const prcFechStr   : string = this.decimalPipe.transform( dtq.crctInv.crctInvAcao.cotcAcao.prcFech, '1.2-2' );
    const volNegcStr   : string = this.shortNumberPipe.transform( dtq.crctInv.crctInvAcao.cotcAcao.volNegc, '1.0-0' );
    const varPrcFech   : number = dtq.crctInv.crctInvAcao.cotcAcao.varPrcFech;
    const varPrcFechStr: string = this.decimalPipe.transform( varPrcFech, '1.2-2' );

    let colorOfVarPrcFechViewed: string = varPrcFech != null && varPrcFech >= 0 ? 'blue' : 'red';

    let tooltipStr = '<div>' + '\n' +
                     '  Posição no ranking geral: ' + nrSeqDtq + 'º' + '\n' +
                     '  Período compreendido de ' + dtIniPerdStr + ' a ' + dtFimPerdStr + '\n' +
                     '  Volume de negócios médio nos últimos 3 meses: ' + volNegcMedStr + '\n' +
                     '</div>';

    tooltipStr += '<div>' + '\n' +
                  '&nbsp;&nbsp;&nbsp; Preço Abert.: '    + prcAbertStr + '<br/>' + '\n' +
                  '  Preço Mín./Máx.: ' + prcMinStr   + ' - ' + prcMaxStr + '<br/>' + '\n' +
                  '  Preço Fech.: '     + prcFechStr  + '<br/>' + '\n' +
                  '  Volume de Neg.: '  + volNegcStr  + '<br/>' + '\n' +
                  '  Variação: <span style="color:' + colorOfVarPrcFechViewed + '">' + varPrcFechStr + '</span> %' + '\n' +
                  '</div>';

    return tooltipStr;
  }

  private getDtRefOffSet( offset: number ): Date{
    const dtRef = this.getDtRefSelected();
    let dtRefOffSet = dtRef;

    if( dtRef != null ){
      const qtDtRef = this._dtRefList != null ? this._dtRefList.length : 0;

      for( let iDtRef = 0; iDtRef < qtDtRef; iDtRef++ ){
        const dtRefIterate = this._dtRefList[ iDtRef ];

        if( dtRefIterate.getTime() === dtRef.getTime() ){
          dtRefOffSet = this._dtRefList[ iDtRef + offset - 1 ];
          break;
        }
      }
    }

    return dtRefOffSet;
  }

  private getDtRefListOfRange( dtRefFrom: Date, dtRefEnd: Date ): Date[]{
    const dtRefRangeList = [];

    const qtDtRef = this._dtRefList != null ? this._dtRefList.length : 0;

    const timeRefFrom = dtRefFrom != null ? dtRefFrom.getTime() : null;
    const timeRefEnd  = dtRefEnd  != null ? dtRefEnd.getTime()  : null;

    for( let iDtRef = 0; iDtRef < qtDtRef; iDtRef++ ){
      const dtRefIterate = this._dtRefList[ iDtRef ];
      const timeRefIterate = dtRefIterate.getTime();

      if( timeRefIterate <= timeRefEnd && timeRefIterate >= timeRefFrom ){
        dtRefRangeList.push( dtRefIterate );
      }
    }

    return dtRefRangeList;
  }

  public onChangeQtMaxDtqSelected( event: MatSelectChange ){
    const qtMaxDtqSelected = this.getQtMaxDtqSelected();
    this.prmUsrService.setValueInt( this.PRM_USR_QT_MAX_DTQ, qtMaxDtqSelected );

    this.refreshAll();
  }

  public onChangeDtRefSelected( event: MatSelectChange ){
    const dtRefSelected = this.getDtRefSelected();
    this.prmUsrService.setValueDate( this.PRM_USR_DT_REF, dtRefSelected );

    this.refreshAll();
  }

  public onChangeVolNegcMinSelected( event: MatSelectChange ){
    const volNegcMedioMinSelected = this.getVolNegcMinSelected();
    this.prmUsrService.setValueInt( this.PRM_USR_VOL_NEGC_MEDIO_MIN, volNegcMedioMinSelected );

    this.refreshAll();
  }

  public onChangePerdcSelected( event: MatOptionSelectionChange ): void{
    const source: MatOption = event.source;
    const sglPerdc   = source.value;
    const isSelected = source.selected;

    this.selectionDtqControlsOfPerdc( sglPerdc, isSelected );

    const sglPerdcSelecteds: string[] = [];

    if( isSelected === true ){
      sglPerdcSelecteds.push( sglPerdc );
    }

    const fc = this._sglPerdcListSelectedFC;
    const valuesFc: string[] = fc != null && fc.value != null ? fc.value : [];

    valuesFc.forEach( sglPerdcSelected => {
      //console.log( 'sglPerdcSelected: ' + sglPerdcSelected );

      if( sglPerdcSelected !== sglPerdc ){
        sglPerdcSelecteds.push( sglPerdcSelected );
      }
    } );

    this.prmUsrService.setValueStringList( this.PRM_USR_SGL_PERDC_LIST, sglPerdcSelecteds );
  }

  private enableDtqControlsOfPerdc( sglPerdc: string ): void{
    this.selectionDtqControlsOfPerdc( sglPerdc, true );
  }

  private disableDtqControlsOfPerdc( sglPerdc: string ): void{
    this.selectionDtqControlsOfPerdc( sglPerdc, false );
  }

  private selectionDtqControlsOfPerdc( sglPerdc: string, isSelected: boolean ): void{
    //Procura os dtqControl que sejam da periodicidade selecionada.
    if( this._dtqControlPanels != null ){
      this._dtqControlPanels.forEach( dtqControlPanel => {
        const dtqControlList = dtqControlPanel.dtqControlList;

        dtqControlList.forEach( dtqControl => {
          if( dtqControl.perdc.sglPerdc === sglPerdc ){
            dtqControl.isAllowedShowDtq = isSelected;
          }
        } );
      } );
    }
  }

  public onOpenedPanel( nmControlSufix: string ): void{
    //console.log( 'onOpenedPanel( ' + nmControlSufix + ' )...' );

    this._perdcList.forEach( perdc => {
      if( this._dtqControlPanels != null ){
        this._dtqControlPanels.forEach( dtqControlPanel => {
          if( dtqControlPanel.nm === nmControlSufix ){
            dtqControlPanel.dtqControlList.forEach( dtqControl => {
              if( dtqControl.perdc.sglPerdc === perdc.sglPerdc ){
                if( dtqControl.isDtqLoaded === false ){
                  dtqControl.isShowProgressBar = true;

                  this.refreshDtq( dtqControl )
                    .then( response => {
                      dtqControl.isShowProgressBar = false;
                    } );
                }
                else{
                  //console.log( 'isDtqLoaded is true' );
                }

                dtqControl.isShowDtq = true;
              }
            } );
          }
        } );
      }
    } );
  }

  private goToDetailSegmEcnm( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ){
    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm, 'cdSubSetorEcnm': cdSubSetorEcnm, 'cdSegmEcnm': cdSegmEcnm } );
  }

  private goToDetailSetorAtvdd( cdSetorAtvdd: number ){
    this.goToPage( '/setor-detail', { 'cdSetorAtvdd': cdSetorAtvdd } );
  }

  public detailCrctInv( cdInv: number ){
    this.goToPage( '/inv-detail', { 'cdInv': cdInv }, true );
  }
}
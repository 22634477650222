import { Injectable                            } from '@angular/core';
import { HttpClient                            } from '@angular/common/http';
import { HttpBasicService                      } from '../http-basic.service';
import { SessionService                        } from '../session/session.service';
import { User } from 'src/app/_models/user';
import { GrupoCrctInvAcao, ItemGrupoCrctInvAcao, MovimentacaoGrupoCrctInvAcao, TipMvtcGrupoCrctInvAcao, RgrEncgMvtc, TipEncg, EncgMvtcGrCrctInvAcao } from 'src/app/_models/grCrctInv/grCrctInv';
import { CrctInv } from 'src/app/_models/inv';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MvtcGrCrctInvService extends HttpBasicService< MovimentacaoGrupoCrctInvAcao, MovimentacaoGrupoCrctInvAcao >{
  constructor( protected http       : HttpClient    ,
               public sessionService: SessionService,
               protected datePipe   : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Adiciona nova movimentacao numa Carteira, com:
   *  - Moeda igual a 1-REAL.
   *  - Estado da movimentação igual a ACEITA.
   */
  public addWithMoedaRealAndTipEstMvtcAceita( cdGrCrctInvAcao: number, dtRefMvtc: Date, sglTipMvtcGrCrctInvAcao: string, 
                                              cdInv: number, qtdUnd: number, prcUnd: number, 
                                              encgMvtcList: EncgMvtcGrCrctInvAcao[], vlTtlEncg: number, txObsMvtc: string, vlTaxaPercRentAaNegc: number ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    const crctInv = new CrctInv();
    crctInv.cdInv = cdInv;

    return this.addWithMoedaRealAndTipEstMvtcAceitaAndCrctInv( cdGrCrctInvAcao, dtRefMvtc, sglTipMvtcGrCrctInvAcao, crctInv, qtdUnd, prcUnd, encgMvtcList, vlTtlEncg, txObsMvtc, vlTaxaPercRentAaNegc );
  }

  /**
   * Adiciona nova movimentacao numa Carteira, com:
   *  - Moeda igual a 1-REAL.
   *  - Estado da movimentação igual a ACEITA.
   */
  public addWithMoedaRealAndTipEstMvtcAceitaAndCrctInv( cdGrCrctInvAcao: number, dtRefMvtc: Date, sglTipMvtcGrCrctInvAcao: string, 
                                              crctInv: CrctInv, qtdUnd: number, prcUnd: number, 
                                              encgMvtcList: EncgMvtcGrCrctInvAcao[], vlTtlEncg: number, txObsMvtc: string, vlTaxaPercRentAaNegc: number ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    return this.addWithTipEstMvtcAceitaAndCrctInv( cdGrCrctInvAcao, dtRefMvtc, sglTipMvtcGrCrctInvAcao, crctInv, 1, qtdUnd, prcUnd, encgMvtcList, vlTtlEncg, txObsMvtc, vlTaxaPercRentAaNegc );
  }

  /**
   * Adiciona nova movimentacao numa Carteira, com o estado igual a ACEITA.
   */
  public addWithTipEstMvtcAceita( cdGrCrctInvAcao: number, dtRefMvtc: Date, sglTipMvtcGrCrctInvAcao: string, 
                                  cdInv: number, cdTipMoeda: number, qtdUnd: number, prcUnd: number, 
                                  encgMvtcList: EncgMvtcGrCrctInvAcao[], vlTtlEncg: number, txObsMvtc: string, vlTaxaPercRentAaNegc: number ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    const crctInv = new CrctInv();
    crctInv.cdInv = cdInv;

    return this.addWithTipEstMvtcAceitaAndCrctInv( cdGrCrctInvAcao, dtRefMvtc, sglTipMvtcGrCrctInvAcao, crctInv, cdTipMoeda, qtdUnd, prcUnd, encgMvtcList, vlTtlEncg, txObsMvtc, vlTaxaPercRentAaNegc );
  }

  /**
   * Adiciona nova movimentacao numa Carteira, com o estado igual a ACEITA.
   */
  public addWithTipEstMvtcAceitaAndCrctInv( cdGrCrctInvAcao: number, dtRefMvtc: Date, sglTipMvtcGrCrctInvAcao: string, 
                                  crctInv: CrctInv, cdTipMoeda: number, qtdUnd: number, prcUnd: number, 
                                  encgMvtcList: EncgMvtcGrCrctInvAcao[], vlTtlEncg: number, txObsMvtc: string, vlTaxaPercRentAaNegc: number ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    return this.add( cdGrCrctInvAcao, dtRefMvtc, sglTipMvtcGrCrctInvAcao, crctInv, cdTipMoeda, qtdUnd, prcUnd, encgMvtcList, vlTtlEncg, 'ACEITA', txObsMvtc, vlTaxaPercRentAaNegc );
  }

  /**
   * Adiciona nova movimentacao numa Carteira.
   */
  public add( cdGrCrctInvAcao: number, dtRefMvtc: Date, sglTipMvtcGrCrctInvAcao: string, 
              crctInv: CrctInv, cdTipMoeda: number, qtdUnd: number, prcUnd: number, 
              encgMvtcList: EncgMvtcGrCrctInvAcao[], vlTtlEncg: number, sglTipEstMvtc: string, txObsMvtc: string, vlTaxaPercRentAaNegc: number ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    const dtRefMvtcSgbdFormat = this.datePipe.transform( dtRefMvtc, 'yyyy-MM-dd', '+00:00' );

    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'] = cdGrCrctInvAcao;
    reqData[ 'dt_ref_mvtc'        ] = dtRefMvtcSgbdFormat;
    reqData[ 'sgl_tip_mvtc'       ] = sglTipMvtcGrCrctInvAcao;

    if( crctInv != null ){
      if( crctInv.cdInv != null ){
        reqData[ 'cd_inv'         ] = crctInv.cdInv;
      }

      if( crctInv.nmInv != null ){
        reqData[ 'nm_inv'         ] = crctInv.nmInv;
      }
    }

    reqData[ 'cd_tip_moeda'       ] = cdTipMoeda;
    reqData[ 'qtd_und'            ] = qtdUnd;
    reqData[ 'prc_und'            ] = prcUnd;
    reqData[ 'vl_ttl_encg'        ] = vlTtlEncg;
    reqData[ 'sgl_tip_est_mvtc'   ] = sglTipEstMvtc;
    reqData[ 'tx_obs_mvtc'        ] = txObsMvtc;

    if( vlTaxaPercRentAaNegc != null ){
      reqData[ 'vl_taxa_perc_rent_aa_negc'        ] = vlTaxaPercRentAaNegc;
    }

    //Adiciona os encargos
    let qtEncg = 0;

    if( encgMvtcList != null ){
      encgMvtcList.forEach( encgMvtcIterate => {
        const vlEncg    : number  = encgMvtcIterate.vlEncg;
        const txObsEncg : string  = encgMvtcIterate.txObsEncg;

        if( ( vlEncg != null && vlEncg != 0 ) || ( txObsEncg != null && txObsEncg.trim().length > 0 ) ){
          qtEncg++;

          reqData[ 'encg_mvtc_' + qtEncg ] = encgMvtcIterate;
        }
      } );
    }

    reqData[ 'qt_encg'            ] = qtEncg;

    return super.detailGenericX< MovimentacaoGrupoCrctInvAcao[] >( '/MGCIA/ADD', reqData, true );
  }

  /**
   * Cancela a movimentacao numa Carteira.
   */
  public cancel( cdGrCrctInvAcao: number, tsInclMvtc: Date, txObsCancelMvtc: string ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    const tsInclMvtcSgbdFormat = this.datePipe.transform( tsInclMvtc, 'yyyy-MM-dd HH:mm:ss', '+00:00' );

    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'] = cdGrCrctInvAcao;
    reqData[ 'ts_incl_mvtc'        ] = tsInclMvtcSgbdFormat;

    if( txObsCancelMvtc != null && txObsCancelMvtc.length > 0 ){
      reqData[ 'tx_obs_mvtc' ] = txObsCancelMvtc.trim();
    }

    return super.detailGenericX< MovimentacaoGrupoCrctInvAcao[] >( '/MGCIA/CANC', reqData, true );
  }

  /**
   * Lista movimentações da Carteira.
   */
  public list( cdGrCrctInvAcao: number, dtRefMvtcFrom: Date, dtRefMvtcEnd: Date, cdUsrCompt?: number ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'] = cdGrCrctInvAcao;

    if( dtRefMvtcFrom != null ){
      reqData[ 'dt_ref_mvtc_from'] = this.datePipe.transform( dtRefMvtcFrom, 'yyyy-MM-dd', '+00:00' );
    }

    if( dtRefMvtcEnd != null ){
      reqData[ 'dt_ref_mvtc_end'] = this.datePipe.transform( dtRefMvtcEnd, 'yyyy-MM-dd', '+00:00' );
    }

    if( cdUsrCompt && cdUsrCompt != null && cdUsrCompt > 0 ){
      reqData[ 'cd_usr_compt'] = cdUsrCompt;
    }

    return super.listGenericX< MovimentacaoGrupoCrctInvAcao >( '/MGCIA/LIST', reqData, true );
  }

  /**
   * Lista tipos de movimentação da Carteira.
   */
  public listTipMvtc( cdTipInv: number, cdEspcAcao: number, sglTipMvtcGrCrctInvAcao: string ): Promise< TipMvtcGrupoCrctInvAcao[] >{
    let reqData = {};

    if( cdTipInv != null ){
      reqData[ 'cd_tip_inv'] = cdTipInv;
    }

    if( cdEspcAcao != null ){
      reqData[ 'cd_espc_acao'] = cdEspcAcao;
    }

    if( sglTipMvtcGrCrctInvAcao != null ){
      reqData[ 'sgl_tip_mvtc_gr_crct_inv_acao'] = sglTipMvtcGrCrctInvAcao;
    }

    return super.listGenericX< TipMvtcGrupoCrctInvAcao >( '/MGCIA/LIST_TIP_MVTC', reqData, false );
  }


  /**
   * Lista tipos de encargo que podem ser aplicados à movimentação.
   */
  public listTipEncgMvtc( sglTipMvtcGrCrctInvAcao: string, cdTipInv: number, cdEspcAcao: number ): Promise< TipEncg[] >{
    let reqData = {};

    if( sglTipMvtcGrCrctInvAcao != null ){
      reqData[ 'sgl_tip_mvtc_gr_crct_inv_acao'] = sglTipMvtcGrCrctInvAcao;
    }

    if( cdTipInv != null ){
      reqData[ 'cd_tip_inv'] = cdTipInv;
    }

    if( cdEspcAcao != null ){
      reqData[ 'cd_espc_acao'] = cdEspcAcao;
    }

    return super.listGenericX< TipEncg >( '/MGCIA/LTE', reqData, true );
  }


  /**
   * Lista regras de encargo da movimentação.
   */
  public listRgrEncgMvtc( sglTipMvtcGrCrctInvAcao: string, sglTipEncg: string, cdTipInv: number, cdEspcAcao: number, dtRefMvtc: Date, limit: number ): Promise< RgrEncgMvtc[] >{
    let reqData = {};

    if( sglTipMvtcGrCrctInvAcao != null ){
      reqData[ 'sgl_tip_mvtc_gr_crct_inv_acao'] = sglTipMvtcGrCrctInvAcao;
    }

    if( sglTipEncg != null ){
      reqData[ 'sgl_tip_encg'] = sglTipEncg;
    }

    if( cdTipInv != null ){
      reqData[ 'cd_tip_inv'] = cdTipInv;
    }

    if( cdEspcAcao != null ){
      reqData[ 'cd_espc_acao'] = cdEspcAcao;
    }

    if( dtRefMvtc != null ){
      const dtRefMvtcSgbdFormat = this.datePipe.transform( dtRefMvtc, 'yyyy-MM-dd', '+00:00' );

      reqData[ 'dt_ref_mvtc'] = dtRefMvtcSgbdFormat;
    }

    if( limit != null ){
      reqData[ 'limit'] = limit;
    }

    return super.listGenericX< RgrEncgMvtc >( '/MGCIA/LRE', reqData, true );
  }
}
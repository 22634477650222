import { ViewChild                      } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router         } from '@angular/router';
import { FormBuilder, FormControl       } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { Options, ChangeContext } from '@angular-slider/ngx-slider';

import { CrctInv } from '../../../_models/inv';

import { AplicationStateService } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { InvService                  } from '../../../_services/inv/inv.service';
import { CotcService } from 'src/app/_services/cotc/cotc.service';
import { AbstractMainComponent } from '../../abstract-main.component';
import { Empr, InfComplEmpr } from 'src/app/_models/empr';
import { EmprService } from 'src/app/_services/empr/empr.service';
import { DdcService } from 'src/app/_services/ddc/ddc.service';
import { DocDmntCntb } from 'src/app/_models/ddc';
import { MatTableDataSource } from '@angular/material/table';
import { RstdEmpr, RstdEmprJoinInfRstdEmpr, TipInfRstdEmprJoinInfRstdEmpr, InfRstdEmpr } from 'src/app/_models/rstdEmpr';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { IceService } from 'src/app/_services/ice/ice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { WebDriverLogger } from 'blocking-proxy/built/lib/webdriver_logger';
import { EventEmitter } from 'events';
import { DatePipe } from '@angular/common';
import { HstCotcAcao } from 'src/app/_models/cotc';
import { ResponseApiGenericException } from 'src/app/_models/apiService';
import { ControlLoad } from 'src/app/_models/control/controlLoad';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class EmprDetailComponent extends AbstractMainComponent{
  public _empr: Empr;

  //Controls de cada seção da pagina.
  public _controlMain        : ControlLoad;
  public _controlDadosBasicos: ControlLoad;
  public _controlSetor       : ControlLoad;
  public _controlInvList     : ControlLoad;
  public _controlOpcaoList   : ControlLoad;
  public _controlRstdCntb    : ControlLoad;
  public _controlDocList     : ControlLoad;

  public _invDemaisDisplayedColumns: string[] = [ 'inv', 'tipInv', 'negc', 'volNegc', 'lastCotc', 'options' ];
  public _invDemaisDataSource: MatTableDataSource< CrctInv >;

  public _invOpcaoDisplayedColumns: string[] = [ 'invOpcaoNmInv', 'invOpcaoNmEspc', 'invOpcaoEstl', 'invOpcaoPrcExerc', 'invOpcaoDtFimNegc', 'invOpcaoVolNegcMed', 'invOpcaoLastCotc', 'invOpcaoOptions' ];
  public _invOpcaoDataSource: MatTableDataSource< CrctInv >;
  @ViewChild( MatPaginator, {static: true} ) _invOpcaoPaginator: MatPaginator;
  @ViewChild( MatSort     , {static: true} ) _invOpcaoSort     : MatSort;

  public paginatorTickersOpcaoPageSize  = 10;
  public paginatorTickersOpcaoPageIndex = 0;
  private _invOpcaoFilterColumns = {};

  public _espcOpcaoList: any[] = [ { value: ''    , viewValue: 'Todas' },
                                   { value: 'CALL', viewValue: 'CALL'  },
                                   { value: 'PUT' , viewValue: 'PUT'   } ];

  public _estlOpcaoList: any[] = [ { value: ''  , viewValue: 'Todos'       },
                                   { value: 'A' , viewValue: '[A]mericano' },
                                   { value: 'E' , viewValue: '[E]uropeu'   } ];

  public _dtFimNegcOpcaoList: any[];
  private DT_FIM_NEGC_OPCAO_TODOS = { 'value': 0, 'viewValue': 'Todos', 'objectValue': null };

  public _optionsStrikeRangeSliderOpcao: Options = { floor          : 10   ,
                                                     ceil           : 90   ,
                                                     step           : 0.01 ,
                                                     showTicks      : true ,
                                                     showTicksValues: false,
                                                     noSwitching    : true ,
                                                     draggableRange : true 
                                                     //getLegend: (value: number): string => {
                                                     //  return this._legendsTicksStrikeRangeSliderOpcao[ value.toString() ];
                                                     //}
  };

  public _legendsTicksStrikeRangeSliderOpcao = {};

  public _ddcDisplayedColumns: string[] = [ 'dtPblccDocDmntCntb', 'sglDocDmntCntb', 'options' ];
  public _ddcDataSource: MatTableDataSource< DocDmntCntb >;

  public _rstdDisplayedColumns: string[] = [ 'refPerdc', 'dtPblccRef', 'lpAtrbdSocCntr', 'divCp', 'divLp', 'disp', 'options' ];
  public _rstdDataSource: MatTableDataSource< RstdEmprJoinInfRstdEmpr >;

  public _tipInfRstdPerdcDisplayedColumns: string[];
  public _tipInfRstdDisplayedColumns: string[];
  public _tipInfRstdDataSource: MatTableDataSource< TipInfRstdEmprJoinInfRstdEmpr >;

  public isInvDemaisListEmpty: boolean;
  public isInvOpcaoListEmpty: boolean;
  public isDdcListEmpty: boolean;
  public isRstdListEmpty: boolean;
  public isTipInfRstdListEmpty: boolean;

  public _rstdEmprLast: RstdEmprJoinInfRstdEmpr;

  public _infComplList: InfComplEmpr[];
  public _qtdAcoesOn: number;
  public _qtdAcoesPn: number;
  public _qtdTtlAcoes: number;
  public _dtIncFncEmpr: Date;
  public _qtdAcionistas: number;

  public _aaRefList: any[] = [];

  public _nrRefList: any[] = [ { value: 0, viewValue: 'Todos' }, 
                               { value: 1, viewValue: '1º' }, 
                               { value: 2, viewValue: '2º' }, 
                               { value: 3, viewValue: '3º' },
                               { value: 4, viewValue: '4º' } ];

  private _nrRefTrmstList: any[] = [ { value: 0, viewValue: 'Todos' },
                                    { value: 1, viewValue: '1º' },
                                    { value: 2, viewValue: '2º' },
                                    { value: 3, viewValue: '3º' },
                                    { value: 4, viewValue: '4º' } ];

  public _nmColumnNrRef: string = "Trimestre:";

                                   private _nrRefSmstList: any[] = [ { value: 0, viewValue: 'Todos' },
                                   { value: 1, viewValue: '1º' },
                                   { value: 2, viewValue: '2º' } ];

  public _form;

  constructor( protected router: Router,
               protected activatedRoute: ActivatedRoute,
               protected sanitizer: DomSanitizer,
               protected formBuilder: FormBuilder,
               protected dialog: MatDialog,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar             : MatSnackBar           ,
               protected invService: InvService,
               protected ddcService: DdcService,
               protected emprService: EmprService,
               protected rstdEmprService: RstdEmprService,
               protected cotcService: CotcService,
               protected iceService: IceService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    this._controlMain         = new ControlLoad( 'main' );
    this._controlDadosBasicos = new ControlLoad( 'dadosBasicos' );
    this._controlSetor        = new ControlLoad( 'setor' );
    this._controlInvList      = new ControlLoad( 'invList' );
    this._controlOpcaoList    = new ControlLoad( 'opcaoList' );
    this._controlRstdCntb     = new ControlLoad( 'resultadosContabeis' );
    this._controlDocList      = new ControlLoad( 'documentos' );

    this._controlMain.initializingLoad();

    let promises  : Promise< any >[] = [];

    const pCreateForm = this.initCreateForm();
    promises.push( pCreateForm );

    this.activatedRoute.queryParams.subscribe( params => {
        const _this = this;

        const cdEmpr = params.cdEmpr;

        if( cdEmpr && cdEmpr != null ){
          const pCreateDetail = this.detail( cdEmpr );
          promises.push( pCreateDetail );

          pCreateDetail
            .then( cdEmpr => {
              if( cdEmpr != null ){
                //Obtem lista de anos que se tem resultado contabil para a empresa.
                const pAllAaRefRstdEmpr = _this.loadAllAaRefRstdEmpr( cdEmpr );
                promises.push( pAllAaRefRstdEmpr );

                //Obtem o ultimo resultado da empresa.
                const pLastRstdEmpr = _this.loadLastRstdEmpr( cdEmpr );
                promises.push( pLastRstdEmpr );

                pLastRstdEmpr.then( isLoadedLastRstdEmpr => {
                    //Obtem lista de resultados trimestrais contabeis vinculados a empresa.
                    const pLoadRstdEmpr = _this.loadRstdEmprSincePrevYear( cdEmpr );
                    promises.push( pLoadRstdEmpr );
                  } );
              }

              _this._controlDadosBasicos.loaded();
            } )
            .catch( error => {
              console.log( 'Erro ao detalhar a empresa: ' + error );
            } );
        }
        else{
          console.log( 'Empresa não informada.' );
        }
      }
    );

    this.monitoryInit( promises );
  }

  private initCreateForm(): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      //Get last year.
      const lastYear = (new Date()).getFullYear() - 1;

      _this._form = _this.formBuilder.group(
        { nmEmpr  : new FormControl({ value: '', disabled: true }),
          sglPerdc: 'TRMST' ,
          aaRef   : lastYear,
          nrRef   : 0       ,
          espcOpcaoSelected     : '',
          estlOpcaoSelected     : '',
          dtFimNegcOpcaoSelected: 0,
          isLastCotcExistOpcaoSelected: new FormControl( true ),
          strikeOpcaoSelected         : new FormControl( [20, 80] )
        }
      );

      resolve();
    } );

    return p;
  }

  private getEspcOpcaoSelected(){
    return this._form.controls[ 'espcOpcaoSelected' ].value;
  }

  private getEstlOpcaoSelected(){
    return this._form.controls[ 'estlOpcaoSelected' ].value;
  }

  private getDtFimNegcOpcaoSelected(){
    return this._form.controls[ 'dtFimNegcOpcaoSelected' ].value;
  }

  private isLastCotcExistOpcaoSelected(){
    return this._form.controls[ 'isLastCotcExistOpcaoSelected' ].value;
  }

  private getStrikeMinOpcaoSelected(){
    return this._form.controls[ 'strikeOpcaoSelected' ].value[0];
  }

  private setStrikeMinOpcaoSelected( valueMin: number ){
    const valueMax = this.getStrikeMaxOpcaoSelected();
    this._form.patchValue( { strikeOpcaoSelected: [valueMin, valueMax] } );
  }

  private getStrikeMaxOpcaoSelected(){
    return this._form.controls[ 'strikeOpcaoSelected' ].value[1];
  }

  private setStrikeMaxOpcaoSelected( valueMax: number ){
    const valueMin = this.getStrikeMinOpcaoSelected();
    this._form.patchValue( { strikeOpcaoSelected: [valueMin, valueMax] } );
  }

  private getSglPerdcSelected(){
    return this._form.controls[ 'sglPerdc' ].value;
  }

  private setSglPerdcSelected( sglPerdcSelected: string ){
    this._form.patchValue( { sglPerdc: sglPerdcSelected } );
  }

  private isSglPerdcSelectedAnual(){
    const sglPerdcSelected = this.getSglPerdcSelected();

    return sglPerdcSelected === 'ANUAL';
  }

  private isSglPerdcSelectedTrimestral(){
    const sglPerdcSelected = this.getSglPerdcSelected();

    return sglPerdcSelected === 'TRMST';
  }

  private isSglPerdcSelectedSemestral(){
    const sglPerdcSelected = this.getSglPerdcSelected();

    return sglPerdcSelected === 'SMST';
  }

  public isSglPerdcSelectedNecessitaNrRef(){
    return this.isSglPerdcSelectedTrimestral() || this.isSglPerdcSelectedSemestral();
  }

  private getAaRefSelected(){
    return this._form.controls[ 'aaRef' ].value;
  }

  private setAaRefSelected( aaRefSelected: number ){
    this._form.patchValue( { aaRef: aaRefSelected } );
  }

  private getNrRefSelected(){
    return this._form.controls[ 'nrRef' ].value;
  }

  private setNrRefSelected( nrRefSelected: number ){
    this._form.patchValue( { nrRef: nrRefSelected } );
  }

  onSubmit(){
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    const _this = this;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          _this._controlMain.valueProgressBar = promisesFinish / qtPromises * 100;
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        _this._controlMain.loaded();
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  /**
   * 
   * @param cdEmpr 
   */
  protected detail( cdEmpr: number ): Promise< any >{
    const _this = this;

    this._controlDadosBasicos.initializingLoad();
    this._controlSetor.initializingLoad();

    const p = new Promise< number >( function( resolve, reject ){
      _this.emprService.detail( cdEmpr )
        .then( detailObj => {
          _this._empr = detailObj;

          const cdEmpr = _this._empr == null ? 0 : _this._empr.cdEmpr;

          if( cdEmpr != null ){
            //Altera o titulo do site.
            const nmEmpr = _this._empr.nmEmpr;
            _this.aplicationStateService.setTitle( nmEmpr );

            //Obtem lista de informacoes complementares contabeis da empresa.
            _this.loadInfComplList( cdEmpr )
              .then( response => {
                resolve( cdEmpr );
              } )
              .catch( error => {
                reject();
              } );

            //Obtem lista de informacoes complementares da empresa.
            _this.loadInfComplEmprList( cdEmpr )
              .then( response => {
                resolve( cdEmpr );
              } )
              .catch( error => {
                reject();
              } );
          }
          else{
            resolve( null );
          }
        })
        .catch( error => {
          if( error instanceof ResponseApiGenericException ){
            const apiException: ResponseApiGenericException = error as ResponseApiGenericException;
            window.alert( 'Ocorreu um erro ao detalhar a empresa. [' + apiException.codErr + ' - ' + apiException.msgErr + ']' );
          }
          else{
            window.alert( error );
          }

          reject();
        } )
        .finally( () => {
          _this._controlSetor.loaded();
        } );
    } );

    return p;
  }

  private loadInfComplList( cdEmpr: number ): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      let promises: Promise< any >[] = [];

      const pInfCompl = _this.emprService.listInfCompl( cdEmpr )
        .then( infComplList => {
          _this._infComplList = infComplList;

          _this._qtdAcoesOn = null;
          _this._qtdAcoesPn = null;

          const amountInfCompl = _this._infComplList == null ? 0 : _this._infComplList.length;

          for( let indexInfCompl = 0; indexInfCompl < amountInfCompl; indexInfCompl++ ){
            const infCompl = _this._infComplList[ indexInfCompl ];

            const sglTipInfCompl = infCompl.tipInf.sglTipInf;

            if( sglTipInfCompl === 'QTD_ON_CAP_INTG' ){
              _this._qtdAcoesOn += Number( infCompl.txInfComplEmpr );
            }

            if( sglTipInfCompl === 'QTD_PN_CAP_INTG' ){
              _this._qtdAcoesPn += Number( infCompl.txInfComplEmpr );
            }
          }
        } )
        .catch( error => {
        } );

      promises.push( pInfCompl );


      //Obtem o L/P.
      const pVlByCotc = _this.iceService.listVlByCotc( cdEmpr, 'L/P_12M', 1 )
        .then( iceList => {
          const amountOfReg = iceList != null ? iceList.length : 0;

          if( amountOfReg > 0 ){
            const ice = iceList == null ? null : iceList[ 0 ].infComplEmpr;

            if( ice != null ){
              if( !_this._empr.rstdEmpr.lpAtrbdSocCntr || _this._empr.rstdEmpr.lpAtrbdSocCntr == null ){
                _this._empr.rstdEmpr.lpAtrbdSocCntr = new InfRstdEmpr();
              }

              _this._empr.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr = ice.vlInfComplEmpr;
              _this._empr.rstdEmpr.lpAtrbdSocCntr.dtPblccRef = ice.dtRefInfComplEmpr;
            }
          }
          else{
            _this._empr.rstdEmpr.lpAtrbdSocCntr.vlRstdEmpr = null;
          }
        } )
        .catch( error => {
        } );

        promises.push( pVlByCotc );


        Promise.all( promises )
          .then( response => {
            resolve();
          } )
          .catch( error => {
            reject();
          } );
    } );

    return p;
  }

  private loadInfComplEmprList( cdEmpr: number ): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      let promises: Promise< any >[] = [];

      const pInfCompl = _this.emprService.listInfComplEmpr( cdEmpr )
        .then( infComplList => {
          _this._infComplList = infComplList;

          _this._qtdTtlAcoes  = null;
          _this._dtIncFncEmpr = null;
          _this._qtdAcionistas = null;

          const amountInfCompl = _this._infComplList == null ? 0 : _this._infComplList.length;

          for( let indexInfCompl = 0; indexInfCompl < amountInfCompl; indexInfCompl++ ){
            const infCompl = _this._infComplList[ indexInfCompl ];

            const sglTipInfCompl = infCompl.tipInf.sglTipInf;

            if( sglTipInfCompl === 'TTL_ACOES' ){
              _this._qtdTtlAcoes += Number( infCompl.txInfComplEmpr );
            }

            if( sglTipInfCompl === 'DT_INC_FNC' ){
              _this._dtIncFncEmpr = infCompl.dtInfComplEmpr;
            }

            if( sglTipInfCompl === 'TTL_COTISTAS_QTD_TTL' ){
              _this._qtdAcionistas += infCompl.vlInfComplEmpr;
            }
          }
        } )
        .catch( error => {
        } );

      promises.push( pInfCompl );

      Promise.all( promises )
        .then( response => {
          resolve();
        } )
        .catch( error => {
          reject();
        } );
    } );

    return p;
  }

  /**
   * Carrega os investimentos vinculados aa empresa do tipo 7-ACAO e 2-FUNDO.
   */
  private loadInvList( cdEmprRespInv: number ): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      _this.invService.listInvAcaoFundoOfEmpr( cdEmprRespInv, null, true )
        .then( invList => {
          var datePipe = new DatePipe( 'pt-BR' );
          const amountOfInv = invList != null ? invList.length : 0;
          const lastIndexOfAmountOfInv = amountOfInv - 1;

          _this._invDemaisDataSource = new MatTableDataSource< CrctInv >();

          let promises: Promise< any >[] = [];

          //Obtem a ultima cotacao de cada ticker.
          for( let indexOfInv = 0; indexOfInv < amountOfInv; indexOfInv++ ){
            const invIterate: CrctInv = invList[ indexOfInv ];

            const crctInvAcao = invIterate.crctInvAcao;
            const cdInv       = invIterate.cdInv;
            const nmInv       = invIterate.nmInv;
            const cdTipInv    = invIterate.tipInv.cdTipInv;

            _this._invDemaisDataSource.data.push( invIterate );

            const p = _this.cotcService.listLatest( cdInv, 1 );
            promises.push( p );

            p.then( cotcList => {
                const amountOfCotc = cotcList != null ? cotcList.length : 0;

                invList[ indexOfInv ].crctInvAcao.hstCotcAcao = amountOfCotc > 0 ? cotcList : null;

                if( cdTipInv === 7 && amountOfCotc > 0 ){
                  _this.addCotcAcaoInOptionsStrikeRangeSliderOpcao( nmInv, cotcList[0] );
                }

                if( indexOfInv === lastIndexOfAmountOfInv ){
                  _this.execFilterInvListOpcao( 'fim' );
                }
              } );
          }

          _this.isInvDemaisListEmpty = _this._invDemaisDataSource.data != null ? _this._invDemaisDataSource.data.length == 0 : true;

          Promise.all( promises )
          .then( response => {
            resolve();
          } )
          .catch( error => {
            reject();
          } );
        } );
    } );

    return p;
  }

  /**
   * Carrega os investimentos vinculados aa empresa do tipo OPCAO.
   */
  private loadOpcaoList( cdEmprRespInv: number ): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      _this.invService.listInvOfEmpr( cdEmprRespInv, null, 9 )
        .then( invList => {
          var datePipe = new DatePipe( 'pt-BR' );
          const amountOfInv = invList != null ? invList.length : 0;
          const lastIndexOfAmountOfInv = amountOfInv - 1;

          _this._invOpcaoDataSource = new MatTableDataSource< CrctInv >();

          _this._dtFimNegcOpcaoList = [];

          let minStrikeOpcao = 100;
          let maxStrikeOpcao = 0;

          let promises: Promise< any >[] = []; 

          //Obtem a ultima cotacao de cada OPCAO.
          for( let indexOfInv = 0; indexOfInv < amountOfInv; indexOfInv++ ){
            const invIterate: CrctInv = invList[ indexOfInv ];

            const crctInvAcao = invIterate.crctInvAcao;
            const cdInv       = invIterate.cdInv;
            const nmInv       = invIterate.nmInv;
            const cdTipInv    = invIterate.tipInv.cdTipInv;

            if( cdTipInv === 9 ){
              if( crctInvAcao != null && crctInvAcao.negc != null ){
                if( crctInvAcao.negc.dtFim != null ){
                  const dtFimNegcOpcao = new Date( crctInvAcao.negc.dtFim );
                  invIterate.crctInvAcao.negc.dtFim = dtFimNegcOpcao;

                  if( _this.isExistDtInArray( _this._dtFimNegcOpcaoList, dtFimNegcOpcao, 'objectValue' ) === false ){
                    const dtFimNegcOpcaoStr = datePipe.transform( dtFimNegcOpcao, 'dd/MM/yyyy' );
                    const dtFimNegcOpcaoElem = { 'value': dtFimNegcOpcao.getTime(), 'viewValue': dtFimNegcOpcaoStr, 'objectValue': dtFimNegcOpcao };
                    _this._dtFimNegcOpcaoList.push( dtFimNegcOpcaoElem );
                  }
                }

                if( crctInvAcao.negc.prcExerc != null ){
                  if( crctInvAcao.negc.prcExerc < minStrikeOpcao ){
                    minStrikeOpcao = crctInvAcao.negc.prcExerc;
                  }
                  if( crctInvAcao.negc.prcExerc > maxStrikeOpcao ){
                    maxStrikeOpcao = crctInvAcao.negc.prcExerc;
                  }
                }
              }

              _this._invOpcaoDataSource.data.push( invIterate );
            }

            const p = _this.cotcService.listLatest( cdInv, 1 );
            promises.push( p );

            p.then( cotcList => {
                const amountOfCotc = cotcList != null ? cotcList.length : 0;

                invList[ indexOfInv ].crctInvAcao.hstCotcAcao = amountOfCotc > 0 ? cotcList : null;

                if( cdTipInv === 7 && amountOfCotc > 0 ){
                  _this.addCotcAcaoInOptionsStrikeRangeSliderOpcao( nmInv, cotcList[0] );
                }

                if( indexOfInv === lastIndexOfAmountOfInv ){
                  _this.execFilterInvListOpcao( 'fim' );
                }
              } );
          }

          //Ordena o array contendo as datas de fim de negociação das opcoes, e acrescenta o elementos 'Todos'.
          _this._dtFimNegcOpcaoList.sort( (dtFim1, dtFim2) => dtFim1.objectValue.getTime() - dtFim2.objectValue.getTime() );
          _this._dtFimNegcOpcaoList.unshift( _this.DT_FIM_NEGC_OPCAO_TODOS );

          //Define os valores minimo e maximo do slider do valor do strike.
          const floor = Math.min( minStrikeOpcao, maxStrikeOpcao );
          const ceil  = Math.max( minStrikeOpcao, maxStrikeOpcao );
          _this.setLimitsInOptionsStrikeRangeSliderOpcao( floor, ceil );

          //Redefine os sliders dos valores minimo e maximo do range do strike.
          _this.setStrikeMinOpcaoSelected( floor );
          _this.setStrikeMaxOpcaoSelected( ceil );

          _this._invOpcaoDataSource.paginator = _this._invOpcaoPaginator;

          _this._invOpcaoDataSource.sortingDataAccessor = ( item, property ) => {
            switch( property ) {
              case 'invOpcaoNmInv'      : return item.nmInv;
              case 'invOpcaoNmEspc'     : return item.crctInvAcao != null && item.crctInvAcao.espcAcao != null ? item.crctInvAcao.espcAcao.nmEspcAcao : null;
              case 'invOpcaoEstl'       : return item.crctInvAcao != null ? item.crctInvAcao.estlOpcao : null;
              case 'invOpcaoPrcExerc'   : return ( item.crctInvAcao != null && item.crctInvAcao.negc ) != null ? item.crctInvAcao.negc.prcExerc : null;
              case 'invOpcaoDtFimNegc'  : return ( item.crctInvAcao != null && item.crctInvAcao.negc ) != null ? item.crctInvAcao.negc.dtFim : null;
              case 'invOpcaoVolNegcMed' : return item.crctInvAcao != null ? item.crctInvAcao.volNegcMed : null;
              case 'invOpcaoLastCotc'   : return ( item.crctInvAcao.hstCotcAcao != null ) ? item.crctInvAcao.hstCotcAcao[0].prcFech : null;
              default: return item[ property ];
            }
          };

          _this._invOpcaoDataSource.sort = _this._invOpcaoSort;

          //Filtros
          _this._invOpcaoDataSource.filterPredicate = ( d: CrctInv, filter: string ) => {
            try{
              let isView = true;

              const espcSelected            = _this.getEspcOpcaoSelected();
              const estlSelected            = _this.getEstlOpcaoSelected();
              const dtFimNegcSelected       = _this.getDtFimNegcOpcaoSelected();
              const isLastCotcExistSelected = _this.isLastCotcExistOpcaoSelected();
              const strikeMinOpcaoSelected  = _this.getStrikeMinOpcaoSelected();
              const strikeMaxOpcaoSelected  = _this.getStrikeMaxOpcaoSelected();

              //console.log( 'Espc/Estl Selecteds: ' + espcSelected + '/' + estlSelected + '/' + strikeMinOpcaoSelected + '-' + strikeMaxOpcaoSelected );

              isView = isView && ( espcSelected            == null || espcSelected.length == 0 || espcSelected === d.crctInvAcao.espcAcao.nmEspcAcao );
              isView = isView && ( estlSelected            == null || estlSelected.length == 0 || estlSelected === d.crctInvAcao.estlOpcao           );

              if( d.crctInvAcao != null || d.crctInvAcao.negc != null ){
                isView = isView && ( ( isLastCotcExistSelected == null  )  ||
                                    ( isLastCotcExistSelected === false ) ||
                                    ( isLastCotcExistSelected === true && d.crctInvAcao.hstCotcAcao != null && d.crctInvAcao.hstCotcAcao.length > 0 && d.crctInvAcao.hstCotcAcao[0].prcFech > 0 ) );

                if( d.crctInvAcao.negc.dtFim != null && dtFimNegcSelected != null && dtFimNegcSelected > 0 ){
                  isView = isView && ( dtFimNegcSelected === d.crctInvAcao.negc.dtFim.getTime() );
                }

                if( d.crctInvAcao.negc.prcExerc != null ){
                  isView = isView && ( strikeMinOpcaoSelected == null || strikeMinOpcaoSelected.length == 0 || strikeMinOpcaoSelected <= d.crctInvAcao.negc.prcExerc );
                  isView = isView && ( strikeMaxOpcaoSelected == null || strikeMaxOpcaoSelected.length == 0 || strikeMaxOpcaoSelected >= d.crctInvAcao.negc.prcExerc );
                }
              }

              return isView;
            }
            catch( error ){
              console.log( error );
              return false;
            }
          };

          _this.isInvOpcaoListEmpty  = _this._invOpcaoDataSource.data  != null ? _this._invOpcaoDataSource.data.length  == 0 : true;

          Promise.all( promises )
          .then( response => {
            resolve();
          } )
          .catch( error => {
            reject();
          } );
        } )
        ;
    } );

    return p;
  }

  private isExistDtInArray( array: any[], dtTarget: Date, label: string ){
    const countElems = array != null ? array.length : 0;

    let isExist = false;

    for(let iArray = 0; iArray < countElems; iArray++ ){
      const elem = array[ iArray ];

      const dtOfElem = label == null ? elem.getTime() : elem[ label ].getTime();

      if( dtTarget.getTime() === dtOfElem ){
        isExist = true;
        break;
      }
    }

    return isExist;
  }

  private addCotcAcaoInOptionsStrikeRangeSliderOpcao( nmInv, cotcAcao: HstCotcAcao ){
    //console.log( cotcAcao.cdInv + '-' + nmInv + ': ' + cotcAcao.prcFech );

    const newOptionsStrikeRangeSliderOpcao: Options = Object.assign( {}, this._optionsStrikeRangeSliderOpcao );

    if( !newOptionsStrikeRangeSliderOpcao.ticksArray ){
      newOptionsStrikeRangeSliderOpcao.ticksArray = [];
    }

    newOptionsStrikeRangeSliderOpcao.ticksArray.push( cotcAcao.prcFech );

    this._optionsStrikeRangeSliderOpcao = newOptionsStrikeRangeSliderOpcao;

    const valusStr = cotcAcao.prcFech.toString();
    this._legendsTicksStrikeRangeSliderOpcao[ valusStr ] = nmInv;
  }

  private setLimitsInOptionsStrikeRangeSliderOpcao( floor: number, ceil: number ){
    const newOptionsStrikeRangeSliderOpcao: Options = Object.assign( {}, this._optionsStrikeRangeSliderOpcao );
    newOptionsStrikeRangeSliderOpcao.floor = floor;
    newOptionsStrikeRangeSliderOpcao.ceil  = ceil;

    this._optionsStrikeRangeSliderOpcao = newOptionsStrikeRangeSliderOpcao;
  }

  private loadDdc( cdEmpr: number ): Promise< any >{
    this._controlDocList.initializingLoad();

    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      _this.ddcService.list( cdEmpr )
        .then( ddcList => {
          _this.isDdcListEmpty = ( ddcList.length === 0 );

          _this._ddcDataSource = new MatTableDataSource< DocDmntCntb >( ddcList );

          _this._controlDocList.loaded();

          resolve();
        } )
        .catch( error => {
          reject();
        } );
    } );

    return p;
  }

  private loadLastRstdEmpr( cdEmpr: number ){
    let _this = this;

    return new Promise( function( resolve, reject ) {
      _this.rstdEmprService.listLastRstdEmpr( cdEmpr )
        .then( rstdEmprList => {
          const rstdEmprLast: RstdEmprJoinInfRstdEmpr = ( rstdEmprList == null || rstdEmprList.length === 0 ) ? null : rstdEmprList[ 0 ];

          _this.updateLastRstdEmpr( rstdEmprLast );

          _this.isRstdListEmpty = ( rstdEmprLast === null );

          resolve( true );
        } );
    });
  }

  private loadAllAaRefRstdEmpr( cdEmpr: number ): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      _this.rstdEmprService.listAllAaRefRstdEmpr( cdEmpr )
        .then( aaRefList => {
          const sizeAaRefList = aaRefList == null ? 0 : aaRefList.length;

          _this._aaRefList = [];
          _this._aaRefList.push( { value: 0, viewValue: 'Todos' } );

          for( let indexAaRef = 0; indexAaRef < sizeAaRefList; indexAaRef++ ){
            const aaRefIterated = aaRefList[ indexAaRef ];

            const aaRefItem = { value: aaRefIterated.rstdEmpr.aaRefPerdc, viewValue: aaRefIterated.rstdEmpr.aaRefPerdc.toString() };

            _this._aaRefList.push( aaRefItem );
          }

          resolve();
        } )
        .catch( error => {
          reject();
        } );
    } );

    return p;
  }

  private loadRstdEmprSincePrevYear( cdEmpr: number ): Promise< any >{
    this._controlRstdCntb.initializingLoad();

    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      const sglPerdc = _this.getSglPerdcSelected();
      const aaRef    = _this.getAaRefSelected() - 1;
      const nrRef    = _this.getNrRefSelected();

      _this.loadRstd( cdEmpr, sglPerdc, aaRef, nrRef )
        .then( response => {
          resolve();
        } )
        .catch( error => {
          reject();
        } )
        .finally( () => {
          _this._controlRstdCntb.loaded();
        } );
    } );

    return p;
  }

  private loadRstdEmpr( cdEmpr: number ): Promise< any >{
    const sglPerdc = this.getSglPerdcSelected();
    const aaRef = this.getAaRefSelected();
    const nrRef = this.getNrRefSelected();

    return this.loadRstd( cdEmpr, sglPerdc, aaRef, nrRef );
  }

  private loadRstd( cdEmpr: number, sglPerdc: string, aaRef: number, nrRef: number ): Promise< any >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      const returnCols = [ { 'lpAtrbdSocCntr': true }, { 'lpAtrbdMinor': true }, { 'ebitda': true }, { 'ativoTotal': true }, { 'resultFinanc': true }, { 'divCp': true }, { 'divLp': true }, { 'disp': true }, { 'pl': true }, { 'divLq': true }, { 'deprecAmort': true }, { 'irCsll': true } ];

      _this.rstdEmprService.listTipInfWithVariousInfRstd( cdEmpr, sglPerdc, aaRef, nrRef, null, returnCols )
        .then( tipInfList => {
          const sizeTipInfList = tipInfList == null ? 0 : tipInfList.length;

          _this.isTipInfRstdListEmpty = ( sizeTipInfList === 0 );

          _this._tipInfRstdDataSource = new MatTableDataSource< TipInfRstdEmprJoinInfRstdEmpr >( tipInfList );

          //Obtem os ano/nr-ref
          const infRstdEmprArray = sizeTipInfList == 0 ? null : tipInfList[ 0 ].infRstdEmprArray;

          _this._tipInfRstdPerdcDisplayedColumns = new Array();
          _this._tipInfRstdDisplayedColumns = new Array();

          if( infRstdEmprArray != null ){
            const sizeInfRstdList = infRstdEmprArray.length;

            for( let indexInfRstd = 0; indexInfRstd < sizeInfRstdList; indexInfRstd++ ){
              const infRstdEmprSelected = infRstdEmprArray[ indexInfRstd ];

              const aaRefPerdc = infRstdEmprSelected.aaRefPerdc;
              const nrRefPerdc = infRstdEmprSelected.nrRefPerdc;

              const column = aaRefPerdc + ( sglPerdc === 'ANUAL' ? '' : ( '/' + nrRefPerdc ) );

              _this._tipInfRstdPerdcDisplayedColumns.push( column );
              _this._tipInfRstdDisplayedColumns.push( column );
            }
          }

          _this._tipInfRstdDisplayedColumns.unshift( 'tipInfRstdEmpr' );

          resolve();
        } )
        .catch( error => {
          reject();
        } );
    } );

    return p;
  }

  private updateLastRstdEmpr( rstdEmpr: RstdEmprJoinInfRstdEmpr ){
    this._rstdEmprLast = rstdEmpr;

    if( rstdEmpr != null ){
      this.setSglPerdcSelected( rstdEmpr.rstdEmpr.perdc.sglPerdc );
      this.setAaRefSelected( rstdEmpr.rstdEmpr.aaRefPerdc );
      this.setNrRefSelected( rstdEmpr.rstdEmpr.nrRefPerdc );
    }
    else{
      this.setSglPerdcSelected( null );
      this.setAaRefSelected( null );
      this.setNrRefSelected( null );
    }
  }

  public onOpenedPanelInvList(): void{
    const cdEmpr = this._empr != null ? this._empr.cdEmpr : null;
    const controlLoad = this._controlInvList;

    if( cdEmpr != null && cdEmpr > 0 ){
      if( controlLoad.isDataLoaded === false ){
        controlLoad.initializingLoad();

        this.loadInvList( cdEmpr )
          .finally( () => {
            controlLoad.loaded();
          } )
      }
    }
  }

  public onOpenedPanelOpcaoList(): void{
    const cdEmpr = this._empr != null ? this._empr.cdEmpr : null;
    const controlLoad = this._controlOpcaoList;

    if( cdEmpr != null && cdEmpr > 0 ){
      if( controlLoad.isDataLoaded === false ){
        controlLoad.initializingLoad();

        this.loadOpcaoList( cdEmpr )
          .finally( () => {
            controlLoad.loaded();
          } )
      }
    }
  }

  public onOpenedPanelDocList(): void{
    const cdEmpr = this._empr != null ? this._empr.cdEmpr : null;
    const controlLoad = this._controlDocList;

    if( cdEmpr != null && cdEmpr > 0 ){
      if( controlLoad.isDataLoaded === false ){
        controlLoad.initializingLoad();

        //Obtem lista de documentos vinculados a empresa.
        this.loadDdc( cdEmpr )
          .finally( () => {
            controlLoad.loaded();
          } );
      }
    }
  }

  updateTipInfRstdEmprListByPerdc( event: Event ){
    if( this.isSglPerdcSelectedAnual() ){
      this.setNrRefSelected( 0 );
      this._nmColumnNrRef = '';
    }
    else{
      if( this.isSglPerdcSelectedSemestral() ){
        this._nrRefList = this._nrRefSmstList;
        this.setNrRefSelected( 0 );
        this._nmColumnNrRef = 'Semestre:';
      }
      else{
        this._nrRefList = this._nrRefTrmstList;
        this.setNrRefSelected( 0 );
        this._nmColumnNrRef = 'Trimestre:';
      }
    }

    this.loadRstdEmpr( this._empr.cdEmpr );
  }

  updateTipInfRstdEmprList( event: Event ){
    this.loadRstdEmpr( this._empr.cdEmpr );
  }

  applyFilterEspcOpcaoSelected( event: Event ){
    const filterValue = event[ 'value' ];
    this.execFilterInvListOpcao( filterValue );
  }

  applyFilterEstlOpcaoSelected( event: Event ){
    const filterValue = event[ 'value' ];
    this.execFilterInvListOpcao( filterValue );
  }

  applyFilterDtFimNegcOpcaoSelected( event: Event ){
    const filterValue = event[ 'value' ];
    this.execFilterInvListOpcao( filterValue );
  }

  applyFilterLastCotcExistOpcaoSelected( event: Event ){
    const filterValue = event[ 'value' ];
    this.execFilterInvListOpcao( 'lastCotcExist' );
  }

  applyFilterStrikeRangeOpcaoSelected( context: ChangeContext ){
    this.execFilterInvListOpcao( context.highValue );
  }

  private execFilterInvListOpcao( filterValue: any ){
    if( this._invOpcaoDataSource && this._invOpcaoDataSource != null ){
      this._invOpcaoDataSource.filter = filterValue;
    }
  }

  changeTickersOpcaoPageSize( event: Event ){

  }

  detailSetorEcnm(){
    const segm     = this._empr.segmEcnm;
    const subSetor = segm.subSetorEcnm;
    const setor    = subSetor.setorEcnm;

    this.goToDetailSegmEcnm( setor.cdSetorEcnm, null, null );
  }

  detailSubSetorEcnm(){
    const segm     = this._empr.segmEcnm;
    const subSetor = segm.subSetorEcnm;
    const setor    = subSetor.setorEcnm;

    this.goToDetailSegmEcnm( setor.cdSetorEcnm, subSetor.cdSubSetorEcnm, null );
  }

  detailSegmEcnm(){
    const segm     = this._empr.segmEcnm;
    const subSetor = segm.subSetorEcnm;
    const setor    = subSetor.setorEcnm;

    this.goToDetailSegmEcnm( setor.cdSetorEcnm, subSetor.cdSubSetorEcnm, segm.cdSegmEcnm );
  }

  detailSetorAtvdd(){
    const setorAtvdd   = this._empr.setorAtvdd;
    const cdSetorAtvdd = setorAtvdd == null ? null : setorAtvdd.cdSetorAtvdd;

    this.goToDetailSetorAtvdd( cdSetorAtvdd );
  }

  private goToDetailSegmEcnm( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ){
    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm, 'cdSubSetorEcnm': cdSubSetorEcnm, 'cdSegmEcnm': cdSegmEcnm }, true );
  }

  private goToDetailSetorAtvdd( cdSetorAtvdd: number ){
    this.goToPage( '/setor-detail', { 'cdSetorAtvdd': cdSetorAtvdd }, true );
  }

  detailCrctInv( cdInv: number ){
    this.goToPage( '/inv-detail', { 'cdInv': cdInv }, true );
  }

  detailDdc( sglDocDmntCntb: string, dtPblccDocDmntCntb: Date, nrSeqDocDmntCntb: number ){
    this.goToPage( '/ddc-detail', { 'cdEmpr': this._empr.cdEmpr, 'sglDocDmntCntb': sglDocDmntCntb, 'dtPblccDocDmntCntb': dtPblccDocDmntCntb, 'nrSeqDocDmntCntb': nrSeqDocDmntCntb }, true );
  }

  detailRstd( aaRefPerdc: number, nrRefPerdc: number, sglPerdc: string ){
    this.goToPage( '/rstdEmpr-detail', { 'cdEmpr': this._empr.cdEmpr, 'aaRefPerdc': aaRefPerdc, 'nrRefPerdc': nrRefPerdc, 'sglPerdc': sglPerdc }, true );
  }
}

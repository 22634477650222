<!-- ================================= CONTENT ================================= -->

<div class="grid_container_home_desktop">
	<div class="grid_item_home_desktop_links">
    <ul class="home_desktop_list">
      <li class="home_desktop_list_item">
				<a routerLink="/estrtg-pend" routerLinkActive="active" class="home_link">
					<mat-icon>insights</mat-icon>
					Estratégias ativas (necessário estar logado)
				</a>
			</li>

      <li class="home_desktop_list_item">
				<a routerLink="/inv-list" routerLinkActive="active" class="home_link">
					<mat-icon>description</mat-icon>
					Pesquisar ativos financeiros, empresas, setores
				</a>
			</li>

      <li class="home_desktop_list_item">
				<a routerLink="/inv-chart" routerLinkActive="active" class="home_link">
					<mat-icon>compare_arrows</mat-icon>
					Comparar ativos financeiros
				</a>
			</li>

      <li class="home_desktop_list_item">
				<a routerLink="/destaques-list" routerLinkActive="active" class="home_link">
					<mat-icon>featured_play_list</mat-icon>
					Veja os destaques do momento
				</a>
			</li>

      <li class="home_desktop_list_item">
				<a routerLink="/ddc-list" routerLinkActive="active" class="home_link">
					<mat-icon>folder</mat-icon>
					Documentos publicados pelas empresas
				</a>
			</li>

      <li class="home_desktop_list_item">
				<a routerLink="/gcia-list" routerLinkActive="active" class="home_link">
					<mat-icon>account_balance_wallet</mat-icon>
					Minhas Carteiras (necessário estar logado)
				</a>
			</li>
		</ul>
	</div>
</div>



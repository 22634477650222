<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Comparativo de Ativos</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Ativos
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_chart_tickers">
			<div class="grid_item_ticker_chart_tickers_list">
				<table mat-table [dataSource]="_tickerListDataSource" #itemGrCrctInvAcaoListSort="matSort" matSort>
					<ng-container matColumnDef="ticker" sticky>
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Ticker </th>
						<td mat-cell *matCellDef="let element">
							<a [routerLink]="" routerLinkActive="active" (click)="detailTicker( element.getCrctInv().cdInv )" class="linkInTable">
								<span [ngClass]="{'text_riscado' : this.isTickerExistInManager( element ) === false}">
									{{element.getCrctInv().nmInv}}
								</span>
							</a>
						</td>
					</ng-container>

					<ng-container matColumnDef="tipInv">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.getCrctInv().tipInv.nmTipInv}} </td>
					</ng-container>

					<ng-container matColumnDef="empr">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
						<td mat-cell *matCellDef="let element"> 
							<a [routerLink]="" routerLinkActive="active" (click)="detailEmpr( element.getCrctInv().empr.cdEmpr )" class="label_value linkInTable"> 
								<ng-container *ngIf="element.getCrctInv().empr.nmAbrvEmpr;else nmEmprTemplate">
									{{element.getCrctInv().empr.nmAbrvEmpr}}
								</ng-container>
								<ng-template #nmEmprTemplate>
									{{element.getCrctInv().empr.nmEmpr}}
								</ng-template>
							</a>
						</td>
					</ng-container>

					<ng-container matColumnDef="setor">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Setor </th>
						<td mat-cell *matCellDef="let element"><span *ngIf="element.getCrctInv().empr.segmEcnm != null"> {{element.getCrctInv().empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}</span> </td>
					</ng-container>

					<ng-container matColumnDef="options">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options">
								<button mat-icon-button color="accent" (click)="this.removeTickerOfList( element )">
									<mat-icon matTooltip="Excluir ativo da lista de selecionados.">delete</mat-icon>
								</button>

								<button *ngIf="this.isTickerExistInManager( element ) === true" mat-icon-button color="accent" (click)="this.hiddenTickerOfList( element )">
									<mat-icon matTooltip="Deixar de mostrar o ativo no gráfico.">visibility_off</mat-icon>
								</button>

								<button *ngIf="this.isTickerExistInManager( element ) === false" mat-icon-button color="accent" (click)="this.showTickerOfList( element )">
									<mat-icon matTooltip="Mostrar ativo no gráfico.">visibility</mat-icon>
								</button>
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_tickerListDisplayedColumns"></tr>
					<tr mat-row        *matRowDef="let row; columns: _tickerListDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this._tickerListPaginatorPageSize"
								[pageIndex]="this._tickerListPaginatorPageIndex"
								(page)="onChangeTickerListPageSize($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div class="grid_item_ticker_chart_tickers_grupos">
				<span *ngIf="this.isAllowedAddGrCrctInv()" class="column_options">
					<mat-form-field>
						<mat-label>Carteira:</mat-label>
						<mat-select matNativeControl 
									(selectionChange)="onChangeGrCrctInvSelected( $event )"
									matTooltip="Selecione uma Carteira para visualizar os seus Ativos." 
									[(value)]="_cdGrCrctInvSelected">
							<mat-option *ngFor="let grCrctInv of this._grCrctInvAcaoList" 
										[value]="grCrctInv.cdGrCrctInvAcao" >
								{{ grCrctInv.nmGrCrctInvAcao }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<button mat-icon-button *ngIf="this.isExistGrCrctInvSelected()" class="column_options_element" color="accent" (click)="this.openDialogEditGrCrctInv()">
						<mat-icon matTooltip="Editar a Carteira selecionada.">settings</mat-icon>
					</button>

					<button mat-icon-button class="column_options_element" color="accent" (click)="this.openDialogAddGrCrctInv()">
						<mat-icon matTooltip="Criar nova Carteira.">add_circle</mat-icon>
					</button>
				</span>
			</div>

			<div class="grid_item_ticker_chart_tickers_buttons">
				<span class="column_options">
					<button mat-icon-button color="accent" (click)="this.showAllTickerOfList()">
						<mat-icon matTooltip="Mostrar todos ativos no gráfico.">visibility</mat-icon>
					</button>

					<button mat-icon-button color="accent" (click)="this.hiddenAllTickerOfList( true )">
						<mat-icon matTooltip="Deixar de mostrar todos ativos no gráfico.">visibility_off</mat-icon>
					</button>

					<button mat-icon-button color="accent" (click)="openDialogAddTicker()">
						<mat-icon matTooltip="Adicionar ativo à lista">playlist_add</mat-icon>
					</button>
				</span>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Gráfico
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_chart_cotcAcaoList">
			<div class="grid_item_ticker_chart_cotcAcaoList_filter_from">
				<mat-form-field>
					<mat-label>A Partir De:</mat-label>

					<input matInput 
						[matDatepicker]="pickerFrom" 
						formControlName="dtCotcFrom"
						(ngModelChange)="updateDtCotcFrom( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
					<mat-datepicker #pickerFrom></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_item_ticker_chart_cotcAcaoList_filter_end">
				<mat-form-field>
					<mat-label>Até:</mat-label>

					<input matInput 
						[matDatepicker]="pickerEnd" 
						formControlName="dtCotcEnd"
						(ngModelChange)="updateDtCotcEnd( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
					<mat-datepicker #pickerEnd></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_item_ticker_chart_cotcAcaoList_buttonOpenInfos">
				<button mat-icon-button *ngIf="this.isExistTickerToChart()" color="accent" (click)="toggleInfosOfTicker()" ><mat-icon matTooltip="Controlar informações a serem apresentadas no gráfico.">addchart</mat-icon></button>
			</div>

			<div class="grid_item_ticker_chart_cotcAcaoList_crctInvChips">
				<mat-chip-list  *ngIf="this.isShowChart()" class="mat-chip-list-inline"
								[selectable]="true" 
								[multiple]="true" >
					<mat-chip 	*ngFor="let chip of this._tickerListDataSource.data"
								[selected]="chip.isControlEnabled() == true"
								(click)="onSelectedControlCrctInv( chip )"
								[matTooltip]="this.getTooltipOfControl( chip )"
								matTooltipClass="multline-tooltip" >
						{{ chip.getCrctInv().nmInv }}
						<span *ngIf="chip.getItemGrupoCrctInvAcao().tipTndc != null">
							<mat-icon *ngIf="chip.getItemGrupoCrctInvAcao().tipTndc.sglTipTndc === 'A'" class="blue-icon"  matTooltip="Ativo com tendência de alta, conforme informado pelo usuário.">north_east</mat-icon>
							<mat-icon *ngIf="chip.getItemGrupoCrctInvAcao().tipTndc.sglTipTndc === 'B'" class="red-icon"   matTooltip="Ativo com tendência de baixa, conforme informado pelo usuário.">south_east</mat-icon>
							<mat-icon *ngIf="chip.getItemGrupoCrctInvAcao().tipTndc.sglTipTndc === 'N'" class="green-icon" matTooltip="Ativo em lateralidade, conforme informado pelo usuário.">east</mat-icon>
						</span>
					</mat-chip>
				</mat-chip-list>
			</div>

			<div class="grid_item_ticker_chart_cotcAcaoList_actionsTndc" *ngIf="this.isShowChart() && this.getCdGrCrctInvSelected() > 0">
				<button mat-icon-button color="accent" (click)="this.onClickTndcAltaCrctInvSelected()"        ><mat-icon [ngClass]="this.isItemGrupoCrctInvAcaoSelectedOfTndc( 'A' ) == true ? 'blue-icon  circle-icon' : 'blue-icon' " matTooltip="Informa que o Ativo está com tendência de alta.">north_east</mat-icon></button>
				<button mat-icon-button color="accent" (click)="this.onClickTndcBaixaCrctInvSelected()"       ><mat-icon [ngClass]="this.isItemGrupoCrctInvAcaoSelectedOfTndc( 'B' ) == true ? 'red-icon   circle-icon' : 'red-icon'  " matTooltip="Informa que o Ativo está com tendência de baixa.">south_east</mat-icon></button>
				<button mat-icon-button color="accent" (click)="this.onClickTndcLateralidadeCrctInvSelected()"><mat-icon [ngClass]="this.isItemGrupoCrctInvAcaoSelectedOfTndc( 'N' ) == true ? 'green-icon circle-icon' : 'green-icon'" matTooltip="Informa que o Ativo está em lateralidade">east</mat-icon></button>
			</div>

			<div class="grid_item_ticker_chart_cotcAcaoList_chart">
				<mat-sidenav-container (backdropClick)="this.onCloseInfosOfTickers()">
					<mat-sidenav-content class="sidenav-chart-infoTickers" >
						<div #divCotcChart>
							<google-chart 	#chart
											*ngIf="this.isShowChart() === true"
											[title]="_cotcChart.title"
											[type]="_cotcChart.type"
											[data]="_cotcChart.data"
											[columns]="_cotcChart.columns"
											[options]="_cotcChart.options"
											(error)="onErrorChart($event)"
							>
							</google-chart>
							<div *ngIf="this.isShowChart() === false" class="label_list_empty" style="min-height: 10em">
								Selecione pelo menos um <u>ativo</u> e uma <u>informação</u> para que o gráfico seja apresentado.
							</div>
						</div>
					</mat-sidenav-content>

					<mat-sidenav #sidenav position="end" (keydown.escape)="this.onCloseInfosOfTickers()" disableClose >
						<div class="grid_container_ticker_chart_infCrctInv">
							<div class="grid_item_ticker_chart_infCrctInv_list">
								<mat-form-field>
									<mat-label>Informação:</mat-label>
									<mat-select matNativeControl (selectionChange)="onSelectedInfCrctInvSelected( $event )" formControlName="infCrctInvSelected">
										<mat-option *ngFor="let infCrctInv of this._infCrctInvList" [value]="infCrctInv.getNmInfCrctInv()" >{{ infCrctInv.getNmInfCrctInv() }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div *ngIf="this._infCrctInvPerdcList != null && this._infCrctInvPerdcList.length > 0" class="grid_item_ticker_chart_infCrctInv_perdcList">
								<mat-form-field>
									<mat-label>Período:</mat-label>
									<mat-select matNativeControl formControlName="infCrctInvPerdcSelected">
										<mat-option *ngFor="let infCrctInvPerdc of this._infCrctInvPerdcList" [value]="infCrctInvPerdc" >{{ infCrctInvPerdc }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div *ngIf="this._infCrctInvTipViewList != null && this._infCrctInvTipViewList.length > 1" class="grid_item_ticker_chart_infCrctInv_tipViewList">
								<mat-form-field>
									<mat-label>Mostrar:</mat-label>
									<mat-select matNativeControl formControlName="infCrctInvTipViewSelected">
										<mat-option *ngFor="let infCrctInvTipView of this._infCrctInvTipViewList" [value]="infCrctInvTipView" >{{ infCrctInvTipView.toString() }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div class="grid_item_ticker_chart_infCrctInv_buttonAdd">
								<button mat-icon-button color="accent" (click)="this.onAddInfCrctInvSelected()" ><mat-icon matTooltip="Adicionar informação à lista de selecionadas.">add</mat-icon></button>
							</div>

							<div class="grid_item_ticker_chart_infCrctInv_list_selected">
								Informações Selecionadas:
								<mat-chip-list class="mat-chip-list-stacked">
									<mat-chip 	*ngFor="let chip of this._infCrctInvSelectedList" selected
												[color]="this.getColorTipInfTicker( chip )"
												[removable]="true"
												(removed)="onRemoveInfCrctInvSelected( chip )" >
										{{chip.getNmInfCrctInvViewed()}}
										<mat-icon matChipRemove>cancel</mat-icon>
									</mat-chip>
								</mat-chip-list>
							</div>
						</div>
					</mat-sidenav>
				</mat-sidenav-container>
			</div>
		</div>
	</mat-expansion-panel>
</form>
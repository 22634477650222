import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { DocDmntCntb } from 'src/app/_models/ddc';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';


@Injectable({
  providedIn: 'root'
})
export class DdcService extends HttpBasicService< DocDmntCntb, DocDmntCntb >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista documentos de demonstração contabil.
   */
  list( cdEmpr: number ): Promise< DocDmntCntb[] >{
    return super.listGeneric( '/DDC/LIST', { cd_empr: cdEmpr }, false );
  }

  /**
   * Lista documentos de demonstração contabil a partir da data de publicação especificada.
   */
  listFromDtPblcc( dtPblccDocDmntCntb: Date, isOnlyEmprMonitored: boolean ): Promise< DocDmntCntb[] >{
    return this.listBetweenDtPblcc( dtPblccDocDmntCntb, null, isOnlyEmprMonitored );
  }

  /**
   * Lista documentos de demonstração contabil entre as datas de publicação especificadas.
   */
  listBetweenDtPblcc( dtPblccDocDmntCntbStart: Date, dtPblccDocDmntCntbEnd: Date, isOnlyEmprMonitored: boolean ): Promise< DocDmntCntb[] >{
    const dtPblccDocDmntCntbSgbdStartFormat = this.datePipe.transform( dtPblccDocDmntCntbStart, 'yyyy-MM-dd' );
    const dtPblccDocDmntCntbSgbdEndFormat   = dtPblccDocDmntCntbEnd == null ? null : this.datePipe.transform( dtPblccDocDmntCntbEnd  , 'yyyy-MM-dd' );

    let reqData = { dt_pblcc_doc_dmnt_cntb: dtPblccDocDmntCntbSgbdStartFormat };

    if( dtPblccDocDmntCntbSgbdEndFormat != null ){
      reqData[ 'dt_pblcc_doc_dmnt_cntb_end' ] = dtPblccDocDmntCntbSgbdEndFormat;
    }

    if( isOnlyEmprMonitored ){
      reqData[ 'cd_tip_est_atlz_doc_dmnt_cntb' ] = 5;
    }

    return super.listGeneric( '/DDC/LIST', reqData, false );
  }
}

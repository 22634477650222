import { Component              } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder            } from '@angular/forms';

import { LoginComponent }   from '../login.component';

import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { SessionService } from '../../../_services/session/session.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';

@Component({
  selector: 'app-login-desktop',
  templateUrl: './login-desktop.component.html',
  styleUrls: ['./login-desktop.component.css']
})
export class LoginDesktopComponent extends LoginComponent {
    constructor( formBuilder: FormBuilder,
                 route: ActivatedRoute,
                 router: Router,
                 protected sessionService: SessionService,
                 protected authenticationService: AuthenticationService,
                 protected prmUsrService: PrmUsrService ) { 
        super( formBuilder, route, router, sessionService, authenticationService, prmUsrService );
    }
}
import { Component, OnInit, ViewChild       } from '@angular/core';
import { DomSanitizer                       } from '@angular/platform-browser';
import { NavigationExtras, Router           } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { MatTableDataSource                 } from '@angular/material/table';
import { MatPaginator     } from '@angular/material/paginator';

import { AplicationStateService             } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService              } from '../../../_services/authentication/authentication.service';
import { EttgstService } from 'src/app/_services/ettgst/ettgst.service';
import { AbstractMainComponent } from '../../abstract-main.component';


import { Ettgst } from 'src/app/_models/ettgst';
import { MatSnackBar } from '@angular/material/snack-bar';


export abstract class EttgstListComponent extends AbstractMainComponent{
  public displayedColumns: string[] = [ 'sglEttgst', 'estrtgPend', 'estrtgIns', 'estrtgSuc', 'estrtgInv', 'estrtgTtl', 'options' ];
  public dataSourceEttgstList;
  @ViewChild( MatPaginator, {static: true} ) paginator: MatPaginator;

  constructor( protected router               : Router                ,
               protected formBuilder          : FormBuilder           ,
               protected dialog               : MatDialog             ,
               sanitizer                      : DomSanitizer          ,
               aplicationStateService         : AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar             : MatSnackBar           ,
               protected ettgstService        : EttgstService ) { 
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    //console.log( 'EttgstListComponent.ngOnInit()...' );

    super.ngOnInit();
    this.refresh();

    //console.log( "dataSourceEttgstList: " + this.dataSourceEttgstList );
  }

  refresh(){
    this.ettgstService.list()
      .then( dataResp => {
        //console.log( 'EttgstService.list(): ' + dataResp );

        this.dataSourceEttgstList = new MatTableDataSource< Ettgst >( dataResp );
        this.dataSourceEttgstList.paginator = this.paginator;
      });
  }

  detailEttgst( sglEttgst: string ){
    //console.log( 'EttgstListComponent.detailEttgst( ' + sglEttgst + ' )...' );

    const queryParams = {
      sglEttgst: sglEttgst
    };

    this.goToPage( '/ettgst-detail', queryParams );

    //console.log( 'MarcaListComponent.detailMarca().' );
  }
}

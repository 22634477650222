import { MovimentacaoGrupoCrctInvAcao } from 'src/app/_models/grCrctInv/grCrctInv';

export class ControlLoadMvtc{
	public informedValues: InformeValueToLoadMvtc[];
	public mvtcGr: MovimentacaoGrupoCrctInvAcao;
	public tipEstLoadMvtc: TipEstLoadMvtcEnum;
	public msgLoadMvtc: any;
}


export class InformeValueToLoadMvtc{
	public informedValue: string;
	public exceptionValue: any;
	public isValueConverted: boolean;
}


export enum TipEstLoadMvtcEnum{
	//Aguardando que o usuário solicite a gravação da movimentação.
	WAITING_RECORD = 0,

	//Usuário cancelou a gravação da movimentação.
	RECORD_CANCELED = 1,

	//Solicitada a gravacao da movimentacao
	RECORDING = 2,

	//A gravação da movimentação falhou.
	RECORD_FAILED = 3,

	//A gravação da movimentação foi registrada com sucesso.
	RECORD_SUCESS = 4,
}

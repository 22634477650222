<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="grid_container_login">
    <div class="grid_item_login_header">
      Informe seu usuário e senha para acessar o Invest Rápido.
    </div>

    <div class="grid_item_login_user">
      <div class="label_chave">
        Usuário:
      </div>

      <div>
        <input type="text" class="input" formControlName="username" required minlength="0" placeholder="" >
      </div>
    </div>

    <div class="grid_item_login_pass">
      <div class="label_chave">
        Senha:
      </div>

      <div>
        <input type="password" class="input" formControlName="password" placeholder="" >
      </div>
    </div>

    <div class="grid_item_login_forgotPassword">
      <a routerLink="/login-forgot" routerLinkActive="active" class="label_forgotPassword">Esqueceu usuário ou senha?</a>
    </div>

    <div class="grid_item_login_button">
      <button type="submit" class="button">Login</button>
    </div>

    <div class="grid_item_login_newUser">
      <div class="label_newUser">Acessando pela primeira vez?</div>
      <div class="label_newUser">Cadastre-se clicando <a routerLink="/login-add" routerLinkActive="active" class="label_newUserLink">aqui</a>.</div>
      <div class="label_newUser">É gratuito.</div>
    </div>
  </div>
</form>

import { ElementRef, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router         } from '@angular/router';
import { FormBuilder, FormControl       } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { CrctInvDetail } from '../../../_models/inv';

import { AplicationStateService } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { InvService                  } from '../../../_services/inv/inv.service';
import { CotcService } from 'src/app/_services/cotc/cotc.service';
import { HstCotcAcao } from 'src/app/_models/cotc';
import { MatTableDataSource } from '@angular/material/table';

import { ChartErrorEvent, ChartMouseLeaveEvent, ChartMouseOverEvent, ChartSelectionChangedEvent, ChartType, Column, GoogleChartComponent, ScriptLoaderService, getPackageForChart, ChartWrapperComponent } from 'angular-google-charts';

import { AbstractMainComponent  } from '../../abstract-main.component';
import { DdcService             } from 'src/app/_services/ddc/ddc.service';
import { DocDmntCntb            } from 'src/app/_models/ddc';
import { DatePipe               } from '@angular/common';
import { IndTcnService } from 'src/app/_services/indTcn/indTcn.service';
import { HstIndTcn } from 'src/app/_models/indTcn';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange    } from '@angular/material/radio';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { TipInfRstdEmprJoinInfRstdEmpr } from 'src/app/_models/rstdEmpr';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PgmtPrvt } from '@models/prvt';
import { PrvtService } from '@services/prvt/prvt.service';
import { ControlLoad } from '@models/control/controlLoad';

export abstract class TickerDetailComponent extends AbstractMainComponent{
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_COTC_DT_INICIO  = 'InvDetail_cotcDtInicio';
  private PRM_USR_COTC_DT_FIM     = 'InvDetail_cotcDtFim';

  //Controls de cada seção da pagina.
  public _controlPrvt                : ControlLoad;


  public _crctInv: CrctInvDetail;

  public _cotcAcaoDisplayedColumns: string[] = [ 'dtCotc', 'prcAbert', 'prcMin', 'prcMax', 'prcFech', 'varPrcFech', 'volNegc', 'options' ];
  public _cotcAcaoDataSource: MatTableDataSource< HstCotcAcao >;

  public _prvtDisplayedColumns: string[] = [ 'dtBase', 'tipPrvt', 'vlPrvt', 'dyMensal', 'dtPgmt' ];
  public _prvtDataSource: MatTableDataSource< PgmtPrvt >;

  public _ddcDisplayedColumns: string[] = [ 'dtPblccDocDmntCntb', 'sglDocDmntCntb', 'options' ];
  public _ddcDataSource: MatTableDataSource< DocDmntCntb >;

  public isCotcAcaoListEmpty: boolean;
  public isDdcListEmpty: boolean = true;

  public _dadosPeriodo = { cotcMin: null,
                           cotcMax: null,
                           varPerc: null,
                           volMed : null,
                           volMax : null  };

  public _hstIndTcn = {};

  public _tipInfRstdDisplayedColumns: string[] = [ 'tipInfRstdEmpr', 'vlRstdEmpr' ];
  public _tipInfRstdDataSource: MatTableDataSource< TipInfRstdEmprJoinInfRstdEmpr >;

  public _form;

  public _typeRangeIndTcnRsi: 0;
  public _typeRangeIndTcnRsiList = [ { value: 0, label: 'Fixo'    , checked: true , disabled: true }, 
                                     { value: 1, label: 'Variável', checked: false, disabled: true } ];

  private readonly chartPackage = getPackageForChart( ChartType.BarChart );

  @ViewChild( 'divCotcChart', { read: ElementRef } ) 
  _divCotcChartElementView: ElementRef< GoogleChartComponent >;

  public _cotcChart = {
    title: '',
    type: ChartType.ColumnChart,
    data: [],
    columns: [],
    options: { legend: 'none',
               titlePosition: 'in',
               titleTextStyle: { fontSize: '0.8em', italic: true },
               candlestick: { fallingColor: { strokeWidth: 0, fill: '#a52714' }, // red
                              risingColor: { strokeWidth: 0, fill: '#0f9d58' }   // green
                            },
               width: '0',
               height: '600',
               isStacked: true,
               tooltip: { isHtml: true },
               interpolateNulls: true,
               hAxis: { title: 'Data' },
               vAxes: { 0: { logScale: false, title: 'R$  ', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'left' , gridlines: { count: -1 } },
                        1: { logScale: false, title: '    ', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none' , gridlines: { count:  0 } },
                        2: { logScale: false, title: '%   ', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'right', gridlines: { count:  0 } },
                        3: { logScale: false, title: '    ', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none ', gridlines: { count:  0 } },
                        4: { logScale: false, title: 'Obv ', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none ', gridlines: { count:  0 } },
                        5: { logScale: false, title: 'Mom ', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none ', gridlines: { count:  0 } },
                        6: { logScale: false, title: 'Stoc', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none ', gridlines: { count:  0 } },
                        7: { logScale: false, title: 'Pe'  , format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none ', gridlines: { count:  0 } },
                        8: { logScale: false, title: 'Pvpa', format: 'decimal', viewWindow: { min: 0, max:   0 }, textPosition: 'none ', gridlines: { count:  0 } }
                      },
               seriesType: "bars",
               series: {},
               chartArea: { left: '10%', right: '10%', top: '5%', bottom: '5%', width: '78%' }
             }
  };

  constructor( protected router                 : Router                ,
               protected activatedRoute         : ActivatedRoute        ,
               protected sanitizer              : DomSanitizer          ,
               protected formBuilder            : FormBuilder           ,
               protected dialog                 : MatDialog             ,
               protected datepipe               : DatePipe              ,
               protected aplicationStateService : AplicationStateService,
               protected authenticationService  : AuthenticationService ,
               protected prmUsrService          : PrmUsrService         ,
               protected snackBar               : MatSnackBar           ,
               protected invService             : InvService            ,
               protected cotcService            : CotcService           ,
               protected ddcService             : DdcService            ,
               protected indTcnService          : IndTcnService         ,
               protected rstdEmprService        : RstdEmprService       ,
               protected prvtService            : PrvtService           ,
               protected loaderService          : ScriptLoaderService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnChanges( changes: SimpleChanges ){
  }

  ngOnInit() {
    this._controlPrvt = new ControlLoad( 'prvt' );

    this.loaderService.loadChartPackages( this.chartPackage ).subscribe(() => {
      //console.log( 'chartPackages loaded.' );
    });

    this._form = this.createForm();

    this.activatedRoute.queryParams.subscribe( params => {
        //console.log( 'activatedRoute.queryParams.subscribe...' );
        //console.log( 'params: ' + Object.keys( params ) );

        const cdInv = params.cdInv ? params.cdInv : null;
        const nmInv = params.nmInv ? params.nmInv : null;

        this.detail( cdInv, nmInv );
      }
    );

    //console.log( 'TickerDetailComponent.ngOnInit().' );
  }

  ngAfterViewChecked(){
    //console.log( 'TickerDetailComponent.ngAfterViewChecked()...' );

    if( this._divCotcChartElementView != null ){
      const elRef: ElementRef = this._divCotcChartElementView;
      const widthOfDivCotcChart = elRef.nativeElement.offsetWidth;

      //Altera o tamanho do grafico
      this._cotcChart.options.width = widthOfDivCotcChart;
    }
  }

  private createForm(){
    //Verifica se usuario possui parametro armazenado.
    const cotcDtInicioOfPrmUsr   = this.prmUsrService.getValue( this.PRM_USR_COTC_DT_INICIO );
    const cotcDtFimOfPrmUsr      = this.prmUsrService.getValue( this.PRM_USR_COTC_DT_FIM    );

    //Define valores iniciais.
    let dtCotcFrom = cotcDtInicioOfPrmUsr == null ? new Date() : new Date( cotcDtInicioOfPrmUsr );
    dtCotcFrom.setDate( dtCotcFrom.getDate() - ( cotcDtInicioOfPrmUsr == null ? 90 : 0 ) );

    let dtCotcEnd = cotcDtFimOfPrmUsr    == null ? new Date() : new Date( cotcDtFimOfPrmUsr );
    dtCotcEnd.setDate( dtCotcEnd.getDate() - 0 );

    return this.formBuilder.group(
      { nmInv            : new FormControl( { value: '', disabled: true } ),
        dtCotcFrom       : dtCotcFrom,
        dtCotcEnd        : dtCotcEnd ,
        isShowCotcInTable   : false    ,
        isShowIndTcnCotc    : true     ,
        isShowIndTcnCandle  : false    ,
        isShowIndTcnVol     : true     ,
        isShowIndTcnSma     : false    ,
        isShowIndTcnEma     : false    ,
        isShowIndTcnMom     : false    ,
        isShowIndTcnRsi     : false    ,
        idTypeRangeIndTcnRsi: 0        ,
        isShowIndTcnEfi     : false    ,
        isShowIndTcnObv     : false    ,
        isShowIndTcnStoc    : false    ,
        isShowIndTcnPe      : false    ,
        isShowIndTcnPvpa    : false
      }
    );
  }

  private getDtCotcFromSelected(){
    return this._form.controls[ 'dtCotcFrom' ].value;
  }

  private setDtCotcFromSelected( dtCotcFrom: string ){
    this._form.patchValue( { dtCotcFrom: dtCotcFrom } );
  }

  private getDtCotcEndSelected(){
    return this._form.controls[ 'dtCotcEnd' ].value;
  }

  private setDtCotcEndSelected( dtCotcEnd: string ){
    this._form.patchValue( { dtCotcEnd: dtCotcEnd } );
  }

  public isShowCotcInTableSelected(){
    return this._form.controls[ 'isShowCotcInTable' ].value == true;
  }

  private setShowCotcInTableSelected( isShowCotcInTableValue: boolean ){
    this._form.patchValue( { isShowCotcInTable: isShowCotcInTableValue } );
  }

  public isShowIndTcnCotcSelected(){
    return this._form.controls[ 'isShowIndTcnCotc' ].value == true;
  }

  private setShowIndTcnCotcSelected( isShowIndTcn: boolean ){
    this._form.patchValue( { isShowIndTcnCotc: isShowIndTcn } );
  }

  public isShowIndTcnCandleSelected(){
    return this._form.controls[ 'isShowIndTcnCandle' ].value == true;
  }

  public isShowIndTcnVolSelected(){
    return this._form.controls[ 'isShowIndTcnVol' ].value == true;
  }

  public isShowIndTcnSmaSelected(){
    return this._form.controls[ 'isShowIndTcnSma' ].value == true;
  }

  public isShowIndTcnEmaSelected(){
    return this._form.controls[ 'isShowIndTcnEma' ].value == true;
  }

  public isShowIndTcnMomSelected(){
    return this._form.controls[ 'isShowIndTcnMom' ].value == true;
  }

  public isShowIndTcnRsiSelected(){
    return this._form.controls[ 'isShowIndTcnRsi' ].value == true;
  }

  public isTypeRangeIndTcnRsiVariable(){
    return this.isShowIndTcnRsiSelected() === true && this.getIdTypeRangeIndTcnRsi() === 1;
  }

  private getIdTypeRangeIndTcnRsi(){
    return this._form.controls[ 'idTypeRangeIndTcnRsi' ].value;
  }

  private setIdTypeRangeIndTcnRsi( idTypeRange: number ){
    this._form.patchValue( { idTypeRangeIndTcnRsi: idTypeRange } );
    this._typeRangeIndTcnRsiList[ idTypeRange ].checked = true;
  }

  private enableAllTypeRangeIndTcnRsi(){
    this.changeAllTypeRangeIndTcnRsi( true );
  }

  private disableAllTypeRangeIndTcnRsi(){
    this.changeAllTypeRangeIndTcnRsi( false );
  }

  private changeAllTypeRangeIndTcnRsi( isEnabled: boolean ){
    const amountTypes = this._typeRangeIndTcnRsiList.length;

    for( let index = 0; index < amountTypes; index++ ){
      this._typeRangeIndTcnRsiList[ index ].disabled = !isEnabled;
    }
  }

  public isShowIndTcnEfiSelected(){
    return this._form.controls[ 'isShowIndTcnEfi' ].value == true;
  }

  public isShowIndTcnObvSelected(){
    return this._form.controls[ 'isShowIndTcnObv' ].value == true;
  }

  public isShowIndTcnStocSelected(){
    return this._form.controls[ 'isShowIndTcnStoc' ].value == true;
  }

  public isShowIndTcnPeSelected(){
    return this._form.controls[ 'isShowIndTcnPe' ].value == true;
  }

  public isShowIndTcnPvpaSelected(){
    return this._form.controls[ 'isShowIndTcnPvpa' ].value == true;
  }

  public isNeedUpdateIndTcnCotc(){
    return this.isShowIndTcnCotcSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'COTC' ] == null || this._hstIndTcn[ 'COTC' ].length === 0 );
  }

  public isNeedUpdateIndTcnCandle(){
    return this.isShowIndTcnCandleSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'CANDLE' ] == null || this._hstIndTcn[ 'CANDLE' ].length === 0 );
  }

  public isNeedUpdateIndTcnVol(){
    return this.isShowIndTcnVolSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'VOL' ] == null || this._hstIndTcn[ 'VOL' ].length === 0 );
  }

  public isNeedUpdateIndTcnSma(){
    return this.isShowIndTcnSmaSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'SMA' ] == null || this._hstIndTcn[ 'SMA' ].length === 0 );
  }

  public isNeedUpdateIndTcnEma(){
    return this.isShowIndTcnEmaSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'EMA' ] == null || this._hstIndTcn[ 'EMA' ].length === 0 );
  }

  public isNeedUpdateIndTcnMom(){
    return this.isShowIndTcnMomSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'MOM' ] == null || this._hstIndTcn[ 'MOM' ].length === 0 );
  }

  public isNeedUpdateIndTcnRsi(){
    return this.isShowIndTcnRsiSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'RSI' ] == null || this._hstIndTcn[ 'RSI' ].length === 0 );
  }

  protected isNeedUpdateIndTcnRsiTypeRangeVariable(){
    return this.isShowIndTcnRsiSelected() === true && this.isTypeRangeIndTcnRsiVariable() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'RSI_RANGE_INF' ] == null || this._hstIndTcn[ 'RSI_RANGE_INF' ].length === 0 || this._hstIndTcn[ 'RSI_RANGE_SUP' ] == null || this._hstIndTcn[ 'RSI_RANGE_SUP' ].length === 0 );
  }

  public isNeedUpdateIndTcnEfi(){
    return this.isShowIndTcnEfiSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'EFI' ] == null || this._hstIndTcn[ 'EFI' ].length === 0 );
  }

  public isNeedUpdateIndTcnObv(){
    return this.isShowIndTcnObvSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'OBV' ] == null || this._hstIndTcn[ 'OBV' ].length === 0 );
  }

  public isNeedUpdateIndTcnStoc(){
    return this.isShowIndTcnStocSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'STOC' ] == null || this._hstIndTcn[ 'STOC' ].length === 0 );
  }

  public isNeedUpdateIndTcnPe(){
    return this.isShowIndTcnPeSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'P/E' ] == null || this._hstIndTcn[ 'P/E' ].length === 0 );
  }

  public isNeedUpdateIndTcnPvpa(){
    return this.isShowIndTcnPvpaSelected() === true && this._hstIndTcn != null && ( this._hstIndTcn[ 'P/VPA' ] == null || this._hstIndTcn[ 'P/VPA' ].length === 0 );
  }

  public isExistPgmtPrvt(){
    return this._prvtDataSource != null && this._prvtDataSource.data != null && this._prvtDataSource.data.length > 0;
  }

  public isExistLastRstdEmpr(){
    return this._tipInfRstdDataSource != null;
  }

  public isPerdcAnualOfLastRstdEmpr(){
    return this._tipInfRstdDataSource != null && this._tipInfRstdDataSource.data[0].infRstdEmprArray[0].perdc.sglPerdc === 'ANUAL';
  }

  public getNmPerdcOfLastRstdEmpr(){
    return this._tipInfRstdDataSource == null ? null : this._tipInfRstdDataSource.data[0].infRstdEmprArray[0].perdc.nmPerdc;
  }

  public getAaRefOfLastRstdEmpr(){
    return this._tipInfRstdDataSource == null ? null : this._tipInfRstdDataSource.data[0].infRstdEmprArray[0].aaRefPerdc;
  }

  public getNrRefOfLastRstdEmpr(){
    return this._tipInfRstdDataSource == null ? null : this._tipInfRstdDataSource.data[0].infRstdEmprArray[0].nrRefPerdc;
  }

  public getDtPblccRefOfLastRstdEmpr(){
    return this._tipInfRstdDataSource == null ? null : this._tipInfRstdDataSource.data[0].infRstdEmprArray[0].dtPblccRef;
  }

  onSubmit(){
    console.log( 'onSubmit()...' );

    console.log( 'onSubmit().' );
  }

  protected detail( cdInv: number, nmInv: string ){
    if( cdInv != null ){
      this.detailByCd( cdInv );
    }
    else{
      if( nmInv != null ){
        this.detailByNm( nmInv );
      }
    }
  }

  protected detailByCd( cdInv: number ){
    this.invService.detail( cdInv )
      .then( detailObj => {
        this._crctInv = detailObj;

        const nmInv = this._crctInv.crctInv.nmInv;
        this.aplicationStateService.setTitle( nmInv );

        const empr = detailObj.crctInv == null ? null : detailObj.crctInv.empr;
        const cdEmpr = empr == null ? 0 : empr.cdEmpr;

        //Obtem lista de cotacoes e indicadores do investimento.
        this.loadCotcAndHstIndTcnAcao();

        if( cdEmpr != null ){
          //Obtem o ultimo resultado da empresa.
          this.loadLastRstdEmpr( cdEmpr );

          //Obtem lista de documentos vinculados a empresa.
          this.loadDdc( detailObj.crctInv.empr.cdEmpr );
        }
        else{
          console.log( 'empr is null or cdEmpr less or equal at zero' );
        }
      });
  }


  protected detailByNm( nmInv: string ){
    this.invService.detailTicker( nmInv )
      .then( detailObj => {
        this._crctInv = detailObj;

        const nmInv = this._crctInv.crctInv.nmInv;
        this.aplicationStateService.setTitle( nmInv );

        const empr = detailObj.crctInv == null ? null : detailObj.crctInv.empr;
        const cdEmpr = empr == null ? 0 : empr.cdEmpr;

        //Obtem lista de cotacoes e indicadores do investimento.
        this.loadCotcAndHstIndTcnAcao();

        if( cdEmpr != null ){
          //Obtem o ultimo resultado da empresa.
          this.loadLastRstdEmpr( cdEmpr );

          //Obtem lista de documentos vinculados a empresa.
          this.loadDdc( detailObj.crctInv.empr.cdEmpr );
        }
        else{
          console.log( 'empr is null or cdEmpr less or equal at zero' );
        }
      });
  }

  public loadCotcAndHstIndTcnAcao(){
    //Limpa os indicadores.
    this._hstIndTcn = {};

    //Obtem as informacoes necessarias para realizar as atualizacoes.
    const cdInv = this._crctInv.crctInv.cdInv;
    let dtCotcFromSelected = this.getDtCotcFromSelected();
    let dtCotcEndSelected = this.getDtCotcEndSelected();

    //Atualiza as cotacoes e indicadores.
    this.loadCotcAcao( cdInv, dtCotcFromSelected, dtCotcEndSelected, 900 );
    this.loadHstIndTcn( cdInv, dtCotcFromSelected, dtCotcEndSelected );
  }  

  private loadCotcAcao( cdInv: number, dtCotcStart: Date, dtCotcEnd: Date, qtdLimit: number ){
    this.cotcService.listBetweenDatesWithLimit( cdInv, dtCotcStart, dtCotcEnd, qtdLimit, null )
      .then( cotcAcaoList => {
        const amountCotcAcaoList = cotcAcaoList == null ? 0 : cotcAcaoList.length;
        this.isCotcAcaoListEmpty = ( amountCotcAcaoList === 0 );

        this._cotcAcaoDataSource = new MatTableDataSource< HstCotcAcao >( cotcAcaoList );

        this._cotcChart.data = [];
        let monthLast = null;

        //Obtem informacoes das cotacoes
        let volTtl = 0;

        for( let indexCotcAcao = amountCotcAcaoList - 1; indexCotcAcao >= 0; indexCotcAcao-- ){
          const cotcAcao: HstCotcAcao = cotcAcaoList[ indexCotcAcao ];

          const cotcPrcMin   = cotcAcao.prcMin;
          const cotcPrcMax   = cotcAcao.prcMax;
          const cotcVol      = cotcAcao.volNegc;

          //Verifica se valores da cotacao sao MINIMO/MAXIMO do periodo.
          if( this._dadosPeriodo.cotcMin == null || ( cotcPrcMin > 0 && this._dadosPeriodo.cotcMin > cotcPrcMin ) ){
            this._dadosPeriodo.cotcMin = cotcPrcMin;
          }

          if( this._dadosPeriodo.cotcMax == null || this._dadosPeriodo.cotcMax < cotcPrcMax ){
            this._dadosPeriodo.cotcMax = cotcPrcMax;
          }

          if( this._dadosPeriodo.volMax == null || ( cotcVol > 0 && this._dadosPeriodo.volMax < cotcVol ) ){
            this._dadosPeriodo.volMax = cotcVol;
          }

          volTtl += cotcAcao.volNegc;
        }

        this._dadosPeriodo.volMed = volTtl == 0 ? 0 : Math.round( volTtl / amountCotcAcaoList );

        if( amountCotcAcaoList > 0 ){
          //Obtem a primeira e ultima cotacao, para calcular a var. perc, no periodo.
          const cotcAbertPeriodo = cotcAcaoList[ amountCotcAcaoList - 1 ];
          const cotcFechPeriodo = cotcAcaoList[ 0 ];

          this._dadosPeriodo.varPerc = ( cotcFechPeriodo.prcFech - cotcAbertPeriodo.prcAbert ) / cotcAbertPeriodo.prcAbert * 100.00;
        }

        this.updateCotcAcaoChart();
      });
  }

  private loadHstIndTcn( cdInv: number, dtCotcStart: Date, dtCotcEnd: Date ){
    const isNeedUpdateCotc   = this.isNeedUpdateIndTcnCotc();
    const isNeedUpdateCancle = this.isNeedUpdateIndTcnCandle();
    const isNeedUpdateVol    = this.isNeedUpdateIndTcnVol();
    const isNeedUpdateSma    = this.isNeedUpdateIndTcnSma();
    const isNeedUpdateEma    = this.isNeedUpdateIndTcnEma();
    const isNeedUpdateMom    = this.isNeedUpdateIndTcnMom();
    const isNeedUpdateRsi    = this.isNeedUpdateIndTcnRsi();
    const isNeedUpdateRsiTypeRangeVariable = this.isNeedUpdateIndTcnRsiTypeRangeVariable();
    const isNeedUpdateEfi    = this.isNeedUpdateIndTcnEfi();
    const isNeedUpdateObv    = this.isNeedUpdateIndTcnObv();
    const isNeedUpdateStoc   = this.isNeedUpdateIndTcnStoc();
    const isNeedUpdatePe     = this.isNeedUpdateIndTcnPe();
    const isNeedUpdatePvpa   = this.isNeedUpdateIndTcnPvpa();

    if( isNeedUpdateSma ){
      this.indTcnService.listHst( cdInv, 'SMA', 'SMA', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'SMA' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateEma ){
      this.indTcnService.listHst( cdInv, 'EMA', 'EMA', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'EMA' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateMom ){
      this.indTcnService.listHst( cdInv, 'MOM', 'SMA', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'MOM' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateRsi ){
      this.indTcnService.listHst( cdInv, 'RSI', 'SMA', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'RSI' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateRsiTypeRangeVariable ){
      this.indTcnService.listHst( cdInv, 'RSI', 'SIGMA_1.0_INF', 63, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'RSI_RANGE_INF' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );

      this.indTcnService.listHst( cdInv, 'RSI', 'SIGMA_1.0_SUP', 63, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'RSI_RANGE_SUP' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateEfi ){
      this.indTcnService.listHst( cdInv, 'EFI', 'SMA', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'EFI' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateObv ){
      this.indTcnService.listHst( cdInv, 'OBV', 'SMA', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'OBV' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdateStoc ){
      this.indTcnService.listHst( cdInv, 'STOC', '%D', 10, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'STOC' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdatePe ){
      this.indTcnService.listHst( cdInv, 'P', 'P/E', 1, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'P/E' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( isNeedUpdatePvpa ){
      this.indTcnService.listHst( cdInv, 'P', 'P/VPA', 1, dtCotcStart, dtCotcEnd, null )
        .then( hstIndTcnList => {
          this._hstIndTcn[ 'P/VPA' ] = hstIndTcnList;

          this.updateCotcAcaoChart();
        } );
    }

    if( !isNeedUpdateSma && !isNeedUpdateEma && !isNeedUpdateRsi && !isNeedUpdateEfi && !isNeedUpdateObv && !isNeedUpdateStoc && !isNeedUpdatePe && !isNeedUpdatePvpa ){
      this.updateCotcAcaoChart();
    }
  }

  private onLoadPrvtList(): Promise< any >{
    const promises = [];

    if( this._controlPrvt.isLoading() === false ){
      promises.push( this.loadPrvtList() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          console.error( err );

          reject();
        } );
    } );

    return p;
  }

  private loadPrvtList(): Promise< any >{
    this._controlPrvt.initializingLoad();

    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const cdInv = _this._crctInv.crctInv.cdInv;

      _this.prvtService.list( cdInv )
        .then( prvtList => {
          _this._prvtDataSource = new MatTableDataSource< PgmtPrvt >( prvtList );

          _this._controlPrvt.isCanShow = true;
        } )
        .catch( error => {
          console.log( 'Ocorreu um erro ao tentar obter os proventos.' );

          console.error( error );
        } )
        .finally( () => {
          _this._controlPrvt.loaded();

          resolve();
        } );
    } );

    return p;
  }

  private loadLastRstdEmpr( cdEmpr: number ){
    let _this = this;

    return new Promise( function( resolve, reject ) {
      const returnCols = [ { 'lpAtrbdSocCntr': true }, { 'lpAtrbdMinor': true }, { 'ebitda': true }, { 'ativoTotal': true }, { 'resultFinanc': true }, { 'divCp': true }, { 'divLp': true }, { 'disp': true }, { 'pl': true }, { 'divLq': true }, { 'deprecAmort': true }, { 'irCsll': true } ];

      _this.rstdEmprService.listTipInfWithVariousInfRstd( cdEmpr, null, null, null, 1, returnCols )
        .then( tipInfList => {
          const sizeTipInfList = tipInfList == null ? 0 : tipInfList.length;

          _this._tipInfRstdDataSource = sizeTipInfList == 0 ? null : new MatTableDataSource< TipInfRstdEmprJoinInfRstdEmpr >( tipInfList );

          resolve( true );
        } );
    });
  }

  private loadDdc( cdEmpr: number ){
    this.ddcService.list( cdEmpr )
      .then( ddcList => {
        this.isDdcListEmpty = ( ddcList.length === 0 );

        this._ddcDataSource = new MatTableDataSource< DocDmntCntb >( ddcList );
      } );
  }

  updateCotcAcaoChart(){
    let isInfoUpdates = false;

    const isShowIndTcnCotcSelected     = this.isShowIndTcnCotcSelected();
    const isShowIndTcnCandleSelected   = this.isShowIndTcnCandleSelected();
    const isShowIndTcnVolSelected      = this.isShowIndTcnVolSelected();
    const isShowIndTcnSmaSelected      = this.isShowIndTcnSmaSelected();
    const isShowIndTcnEmaSelected      = this.isShowIndTcnEmaSelected();
    const isShowIndTcnMomSelected      = this.isShowIndTcnMomSelected();
    const isShowIndTcnRsiSelected      = this.isShowIndTcnRsiSelected();
    const isTypeRangeIndTcnRsiVariable = this.isTypeRangeIndTcnRsiVariable();
    const isShowIndTcnEfiSelected      = this.isShowIndTcnEfiSelected();
    const isShowIndTcnObvSelected      = this.isShowIndTcnObvSelected();
    const isShowIndTcnStocSelected     = this.isShowIndTcnStocSelected();
    const isShowIndTcnPeSelected       = this.isShowIndTcnPeSelected();
    const isShowIndTcnPvpaSelected     = this.isShowIndTcnPvpaSelected();

    //Verifica primeiro se TODAS informacoes necessarias ja foram carregadas.
    if( this.isCotcAcaoListEmpty       === false && this._hstIndTcn != null &&
        ( isShowIndTcnSmaSelected      === false || this._hstIndTcn[ 'SMA' ]  != null ) &&
        ( isShowIndTcnEmaSelected      === false || this._hstIndTcn[ 'EMA' ]  != null ) && 
        ( isShowIndTcnMomSelected      === false || this._hstIndTcn[ 'MOM' ]  != null ) && 
        ( isShowIndTcnRsiSelected      === false || this._hstIndTcn[ 'RSI' ]  != null ) &&
        ( isTypeRangeIndTcnRsiVariable === false || ( this._hstIndTcn[ 'RSI_RANGE_INF' ]  != null && this._hstIndTcn[ 'RSI_RANGE_SUP' ]  != null ) ) && 
        ( isShowIndTcnEfiSelected      === false || this._hstIndTcn[ 'EFI' ]  != null ) && 
        ( isShowIndTcnObvSelected      === false || this._hstIndTcn[ 'OBV' ]  != null ) &&
        ( isShowIndTcnStocSelected     === false || this._hstIndTcn[ 'STOC' ] != null ) &&
        ( isShowIndTcnPeSelected       === false || this._hstIndTcn[ 'P/E' ] != null ) &&
        ( isShowIndTcnPvpaSelected     === false || this._hstIndTcn[ 'P/VPA' ] != null )    ){
      isInfoUpdates = true;
    }

    if( isInfoUpdates === true ){
      this._cotcChart.data = [];
      let monthLast = null;
      let volLast = null;

      let amountCotcAcaoList             = this._cotcAcaoDataSource   != null && this._cotcAcaoDataSource.data.length;
      let amountHstIndTcnSmaList         = this._hstIndTcn[ 'SMA' ]   != null ? this._hstIndTcn[ 'SMA' ].length  : 0;
      let amountHstIndTcnEmaList         = this._hstIndTcn[ 'EMA' ]   != null ? this._hstIndTcn[ 'EMA' ].length  : 0;
      let amountHstIndTcnMomList         = this._hstIndTcn[ 'MOM' ]   != null ? this._hstIndTcn[ 'MOM' ].length  : 0;
      let amountHstIndTcnRsiList         = this._hstIndTcn[ 'RSI' ]   != null ? this._hstIndTcn[ 'RSI' ].length  : 0;
      let amountHstIndTcnRsiRangeInfList = isTypeRangeIndTcnRsiVariable === true && this._hstIndTcn[ 'RSI_RANGE_INF' ]  != null ? this._hstIndTcn[ 'RSI_RANGE_INF' ].length  : 0;
      let amountHstIndTcnRsiRangeSupList = isTypeRangeIndTcnRsiVariable === true && this._hstIndTcn[ 'RSI_RANGE_SUP' ]  != null ? this._hstIndTcn[ 'RSI_RANGE_SUP' ].length  : 0;
      let amountHstIndTcnEfiList         = this._hstIndTcn[ 'EFI' ]   != null ? this._hstIndTcn[ 'EFI' ].length  : 0;
      let amountHstIndTcnObvList         = this._hstIndTcn[ 'OBV' ]   != null ? this._hstIndTcn[ 'OBV' ].length  : 0;
      let amountHstIndTcnStocList        = this._hstIndTcn[ 'STOC' ]  != null ? this._hstIndTcn[ 'STOC' ].length : 0;
      let amountHstIndTcnPeList          = this._hstIndTcn[ 'P/E' ]   != null ? this._hstIndTcn[ 'P/E' ].length : 0;
      let amountHstIndTcnPvpaList        = this._hstIndTcn[ 'P/VPA' ] != null ? this._hstIndTcn[ 'P/VPA' ].length : 0;

      //Obtem informacoes das cotacoes
      let volTtl = 0;

      let indexOfLastCotcAcao     = amountCotcAcaoList - 1;
      let indexCotcAcao           = amountCotcAcaoList;
      let indexIndTcnRsi          = amountHstIndTcnRsiList;
      let indexIndTcnRsiRangeInf  = amountHstIndTcnRsiRangeInfList;
      let indexIndTcnRsiRangeSup  = amountHstIndTcnRsiRangeSupList;

      const indTcnList = [ { name: 'SMA'  , obj: null, value: null, index: amountHstIndTcnSmaList , isShow: isShowIndTcnSmaSelected , vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: GoldenRod;'      },
                           { name: 'EMA'  , obj: null, value: null, index: amountHstIndTcnEmaList , isShow: isShowIndTcnEmaSelected , vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: green;'          },
                           { name: 'MOM'  , obj: null, value: null, index: amountHstIndTcnMomList , isShow: isShowIndTcnMomSelected , vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: CornflowerBlue;' },
                           { name: 'EFI'  , obj: null, value: null, index: amountHstIndTcnEfiList , isShow: isShowIndTcnEfiSelected , vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: red;'            },
                           { name: 'OBV'  , obj: null, value: null, index: amountHstIndTcnObvList , isShow: isShowIndTcnObvSelected , vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: blue;'           },
                           { name: 'STOC' , obj: null, value: null, index: amountHstIndTcnStocList, isShow: isShowIndTcnStocSelected, vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: Brown;'          },
                           { name: 'P/E'  , obj: null, value: null, index: amountHstIndTcnPeList  , isShow: isShowIndTcnPeSelected  , vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: #673AB7;'        },
                           { name: 'P/VPA', obj: null, value: null, index: amountHstIndTcnPvpaList, isShow: isShowIndTcnPvpaSelected, vlMin: Number.MAX_SAFE_INTEGER, vlMax: Number.MIN_SAFE_INTEGER, vlDefault:  0, typeStyle: 0, style: 'stroke-opacity: 0.8;      color: Purple;'         } ];

      const qtIndTcn = indTcnList.length;
 
      while( --indexCotcAcao >= 0 ){
        const dtRef = this._cotcAcaoDataSource.data[ indexCotcAcao   ].dtCotc;

        const cotcAcao         : HstCotcAcao = indexCotcAcao          >= 0 ? this._cotcAcaoDataSource.data[ indexCotcAcao   ] : null;

        //Obtem os valores associados à cotação.
        const cotcDt            = new Date( cotcAcao.dtCotc );
        const cotcDtStr: string = this.datepipe.transform( cotcDt, 'dd/MM/yyyy', '+0000' );
        const cotcDd            = cotcDt.getDate() + 1;
        const cotcMm            = cotcDt.getMonth() + 1;
        const cotcPrcAbert      = cotcAcao.prcAbert;
        const cotcPrcMin        = cotcAcao.prcMin;
        const cotcPrcMax        = cotcAcao.prcMax;
        const cotcPrcFech       = cotcAcao.prcFech;
        const cotcVarPrcFech    = cotcAcao.varPrcFech;
        const cotcVol           = cotcAcao.volNegc;
        let   cotcLabel         = cotcDd.toString();

        if( monthLast == null || monthLast != cotcMm ){
          //cotcLabel = this.datepipe.transform( cotcDt, 'MMM' );
          cotcLabel = cotcDtStr;
        }
        else{
          cotcLabel = cotcDtStr;
        }

        //Dados a serem plotados
        let elementsOfData = [];

        elementsOfData.push( cotcLabel );

        let indTcnRsi        : HstIndTcn = indexIndTcnRsi         > 0 ? this._hstIndTcn[ 'RSI'  ][ --indexIndTcnRsi  ]                 : null;
        let indTcnRsiRangeInf: HstIndTcn = indexIndTcnRsiRangeInf > 0 ? this._hstIndTcn[ 'RSI_RANGE_INF' ][ --indexIndTcnRsiRangeInf ] : null;
        let indTcnRsiRangeSup: HstIndTcn = indexIndTcnRsiRangeSup > 0 ? this._hstIndTcn[ 'RSI_RANGE_SUP' ][ --indexIndTcnRsiRangeSup ] : null;

        //Verifica se o indicador esta em data diferente da cotacao.
        if( indTcnRsi != null && indTcnRsi && cotcDt.getTime() != new Date( indTcnRsi.dtIndTcn ).getTime() ){
          indexIndTcnRsi++;
          indTcnRsi = null;
        }

        //Verifica se o indicador esta em data diferente da cotacao.
        if( indTcnRsiRangeInf != null && cotcDt.getTime() != new Date( indTcnRsiRangeInf.dtIndTcn ).getTime() ){
          indexIndTcnRsiRangeInf++;
          indTcnRsiRangeInf = null;
        }

        //Verifica se o indicador esta em data diferente da cotacao.
        if( indTcnRsiRangeSup != null && cotcDt.getTime() != new Date( indTcnRsiRangeSup.dtIndTcn ).getTime() ){
          indexIndTcnRsiRangeSup++;
          indTcnRsiRangeSup = null;
        }

        //Obtem os valores associados aos indicadores tecnicos.
        const indTcnRsiValue          = indTcnRsi          != null ? indTcnRsi.vlIndTcn                                          :  0;
        const indTcnRsiRangeInfValue  = indTcnRsiRangeInf  != null ? ( indTcnRsiRangeInf.vlIndTcn                              ) : 30;
        const indTcnRsiRangeSupValue  = indTcnRsiRangeSup  != null ? ( indTcnRsiRangeSup.vlIndTcn - indTcnRsiRangeInf.vlIndTcn ) : 40;
        const indTcnRsiRangeTopValue  = 100 - ( indTcnRsiRangeInfValue + indTcnRsiRangeSupValue );

        //Define as propriedades de style a serem aplicadas.
        let   styleVol            = "color: " + ( cotcVarPrcFech === null || cotcVarPrcFech < 0 ? "red" : "green" ) + "; opacity: 0.4; stroke-opacity: 0.6; stroke-width: 2";
        const styleCotc           = 'stroke-opacity: 0.4;      color: Black;';
        const styleCandle         = 'stroke-opacity: 0.4;';
        const styleIndTcnRsi      = 'stroke-opacity: 0.8;      color: DarkViolet;';
        const styleTrackLowRsi    = '       opacity: 0.3; fill-color: #76A7FA;';
        const styleTrackMiddleRsi = '       opacity: 0.4; fill-color: yellow;';
        const styleTrackHighRsi   = '       opacity: 0.2; fill-color: red;';

        if( isShowIndTcnCotcSelected === true ){
          elementsOfData.push( cotcPrcFech, styleCotc );
        }

        if( isShowIndTcnCandleSelected === true ){
          elementsOfData.push( cotcPrcMin, cotcPrcAbert, cotcPrcFech, cotcPrcMax, styleCandle );
        }

        if( isShowIndTcnVolSelected === true ){
          elementsOfData.push( cotcVol, styleVol );
        }

        if( isShowIndTcnRsiSelected === true ){
          elementsOfData.push( indTcnRsiValue, styleIndTcnRsi, indTcnRsiRangeInfValue, styleTrackLowRsi, indTcnRsiRangeSupValue, styleTrackMiddleRsi, indTcnRsiRangeTopValue, styleTrackHighRsi );
        }

        //Itera por cada um dos indicadores tecnicos.
        for( let indexIndTcnIterate = 0; indexIndTcnIterate < qtIndTcn; indexIndTcnIterate++ ){
          const indTcnIterate = indTcnList[ indexIndTcnIterate ];

          let   indexIndTcnThis     = indTcnIterate.index;
          const nameIndTcnThis      = indTcnIterate.name;
          const vlDefaultIndTcnThis = indTcnIterate.vlDefault;
          const typeStyle           = indTcnIterate.typeStyle;
          const styleIndTcnThis     = indTcnIterate.style;
          const isShowIndTcnThis    = indTcnIterate.isShow;
          let   indTcn: HstIndTcn   = indexIndTcnThis > 0 ? this._hstIndTcn[ nameIndTcnThis ][ --indexIndTcnThis ] : null;
          let   indTcnValue         = indTcn  != null ? indTcn.vlIndTcn  : vlDefaultIndTcnThis;

          //Verifica se o indicador esta em data diferente da cotacao.
          if( indTcn != null && cotcDt.getTime() != new Date( indTcn.dtIndTcn ).getTime() ){
            indexIndTcnThis++;
            indTcn = null;
            indTcnValue = null;
          }

          //Verifica se o valor do indicador é um minimo ou maximo.
          if( indTcnValue != null && indTcnValue < indTcnIterate.vlMin ){
            indTcnIterate.vlMin = indTcnValue;
          }
          else{
            if( indTcnValue != null && indTcnValue > indTcnIterate.vlMax ){
              indTcnIterate.vlMax = indTcnValue;
            }
          }

          if( isShowIndTcnThis === true ){
            elementsOfData.push( indTcnValue );

            //Monta o style conforme o tipo definido.
            let styleMake = null;

            switch( typeStyle ){
              case 0:
                styleMake = styleIndTcnThis;
                break;

              case 1:
                styleMake = 'color: ' + ( cotcVarPrcFech === null || cotcVarPrcFech < 0 ? 'red' : 'green' ) + '; ' + styleIndTcnThis;
                break;
            }

            //Adiciona o style ao array de elementos de dados do grafico.
            elementsOfData.push( styleMake );
          }

          indTcnIterate.index = indexIndTcnThis;
        }

        this._cotcChart.data.push( elementsOfData );

        monthLast = cotcMm;
        volLast = cotcVol;
      }

      const columnsOfChart = [];
      const seriesOfChart  = {};
      let seriesIndexOfChart: number = -1;
      let titleOfColumnMom         = ' ';
      let textPositionOfColumnMom  = 'none';
      let titleOfColumnStoc        = ' ';
      let textPositionOfColumnStoc = 'none';
      let titleOfColumnPe          = ' ';
      let textPositionOfColumnPe   = 'none';
      let titleOfColumnPvpa        = ' ';
      let textPositionOfColumnPvpa = 'none';

      columnsOfChart.push( 'Data' );

      if( isShowIndTcnCotcSelected === true ){
        columnsOfChart.push( 'Preço Fec.', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 0 };
      }

      if( isShowIndTcnCandleSelected === true ){
        columnsOfChart.push( 'Cot.', 'Mín', 'Máx', 'Fech', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'candlesticks', targetAxisIndex: 0 };
      }

      if( isShowIndTcnVolSelected === true ){
        columnsOfChart.push( 'Vol.', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'bars'        , targetAxisIndex: 1 };
      }

      if( isShowIndTcnRsiSelected === true ){
        columnsOfChart.push( 'RSI(10)', { role: 'style' }, '', { role: 'style' }, '', { role: 'style' }, '', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 2 };
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'area'        , targetAxisIndex: 2 };
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'area'        , targetAxisIndex: 2 };
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'area'        , targetAxisIndex: 2 };
      }

      if( isShowIndTcnSmaSelected === true ){
        columnsOfChart.push( 'SMA(10)', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 0 };
      }

      if( isShowIndTcnEmaSelected === true ){
        columnsOfChart.push( 'EMA(10)', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 0 };
      }

      if( isShowIndTcnMomSelected === true ){
        columnsOfChart.push( 'MOM(10)', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 5 };

        if( isShowIndTcnRsiSelected === false ){
          titleOfColumnMom        = '%';
          textPositionOfColumnMom = 'right';
        }
      }

      if( isShowIndTcnEfiSelected === true ){
        columnsOfChart.push( 'EFI(10)', { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 3 };
      }

      if( isShowIndTcnObvSelected === true ){
        columnsOfChart.push( 'OBV(10)' );
        columnsOfChart.push( { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 4 };
      }

      if( isShowIndTcnStocSelected === true ){
        columnsOfChart.push( 'STOC(10)' );
        columnsOfChart.push( { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 6 };

        if( isShowIndTcnRsiSelected === false && isShowIndTcnMomSelected === false ){
          titleOfColumnStoc        = '%';
          textPositionOfColumnStoc = 'right';
        }
      }

      if( isShowIndTcnPeSelected === true ){
        columnsOfChart.push( 'P/E' );
        columnsOfChart.push( { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 7 };

        if( isShowIndTcnRsiSelected === false && isShowIndTcnMomSelected === false && isShowIndTcnStocSelected === false ){
          titleOfColumnPe        = ' ';
          textPositionOfColumnPe = 'right';
        }
      }

      if( isShowIndTcnPvpaSelected === true ){
        columnsOfChart.push( 'P/VPA' );
        columnsOfChart.push( { role: 'style' } );
        seriesOfChart[ ( ++seriesIndexOfChart ).toString() ] = { type: 'line'        , targetAxisIndex: 8 };

        if( isShowIndTcnRsiSelected === false && isShowIndTcnMomSelected === false && isShowIndTcnStocSelected === false ){
          titleOfColumnPvpa        = ' ';
          textPositionOfColumnPvpa = 'right';
        }
      }

      //Define o maior valor absoluto, se o min, ou o max.
      const vlrEfiLargerAbs    = Math.max( Math.abs( this.getObjOfPropertie( indTcnList, 'name', 'EFI' ).vlMin ), Math.abs( this.getObjOfPropertie( indTcnList, 'name', 'EFI' ).vlMax ) );
      const vlrObvLargerAbs    = Math.max( Math.abs( this.getObjOfPropertie( indTcnList, 'name', 'OBV' ).vlMin ), Math.abs( this.getObjOfPropertie( indTcnList, 'name', 'OBV' ).vlMax ) );
      const vlrMomLargerAbs    = Math.max( ( this.getObjOfPropertie( indTcnList, 'name', 'MOM' ).vlMax - 100 ), ( 100 - this.getObjOfPropertie( indTcnList, 'name', 'MOM' ).vlMin ) );
      const vlrStocSmallerAxis = Math.min( this.getObjOfPropertie( indTcnList, 'name', 'STOC' ).vlMin,   0 );
      const vlrStocLargerAxis  = Math.max( this.getObjOfPropertie( indTcnList, 'name', 'STOC' ).vlMax, 100 );
      const vlrPeSmallerAxis   = this.getObjOfPropertie( indTcnList, 'name', 'P/E' ).vlMin * 0.9;
      const vlrPeLargerAxis    = this.getObjOfPropertie( indTcnList, 'name', 'P/E' ).vlMax * 1.1;
      const vlrPvpaSmallerAxis = this.getObjOfPropertie( indTcnList, 'name', 'P/VPA' ).vlMin * 0.9;
      const vlrPvpaLargerAxis  = this.getObjOfPropertie( indTcnList, 'name', 'P/VPA' ).vlMax * 1.1;

      this._cotcChart.title = this._crctInv.crctInv.nmInv + ':';
      this._cotcChart.options = { legend: this._cotcChart.options.legend,
                                  titlePosition: this._cotcChart.options.titlePosition,
                                  titleTextStyle: this._cotcChart.options.titleTextStyle,
                                  candlestick: this._cotcChart.options.candlestick,
                                  width: this._cotcChart.options.width,
                                  height: this._cotcChart.options.height,
                                  isStacked: this._cotcChart.options.isStacked,
                                  tooltip: this._cotcChart.options.tooltip,
                                  interpolateNulls: this._cotcChart.options.interpolateNulls,
                                  hAxis: this._cotcChart.options.hAxis,
                                  vAxes: { 0: { logScale: false, title: 'R$ ', format: 'decimal', viewWindow: { min: (0.9 * this._dadosPeriodo.cotcMin), max:   (1.1 * this._dadosPeriodo.cotcMax) }, textPosition: 'left' , gridlines: { count: -1 } },
                                           1: { logScale: false, title: '   ', format: 'decimal', viewWindow: { min: 0                                 , max:   (3.5 * this._dadosPeriodo.volMax) }, textPosition: 'none' , gridlines: { count:  0 } },
                                           2: { logScale: false, title: '%  ', format: 'decimal', viewWindow: { min: 0, max: 100 }, textPosition: 'right', gridlines: { count:  0 } },
                                           3: { logScale: false, title: '   ', format: 'decimal', viewWindow: { min: (vlrEfiLargerAbs * (-1.1)), max:   (vlrEfiLargerAbs * (+1.1)) }, textPosition: 'none' , gridlines: { count:  0 } },
                                           4: { logScale: false, title: '   ', format: 'decimal', viewWindow: { min: (vlrObvLargerAbs * (-1.1)), max:   (vlrObvLargerAbs * (+1.1)) }, textPosition: 'none', gridlines: { count:  0 } },
                                           5: { logScale: false, title: titleOfColumnMom , format: 'decimal', viewWindow: { min: 0.9*(100 - vlrMomLargerAbs ), max: 1.1*(100 + vlrMomLargerAbs ) }, textPosition: textPositionOfColumnMom , gridlines: { count:  0 } },
                                           6: { logScale: false, title: titleOfColumnStoc, format: 'decimal', viewWindow: { min: vlrStocSmallerAxis          , max: vlrStocLargerAxis            }, textPosition: textPositionOfColumnStoc, gridlines: { count:  0 } },
                                           7: { logScale: false, title: titleOfColumnPe  , format: 'decimal', viewWindow: { min: vlrPeSmallerAxis            , max: vlrPeLargerAxis              }, textPosition: textPositionOfColumnPe  , gridlines: { count:  0 } },
                                           8: { logScale: false, title: titleOfColumnPvpa, format: 'decimal', viewWindow: { min: vlrPvpaSmallerAxis          , max: vlrPvpaLargerAxis            }, textPosition: textPositionOfColumnPvpa, gridlines: { count:  0 } }
                                         },
                                  seriesType: this._cotcChart.options.seriesType,
                                  series: seriesOfChart,
                                  chartArea: this._cotcChart.options.chartArea
                                };
      this._cotcChart.columns = columnsOfChart;
    }
  }

  updateDtCotc( event: Event ){
    let dtCotcFromSelected = this.getDtCotcFromSelected();
    let dtCotcEndSelected = this.getDtCotcEndSelected();

    //Verifica se a data de inicio eh maior que a data de termino.
    if( dtCotcFromSelected != null && dtCotcEndSelected != null ){
      let diff = Math.round( dtCotcEndSelected.getTime() - dtCotcFromSelected.getTime() );
      let diffDays = Math.ceil( diff / (1000 * 3600 * 24) );

      if( diffDays < 0 ){
        this.setDtCotcFromSelected( dtCotcEndSelected );
        this.setDtCotcEndSelected( dtCotcFromSelected );

        dtCotcFromSelected = this.getDtCotcFromSelected();
        dtCotcEndSelected = this.getDtCotcEndSelected();
      }
    }

    this.loadCotcAndHstIndTcnAcao();

    this.prmUsrService.setValue( this.PRM_USR_COTC_DT_INICIO, dtCotcFromSelected );
    this.prmUsrService.setValue( this.PRM_USR_COTC_DT_FIM   , dtCotcEndSelected );
  }

  updateCheckboxIndTcn( nmSnlTcn: string, event: MatCheckboxChange ){
    const isChecked = event.checked;

    //Limpa o indicador técnico.
    this._hstIndTcn[ nmSnlTcn ] = [];

    //Obtem as informacoes necessarias para realizar as atualizacoes.
    const cdInv = this._crctInv.crctInv.cdInv;
    const dtCotcFromSelected = this.getDtCotcFromSelected();
    const dtCotcEndSelected = this.getDtCotcEndSelected();

    if( nmSnlTcn === 'CANDLE' ){
      this.setShowIndTcnCotcSelected( !isChecked );
    }

    if( nmSnlTcn === 'RSI' ){
      this._hstIndTcn[ 'RSI_RANGE_INF' ] = [];
      this._hstIndTcn[ 'RSI_RANGE_SUP' ] = [];

      if( isChecked === true ){
        this.enableAllTypeRangeIndTcnRsi();
      }
      else{
        this.disableAllTypeRangeIndTcnRsi();
      }
    }

    this.loadHstIndTcn( cdInv, dtCotcFromSelected, dtCotcEndSelected );
  }

  public onChangeTypeRangeIndTcnRsi( event: MatRadioChange ){
    this.setIdTypeRangeIndTcnRsi( event.value );

    //Obtem as informacoes necessarias para realizar as atualizacoes.
    const cdInv = this._crctInv.crctInv.cdInv;
    const dtCotcFromSelected = this.getDtCotcFromSelected();
    const dtCotcEndSelected = this.getDtCotcEndSelected();

    this.loadHstIndTcn( cdInv, dtCotcFromSelected, dtCotcEndSelected );
  }


  public onOpenedPanelPrvt(): void{
    const controlLoad = this._controlPrvt;

    controlLoad.isCanShow = true;

    if( controlLoad.isDataLoaded === false ){
      this.onLoadPrvtList()
        .finally( () => {
        } );
    }
  }


  public onClosedPanelPrvt(): void{
    const controlLoad = this._controlPrvt;

    controlLoad.isCanShow    = false;
    controlLoad.isDataLoaded = false;
  }


  detailCotcAcao( dtCotc: Date ){
    this.goToPage( '/cotc-detail', { 'cdInv': this._crctInv.crctInv.cdInv, 'dtCotc': dtCotc }, true );
  }

  detailDdc( sglDocDmntCntb: string, dtPblccDocDmntCntb: Date, nrSeqDocDmntCntb: number ){
    this.goToPage( '/ddc-detail', { 'cdEmpr': this._crctInv.crctInv.empr.cdEmpr, 'sglDocDmntCntb': sglDocDmntCntb, 'dtPblccDocDmntCntb': dtPblccDocDmntCntb, 'nrSeqDocDmntCntb': nrSeqDocDmntCntb } );
  }

  detailEmpr(){
    this.goToPage( '/empr-detail', { 'cdEmpr': this._crctInv.crctInv.empr.cdEmpr }, true );
  }

  detailSetorEcnm(){
    const segm     = this._crctInv.crctInv.empr.segmEcnm;
    const subSetor = segm.subSetorEcnm;
    const setor    = subSetor.setorEcnm;

    this.goToDetailSegmEcnm( setor.cdSetorEcnm, null, null );
  }

  detailSubSetorEcnm(){
    const segm     = this._crctInv.crctInv.empr.segmEcnm;
    const subSetor = segm.subSetorEcnm;
    const setor    = subSetor.setorEcnm;

    this.goToDetailSegmEcnm( setor.cdSetorEcnm, subSetor.cdSubSetorEcnm, null );
  }

  detailSegmEcnm(){
    const segm     = this._crctInv.crctInv.empr.segmEcnm;
    const subSetor = segm.subSetorEcnm;
    const setor    = subSetor.setorEcnm;

    this.goToDetailSegmEcnm( setor.cdSetorEcnm, subSetor.cdSubSetorEcnm, segm.cdSegmEcnm );
  }

  detailSetorAtvdd(){
    const setorAtvdd   = this._crctInv.crctInv.empr.setorAtvdd;
    const cdSetorAtvdd = setorAtvdd == null ? null : setorAtvdd.cdSetorAtvdd;

    this.goToDetailSetorAtvdd( cdSetorAtvdd );
  }

  private goToDetailSegmEcnm( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ){
    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm, 'cdSubSetorEcnm': cdSubSetorEcnm, 'cdSegmEcnm': cdSegmEcnm }, true );
  }

  private goToDetailSetorAtvdd( cdSetorAtvdd: number ){
    this.goToPage( '/setor-detail', { 'cdSetorAtvdd': cdSetorAtvdd }, true );
  }

  private getObjOfPropertie( objList, propTarget: string, valueTarget: string ){
    let objFound = null;

    //Obtem a quantidade de objetos.
    const qtProperties = objList == null || !objList ? 0 : objList.length;

    for( let index = 0; index < qtProperties; index++ ){
      const obj = objList[ index ];

      const propertie = obj[ propTarget ];

      if( propertie === valueTarget ){
        objFound = obj;
        break;
      }
    }

    return objFound;
  }
}

import { DestaquesBySeq } from 'src/app/_models/dtq/dtq';
import { MatTableDataSource } from '@angular/material/table';
import { Perdc } from 'src/app/_models/perdc';

export class DestaqueControl{
  //Um nome para este objeto.
  public nm                   : string;

  //Usuário quer ver o Destaque?
  public isShowDtq            : boolean = false;

  //Permitido mostrar o Destaque?
  public isAllowedShowDtq     : boolean = false;

  //Dados do Destaque foram carregados?
  public isDtqLoaded		      : boolean = false;

  public displayedColumns     : string[];
  public datesDisplayedColumns: string[];
  public dataSource           : MatTableDataSource< DestaquesBySeq >;
  public isShowProgressBar    : boolean = false;
  public valueProgressBar     : number = 0;
  public nmFcn                : string;

  public perdc                : Perdc;

  public constructor( nm: string, nmFcnParm: string, perdc: Perdc ){
    this.nm    = nm;
    this.nmFcn = nmFcnParm;
    this.perdc = perdc;
  }
}

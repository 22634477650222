<form [formGroup]="logoutForm" (ngSubmit)="onSubmit()">
  <div class="grid_container_logout">

    <div class="grid_item_logout_user">
      <div class="label_chave">
        Usuário: <label>{{sessionService.currentUserValue.username}}</label>
      </div>
    </div>

    <div class="grid_item_logout_name">
      <div class="label_chave">
        Nome Completo: <label>{{sessionService.currentUserValue.name}}</label>
      </div>
    </div>

    <div class="grid_item_logout_email">
      <div class="label_chave">
        E-mail: <label>{{sessionService.currentUserValue.email}}</label>
      </div>
    </div>

    <div *ngIf="this._isEnableChangePassword.value == false" class="grid_item_logout_changePassword">
      <a [routerLink]="" routerLinkActive="active" (click)="onEnableChangePassword()" class="label_changePasswordLink"> 
        Alterar Senha
      </a>
    </div>

    <div *ngIf="this._isEnableChangePassword.value == true" class="grid_item_logout_actualPassword">
      <mat-form-field appearance="fill">
        <mat-label>Senha Atual:</mat-label>
        <input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._actualPwdForm">
        <button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
          <mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngIf="this._isEnableChangePassword.value == true" class="grid_item_logout_newPassword">
      <mat-form-field appearance="fill">
        <mat-label>Nova Senha:</mat-label>
        <input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._newPwdForm">
        <button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
          <mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngIf="this._isEnableChangePassword.value == true" class="grid_item_logout_repeatNewPassword">
      <mat-form-field appearance="fill">
        <mat-label>Repetir Nova Senha:</mat-label>
        <input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._newPwdRepeatForm">
        <button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
          <mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngIf="this._isEnableChangePassword.value == true" class="grid_item_logout_buttonsChangePassword">
      <button mat-icon-button class="column_options_element" color="accent" (click)="this.onCancelChangePassword()">
        <mat-icon matTooltip="Cancelar a alteração da senha.">cancel</mat-icon>
      </button>

      <button mat-icon-button class="column_options_element" color="accent" (click)="this.onChangePassword()">
        <mat-icon matTooltip="Salvar a nova senha.">save</mat-icon>
      </button>
    </div>

    <div *ngIf="this._isEnableChangePassword.value == false" class="grid_item_logout_button">
      <button (click)="this.onLogout()" class="button">Logout</button>
    </div>
  </div>
</form>

import { Component, OnInit, ViewChild       } from '@angular/core';
import { DomSanitizer                       } from '@angular/platform-browser';
import { NavigationExtras, Router           } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { MatTableDataSource                 } from '@angular/material/table';
import { MatPaginator     } from '@angular/material/paginator';

import { AplicationStateService             } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService              } from '../../../_services/authentication/authentication.service';
import { AbstractMainComponent } from '../../abstract-main.component';
import { DdcService } from 'src/app/_services/ddc/ddc.service';
import { DocDmntCntb } from 'src/app/_models/ddc';
import { PdcService } from 'src/app/_services/pdc/pdc.service';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { TipInfRstdEmprJoinInfRstdEmpr } from 'src/app/_models/rstdEmpr';
import { PerdDmntCntb } from 'src/app/_models/perdDmntCntb';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';


export abstract class DdcListComponent extends AbstractMainComponent{
  public _ddcColumnsDisplayed: string[] = [];
  public dataSourceDdcList;
  @ViewChild( MatPaginator, {static: true} ) paginator: MatPaginator;
  @ViewChild( MatSort, {static: true} ) sort: MatSort;

  public isDdcListEmpty: boolean;

  public _form;

  constructor( protected router               : Router                ,
               protected formBuilder          : FormBuilder           ,
               protected dialog               : MatDialog             ,
               sanitizer                      : DomSanitizer          ,
               aplicationStateService         : AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar             : MatSnackBar           ,
               protected ddcService           : DdcService            ,
               protected pdcService           : PdcService            ,
               protected rstdEmprService      : RstdEmprService ) { 
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    super.ngOnInit();

    this._form = this.createForm();

    this.createNameOfDisplayedColumns();
    this.refresh();
  }

  private createForm(){
    let dateFrom = new Date();
    let dateEnd  = new Date();

    dateFrom.setDate( dateFrom.getDate() - 7 );

    return this.formBuilder.group(
      { dtPblccDocDmntCntbFrom  : dateFrom,
        dtPblccDocDmntCntbEnd   : dateEnd ,
        nmTicker                : ''      ,
        isOnlyEmprMonitored     : new FormControl( true ),
        isShowColumnSglDdc      : new FormControl( false ),
        isShowColumnSetorEcnm   : new FormControl( true ),
        isShowColumnSubSetorEcnm: new FormControl( false ),
        isShowColumnSegmEcnm    : new FormControl( false )  }
    );
  }

  private isShowColumnSglDdc(){
    return this._form.controls[ 'isShowColumnSglDdc' ].value;
  }

  private isShowColumnSetorEcnm(){
    return this._form.controls[ 'isShowColumnSetorEcnm' ].value;
  }

  private isShowColumnSubSetorEcnm(){
    return this._form.controls[ 'isShowColumnSubSetorEcnm' ].value;
  }

  private isShowColumnSegmEcnm(){
    return this._form.controls[ 'isShowColumnSegmEcnm' ].value;
  }

  private refreshDisplayedColumns(){
    this.createNameOfDisplayedColumns();
  }

  private createNameOfDisplayedColumns(){
    this._ddcColumnsDisplayed = [];

    this._ddcColumnsDisplayed.push( 'dtPblccDocDmntCntb' );

    if( this.isShowColumnSglDdc() ){
      this._ddcColumnsDisplayed.push( 'sglDocDmntCntb' );
    }

    this._ddcColumnsDisplayed.push( 'empr' );

    if( this.isShowColumnSetorEcnm() ){
      this._ddcColumnsDisplayed.push( 'setorEcnm' );
    }

    if( this.isShowColumnSubSetorEcnm() ){
      this._ddcColumnsDisplayed.push( 'subSetorEcnm' );
    }

    if( this.isShowColumnSegmEcnm() ){
      this._ddcColumnsDisplayed.push( 'segmEcnm' );
    }

    this._ddcColumnsDisplayed.push( 'perdRef' );
    this._ddcColumnsDisplayed.push( 'varLp' );
    this._ddcColumnsDisplayed.push( 'options' );
  }

  refresh(){
    const dtPblccControl = this._form.controls[ 'dtPblccDocDmntCntbFrom' ];
    const dtPblccValue   = dtPblccControl == null ? null : dtPblccControl.value;

    const dtPblccEndControl = this._form.controls[ 'dtPblccDocDmntCntbEnd' ];
    const dtPblccEndValue   = dtPblccControl == null ? null : dtPblccEndControl.value;

    const isOnlyEmprMonitoredControl = this._form.controls[ 'isOnlyEmprMonitored' ];
    const isOnlyEmprMonitoredValue   = isOnlyEmprMonitoredControl == null ? null : isOnlyEmprMonitoredControl.value;

    this.ddcService.listBetweenDtPblcc( dtPblccValue, dtPblccEndValue, isOnlyEmprMonitoredValue )
      .then( ddcList => {
        const qtDdc = ddcList === null ? 0 : ddcList.length;
        this.isDdcListEmpty = ( qtDdc === 0 );

        this.dataSourceDdcList = new MatTableDataSource< DocDmntCntb >( ddcList );
        this.dataSourceDdcList.paginator = this.paginator;

        this.dataSourceDdcList.sortingDataAccessor = ( item, property ) => {
          switch( property ) {
            case 'dtPblccDocDmntCntb': return item.dtPblccDocDmntCntb;
            case 'sglDocDmntCntb'    : return item.sglDocDmntCntb;
            case 'empr'              : return item.empr.nmAbrvEmpr && item.empr.nmAbrvEmpr != null ? item.empr.nmAbrvEmpr : item.empr.nmEmpr;
            case 'setorEcnm'         : return item.empr.segmEcnm && item.empr.segmEcnm != null ? item.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm.normalize( "NFD" ) : null;
            case 'subSetorEcnm'      : return item.empr.segmEcnm && item.empr.segmEcnm != null ? item.empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm.normalize( "NFD" ) : null;
            case 'segmEcnm'          : return item.empr.segmEcnm && item.empr.segmEcnm != null ? item.empr.segmEcnm.nmSegmEcnm.normalize( "NFD" ) : null;
            case 'perdRef'           : return ( item.perdcRef && item.perdcRef != null ) ? ( item.perdcRef.aaRefPerdc + '/' + item.perdcRef.nrRefPerdc + '/' + item.perdcRef.perdc.sglPerdc ) : null;
            case 'varLp'             : return ( item.tipInfRstdEmprJoinInfRstdEmpr && item.tipInfRstdEmprJoinInfRstdEmpr[0] != null && item.tipInfRstdEmprJoinInfRstdEmpr.length > 0 ) ? item.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].getVarPercVlRstdEmpr() : null;

            default: return item[ property ];
          }
        };

        this.dataSourceDdcList.sort = this.sort;

        this.dataSourceDdcList.filterPredicate = (d: DocDmntCntb, filter: string) => {
          const stringOfFilter = filter.toLowerCase();

          try{
            const nmAbrvEmpr = d.empr.nmAbrvEmpr;
            const nmEmpr     = d.empr.nmEmpr.toLowerCase() || '';

            const nmAbrvEmprToTest = nmAbrvEmpr == null ? '' : nmAbrvEmpr.toLowerCase() || '';

            const sglDocDmntCntb = d.sglDocDmntCntb != null ? d.sglDocDmntCntb.toLocaleLowerCase() : '';
            const nmSetorEcnm    = d.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm.trim().toLocaleLowerCase();
            const nmSubSetorEcnm = d.empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm.trim().toLocaleLowerCase();
            const nmSegmEcnm     = d.empr.segmEcnm.nmSegmEcnm.trim().toLocaleLowerCase();

            const isFilter =  nmAbrvEmprToTest.indexOf( stringOfFilter ) !== -1 ||
                              nmEmpr.indexOf( stringOfFilter )           !== -1 ||
                              sglDocDmntCntb.indexOf( stringOfFilter )   !== -1 ||
                              nmSetorEcnm.indexOf( stringOfFilter )      !== -1 ||
                              nmSubSetorEcnm.indexOf( stringOfFilter )   !== -1 ||
                              nmSegmEcnm.indexOf( stringOfFilter )       !== -1   ;

            return isFilter;
          }
          catch( error ){
            console.log( error );
            return false;
          }
        };

        const returnCols = [ { 'lpAtrbdSocCntr': true } ];

        //Busca os periodos de demonstracao contabil, iterando atraves de cada um dos documentos.
        for( let indexDdc = 0; indexDdc < qtDdc; indexDdc++ ){
          const ddc                = ddcList[ indexDdc ];
          const cdEmpr             = ddc.empr.cdEmpr;
          const sglDocDmntCntb     = ddc.sglDocDmntCntb;
          const dtPblccDocDmntCntb = ddc.dtPblccDocDmntCntb;
          const perdcRef           = ddc.perdcRef;

          //Chama o servico para buscar os periodos.
          this.pdcService.list( cdEmpr, sglDocDmntCntb, dtPblccDocDmntCntb )
            .then( pdcList => {
              const qtPdc = pdcList === null ? 0 : pdcList.length;

              if( qtPdc > 0 ){
                for( let indexPdc = 0; indexPdc < qtPdc; indexPdc++ ){
                  const pdc = pdcList[ indexPdc ];

                  if( !ddc.perdDmntCntbList ){
                    ddc[ 'perdDmntCntbList' ] = [];
                  }

                  ddc.perdDmntCntbList.push( pdc );
                }
              }
          } );

          if( perdcRef.perdc.sglPerdc != null ){
            //Busca o lucro/prejuizo do periodo atual e do ano anterior, para realizar a comparacao %.
            this.rstdEmprService.listTipInfWithVariousInfRstd( cdEmpr, perdcRef.perdc.sglPerdc, (perdcRef.aaRefPerdc - 1), perdcRef.nrRefPerdc, 2, returnCols )
              .then( tipInfList => {
                //Deve retornar apenas 1 elemento no array, referente ao lucro/prejuizo.
                const sizeTipInfList = tipInfList == null ? 0 : tipInfList.length;

                if( sizeTipInfList > 0 ){
                  //Espera-se que sejam retornados 2 elementos, referentes ao ano atual e anterior.
                  const sizeInfRstdEmprList = tipInfList[0].infRstdEmprArray && tipInfList[0].infRstdEmprArray != null ? tipInfList[0].infRstdEmprArray.length : 0;

                  if( sizeInfRstdEmprList === 2 ){
                    ddc.tipInfRstdEmprJoinInfRstdEmpr = new Array< TipInfRstdEmprJoinInfRstdEmpr >();
                    ddc.tipInfRstdEmprJoinInfRstdEmpr[0] = tipInfList[0];
                  }
                  else{
                    ddc.tipInfRstdEmprJoinInfRstdEmpr = null;
                  }
                }
            });
          }
        }
      });
  }

  onSubmit(){
  }

  public getPerdsDmntCntbStringMultline( pdcList: PerdDmntCntb[] ){
    const qtPdc = pdcList && pdcList != null ? pdcList.length : 0;

    let text = "";

    if( qtPdc > 0 ){
      text += "E mais " + (qtPdc-1) + " períodos:\n";

      for( let index = 1; index < qtPdc; index++ ){
        const pdc = pdcList[ index ];

        text += pdc.perdcRef.aaRefPerdc + ( pdc.perdcRef.nrRefPerdc > 0 ? ( '/' + pdc.perdcRef.nrRefPerdc ) : '' ) + ' (' + pdc.perdcRef.perdc.nmPerdc + ')' + '\n';
      }
    }

    return text;
  }

  applyFilterDdc( event: Event ){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDdcList.filter = filterValue.trim().toLowerCase();
  }

  updateDdcListFromDtPblccDocDmntCntb( event: Event ){
    this.refresh();
  }

  updateDdcListEndDtPblccDocDmntCntb( event: Event ){
    this.refresh();
  }

  public updateShowColumn( event: MatCheckboxChange ){
    this.refreshDisplayedColumns();
  }

  detailEmpr( cdEmpr: number ){
    const queryParams = {
      cdEmpr: cdEmpr
    };

    this.goToPage( '/empr-detail', queryParams, true );
  }

  detailSetorEcnm( cdSetorEcnm: number ){
    this.detailSegmEcnm( cdSetorEcnm, null, null );
  }

  detailSubSetorEcnm( cdSetorEcnm: number, cdSubSetorEcnm: number ){
    this.detailSegmEcnm( cdSetorEcnm, cdSubSetorEcnm, null );
  }

  detailSegmEcnm( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ){
    const queryParams = {
      cdSetorEcnm   : cdSetorEcnm   ,
      cdSubSetorEcnm: cdSubSetorEcnm,
      cdSegmEcnm    : cdSegmEcnm
    };

    this.goToPage( '/setor-detail', queryParams, true );
  }

  detailDdc( cdEmpr: number, sglDocDmntCntb: string, dtPblccDocDmntCntb: Date, nrSeqDocDmntCntb: number ){
    const queryParams = {
      cdEmpr: cdEmpr,
      sglDocDmntCntb: sglDocDmntCntb,
      dtPblccDocDmntCntb: dtPblccDocDmntCntb,
      nrSeqDocDmntCntb: nrSeqDocDmntCntb
    };

    this.goToPage( '/ddc-detail', queryParams );
  }
}

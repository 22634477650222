import { Component, OnInit, ViewChild, ChangeDetectorRef         } from '@angular/core';
import { DomSanitizer                         } from '@angular/platform-browser';
import { ActivatedRoute, Router               } from '@angular/router';
import { FormBuilder, FormControl             } from '@angular/forms';

import { DatePipe, DecimalPipe                } from '@angular/common';

import * as moment from 'moment';

import { MatDialog                            } from '@angular/material/dialog';
import { MatTableDataSource                   } from '@angular/material/table';
import { MatPaginator                         } from '@angular/material/paginator';
import { MatSnackBar                          } from '@angular/material/snack-bar';

import { AbstractMainComponent                } from '../../abstract-main.component';

import { AplicationStateService               } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService                } from '@services/authentication/authentication.service';
import { InvService                           } from '@services/inv/inv.service';
import { PrmUsrService                        } from '@services/prmUsr/prmUsr.service';
import { GrCrctInvService                     } from '@services/grCrctInv/grCrctInv.service';
import { MvtcGrCrctInvService                 } from '@services/mvtcGrCrctInv/mvtcGrCrctInv.service';

import { saveAs } from 'file-saver';

import { ShortNumberPipe                      } from '../../../_pipes/short-number/short-number.pipe';

import { GrupoCrctInvAcao, MovimentacaoGrupoCrctInvAcao, ItemGrupoCrctInvAcao, TipMvtcGrupoCrctInvAcao, HistoricoContabilGrupoCrctInvAcao, UsrComptGrCrctInvAcao                     } from 'src/app/_models/grCrctInv/grCrctInv';

import { CarteiraMvtcAddDataParm                } from './dialog/add/models/carteiraMvtcAddDataParm';
import { CarteiraMvtcDialogAddDesktopComponent  } from './dialog/add/desktop/carteira-mvtc-dialog-add-desktop.component';

import { CrctInv                                } from 'src/app/_models/inv';
import { ControlShow                            } from 'src/app/_models/control/controlShow';
import { ControlLoad                            } from 'src/app/_models/control/controlLoad';

import { CarteiraDialogEditDesktopComponent     } from '../dialog/edit/desktop/carteira-dialog-edit-desktop.component';
import { GeneralDialogConfirmDesktopComponent   } from 'src/app/_components/general/dialog/confirm/desktop/general-dialog-confirm-desktop.component';
import { TickerDialogSelectDesktopComponent     } from '../../ticker/dialog/select/desktop/ticker-dialog-select-desktop.component';

import { ComponentType                                                } from '@angular/cdk/portal';
import { MatSort                                                      } from '@angular/material/sort';

import { ConfirmDataParm                                              } from '@components/general/dialog/confirm/models/confirmDataParm';
import { ControlLoadMvtc, InformeValueToLoadMvtc, TipEstLoadMvtcEnum  } from './models/constrolLoadMvtc';
import { ReturnDataParm                                               } from '../../general/dialog/confirm/models/returnDataParm';
import { MatSidenav                                                   } from '@angular/material/sidenav';
import { ControlShowColumnItemGr                                      } from './models/controlShowColumnItemGr';
import { ControlShowItemGrCrctInvAcao                                 } from './models/controlShowItemGr';
import { CarteiraMvtcDialogAddComponent                               } from './dialog/add/carteira-mvtc-dialog-add.component';
import { GeneralDialogConfirmComponent                                } from '@components/general/dialog/confirm/general-dialog-confirm.component';
import { GeneralDialogInputMessageComponent   } from '@components/general/dialog/inputMessage/general-dialog-inputMessage.component';
import { InputMessageDataParm                 } from '@components/general/dialog/inputMessage/models/inputMessageDataParm';
import { CdkVirtualScrollViewport             } from '@angular/cdk/scrolling';
import { UltAcaoAssist } from '@models/assist/assist';
import { AssistService } from '@services/assist/assist.service';

export abstract class CarteiraMvtcComponent extends AbstractMainComponent{
  private COMPONENT_NAME: string = 'CarteiraMvtc';

  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_PAGINATOR_PAGE_SIZE  = this.COMPONENT_NAME + '_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = this.COMPONENT_NAME + '_paginatorPageIndex';
  private PRM_USR_PAGINATOR_PAGE_SIZE_HST_CNTB  = this.COMPONENT_NAME + '_paginatorPageSize' + 'HstCntb';
  private PRM_USR_PAGINATOR_PAGE_INDEX_HST_CNTB = this.COMPONENT_NAME + '_paginatorPageIndex' + 'HstCntb';

  //Controls de cada seção da pagina.
  public _controlMain                : ControlLoad;
  public _controlDadosBasicos        : ControlLoad;
  public _controlFilters             : ControlLoad;
  public _controlUsrComptList        : ControlLoad;
  public _controlItemGrCrctInvList   : ControlLoad;
  public _controlHstCntbMensal       : ControlLoad;
  public _controlMvtcList            : ControlLoad;
  public _controlTipMvtcGrCrctInvList: ControlLoad;
  public _controlLoadMvtc            : ControlLoad;
  public _controlDownloadMvtc        : ControlLoad;

  public _form;

  public _grCrctInvAcao: GrupoCrctInvAcao;
  private _cdGrCrctInvAcao: number;

  //Se estiver detalhando uma Carteira compartilhada, informe o cdUsrCompt.
  public _cdUsrCompt: number;

  //Lista dos usuarios com os quais a Carteira esta sendo compartilhada.
  public _usrComptGrCrctInvAcaoListDataSource      : MatTableDataSource< UsrComptGrCrctInvAcao >;

  //Lista dos itens pertencentes a Carteira.
  public _itemGrCrctInvAcaoListDisplayedColumns: string[] = [];
  public _itemGrCrctInvAcaoListDataSource      : MatTableDataSource< ItemGrupoCrctInvAcao >;
  @ViewChild( 'itemGrCrctInvAcaoListPaginator', {static: true} ) _itemGrCrctInvAcaoListPaginator: MatPaginator;
  @ViewChild( 'itemGrCrctInvAcaoListSort', {static: true} ) _itemGrCrctInvAcaoListSort: MatSort;
  public _itemGrCrctInvAcaoListPaginatorPageSize  = 20;
  public _itemGrCrctInvAcaoListPaginatorPageIndex = 1;

  @ViewChild( 'sidenavColumnsItemGrCrctInvAcaoList' ) sidenavColumnsItemGrCrctInvAcaoList: MatSidenav;
  public _itemGrCrctInvAcaoListAvailableColumns: ControlShowColumnItemGr[] = [ new ControlShowColumnItemGr( 'crctInvItens'          , 'Ativo'        , true , false ),
                                                                               new ControlShowColumnItemGr( 'tipInvItens'           , 'Tipo'         , false, true  ),
                                                                               new ControlShowColumnItemGr( 'emprItens'             , 'Empresa'      , false, true  ),
                                                                               new ControlShowColumnItemGr( 'vlSdoRlzdItens'        , 'Realizado'    , false, true  ),
                                                                               new ControlShowColumnItemGr( 'vlInvtAtuItens'        , 'Investido'    , true , true  ),
                                                                               new ControlShowColumnItemGr( 'qtdUndNegcItens'       , 'Qtd.'         , true , true  ),
                                                                               new ControlShowColumnItemGr( 'vlItens'               , 'Valor Atual'  , true , true  ),
                                                                               new ControlShowColumnItemGr( 'vlRendimentoItens'     , 'Rendimento'   , true , true  ),
                                                                               new ControlShowColumnItemGr( 'vlRendimentoPercItens' , 'Rendimento %' , true , true  ),
                                                                               new ControlShowColumnItemGr( 'vlSdoRstdItens'        , 'Resultado'    , false, true  ),
                                                                               new ControlShowColumnItemGr( 'assistItens'           , 'Sugestão'     , false, true  ),
                                                                               new ControlShowColumnItemGr( 'optionsItens'          , 'Opções'       , true , false )  ];


  //Historico contabil mensal da Carteira.
  public _hstCntbGrCrctInvAcaoListDisplayedColumns: string[] = [ 'dtRefHstCntb', 'vlSdoCptlHstCntb', 'vlSdoRlzdHstCntb', 'vlSdoCxHstCntb', 'vlSdoInvtHstCntb', 'vlSdoInvtAtlzdHstCntb', 'vlSdoRstdHstCntb', 'optionsHstCntb' ];
  public _hstCntbGrCrctInvAcaoListDataSource      : MatTableDataSource< HistoricoContabilGrupoCrctInvAcao >;
  @ViewChild( 'hstCntbGrCrctInvAcaoListPaginator', {static: true} ) _hstCntbGrCrctInvAcaoListPaginator: MatPaginator;
  @ViewChild( 'hstCntbGrCrctInvAcaoListSort', {static: true} ) _hstCntbGrCrctInvAcaoListSort: MatSort;
  public _hstCntbGrCrctInvAcaoListPaginatorPageSize  = 20;
  public _hstCntbGrCrctInvAcaoListPaginatorPageIndex = 1;

  //Lista das movimentacoes da Carteira.
  public _mvtcGrCrctInvAcaoListDisplayedColumns: string[] = [ 'dtRefMvtc', 'tipMvtc', 'crctInv', 'qtdUnd', 'prcUnd', 'vlTtlEncg', 'vlTtlMvtc', 'options' ];
  public _mvtcGrCrctInvAcaoListDataSource      : MatTableDataSource< MovimentacaoGrupoCrctInvAcao >;
  @ViewChild( 'mvtcGrCrctInvAcaoListPaginator', {static: true} ) _mvtcGrCrctInvAcaoListPaginator: MatPaginator;
  @ViewChild( 'mvtcGrCrctInvAcaoListSort', {static: true} ) _mvtcGrCrctInvAcaoListSort: MatSort;
  public _mvtcGrCrctInvAcaoListPaginatorPageSize  = 20;
  public _mvtcGrCrctInvAcaoListPaginatorPageIndex = 1;

  //Lista os tipos de movimentacao possíveis para numa carteira.
  public _tipMvtcGrCrctInvAcaoList: TipMvtcGrupoCrctInvAcao[];

  //Lista das movimentacoes a serem incluidas de forma massificada.
  public _mvtcMassFileListDisplayedColumns: string[] = [ 'isMvtcSelected', 'dtRefMvtc', 'tipMvtc', 'crctInv', 'qtdUnd', 'prcUnd', 'encg', 'vlTtlMvtc', 'options' ];
  public _mvtcMassFileListDataSource      : MatTableDataSource< ControlLoadMvtc >;
  @ViewChild( 'mvtcMassFileListPaginator', {static: false} ) _mvtcMassFileListPaginator: MatPaginator;
  public _mvtcMassFileListPaginatorPageSize  = 20;
  public _mvtcMassFileListPaginatorPageIndex = 1;

  constructor( protected router                   : Router                                         ,
               protected activatedRoute           : ActivatedRoute                                 ,
               protected sanitizer                : DomSanitizer                                   ,
               protected formBuilder              : FormBuilder                                    ,
               protected dialog                   : MatDialog                                      ,
               protected datePipe                 : DatePipe                                       ,
               protected decimalPipe              : DecimalPipe                                    ,
               protected shortNumberPipe          : ShortNumberPipe                                ,
               protected aplicationStateService   : AplicationStateService                         ,
               protected authenticationService    : AuthenticationService                          ,
               protected prmUsrService            : PrmUsrService                                  ,
               protected snackBar                 : MatSnackBar                                    ,
               protected generalDialogConfirm     : ComponentType< GeneralDialogConfirmComponent > ,
               protected generalDialogInputMessage: ComponentType< GeneralDialogInputMessageComponent >,
               protected carteiraMvtcDialogAdd    : ComponentType< CarteiraMvtcDialogAddComponent >,
               protected invService               : InvService                                     ,
               protected grCrctInvService         : GrCrctInvService                               ,
               protected mvtcGrCrctInvService     : MvtcGrCrctInvService                           ,
               protected assistService            : AssistService                                  ,
               protected cdr: ChangeDetectorRef ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    //console.log( 'ngOnInit()...' );

    this._controlMain                 = new ControlLoad( 'main' );
    this._controlDadosBasicos         = new ControlLoad( 'dadosBasicos' );
    this._controlFilters              = new ControlLoad( 'filters' );
    this._controlUsrComptList         = new ControlLoad( 'usrComptList' );
    this._controlItemGrCrctInvList    = new ControlLoad( 'crctInvcList' );
    this._controlHstCntbMensal        = new ControlLoad( 'resume' );
    this._controlMvtcList             = new ControlLoad( 'mvtcList' );
    this._controlTipMvtcGrCrctInvList = new ControlLoad( 'tipMvtcGrCrctInvList' );
    this._controlLoadMvtc             = new ControlLoad( 'loadMvtc' );
    this._controlDownloadMvtc         = new ControlLoad( 'downloadMvtc' );

    this._controlMain.initializingLoad();

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitGeneral = this.initGeneral();
    promises.push( pInitGeneral );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    const pInitFilters = this.initFilters();
    promises.push( pInitFilters );

    const pInitPanels = this.initPanels();
    promises.push( pInitPanels );

    this.monitoryInit( promises );
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    const _this = this;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          _this._controlMain.valueProgressBar = promisesFinish / qtPromises * 100;
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        _this._controlMain.loaded();
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      let dateFrom = new Date();
      let dateEnd  = new Date();

      dateFrom.setDate( dateFrom.getDate() - 90 );

      _this._form = _this.formBuilder.group(
        { isShowAlsoItemGrCrctInvAcaoNotInvestment: false,
          isShowAlsoItemGrCrctInvAcaoRemoved      : false,
          isShowGrCrctInvAll                      : false,
          dtHstCntbFrom                           : dateFrom,
          dtHstCntbEnd                            : dateEnd ,
          dtMvtcFrom                              : dateFrom,
          dtMvtcEnd                               : dateEnd ,
          caracterSeparadorDecimalSelected        : ','     ,
          delimiterSelected                       : ';'
         }
      );

      resolve();
    } );

    return p;
  }

  private isShowGrCrctInvAll(){
    return this._form.controls[ 'isShowGrCrctInvAll' ].value;
  }

  private setShowGrCrctInvAll( isShowGrCrctInvAll: boolean ){
    this._form.patchValue( { 'isShowGrCrctInvAll': isShowGrCrctInvAll } );
  }

  private isShowAlsoItemGrCrctInvAcaoNotInvestment(){
    return this._form.controls[ 'isShowAlsoItemGrCrctInvAcaoNotInvestment' ].value;
  }

  private setShowAlsoItemGrCrctInvAcaoNotInvestment( isValue: boolean ){
    this._form.patchValue( { 'isShowAlsoItemGrCrctInvAcaoNotInvestment': isValue } );
  }

  private isShowAlsoItemGrCrctInvAcaoRemoved(){
    return this._form.controls[ 'isShowAlsoItemGrCrctInvAcaoRemoved' ].value;
  }

  private setShowAlsoItemGrCrctInvAcaoRemoved( isValue: boolean ){
    this._form.patchValue( { 'isShowAlsoItemGrCrctInvAcaoRemoved': isValue } );
  }

  private getDtHstCntbFrom(): Date{
    return this._form.controls[ 'dtHstCntbFrom' ].value;
  }

  private setDtHstCntbFrom( dt: Date ){
    this._form.patchValue( { 'dtHstCntbFrom': dt } );
  }

  private getDtHstCntbEnd(): Date{
    return this._form.controls[ 'dtHstCntbEnd' ].value;
  }

  private setDtHstCntbEnd( dt: Date ){
    this._form.patchValue( { 'dtHstCntbEnd': dt } );
  }

  private getDtMvtcFrom(): Date{
    return this._form.controls[ 'dtMvtcFrom' ].value;
  }

  private setDtMvtcFrom( dt: Date ){
    this._form.patchValue( { 'dtMvtcFrom': dt } );
  }

  private getDtMvtcEnd(): Date{
    return this._form.controls[ 'dtMvtcEnd' ].value;
  }

  private setDtMvtcEnd( dt: Date ){
    this._form.patchValue( { 'dtMvtcEnd': dt } );
  }

  private getDelimiterSelected(): string{
    return this._form.controls[ 'delimiterSelected' ].value;
  }

  private setDelimiterSelected( charDelimiter: string ){
    //console.log( 'setDelimiterSelected( ' + charDelimiter + ' )...' );

    this._form.patchValue( { 'delimiterSelected': charDelimiter } );
  }

  private getSeparadorDecimalSelected(): string{
    return this._form.controls[ 'caracterSeparadorDecimalSelected' ].value;
  }

  private setSeparadorDecimalSelected( charDelimiter: string ){
    this._form.patchValue( { 'caracterSeparadorDecimalSelected': charDelimiter } );
  }

  private getCdGrCrctInvAcao(): number{
    return this._cdGrCrctInvAcao;
  }

  private getCdUsrCompt(): number{
    return this._cdUsrCompt;
  }

  /**
   * Retorna se a coluna 'Sugestão' está marcada para ser apresentada ou não.
   */
  private isAllowedShowColumnSugestao(): boolean{
    const control: ControlShowColumnItemGr = this.getControlShowColumnItemGrAssistItens();

    return control != null && control.isAllowedShow === true;
  }

  /**
   * Retorna o ControlShowColumnItemGr referente à coluna 'assistItens'.
   */
  private getControlShowColumnItemGrAssistItens(): ControlShowColumnItemGr{
    return this.getControlShowColumnItemGr( 'assistItens' );
  }

  /**
   * Retorna o ControlShowColumnItemGr que possua o nome informado em nmColumn.
   * @param nmColumn Nome da coluna.
   */
  private getControlShowColumnItemGr( nmColumn: string ): ControlShowColumnItemGr{
    const qtControls: number = this._itemGrCrctInvAcaoListAvailableColumns != null ? this._itemGrCrctInvAcaoListAvailableColumns.length : 0;

    let controlTarget: ControlShowColumnItemGr = null;

    for( let index = 0; index < qtControls; index++ ){
      const controlIterate: ControlShowColumnItemGr = this._itemGrCrctInvAcaoListAvailableColumns[ index ];

      const nmControlIterate = controlIterate.nm;

      if( nmControlIterate === nmColumn ){
        controlTarget = controlIterate;
        break;
      }
    }

    return controlTarget;
  }

  public isAllowedShowUsrComptList(): boolean{
    let isAllowed = this._grCrctInvAcao != null && 
                    this._grCrctInvAcao.tipGrCrctInvAcao != null && this._grCrctInvAcao.tipGrCrctInvAcao.sglTipGrCrctInvAcao === 'PRIV' &&
                    this._usrComptGrCrctInvAcaoListDataSource != null && this._usrComptGrCrctInvAcaoListDataSource.data != null;

    isAllowed = isAllowed === true && this._grCrctInvAcao.usr != null && this._grCrctInvAcao.usr.id === this.authenticationService.getSessionService().currentUserValue.id;

    return isAllowed;
  }


  public isGrCrctInvAcaoCompt(): boolean{
    let isReturn = this._grCrctInvAcao != null ? this._grCrctInvAcao.usr.id != this.authenticationService.getSessionService().currentUserValue.id : null;

    return isReturn;
  }


  public getTooltipToCotcInv( itemGrupoCrctInvAcao: ItemGrupoCrctInvAcao ): string{
    let tooltipStr = "";

    const crctInv = itemGrupoCrctInvAcao.crctInv != null ? itemGrupoCrctInvAcao.crctInv : null;
    const crctInvAcao = crctInv != null ? crctInv.crctInvAcao : null;
    const cotcAcao = crctInvAcao != null ? crctInvAcao.cotcAcao : null;

    if( cotcAcao != null ){
      const dtCotcStr    : string = this.datePipe.transform( cotcAcao.dtCotc, 'dd/MM/yyyy', '+0000' );
      const prcAbertStr  : string = this.decimalPipe.transform( cotcAcao.prcAbert   , '1.2-2' );
      const prcMinStr    : string = this.decimalPipe.transform( cotcAcao.prcMin     , '1.2-2' );
      const prcMaxStr    : string = this.decimalPipe.transform( cotcAcao.prcMax     , '1.2-2' );
      const prcFechStr   : string = this.decimalPipe.transform( cotcAcao.prcFech    , '1.2-2' );
      const volNegcStr   : string = this.shortNumberPipe.transform( cotcAcao.volNegc, '1.0-0' );
      const varPrcFechStr: string = this.decimalPipe.transform( cotcAcao.varPrcFech , '1.2-2' );

      tooltipStr += ( 'Cotação no dia ' + dtCotcStr + ":" + "\n" );
      tooltipStr += ( 'Preço Abert.: ' + prcAbertStr + "\n" );
      tooltipStr += ( 'Preço Mín./Máx.: ' + prcMinStr + ' - ' + prcMaxStr + "\n" );
      tooltipStr += ( 'Preço Fech.: ' + prcFechStr + "\n" );
      tooltipStr += ( 'Volume de Neg.: ' + volNegcStr + "\n" );
      tooltipStr += ( 'Variação: ' + varPrcFechStr + ' %' + "\n" );
    }

    return tooltipStr;
  }


  public getTooltipToVlSdoInvt( hstCntbGrCrctInvAcao: HistoricoContabilGrupoCrctInvAcao ): string{
    const itemGrupoCrctInvAcaoList: ItemGrupoCrctInvAcao[] = hstCntbGrCrctInvAcao.itemGrupoCrctInvAcaoList;
    const qtItemGr = itemGrupoCrctInvAcaoList !=null ? itemGrupoCrctInvAcaoList.length : 0;

    let tooltipStr = "";

    for( let indexItemGr = 0; indexItemGr < qtItemGr; indexItemGr++ ){
      const itemGrIterate     = itemGrupoCrctInvAcaoList[ indexItemGr ];

      const crctInvIterate               = itemGrIterate  != null ? itemGrIterate.crctInv : null;
      const nmInvIterate                 = crctInvIterate != null ? crctInvIterate.nmInv : null;
      const qtdUndNegcIterate            = itemGrIterate  != null ? itemGrIterate.qtdUndNegc : 0;
      const qtdUndNegcStrIterate         = this.decimalPipe.transform( qtdUndNegcIterate, '1.0-3' );
      const prcMedAtuIterate             = itemGrIterate  != null ? itemGrIterate.prcMedAtu : 0;
      const prcMedAtuStrIterate          = this.decimalPipe.transform( prcMedAtuIterate , '1.2-2' );
      const vlSdoInvtIterate             = itemGrIterate  != null ? itemGrIterate.vlSdoInvt : 0;

      tooltipStr += nmInvIterate != null ? nmInvIterate : '';
      tooltipStr += ' (' + qtdUndNegcStrIterate + ' a R$ ' + prcMedAtuStrIterate + '): ';
      tooltipStr += 'R$ ' + vlSdoInvtIterate + '\n';

      tooltipStr += '\n';
    }

    return tooltipStr;
  }


  public getTooltipToVlSdoInvtAtlzd( hstCntbGrCrctInvAcao: HistoricoContabilGrupoCrctInvAcao ): string{
    const vlSdoInvt        = hstCntbGrCrctInvAcao.vlSdoInvt        != null ? hstCntbGrCrctInvAcao.vlSdoInvt        : 0;
    const vlVarSdoInvt     = hstCntbGrCrctInvAcao.vlVarSdoInvt     != null ? hstCntbGrCrctInvAcao.vlVarSdoInvt     : 0;
    const vlVarPercSdoInvt = hstCntbGrCrctInvAcao.vlVarPercSdoInvt != null ? hstCntbGrCrctInvAcao.vlVarPercSdoInvt : 0;

    const vlSdoInvtStr        = this.decimalPipe.transform( vlSdoInvt       , '1.2-2' );
    const vlVarSdoInvtStr     = this.decimalPipe.transform( vlVarSdoInvt    , '1.2-2' );
    const vlVarPercSdoInvtStr = this.decimalPipe.transform( vlVarPercSdoInvt, '1.1-1' );

    const itemGrupoCrctInvAcaoList: ItemGrupoCrctInvAcao[] = hstCntbGrCrctInvAcao.itemGrupoCrctInvAcaoList;
    const qtItemGr = itemGrupoCrctInvAcaoList !=null ? itemGrupoCrctInvAcaoList.length : 0;

    let tooltipStr = "";

    tooltipStr += 'Investido: ' + vlSdoInvtStr + '\n';
    tooltipStr += 'Variação: ' + ( vlVarSdoInvt >= 0 ? '+' : '' ) + ( vlVarSdoInvtStr ) + ' ( ' + ( vlVarPercSdoInvt >= 0 ? '+' : '' ) + vlVarPercSdoInvtStr + '% )' + '\n\n';

    for( let indexItemGr = 0; indexItemGr < qtItemGr; indexItemGr++ ){
      const itemGrIterate     = itemGrupoCrctInvAcaoList[ indexItemGr ];

      const crctInvIterate               = itemGrIterate  != null ? itemGrIterate.crctInv : null;
      const crctInvAcaoIterate           = crctInvIterate != null ? crctInvIterate.crctInvAcao : null;
      const nmInvIterate                 = crctInvIterate != null ? crctInvIterate.nmInv : null;
      const qtdUndNegcIterate                = itemGrIterate  != null ? itemGrIterate.qtdUndNegc : 0;
      const qtdUndNegcStrIterate             = itemGrIterate  != null ? this.decimalPipe.transform( itemGrIterate.qtdUndNegc, '1.0-3' ) : 0;
      const vlSdoInvtIterate                 = itemGrIterate  != null ? itemGrIterate.vlSdoInvt : 0;
      const cotcAcaoIterate                  = crctInvAcaoIterate != null ? crctInvAcaoIterate.cotcAcao : null;
      const prcFechIterate                   = cotcAcaoIterate != null ? cotcAcaoIterate.prcFech : 0;
      const prcFechStrIterate                = this.decimalPipe.transform( prcFechIterate, '1.2-2' );
      const vlSdoInvtcAtlzdIterate           = qtdUndNegcIterate * prcFechIterate;
      const vlSdoInvtcAtlzdStrIterate        = this.decimalPipe.transform( vlSdoInvtcAtlzdIterate, '1.2-2' );
      const vlVarSdoInvtAtlzdIterate         = vlSdoInvtcAtlzdIterate - vlSdoInvtIterate;
      const vlVarSdoInvtcAtlzdStrIterate     = this.decimalPipe.transform( vlVarSdoInvtAtlzdIterate, '1.2-2' );
      const vlVarPercSdoInvtcAtlzdStrIterate = this.decimalPipe.transform( vlVarSdoInvtAtlzdIterate/vlSdoInvtIterate*100, '1.1-1' );

      tooltipStr += nmInvIterate != null ? nmInvIterate : '';
      tooltipStr += ' (' + qtdUndNegcStrIterate + ' a R$ ' + prcFechStrIterate + '): ';
      tooltipStr += 'R$ ' + vlSdoInvtcAtlzdStrIterate + '\n';
      tooltipStr += 'Valorização de R$ ' + vlVarSdoInvtcAtlzdStrIterate + ', ' + vlVarPercSdoInvtcAtlzdStrIterate + '%' + '\n';

      tooltipStr += '\n';
    }

    return tooltipStr;
  }


  public getTooltipToVlSdoRstd( hstCntbGrCrctInvAcao: HistoricoContabilGrupoCrctInvAcao ): string{
    let tooltipStr = "";

    const vlSdoRlzd         = hstCntbGrCrctInvAcao.vlSdoRlzd != null ? hstCntbGrCrctInvAcao.vlSdoRlzd : 0;
    const vlSdoRlzdStr      = this.decimalPipe.transform( vlSdoRlzd, '1.2-2' );

    const vlSdoInvt         = hstCntbGrCrctInvAcao.vlSdoInvt;
    const vlSdoInvtStr      = this.decimalPipe.transform( vlSdoInvt, '1.2-2' );

    const vlSdoInvtAtlzd    = hstCntbGrCrctInvAcao.vlSdoInvtAtlzd;
    const vlSdoInvtAtlzdStr = this.decimalPipe.transform( vlSdoInvtAtlzd, '1.2-2' );

    const vlRendimento      = vlSdoInvtAtlzd - vlSdoInvt;
    const vlRendimentoStr   = this.decimalPipe.transform( vlRendimento, '1.2-2' );

    tooltipStr += "Rendimento: " + vlRendimentoStr  + " (+)" + '\n';
    tooltipStr += "   - Investido Atualizado: " + vlSdoInvtAtlzdStr + '\n';
    tooltipStr += "   - Investido: " + vlSdoInvtStr + '\n';
    tooltipStr += "Realizado: " + vlSdoRlzdStr + " (+)" + '\n';

    return tooltipStr;
  }


  private initGeneral(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this.loadTipMvtcGrCrctInvList( null, null )
        .then( response => {
          resolve();
        } )
        .catch( error => {
          console.error( error );
          reject();
        } );
    } );

    return p;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Recupera os ativos que estao salvos como prmUsr.
      const paginatorPageSizeOfPrmUsr  = _this.prmUsrService.getValueInt( _this.PRM_USR_PAGINATOR_PAGE_SIZE  );
      const paginatorPageIndexOfPrmUsr = _this.prmUsrService.getValueInt( _this.PRM_USR_PAGINATOR_PAGE_INDEX );

      if( paginatorPageSizeOfPrmUsr != null ){
        _this._mvtcGrCrctInvAcaoListPaginatorPageSize = paginatorPageSizeOfPrmUsr;
      }

      if( paginatorPageIndexOfPrmUsr != null ){
        _this._mvtcGrCrctInvAcaoListPaginatorPageIndex = paginatorPageIndexOfPrmUsr;
      }

      resolve();
    } );

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Verifica se houve o recebimento de algum parm.
      _this.activatedRoute.queryParams.subscribe( params => {
        const cdGrCrctInvAcao = params.cdGrCrctInvAcao;
        const cdUsrCompt      = params.cdUsrCompt;

        if( cdGrCrctInvAcao != null ){
          _this._cdGrCrctInvAcao = cdGrCrctInvAcao;
          _this._cdUsrCompt      = cdUsrCompt && cdUsrCompt != null ? cdUsrCompt : null;

          const promises: Promise< any >[] = [];

          //Detalha a Carteira
          _this._controlDadosBasicos.isCanShow = true;
          const pGrCrctInvAcao = _this.onLoadGrCrctInvList();
          promises.push( pGrCrctInvAcao );

          pGrCrctInvAcao
            .then( respGrCrctInvAcao => {
              //console.log( 'pGrCrctInvAcao.then' );

              if( _this._grCrctInvAcao != null ){
                _this.aplicationStateService.setTitle( 'Carteira ' + _this._grCrctInvAcao.nmGrCrctInvAcao );
              }

              let dtRefMvtcFrom = new Date();
              let dtRefMvtcTo   = _this._grCrctInvAcao != null ? new Date( _this._grCrctInvAcao.dtRefMvtcGreater ) : new Date();

              //Altera a data de inicio para 90 dias antes do ultimo movimento.
              dtRefMvtcFrom.setTime( dtRefMvtcTo.getTime() - ( 1000*60*60*24*90 ) );

              //Altera a data de término para 30 dias após o ultimo movimento.
              //dtRefMvtcTo.setDate( dtRefMvtcTo.getDate() + 30 );

              _this.setDtMvtcFrom( dtRefMvtcFrom );
              //_this.setDtMvtcEnd( dtRefMvtcTo );
            } )
            .catch( errGrCrctInvAcao => {
              console.log( 'pGrCrctInvAcao.catch' );
              console.error( errGrCrctInvAcao );
            } );

          const pItemGrCrctInv = _this.loadItemGrCrctInvList();
          promises.push( pItemGrCrctInv );

          //const pMvtc = _this.onLoadMvtcGrCrctInvAcao( cdGrCrctInvAcao );
          //promises.push( pMvtc );

          //Monitora a finalizacao de todas as tarefas.
          Promise.all( promises )
            .then( response => {
              resolve();
            } );
        }
        else{
          resolve();
        }
      } );
    } );

    return p;
  }

  private initFilters(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initPanels(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }


  private onLoadGrCrctInvList(): Promise< any >{
    const promises = [];

    if( this._controlDadosBasicos.isCanShow === true && this._controlDadosBasicos.isLoading() === false ){
      promises.push( this.loadGrCrctInvList() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          reject();
        } );
    } );

    return p;
  }

  private loadGrCrctInvList(): Promise< any >{
    this._controlDadosBasicos.initializingLoad();

    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao: number = _this.getCdGrCrctInvAcao();
      const cdUsrCompt     : number = _this.getCdUsrCompt();

      _this.grCrctInvService.detailGrupo( cdGrCrctInvAcao, cdUsrCompt )
        .then( grCrctInvAcao => {
          _this._grCrctInvAcao = grCrctInvAcao;

          //Se Carteira for Privada, obtem a lista de usuarios com os quais ela esta sendo compartilhada.
          if( grCrctInvAcao != null && grCrctInvAcao.tipGrCrctInvAcao != null && grCrctInvAcao.tipGrCrctInvAcao.sglTipGrCrctInvAcao === 'PRIV' ){
            _this._controlUsrComptList.isCanShow = true;
            _this.onLoadUsrComptGrCrctInvList();
          }
          else{
            _this._controlUsrComptList.isCanShow = false;
          }
        } )
        .catch( error => {
          console.log( 'Ocorreu um erro ao tentar detalhar a Carteira.' );

          console.error( error );
        } )
        .finally( () => {
          _this._controlDadosBasicos.loaded();

          resolve();
        } );
    } );

    return p;
  }


  private onLoadUsrComptGrCrctInvList(): Promise< any >{
    const promises = [];

    if( this._controlUsrComptList.isCanShow === true ){
      promises.push( this.loadUsrComptGrCrctInvList() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          reject();
        } );
    } );

    return p;
  }

  private loadUsrComptGrCrctInvList(): Promise< any >{
    this._controlUsrComptList.initializingLoad();

    const _this = this;

    if( _this._usrComptGrCrctInvAcaoListDataSource == null ){
      _this._usrComptGrCrctInvAcaoListDataSource           = new MatTableDataSource< UsrComptGrCrctInvAcao >( [] );
    }

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao: number = _this.getCdGrCrctInvAcao();

      _this.grCrctInvService.listUsrComptGrCrctInvAcao( cdGrCrctInvAcao )
        .then( usrComptGrupoCrctInvAcaoList => {
          _this._usrComptGrCrctInvAcaoListDataSource.data = usrComptGrupoCrctInvAcaoList;
        } )
        .catch( error => {
          console.error( error );
        } )
        .finally( () => {
          _this._controlUsrComptList.loaded();

          resolve();
        } );
    } );

    return p;
  }


  private onLoadItemGrCrctInvAcaoList(): Promise< any >{
    const promises = [];

    if( this._controlItemGrCrctInvList.isCanShow === true ){
      promises.push( this.loadItemGrCrctInvList() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          reject();
        } );
    } );

    return p;
  }

  private loadItemGrCrctInvList(): Promise< any >{
    this._controlItemGrCrctInvList.initializingLoad();

    const _this = this;

    if( _this._itemGrCrctInvAcaoListDataSource == null ){
      _this._itemGrCrctInvAcaoListDataSource           = new MatTableDataSource< ItemGrupoCrctInvAcao >( [] );
      _this._itemGrCrctInvAcaoListDataSource.paginator = _this._itemGrCrctInvAcaoListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao: number = _this.getCdGrCrctInvAcao();
      const isShowAlsoItemGrCrctInvAcaoRemoved: boolean = _this.isShowAlsoItemGrCrctInvAcaoRemoved();
      const isInItemGrCrctInvAcaoAtivo: boolean = isShowAlsoItemGrCrctInvAcaoRemoved ? null : true;
      const cdUsrCompt: number = _this.getCdUsrCompt();

      //console.log( 'isShowAlsoItemGrCrctInvAcaoRemoved: ' + isShowAlsoItemGrCrctInvAcaoRemoved );

      _this.grCrctInvService.listItensGrupo( cdGrCrctInvAcao, null, null, cdUsrCompt, true, true, true, true, true, true )
        .then( itemGrupoCrctInvAcaoList => {
          const isAllowedShowColumnSugestao: boolean = _this.isAllowedShowColumnSugestao();

          console.log( 'Permitido mostrar a coluna Sugestao? ' + isAllowedShowColumnSugestao );

          //Ativa a visualizacao de todos ativos.
          itemGrupoCrctInvAcaoList.forEach( element => {
            //console.log( 'inItemGrCrctInvAcaoAtivo: ' + element.inItemGrCrctInvAcaoAtivo );

            element.inItemGrCrctInvAcaoAtivo = element.inItemGrCrctInvAcaoAtivo ? true : false;

            element.controlShow               = new ControlShowItemGrCrctInvAcao( null );

            //Se usuario nao marcou o checkbox, mostra na lista de ativos apenas aqueles que estejam ativos na Carteira.
            //Se usuario marcou o checkbox, mostra na lista de ativos todos, independente se estejam ativos ou nao na Carteira.
            element.controlShow.isAllowedShow = isShowAlsoItemGrCrctInvAcaoRemoved === false ? element.inItemGrCrctInvAcaoAtivo : true;

            element.controlShow.isAllowedShowMvtcItemGrCrctInvAcao = true;

            //Verifica se ha alguma acao sugerida por algum assistente, desde que a coluna 'Sugestão' esteja marcada para ser visualizada.
            if( isAllowedShowColumnSugestao === true && ( _this._grCrctInvAcao == null || _this._grCrctInvAcao.qtAssist > 0 ) ){
              _this.assistService.listUltAcaoAssist( cdGrCrctInvAcao, element.crctInv.cdInv )
                .then( respListUltAcao => {
                  element.ultAcaoAssistList = respListUltAcao;
                } );
            }
          } );

          _this._itemGrCrctInvAcaoListDataSource.data      = itemGrupoCrctInvAcaoList;
          _this._itemGrCrctInvAcaoListDataSource.paginator = _this._itemGrCrctInvAcaoListPaginator;

          _this._itemGrCrctInvAcaoListDataSource.sortingDataAccessor = ( item, property ) => {
            switch( property ) {
              case 'crctInvItens'         : return item.crctInv.tipInv.cdTipInv === 4 ? item.crctInv.crctInvAcao.nmPregao : item.crctInv.nmInv;
              case 'tipInvItens'          : return item.crctInv.tipInv.nmTipInv;
              case 'emprItens'            : return item.crctInv.empr.nmAbrvEmpr ? item.crctInv.empr.nmAbrvEmpr : item.crctInv.empr.nmEmpr;
              case 'vlSdoRlzdItens'       : return item.vlSdoRlzd;
              case 'vlInvtAtuItens'       : return item.vlSdoInvt;
              case 'qtdUndNegcItens'      : return item.qtdUndNegc;
              case 'vlItens'              : return item.vlTtlUndNegc;
              case 'vlRendimentoItens'    : return ( item.vlTtlUndNegc != null && item.vlSdoInvt !=null ) ? ( item.vlTtlUndNegc - item.vlSdoInvt ) : 0;
              case 'vlRendimentoPercItens': return ( item.vlTtlUndNegc != null && item.vlSdoInvt !=null ) ? ( ( item.vlTtlUndNegc - item.vlSdoInvt ) / item.vlSdoInvt * 100 ) : 0;
              case 'vlSdoRstdItens'       : return item.vlSdoRstd;
              case 'assistItens'          :
                if( item.ultAcaoAssistList != null && item.ultAcaoAssistList.length > 0 ){
                  return item.ultAcaoAssistList[0].hstAcaoAssist.dtRefAcaoAssist;
                }
                else{
                  return '';
                }

              default: return item[ property ];
            }
          };

          _this._itemGrCrctInvAcaoListDataSource.sort = _this._itemGrCrctInvAcaoListSort;

          _this._itemGrCrctInvAcaoListDataSource.filterPredicate = ( d: ItemGrupoCrctInvAcao, filter: string) => {
            try{
              //console.log( '_itemGrCrctInvAcaoListDataSource.filterPredicate...' );

              return _this.isItemGrCrctInvAllowedShow( d );
            }
            catch( error ){
              console.error( error );
              return false;
            }
          };

          _this.execFilterItemGrCrctInvAcao( '_' );
        } )
        .catch( error => {
          console.error( error );
        } )
        .finally( () => {
          _this.updateShowColumnsItemGrCrctInvAcao();
          _this._controlItemGrCrctInvList.loaded();

          resolve();
        } );
    } );

    return p;
  }

  private updateInfoOfItemGrCrctInv( cdInv: number ): Promise< any >{
    //console.log( 'updateInfoOfItemGrCrctInv( ' + cdInv + ' )...' );

    this._controlItemGrCrctInvList.initializingLoad();

    const _this = this;

    if( _this._itemGrCrctInvAcaoListDataSource == null ){
      _this._itemGrCrctInvAcaoListDataSource           = new MatTableDataSource< ItemGrupoCrctInvAcao >( [] );
      _this._itemGrCrctInvAcaoListDataSource.paginator = _this._itemGrCrctInvAcaoListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao: number = _this.getCdGrCrctInvAcao();

      _this.grCrctInvService.listItensGrupo( cdGrCrctInvAcao, cdInv, null, null, true, true, true, true, true, false )
        .then( itemGrupoCrctInvAcaoList => {
          //console.log( 'itemGrupoCrctInvAcaoList: ' + itemGrupoCrctInvAcaoList );

          const qtItensGrDetailed = itemGrupoCrctInvAcaoList != null ? itemGrupoCrctInvAcaoList.length : 0;

          for( let indexItemDetailed = 0; indexItemDetailed < qtItensGrDetailed; indexItemDetailed++ ){
            const itemGrDetailed = itemGrupoCrctInvAcaoList[ indexItemDetailed ];

            itemGrDetailed.inItemGrCrctInvAcaoAtivo = itemGrDetailed.inItemGrCrctInvAcaoAtivo ? true : false;

            //Procura o item naqueles atualmente apresentados ao usuario.
            _this.updateItensGrCrctInv( [ itemGrDetailed ] );
          }
        } )
        .catch( error => {
          console.error( error );
        } )
        .finally( () => {
          _this._controlItemGrCrctInvList.loaded();

          resolve();
        } );
    } );

    return p;
  }

  private updateItensGrCrctInv( itemGrUpdatedList: ItemGrupoCrctInvAcao[] ): void{
    //console.log( 'updateItemGrCrctInv...' );

    const qtItemGrUpdated = itemGrUpdatedList != null ? itemGrUpdatedList.length : 0;

    //Procura o item naqueles atualmente apresentados ao usuario.
    const itemGrCrctInvAcaoList = this._itemGrCrctInvAcaoListDataSource.data;
    const qtItemGrCrctInvAcao = itemGrCrctInvAcaoList != null ? itemGrCrctInvAcaoList.length : 0;

    for( let indexItemGrUpdated = 0; indexItemGrUpdated < qtItemGrUpdated; indexItemGrUpdated++ ){
      const itemGrCrctInvAcao = itemGrUpdatedList[ indexItemGrUpdated ];

      for( let indexItemGr = 0; indexItemGr < qtItemGrCrctInvAcao; indexItemGr++ ){
        const itemGrIterate = itemGrCrctInvAcaoList[ indexItemGr ];

        if( itemGrIterate.crctInv.cdInv === itemGrCrctInvAcao.crctInv.cdInv ){
          itemGrCrctInvAcao.controlShow = itemGrIterate.controlShow;

          itemGrCrctInvAcaoList.splice( indexItemGr, 1, itemGrCrctInvAcao );
          //console.log( 'Item found: ' + itemGrCrctInvAcao.crctInv.cdInv + '-' + itemGrCrctInvAcao.crctInv.nmInv + ' - Ativo? ' + itemGrCrctInvAcao.inItemGrCrctInvAcaoAtivo );
          break;
        }
      }
    }

    this._itemGrCrctInvAcaoListDataSource.data = itemGrCrctInvAcaoList;

    this.execFilterItemGrCrctInvAcao( 'updateItensGrCrctInv' );
  }

  private execFilterItemGrCrctInvAcao( filterValue: string ){
    //console.log( 'execFilterMvtcGrCrctInvAcao( ' + filterValue + ' )...' );

    if( this._itemGrCrctInvAcaoListDataSource && this._itemGrCrctInvAcaoListDataSource != null ){
      //console.log( '_itemGrCrctInvAcaoListDataSource exist.' );

      this._itemGrCrctInvAcaoListDataSource.filter = filterValue && filterValue != null ? filterValue.trim().toLowerCase() : '';
    }
  }

  private updateShowColumnsItemGrCrctInvAcao(): void{
    const namesColumns = [];

    this._itemGrCrctInvAcaoListAvailableColumns.forEach( column => {
      if( column.isAllowedShow === true ){
        namesColumns.push( column.nm );
      }
    } );

    this._itemGrCrctInvAcaoListDisplayedColumns = namesColumns;
  }

  private onLoadHstCntbGrCrctInvAcao(): Promise< any >{
    const promises = [];

    if( this._controlHstCntbMensal.isCanShow === true ){
      promises.push( this.loadHstCntbGrCrctInvAcao() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          reject();
        } );
    } );

    return p;
  }

  private loadHstCntbGrCrctInvAcao(): Promise< any >{
    this._controlHstCntbMensal.initializingLoad();

    const _this = this;

    if( _this._hstCntbGrCrctInvAcaoListDataSource == null ){
      _this._hstCntbGrCrctInvAcaoListDataSource           = new MatTableDataSource< HistoricoContabilGrupoCrctInvAcao >( [] );
      _this._hstCntbGrCrctInvAcaoListDataSource.paginator = _this._hstCntbGrCrctInvAcaoListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao: number = _this.getCdGrCrctInvAcao();
      const dtRefHstCntbFrom: Date  = _this.getDtHstCntbFrom();
      const dtRefHstCntbTo: Date    = _this.getDtHstCntbEnd();
      const cdUsrCompt: number      = _this.getCdUsrCompt();

      _this.grCrctInvService.listHstCntbGrCrctInvAcao( cdGrCrctInvAcao, dtRefHstCntbFrom, dtRefHstCntbTo, cdUsrCompt )
        .then( hstCntbGrCrctInvAcaoList => {
          _this._hstCntbGrCrctInvAcaoListDataSource.data      = hstCntbGrCrctInvAcaoList;
          _this._hstCntbGrCrctInvAcaoListDataSource.paginator = _this._hstCntbGrCrctInvAcaoListPaginator;

          _this._hstCntbGrCrctInvAcaoListDataSource.sortingDataAccessor = ( item, property ) => {
            switch( property ) {
              case 'dtRefHstCntb'         : return item.dtRefHstCntb;
              case 'vlSdoCptlHstCntb'     : return item.vlSdoCptl;
              case 'vlSdoRlzdHstCntb'     : return item.vlSdoRlzd;
              case 'vlSdoCxHstCntb'       : return item.vlSdoCx;
              case 'vlSdoInvtHstCntb'     : return item.vlSdoInvt;
              case 'vlSdoInvtAtlzdHstCntb': return item.vlSdoInvtAtlzd;
              case 'vlSdoRstdHstCntb'     : return item.vlSdoRstd;

              default: return item[ property ];
            }
          };

          _this._hstCntbGrCrctInvAcaoListDataSource.sort = _this._hstCntbGrCrctInvAcaoListSort;
        } )
        .catch( error => {
          console.error( error );
          _this.showMessageError( error, 'OK' );

          _this._hstCntbGrCrctInvAcaoListDataSource.data      = null;
          _this._hstCntbGrCrctInvAcaoListDataSource.paginator = _this._hstCntbGrCrctInvAcaoListPaginator;
        } )
        .finally( () => {
          _this._controlHstCntbMensal.loaded();

          resolve();
        } );
    } );

    return p;
  }


  private onLoadMvtcGrCrctInvAcao(): Promise< any >{
    const promises = [];

    if( this._controlMvtcList.isCanShow === true ){
      promises.push( this.loadMvtcGrCrctInvAcao() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          reject();
        } );
    } );

    return p;
  }

  private loadMvtcGrCrctInvAcao(): Promise< any >{
    //console.log( 'loadMvtcGrCrctInvAcao()...' );

    this._controlMvtcList.initializingLoad();

    const _this = this;

    if( _this._mvtcGrCrctInvAcaoListDataSource == null ){
      _this._mvtcGrCrctInvAcaoListDataSource           = new MatTableDataSource< MovimentacaoGrupoCrctInvAcao >( [] );
      _this._mvtcGrCrctInvAcaoListDataSource.paginator = _this._mvtcGrCrctInvAcaoListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      const cdGrCrctInvAcao: number = _this._cdGrCrctInvAcao;
      const dtMvtcFrom     : Date   = _this.getDtMvtcFrom();
      const dtMvtcEnd      : Date   = _this.getDtMvtcEnd();
      const cdUsrCompt     : number = _this.getCdUsrCompt();

      _this.mvtcGrCrctInvService.list( cdGrCrctInvAcao, dtMvtcFrom, dtMvtcEnd, cdUsrCompt )
        .then( mvtcGrCrctInvAcaoList => {
          _this._mvtcGrCrctInvAcaoListDataSource.data      = mvtcGrCrctInvAcaoList;
          _this._mvtcGrCrctInvAcaoListDataSource.paginator = _this._mvtcGrCrctInvAcaoListPaginator;

          _this._mvtcGrCrctInvAcaoListDataSource.sortingDataAccessor = ( item, property ) => {
            switch( property ) {
              case 'dtRefMvtc': return item.dtRefMvtc;
              case 'tipMvtc'  : return item.tipMvtc.nmTipMvtcGrCrctInvAcao;
              case 'crctInv'  : return item.crctInv != null ? item.crctInv.nmInv : null;
              case 'qtdUnd'   : return item.qtdUndNegc;
              case 'prcUnd'   : return item.prcUndNegc;
              case 'vlTtlEncg': return item.vlTtlEncg;
              case 'vlTtlMvtc': return item.vlTtlMvtc;

              default: return item[ property ];
            }
          };

          _this._mvtcGrCrctInvAcaoListDataSource.sort = _this._mvtcGrCrctInvAcaoListSort;

          _this._mvtcGrCrctInvAcaoListDataSource.filterPredicate = ( d: MovimentacaoGrupoCrctInvAcao, filter: string) => {
            try{
              //console.log( '_mvtcGrCrctInvAcaoListDataSource.filterPredicate...' );

              const itemGrCrctInvAcao = d.itemGrCrctInvAcao;
              const crctInv = itemGrCrctInvAcao.crctInv;
              const isShowMvtcGrCrctInvAcao = _this.isCrctInvAllowedShowMvtcItemGrCrctInvAcao( crctInv );

              return isShowMvtcGrCrctInvAcao === true;
            }
            catch( error ){
              console.error( error );
              return false;
            }
          };
        } )
        .catch( error => {
          console.error( error );
        } )
        .finally( () => {
          _this._controlMvtcList.loaded();

          resolve();
        } );
    } );

    return p;
  }

  private execFilterMvtcGrCrctInvAcao( filterValue: string ){
    //console.log( 'execFilterMvtcGrCrctInvAcao( ' + filterValue + ' )...' );

    if( this._mvtcGrCrctInvAcaoListDataSource && this._mvtcGrCrctInvAcaoListDataSource != null ){
      //console.log( '_mvtcGrCrctInvAcaoListDataSource exist.' );

      this._mvtcGrCrctInvAcaoListDataSource.filter = filterValue && filterValue != null ? filterValue.trim().toLowerCase() : '';
    }
  }

  private loadTipMvtcGrCrctInvList( cdTipInv: number, cdEspcAcao: number ): Promise< any >{
    const _this = this;

    _this._controlTipMvtcGrCrctInvList.initializingLoad();

    const p = new Promise< any >( function( resolve, reject ){
      _this.mvtcGrCrctInvService.listTipMvtc( cdTipInv, cdEspcAcao, null )
        .then( tipMvtcGrCrctInvList => {
          tipMvtcGrCrctInvList.forEach( tipMvtcGr => {
            tipMvtcGr.inNcddCdInv = tipMvtcGr.inNcddCdInv ? true : false;
          } );

          _this._tipMvtcGrCrctInvAcaoList = tipMvtcGrCrctInvList;
          resolve();
        } )
        .catch( error => {
          console.error( error );
          reject();
        } )
        .finally( () => {
          _this._controlTipMvtcGrCrctInvList.loaded();
        } );
    } );

    return p;
  }

  onSubmit(){
  }


  private onAddUsrComptGrCrctInvAcao(): void{
    const dataParm: InputMessageDataParm = new InputMessageDataParm();

    dataParm.msgTitle     = 'Compartilhar Carteira';
    dataParm.msgDisplayed = 'Informe o nome do usuário com o qual deseja compartilhar a Carteira.';
    dataParm.msgInput     = 'Nome do Usuário';

    const dialogRef = this.dialog.open( this.generalDialogInputMessage, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm == true ){
        const nmUsrInformed = returnDataParm.txInformed;

        _this.addUsrComptGrCrctInvAcao( nmUsrInformed )
          .then( respCancel => {
            const msgAlert: string = 'Carteira compartilhada com o usuário ' + nmUsrInformed + '.';

            _this.showMessageAlert( msgAlert, 'OK' );
            _this.loadUsrComptGrCrctInvList();
          } )
          .catch( errAddUsrCompt => {
            _this.showMessageError( errAddUsrCompt, 'OK' );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onAddUsrComptGrCrctInvAcao - backdropClick' );
    });
  }

  private addUsrComptGrCrctInvAcao( nmUsr: string ): Promise< void >{
    const cdGrCrctInvAcao = this.getCdGrCrctInvAcao();

    return this.grCrctInvService.addUsrComptGrCrctInvAcao( cdGrCrctInvAcao, nmUsr );
  }


  private onRemoveUsrComptGrCrctInvAcao( usrCompt: UsrComptGrCrctInvAcao ): void{
    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.isReturnMsgObs = false;

    dataParm.msgConfirm    = 'Deixar de compartilhar a Carteira?<br/>';
    dataParm.msgConfirm   += '<br/>';
    dataParm.msgConfirm   += 'Usuário: ' + usrCompt.usr.nmUsr + '<br/>';

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm == true ){
        _this.removeUsrComptGrCrctInvAcao( usrCompt )
          .then( respCancel => {
            const msgAlert: string = 'Excluído o compartilhamento da Carteira com o usuário ' + usrCompt.usr.nmUsr + '.';

            _this.showMessageAlert( msgAlert, 'OK' );
            _this.loadUsrComptGrCrctInvList();
          } )
          .catch( errRemoveUsrCompt => {
            _this.showMessageError( errRemoveUsrCompt, 'OK' );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onRemoveUsrComptGrCrctInvAcao - backdropClick' );
    });
  }

  private removeUsrComptGrCrctInvAcao( usrCompt: UsrComptGrCrctInvAcao ): Promise< void >{
    const cdGrCrctInvAcao = this.getCdGrCrctInvAcao();

    return this.grCrctInvService.removeUsrComptGrCrctInvAcao( cdGrCrctInvAcao, usrCompt.usr.cdUsr );
  }


  private addTicker( ticker: CrctInv ): Promise< void >{
    return this.addTickerList( [ ticker ] );
  }

  private addTickerList( tickerList: CrctInv[] ): Promise< void >{
    const _this = this;
    const cdGrCrctInvAcao = this.getCdGrCrctInvAcao();

    const p = new Promise< any >( function( resolve, reject ){
      const promises: Promise< void >[] = [];

      tickerList.forEach( crctInvIterate => {
        const cdInvIterate = crctInvIterate.cdInv;

        const pAddItemGr = _this.grCrctInvService.addItem( cdGrCrctInvAcao, cdInvIterate );
        promises.push( pAddItemGr );
      } );

      Promise.all( promises )
        .then( response => {
          resolve();
        } )
        .catch( error => {
          console.error( error );
          window.alert( error );
          reject();
        } )
        .finally( () => {
          _this.loadItemGrCrctInvList();
        } );
    } );

    return p;
  }

  private removeItemGrCrctInvAcao( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): Promise< void >{
    return this.removeItemGrCrctInvAcaoList( [ itemGrCrctInvAcao ] );
  }

  private removeItemGrCrctInvAcaoList( itemGrCrctInvAcaoList: ItemGrupoCrctInvAcao[] ): Promise< void >{
    const _this = this;
    const cdGrCrctInvAcao = this.getCdGrCrctInvAcao();

    const p = new Promise< any >( function( resolve, reject ){
      const promises: Promise< void >[] = [];

      itemGrCrctInvAcaoList.forEach( itemGrCrctInvAcaoIterate => {
        const crctInvIterate = itemGrCrctInvAcaoIterate.crctInv;
        const cdInvIterate = crctInvIterate.cdInv;

        const pIterate = _this.grCrctInvService.removeItem( cdGrCrctInvAcao, cdInvIterate );
        promises.push( pIterate );
      } );

      Promise.all( promises )
        .then( response => {
          resolve();
        } )
        .catch( error => {
          console.error( error );
          window.alert( error );
          reject();
        } )
        .finally( () => {
          _this.loadItemGrCrctInvList();
        } );
    } );

    return p;
  }

  private reactiveItemGrCrctInvAcao( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): Promise< void >{
    return this.reactiveItemGrCrctInvAcaoList( [ itemGrCrctInvAcao ] );
  }

  private reactiveItemGrCrctInvAcaoList( itemGrCrctInvAcaoList: ItemGrupoCrctInvAcao[] ): Promise< void >{
    const _this = this;
    const cdGrCrctInvAcao = this.getCdGrCrctInvAcao();

    const p = new Promise< any >( function( resolve, reject ){
      const promises: Promise< ItemGrupoCrctInvAcao >[] = [];

      itemGrCrctInvAcaoList.forEach( itemGrCrctInvAcaoIterate => {
        const crctInvIterate = itemGrCrctInvAcaoIterate.crctInv;
        const cdInvIterate = crctInvIterate.cdInv;

        const pIterate = _this.grCrctInvService.reactivateItemGr( cdGrCrctInvAcao, cdInvIterate );
        promises.push( pIterate );
      } );

      Promise.all( promises )
        .then( response => {
          resolve();
        } )
        .catch( error => {
          console.error( error );
          window.alert( error );
          reject();
        } )
        .finally( () => {
          _this.loadItemGrCrctInvList();
        } );
    } );

    return p;
  }

  public onChangeItemGrCrctInvAcaoListPage( event ){
  }


  private cancelMvtcGrCrctInvAcao( mvtc: MovimentacaoGrupoCrctInvAcao, txObsCancelMvtc: string ): Promise< void >{
    const _this = this;

    const p = new Promise< any >( function( resolve, reject ){
      _this.mvtcGrCrctInvService.cancel( mvtc.grCrctInvAcao.cdGrCrctInvAcao, mvtc.tsInclMvtc, txObsCancelMvtc )
        .then( respCancelMvtc => {
          window.alert( 'Movimentação cancelada/estornada com sucesso.' );
        } )
        .catch( errCancelMvtc => {
          window.alert( 'Não foi possível cancelar/estornar a movimentação.' );
        } );
    } );

    return p;
  }


  public onChangeMvtcGrCrctInvAcaoListPage( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }

  public onChangeHstCntbGrCrctInvAcaoListPage( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE_HST_CNTB , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX_HST_CNTB, event.pageIndex );
  }

  public onChangeMvtcMassFileListPage( event ){
  }


  public compareTickers( grCrctInvAcaoSelected: GrupoCrctInvAcao ){
    const queryParams = {
      cdGrCrctInvAcao: grCrctInvAcaoSelected.cdGrCrctInvAcao,
    };

    this.goToPage( '/inv-chart', queryParams, true );
  }

  public onChangeDtHstCntbFrom( event: Event ): void{
    this.onLoadHstCntbGrCrctInvAcao();
  }

  public onChangeDtHstCntbEnd( event: Event ): void{
    this.onLoadHstCntbGrCrctInvAcao();
  }

  public onChangeDtMvtcFrom( event: Event ): void{
    this.onLoadMvtcGrCrctInvAcao();
  }

  public onChangeDtMvtcEnd( event: Event ): void{
    this.onLoadMvtcGrCrctInvAcao();
  }

  public onAddMvtcGrCrctInvAcao(): void{
    this.onAddMvtcGrCrctInvAcaoToItemGr( null );
  }

  public onAddMvtcGrCrctInvAcaoToCrctInv( cdInv: number ): void{
    const itemGr = new ItemGrupoCrctInvAcao();
    itemGr.grCrctInvAcao = new GrupoCrctInvAcao();
    itemGr.grCrctInvAcao.cdGrCrctInvAcao = this._cdGrCrctInvAcao;

    itemGr.crctInv = new CrctInv();
    itemGr.crctInv.cdInv = cdInv;

    this.onAddMvtcGrCrctInvAcaoToItemGr( itemGr );
  }

  public onAddMvtcGrCrctInvAcaoToCrctInvAndTipMvtc( cdInv: number, tipMvtcGrCrctInvAcao: TipMvtcGrupoCrctInvAcao ): void{
    const itemGr = new ItemGrupoCrctInvAcao();
    itemGr.grCrctInvAcao = new GrupoCrctInvAcao();
    itemGr.grCrctInvAcao.cdGrCrctInvAcao = this._cdGrCrctInvAcao;

    itemGr.crctInv = new CrctInv();
    itemGr.crctInv.cdInv = cdInv;

    this.onAddMvtcGrCrctInvAcaoGeneral( itemGr, tipMvtcGrCrctInvAcao );
  }

  public onAddMvtcGrCrctInvAcaoToTipMvtc( tipMvtcGrCrctInvAcao: TipMvtcGrupoCrctInvAcao ): void{
    this.onAddMvtcGrCrctInvAcaoGeneral( null, tipMvtcGrCrctInvAcao );
  }

  public onAddMvtcGrCrctInvAcaoToItemGr( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    this.onAddMvtcGrCrctInvAcaoGeneral( itemGrCrctInvAcao, null );
  }

  private onAddMvtcGrCrctInvAcaoGeneral( itemGrCrctInvAcao: ItemGrupoCrctInvAcao, tipMvtcGrCrctInvAcao: TipMvtcGrupoCrctInvAcao ): void{
    const dataParm: CarteiraMvtcAddDataParm = new CarteiraMvtcAddDataParm();
    dataParm.cdGrCrctInvAcao      = this._cdGrCrctInvAcao;
    dataParm.crctInv              = itemGrCrctInvAcao != null ? itemGrCrctInvAcao.crctInv : null;
    dataParm.tipMvtcGrCrctInvAcao = tipMvtcGrCrctInvAcao;

    const dialogRef = this.dialog.open( this.carteiraMvtcDialogAdd, { disableClose: false, width: '80%', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( mvtcGrCrctInvAcaoList => {
      //console.log( 'mvtcGrCrctInvAcao: ' + mvtcGrCrctInvAcao );

      const qtMvtc = mvtcGrCrctInvAcaoList != null ? mvtcGrCrctInvAcaoList.length : 0;

      //console.log( 'qtMvtc: ' + qtMvtc );

      if( qtMvtc > 0 ){
        mvtcGrCrctInvAcaoList.forEach( mvtcGrIterate => {
          const crctInv = mvtcGrIterate.crctInv;
          const cdInv = crctInv != null ? crctInv.cdInv : null;

          if( cdInv != null ){
            const nmInv = crctInv != null ? crctInv.nmInv : null;

            //console.log( 'mvtc: ' + cdInv + '-' + nmInv );

            _this.updateInfoOfItemGrCrctInv( cdInv );
          }
        } );

        _this.loadGrCrctInvList();
        _this.onLoadHstCntbGrCrctInvAcao();
        _this.onLoadMvtcGrCrctInvAcao();
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onAddMvtcGrCrctInvAcaoToItemGr - backdropClick' );
    });
  }

  public onCancelMvtcGrCrctInvAcao( mvtc: MovimentacaoGrupoCrctInvAcao ): void{
    const dtRefMvtcStr = this.datePipe.transform( mvtc.dtRefMvtc, 'dd/MM/yyyy', '+00:00' );
    const vlTtlMvtcStr = this.decimalPipe.transform( mvtc.vlTtlMvtc, '1.2-2' );

    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.isReturnMsgObs = true;

    dataParm.msgConfirm    = 'Confirma o cancelamento/estorno da movimentação?<br/>';
    dataParm.msgConfirm   += '<br/>';
    dataParm.msgConfirm   += 'Data: ' + dtRefMvtcStr + '<br/>';
    dataParm.msgConfirm   += 'Tipo: ' + mvtc.tipMvtc.nmTipMvtcGrCrctInvAcao + '<br/>';

    if( mvtc.crctInv != null && mvtc.crctInv.nmInv != null ){
      dataParm.msgConfirm += 'Ativo: ' + mvtc.crctInv.nmInv + '<br/>';
    }

    dataParm.msgConfirm   += 'Valor Total: R$ ' + vlTtlMvtcStr + '<br/>';

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;
      const txObs = returnDataParm.txMsgObs;

      if( isConfirm != null && isConfirm == true ){
        _this.cancelMvtcGrCrctInvAcao( mvtc, txObs )
          .then( respCancel => {
            _this.loadGrCrctInvList();
            _this.onLoadHstCntbGrCrctInvAcao();
            _this.onLoadMvtcGrCrctInvAcao();
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onCancelMvtcGrCrctInvAcao - backdropClick' );
    });
  }

  public onHiddenCrctInvOfList( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    itemGrCrctInvAcao.controlShow.isAllowedShow = false;
  }

  public onShowCrctInvOfList( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    itemGrCrctInvAcao.controlShow.isAllowedShow = true;
  }

  public onHiddenMvtcCrctInv( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    this.setShowMvtcCrctInv( [ itemGrCrctInvAcao ], false );
  }

  public onHiddenMvtcAllCrctInv(): void{
    const itemGrCrctInvList: ItemGrupoCrctInvAcao[] = this._itemGrCrctInvAcaoListDataSource.data;

    this.setShowMvtcCrctInv( itemGrCrctInvList, false );
  }

  public onShowMvtcCrctInv( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    this.setShowMvtcCrctInv( [ itemGrCrctInvAcao ], true );
  }

  public onShowMvtcAllCrctInv(): void{
    const itemGrCrctInvList: ItemGrupoCrctInvAcao[] = this._itemGrCrctInvAcaoListDataSource.data;

    this.setShowMvtcCrctInv( itemGrCrctInvList, true );
  }

  private setShowMvtcCrctInv( itemGrCrctInvAcaoList: ItemGrupoCrctInvAcao[], isAllowed: boolean ): void{
    if( itemGrCrctInvAcaoList != null ){
      itemGrCrctInvAcaoList.forEach( itemGrCrctInvIterate => {
        itemGrCrctInvIterate.controlShow.isAllowedShowMvtcItemGrCrctInvAcao = isAllowed;
      } );

      this.execFilterMvtcGrCrctInvAcao( 'setShowMvtcCrctInv' );
    }
  }

  public onClickRemoveItemGrCrctInvAcao( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.isReturnMsgObs = false;
    dataParm.msgConfirm = 'Confirma a exclusão do ativo ' + itemGrCrctInvAcao.crctInv.nmInv + ' da Carteira?';

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm == true ){
        _this.removeItemGrCrctInvAcao( itemGrCrctInvAcao )
          .then( response => {
            window.alert( 'Ativo excluído com sucesso.' );
          } )
          .catch( error => {
            window.alert( 'Ocorreu um erro na exclusão do ativo: ' + error );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onClickRemoveTickerOfGrCrctInv - backdropClick' );
    });
  }

  public isCrctInvAllowedShow( crctInv: CrctInv ): boolean{
    let isAllowedShow = false;

    const qtItemGr: number = this._itemGrCrctInvAcaoListDataSource != null && this._itemGrCrctInvAcaoListDataSource.data != null ? this._itemGrCrctInvAcaoListDataSource.data.length : 0;

    for( let indexItemGr = 0; indexItemGr < qtItemGr; indexItemGr++ ){
      const itemGrIterate = this._itemGrCrctInvAcaoListDataSource.data[ indexItemGr ];

      if( itemGrIterate.crctInv.cdInv === crctInv.cdInv ){
        isAllowedShow = this.isItemGrCrctInvAllowedShow( itemGrIterate );

        break;
      }
    }

    return isAllowedShow;
  }

  public isCrctInvAllowedShowMvtcItemGrCrctInvAcao( crctInv: CrctInv ): boolean{
    let isAllowedShow = false;

    const qtItemGr: number = this._itemGrCrctInvAcaoListDataSource != null && this._itemGrCrctInvAcaoListDataSource.data != null ? this._itemGrCrctInvAcaoListDataSource.data.length : 0;

    for( let indexItemGr = 0; indexItemGr < qtItemGr; indexItemGr++ ){
      const itemGrIterate = this._itemGrCrctInvAcaoListDataSource.data[ indexItemGr ];

      if( itemGrIterate.crctInv.cdInv === crctInv.cdInv ){
        isAllowedShow = this.isItemGrCrctInvAllowedShowMvtcItemGrCrctInvAcao( itemGrIterate );

        break;
      }
    }

    return isAllowedShow;
  }

  public isItemGrCrctInvAllowedShow( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): boolean{
    const isShowAlsoItemGrCrctInvAcaoNotInvestment = this.isShowAlsoItemGrCrctInvAcaoNotInvestment();
    const isShowAlsoItemGrCrctInvAcaoRemoved       = this.isShowAlsoItemGrCrctInvAcaoRemoved();

    //console.log( 'isShow NotInvestment/Removed? ' + isShowAlsoItemGrCrctInvAcaoNotInvestment + '/' + isShowAlsoItemGrCrctInvAcaoRemoved );

    const qtUndNegc = itemGrCrctInvAcao.qtdUndNegc;
    const isInItemGrCrctInvAcaoAtivo = itemGrCrctInvAcao.inItemGrCrctInvAcaoAtivo;

    const isReturn = ( ( isShowAlsoItemGrCrctInvAcaoNotInvestment === true || qtUndNegc > 0                       ) &&
                       ( isShowAlsoItemGrCrctInvAcaoRemoved       === true || isInItemGrCrctInvAcaoAtivo === true )    );

    //console.log( 'itemGrCrctInvAcao: ' + itemGrCrctInvAcao.crctInv.cdInv + '/' + qtUndNegc + '/' + isInItemGrCrctInvAcaoAtivo + ' - Return? ' + isReturn );

    return isReturn;
  }

  public isItemGrCrctInvAllowedShowMvtcItemGrCrctInvAcao( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): boolean{
    return itemGrCrctInvAcao != null && itemGrCrctInvAcao.controlShow != null ? itemGrCrctInvAcao.controlShow.isAllowedShowMvtcItemGrCrctInvAcao : false;
  }

  public onClickDownloadMvtcGrCrctInvAcao(): void{
    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.isReturnMsgObs = false;
    dataParm.msgConfirm = 'Será realizado o download de um arquivo CSV contendo as movimentações,<br />' + 
                          'exceto aquelas que representam estorno ou que foram estornadas.<br />' +
                          '<br />' +  
                          'Confirma?';

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm == true ){
        _this.downloadAllMvtcGrCrctInvAcaoList()
          .then( response => {
            const qtTtlMvtc     = response[0];
            const qtMvtcGrav    = response[1];
            const qtMvtcDescart = response[2];

            window.alert( 'Arquivo preparado com ' + qtMvtcGrav + ' movimentações, de um total de ' + qtTtlMvtc + ', pois ' + qtMvtcDescart + ' foram descartadas. Em poucos instantes, seu download deve começar.' );
          } )
          .catch( error => {
            window.alert( 'Ocorreu um erro ao gerar o arquivo para download: ' + error );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onClickRemoveTickerOfGrCrctInv - backdropClick' );
    });
  }

  public onRefreshGrCrctInvAcao(): void{
    console.log( 'onRefreshGrCrctInvAcao()...' );

    this.onLoadGrCrctInvList();
  }

  public onRefreshMvtcGrCrctInvAcao(): void{
    this.onLoadMvtcGrCrctInvAcao();
  }

  public onRefreshItemGrCrctInvAcaoList(): void{
    this.onLoadItemGrCrctInvAcaoList();
  }

  public onToggleColumnsItemGrCrctInvAcaoList(): void{
    this.sidenavColumnsItemGrCrctInvAcaoList.toggle();
  }

  public onCloseColumnsItemGrCrctInvAcaoList(){
    this.sidenavColumnsItemGrCrctInvAcaoList.close();
  }

  public onClickShowColumnItemGrCrctInvAcao( event, controlShowColumn: ControlShowColumnItemGr ): void{
    controlShowColumn.isAllowedShow = event.checked;

    this.updateShowColumnsItemGrCrctInvAcao();
  }

  public onRefreshHstCntbGrCrctInvAcao(): void{
    this.onLoadHstCntbGrCrctInvAcao();
  }

  public onClickShowAlsoItemGrNotInvestment(): void{
    //console.log( 'onClickShowAlsoItemGrNotInvestment()...' );
    this.execFilterItemGrCrctInvAcao( 'onClickShowAlsoItemGrNotInvestment' );
  }


  public onClickShowAlsoItemGrRemoved(): void{
    //console.log( 'onClickShowAlsoItemGrRemoved()...' );
    this.execFilterItemGrCrctInvAcao( 'onClickShowAlsoItemGrRemoved' );
  }


  public onClickEditGrCrctInv(): void{
    const cdGrCrctInvSelected = this._cdGrCrctInvAcao;

    const dialogRef = this.dialog.open( CarteiraDialogEditDesktopComponent, { disableClose: false, width: '80%', data: { cdGrCrctInv: cdGrCrctInvSelected } } );

    const _this = this;

    dialogRef.afterClosed().subscribe( grCrctInvAcaoEdited => {
      _this.loadItemGrCrctInvList();
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onClickEditGrCrctInv - backdropClick' );
    });
  }

  public onClickAddTicker(): void{
    const dialogRef = this.dialog.open( TickerDialogSelectDesktopComponent, { disableClose: false, width: '80%' } );

    const _this = this;

    dialogRef.afterClosed().subscribe( tickers => {
      if( tickers != null ){
        const pAddTickers = _this.addTickerList( tickers );

        pAddTickers
          .then( response => {
            const qtTickers = tickers != null ? tickers.length : 0;

            window.alert( 'Adicionado(s) ' + qtTickers + ' ativo(s) à Carteira.' );
          } )
          .catch( error => {
            console.log( 'Erro na inclusao dos ativos à Carteira.' );
            window.alert( error );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'backdropClick' );
    });
  }

  public onClickReactivateItemGrCrctInvAcao( itemGrCrctInvAcao: ItemGrupoCrctInvAcao ): void{
    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.isReturnMsgObs = false;
    dataParm.msgConfirm = 'Confirma a reinclusão do ativo ' + itemGrCrctInvAcao.crctInv.nmInv + ' na Carteira?';

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: 'auto', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm == true ){
        _this.reactiveItemGrCrctInvAcao( itemGrCrctInvAcao )
          .then( response => {
            window.alert( 'Ativo reincluído com sucesso.' );
          } )
          .catch( error => {
            window.alert( 'Ocorreu um erro na reinclusão do ativo na Carteira: ' + error );
          } );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'onClickRemoveTickerOfGrCrctInv - backdropClick' );
    });
  }


  public onCsvInputChange( fileInputEvent: any ){
    const qtFiles = ( fileInputEvent != null && fileInputEvent.target != null && fileInputEvent.target.files ) != null ? fileInputEvent.target.files.length : 0;

    for( let indexFile = 0; indexFile < qtFiles; indexFile++ ){
      const fileIterate: File = fileInputEvent.target.files[ indexFile ];

      this.onLoadMvtcMassFile( fileIterate );
    }
  }


  private onLoadMvtcMassFile( file: File ): Promise< any >{
    const promises = [];

    if( this._controlLoadMvtc.isCanShow === true ){
      promises.push( this.loadMvtcMassFile( file ) );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          console.error( err );
          reject();
        } );
    } );

    return p;
  }


  private loadMvtcMassFile( file: File ): Promise< any >{
    this._controlLoadMvtc.initializingLoad();

    const _this = this;

    if( _this._mvtcMassFileListDataSource == null ){
      _this._mvtcMassFileListDataSource           = new MatTableDataSource< ControlLoadMvtc >( [] );
      _this._mvtcMassFileListDataSource.paginator = _this._mvtcMassFileListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      const delimiter: string = _this.getDelimiterSelected();
      const separadorDecimal: string = _this.getSeparadorDecimalSelected();

      //Realiza a leitura do arquivo.
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileResult: string = fileReader.result.toString();

        //Obtem as linhas do arquivo.
        const rows: string[] = fileResult.split(/\r?\n/);
        const qtRows = rows != null ? rows.length : 0;

        const controlLoadMvtcList: ControlLoadMvtc[] = [];

        for( let indexRow = 0; indexRow < qtRows; indexRow++ ){
          const row = rows[ indexRow ];

          const labels = row.split( delimiter );
          const qtLabels = labels != null ? labels.length : 0;

          const controlLoadMvtc: ControlLoadMvtc = new ControlLoadMvtc();
          controlLoadMvtc.informedValues = [];
          controlLoadMvtc.mvtcGr = new MovimentacaoGrupoCrctInvAcao();
          controlLoadMvtc.mvtcGr.grCrctInvAcao = _this._grCrctInvAcao;
          controlLoadMvtc.msgLoadMvtc = '';

          let isAllValueConverted = true;

          for( let indexLabel = 0; indexLabel < qtLabels; indexLabel++ ){
            const label: string = labels[ indexLabel ] !=null && labels[ indexLabel ].trim().length > 0 ? labels[ indexLabel ] : null;

            const informedValue = new InformeValueToLoadMvtc();
            informedValue.informedValue = label;
            informedValue.exceptionValue = null;
            informedValue.isValueConverted = false;

            if( label != null ){
              const labelTrim = label != null ? label.trim() : '';

              switch( indexLabel ){
                case 0:
                  try{
                    const dtRefMoment: moment.Moment = moment( labelTrim, 'DD/MM/YYYY' );

                    controlLoadMvtc.mvtcGr.dtRefMvtc = dtRefMoment.toDate();
                    informedValue.isValueConverted = true;
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'DATA' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                case 1:
                  try{
                    const tipMvtc = new TipMvtcGrupoCrctInvAcao();
                    tipMvtc.sglTipMvtcGrCrctInvAcao = labelTrim.toUpperCase();
                    tipMvtc.nmTipMvtcGrCrctInvAcao  = labelTrim;

                    controlLoadMvtc.mvtcGr.tipMvtc = tipMvtc;
                    informedValue.isValueConverted = true;
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'TIPO' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                case 2:
                  try{
                    controlLoadMvtc.mvtcGr.crctInv = new CrctInv();
                    controlLoadMvtc.mvtcGr.crctInv.nmInv = labelTrim;
                    informedValue.isValueConverted = true;
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'ATIVO' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                case 3:
                  try{
                    const labelTrimReplaced: string = labelTrim.replace( separadorDecimal, '.' );

                    const qtdUndNegc: number = Number( labelTrimReplaced );

                    if( Number.isNaN( qtdUndNegc ) === false ){
                      controlLoadMvtc.mvtcGr.qtdUndNegc = qtdUndNegc;
                      informedValue.isValueConverted = true;
                    }
                    else{
                      informedValue.exceptionValue = 'Quantidade inválida.';
                    }
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'QTD' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                case 4:
                  try{
                    const labelTrimReplaced: string = labelTrim.replace( separadorDecimal, '.' );

                    const prcUndNegc: number = Number( labelTrimReplaced );

                    if( Number.isNaN( prcUndNegc ) === false ){
                      controlLoadMvtc.mvtcGr.prcUndNegc = prcUndNegc;
                      informedValue.isValueConverted = true;
                    }
                    else{
                      informedValue.exceptionValue = 'Preço inválido.';
                    }
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'PREÇO' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                case 5:
                  try{
                    const labelTrimReplaced: string = labelTrim.replace( separadorDecimal, '.' );

                    const vlTtlEncg: number = Number( labelTrimReplaced );

                    if( Number.isNaN( vlTtlEncg ) === false ){
                      controlLoadMvtc.mvtcGr.vlTtlEncg = vlTtlEncg;
                      informedValue.isValueConverted = true;
                    }
                    else{
                      informedValue.exceptionValue = 'Valor total dos encargos inválido';
                    }
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'ENCARGOS' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                case 6:
                  try{
                    const labelTrimReplaced: string = labelTrim.replace( separadorDecimal, '.' );

                    const vlTtlMvtc: number = Number( labelTrimReplaced );

                    if( Number.isNaN( vlTtlMvtc ) === false ){
                      controlLoadMvtc.mvtcGr.vlTtlMvtc = vlTtlMvtc;
                      informedValue.isValueConverted = true;
                    }
                    else{
                      informedValue.exceptionValue = 'Valor total da movimentação inválido';
                    }
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'VALOR TOTAL' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                case 7:
                  try{
                    controlLoadMvtc.mvtcGr.txObsMvtc = labelTrim;
                    informedValue.isValueConverted = true;
                  }
                  catch( err ){
                    console.log( 'exception disparada ao tentar identificar o campo: ' + 'OBSERVAÇÃO' );
                    console.error( err );
                    informedValue.exceptionValue = err;
                  }

                  break;


                default:
                  if( labelTrim.length > 0 ){
                    informedValue.exceptionValue = 'Há mais valores do que o máximo esperado.';
                    controlLoadMvtc.msgLoadMvtc += '\nHá campo a mais no arquivo, além do esperado.';
                  }
                  else{
                    informedValue.isValueConverted = true;
                  }

                  break;
              }
            }
            else{
              informedValue.isValueConverted = true;
            }

            if( informedValue.isValueConverted === false && isAllValueConverted === true ){
              console.log( '[' + indexRow + ', ' + indexLabel + ']: Ohhh. Label nao pode ser convertido.' );

              isAllValueConverted = false;
            }

            controlLoadMvtc.informedValues.push( informedValue );
          }

          if( isAllValueConverted == false ){
            console.log( '[' + indexRow + ']: Ops. Nem todos valores foram convertidos.' );
          }

          controlLoadMvtc.tipEstLoadMvtc = isAllValueConverted === true ? TipEstLoadMvtcEnum.WAITING_RECORD : TipEstLoadMvtcEnum.RECORD_CANCELED;

          controlLoadMvtcList.push( controlLoadMvtc );
        }

        _this._mvtcMassFileListDataSource.data      = controlLoadMvtcList;
        _this.cdr.detectChanges();
        _this._mvtcMassFileListDataSource.paginator = _this._mvtcMassFileListPaginator;
      }
      fileReader.readAsText( file );

      _this._controlLoadMvtc.loaded();

      resolve();
    } );

    return p;
  }

  private ngAfterViewInit(){
    console.log( 'ngAfterViewInit()...' );

    if( this._mvtcMassFileListDataSource != null ){
      this._mvtcMassFileListDataSource.paginator = this._mvtcMassFileListPaginator;
    }
  }

  private downloadAllMvtcGrCrctInvAcaoList(): Promise< any >{
    const _this = this;

    _this._controlDownloadMvtc.initializingLoad();

    const p = new Promise( function( resolve, reject ){
      const formatter = new Intl.NumberFormat( 'en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2} );

      const mvtcList: MovimentacaoGrupoCrctInvAcao[] = _this._mvtcGrCrctInvAcaoListDataSource.data;
      const qtMvtc = mvtcList != null ? mvtcList.length : 0;

      let qtMvtcGrav        = 0;
      let qtMvtcDescartadas = 0;

      const csv = [];

      for( let indexMvtc = 0; indexMvtc < qtMvtc; indexMvtc++ ){
        const mvtcIterate = mvtcList[ indexMvtc ];

        const tsMvtcDesftIterate = mvtcIterate.tsMvtcDesft;
        const inMvtcDesftIterate = mvtcIterate.inMvtcDesft;

        if( tsMvtcDesftIterate == null && inMvtcDesftIterate === false ){
          const dateMvtcIterate = mvtcIterate.dtRefMvtc;
          const dateFormatedMvtcIterate = _this.datePipe.transform( dateMvtcIterate, 'dd/MM/yyyy', '+0000' );

          const sglTipMvtcIterate     = mvtcIterate.tipMvtc.sglTipMvtcGrCrctInvAcao;
          const nmInvMvtcIterate      = mvtcIterate.crctInv.nmInv != null ? mvtcIterate.crctInv.nmInv : '';
          const qtdUndNegcMvtcIterate = mvtcIterate.qtdUndNegc != null ? mvtcIterate.qtdUndNegc : '';
          const prcUndNegcMvtcIterate = mvtcIterate.prcUndNegc != null ? mvtcIterate.prcUndNegc : '';
          const vlTtlEncgMvtcIterate  = formatter.format( mvtcIterate.vlTtlEncg ).replace( ',', '' );
          const vlTtlMvtcIterate      = formatter.format( mvtcIterate.vlTtlMvtc ).replace( ',', '' );

          const txObsMvtcIterate      = mvtcIterate.txObsMvtc != null ? mvtcIterate.txObsMvtc : '';

          const line = dateFormatedMvtcIterate + ';' + sglTipMvtcIterate + ';' + nmInvMvtcIterate + ';' + qtdUndNegcMvtcIterate + ';' + prcUndNegcMvtcIterate + ';' + vlTtlEncgMvtcIterate + ';' + vlTtlMvtcIterate + ';' + txObsMvtcIterate + ';';

          console.log( line );

          csv.push( line );

          qtMvtcGrav++;
        }
        else{
          qtMvtcDescartadas++;
        }
      }

      console.log( 'Fim da montagem do CSV.' );

      const csvArray = csv.join( '\r\n' );
      const blob = new Blob( [csvArray], {type: 'text/csv' } );

      try{
        saveAs( blob, "movimentacoes.csv" );

        _this._controlDownloadMvtc.loaded;
        resolve( [qtMvtc, qtMvtcGrav, qtMvtcDescartadas] );
      }
      catch( e ){
        reject( e );
      };
    } );

    return p;
  }


  public onOpenedPanelDadosBasicos(): void{
    console.log( 'onOpenedPanelDadosBasicos()...' );

    const controlLoad = this._controlDadosBasicos;

    controlLoad.isCanShow = true;

    if( controlLoad.isDataLoaded === false ){
      controlLoad.initializingLoad();

      this.onLoadGrCrctInvList()
        .finally( () => {
          controlLoad.loaded();
        } );
    }
  }


  public onClosedPanelDadosBasicos(): void{
    const controlLoad = this._controlDadosBasicos;

    controlLoad.isCanShow    = false;
    controlLoad.isDataLoaded = false;
  }


  public onOpenedPanelItemGrCrctInvAcaoList(): void{
    const controlLoad = this._controlItemGrCrctInvList;

    controlLoad.isCanShow = true;

    if( controlLoad.isDataLoaded === false ){
      controlLoad.initializingLoad();

      this.onLoadItemGrCrctInvAcaoList()
        .finally( () => {
          controlLoad.loaded();
        } );
    }
  }


  public onClosedPanelItemGrCrctInvAcaoList(): void{
    this._controlItemGrCrctInvList.isCanShow    = false;
    this._controlItemGrCrctInvList.isDataLoaded = false;
  }


  public onOpenedPanelHstCntbMensal(): void{
    const controlLoad = this._controlHstCntbMensal;

    controlLoad.isCanShow = true;

    if( controlLoad.isDataLoaded === false ){
      controlLoad.initializingLoad();

      this.onLoadHstCntbGrCrctInvAcao()
        .finally( () => {
          controlLoad.loaded();
        } );
    }
  }


  public onClosedPanelHstCntbMensal(): void{
    this._controlHstCntbMensal.isCanShow    = false;
    this._controlHstCntbMensal.isDataLoaded = false;
  }


  public onOpenedPanelMvtcList(): void{
    const controlLoad = this._controlMvtcList;

    controlLoad.isCanShow = true;

    if( controlLoad.isDataLoaded === false ){
      controlLoad.initializingLoad();

      this.onLoadMvtcGrCrctInvAcao()
        .finally( () => {
          controlLoad.loaded();
        } );
    }
  }


  public onClosedPanelMvtcList(): void{
    this._controlMvtcList.isCanShow    = false;
    this._controlMvtcList.isDataLoaded = false;
  }


  public onOpenedPanelLoadMvtc(): void{
    const controlLoad = this._controlLoadMvtc;

    controlLoad.isCanShow = true;
  }


  public onClosedPanelLoadMvtc(): void{
    this._controlLoadMvtc.isCanShow    = false;
    this._controlLoadMvtc.isDataLoaded = false;
  }

  public onClickRemoveUsrComptGrCrctInvAcao( usrCompt: UsrComptGrCrctInvAcao ): void{
    this.onRemoveUsrComptGrCrctInvAcao( usrCompt );
  }

  public onClickAddUsrComptGrCrctInvAcao(): void{
    this.onAddUsrComptGrCrctInvAcao();
  }

  public onClickSelectedItemOfLoadMvtc( indexItemLoadMvtc: number, event: any ): void{
    const isItemLoadMvtcSelected = event.checked;
    const controlLoadMvtcList = this._mvtcMassFileListDataSource.data;
    const controlLoadMvtc = controlLoadMvtcList != null && controlLoadMvtcList.length > 0 ? controlLoadMvtcList[ indexItemLoadMvtc ] : null;

    if( controlLoadMvtc != null ){
      controlLoadMvtc.tipEstLoadMvtc = isItemLoadMvtcSelected === true ? TipEstLoadMvtcEnum.WAITING_RECORD : TipEstLoadMvtcEnum.RECORD_CANCELED;
    }

    console.log( 'controlLoadMvtc.tipEstLoadMvtc: ' + controlLoadMvtc.tipEstLoadMvtc );
  }

  public isDisabledItemOfLoadMvtc( indexItemLoadMvtc: number ): boolean{
    let isDisabled = true;

    const controlLoadMvtcList = this._mvtcMassFileListDataSource.data;
    const controlLoadMvtc = controlLoadMvtcList != null && controlLoadMvtcList.length > 0 ? controlLoadMvtcList[ indexItemLoadMvtc ] : null;
    const tipEstLoadMvtc = controlLoadMvtc != null ? controlLoadMvtc.tipEstLoadMvtc : null;

    if( tipEstLoadMvtc != null ){
      if( tipEstLoadMvtc === TipEstLoadMvtcEnum.WAITING_RECORD || tipEstLoadMvtc === TipEstLoadMvtcEnum.RECORD_CANCELED || tipEstLoadMvtc === TipEstLoadMvtcEnum.RECORD_FAILED ){
        isDisabled = false;
      }
    }

    return isDisabled;
  }

  public isShouldItemOfLoadMvtcChecked( indexItemLoadMvtc: number ): boolean{
    let isChecked = false;

    const controlLoadMvtcList = this._mvtcMassFileListDataSource.data;
    const controlLoadMvtc = controlLoadMvtcList != null && controlLoadMvtcList.length > 0 && indexItemLoadMvtc < controlLoadMvtcList.length ? controlLoadMvtcList[ indexItemLoadMvtc ] : null;
    const tipEstLoadMvtc = controlLoadMvtc != null ? controlLoadMvtc.tipEstLoadMvtc : null;

    if( tipEstLoadMvtc != null ){
      if( tipEstLoadMvtc === TipEstLoadMvtcEnum.WAITING_RECORD || tipEstLoadMvtc === TipEstLoadMvtcEnum.RECORDING || tipEstLoadMvtc === TipEstLoadMvtcEnum.RECORD_FAILED || tipEstLoadMvtc === TipEstLoadMvtcEnum.RECORD_SUCESS ){
        isChecked = true;
      }
    }

    return isChecked;
  }

  public isRecordingItemLoadMvtc( indexItemLoadMvtc: number ): boolean{
    return this.isResultItemLoadMvtc( indexItemLoadMvtc, TipEstLoadMvtcEnum.RECORDING );
  }

  public isRecordFailedItemLoadMvtc( indexItemLoadMvtc: number ): boolean{
    return this.isResultItemLoadMvtc( indexItemLoadMvtc, TipEstLoadMvtcEnum.RECORD_FAILED );
  }

  public isRecordSucessItemLoadMvtc( indexItemLoadMvtc: number ): boolean{
    return this.isResultItemLoadMvtc( indexItemLoadMvtc, TipEstLoadMvtcEnum.RECORD_SUCESS );
  }

  public isRecordCanceledItemLoadMvtc( indexItemLoadMvtc: number ): boolean{
    return this.isResultItemLoadMvtc( indexItemLoadMvtc, TipEstLoadMvtcEnum.RECORD_CANCELED );
  }

  private isResultItemLoadMvtc( indexItemLoadMvtc: number, tipEstLoadMvtcParm: TipEstLoadMvtcEnum ): boolean{
    const controlLoadMvtcList = this._mvtcMassFileListDataSource.data;
    const controlLoadMvtc = controlLoadMvtcList != null && controlLoadMvtcList.length > 0 ? controlLoadMvtcList[ indexItemLoadMvtc ] : null;
    const tipEstLoadMvtc = controlLoadMvtc != null ? controlLoadMvtc.tipEstLoadMvtc : null;

    return tipEstLoadMvtc != null && tipEstLoadMvtc === tipEstLoadMvtcParm ? true : false;
  }

  public onClickSaveLoadMvtc(): void{
    this.onSaveLoadMvtc();
  }

  private onSaveLoadMvtc(): Promise< any >{
    const promises = [];

    const _this = this;

    const controlLoadMvtcList = _this._mvtcMassFileListDataSource.data;
    const qtItensLoadMvtc = controlLoadMvtcList != null ? controlLoadMvtcList.length : 0;

    if( qtItensLoadMvtc > 0 ){
      promises.push( _this.saveLoadMvtc() );
    }
    else{
      _this.showMessageAlert( 'Não há movimentações a serem salvas.', 'OK' );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          _this.showMessageAlert( 'Gravação das movimentações finalizada.', 'OK' );

          _this.loadGrCrctInvList();
          _this.onLoadItemGrCrctInvAcaoList();
          _this.onLoadHstCntbGrCrctInvAcao();
          _this.onLoadMvtcGrCrctInvAcao();

          resolve( resp );
        } )
        .catch( err => {
          _this.showMessageAlert( 'Houve algum erro durante a gravação das movimentações.', 'OK' );

          reject( err );
        } );
    } );

    return p;
  }

  private saveLoadMvtc(): Promise< any >{
    const controlLoadMvtcList = this._mvtcMassFileListDataSource.data;
    const qtItensLoadMvtc = controlLoadMvtcList != null ? controlLoadMvtcList.length : 0;

    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this.saveMvtc( 0, qtItensLoadMvtc )
        .then( respSave => {
          resolve( respSave );
        } )
        .catch( errSave => {
          console.error( 'Error ' + errSave );
          reject( errSave );
        } );
    } );

    return p;
  }

  private saveMvtc( indexMvtc: number, qtMvtc: number ): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      const controlLoadMvtcList = _this._mvtcMassFileListDataSource.data;

      const itemLoadMvtcIterate = controlLoadMvtcList[ indexMvtc ];
      const tipEstLoadMvtc = itemLoadMvtcIterate.tipEstLoadMvtc;

      const promises = [];

      if( tipEstLoadMvtc === TipEstLoadMvtcEnum.WAITING_RECORD || tipEstLoadMvtc === TipEstLoadMvtcEnum.RECORD_FAILED ){
        itemLoadMvtcIterate.tipEstLoadMvtc = TipEstLoadMvtcEnum.RECORDING;

        const p = new Promise( (resolve, reject) => {
          const msg = 'Gravando movimentação ' + ( indexMvtc + 1 ) + ' de ' + qtMvtc + '...';
          _this.showMessageAlert( msg, 'OK' );

          _this.addMvtcGrCrctInv( itemLoadMvtcIterate )
            .then( respAddMvtc => {
              itemLoadMvtcIterate.tipEstLoadMvtc = TipEstLoadMvtcEnum.RECORD_SUCESS;
              itemLoadMvtcIterate.msgLoadMvtc    = 'Incluída';

              resolve( respAddMvtc );
            } )
            .catch( err => {
              console.log( 'error na inclusao de movimentacao.' );
              console.error( err );
              itemLoadMvtcIterate.tipEstLoadMvtc = TipEstLoadMvtcEnum.RECORD_FAILED;
              itemLoadMvtcIterate.msgLoadMvtc = 'Erro => ' + err.codErr + ' - ' + err.msgErr;

              reject( err );
            } );
        } );

        promises.push( p );
      }
      else{
        console.log( 'Registro ignorado.' );
      }

      Promise.all( promises )
        .then( respPromises => {
          if( indexMvtc < ( qtMvtc - 1 ) ){
            _this.saveMvtc( indexMvtc + 1, qtMvtc )
              .then( respSave => {
                resolve( respSave );
              } )
              .catch( errSave => {
                reject( errSave );
              } );
          }
          else{
            resolve();
          }
        } )
        .catch( errPromises => {
          console.error( errPromises );
          reject( errPromises );
        } );
    });

    return p;
  }

  private addMvtcGrCrctInv( controlLoadMvtc: ControlLoadMvtc ): Promise< MovimentacaoGrupoCrctInvAcao[] >{
    const mvtcGr = controlLoadMvtc.mvtcGr;

    const dtRef           = mvtcGr.dtRefMvtc;
    const sglTipMvtc      = mvtcGr.tipMvtc.sglTipMvtcGrCrctInvAcao;
    const cdGrCrctInvAcao = mvtcGr.grCrctInvAcao != null ? mvtcGr.grCrctInvAcao.cdGrCrctInvAcao : null;
    const crctInv         = mvtcGr.crctInv;
    const qtdUnd          = mvtcGr.qtdUndNegc != null && mvtcGr.qtdUndNegc >= 0 ? mvtcGr.qtdUndNegc : null;
    const prcUnd          = mvtcGr.prcUndNegc != null && mvtcGr.prcUndNegc >= 0 ? mvtcGr.prcUndNegc : null;
    const encgMvtcList    = null;
    const vlTtlEncg       = mvtcGr.vlTtlEncg  != null && mvtcGr.vlTtlEncg  >= 0 ? mvtcGr.vlTtlEncg  : null;
    const txObsMvtc       = "Inclusao massificada de movimentacoes."
    const vlTaxaPercRentAaNegc: number = null;

    const p = this.mvtcGrCrctInvService.addWithMoedaRealAndTipEstMvtcAceitaAndCrctInv( cdGrCrctInvAcao, dtRef, sglTipMvtc, crctInv, qtdUnd, prcUnd, encgMvtcList, vlTtlEncg, txObsMvtc, vlTaxaPercRentAaNegc );

    return p;
  }

  public onClickDetailTicker( event, cdInv: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/inv-detail', { 'cdInv': cdInv }, isOpenNewTab );
  }

  public onClickDetailNmInv( nmInv: string ){
    this.goToPage( '/inv-detail', { 'nmInv': nmInv }, true );
  }

  public onClickDetailEmpr( cdEmpr: number ){
    this.goToPage( '/empr-detail', { 'cdEmpr': cdEmpr }, true );
  }
}
import { NgModule                                        } from '@angular/core';
import { CommonModule                                    } from '@angular/common';
import { RouterModule, Routes, Router, PreloadAllModules } from '@angular/router';

import { AuthGuard                                       } from './_helpers/auth/auth.guard';

/* Import de services */
import { AplicationStateService                       } from './_services/aplication-state/aplication-state.service';

/* Import de componentes */
import { CarteiraListDesktopComponent                 } from '@components/carteira/list/desktop/carteira-list-desktop.component';
import { CarteiraListMobileComponent                  } from '@components/carteira/list/mobile/carteira-list-mobile.component';
import { CarteiraMvtcDesktopComponent                 } from '@components/carteira/mvtc/desktop/carteira-mvtc-desktop.component';
import { CarteiraMvtcMobileComponent                  } from '@components/carteira/mvtc/mobile/carteira-mvtc-mobile.component';
import { DdcListDesktopComponent                      } from '@components/ddc/list/desktop/ddc-list-desktop.component';
import { DdcListMobileComponent                       } from '@components/ddc/list/mobile/ddc-list-mobile.component';
import { DestaquesListDesktopComponent                } from '@components/destaques/list/desktop/destaques-list-desktop.component';
import { DestaquesListMobileComponent                 } from '@components/destaques/list/mobile/destaques-list-mobile.component';
import { EmprDetailDesktopComponent                   } from '@components/empr/detail/desktop/empr-detail-desktop.component';
import { EmprDetailMobileComponent                    } from '@components/empr/detail/mobile/empr-detail-mobile.component';
import { EstrtgPendDesktopComponent                   } from '@components/estrtg/pend/desktop/estrtg-pend-desktop.component';
import { EstrtgPendMobileComponent                    } from '@components/estrtg/pend/mobile/estrtg-pend-mobile.component';
import { EttgstListDesktopComponent                   } from '@components/ettgst/list/desktop/ettgst-list-desktop.component';
import { EttgstListMobileComponent                    } from '@components/ettgst/list/mobile/ettgst-list-mobile.component';
import { HomeDesktopComponent                         } from '@components/home/desktop/home-desktop.component';
import { HomeMobileComponent                          } from '@components/home/mobile/home-mobile.component';
import { InvListDesktopComponent                      } from '@components/ticker/list/desktop/inv-list-desktop.component';
import { InvListMobileComponent                       } from '@components/ticker/list/mobile/inv-list-mobile.component';
import { LoginAddDesktopComponent                     } from '@components/login/add/desktop/login-add-desktop.component';
import { LoginAddMobileComponent                      } from '@components/login/add/mobile/login-add-mobile.component';
import { LoginDesktopComponent                        } from '@components/login/desktop/login-desktop.component';
import { LoginMobileComponent                         } from '@components/login/mobile/login-mobile.component';
import { LoginForgotDesktopComponent                  } from '@components/login/forgot/desktop/login-forgot-desktop.component';
import { LoginForgotMobileComponent                   } from '@components/login/forgot/mobile/login-forgot-mobile.component';
import { LoginRecoverDesktopComponent                 } from '@components/login/recover/desktop/login-recover-desktop.component';
import { LoginRecoverMobileComponent                  } from '@components/login/recover/mobile/login-recover-mobile.component';
import { LogoutDesktopComponent                       } from '@components/logout/desktop/logout-desktop.component';
import { LogoutMobileComponent                        } from '@components/logout/mobile/logout-mobile.component';
import { PageNotFoundDesktopComponent                 } from '@components/page-not-found/desktop/page-not-found.desktop.component';
import { SetorDetailDesktopComponent                  } from '@components/setor/detail/desktop/setor-detail-desktop.component';
import { SetorDetailMobileComponent                   } from '@components/setor/detail/mobile/setor-detail-mobile.component';
import { TickerChartDesktopComponent                  } from '@components/ticker/chart/desktop/ticker-chart-desktop.component';
import { TickerChartMobileComponent                   } from '@components/ticker/chart/mobile/ticker-chart-mobile.component';
import { TickerDetailDesktopComponent                 } from '@components/ticker/detail/desktop/ticker-detail-desktop.component';
import { TickerDetailMobileComponent                  } from '@components/ticker/detail/mobile/ticker-detail-mobile.component';

const desktop_routes: Routes = [
  { path: ''                              , redirectTo: '/home', pathMatch: 'full'                                },
  { path: 'gcia-list'                     , component: CarteiraListDesktopComponent    , canActivate: [AuthGuard] },
  { path: 'gcia-mvtc'                     , component: CarteiraMvtcDesktopComponent    , canActivate: [AuthGuard] },
  { path: 'ddc-list'                      , component: DdcListDesktopComponent                                    },
  { path: 'destaques-list'                , component: DestaquesListDesktopComponent                              },
  { path: 'empr-detail'                   , component: EmprDetailDesktopComponent                                 },
  { path: 'estrtg-pend'                   , component: EstrtgPendDesktopComponent      , canActivate: [AuthGuard] },
  { path: 'ettgst-list'                   , component: EttgstListDesktopComponent      , canActivate: [AuthGuard] },
  { path: 'home'                          , component: HomeDesktopComponent                                       },
  { path: 'login'                         , component: LoginDesktopComponent                                      },
  { path: 'login-add'                     , component: LoginAddDesktopComponent                                   },
  { path: 'login-forgot'                  , component: LoginForgotDesktopComponent                                },
  { path: 'login-recover'                 , component: LoginRecoverDesktopComponent                               },
  { path: 'logout'                        , component: LogoutDesktopComponent                                     },
  { path: 'inv-chart'                     , component: TickerChartDesktopComponent                                },
  { path: 'inv-detail'                    , component: TickerDetailDesktopComponent                               },
  { path: 'inv-list'                      , component: InvListDesktopComponent                                    },
  { path: 'setor-detail'                  , component: SetorDetailDesktopComponent                                },
  { path: '**'                            , component: PageNotFoundDesktopComponent                               }
];

const mobile_routes: Routes = [
  { path: ''                              , redirectTo: '/home', pathMatch: 'full'                                },
  { path: 'gcia-list'                     , component: CarteiraListMobileComponent     , canActivate: [AuthGuard] },
  { path: 'gcia-mvtc'                     , component: CarteiraMvtcMobileComponent     , canActivate: [AuthGuard] },
  { path: 'ddc-list'                      , component: DdcListMobileComponent                                     },
  { path: 'destaques-list'                , component: DestaquesListMobileComponent                               },
  { path: 'empr-detail'                   , component: EmprDetailMobileComponent                                  },
  { path: 'estrtg-pend'                   , component: EstrtgPendMobileComponent                                  },
  { path: 'ettgst-list'                   , component: EttgstListMobileComponent                                  },
  { path: 'home'                          , component: HomeMobileComponent                                        },
  { path: 'login'                         , component: LoginMobileComponent                                       },
  { path: 'login-add'                     , component: LoginAddMobileComponent                                    },
  { path: 'login-forgot'                  , component: LoginForgotMobileComponent                                 },
  { path: 'login-recover'                 , component: LoginRecoverMobileComponent                                },
  { path: 'logout'                        , component: LogoutMobileComponent                                      },
  { path: 'inv-chart'                     , component: TickerChartMobileComponent                                 },
  { path: 'inv-detail'                    , component: TickerDetailMobileComponent                                },
  { path: 'inv-list'                      , component: InvListMobileComponent                                     },
  { path: 'setor-detail'                  , component: SetorDetailMobileComponent                                 },
  { path: '**'                            , component: PageNotFoundDesktopComponent                               }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forRoot( desktop_routes, { preloadingStrategy: PreloadAllModules } )
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { 
  public constructor( private router: Router,
                      private aplicationStateService: AplicationStateService ) {
    //console.log( 'AppRoutingModule()...' );

    if( aplicationStateService.isMobileResolution() ) {
      //console.log( 'is small screen.' );

      router.resetConfig( mobile_routes );

      //console.log( 'Router reseted for mobile_routes.' );
    } else {
      //console.log( 'is big screen.' );
    }

    /* Para visualizar os eventos de roteamento:
    router.events.subscribe( event => {
      console.info( event, '<-- subscribe func' );
    });
    */

    //console.log( 'AppRoutingModule().' );
  }
}

import { ControlShow } from 'src/app/_models/control/controlShow';

/**
 * Controle da apresentacao (show) dos dados de um item de grupo.
 */
export class ControlShowItemGrCrctInvAcao extends ControlShow{
  //Permitido mostrar movimentacoes do ativo?
  public isAllowedShowMvtcItemGrCrctInvAcao: boolean = true;

  public constructor( nm: string ){
    super( nm );
  }
}

<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Robôs</h2>

<div class="grid_container_ettgst_list">
	<div class="grid_item_ettgst_list_ettgstList">
		<table mat-table [dataSource]="dataSourceEttgstList">
			<ng-container matColumnDef="sglEttgst" sticky>
				<th mat-header-cell *matHeaderCellDef> Robô </th>
				<td mat-cell *matCellDef="let element"> {{element.sglEttgst}} </td>
			</ng-container>

			<ng-container matColumnDef="estrtgPend">
				<th mat-header-cell *matHeaderCellDef> Pendentes </th>
				<td mat-cell *matCellDef="let element"> {{element.estrtgPend}} </td>
			</ng-container>

			<ng-container matColumnDef="estrtgIns">
				<th mat-header-cell *matHeaderCellDef> Insucesso </th>
				<td mat-cell *matCellDef="let element"> {{element.estrtgIns}} - {{element.percInsVld | number:'1.1-1'}}% </td>
			</ng-container>

			<ng-container matColumnDef="estrtgSuc">
				<th mat-header-cell *matHeaderCellDef> Sucesso </th>
				<td mat-cell *matCellDef="let element"> {{element.estrtgSuc}} - {{element.percSucVld | number:'1.1-1'}}% </td>
			</ng-container>

			<ng-container matColumnDef="estrtgInv">
				<th mat-header-cell *matHeaderCellDef> Invalidadas </th>
				<td mat-cell *matCellDef="let element"> {{element.estrtgInv}} </td>
			</ng-container>

			<ng-container matColumnDef="estrtgTtl">
				<th mat-header-cell *matHeaderCellDef> Total </th>
				<td mat-cell *matCellDef="let element"> {{element.qtdEstrtgTtl}} </td>
			</ng-container>

			<ng-container matColumnDef="options">
		      <th mat-header-cell *matHeaderCellDef></th>
      			<td mat-cell *matCellDef="let element">
					<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
                		<mat-icon>more_vert</mat-icon>
            		</button>
					<mat-menu #appMenu="matMenu">
						<button mat-menu-item (click)="detailEttgst( element.sglEttgst )" type="submit">Detalhar</button>
					</mat-menu>
      			</td>
    		</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
	</div>
</div>



<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>
	<ng-container *ngIf="this._crctInv != null && this._crctInv.crctInv != null">{{this._crctInv.crctInv.nmInv}} | </ng-container>Detalhamento
	<div *ngIf="this._crctInv != null && this._crctInv.crctInv != null && this._crctInv.crctInv.crctInvAcao.nmPregao != null" class="subTitle">
		({{this._crctInv.crctInv.crctInvAcao.nmPregao}})
	</div>
</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Dados Básicos:
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_detail_dadosBasicos">
			<div class="grid_item_ticker_detail_dadosBasicos_ticker_label">
				<div class="label_key">
					Ticker:
				</div>
			</div>

			<div class="grid_item_ticker_detail_dadosBasicos_ticker_value">
				<div *ngIf="this._crctInv != null" class="label_value">	
					<label>{{this._crctInv.crctInv.nmInv}} &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;</label>
					<label *ngIf="this._crctInv.crctInv.tipInv.cdTipInv == 7">
						{{this._crctInv.crctInv.tipInv.nmTipInv}} {{this._crctInv.crctInv.crctInvAcao.espcAcao.nmEspcAcao}}
						<mat-icon class="icon_help" matTooltip="{{this._crctInv.crctInv.crctInvAcao.espcAcao.descrEspcAcao}}">help</mat-icon>
						&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
					</label>
					<label *ngIf="this._crctInv.crctInv.tipInv.cdTipInv != 7">
						{{this._crctInv.crctInv.tipInv.nmTipInv}}
						&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
					</label>
					<label>{{this._crctInv.crctInv.crctInvAcao.nmPregao}}</label>
				</div>
			</div>

			<div class="grid_item_ticker_detail_dadosBasicos_empr_label">
				<div class="label_key">
					Empresa:
				</div>
			</div>

			<div *ngIf="this._crctInv != null" class="grid_item_ticker_detail_dadosBasicos_empr_value">
				<a [routerLink]="" routerLinkActive="active" (click)="detailEmpr()"> 
					{{this._crctInv.crctInv.empr.nmEmpr}}
				</a> 
			</div>

			<div class="grid_item_ticker_detail_dadosBasicos_sitEmpr_label">
				<div class="label_key">
					Situação da Empresa:
				</div>
			</div>

			<div class="grid_item_ticker_detail_dadosBasicos_sitEmpr_value">
				<label *ngIf="this._crctInv != null" class="label_value">{{this._crctInv.crctInv.empr.tipSitEmpr.nmTipSitEmpr}}</label>
			</div>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Classificação Setorial:
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_detail_setorEcnm">
			<div class="grid_item_ticker_detail_setorEcnm_setor_label">
				<div class="label_key">
					Setor:
				</div>
			</div>

			<div class="grid_item_ticker_detail_setorEcnm_setor_value">
				<a *ngIf="this._crctInv != null" [routerLink]="" routerLinkActive="active" (click)="detailSetorEcnm()"> 
					{{this._crctInv.crctInv.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
				</a> 
			</div>

			<div class="grid_item_ticker_detail_setorEcnm_subSetor_label">
				<div class="label_key">
					Sub-setor:
				</div>
			</div>

			<div class="grid_item_ticker_detail_setorEcnm_subSetor_value">
				<a *ngIf="this._crctInv != null" [routerLink]="" routerLinkActive="active" (click)="detailSubSetorEcnm()"> 
					{{this._crctInv.crctInv.empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm}}
				</a> 
			</div>

			<div class="grid_item_ticker_detail_setorEcnm_segmento_label">
				<div class="label_key">
					Segmento:
				</div>
			</div>

			<div class="grid_item_ticker_detail_setorEcnm_segmento_value">
				<a *ngIf="this._crctInv != null" [routerLink]="" routerLinkActive="active" (click)="detailSegmEcnm()"> 
					{{this._crctInv.crctInv.empr.segmEcnm.nmSegmEcnm}}
				</a> 
			</div>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Cotações:
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_detail_cotcAcaoList">
			<div class="grid_item_ticker_detail_cotcAcaoList_filter_from">
				<mat-form-field>
					<mat-label>A Partir De:</mat-label>

					<input matInput 
						[matDatepicker]="pickerFrom" 
						formControlName="dtCotcFrom"
						(ngModelChange)="updateDtCotc( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
					<mat-datepicker #pickerFrom></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_item_ticker_detail_cotcAcaoList_filter_end">
				<mat-form-field>
					<mat-label>Até:</mat-label>

					<input matInput 
						[matDatepicker]="pickerEnd" 
						formControlName="dtCotcEnd"
						(ngModelChange)="updateDtCotc( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
					<mat-datepicker #pickerEnd></mat-datepicker>
				</mat-form-field>
			</div>

			<div *ngIf="isCotcAcaoListEmpty === false" class="grid_item_ticker_detail_cotcAcaoList_dadosPeriodo_label">
				<div class="label_value">
					Cotações no período variam de <span class="label_key">R$ {{this._dadosPeriodo.cotcMin | number:'1.2-2'}}</span> a <span class="label_key">R$ {{this._dadosPeriodo.cotcMax | number:'1.2-2'}}</span>.
				</div>
				<div class="label_value">
					No período, houve variação de <span class="label_key">{{this._dadosPeriodo.varPerc | number:'1.2-2'}}</span>%.
				</div>
				<div class="label_value">
					Volume médio de negócios no período: <span class="label_key">{{this._dadosPeriodo.volMed | shortNumber}}</span>.
				</div>
			</div>

			<div *ngIf="isCotcAcaoListEmpty === false" class="grid_item_ticker_detail_cotcAcaoList_chart">
				<div #divCotcChart>
					<google-chart 
						#chart
						[title]="_cotcChart.title"
						[type]="_cotcChart.type"
						[data]="_cotcChart.data"
						[columns]="_cotcChart.columns"
						[options]="_cotcChart.options"
					>
					</google-chart>
				</div>
			</div>

			<div *ngIf="isCotcAcaoListEmpty === false" class="grid_item_ticker_detail_cotcAcaoList_chart_filters">
				<div class="checkboxesIndTcn">
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'CANDLE' , $event )" formControlName="isShowIndTcnCandle" >Candle</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'VOL'    , $event )" formControlName="isShowIndTcnVol"    >Volume</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'SMA'    , $event )" formControlName="isShowIndTcnSma"    >SMA</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'EMA'    , $event )" formControlName="isShowIndTcnEma"    >EMA</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'MOM'    , $event )" formControlName="isShowIndTcnMom"    >Momentum</mat-checkbox></div>
					<div class="checkboxIndTcn">
						<mat-checkbox (change)="updateCheckboxIndTcn( 'RSI'    , $event )" formControlName="isShowIndTcnRsi" >RSI</mat-checkbox>
					</div>
					<div class="radiobuttonTypeRangeRsi">
						<mat-radio-group (change)="onChangeTypeRangeIndTcnRsi( $event )" formcontrolname="idTypeRangeIndTcnRsi">
							<mat-radio-button *ngFor="let op of _typeRangeIndTcnRsiList" [value]="op.value" [checked]="op.checked" [disabled]="op.disabled" class="optionRsi">{{op.label}}</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'EFI'    , $event )" formControlName="isShowIndTcnEfi"    >EFI</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'OBV'    , $event )" formControlName="isShowIndTcnObv"    >OBV</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'STOC'   , $event )" formControlName="isShowIndTcnStoc"   >Estocástico</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'P/E'   , $event )" formControlName="isShowIndTcnPe"   >P/Lucro (P/E)</mat-checkbox></div>
					<div class="checkboxIndTcn"><mat-checkbox (change)="updateCheckboxIndTcn( 'P/VPA'   , $event )" formControlName="isShowIndTcnPvpa"   >P/VPA</mat-checkbox></div>
				</div>
			</div>

			<div class="grid_item_ticker_detail_cotcAcaoList_value">
				<mat-checkbox *ngIf="isCotcAcaoListEmpty === false" formControlName="isShowCotcInTable" >
					Mostrar cotações em tabela
				</mat-checkbox>

				<table mat-table [dataSource]="_cotcAcaoDataSource" *ngIf="this.isShowCotcInTableSelected() || isCotcAcaoListEmpty === true" >
					<ng-container matColumnDef="dtCotc" sticky>
						<th mat-header-cell *matHeaderCellDef> Data </th>
						<td mat-cell *matCellDef="let element"> {{element.dtCotc | date:'dd/MM/yyyy':'+0000'}} </td>
					</ng-container>

					<ng-container matColumnDef="prcAbert">
						<th mat-header-cell *matHeaderCellDef> Abert. (R$) </th>
						<td mat-cell *matCellDef="let element"> {{element.prcAbert | number:'1.2-2'}} </td>
					</ng-container>

					<ng-container matColumnDef="prcMin">
						<th mat-header-cell *matHeaderCellDef> Mín. (R$) </th>
						<td mat-cell *matCellDef="let element"> {{element.prcMin | number:'1.2-2'}} </td>
					</ng-container>

					<ng-container matColumnDef="prcMax">
						<th mat-header-cell *matHeaderCellDef> Máx. (R$) </th>
						<td mat-cell *matCellDef="let element"> {{element.prcMax | number:'1.2-2'}} </td>
					</ng-container>

					<ng-container matColumnDef="prcFech">
						<th mat-header-cell *matHeaderCellDef> Fech. (R$) </th>
						<td mat-cell *matCellDef="let element"> {{element.prcFech | number:'1.2-2'}} </td>
					</ng-container>

					<ng-container matColumnDef="varPrcFech">
						<th mat-header-cell *matHeaderCellDef> Var. (%) </th>
						<td mat-cell *matCellDef="let element"> {{element.varPrcFech | number:'1.2-2'}} </td>
					</ng-container>

					<ng-container matColumnDef="volNegc">
						<th mat-header-cell *matHeaderCellDef> Vol. Neg. </th>
						<td mat-cell *matCellDef="let element"> {{element.volNegc | shortNumber:''}} </td>
					</ng-container>

					<ng-container matColumnDef="options">
					<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #appMenu="matMenu">
								<button mat-menu-item (click)="detailCotcAcao( element.dtCotc )" type="submit">Detalhar Cotação</button>
							</mat-menu>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_cotcAcaoDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _cotcAcaoDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="isCotcAcaoListEmpty" class="grid_item_ticker_detail_cotcAcaoList_empty">
				<label class="label_list_empty">Não há cotações disponíveis.</label>
			</div>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false"
						 (opened)="onOpenedPanelPrvt()"
						 (closed)="onClosedPanelPrvt()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Proventos (Rendimentos e Amortizações):
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_detail_prvt">
			<div *ngIf="this.isExistPgmtPrvt() === true" class="grid_item_ticker_detail_prvt_header">
				<div class="label_value">
					Lista de proventos pagos, ou a serem pagos, aos acionistas detentores do papel.
				</div>
			</div>

			<div *ngIf="this.isExistPgmtPrvt() === true" class="grid_item_ticker_detail_prvt_value">
				<table mat-table [dataSource]="_prvtDataSource">
					<ng-container matColumnDef="dtBase">
						<th mat-header-cell *matHeaderCellDef> Data COM </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{element.dtBase | date:'dd/MM/yyyy':'+0000'}}
							</label>
						</td>
					</ng-container>

					<ng-container matColumnDef="tipPrvt">
						<th mat-header-cell *matHeaderCellDef> Tipo Provento </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{element.tipPrvt.nmTipPrvt}}
							</label>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlPrvt">
						<th mat-header-cell *matHeaderCellDef> Valor (R$) </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{ element.vlPrvt | number:'1.2-11' }}
							</label>
						</td>
					</ng-container>

					<ng-container matColumnDef="dyMensal">
						<th mat-header-cell *matHeaderCellDef> Yield Mensal (%) </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{ element.vlPrvt / element.cotc.prcFech * 100 | number:'1.2-2' }}
							</label>
						</td>
					</ng-container>

					<ng-container matColumnDef="dtPgmt">
						<th mat-header-cell *matHeaderCellDef> Data Pagamento </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{element.dtPgmt | date:'dd/MM/yyyy':'+0000'}}
							</label>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_prvtDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _prvtDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="this.isExistPgmtPrvt() === false; then thenBlockPrvt else elseBlockPrvt" ></div>
			<ng-template #thenBlockPrvt>
				<div class="grid_item_ticker_detail_prvt_empty">
					<label class="label_list_empty">Não há nenhum pagamento identificado.</label>
				</div>
			</ng-template>
			<ng-template #elseBlockPrvt>
				<div class="grid_item_ticker_detail_prvt_comment">
					<label class="label_comment">A Data COM refere-se ao último dia de pregão para que o acionista tenha direito ao provento.</label>
				</div>
			</ng-template>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Resultado Contábil:
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_detail_rstd">
			<div *ngIf="this.isExistLastRstdEmpr() === true" class="grid_item_ticker_detail_rstd_header">
				<div class="label_value">
					Resultado 
					<span class="label_key">{{this.getNmPerdcOfLastRstdEmpr()}}</span>, referente a 
					<span class="label_key">{{this.getAaRefOfLastRstdEmpr()}}</span>
					<span *ngIf="this.isPerdcAnualOfLastRstdEmpr() == false" class="label_key">/{{this.getNrRefOfLastRstdEmpr()}}</span>, divulgado em
					<span class="label_key">{{this.getDtPblccRefOfLastRstdEmpr() | date:'dd/MM/yyyy':'+0000'}}</span>:
				</div>
			</div>

			<div *ngIf="this.isExistLastRstdEmpr() === true" class="grid_item_ticker_detail_rstd_value">
				<table mat-table [dataSource]="_tipInfRstdDataSource">
					<ng-container matColumnDef="tipInfRstdEmpr">
						<th mat-header-cell *matHeaderCellDef> Informação </th>
						<td mat-cell *matCellDef="let element">
							<label>
								{{element.tipInfRstdEmpr.nmTipInfRstdEmpr}}
								<mat-icon class="icon_help" matTooltip="{{element.tipInfRstdEmpr.txDcrUsuTipInfRstdEmpr}}">help</mat-icon>
							</label>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlRstdEmpr">
						<th mat-header-cell *matHeaderCellDef> Valor (R$) </th>
						<td mat-cell *matCellDef="let element" class="mat-cell-number">
							<div *ngIf="element.infRstdEmprArray[0].vlRstdEmpr >= 0; then thenBlock else elseBlock" ></div>
							<ng-template #thenBlock>
								<span class="mat-cell-value-positive">{{(element.infRstdEmprArray[0].vlRstdEmpr / 1000) | number:'1.0-0' }}</span>
							</ng-template>
							<ng-template #elseBlock>
								<span class="mat-cell-value-negative">{{(element.infRstdEmprArray[0].vlRstdEmpr / 1000) | number:'1.0-0' }}</span>
							</ng-template>

							<div *ngIf="element.infRstdEmprArray[0].getVarPercVlRstdEmpr() != null; then varVlRstdThenBlock" ></div>
							<ng-template #varVlRstdThenBlock>
								<div *ngIf="element.infRstdEmprArray[0].getVarPercVlRstdEmpr() > 0; then varPosVlRstdThenBlock else varNegVlRstdThenBlock" ></div>
								<ng-template #varPosVlRstdThenBlock>
									<span matTooltip="Valor em {{element.infRstdEmprArray[0].aaRefPerdc - 1}} era {{element.infRstdEmprArray[0].vlRstdEmprAaRefAnt / 1000 | number:'1.0-0'}}" class="mat-cell-value-positive">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_up" >arrow_upward</mat-icon> {{ element.infRstdEmprArray[0].getVarPercVlRstdEmpr() | number:'1.0-0' }}%
									</span>
								</ng-template>
								<ng-template #varNegVlRstdThenBlock>
									<span matTooltip="Valor em {{element.infRstdEmprArray[0].aaRefPerdc - 1}} era {{element.infRstdEmprArray[0].vlRstdEmprAaRefAnt / 1000 | number:'1.0-0'}}" class="mat-cell-value-negative">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_down" >arrow_downward</mat-icon> {{ element.infRstdEmprArray[0].getVarPercVlRstdEmpr() * (-1) | number:'1.0-0' }}%
									</span>
								</ng-template>
							</ng-template>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_tipInfRstdDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _tipInfRstdDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="this.isExistLastRstdEmpr() === false; then thenBlockRstd else elseBlockRstd" ></div>
			<ng-template #thenBlockRstd>
				<div class="grid_item_ticker_detail_rstd_empty">
					<label class="label_list_empty">Não há nenhum resultado disponível.</label>
				</div>
			</ng-template>
			<ng-template #elseBlockRstd>
				<div class="grid_item_ticker_detail_rstd_comment">
					<label class="label_comment">Valores em milhares.</label>
				</div>
			</ng-template>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Documentos:
      		</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="grid_container_ticker_detail_ddcList">
			<div *ngIf="isDdcListEmpty == false" class="grid_item_ticker_detail_ddcList_value">
				<table mat-table [dataSource]="_ddcDataSource">
					<ng-container matColumnDef="dtPblccDocDmntCntb">
						<th mat-header-cell *matHeaderCellDef> Data Publicação </th>
						<td mat-cell *matCellDef="let element"> {{element.dtPblccDocDmntCntb | date:'dd/MM/yyyy':'+0000'}} </td>
					</ng-container>

					<ng-container matColumnDef="sglDocDmntCntb">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.sglDocDmntCntb}} </td>
					</ng-container>

					<ng-container matColumnDef="options">
					<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #appMenu="matMenu">
								<button mat-menu-item (click)="detailDdc( element.sglDocDmntCntb, element.dtPblccDocDmntCntb, element.nrSeqDocDmntCntb )" type="submit">Detalhar Documento</button>
							</mat-menu>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_ddcDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: _ddcDisplayedColumns;"></tr>
				</table>
			</div>

			<div *ngIf="isDdcListEmpty" class="grid_item_ticker_detail_ddcList_empty">
				<label class="label_list_empty">Não há documentos disponíveis.</label>
			</div>
		</div>
	</mat-expansion-panel>
</form>
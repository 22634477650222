import { Component, OnInit, ViewChild   } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Router, NavigationExtras                         } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { MatDialog } from '@angular/material/dialog';

import { MatTableDataSource                 } from '@angular/material/table';
import { MatPaginator     } from '@angular/material/paginator';

import { Estrtg } from '@models/estrtg';

import { AbstractMainComponent } from '../../abstract-main.component';

import { AplicationStateService } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { EstrtgService                  } from '@services/estrtg/estrtg.service';
import { MatSort } from '@angular/material/sort';
import { Ettgst } from '@models/ettgst';
import { MatSelectChange } from '@angular/material/select';
import { ControlLoad } from '@models/control/controlLoad';
import { EttgstService } from '@services/ettgst/ettgst.service';


export abstract class EstrtgPendComponent extends AbstractMainComponent{
  //Controls de cada seção da pagina.
  public _controlMain      : ControlLoad;
  public _controlEttgstList: ControlLoad;

  public _form;

  public displayedColumns: string[] = [ 'nmInv', 'sglTipRsltdOrdem', 'dtRefEstrtg', 'vlStart', 'vlStopPerda', 'vlStopGanho', 'sglEttgst', 'options' ];
  public dataSourceEstrtgList;
  @ViewChild( MatPaginator, {static: true} ) paginator: MatPaginator;
  @ViewChild( 'estrtgListSort', {static: true} ) _estrtgListSort: MatSort;

  public _ettgstList : Ettgst[];

  public isEstrtgListEmpty: boolean;

  constructor( protected router               : Router                ,
               protected formBuilder          : FormBuilder           ,
               protected dialog               : MatDialog             ,
               sanitizer                      : DomSanitizer          ,
               aplicationStateService         : AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar             : MatSnackBar           ,
               protected estrtgService        : EstrtgService         ,
               protected ettgstService        : EttgstService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    super.ngOnInit();

    this._controlMain       = new ControlLoad( 'main' );
    this._controlEttgstList = new ControlLoad( 'ettgstList' );

    this._form = this.createForm();

    // Carrega os filtros.
    this.initFilters();

    this.refresh();
  }


  private createForm(){
    return this.formBuilder.group(
      { sglEttgstSelected: null }
    );
  }


  refresh(){
    this.estrtgService.listPnd()
      .then( dataResp => {
        this.dataSourceEstrtgList = new MatTableDataSource< Estrtg >( dataResp );
        this.dataSourceEstrtgList.paginator = this.paginator;

        this.dataSourceEstrtgList.filterPredicate = (d: Estrtg, filter: string) => {
          try{
            const nmInvDsglEttgst  = d.sglEttgst.toLowerCase() || '';

            return filter        === null ||
                   filter.length === 0    ||
                   nmInvDsglEttgst.indexOf( filter ) !== -1;
          }
          catch( error ){
            console.log( error );
            return false;
          }
        };

        this.dataSourceEstrtgList.sortingDataAccessor = ( item, property ) => {
          switch( property ) {
            case 'nmInv'            : return item.nmInv;
            case 'sglTipRsltdOrdem' : return item.tipOrdem.nmTipOrdem;
            case 'dtRefEstrtg'      : return item.dtRefEstrtg;
            case 'vlStart'          : return item.vlStart;
            case 'vlStopPerda'      : return item.vlStopPerda;
            case 'vlStopGanho'      : return item.vlStopGanho;
            case 'sglEttgst'        : return item.sglEttgst;

            default: return item[ property ];
          }
        };

        this.dataSourceEstrtgList.sort = this._estrtgListSort;

        this.isEstrtgListEmpty = (dataResp.length === 0);
      });
  }

  private initFilters(): void{
    this._controlEttgstList.isCanShow = true;

    this.onLoadEttgstList();
  }

  private onLoadEttgstList(): Promise< any >{
    const promises = [];

    if( this._controlEttgstList.isCanShow === true ){
      promises.push( this.loadEttgstList() );
    }

    const p = new Promise( function( resolve, reject ){
      Promise.all( promises )
        .then( resp => {
          resolve();
        } )
        .catch( err => {
          reject();
        } );
    } );

    return p;
  }

  private loadEttgstList(): Promise< any >{
    this._controlEttgstList.initializingLoad();

    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this.ettgstService.list()
        .then( ettgstList => {
          //Adiciona aa lista de estrategistas um referente a Todos.
          const ettgstTodos = new Ettgst();
          ettgstTodos.sglEttgst = '';
          ettgstTodos.nmEttgst  = 'Todos';
          ettgstList.splice( 0, 0, ettgstTodos );

          _this._ettgstList = ettgstList;
        } )
        .catch( error => {
          console.error( error );
          _this.showMessageError( error, 'OK' );

          _this._ettgstList      = null;
        } )
        .finally( () => {
          _this._controlEttgstList.loaded();

          resolve();
        } );
    } );

    return p;
  }

  onSubmit(){
  }

  private execFilterSglEttgst( filterValue: string ){
    if( this.dataSourceEstrtgList && this.dataSourceEstrtgList != null ){
      this.dataSourceEstrtgList.filter = filterValue && filterValue != null ? filterValue.trim().toLowerCase() : '';
    }
  }

  public onRefreshEstrtgList(): void{
    this.refresh();
    this.onLoadEttgstList();
  }

  detailEstrtg( dtRefEstrtg: Date, cdInv: number, sglEttgst: string ){
    const queryParams = {
      dtRefEstrtg: dtRefEstrtg,
      cdInv: cdInv,
      sglEttgst: sglEttgst
    };

    this.goToPage( '/estrtg-detail', queryParams );
  }

  detailTicker( cdInv: number ){
    const queryParams = {
      cdInv: cdInv,
    };

    this.goToPage( '/inv-detail', queryParams, true );
  }

  listEttgst(){
    const queryParams = {};

    this.goToPage( '/ettgst-list', queryParams );
  }


  public updateSglEttgstSelected( event: MatSelectChange ){
    const sglEttgstSelected = event != null ? event.value : null;

    this.execFilterSglEttgst( sglEttgstSelected );
  }
}

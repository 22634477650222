import { Component              } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder            } from '@angular/forms';

import { LoginComponent }   from '../login.component';

import { AuthenticationService  } from '@services/authentication/authentication.service';
import { SessionService         } from '@services/session/session.service';
import { PrmUsrService          } from '@services/prmUsr/prmUsr.service';

@Component({
  selector: 'app-login-mobile',
  templateUrl: '../desktop/login-desktop.component.html',
  styleUrls: ['./login-mobile.component.css']
})
export class LoginMobileComponent extends LoginComponent {
    constructor( formBuilder: FormBuilder,
                 route: ActivatedRoute,
                 router: Router,
                 protected sessionService: SessionService,
                 protected authenticationService: AuthenticationService,
                 protected prmUsrService: PrmUsrService ) { 
        super( formBuilder, route, router, sessionService, authenticationService, prmUsrService );
    }
}
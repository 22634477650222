import { Injectable                               } from '@angular/core';
import { HttpClient                               } from '@angular/common/http';
import { HttpBasicService                         } from '../http-basic.service';
import { SessionService                           } from '../session/session.service';
import { CrctInv, CrctInvDetail, TipInv, EspcAcao } from 'src/app/_models/inv';
import { PrmUsr                                   } from 'src/app/_models/prmUsr';

@Injectable({
  providedIn: 'root'
})
export class PrmUsrService extends HttpBasicService< PrmUsr, PrmUsr >{
  private lastCallServiceToUpdatePrmUsr = null;   //Armazena o ultimo TS da chamada ao servico de atualizacao dos parametros do usuario.

  constructor( protected http: HttpClient,
               public sessionService: SessionService ) {
    super( http, sessionService );

    this.checkServiceOfUpdatePrmUsr( true );
  }

  public checkServiceOfUpdatePrmUsr( isForceUpdate: boolean ){
    if( isForceUpdate === true || ( this.sessionService.isUserLogged() == true && this.lastCallServiceToUpdatePrmUsr == null ) ){
      this.list()
        .then( dataResp => {
          this.sessionService.clearPrmUsr();
          const countElem = dataResp && dataResp != null ? dataResp.length : 0;

          for( let i = 0; i < countElem; i++ ){
            const prmUsr = dataResp[ i ];

            this.sessionService.setPrmUsr( prmUsr.sglPrmUsr, prmUsr.txPrmUsr );
          }

          this.lastCallServiceToUpdatePrmUsr = new Date();
        });
    }
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValue( sglPrmUsr: string ): string{
    //console.log( 'PrmUsrService.getValue( ' + sglPrmUsr + ' )...' );

    return this.sessionService.getValueOfPrmUsr( sglPrmUsr );
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValueInt( sglPrmUsr: string ): number{
    const txPrmUsr = this.getValue( sglPrmUsr );

    const numberPrmUsr: number = txPrmUsr != null ? Number.parseInt( txPrmUsr ) : null;

    return numberPrmUsr;
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValueDate( sglPrmUsr: string ): Date{
    const txPrmUsr = this.getValue( sglPrmUsr );

    const datePrmUsr: Date = txPrmUsr != null ? new Date( txPrmUsr ) : null;

    return datePrmUsr;
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValueNumberList( sglPrmUsr: string ): number[]{
    const txPrmUsr = this.getValue( sglPrmUsr );

    const arrNumbers = txPrmUsr != null ? txPrmUsr.split( ',' ).map( Number ) : null;

    return arrNumbers;
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValueStringList( sglPrmUsr: string ): string[]{
    const txPrmUsr = this.getValue( sglPrmUsr );

    const arr: string[] = txPrmUsr != null ? txPrmUsr.split( ',' ) : null;

    return arr;
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValueJsonList( sglPrmUsr: string ): any[]{
    const txPrmUsr = this.getValue( sglPrmUsr );

    const jsonParsed = JSON.parse( txPrmUsr );

    const arrElements: any[] = jsonParsed != null ? Object.values( jsonParsed ) : null;

    let arrJson: any[] = null;

    if( arrElements != null ){
      arrJson = [];

      arrElements.forEach( json => {
        arrJson.push( json );
      } );
    }

    return arrJson;
  }

  public setValue( sglPrmUsr: string, txPrmUsr: string ){
    //console.log( 'setValue( ' + sglPrmUsr + ', ' + txPrmUsr + ' )...' );

    this.sessionService.setPrmUsr( sglPrmUsr, txPrmUsr );

    if( this.sessionService.isUserLogged() === true ){
      super.detailGeneric( '/PRM_USR/SET', { 'sgl_prm_usr': sglPrmUsr, 'tx_prm_usr': txPrmUsr }, true );
    }
  }

  public setValueInt( sglPrmUsr: string, numberPrmUsr: number ){
    this.setValue( sglPrmUsr, ( numberPrmUsr != null ? numberPrmUsr.toString() : null ) );
  }

  public setValueDate( sglPrmUsr: string, datePrmUsr: Date ){
    this.setValue( sglPrmUsr, ( datePrmUsr != null ? datePrmUsr.toString() : null ) );
  }

  public setValueNumberList( sglPrmUsr: string, valueList: number[] ){
    //Transforma o array de number em uma string.
    let txPrmUsr = '';

    valueList.forEach( ( valueIterate, indexIterate ) => {
      if( valueIterate != null ){
        if( indexIterate > 0 ){
          txPrmUsr += ', ';
        }

        txPrmUsr += valueIterate.toString();
      }
    } );

    this.setValue( sglPrmUsr, txPrmUsr );
  }

  public setValueStringList( sglPrmUsr: string, valueList: string[] ){
    //Transforma o array de string em uma string.
    let txPrmUsr = '';

    valueList.forEach( ( valueIterate, indexIterate ) => {
      if( valueIterate != null ){
        if( indexIterate > 0 ){
          txPrmUsr += ', ';
        }

        txPrmUsr += valueIterate;
      }
    } );

    this.setValue( sglPrmUsr, txPrmUsr );
  }

  /**
   * 0: {"nmInfCrctInv":"SMA (5)","perdcInfCrctInv":5,"tipViewInfCrctInv":null}, 
   * 1: {"nmInfCrctInv":"SMA (20)","perdcInfCrctInv":20,"tipViewInfCrctInv":null}
   */
  public setValueJsonList( sglPrmUsr: string, valueList: any[] ){
    //Transforma o array de number em uma string.
    let txPrmUsr = '{';

    valueList.forEach( ( valueIterate, indexIterate ) => {
      if( valueIterate != null ){
        if( indexIterate > 0 ){
          txPrmUsr += ', ';
        }

        const elementJson = JSON.stringify( valueIterate );

        txPrmUsr += ( '"' + indexIterate.toString() + '":' + elementJson.toString() );
      }
    } );

    txPrmUsr += '}';

    //console.log( 'setValueJsonList: ' + txPrmUsr );

    this.setValue( sglPrmUsr, txPrmUsr );
  }

  detail( sglPrmUsr: string ) : Promise< PrmUsr > {
    return super.detailGeneric( '/PRM_USR/DETAIL', { 'sgl_prm_usr': sglPrmUsr }, true );
  }

  list(): Promise< PrmUsr[] >{
    if( this.sessionService.isUserLogged() === true ){
      return super.listGeneric( '/PRM_USR/LIST', {}, true );
    }
    else{
      return new Promise< PrmUsr[] >( function( resolve, reject ) {
        reject( 'Usuário não logado.' );
      });
    }
  }
}
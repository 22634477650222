import { Component, OnInit              } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Router, NavigationExtras                   } from '@angular/router';
import { HomeComponentModel             } from './home.component.model';
import { FormBuilder                    } from '@angular/forms';
import { FormControl                    } from '@angular/forms';
import { Observable                     } from 'rxjs';
import { map, startWith                 } from 'rxjs/operators';

import { SearchGeneralService           } from '../../_services/search-general/search-general.service';
import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';


export abstract class HomeComponent implements OnInit {
  private model: HomeComponentModel;

  public searchForm;

  public tickersFormControl = new FormControl();

  public nomesEmpresasFormControl = new FormControl();

  // Lista dos tickers possiveis.
  public optionsTickers: string[] = [];

  // Lista dos tickers filtrados.
  public filteredOptionsTickers: Observable< string[] >;

  // Lista dos nomes das empresas possiveis.
  public optionsNomesEmpresas: string[] = [];

  // Lista dos nomes das empresas filtrados.
  public filteredOptionsNomesEmpresas: Observable< string[] >;

  /**
   * 
   */
  constructor( protected router                 : Router, 
               protected sanitizer              : DomSanitizer,
               protected formBuilder            : FormBuilder,
               protected aplicationStateService : AplicationStateService,
               protected prmUsrService          : PrmUsrService,
               protected searchGeneralService   : SearchGeneralService ){
    this.model = new HomeComponentModel( sanitizer );

    this.searchForm = this.createForm();
  }

  public ngOnInit(){
    console.log( 'HomeComponent.ngOnInit()...' );
    //this.searchTickers( '' );
    //this.searchNomesEmpresas( '' );

    //Filtro dos tickers
    this.filteredOptionsTickers = this.tickersFormControl.valueChanges
      .pipe(
        startWith( '' ),
        map( value => this.filterTickers( value ) )
    );

    //Filtro dos nomes das empresas
    this.filteredOptionsNomesEmpresas = this.nomesEmpresasFormControl.valueChanges
      .pipe(
        startWith( '' ),
        map( value => this.filterNomesEmpresas( value ) )
    );
  }

  private createForm(){
    return this.formBuilder.group(
      { tickerSearch            : ''  ,
        tickerSearchLast        : ''  ,
        tickerSearchLastLen     : 0   ,
        nomeEmpresaSearch       : ''  ,
        nomeEmpresaSearchLast   : ''  ,
        nomeEmpresaSearchLastLen: 0   ,
        lastLabelEdited         : ''
      }
    );
  }

  public detailed( ticker: string, nomeEmpresa: string ){
    if( this.searchForm.lastLabelEdited === 'nomeEmpresaSearch' ){
      this.detailedNomeEmpresa();
    }
    else{
      if( this.searchForm.lastLabelEdited === 'tickerSearch' ){
        this.detailedTicker();
      }
      else{
        window.alert( 'Digite um ticker ou nome de empresa para realizar a pesquisa!' );
      }
    }
  }

  public detailedTicker(){
    const nmInv = this.tickersFormControl.value;

    //Cria objeto contendo os dados do orcamento.
    let navigationExtras: NavigationExtras = {
        queryParams:{ 
          'nmInv'     : nmInv
        },

        skipLocationChange: true
    };

    this.router.navigate( [ '/detalhar-ticker' ], navigationExtras );
  }

  public detailedNomeEmpresa(){
  }

  public searchTickers( keySearch: string ){
    this.searchGeneralService.searchTickers( keySearch ).then( 
      ( data: any[] ) => {
        const resultList: string[] = [];

        for( const d of ( data as any ) ){
          let result: string;

          result = d[ 'nmInv' ];

          resultList.push( result );
        }

        this.optionsTickers = resultList;
      }
    );
  }

  public searchNomesEmpresas( keySearch: string ){
    this.searchGeneralService.searchEmpresas( keySearch ).then( 
      ( data: any[] ) => {
        const resultList: string[] = [];

        for( const d of ( data as any ) ){
          let result: string;

          result = d[ 'nmEmpr' ];

          resultList.push( result );
        }

        this.optionsNomesEmpresas = resultList;
      }
    );
  }

  private filterTickers( value: string ): string[] {
    this.searchForm.lastLabelEdited = 'tickerSearch';

    //Obtem o tamanho do texto digitado.
    const valueLength = value == null ? 0 : value.length;

    //Obtem o tamanho do texto anteriormente digitado.
    const valueLastLength = this.searchForm.tickerSearchLastLen;

    if( valueLength == 2 || ( valueLength > 2 && valueLastLength > valueLength ) ){
      this.searchTickers( value );
    }

    //Armazena a ultima string digitada para pesquisa.
    this.searchForm.tickerSearchLast = value;
    this.searchForm.tickerSearchLastLen = valueLength;

    const filterValue = value.toUpperCase();

    return this.optionsTickers.filter( option => option.toUpperCase().includes( filterValue ) );
  }

  private filterNomesEmpresas( value: string ): string[] {
    this.searchForm.lastLabelEdited = 'nomeEmpresaSearch';

    //Obtem o tamanho do texto digitado.
    const valueLength = value == null ? 0 : value.length;

    //Obtem o tamanho do texto anteriormente digitado.
    const valueLastLength = this.searchForm.nomeEmpresaSearchLastLen;

    if( valueLength == 2 || ( valueLength > 2 && valueLastLength > valueLength ) ){
      this.searchNomesEmpresas( value );
    }

    //Armazena a ultima string digitada para pesquisa.
    this.searchForm.nomeEmpresaSearchLast = value;
    this.searchForm.nomeEmpresaSearchLastLen = valueLength;

    const filterValue = value.toUpperCase();

    return this.optionsNomesEmpresas.filter( option => option.toUpperCase().includes( filterValue ) );
  }
}

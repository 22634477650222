export class User {
	id: number;
	cdUsr: number;

	username: string;
	nmUsr: string;

	email: string;
	password: string;
	name: string;
	token?: string;
	pss: Pss;
}


export class Pss {
	cdPss: number;
	nmPss: string;
	tipPss: TipPss;
}


export class TipPss {
	sglTipPss: string;
	nmTipPss : string;
}
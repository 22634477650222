import { Component, OnInit              } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SessionService } from '../../_services/session/session.service';
import { AuthenticationService } from '../../_services/authentication/authentication.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';

export abstract class HeaderComponent{
  public imageUrl: SafeResourceUrl;

  constructor( protected sanitizer: DomSanitizer,
               aplicationStateService: AplicationStateService,
               public sessionService: SessionService,
               authenticationService: AuthenticationService ){
    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl( '' );
  }
}

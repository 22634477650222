import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { SetorAtvdd } from 'src/app/_models/setorAtvdd';


@Injectable({
  providedIn: 'root'
})
export class SetorAtvddService extends HttpBasicService< SetorAtvdd, SetorAtvdd >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Detalha setor da atividade CVM.
   */
  detail( cdSetorAtvdd: number ): Promise< SetorAtvdd >{
    let reqData = {};

    reqData[ 'cd_setor_atvdd'     ] = cdSetorAtvdd    == null ? 0 : cdSetorAtvdd;

    return super.detailGeneric( '/SETOR_ATVDD/DET', reqData, false );
  }

  /**
   * Lista setores de atividade CVM.
   */
  list( cdSetorAtvdd: number ): Promise< SetorAtvdd[] >{
    let reqData = {};

    if( cdSetorAtvdd != null ){
      reqData[ 'cd_setor_atvdd' ] = cdSetorAtvdd;
    } 

    return super.listGeneric( '/SETOR_ATVDD/LIST', reqData, false );
  }
}

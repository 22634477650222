<div id="footer" class="wrap">
  <p class="footer_direitos_autorais">
    Direitos autorais de <label class="name_site">Invest Rápido</label>.
  </p>

  <p class="footer_versao_desktop">
    <svg class="footer_versao_icon_desktop" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="0">
      <path d="M36,27.625h-2.302c0.622-0.339,1.052-0.991,1.052-1.75v-17.5c0-1.104-0.896-2-2-2H5.25c-1.104,0-2,0.896-2,2v17.5 c0,0.759,0.428,1.411,1.051,1.75H2c-1.104,0-2,0.896-2,2s0.896,2,2,2h34c1.104,0,2-0.896,2-2S37.104,27.625,36,27.625z M7.25,10.375h23.5v13.5H7.25V10.375z"></path> 
    </svg>
  </p>
</div>

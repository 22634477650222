import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';

import { Estrtg } from 'src/app/_models/estrtg';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';


@Injectable({
  providedIn: 'root'
})
export class EstrtgService extends HttpBasicService< Estrtg, Estrtg >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService ) {
    super( http, sessionService );
  }

  /**
   * Lista as estrategias pendentes.
   */
  listPnd() {
    return this.list( null ) ;
  }

  /**
   * Lista estrategias.
   * @param isOnlyPendente: Se true, serao retornados apenas os pendentes, caso contrario, serao retornados todos.
   */
  list( sglTipRsltdOrdem: string ): Promise< Estrtg[] >{
    const objData = { 'sgl_tip_rsltd_ordem': (sglTipRsltdOrdem == null ? '%00' : sglTipRsltdOrdem) };

    //Executa servico.
    const respJsonGen = super.reqGetJsonGeneric( '/ESTRTG/LIST', objData );

    let _this = this;

    let objList = new Array< Estrtg >();

    return new Promise< Estrtg[] >( function( resolve, reject ) {
      respJsonGen.then( rag => {
        let dataResp = rag.dataResp;

        for( const dR of (dataResp as any) ){
          objList.push( dR );
        }

        resolve( objList );
      });
    });
  }

  /**
   * Detalha uma estrategia.
   */
  detail( dtRefEstrtg: Date, cdInv: number, sglEttgst: string ) : Promise< Estrtg > {
    const reqData = { 'dt_ref_estrtg': dtRefEstrtg,
                      'cd_inv': cdInv,
                      'sgl_ettgst': sglEttgst };

    //Executa servico.
    const respJson = super.reqGetJsonGeneric( '/ESTRTG/DET', reqData );

    let _this = this;

    return new Promise< Estrtg >( function( resolve, reject ) {
      respJson.then( dataResp => {
        let d = new Estrtg();

        d = dataResp[ 'dataResp' ];

        resolve( d );
      });
    });
  }
}

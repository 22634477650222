import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpBasicService } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { IndTcn, HstIndTcn } from 'src/app/_models/indTcn';
import { DatePipe } from '@angular/common';
import { PerdcIndTcn } from 'src/app/_models/perdc';

@Injectable({
  providedIn: 'root'
})
export class IndTcnService extends HttpBasicService< IndTcn, IndTcn >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista historico dos valores calculados para o indicador tecnico.
   */
  listHst( cdInv: number, sgSnlTcn: string, sgIndTcn: string, perdcIndTcn: number, dtIndTcnStart: Date, dtIndTcnEnd: Date, limit: number ): Promise< HstIndTcn[] >{
    let reqData = { cd_inv: cdInv };

    if( sgSnlTcn != null ){
      reqData[ 'sg_snl_tcn' ] = sgSnlTcn;
    }

    if( sgIndTcn != null ){
      reqData[ 'sg_ind_tcn' ] = sgIndTcn;
    }

    if( perdcIndTcn != null ){
      reqData[ 'perdc_ind_tcn' ] = perdcIndTcn;
    }

    if( dtIndTcnStart != null || dtIndTcnEnd != null ){
      let dtIndTcn = "";

      if( dtIndTcnStart != null ){
        dtIndTcn = this.datePipe.transform( dtIndTcnStart, 'yyyy-MM-dd' );
      }

      if( dtIndTcnEnd != null ){
        dtIndTcn += "," + this.datePipe.transform( dtIndTcnEnd, 'yyyy-MM-dd' );
      }

      reqData[ 'dt_ind_tcn' ] = dtIndTcn;
    }

    if( limit != null ){
      reqData[ 'limit' ] = limit;
    }

    return super.listGenericX< HstIndTcn >( '/IND_TCN/LIST_HST', reqData, false );
  }

  /**
   * Lista os indicadores técnicos existentes.
   */
  public listIndTcn(): Promise< IndTcn[] >{
    return super.listGenericX< IndTcn >( '/IND_TCN/LIST_IND_TCN', null, false );
  }

  /**
   * Lista as periodicidades existentes para o indicador tecnico especificado.
   */
  public listPerdcOfIndTcn( sgSnlTcn: string, sgIndTcn: string ): Promise< PerdcIndTcn[] >{
    const reqData = { sg_snl_tcn: sgSnlTcn, sg_ind_tcn: sgIndTcn };
    return super.listGenericX< PerdcIndTcn >( '/IND_TCN/LIST_PERDC_IND_TCN', reqData, false );
  }
}
import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { PerdDmntCntb } from 'src/app/_models/perdDmntCntb';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';


@Injectable({
  providedIn: 'root'
})
export class PdcService extends HttpBasicService< PerdDmntCntb, PerdDmntCntb >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista documentos de demonstração contabil.
   */
  list( cdEmpr: number, sglDocDmntCntb: string, dtPblccDocDmntCntb: Date ): Promise< PerdDmntCntb[] >{
    const dtPblccDocDmntCntbSgbdFormat = this.datePipe.transform( dtPblccDocDmntCntb, 'yyyy-MM-dd', '+00:00' );

    return super.listGeneric( '/PDC/LIST', { cd_empr: cdEmpr, sgl_doc_dmnt_cntb: sglDocDmntCntb, dt_pblcc_doc_dmnt_cntb: dtPblccDocDmntCntbSgbdFormat }, false );
  }
}

<!-- ================================= CONTENT ================================= -->

<h2>
	<span mat-dialog-title>Carteira <span *ngIf="this._grCrctInvAcao != null then nmGrCrctInvAcao"></span></span>
	<ng-template #nmGrCrctInvAcao>{{this._grCrctInvAcao.nmGrCrctInvAcao}}</ng-template>
	<mat-progress-bar *ngIf="this._controlMain.isShowProgressBar" [mode]="this._controlMain.modeProgressBar" value="this._controlMain.valueProgressBar"></mat-progress-bar>
</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true"
						 (opened)="onOpenedPanelDadosBasicos()"
						 (closed)="onClosedPanelDadosBasicos()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Dados Básicos
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlDadosBasicos.isShowProgressBar" 
								  [mode]="this._controlDadosBasicos.modeProgressBar" 
								  value="this._controlDadosBasicos.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_mvtc_dadosBasicos">
			<div class="grid_item_gcia_mvtc_dadosBasicos_refresh">
				<button mat-icon-button color="accent" (click)="this.onRefreshGrCrctInvAcao()">
					<mat-icon matTooltip="Atualizar dados básicos da Carteira.">refresh</mat-icon>
				</button>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_tipGrCrctInvAcao">
				<mat-icon [matTooltip]="this._grCrctInvAcao.tipGrCrctInvAcao.txDescrTipGrCrctInvAcao" *ngIf="this._grCrctInvAcao != null && this._grCrctInvAcao.tipGrCrctInvAcao != null">{{this._grCrctInvAcao.tipGrCrctInvAcao.nmIcon}}</mat-icon>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_compartGrCrctInvAcao">
				<span *ngIf="this.isAllowedShowUsrComptList() === true">
					<button mat-icon-button 
							matTooltip="Usuários com os quais esta Carteira está sendo compartilhada"
							[matMenuTriggerFor]="menuToUsrComptList">
						<mat-icon>folder_shared</mat-icon>
					</button>
					<mat-menu #menuToUsrComptList="matMenu">
						<div *ngFor="let usrCompt of this._usrComptGrCrctInvAcaoListDataSource.data">
							<button mat-menu-item
									(click)="this.onClickRemoveUsrComptGrCrctInvAcao( usrCompt )">
								<mat-icon matTooltip="Remover o compartilhamento da Carteira">person_remove</mat-icon>
								<span>{{usrCompt.usr.nmUsr}}</span>
							</button>
						</div>

						<button mat-menu-item
								(click)="this.onClickAddUsrComptGrCrctInvAcao()">
							<mat-icon matTooltip="Compartilhar Carteira com outros usuários">person_add</mat-icon>
							<span>Compartilhar Carteira</span>
						</button>
					</mat-menu>
				</span>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_saldo">
				Saldo Atual da Carteira: 	<span *ngIf="this._grCrctInvAcao != null && this._grCrctInvAcao.vlSdoInvtAtlzd != null">
												&nbsp;{{this._grCrctInvAcao.vlSdoInvtAtlzd | number:'1.2-2'}}
											</span>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_capital">
				Capital:	<span *ngIf="this._grCrctInvAcao != null">
								&nbsp;{{this._grCrctInvAcao.vlSdoCptl | number:'1.2-2'}}
							</span>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_caixa">
				Em Caixa:	<span *ngIf="this._grCrctInvAcao != null && this._grCrctInvAcao.vlSdoCx != null">
								&nbsp;{{this._grCrctInvAcao.vlSdoCx | number:'1.2-2'}}
							</span>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_investido">
				Investido:	<span *ngIf="this._grCrctInvAcao != null && this._grCrctInvAcao.vlSdoInvtAtlzd != null">
								&nbsp;{{this._grCrctInvAcao.vlSdoInvt | number:'1.2-2'}} 
								<span>
									({{ ( this._grCrctInvAcao.vlSdoInvtAtlzd - this._grCrctInvAcao.vlSdoInvt ) | number:'1.2-2' }})
								</span>
							</span>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_realizado">
				Realizado:	<span *ngIf="this._grCrctInvAcao != null && this._grCrctInvAcao.vlSdoRlzd != null && this._grCrctInvAcao.vlSdoCptl != null">
								&nbsp;{{this._grCrctInvAcao.vlSdoRlzd | number:'1.2-2'}} | {{this._grCrctInvAcao.vlSdoRlzd / this._grCrctInvAcao.vlSdoCptl*100 | number:'1.2-2'}}%
							</span>
			</div>

			<div class="grid_item_gcia_mvtc_dadosBasicos_rstd">
				Resultado:	<span *ngIf="this._grCrctInvAcao != null && this._grCrctInvAcao.vlSdoRstd != null">
								&nbsp;{{this._grCrctInvAcao.vlSdoRstd | number:'1.2-2'}}
							</span>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false"
	                     (opened)="onOpenedPanelItemGrCrctInvAcaoList()"
						 (closed)="onClosedPanelItemGrCrctInvAcaoList()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Ativos
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlFilters.isShowProgressBar" [mode]="this._controlFilters.modeProgressBar" value="this._controlFilters.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_mvtc_filters">
			<div class="grid_item_gcia_mvtc_filters_itens_isShowAlsoNotInvestment">
				<mat-checkbox labelPosition="after"
							  (change)="onClickShowAlsoItemGrNotInvestment()" 
							  formControlName="isShowAlsoItemGrCrctInvAcaoNotInvestment" >
					Mostrar ativos sem investimento atualmente?
				</mat-checkbox>
			</div>

			<div class="grid_item_gcia_mvtc_filters_itens_isShowAlsoRemoveds">
				<mat-checkbox labelPosition="after"
							  (change)="onClickShowAlsoItemGrRemoved()" 
							  formControlName="isShowAlsoItemGrCrctInvAcaoRemoved" >
					Mostrar ativos que foram excluídos da Carteira?
				</mat-checkbox>
			</div>

			<div class="grid_item_gcia_mvtc_filters_itens_progress">
				<ng-container *ngIf="this._controlItemGrCrctInvList.isShowProgressBar;else itemTableRefresh">
					<mat-progress-bar mode="this._controlItemGrCrctInvList.modeProgressBar" value="this._controlItemGrCrctInvList.valueProgressBar"></mat-progress-bar>
				</ng-container>
				<ng-template #itemTableRefresh>
					<button mat-icon-button color="accent" (click)="this.onToggleColumnsItemGrCrctInvAcaoList()">
						<mat-icon matTooltip="Controlar colunas a serem apresentadas na lista de Ativos.">view_column</mat-icon>
					</button>
					<button mat-icon-button color="accent" (click)="this.onRefreshItemGrCrctInvAcaoList()">
						<mat-icon matTooltip="Atualizar Ativos.">refresh</mat-icon>
					</button>
				</ng-template>
			</div>

			<div class="grid_item_gcia_mvtc_filters_itens_value">
				<mat-sidenav-container (backdropClick)="this.onCloseColumnsItemGrCrctInvAcaoList()">
					<mat-sidenav-content class="sidenav-table-columnsItemGrCrctInvAcaoList" >
						<table mat-table [dataSource]="_itemGrCrctInvAcaoListDataSource" #itemGrCrctInvAcaoListSort="matSort" matSort>
							<ng-container matColumnDef="crctInvItens" sticky>
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Ativo </th>
								<td mat-cell *matCellDef="let element">
									<span [ngClass]="{'text_riscado' : element.inItemGrCrctInvAcaoAtivo === false || element.controlShow.isAllowedShowMvtcItemGrCrctInvAcao === false}">
										<a routerLinkActive="active" (click)="onClickDetailTicker( $event, element.crctInv.cdInv )" class="linkInTable">
											<span *ngIf="element.crctInv.tipInv.cdTipInv === 4 then nmInvTesouroDireto else nmInvDemais"></span>
											<ng-template #nmInvTesouroDireto>{{element.crctInv.crctInvAcao.nmPregao}}</ng-template>
											<ng-template #nmInvDemais>{{element.crctInv.nmInv}}</ng-template>
										</a>

										<span *ngIf="element.tipTndc != null">
											<mat-icon *ngIf="element.tipTndc.sglTipTndc === 'A'" class="blue-icon" matTooltip="Ativo com tendência de alta, conforme informado pelo usuário.">north_east</mat-icon>
											<mat-icon *ngIf="element.tipTndc.sglTipTndc === 'B'" class="red-icon"  matTooltip="Ativo com tendência de baixa, conforme informado pelo usuário.">south_east</mat-icon>
											<mat-icon *ngIf="element.tipTndc.sglTipTndc === 'N'" class="green-icon" matTooltip="Ativo em lateralidade, conforme informado pelo usuário.">east</mat-icon>
										</span>
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="tipInvItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
								<td mat-cell *matCellDef="let element">
									<span>
										{{element.crctInv.tipInv.nmTipInv}}
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="emprItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
								<td mat-cell *matCellDef="let element">
									<span>
										<a [routerLink]="" routerLinkActive="active" (click)="onClickDetailEmpr( element.crctInv.empr.cdEmpr )" class="linkInTable">
											<span>
												<ng-container *ngIf="element.crctInv.empr.nmAbrvEmpr;else nmEmprTemplate">
													{{element.crctInv.empr.nmAbrvEmpr}}
												</ng-container>
												<ng-template #nmEmprTemplate>
													{{element.crctInv.empr.nmEmpr}}
												</ng-template>
											</span>
										</a>
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="vlSdoRlzdItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Realizado </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.vlSdoRlzd != null">
										{{element.vlSdoRlzd | number:'1.2-2'}}
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="vlInvtAtuItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Investido </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.vlSdoInvt != null && element.vlSdoInvt > 0">
										{{element.vlSdoInvt | number:'1.2-2'}}
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="qtdUndNegcItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Qtd. </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.qtdUndNegc != null && element.qtdUndNegc > 0">
										{{element.qtdUndNegc | number:'1.0-9'}}
										<mat-icon class="icon_help" *ngIf="element.prcMedAtu != null && element.prcMedAtu > 0" 
												matTooltip="Preço Médio: R$ {{element.prcMedAtu | number:'1.2-2'}}"
												[matTooltipClass]="'multline-tooltip'" >info
										</mat-icon>
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="vlItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Atual </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.vlTtlUndNegc != null">
										{{element.vlTtlUndNegc | number:'1.2-2'}}
										<mat-icon class="icon_help" 
											matTooltip="Cotação no dia {{element.crctInv.crctInvAcao.cotcAcao.dtCotc | date:'dd/MM/yyyy':'+0000'}}:
														&nbsp;&nbsp;&nbsp; Preço Abert.: {{element.crctInv.crctInvAcao.cotcAcao.prcAbert | number:'1.2-2'}}
														&nbsp;&nbsp;&nbsp; Preço Mín./Máx.: {{element.crctInv.crctInvAcao.cotcAcao.prcMin | number:'1.2-2'}} - {{element.crctInv.crctInvAcao.cotcAcao.prcMax | number:'1.2-2'}}
														&nbsp;&nbsp;&nbsp; Preço Fech.: {{element.crctInv.crctInvAcao.cotcAcao.prcFech | number:'1.2-2'}}
														&nbsp;&nbsp;&nbsp; Volume de Neg.: {{element.crctInv.crctInvAcao.cotcAcao.volNegc | shortNumber:''}}
														&nbsp;&nbsp;&nbsp; Variação: {{element.crctInv.crctInvAcao.cotcAcao.varPrcFech | number:'1.2-2'}}%"
											[matTooltipClass]="'multline-tooltip'" >info
										</mat-icon>
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="vlRendimentoItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Rendimento </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.vlTtlUndNegc != null && element.vlSdoInvt != null">
										{{(element.vlTtlUndNegc - element.vlSdoInvt) | number:'1.2-2'}}
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="vlRendimentoPercItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Rendimento % </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.vlTtlUndNegc != null && element.vlSdoInvt != null">
										{{ ( (element.vlTtlUndNegc - element.vlSdoInvt) / element.vlSdoInvt * 100 ) | number:'1.1-1'}}%
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="vlSdoRstdItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Resultado </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.vlSdoRstd != null">
										{{element.vlSdoRstd | number:'1.2-2'}}
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="assistItens">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Sugestão </th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="element.ultAcaoAssistList != null && element.ultAcaoAssistList.length > 0 && element.ultAcaoAssistList[0].hstAcaoAssist.dtRefAcaoAssist > element.ultAcaoAssistList[0].mvtcGrCrctInvAcao.dtRefMvtc">
										{{element.ultAcaoAssistList[0].hstAcaoAssist.tipAcaoAssist.nmTipAcaoAssist}}
										<mat-icon class="icon_help" 
											matTooltip="Data de Ref.: {{element.ultAcaoAssistList[0].hstAcaoAssist.dtRefAcaoAssist | date:'dd/MM/yyyy':'+0000'}}"
											[matTooltipClass]="'multline-tooltip'" >info
										</mat-icon>
									</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="optionsItens">
								<th mat-header-cell *matHeaderCellDef></th>
								<td mat-cell *matCellDef="let element">
									<span class="column_options">
										<button mat-icon-button *ngIf="element.controlShow.isAllowedShowMvtcItemGrCrctInvAcao === true" color="accent" (click)="this.onHiddenMvtcCrctInv( element )">
											<mat-icon matTooltip="Deixar de mostrar as movimentações do ativo.">visibility_off</mat-icon>
										</button>

										<button mat-icon-button *ngIf="element.controlShow.isAllowedShowMvtcItemGrCrctInvAcao === false" color="accent" (click)="this.onShowMvtcCrctInv( element )">
											<mat-icon matTooltip="Mostrar movimentações do ativo.">visibility</mat-icon>
										</button>

										<button mat-mini-fab
												*ngIf="element.inItemGrCrctInvAcaoAtivo === true && this.isGrCrctInvAcaoCompt() === false" 
												[matMenuTriggerFor]="menuToItemGr" 
												class="column_options_element" 
												color="primary" >
											<mat-icon class="white-icon" matTooltip="Menu de opções...">more_vert</mat-icon>
										</button>
										<mat-menu #menuToItemGr="matMenu">
											<div *ngFor="let tipMvtc of this._tipMvtcGrCrctInvAcaoList">
												<button mat-menu-item
														*ngIf="tipMvtc.inNcddCdInv === true" 
														(click)="this.onAddMvtcGrCrctInvAcaoToCrctInvAndTipMvtc( element.crctInv.cdInv, tipMvtc )">
													<mat-icon [matTooltip]="tipMvtc.txDescrTipMvtcGrCrctInvAcao">{{tipMvtc.nmIcon}}</mat-icon>
													<span>{{tipMvtc.nmTipMvtcGrCrctInvAcao}}</span>
												</button>
											</div>

											<button mat-menu-item *ngIf="element.inItemGrCrctInvAcaoAtivo === true" color="primary" (click)="this.onClickRemoveItemGrCrctInvAcao( element )">
												<mat-icon matTooltip="Excluir ativo da Carteira.">delete</mat-icon>
												<span>Excluir ativo</span>
											</button>

											<button mat-menu-item *ngIf="element.inItemGrCrctInvAcaoAtivo === false" color="primary" (click)="this.onClickReactivateItemGrCrctInvAcao( element )">
												<mat-icon matTooltip="Incluir novamente o ativo na Carteira">toggle_off</mat-icon>
												<span>Incluir ativo</span>
											</button>
										</mat-menu>
									</span>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="_itemGrCrctInvAcaoListDisplayedColumns; sticky: true"></tr>
							<tr mat-row        *matRowDef="let row; columns: _itemGrCrctInvAcaoListDisplayedColumns;"></tr>
						</table>

						<mat-paginator 	#itemGrCrctInvAcaoListPaginator
										class="mat-paginator-sticky"
										[pageSizeOptions]="[10, 20, 50, 100, 200]" 
										[pageSize]="this._itemGrCrctInvAcaoListPaginatorPageSize"
										[pageIndex]="this._itemGrCrctInvAcaoListPaginatorPageIndex"
										(page)="onChangeItemGrCrctInvAcaoListPage( $event )" 
										showFirstLastButtons>
						</mat-paginator>
					</mat-sidenav-content>

					<mat-sidenav #sidenavColumnsItemGrCrctInvAcaoList position="end" (keydown.escape)="this.onCloseColumnsItemGrCrctInvAcaoList()" disableClose >
						<div class="grid_container_gcia_mvtc_crctInv_columnsView">
							<div class="grid_item_gcia_mvtc_crctInv_columnsView_header">
								Selecione as colunas que queira visualizar na lista de Ativos
							</div>

							<div class="grid_item_gcia_mvtc_crctInv_columnsView_list">
								<div *ngFor="let columnItemGrCrctInvAcao of this._itemGrCrctInvAcaoListAvailableColumns" >
									<mat-checkbox [disabled]="!columnItemGrCrctInvAcao.isAllowedChange" [checked]="columnItemGrCrctInvAcao.isAllowedShow" (change)="this.onClickShowColumnItemGrCrctInvAcao( $event, columnItemGrCrctInvAcao )">{{columnItemGrCrctInvAcao.nmDisplayable}}</mat-checkbox>
								</div>
							</div>
						</div>
					</mat-sidenav>
				</mat-sidenav-container>
			</div>

			<div class="grid_item_gcia_mvtc_filters_itens_buttons">
				<button mat-icon-button class="column_options_element" color="accent" (click)="this.onShowMvtcAllCrctInv()">
					<mat-icon matTooltip="Mostrar as movimentações de todos os ativos.">visibility</mat-icon>
				</button>

				<button mat-icon-button class="column_options_element" color="accent" (click)="this.onHiddenMvtcAllCrctInv()">
					<mat-icon matTooltip="Deixar de mostrar as movimentações de todos os ativos.">visibility_off</mat-icon>
				</button>

				<button mat-icon-button *ngIf="this.isGrCrctInvAcaoCompt() === false" class="column_options_element" color="accent" (click)="this.onClickEditGrCrctInv()">
					<mat-icon matTooltip="Editar a Carteira">settings</mat-icon>
				</button>

				<button mat-icon-button *ngIf="this.isGrCrctInvAcaoCompt() === false" color="accent" (click)="this.onClickAddTicker()">
					<mat-icon matTooltip="Adicionar ativo à Carteira.">playlist_add</mat-icon>
				</button>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false" 
						 (opened)="onOpenedPanelHstCntbMensal()"
						 (closed)="onClosedPanelHstCntbMensal()" >
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Histórico Mensal
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlHstCntbMensal.isShowProgressBar" [mode]="this._controlHstCntbMensal.modeProgressBar" value="this._controlHstCntbMensal.valueProgressBar">
				</mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_mvtc_hstCntbMensal">
			<div class="grid_gcia_mvtc_hstCntbMensal_filter_dtMvtcFrom">
				<mat-form-field>
					<mat-label>De:</mat-label>

					<input matInput 
						   [matDatepicker]="pickerDtHstCntbFrom" 
						   formControlName="dtHstCntbFrom"
						   (ngModelChange)="onChangeDtHstCntbFrom( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerDtHstCntbFrom"></mat-datepicker-toggle>
					<mat-datepicker #pickerDtHstCntbFrom></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_gcia_mvtc_hstCntbMensal_filter_dtMvtcEnd">
				<mat-form-field>
					<mat-label>Até:</mat-label>

					<input matInput 
						[matDatepicker]="pickerDtHstCntbEnd" 
						formControlName="dtHstCntbEnd"
						(ngModelChange)="onChangeDtHstCntbEnd( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerDtHstCntbEnd"></mat-datepicker-toggle>
					<mat-datepicker #pickerDtHstCntbEnd></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_gcia_mvtc_hstCntbMensal_table_progress">
				<ng-container *ngIf="this._controlHstCntbMensal.isShowProgressBar;else hstCntbTableRefresh">
					<mat-progress-bar mode="this._controlHstCntbMensal.modeProgressBar" value="this._controlHstCntbMensal.valueProgressBar"></mat-progress-bar>
				</ng-container>
				<ng-template #hstCntbTableRefresh>
					<button mat-icon-button color="accent" (click)="this.onRefreshHstCntbGrCrctInvAcao()">
						<mat-icon matTooltip="Atualizar histórico contábil mensal.">refresh</mat-icon>
					</button>
				</ng-template>
			</div>

			<div class="grid_gcia_mvtc_hstCntbMensal_table_value">
				<table mat-table [dataSource]="_hstCntbGrCrctInvAcaoListDataSource" #hstCntbGrCrctInvAcaoListSort="matSort" matSort>
					<ng-container matColumnDef="dtRefHstCntb" sticky>
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Mês/Ano </th>
						<td mat-cell *matCellDef="let element">
							{{element.dtRefHstCntb | date:'MM/yyyy':'+0000'}}
						</td>
					</ng-container>

					<ng-container matColumnDef="vlSdoCptlHstCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Capital </th>
						<td mat-cell *matCellDef="let element"> 
							<span>
								{{element.vlSdoCptl | number:'1.2-2'}}
							</span>
							<span *ngIf="element.vlVarPercSdoCptl != null && element.vlVarPercSdoCptl != 0">
								<span *ngIf="element.vlVarPercSdoCptl >= 0; then varPosVlVarPercSdoCptlThenBlock else varNegVlVarPercSdoCptlThenBlock"></span>
							</span>
							<ng-template #varPosVlVarPercSdoCptlThenBlock>
								<div class="mat-cell-value-positive">
									<div class="mat-cell-value-positive">+{{element.vlVarSdoCptl |number:'1.2-2'}}</div>
									<div class="mat-cell-value-positive"><mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.vlVarPercSdoCptl | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
							<ng-template #varNegVlVarPercSdoCptlThenBlock>
								<div class="mat-cell-value-negative">
									<div class="mat-cell-value-negative">{{element.vlVarSdoCptl |number:'1.2-2'}}</div>
									<div class="mat-cell-value-negative"><mat-icon class="icon_var_value_down" >arrow_downward</mat-icon>   {{element.vlVarPercSdoCptl | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlSdoRlzdHstCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Realizado</th>
						<td mat-cell *matCellDef="let element"> 
							<span>
								{{element.vlSdoRlzd | number:'1.2-2'}}
							</span>
							<span *ngIf="element.vlVarPercSdoRlzd != null">
								<span *ngIf="element.vlVarPercSdoRlzd >= 0; then varPosVlVarPercSdoRlzdThenBlock else varNegVlVarPercSdoRlzdThenBlock"></span>
							</span>
							<ng-template #varPosVlVarPercSdoRlzdThenBlock>
								<div class="mat-cell-value-positive">
									<div class="mat-cell-value-positive">+{{element.vlVarSdoRlzd |number:'1.2-2'}}</div>
									<div class="mat-cell-value-positive"><mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.vlVarPercSdoRlzd | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
							<ng-template #varNegVlVarPercSdoRlzdThenBlock>
								<div class="mat-cell-value-negative">
									<div class="mat-cell-value-negative">{{element.vlVarSdoRlzd |number:'1.2-2'}}</div>
									<div class="mat-cell-value-negative"><mat-icon class="icon_var_value_down" >arrow_downward</mat-icon>   {{element.vlVarPercSdoRlzd | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlSdoCxHstCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Caixa </th>
						<td mat-cell *matCellDef="let element"> 
							<span>
								{{element.vlSdoCx | number:'1.2-2'}}
							</span>
							<span *ngIf="element.vlVarPercSdoCx != null">
								<span *ngIf="element.vlVarPercSdoCx >= 0; then varPosVlVarPercSdoCxThenBlock else varNegVlVarPercSdoCxThenBlock"></span>
							</span>
							<ng-template #varPosVlVarPercSdoCxThenBlock>
								<div class="mat-cell-value-positive">
									<div class="mat-cell-value-positive">+{{element.vlVarSdoCx |number:'1.2-2'}}</div>
									<div class="mat-cell-value-positive"><mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.vlVarPercSdoCx | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
							<ng-template #varNegVlVarPercSdoCxThenBlock>
								<div class="mat-cell-value-negative">
									<div class="mat-cell-value-negative">{{element.vlVarSdoCx |number:'1.2-2'}}</div>
									<div class="mat-cell-value-negative"><mat-icon class="icon_var_value_down" >arrow_downward</mat-icon>   {{element.vlVarPercSdoCx | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlSdoInvtHstCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Investido </th>
						<td mat-cell *matCellDef="let element"> 
							<span>
								{{element.vlSdoInvt | number:'1.2-2'}}
							</span>
							<mat-icon class="icon_help" 
								[matTooltip]="this.getTooltipToVlSdoInvt( element )"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
							<div *ngIf="element.vlVarPercSdoInvt != null && element.vlVarPercSdoInvt != 0">
								<span *ngIf="element.vlVarPercSdoInvt >= 0; then varPosVlVarPercSdoInvtThenBlock else varNegVlVarPercSdoInvtThenBlock"></span>
							</div>
							<ng-template #varPosVlVarPercSdoInvtThenBlock>
								<div class="mat-cell-value-positive">
									<div class="mat-cell-value-positive">+{{element.vlVarSdoInvt |number:'1.2-2'}}</div>
									<div class="mat-cell-value-positive"><mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.vlVarPercSdoInvt | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
							<ng-template #varNegVlVarPercSdoInvtThenBlock>
								<div class="mat-cell-value-negative">
									<div class="mat-cell-value-negative">{{element.vlVarSdoInvt |number:'1.2-2'}}</div>
									<div class="mat-cell-value-negative"><mat-icon class="icon_var_value_down" >arrow_downward</mat-icon>   {{element.vlVarPercSdoInvt | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlSdoInvtAtlzdHstCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							<div> 
								<div class="multline-title-column-table">Investido</div>
								<div class="multline-title-column-table">(Atualizado)</div>
							</div> 
						</th>
						<td mat-cell *matCellDef="let element"> 
							<span>
								{{element.vlSdoInvtAtlzd | number:'1.2-2'}}
							</span>
							<mat-icon class="icon_help" 
								[matTooltip]="this.getTooltipToVlSdoInvtAtlzd( element )"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
							<div *ngIf="element.vlVarSdoInvtAtlzd != null">
								<span *ngIf="element.vlVarSdoInvtAtlzd >= 0; then varPosVlVarPercSdoInvtAtlzdThenBlock else varNegVlVarPercSdoInvtAtlzdThenBlock"></span>
							</div>
							<ng-template #varPosVlVarPercSdoInvtAtlzdThenBlock>
								<div>
									<div class="mat-cell-value-positive">+{{element.vlVarSdoInvtAtlzd |number:'1.2-2'}}</div> 
									<div class="mat-cell-value-positive"><mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.vlVarPercSdoInvtAtlzd | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
							<ng-template #varNegVlVarPercSdoInvtAtlzdThenBlock>
								<div>
									<div class="mat-cell-value-negative">{{element.vlVarSdoInvtAtlzd |number:'1.2-2'}}</div>
									<div class="mat-cell-value-negative"><mat-icon class="icon_var_value_down" >arrow_downward</mat-icon>   {{element.vlVarPercSdoInvtAtlzd | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlSdoRstdHstCntb">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Resultado </th>
						<td mat-cell *matCellDef="let element"> 
							<span>
								{{element.vlSdoRstd | number:'1.2-2'}}
							</span>
							<mat-icon class="icon_help" 
								[matTooltip]="this.getTooltipToVlSdoRstd( element )"
								[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
							<div *ngIf="element.vlVarPercSdoRstd != null">
								<span *ngIf="element.vlVarPercSdoRstd >= 0; then varPosVlVarPercSdoRstdThenBlock else varNegVlVarPercSdoRstdThenBlock"></span>
							</div>
							<ng-template #varPosVlVarPercSdoRstdThenBlock>
								<div>
									<div class="mat-cell-value-positive">+{{element.vlVarSdoRstd |number:'1.2-2'}}</div>
									<div class="mat-cell-value-positive"><mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>{{element.vlVarPercSdoRstd | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
							<ng-template #varNegVlVarPercSdoRstdThenBlock>
								<div>
									<div class="mat-cell-value-negative">{{element.vlVarSdoRstd |number:'1.2-2'}}</div>
									<div class="mat-cell-value-negative"><mat-icon class="icon_var_value_down" >arrow_downward</mat-icon>{{element.vlVarPercSdoRstd | number:'1.1-1'}}%</div>
								</div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="optionsHstCntb">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options_block">
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_hstCntbGrCrctInvAcaoListDisplayedColumns; sticky: true"></tr>
					<tr mat-row        *matRowDef="let row; columns: _hstCntbGrCrctInvAcaoListDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	#hstCntbGrCrctInvAcaoListPaginator
				                class="mat-paginator-sticky"
								[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this._hstCntbGrCrctInvAcaoListPaginatorPageSize"
								[pageIndex]="this._hstCntbGrCrctInvAcaoListPaginatorPageIndex"
								(page)="onChangeHstCntbGrCrctInvAcaoListPage($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div class="grid_gcia_mvtc_hstCntbMensal_buttons">
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" 
						 [expanded]="false" 
						 (opened)="onOpenedPanelMvtcList()"
						 (closed)="onClosedPanelMvtcList()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Movimentações
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlMvtcList.isShowProgressBar" [mode]="this._controlMvtcList.modeProgressBar" value="this._controlMvtcList.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_mvtc_mvtc">
			<div class="grid_item_gcia_mvtc_mvtc_filter_dtMvtcFrom">
				<mat-form-field>
					<mat-label>De:</mat-label>

					<input matInput 
						   [matDatepicker]="pickerDtMvtcFrom" 
						   formControlName="dtMvtcFrom"
						   (ngModelChange)="onChangeDtMvtcFrom( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerDtMvtcFrom"></mat-datepicker-toggle>
					<mat-datepicker #pickerDtMvtcFrom></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_item_gcia_mvtc_mvtc_filter_dtMvtcEnd">
				<mat-form-field>
					<mat-label>Até:</mat-label>

					<input matInput 
						[matDatepicker]="pickerDtMvtcEnd" 
						formControlName="dtMvtcEnd"
						(ngModelChange)="onChangeDtMvtcEnd( $event )" >
					<mat-datepicker-toggle matSuffix [for]="pickerDtMvtcEnd"></mat-datepicker-toggle>
					<mat-datepicker #pickerDtMvtcEnd></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="grid_item_gcia_mvtc_mvtc_table_progress">
				<ng-container *ngIf="this._controlMvtcList.isShowProgressBar;else mvtcTableRefresh">
					<mat-progress-bar mode="this._controlMvtcList.modeProgressBar" value="this._controlMvtcList.valueProgressBar"></mat-progress-bar>
				</ng-container>
				<ng-template #mvtcTableRefresh>
					<button mat-icon-button color="accent" (click)="this.onClickDownloadMvtcGrCrctInvAcao()">
						<mat-icon matTooltip="Fazer download da lista de movimentações.">download</mat-icon>
					</button>

					<button mat-icon-button color="accent" (click)="this.onRefreshMvtcGrCrctInvAcao()">
						<mat-icon matTooltip="Atualizar lista de movimentações.">refresh</mat-icon>
					</button>
				</ng-template>
			</div>

			<div class="grid_item_gcia_mvtc_mvtc_table_value">
				<table mat-table [dataSource]="_mvtcGrCrctInvAcaoListDataSource" #mvtcGrCrctInvAcaoListSort="matSort" matSort>
					<ng-container matColumnDef="dtRefMvtc" sticky>
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
						<td mat-cell *matCellDef="let element">
							<span [ngClass]="{'text_riscado' : element.inMvtcDesft === true}">
								{{element.dtRefMvtc | date:'dd/MM/yyyy':'+0000'}}
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="tipMvtc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
						<td mat-cell *matCellDef="let element">
							<span *ngIf="element.tsMvtcDesft != null">Estorno de</span> 
							<span>
								{{element.tipMvtc.nmTipMvtcGrCrctInvAcao}}
							</span>
							<span *ngIf="element.inMvtcDesft === true">(Estornada)</span>
							<mat-icon	*ngIf="element.txObsMvtc != null && element.txObsMvtc.length > 0"
										class="icon_help" 
										matTooltip="Observação: 
													{{element.txObsMvtc}}"
										[matTooltipClass]="'multline-tooltip'" >info
							</mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="crctInv">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Ativo </th>
						<td mat-cell *matCellDef="let element"> 
							<a routerLinkActive="active" (click)="onClickDetailTicker( $event, element.crctInv.cdInv )" class="linkInTable">
								<span *ngIf="element.crctInv != null && element.crctInv.tipInv != null && element.crctInv.tipInv.cdTipInv === 4 then nmInvMvtcTesouroDireto else nmInvMvtcDemais"></span>
								<ng-template #nmInvMvtcTesouroDireto>{{element.crctInv.crctInvAcao.nmPregao}}</ng-template>
								<ng-template #nmInvMvtcDemais>{{element.crctInv.nmInv}}</ng-template>
							</a>
						</td>
					</ng-container>

					<ng-container matColumnDef="qtdUnd">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Quantidade </th>
						<td mat-cell *matCellDef="let element">
							<span [ngClass]="{'text_riscado' : element.inMvtcDesft === true}">
								{{element.qtdUndNegc | number:'1.0-9'}}
							</span> 
						</td>
					</ng-container>

					<ng-container matColumnDef="prcUnd">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Preço (R$) </th>
						<td mat-cell *matCellDef="let element">
							<span [ngClass]="{'text_riscado' : element.inMvtcDesft === true}"> 
								{{element.prcUndNegc | number:'1.2-9'}}
							</span>
							<div *ngIf="element.vlTaxaPercRentAaNegc != null"> 
								<span>(Taxa: {{element.vlTaxaPercRentAaNegc | number:'1.2-9'}}%)</span>
								<mat-icon	class="icon_help" 
											matTooltip="Taxa percentual de rentabilidade negociada."
											[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlTtlEncg">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Encargos (R$) </th>
						<td mat-cell *matCellDef="let element"> 
							<span [ngClass]="{'text_riscado' : element.inMvtcDesft === true}">
								{{element.vlTtlEncg | number:'1.2-2'}}
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlTtlMvtc">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Total (R$) </th>
						<td mat-cell *matCellDef="let element">
							<span [ngClass]="{'text_riscado' : element.inMvtcDesft === true}">
								{{element.vlTtlMvtc | number:'1.2-2'}}
							</span> 
						</td>
					</ng-container>

					<ng-container matColumnDef="options">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options_block">
								<button mat-mini-fab
										*ngIf="element.itemGrCrctInvAcao.inItemGrCrctInvAcaoAtivo && this.isGrCrctInvAcaoCompt() === false" 
										[matMenuTriggerFor]="menuAddMvtcToCrctInv" 
										class="column_options_element" 
										color="primary" >
									<mat-icon class="white-icon" matTooltip="Menu de opções da movimentação...">more_vert</mat-icon>
								</button>

								<mat-menu #menuAddMvtcToCrctInv="matMenu">
									<button mat-menu-item 
											*ngFor="let tipMvtc of this._tipMvtcGrCrctInvAcaoList" 
											(click)="this.onAddMvtcGrCrctInvAcaoToCrctInvAndTipMvtc( element.crctInv.cdInv, tipMvtc )">
										<mat-icon [matTooltip]="tipMvtc.txDescrTipMvtcGrCrctInvAcao">{{tipMvtc.nmIcon}}</mat-icon>
										<span>{{tipMvtc.nmTipMvtcGrCrctInvAcao}}</span>
									</button>

									<button mat-menu-item
											*ngIf="element.tipEstMvtc.sglTipEstMvtcGrCrctInvAcao === 'ACEITA' && element.tsMvtcDesft === null && element.inMvtcDesft === false"
											color="primary"
											(click)="this.onCancelMvtcGrCrctInvAcao( element )">
										<mat-icon matTooltip="Cancelar movimentação.">cancel</mat-icon>
										<span>Cancelar movimentação</span>
									</button>
								</mat-menu>
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_mvtcGrCrctInvAcaoListDisplayedColumns; sticky: true"></tr>
					<tr mat-row        *matRowDef="let row; columns: _mvtcGrCrctInvAcaoListDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	#mvtcGrCrctInvAcaoListPaginator
								class="mat-paginator-sticky"
								[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this._mvtcGrCrctInvAcaoListPaginatorPageSize"
								[pageIndex]="this._mvtcGrCrctInvAcaoListPaginatorPageIndex"
								(page)="onChangeMvtcGrCrctInvAcaoListPage($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div class="grid_item_gcia_mvtc_mvtc_buttons">
				<ng-container *ngIf="this._controlTipMvtcGrCrctInvList.isShowProgressBar;else tipMvtcMenu">
					<mat-progress-spinner [mode]="this._controlTipMvtcGrCrctInvList.modeProgressBar" [value]="this._controlTipMvtcGrCrctInvList.valueProgressBar" [diameter]="56"></mat-progress-spinner>
				</ng-container>
				<ng-template #tipMvtcMenu>
					<button mat-fab *ngIf="this.isGrCrctInvAcaoCompt() === false" [matMenuTriggerFor]="menuAddMvtc" class="column_options_element" color="primary" >
					    <mat-icon class="white-icon" matTooltip="Adicionar nova movimentação...">shopping_cart</mat-icon>
					</button>

					<mat-menu #menuAddMvtc="matMenu">
						<div *ngFor="let tipMvtc of this._tipMvtcGrCrctInvAcaoList">
							<button mat-menu-item
									*ngIf="tipMvtc.inNcddCdInv === false"
									(click)="this.onAddMvtcGrCrctInvAcaoToTipMvtc( tipMvtc )">
								<mat-icon [matTooltip]="tipMvtc.txDescrTipMvtcGrCrctInvAcao">{{tipMvtc.nmIcon}}</mat-icon>
								<span>{{tipMvtc.nmTipMvtcGrCrctInvAcao}}</span>
							</button>
						</div>
					</mat-menu>
				</ng-template>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel *ngIf="this.isGrCrctInvAcaoCompt() === false"
						 class="mat-expansion-panel-spacing" 
						 [expanded]="false" 
						 (opened)="onOpenedPanelLoadMvtc()"
						 (closed)="onClosedPanelLoadMvtc()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Inclusão Massificada de Movimentações
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlLoadMvtc.isShowProgressBar" [mode]="this._controlLoadMvtc.modeProgressBar" value="this._controlLoadMvtc.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_mvtc_loadMvtc">
			<div class="grid_item_gcia_mvtc_loadMvtc_caracterSeparadorDecimal">
				<mat-form-field>
					<mat-label>Separador Decimal:</mat-label>
					<input matInput type="text" 
						   matTooltip="Informe o caracter utilizado como separador decimal." 
						   formControlName="caracterSeparadorDecimalSelected" >
				</mat-form-field>
			</div>

			<div class="grid_item_gcia_mvtc_loadMvtc_caracterSeparadorCampo">
				<mat-form-field>
					<mat-label>Separador Entre os Campos:</mat-label>
					<input matInput type="text" 
						   matTooltip="Informe o caracter utilizado como separador entre os campos do arquivo CSV." 
						   formControlName="delimiterSelected" >
				</mat-form-field>
			</div>

			<div class="grid_item_gcia_mvtc_loadMvtc_file">
				<input #csvInput hidden="true" 
					   type="file" 
					   onclick="this.value=null" 
					   (change)="onCsvInputChange( $event )" 
					   accept=".csv"
				/>
				<button mat-flat-button 
				        color="basic" 
						(click)="csvInput.click()">
					<mat-icon class="icon_var_value_up" 
							  matTooltip="Selecione o arquivo CSV que contém a lista de movimentações." >
						file_upload
					</mat-icon>
				</button>
			</div>

			<div class="grid_item_gcia_mvtc_loadMvtc_table_value">
				<table mat-table [dataSource]="_mvtcMassFileListDataSource">
					<ng-container matColumnDef="isMvtcSelected">
						<th mat-header-cell *matHeaderCellDef>  </th>
						<td mat-cell *matCellDef="let element; let i = index;">
							<mat-checkbox (change)="onClickSelectedItemOfLoadMvtc( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ), $event )"
							              [disabled]="isDisabledItemOfLoadMvtc( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ) )"
										  [checked]="isShouldItemOfLoadMvtcChecked( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ) )" >
							</mat-checkbox>
						</td>
					</ng-container>

					<ng-container matColumnDef="dtRefMvtc">
						<th mat-header-cell *matHeaderCellDef> Data </th>
						<td mat-cell *matCellDef="let element">
							<span *ngIf="element.informedValues[0].isValueConverted === false">
								<div>Informado: {{element.informedValues[0].informedValue}}</div>
								<div>Erro: {{element.informedValues[0].exceptionValue}}</div>
							</span>
							<span *ngIf="element.informedValues[0].isValueConverted === true">{{element.mvtcGr.dtRefMvtc | date:'dd/MM/yyyy':'+0000'}}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="tipMvtc">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element">
							<span *ngIf="element.informedValues[1].isValueConverted === false">
								<div>Informado: {{element.informedValues[1].informedValue}}</div>
								<div>Erro: {{element.informedValues[1].exceptionValue}}</div>
							</span>
							<span *ngIf="element.informedValues[1].isValueConverted === true">{{element.mvtcGr.tipMvtc.nmTipMvtcGrCrctInvAcao}}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="crctInv">
						<th mat-header-cell *matHeaderCellDef> Ativo </th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.informedValues[2].isValueConverted === false">
								<div>Informado: {{element.informedValues[2].informedValue}}</div>
								<div>Erro: {{element.informedValues[2].exceptionValue}}</div>
							</span>
							<span *ngIf="element.mvtcGr.crctInv != null && element.informedValues[2].isValueConverted === true">
								<a [routerLink]="" routerLinkActive="active" (click)="onClickDetailNmInv( element.mvtcGr.crctInv.nmInv )" class="linkInTable">
									<span>{{element.mvtcGr.crctInv.nmInv}}</span>
								</a>
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="qtdUnd">
						<th mat-header-cell *matHeaderCellDef> Quantidade </th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.informedValues[3].isValueConverted === false">
								<div>Informado: {{element.informedValues[3].informedValue}}</div>
								<div>Erro: {{element.informedValues[3].exceptionValue}}</div>
							</span>
							<span *ngIf="element.informedValues[3].isValueConverted === true">{{element.mvtcGr.qtdUndNegc | number:'1.0-2'}}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="prcUnd">
						<th mat-header-cell *matHeaderCellDef> Preço (R$) </th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.informedValues[4].isValueConverted === false">
								<div>Informado: {{element.informedValues[4].informedValue}}</div>
								<div>Erro: {{element.informedValues[4].exceptionValue}}</div>
							</span>
							<span *ngIf="element.informedValues[4].isValueConverted === true">{{element.mvtcGr.prcUndNegc | number:'1.2-2'}}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="encg">
						<th mat-header-cell *matHeaderCellDef> Encargos </th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.informedValues[5].isValueConverted === false">
								<div>Informado: {{element.informedValues[5].informedValue}}</div>
								<div>Erro: {{element.informedValues[5].exceptionValue}}</div>
							</span>
							<span *ngIf="element.informedValues[5].isValueConverted === true">{{element.mvtcGr.vlTtlEncg | number:'1.2-2'}}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="vlTtlMvtc">
						<th mat-header-cell *matHeaderCellDef> Valor Total </th>
						<td mat-cell *matCellDef="let element"> 
							<span *ngIf="element.informedValues[6].isValueConverted === false">
								<div>Informado: {{element.informedValues[6].informedValue}}</div>
								<div>Erro: {{element.informedValues[6].exceptionValue}}</div>
							</span>
							<span *ngIf="element.informedValues[6].isValueConverted === true">{{element.mvtcGr.vlTtlMvtc | number:'1.2-2'}}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="options">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element; let i = index;">
							<span class="column_options_block">
								<mat-spinner *ngIf="isRecordingItemLoadMvtc( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ) )" 		diameter="20"></mat-spinner>
								<mat-icon	 *ngIf="isRecordFailedItemLoadMvtc( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ) )" 	matTooltip="{{element.msgLoadMvtc}}" 	[matTooltipClass]="'multline-tooltip'" >error</mat-icon>
								<mat-icon	 *ngIf="isRecordSucessItemLoadMvtc( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ) )" 	matTooltip="{{element.msgLoadMvtc}}"	[matTooltipClass]="'multline-tooltip'" >done</mat-icon>
								<mat-icon	 *ngIf="isRecordCanceledItemLoadMvtc( i + ( mvtcMassFileListPaginator.pageIndex * mvtcMassFileListPaginator.pageSize ) )" 	matTooltip="{{element.msgLoadMvtc}}" 	[matTooltipClass]="'multline-tooltip'" >cancel</mat-icon>
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_mvtcMassFileListDisplayedColumns; sticky: true"></tr>
					<tr mat-row        *matRowDef="let row; columns: _mvtcMassFileListDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	#mvtcMassFileListPaginator
				                class="mat-paginator-sticky"
								[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this._mvtcMassFileListPaginatorPageSize"
								[pageIndex]="this._mvtcMassFileListPaginatorPageIndex"
								(page)="onChangeMvtcMassFileListPage($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div class="grid_item_gcia_mvtc_loadMvtc_buttons">
				<button mat-icon-button class="column_options_element" color="accent" (click)="onClickSaveLoadMvtc()">
					<mat-icon matTooltip="Gravar na base de dados todas as movimentações selecionadas.">save</mat-icon>
				</button>
			</div>
		</div>
	</mat-expansion-panel>
</form>
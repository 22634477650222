import { Injectable                   } from '@angular/core';
import { HttpClient, HttpHeaders      } from '@angular/common/http';

import { User                         } from '../../_models/user';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService               } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class UsrService extends HttpBasicService< User, User >{
  constructor( protected http          : HttpClient    ,
               protected sessionService: SessionService ) {
    super( http, sessionService );
  }

  public addUsr( nmPss: string, nmUsr: string, email: string, pwd: string ) : Promise< User > {
    return super.detailGeneric( '/USR/ADD', { 'nm_pss': nmPss, 'nm_usr': nmUsr, 'email': email, 'pwd': pwd }, true );
  }

  public recoverPassword( nmUsr: string, email: string ): Promise< User > {
    let reqData = {};

    if( nmUsr != null ){
      reqData[ 'nm_usr' ] = nmUsr;
    }

    if( email != null ){
      reqData[ 'email' ] = email;
    }

    return super.detailGeneric( '/USR/RECOVER', reqData, true );
  }

  public registerNewPassword( nmUsr: string, email: string, id: string, newPassword: string ): Promise< User > {
    let reqData = {};

    if( nmUsr != null ){
      reqData[ 'nm_usr_recover' ] = nmUsr;
    }

    if( email != null ){
      reqData[ 'email_recover' ] = email;
    }

    if( id != null ){
      reqData[ 'id_recover' ] = id;
    }

    if( newPassword != null ){
      reqData[ 'pwd' ] = newPassword;
    }

    return super.detailGeneric( '/USR/NEW_PASS', reqData, true );
  }
}

import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { Empr, InfComplEmpr, InfComplEmprByCotc } from 'src/app/_models/empr';
import { SegmEcnm } from 'src/app/_models/setorEcnm';
import { SetorAtvdd } from 'src/app/_models/setorAtvdd';


@Injectable({
  providedIn: 'root'
})
export class IceService extends HttpBasicService< Empr, Empr >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista valores da informacao complementar especificada, da empresa informada.
   */
  listVlByCotc( cdEmpr: number, sglTipInf: string, limit: number ): Promise< InfComplEmprByCotc[] >{
    const reqData = { cd_empr: cdEmpr, sgl_tip_inf: sglTipInf };

    if( limit != null ){
      reqData[ 'limit' ] = limit;
    }

    return super.listGenericX< InfComplEmprByCotc >( '/ICE/LIST_VL', reqData, false );
  }
}

import { Component, OnInit              } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoginComponentModel             } from './login.component.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService          } from '../../_services/authentication/authentication.service';
import { SessionService } from '../../_services/session/session.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { MatSnackBar } from '@angular/material/snack-bar';


/**
 * Baseado no artigo disponivel em: https://jasonwatmore.com/post/2019/06/22/angular-8-jwt-authentication-example-tutorial
 */
export abstract class LoginComponent implements OnInit {
  private model: LoginComponentModel;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor( protected formBuilder: FormBuilder,
               protected route: ActivatedRoute,
               protected router: Router,
               protected sessionService: SessionService,
               protected authenticationService: AuthenticationService,
               protected prmUsrService: PrmUsrService ){
    // redirect to home if already logged in
    if( this.sessionService.currentUserValue ) {
      console.log( 'this.sessionService.currentUserValue is true' );
      this.router.navigate(['/']);
    }
    else{
      console.log( 'this.sessionService.currentUserValue is false' );
    }
  }

  ngOnInit() {
    console.log( 'ngOnInit()...' );

    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams[ 'returnUrl' ] || '/';

    console.log( 'returnUrl: ' + this.returnUrl );

    console.log( 'ngOnInit().' );
  }

  // convenience getter for easy access to form fields
  get f() { 
    return this.loginForm.controls;
  }

  onSubmit() {
    console.log( 'LoginComponent.onSubmit()...' );

    this.submitted = true;

    // stop here if form is invalid
    if( this.loginForm.invalid ){
      console.log( 'loginForm.invalid!' );
      return;
    }

    this.loading = true;

    const user = this.authenticationService.login(this.f.username.value, this.f.password.value);

    user.then( data => {
      window.alert( 'Login realizado com sucesso!' );

      this.prmUsrService.checkServiceOfUpdatePrmUsr( true );

      this.router.navigate( [this.returnUrl] );
    })
    .catch( error =>{
      console.log( 'error: ' + error );
      window.alert( error );
    })
  }
}

<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Documentos Publicados</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<div class="grid_container_ddc_list">
		<div class="grid_item_ddc_list_dtPblccDocDmntCntbFrom">
			<mat-form-field>
				<mat-label>De:</mat-label>

				<input matInput 
					   [matDatepicker]="picker" 
					   formControlName="dtPblccDocDmntCntbFrom"
					   (ngModelChange)="updateDdcListFromDtPblccDocDmntCntb( $event )" >
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div>

		<div class="grid_item_ddc_list_dtPblccDocDmntCntbEnd">
			<mat-form-field>
				<mat-label>Até:</mat-label>

				<input matInput 
					   [matDatepicker]="pickerDtPblccDocDmntCntbEnd" 
					   formControlName="dtPblccDocDmntCntbEnd"
					   (ngModelChange)="updateDdcListEndDtPblccDocDmntCntb( $event )" >
				<mat-datepicker-toggle matSuffix [for]="pickerDtPblccDocDmntCntbEnd"></mat-datepicker-toggle>
				<mat-datepicker #pickerDtPblccDocDmntCntbEnd></mat-datepicker>
			</mat-form-field>
		</div>

		<div class="grid_item_ddc_list_filter">
			<mat-form-field>
				<mat-label>Filtrar Documentos:</mat-label>

				<input matInput type="text" (keyup)="applyFilterDdc( $event )" placeholder="petrobras, itr..." formControlName="nmTicker" >
			</mat-form-field>
		</div>

		<div class="grid_item_ddc_list_mostrarOcultar">
			<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false">
				<mat-expansion-panel-header class="label_title_panel_right">
					<mat-panel-title>
						Mostrar/Ocultar Colunas:
					</mat-panel-title>
				</mat-expansion-panel-header>

				<div class="grid_container_ddc_list_emprList_mostrarOcultar">
					<div class="grid_item_ddc_list_mostrarOcultar_isShowColumnSglDdc label_showColumn">
						<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnSglDdc" >
							Tipo
						</mat-checkbox>
					</div>

					<div class="grid_item_ddc_list_mostrarOcultar_isShowColumnSetorEcnm label_showColumn">
						<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnSetorEcnm" >
							Setor
						</mat-checkbox>
					</div>

					<div class="grid_item_ddc_list_mostrarOcultar_isShowColumnSubSetorEcnm label_showColumn">
						<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnSubSetorEcnm" >
							Subsetor
						</mat-checkbox>
					</div>

					<div class="grid_item_ddc_list_mostrarOcultar_isShowColumnSegmEcnm label_showColumn">
						<mat-checkbox (change)="updateShowColumn($event)" formControlName="isShowColumnSegmEcnm" >
							Segmento
						</mat-checkbox>
					</div>
				</div>
			</mat-expansion-panel>
		</div>

		<div class="grid_item_ddc_list_checkIsOnlyMonitored">
			<mat-checkbox (change)="refresh()" formControlName="isOnlyEmprMonitored" >
				Mostrar apenas ativos monitorados
			</mat-checkbox>
		</div>

		<div class="grid_item_ddc_list_ddcList">
			<table mat-table [dataSource]="dataSourceDdcList" matSort>
				<ng-container matColumnDef="dtPblccDocDmntCntb" sticky>
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Data Publicação </th>
					<td mat-cell *matCellDef="let element">
						{{element.dtPblccDocDmntCntb | date:'dd/MM/yyyy':'+0000'}}
					</td>
				</ng-container>

				<ng-container matColumnDef="sglDocDmntCntb">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
					<td mat-cell *matCellDef="let element"> {{element.sglDocDmntCntb}} </td>
				</ng-container>

				<ng-container matColumnDef="empr">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
					<td mat-cell *matCellDef="let element"> 
						<a [routerLink]="" routerLinkActive="active" (click)="detailEmpr( element.empr.cdEmpr )" class="linkInTable"> 
							<span *ngIf="element.empr.nmAbrvEmpr != null; else elseBlock">
								{{element.empr.nmAbrvEmpr}}
							</span>
							<ng-template #elseBlock>{{element.empr.nmEmpr}}</ng-template>
						</a> 
					</td>
				</ng-container>

				<ng-container matColumnDef="setorEcnm">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Setor </th>
					<td mat-cell *matCellDef="let element"> 
						<a [routerLink]="" routerLinkActive="active" (click)="detailSetorEcnm( element.empr.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm )" class="linkInTable"> 
							<span *ngIf="element.empr.segmEcnm != null">
								{{element.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}}
							</span>
						</a> 
					</td>
				</ng-container>

				<ng-container matColumnDef="subSetorEcnm">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Sub-Setor </th>
					<td mat-cell *matCellDef="let element"> 
						<a [routerLink]="" routerLinkActive="active" (click)="detailSubSetorEcnm( element.empr.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, element.empr.segmEcnm.subSetorEcnm.cdSubSetorEcnm )" class="linkInTable"> 
							<span *ngIf="element.empr.segmEcnm != null">
								{{element.empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm}}
							</span>
						</a> 
					</td>
				</ng-container>

				<ng-container matColumnDef="segmEcnm">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Segmento </th>
					<td mat-cell *matCellDef="let element"> 
						<a [routerLink]="" routerLinkActive="active" (click)="detailSegmEcnm( element.empr.segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm, element.empr.segmEcnm.subSetorEcnm.cdSubSetorEcnm, element.empr.segmEcnm.cdSegmEcnm )" class="linkInTable"> 
							<span *ngIf="element.empr.segmEcnm != null">
								{{element.empr.segmEcnm.nmSegmEcnm}}
							</span>
						</a> 
					</td>
				</ng-container>

				<ng-container matColumnDef="perdRef">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Período Ref. </th>
					<td mat-cell *matCellDef="let element"> 
						<span *ngIf="element.perdcRef != null && element.perdcRef.aaRefPerdc > 0">
							{{element.perdcRef.aaRefPerdc}}
							<span *ngIf="element.perdcRef.nrRefPerdc > 0">
								/ {{element.perdcRef.nrRefPerdc}}
							</span>
							<span *ngIf="element.perdDmntCntbList && element.perdDmntCntbList != null">
								<mat-icon class="icon_help" 
									matTooltip="{{this.getPerdsDmntCntbStringMultline( element.perdDmntCntbList )}}"
									[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</span> 
						</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="varLp">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> &Delta; % Lucro/Prej. </th>
					<td mat-cell *matCellDef="let element"> 
						<span *ngIf="element.perdcRef != null && element.perdcRef.aaRefPerdc > 0">
							<span *ngIf="element.tipInfRstdEmprJoinInfRstdEmpr != null">
								<div *ngIf="element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].getVarPercVlRstdEmpr() > 0; then varPosVlRstdThenBlock else varNegVlRstdThenBlock" ></div>
								<ng-template #varPosVlRstdThenBlock>
									<span class="mat-cell-value-positive">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_up" >arrow_upward</mat-icon>   {{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].getVarPercVlRstdEmpr() | number:'1.0-0'}}%
									</span>
								</ng-template>
								<ng-template #varNegVlRstdThenBlock>
									<span class="mat-cell-value-negative">
										&nbsp;&nbsp; <mat-icon class="icon_var_value_down" >arrow_downward</mat-icon> {{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].getVarPercVlRstdEmpr()*(-1) | number:'1.0-0'}}%
									</span>
								</ng-template>
								<mat-icon class="icon_help" 
									matTooltip="Lucro/Prejuízo nos períodos ({{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].perdc.nmPerdc}})
												{{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].aaRefPerdc}}/{{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].nrRefPerdc}}: R$ {{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[0].vlRstdEmpr | shortNumber:'1.2-2'}}
												{{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[1].aaRefPerdc}}/{{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[1].nrRefPerdc}}: R$ {{element.tipInfRstdEmprJoinInfRstdEmpr[0].infRstdEmprArray[1].vlRstdEmpr | shortNumber:'1.2-2'}}"
									[matTooltipClass]="'multline-tooltip'" >info
								</mat-icon>
							</span>
						</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="options">
				<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #appMenu="matMenu">
							<button mat-menu-item (click)="detailDdc( element.cdEmpr, element.sglDocDmntCntb, element.dtPblccDocDmntCntb, element.nrSeqDocDmntCntb )" type="submit">Detalhar Documento</button>
						</mat-menu>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="this._ddcColumnsDisplayed"></tr>
				<tr mat-row *matRowDef="let row; columns: this._ddcColumnsDisplayed;"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
		</div>

		<div *ngIf="isDdcListEmpty == true" class="grid_item_ddc_list_ddcList_empty">
			<label class="label_list_empty">Não há documentos publicados a partir da data especificada.</label>
		</div>
	</div>
</form>


import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { Empr, InfComplEmpr } from 'src/app/_models/empr';
import { SegmEcnm } from 'src/app/_models/setorEcnm';
import { SetorAtvdd } from 'src/app/_models/setorAtvdd';


@Injectable({
  providedIn: 'root'
})
export class EmprService extends HttpBasicService< Empr, Empr >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Detalha empresa.
   */
  detail( cdEmpr: number ): Promise< Empr >{
    return super.detailGeneric( '/EMPR/DET', { cd_empr: cdEmpr, 
                                               return_lp : true, 
                                               return_ev : true, 
                                               return_mc : true, 
                                               return_vpa: true, 
                                               return_la : true, 
                                               return_roa: true, 
                                               return_roe: true }, false );
  }

  /**
   * Lista informacoes complementares da empresa.
   */
  list( cdEmpr: number, segmEcnm: SegmEcnm, setorAtvdd: SetorAtvdd, inPermtAtlzCotc: Boolean ): Promise< Empr[] >{
    let reqData = {};

    if( cdEmpr != null ){
      reqData[ 'cd_empr' ] = cdEmpr;
    }

    if( segmEcnm != null ){
      if( segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm != null ){
        reqData[ 'cd_setor_ecnm' ] = segmEcnm.subSetorEcnm.setorEcnm.cdSetorEcnm;
      }

      if( segmEcnm.subSetorEcnm.cdSubSetorEcnm != null ){
        reqData[ 'cd_sub_setor_ecnm' ] = segmEcnm.subSetorEcnm.cdSubSetorEcnm;
      }

      if( segmEcnm.cdSegmEcnm != null ){
        reqData[ 'cd_segm_ecnm' ] = segmEcnm.cdSegmEcnm;
      }
    }

    if( setorAtvdd != null ){
      if( setorAtvdd.cdSetorAtvdd != null ){
        reqData[ 'cd_setor_atvdd' ] = setorAtvdd.cdSetorAtvdd;
      }
    }

    if( inPermtAtlzCotc != null ){
      reqData[ 'in_permt_atlz_cotc' ] = inPermtAtlzCotc;
    }

    return super.listGeneric( '/EMPR/LIST', reqData, false );
  }

  /**
   * Lista informacoes complementares contabeis da empresa.
   */
  listInfCompl( cdEmpr: number ): Promise< InfComplEmpr[] >{
    return super.listGenericX< InfComplEmpr >( '/EMPR/LIST_INF_COMPL', { cd_empr: cdEmpr }, false );
  }

  /**
   * Lista informacoes complementares da empresa.
   */
  listInfComplEmpr( cdEmpr: number ): Promise< InfComplEmpr[] >{
    return super.listGenericX< InfComplEmpr >( '/EMPR/LICE', { cd_empr: cdEmpr }, false );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpBasicService } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { HstCotcAcao } from 'src/app/_models/cotc';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CotcService extends HttpBasicService< HstCotcAcao, HstCotcAcao >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }


  /**
   * Detalha uma cotacao.
   */
  detail( cdInv: number, dtCotc: Date ) : Promise< HstCotcAcao > {
    const dtSgbdFormat = this.datePipe.transform( dtCotc, 'yyyy-MM-dd' );

    return super.detailGeneric( '/COTC/DET', { cd_inv: cdInv, dt_cotc: dtSgbdFormat }, false );
  }

  /**
   * Lista cotacoes.
   */
  list( cdInv: number ): Promise< HstCotcAcao[] >{
    return super.listGeneric( '/COTC/LIST', { cd_inv: cdInv }, false );
  }

  /**
   * Lista ultimas cotacoes.
   */
  listLatest( cdInv: number, qtdLimit: number ): Promise< HstCotcAcao[] >{
    return super.listGeneric( '/COTC/LIST', { cd_inv: cdInv, qtd_limit: qtdLimit }, false );
  }

  /**
   * Lista cotacoes comprendidas entre as datas especificadas.
   */
  listBetweenDates( cdInv: number, dtCotcStart: Date, dtCotcEnd: Date ): Promise< HstCotcAcao[] >{
    return super.listGeneric( '/COTC/LIST', { cd_inv: cdInv, dt_cotc_start: dtCotcStart, dt_cotc_end: dtCotcEnd }, false );
  }

  /**
   * Lista cotacoes comprendidas entre as datas especificadas, até a quantidade limite de registros especificada.
   * @param orderBy: Ordem dos dados a serem retornados. Informar 'ASC' ou 'DESC' (default).
   */
  listBetweenDatesWithLimit( cdInv: number, dtCotcStart: Date, dtCotcEnd: Date, qtdLimit: number, orderBy: string ): Promise< HstCotcAcao[] >{
    //console.log( 'listBetweenDatesWithLimit( ' + cdInv + ', ' + dtCotcStart + ', ' + dtCotcEnd + ', ' + qtdLimit + ', ' + orderBy + ' )...' );
    let reqData = { cd_inv        : cdInv };

    if( dtCotcStart != null ){
      try{
        const dtSgbdFormat = this.datePipe.transform( dtCotcStart, 'yyyy-MM-dd' );

        reqData[ 'dt_cotc_start' ] = dtSgbdFormat;
      }
      catch( error ){
      }
    }

    if( dtCotcEnd != null ){
      const dtSgbdFormat = this.datePipe.transform( dtCotcEnd, 'yyyy-MM-dd' );

      reqData[ 'dt_cotc_end' ] = dtSgbdFormat;
    }

    if( qtdLimit && qtdLimit != null ){
      reqData[ 'qtd_limit' ] = qtdLimit;
    }

    if( orderBy && orderBy != null ){
      reqData[ 'orderBy' ] = orderBy;
    }

    const p = super.listGeneric( '/COTC/LIST', reqData, false );

    //console.log( 'listBetweenDatesWithLimit().' );

    return p;
  }
}
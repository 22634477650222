import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root' 
})
export class AplicationStateService{
  private isSmallScreen: boolean;

  constructor( private titleService: Title ) {
    //console.log( 'AplicationStateService()...' );
    //console.log( 'window: ' + window );

    if( window.innerWidth < 960 ){
      this.isSmallScreen = true;
    }
    else{
      this.isSmallScreen = false;
    }

    //console.log( 'small screen? ' + this.isSmallScreen );
    //console.log( 'AplicationStateService().' );
  }

  /**
   * Retorna true caso a tela do device seja pequena.
   */
  public isMobileResolution(): boolean {
    //console.log( 'AplicationStateService.isMobileResolution()...' );
    //console.log( 'isSmallScreen? ' + this.isSmallScreen );
    //console.log( 'AplicationStateService.isMobileResolution().' );

    return this.isSmallScreen;
  }

  public resetTitle(): void{
    this.setTitle( null );
  }

  public setTitle( titleStr: string ): void{
    const nmSite = environment.nmSite;

    const titleSite = titleStr != null ? ( titleStr + " | " + nmSite ) : nmSite;

    this.titleService.setTitle( titleSite );
  }
}

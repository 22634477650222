import { Component, ViewEncapsulation     } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TickerDialogSelectComponent  } from '../ticker-dialog-select.component';

import { AplicationStateService       } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService        } from '@services/authentication/authentication.service';
import { PrmUsrService                } from '@services/prmUsr/prmUsr.service';
import { InvService                   } from '@services/inv/inv.service';


@Component({
  selector: 'app-ticker-dialog-select-mobile',
  templateUrl: '../desktop/ticker-dialog-select-desktop.component.html',
  styleUrls: ['./ticker-dialog-select-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TickerDialogSelectMobileComponent extends TickerDialogSelectComponent {
  constructor( public dialogRef: MatDialogRef< TickerDialogSelectMobileComponent >,
               protected formBuilder           : FormBuilder            ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService               ){
    super( dialogRef, formBuilder, aplicationStateService, authenticationService, prmUsrService, invService );
  }

  ngOnInit() {
    console.log( 'TickerDialogSelectDesktopComponent.ngOnInit()...' );
    super.ngOnInit();

    //console.log( 'TickerDialogSelectDesktopComponent.ngOnInit().' );
  }

}

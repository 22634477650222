import { CrctInv 				} from 'src/app/_models/inv';
import { InfCrctInvInterface 	} from 'src/app/_models/infCrctInv/infCrctInv';
import { CotcService 			} from 'src/app/_services/cotc/cotc.service';
import { IndTcnService 			} from 'src/app/_services/indTcn/indTcn.service';
import { TipTndc, ItemGrupoCrctInvAcao } from '@models/grCrctInv/grCrctInv';

export class ControlLifeCycleCrctInv{
	//O ticker.
	private itemGrupoCrctInvAcao: ItemGrupoCrctInvAcao;

	//Atributo utilizado pelo Manager para saber se deve ou não tratar o ticker.
	private isEnabled	  : boolean;

	//As informacoes vinculadas a esse ticker.
	private infCrctInvList: InfCrctInvInterface< any >[];

	private tipTndcCrctInv: TipTndc;

	constructor( itemGrupoCrctInvAcao: ItemGrupoCrctInvAcao      ,
				 cotcService  : CotcService  ,
				 indTcnService: IndTcnService ){
		this.itemGrupoCrctInvAcao = itemGrupoCrctInvAcao;
		this.isEnabled = true;
		this.infCrctInvList = [];
	}

	public getItemGrupoCrctInvAcao(): ItemGrupoCrctInvAcao{
		return this.itemGrupoCrctInvAcao;
	}

	public getCrctInv(): CrctInv{
		return this.itemGrupoCrctInvAcao.crctInv;
	}

	public isControlEnabled(): boolean{
		return this.isEnabled === true;
	}

	public setEnable( isState: boolean ): void{
		this.isEnabled = isState;
	}

	public isEqual( otherControl: ControlLifeCycleCrctInv ): boolean{
		return otherControl != null && otherControl.getCrctInv() != null && this.getCrctInv() != null && otherControl.getCrctInv().cdInv === this.getCrctInv().cdInv;
	}

	public getInfCrctInvList(): InfCrctInvInterface< any >[]{
		return this.infCrctInvList;
	}

	public getQtInfCrctInv(): number{
		return this.infCrctInvList.length;
	}

	public addInfCrctInv( infCrctInv: InfCrctInvInterface< any[] > ){
		//console.log( 'addInfCrctInv ' + infCrctInv.getNmInfCrctInv() + ' no ticker ' + this.getCrctInv().cdInv + '...' );
		this.infCrctInvList.push( infCrctInv );
	}

	public removeInfCrctInv( infCrctInv: InfCrctInvInterface< any[] > ){
		//Obtem a quantidade de informacoes.
		const qtInfCrctInv = this.infCrctInvList.length;

		for( let indexInfCrctInv = (qtInfCrctInv-1); indexInfCrctInv >= 0; indexInfCrctInv-- ){
			const infCrctInvIterate = this.infCrctInvList[ indexInfCrctInv ];

			if( infCrctInvIterate.isEqual( infCrctInv ) === true ){
				this.infCrctInvList.splice( indexInfCrctInv, 1 );
				break;
			}
		}
	}

	public getTipTndcCrctInv(): TipTndc{
		return this.itemGrupoCrctInvAcao.tipTndc;
	}

	public setDtFromValues( dt: Date ): void{
		this.infCrctInvList.forEach( infCrctInv => {
			infCrctInv.setDtFromValues( dt );
		} );
	}

	public setDtEndValues( dt: Date ): void{
		this.infCrctInvList.forEach( infCrctInv => {
			infCrctInv.setDtEndValues( dt );
		} );
	}

	public setQtLimitValues( qtLimit: number ): void{
		this.infCrctInvList.forEach( infCrctInv => {
			infCrctInv.setQtLimitValues( qtLimit );
		} );
	}

	public resetAllPointerToMin(): void{
		this.infCrctInvList.forEach( infCrctInv => {
			infCrctInv.resetPointerToMin();
		} );
	}

	public resetAllPointerToMax(): void{
		this.infCrctInvList.forEach( infCrctInv => {
			infCrctInv.resetPointerToMax();
		} );
	}

	/**
	 * Itera por todas as informacoes, verificando cada informacao que o ponteiro
	 * está apontando, retornando a menor data.
	 */
	public getMinDateOfAllPointers(): Date{
		let minDate: Date = null;

		this.infCrctInvList.forEach( infCrctInv => {
			const dtIterate: Date = infCrctInv.getDateRefOfValueOfIndex( infCrctInv.getIndexOfPointer() );

			//Compara as datas.
			if( ( dtIterate != null ) && ( ( minDate === null ) || ( dtIterate.getTime() < minDate.getTime() ) ) ){
				minDate = dtIterate;
			}
		} );

		return minDate;
	}

	nextValuesOfPointerIfEqualDateRef( dateRef: Date ): InfCrctInvInterface< any[] >[]{
		let values = [];

		this.infCrctInvList.forEach( infCrctInv => {
			const value = infCrctInv.nextValueOfPointerIfEqualDateRef( dateRef );

			if( value != null ){
				values.push( value );
			}
		} );

		return values;
	}

	public prevValuesOfPointerIfEqualDateRef( dateRef: Date ): InfCrctInvInterface< any[] >[]{
		let values = [];

		this.infCrctInvList.forEach( infCrctInv => {
			const value = infCrctInv.prevValueOfPointerIfEqualDateRef( dateRef );

			if( value != null ){
				values.push( value );
			}
		} );

		return values;
	}

	/**
	 * Solicita uma atualizacao dos valores de cada informacao.
	 */
	public refreshValues( infCrctInvSpecificList?: InfCrctInvInterface< any >[] ): Promise< any >{
		const qtInfCrctInv = this.infCrctInvList != null ? this.infCrctInvList.length : 0;

		const _this = this;

		return new Promise( function( resolve, reject ){
			try{
				let promises  : Promise< any >[] = [];

				const qtInfCrctInvSpecific = infCrctInvSpecificList && infCrctInvSpecificList != null ? infCrctInvSpecificList.length : 0;

				//Solicita a atualizacao dos valores de cada umas das informacoes.
				for( let indexInfCrctInv = 0; indexInfCrctInv < qtInfCrctInv; indexInfCrctInv++ ){
					const infCrctInv = _this.infCrctInvList[ indexInfCrctInv ];

					let isRefresh = ( qtInfCrctInvSpecific === 0 );

					for( let indexInfCrctInvSpecific = 0; indexInfCrctInvSpecific < qtInfCrctInvSpecific; indexInfCrctInvSpecific++ ){
						const infCrctInvSpecificIterate = infCrctInvSpecificList[ indexInfCrctInvSpecific ];

						if( infCrctInv.isEqual( infCrctInvSpecificIterate ) ){
							isRefresh = true;
							break;
						}
					}

					if( isRefresh === true ){
						const promiseIterate = infCrctInv.refreshValues();

						promises.push( promiseIterate );
					}
				}

				//Aguarda a finalizacao de todas requisicoes.
				Promise.all( promises )
					.then( response => {
						resolve();
					} );
			}
			catch( error ){
				console.log( 'Exception disparada em ControlLifeCycleCrctInv.refreshValues(): ' + error );
				reject( error );
			}
		});
	}
}

export enum MomentLifeCycleCrctInv{
	//Aguardando buscar os dados.
	WAITING_SEARCHING_DATA = 1,

	//Foi iniciada a busca pelos dados.
	SEARCHING_DATA = 2,

	//Dados foram retornados pelo web service e armazenados.
	STORED_DATA = 3,
}

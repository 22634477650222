import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router                 } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';
import { EmprDetailComponent } from '../empr-detail.component';
import { MatDialog } from '@angular/material/dialog';

import { AplicationStateService } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from '../../../../_services/authentication/authentication.service';
import { InvService                  } from '../../../../_services/inv/inv.service';
import { CotcService } from 'src/app/_services/cotc/cotc.service';
import { EmprService } from 'src/app/_services/empr/empr.service';
import { DdcService } from 'src/app/_services/ddc/ddc.service';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { IceService } from 'src/app/_services/ice/ice.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-empr-detail-mobile',
  templateUrl: '../desktop/empr-detail-desktop.component.html',
  styleUrls: ['./empr-detail-mobile.component.css']
})
export class EmprDetailMobileComponent extends EmprDetailComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar             : MatSnackBar           ,
               protected invService            : InvService            ,
               protected ddcService            : DdcService            ,
               protected emprService           : EmprService,
               protected rstdEmprService       : RstdEmprService       ,
               protected cotcService           : CotcService,
               protected iceService            : IceService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, aplicationStateService, authenticationService, snackBar, invService, ddcService, emprService, rstdEmprService, cotcService, iceService );
  }
}

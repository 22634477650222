import { Injectable                            } from '@angular/core';
import { HttpClient                            } from '@angular/common/http';
import { HttpBasicService                      } from '../http-basic.service';
import { SessionService                        } from '../session/session.service';
import { User } from 'src/app/_models/user';
import { GrupoCrctInvAcao, ItemGrupoCrctInvAcao, HistoricoContabilGrupoCrctInvAcao, UsrComptGrCrctInvAcao } from 'src/app/_models/grCrctInv/grCrctInv';
import { CrctInv } from 'src/app/_models/inv';
import { isDefined } from '@angular/compiler/src/util';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GrCrctInvService extends HttpBasicService< GrupoCrctInvAcao, GrupoCrctInvAcao >{
  constructor( protected http: HttpClient,
               public sessionService: SessionService,
               protected datePipe   : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Detalha grupo vinculado ao usuário.
   */
  public detailGrupo( cdGrCrctInv: number, cdUsrCompt?: number ): Promise< GrupoCrctInvAcao >{
    console.log( 'detailGrupo( ' + cdGrCrctInv + ' )...' );

    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'       ] = cdGrCrctInv;
    reqData[ 'in_gr_crct_inv_acao_ativo' ] = true;
    reqData[ 'return_qt_assist'          ] = true;

    if( cdUsrCompt && cdUsrCompt != null && cdUsrCompt > 0 ){
      reqData[ 'cd_usr_compt' ] = cdUsrCompt;
    }

    return super.detailGenericX< GrupoCrctInvAcao >( '/GR_INV/DET_GR', reqData, true );
  }

  /**
   * Adiciona novo ativo ao grupo.
   */
  public addGrupo( cdUsr: number, nmGrCrctInvAcao: string, txDescrGrCrctInvAcao: string ): Promise< GrupoCrctInvAcao >{
    let reqData = {};

    reqData[ 'cd_usr' ] = cdUsr;
    reqData[ 'nm_gr_crct_inv_acao' ] = nmGrCrctInvAcao;
    reqData[ 'tx_descr_gr_crct_inv_acao' ] = txDescrGrCrctInvAcao;

    return super.detailGenericX< GrupoCrctInvAcao >( '/GR_INV/ADD_GR', reqData, false );
  }

  /**
   * Altera informacoes do grupo.
   */
  public setGrupo( cdGrCrctInv: number, nmGrCrctInvAcao: string, txDescrGrCrctInvAcao: string, inGrCrctInvAcaoAtivo: boolean ): Promise< GrupoCrctInvAcao >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao' ] = cdGrCrctInv;

    if( nmGrCrctInvAcao && nmGrCrctInvAcao != null ){
      reqData[ 'nm_gr_crct_inv_acao' ] = nmGrCrctInvAcao;
    }

    if( txDescrGrCrctInvAcao && txDescrGrCrctInvAcao != null ){
      reqData[ 'tx_descr_gr_crct_inv_acao' ] = txDescrGrCrctInvAcao;
    }

    if( inGrCrctInvAcaoAtivo != null ){
      reqData[ 'in_gr_crct_inv_acao_ativo' ] = inGrCrctInvAcaoAtivo;
    }

    return super.detailGenericX< GrupoCrctInvAcao >( '/GR_INV/SET_GR', reqData, true );
  }

  /**
   * Lista grupos vinculados ao usuário logado.
   */
  public listGrupos( isInGrCrctInvAcaoAtivo: boolean, isReturnVlCptlTtl: boolean, isReturnVlResgTtl: boolean, 
                     isReturnVlSdoInvtAtlzd: boolean, isReturnDtRefMvtcLess: boolean, isReturnDtRefMvtcGreater: boolean, isReturnDtRefMvtcBefore: boolean ): Promise< GrupoCrctInvAcao[] >{
    let reqData = {};

    reqData[ 'in_gr_crct_inv_acao_ativo'  ] = isInGrCrctInvAcaoAtivo === undefined ? true : isInGrCrctInvAcaoAtivo;
    reqData[ 'return_vl_cptl_ttl'         ] = isReturnVlCptlTtl;
    reqData[ 'return_vl_resg_ttl'         ] = isReturnVlResgTtl;
    reqData[ 'return_vl_sdo_invt_atlzd'   ] = isReturnVlSdoInvtAtlzd;
    reqData[ 'return_dt_ref_mvtc_less'    ] = isReturnDtRefMvtcLess;
    reqData[ 'return_dt_ref_mvtc_greater' ] = isReturnDtRefMvtcGreater;
    reqData[ 'return_dt_ref_mvtc_before'  ] = isReturnDtRefMvtcBefore;

    return super.listGenericX< GrupoCrctInvAcao >( '/GR_INV/LIST_GR', reqData, true );
  }

  /**
   * Exclui o grupo do usuario.
   */
  public removeGrupo( cdGrCrctInvAcao: number ): Promise< GrupoCrctInvAcao >{
    return this.setGrupo( cdGrCrctInvAcao, null, null, false );
  }

  /**
   * Reativa o grupo do usuario.
   */
  public reactivateGrupo( cdGrCrctInvAcao: number ): Promise< GrupoCrctInvAcao >{
    return this.setGrupo( cdGrCrctInvAcao, null, null, true );
  }

  /**
   * Lista itens vinculados ao grupo especificado.
   */
  public listItensGrupo( cdGrCrctInvAcao: number, cdInv: number, isInItemGrCrctInvAcaoAtivo: boolean, cdUsrCompt: number, 
                         isReturnCotc: boolean, isReturnQtdUndNegcZero: boolean,
                         isReturnVlCptlTtl: boolean, isReturnVlResgTtl: boolean, isReturnVlSdoInvtAtlzd: boolean, isReturnTndc: boolean ): Promise< ItemGrupoCrctInvAcao[] >{
    //console.log( 'listItensGrupo( ' + cdGrCrctInvAcao + '/' + cdInv + '/' + isInItemGrCrctInvAcaoAtivo + ' )...' );

    const dtNow: Date = new Date();
    const dtNowSgbdFormat = this.datePipe.transform( dtNow, 'yyyy-MM-dd', '+00:00' );

    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'            ] = cdGrCrctInvAcao;

    if( typeof isInItemGrCrctInvAcaoAtivo !== 'undefined' && isInItemGrCrctInvAcaoAtivo !== null ){
      reqData[ 'in_item_gr_crct_inv_acao_ativo' ] = isInItemGrCrctInvAcaoAtivo;
    }

    if( cdInv && cdInv != null && cdInv > 0 ){
      reqData[ 'cd_inv' ] = cdInv;
    }

    if( cdUsrCompt && cdUsrCompt != null && cdUsrCompt > 0 ){
      reqData[ 'cd_usr_compt' ] = cdUsrCompt;
    }

    reqData[ 'dt_ref_mvtc_to'           ] = dtNowSgbdFormat;

    reqData[ 'return_cotc'              ] = isReturnCotc;
    reqData[ 'return_qtd_und_negc_zero' ] = isReturnQtdUndNegcZero;
    reqData[ 'return_vl_cptl_ttl'       ] = isReturnVlCptlTtl;
    reqData[ 'return_vl_resg_ttl'       ] = isReturnVlResgTtl;
    reqData[ 'return_vl_sdo_invt_atlzd' ] = isReturnVlSdoInvtAtlzd;
    reqData[ 'return_tndc' ] = isReturnTndc;

    return super.listGenericX< ItemGrupoCrctInvAcao >( '/GR_INV/LIST_ITEM', reqData, true );
  }

  /**
   * Adiciona novo ativo ao grupo.
   */
  public addItem( cdGrCrctInv: number, cdInv: number ): Promise< void >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao' ] = cdGrCrctInv;
    reqData[ 'cd_inv' ] = cdInv;

    return super.detailGenericX< void >( '/GR_INV/ADD_ITEM', reqData, true );
  }

  /**
   * Remove ativo do grupo.
   */
  public removeItem( cdGrCrctInv: number, cdInv: number ): Promise< void >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao' ] = cdGrCrctInv;
    reqData[ 'cd_inv' ] = cdInv;

    return super.detailGenericX< void >( '/GR_INV/REMOVE_ITEM', reqData, true );
  }

  /**
   * Altera informacoes do item.
   */
  public setItemGr( cdGrCrctInv: number, cdInv: number, inItemGrCrctInvAcaoAtivo: boolean ): Promise< ItemGrupoCrctInvAcao >{
    let p;

    if( inItemGrCrctInvAcaoAtivo === true ){
      p = this.addItem( cdGrCrctInv, cdInv );
    }
    else{
      p = this.removeItem( cdGrCrctInv, cdInv );
    }

    return p;
  }

  /**
   * Reativa o item do grupo.
   */
  public reactivateItemGr( cdGrCrctInvAcao: number, cdInv: number ): Promise< ItemGrupoCrctInvAcao >{
    return this.setItemGr( cdGrCrctInvAcao, cdInv, true );
  }

  /**
   * Lista historico contabil do grupo especificado.
   */
  public listHstCntbGrCrctInvAcao( cdGrCrctInvAcao: number, dtRefMvtcFrom: Date, dtRefMvtcTo: Date, cdUsrCompt?: number ): Promise< HistoricoContabilGrupoCrctInvAcao[] >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'            ] = cdGrCrctInvAcao;

    if( dtRefMvtcFrom != null ){
      const aaRefMvtcFrom = dtRefMvtcFrom.getFullYear();
      const mmRefMvtcFrom = dtRefMvtcFrom.getMonth() + 1;

      reqData[ 'aa_ref_mvtc_from'             ] = aaRefMvtcFrom;
      reqData[ 'mm_ref_mvtc_from'             ] = mmRefMvtcFrom;
    }

    if( dtRefMvtcTo != null ){
      const aaRefMvtcTo = dtRefMvtcTo.getFullYear();
      const mmRefMvtcTo = dtRefMvtcTo.getMonth() + 1;

      reqData[ 'aa_ref_mvtc_to'               ] = aaRefMvtcTo;
      reqData[ 'mm_ref_mvtc_to'               ] = mmRefMvtcTo;
    }

    if( cdUsrCompt != null ){
      reqData[ 'cd_usr_compt'                 ] = cdUsrCompt;
    }

    return super.listGenericX< HistoricoContabilGrupoCrctInvAcao >( '/GR_INV/LHCMG', reqData, true );
  }


  /**
   * Lista grupos compartilhados com o usuário.
   */
  public listGruposCompt(): Promise< GrupoCrctInvAcao[] >{
    let reqData = { 'cd_usr_compt': this.sessionService.currentUserValue.id };

    return super.listGenericX< GrupoCrctInvAcao >( '/GR_INV/LIST_GR', reqData, true );
  }


  /**
   * Lista os usuarios com os quais a Carteira esta sendo compartilhada..
   */
  public listUsrComptGrCrctInvAcao( cdGrCrctInvAcao: number ): Promise< UsrComptGrCrctInvAcao[] >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao'            ] = cdGrCrctInvAcao;

    return super.listGenericX< UsrComptGrCrctInvAcao >( '/CGCIA/LU', reqData, true );
  }

  /**
   * Adiciona compartilhamento da Carteira com o usuario especificado.
   */
  public addUsrComptGrCrctInvAcao( cdGrCrctInv: number, nmUsr: string ): Promise< void >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao' ] = cdGrCrctInv;
    reqData[ 'nm_usr_compt' ] = nmUsr;

    return super.detailGenericX< void >( '/CGCIA/ADD_COMPT', reqData, true );
  }

  /**
   * Remove o compartilhamento da Carteira com o usuario especificado.
   */
  public removeUsrComptGrCrctInvAcao( cdGrCrctInv: number, cdUsr: number ): Promise< void >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao' ] = cdGrCrctInv;
    reqData[ 'cd_usr_compt' ] = cdUsr;

    return super.detailGenericX< void >( '/CGCIA/REMOVE_COMPT', reqData, true );
  }

  public setTndcCrctInvAcao( cdGrCrctInv: number, cdInv: number, sglTipTndc: string ): Promise< void >{
    let reqData = {};

    reqData[ 'cd_gr_crct_inv_acao' ] = cdGrCrctInv;
    reqData[ 'cd_inv' ] = cdInv;
    reqData[ 'sgl_tip_tndc' ] = sglTipTndc;

    return super.detailGenericX< void >( '/TGCIA/ADD', reqData, true );
  }
}
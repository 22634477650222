import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router                 } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SetorDetailComponent } from '../setor-detail.component';

import { AplicationStateService } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '@services/authentication/authentication.service';
import { EmprService            } from '@services/empr/empr.service';
import { SetorService           } from '@services/setor/setor.service';
import { SetorAtvddService      } from '@services/setor-atvdd/setor-atvdd.service';
import { InvService             } from '@services/inv/inv.service';
import { CotcService            } from '@services/cotc/cotc.service';
import { IceService             } from '@services/ice/ice.service';
import { IndTcnService          } from '@services/indTcn/indTcn.service';
import { PdcService             } from '@services/pdc/pdc.service';
import { RstdEmprService        } from '@services/rstdEmpr/rstdEmpr.service';

@Component({
  selector: 'app-setor-detail-mobile',
  templateUrl: '../desktop/setor-detail-desktop.component.html',
  styleUrls: ['./setor-detail-mobile.component.css']
})
export class SetorDetailMobileComponent extends SetorDetailComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected setorService          : SetorService          ,
               protected setorAtvddService     : SetorAtvddService     ,
               protected emprService           : EmprService,
               protected invService            : InvService,
               protected cotcService           : CotcService,
               protected iceService            : IceService,
               protected indTcnService         : IndTcnService        ,
               protected rstdEmprService       : RstdEmprService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, aplicationStateService, authenticationService, snackBar, setorService, setorAtvddService, emprService, invService, cotcService, iceService, indTcnService, rstdEmprService );
  }
}

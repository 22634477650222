<mat-toolbar class="app_toolbar_header" color="primary">
	<!-- ================================= HEADER ================================= -->

  <!-- Telas grandes -->
	<div fxShow="true" fxHide.lt-md="true">
		<app-header-desktop class="app_header_desktop"></app-header-desktop>
	</div>

  <!-- Telas pequenas -->
	<div fxShow="true" fxHide.gt-sm="true" class="app_header">
		<div class="app_header_menu">
			<a (click)="sidenav.toggle()">
				<mat-icon>menu</mat-icon>
			</a>
		</div>
		
		<app-header-mobile></app-header-mobile>
	</div>
</mat-toolbar>


<mat-sidenav-container class="app_container">
  <!-- Menu para telas pequenas. -->
  <mat-sidenav #sidenav fxLayout="column" >
    <div class="app_menu_small_screen" fxLayout="column">
      <a mat-button routerLink="/home"         			         routerLinkActive="active" class="header_menu_link"> <mat-icon>home</mat-icon> </a>
    </div>
    <div *ngIf="sessionService.isUserLogged()" class="app_menu_small_screen" fxLayout="column">
      <a mat-button routerLink="/perfil"         			       routerLinkActive="active" class="header_menu_link"> <mat-icon>account_box</mat-icon> </a>
    </div>
    <div *ngIf="sessionService.isUserLogged()" class="app_menu_small_screen" fxLayout="column">
      <a mat-button routerLink="/logout"         			       routerLinkActive="active" class="header_menu_link"> <mat-icon>exit_to_app</mat-icon> </a>
    </div>
  </mat-sidenav>

  <!-- Conteudo da tela -->
  <mat-sidenav-content class="app_sidenav_content">

		<div [ngClass.gt-sm]="'grid_container_desktop'" [ngClass.lt-md]="'grid_container_mobile'">
			<div [ngClass.gt-sm]="'grid_item_desktop_content_left'" [ngClass.lt-md]="'grid_item_mobile_content_left'" ></div>

			<!-- ================================= CONTENT ================================= -->
			<div [ngClass.gt-sm]="'grid_item_desktop_content_center'" [ngClass.lt-md]="'grid_item_mobile_content_center'">
				<router-outlet></router-outlet>
			</div>

			<div [ngClass.gt-sm]="'grid_item_desktop_content_right'" [ngClass.lt-md]="'grid_item_mobile_content_right'"></div>

			<!-- ================================= FOOTER ================================= -->
			<div [ngClass.gt-sm]="'grid_item_desktop_footer'" [ngClass.lt-md]="'grid_item_mobile_footer'">
				<app-footer-desktop></app-footer-desktop>
			</div>
		</div>

  </mat-sidenav-content>
</mat-sidenav-container>

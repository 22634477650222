import { Injectable                            } from '@angular/core';
import { HttpClient                            } from '@angular/common/http';
import { HttpBasicService                      } from '../http-basic.service';
import { SessionService                        } from '../session/session.service';
import { IndTcnService                         } from '../indTcn/indTcn.service';
import { InfCrctInvInterface, TipViewInfCrctInv } from 'src/app/_models/infCrctInv/infCrctInv';
import { InfCrctInvCotc } from 'src/app/_models/infCrctInv/infCrctInvCotc';
import { InfCrctInvIndTcn } from 'src/app/_models/infCrctInv/infCrctInvIndTcn';
import { CotcService } from '../cotc/cotc.service';

@Injectable({
  providedIn: 'root'
})
export class InfCrctInvService extends HttpBasicService< InfCrctInvInterface< any >, InfCrctInvInterface< any > >{
  constructor( protected http: HttpClient,
               public sessionService: SessionService,
               protected indTcnService: IndTcnService,
               protected cotcService: CotcService ) {
    super( http, sessionService );
  }

  public getValues(): Promise< InfCrctInvInterface< any >[] >{
    const _sessionService = this.sessionService;
    const _indTcnService  = this.indTcnService;
    const _cotcService    = this.cotcService;

    const p = new Promise< InfCrctInvInterface< any >[] >( function( resolve, reject ) {
      //Verifica se os valores ja estao armazenados.
      if( _sessionService.isExistInfCrctInvList() === false ){
        //Adiciona a informacao relacionada à cotacao.
        const infCotc = new InfCrctInvCotc( null, _cotcService );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.PRC_FECH        );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.CANDLE          );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.PERCENT         );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.VOL_NEGC        );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.PRC_ABERT       );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.PRC_MIN         );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.PRC_MAX         );
        infCotc.addTipViewListInfCrctInv( TipViewInfCrctInv.TX_PERC_RENT_AA );
        _sessionService.addInfCrctInv( infCotc );

        //Adiciona os indicadores tecnicos.
        _indTcnService.listIndTcn()
          .then( indTcnList => {
            const qtIndTcnList = indTcnList != null ? indTcnList.length : 0;

            for( let i = 0; i < qtIndTcnList; i++ ){
              const indTcnIterate = indTcnList[ i ];

              const inf = new InfCrctInvIndTcn( null, _indTcnService, indTcnIterate, null );
              _sessionService.addInfCrctInv( inf );

              //Busca as periodicidades vinculadas ao indicador tecnico.
              _indTcnService.listPerdcOfIndTcn( indTcnIterate.snlTcn.sgSnlTcn, indTcnIterate.sgIndTcn )
                .then( perdcIndTcnList => {
                  let perdcList: number[] = [];
                  const qtPerdc = perdcIndTcnList != null ? perdcIndTcnList.length : 0;

                  for( let i = 0; i < qtPerdc; i++ ){
                    perdcList.push( perdcIndTcnList[i].perdcIndTcn );
                  }

                  _sessionService.setPerdcListOfInfCrctInv( inf, perdcList );

                  //Verifica se todas informacoes ja possuem a lista de periodicidades.
                  if( _sessionService.isAllInfCrctInvHasPerdcListNotEqualNull() === true ){
                    resolve( _sessionService.getInfCrctInvList() );
                  }
                }
              );
            }
          }
        );
      }
      else{
        resolve( _sessionService.getInfCrctInvList() );
      }
    });

    return p;
  }
}
import { DestaquesBySeq     } from 'src/app/_models/dtq/dtq';
import { MatTableDataSource } from '@angular/material/table';
import { Perdc              } from 'src/app/_models/perdc';
import { DestaqueControl    } from './dtqControl';

export class DestaqueControlPanel{
  public nm   : string
  public title: string;
  public dtqControlList: DestaqueControl[];
}

import { OnInit, OnChanges, DoCheck, SimpleChanges } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AplicationStateService } from '../_services/aplication-state/aplication-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';


export abstract class AbstractMainComponent implements OnInit, OnChanges, DoCheck{
  constructor( protected router: Router,
               protected aplicationStateService: AplicationStateService,
               protected snackBar              : MatSnackBar             ){
    aplicationStateService.resetTitle();
  }

  ngOnChanges( changes: SimpleChanges ): void {
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void{
  }

  goToPage( page: string, queryParams: Object, isOpenNewTab?: boolean ){
    console.log( 'goToPage( ' + page + ', ' + queryParams + ', ' + isOpenNewTab + ' )...' );

    let navigationExtras: NavigationExtras = {
        'queryParams': queryParams,
        skipLocationChange: false
    };

    if( isOpenNewTab && isOpenNewTab === true ){
      console.log( 'if' );

      const _this = this;

      const newRelativeUrl = this.router.serializeUrl( _this.router.createUrlTree( [ page ], navigationExtras ) );
      const baseUrl        = window.location.href.replace( _this.router.url, '' );
      const url            = baseUrl + newRelativeUrl;

      console.log( 'url: ' + url );

      window.open( url, '_blank' );

      /*
      _this.router.navigate( [] )
        .then( result => {
          console.log( 'then' );

          const newRelativeUrl = _this.router.createUrlTree( [ page ], navigationExtras );
          const baseUrl        = window.location.href.replace( _this.router.url, '' );
          const url            = baseUrl + newRelativeUrl;

          window.open( url, '_blank' );
        } );
      */
    }
    else{
      console.log( 'else' );

      this.router.navigate( [ page ], navigationExtras );
    }
  }

  protected showMessageAlert( msg: string, msgAction: string ): void{
    const config = { duration: 30000, panelClass: [ 'snackbar-default' ] };

    this.showMessage( msg, msgAction, config );
  }

  protected showMessageError( error: any, msgAction: string ): void{
    const config = { duration: 600000, panelClass: [ 'snackbar-error' ] };

    let msg = 'Erro ' + error.codErr + ': ' + error.msgErr;

    this.showMessage( msg, msgAction, config );
  }

  private showMessage( msg: string, msgAction: string, config: any ): void{
    this.snackBar.open( msg, msgAction, config );
  }
}

import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService        } from '../session/session.service';

import { UltAcaoAssist, Assist } from '@models/assist/assist';


@Injectable({
  providedIn: 'root'
})
export class AssistService extends HttpBasicService< Assist, Assist >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService ) {
    super( http, sessionService );
  }

  /**
   * Lista a ultima acao sugerida por cada assistente.
   */
  listUltAcaoAssist( cdGrCrctInvAcao: number, cdInv: number ): Promise< UltAcaoAssist[] >{
    const objData = { cd_gr_crct_inv_acao: cdGrCrctInvAcao, cd_inv: cdInv };

    //Executa servico.
    const respJsonGen = super.reqPostJsonGeneric( '/ASSIST/LUAA', objData );

    let _this = this;

    let objList = new Array< UltAcaoAssist >();

    return new Promise< UltAcaoAssist[] >( function( resolve, reject ) {
      respJsonGen.then( rag => {
        let dataResp = rag.dataResp;

        for( const dR of (dataResp as any) ){
          objList.push( dR );
        }

        resolve( objList );
      });
    });
  }
}

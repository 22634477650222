import { Component, Inject, OnInit, ViewChild     } from '@angular/core';
import { DomSanitizer                             } from '@angular/platform-browser';
import { ActivatedRoute                           } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl       } from '@angular/forms';

import { MatDialogRef                             } from '@angular/material/dialog';

import { AplicationStateService             } from '../../../../_services/aplication-state/aplication-state.service';
import { TickerDialogSelectDesktopComponent } from './desktop/ticker-dialog-select-desktop.component';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { MatSelectChange } from '@angular/material/select';
import { TipInv, EspcAcao, CrctInv } from 'src/app/_models/inv';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';


export abstract class TickerDialogSelectComponent implements OnInit {
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_CD_TIP_INV_SELECTED  = 'TickerDialogSelect_cdTipInvSelected';
  private PRM_USR_CD_ESPC_SELECTED     = 'TickerDialogSelect_cdEspcSelected';
  private PRM_USR_FILTER_NAME          = 'TickerDialogSelect_filterName';
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'TickerDialogSelect_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'TickerDialogSelect_paginatorPageIndex';

  //Lista de tickers filtrados
  public _invListDisplayedColumns: string[] = [ 'select', 'inv', 'tipInv', 'empr', 'setor' ];
  public _invListDataSource;
  @ViewChild( 'invListPaginator', {static: true} ) paginator: MatPaginator;

  //Lista de tickers que foram marcados/selecionados pelo usuario.
  public _invListSelectedsDisplayedColumns: string[] = [ 'tickerSelected' ];
  public _invListSelectedsDataSource: MatTableDataSource< CrctInv >;
  @ViewChild( 'invListSelectedsPaginator', {static: true} ) _invListSelectedsPaginator: MatPaginator;

  public tipInvList : TipInv[];
  public tipEspcList: EspcAcao[];

  public _tickerForm;

  public _paginatorPageSize;
  public _paginatorPageIndex;

  public _invListColumnSelectColor   = 'primary';
  public _invListColumnSelectChecked = false;

  constructor( public dialogRef: MatDialogRef< TickerDialogSelectComponent >,
               protected formBuilder           : FormBuilder            ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService ) {
  }

  backdropClick(){
    console.log( 'backdropClick.' );
  }

  ngOnInit() {
    this._tickerForm = this.createForm();

    //Verifica se usuario possui parametro armazenado.
    const cdTipInvSelectedOfPrmUsr   = this.prmUsrService.getValue( this.PRM_USR_CD_TIP_INV_SELECTED );
    const cdEspcSelectedOfPrmUsr     = this.prmUsrService.getValue( this.PRM_USR_CD_ESPC_SELECTED );
    const filterNameOfPrmUsr         = this.prmUsrService.getValue( this.PRM_USR_FILTER_NAME );
    const paginatorPageSizeOfPrmUsr  = this.prmUsrService.getValue( this.PRM_USR_PAGINATOR_PAGE_SIZE );
    const paginatorPageIndexOfPrmUsr = this.prmUsrService.getValue( this.PRM_USR_PAGINATOR_PAGE_INDEX );

    //Define valores iniciais.
    const cdTipInvInit           = cdTipInvSelectedOfPrmUsr   == null ? 7  : Number( cdTipInvSelectedOfPrmUsr );
    const cdEspcInit             = cdEspcSelectedOfPrmUsr     == null ? 0  : Number( cdEspcSelectedOfPrmUsr );
    const filterNameInit         = filterNameOfPrmUsr         == null ? '' : filterNameOfPrmUsr;
    const paginatorPageSizeInit  = paginatorPageSizeOfPrmUsr  == null ? 20 : paginatorPageSizeOfPrmUsr;
    const paginatorPageIndexInit = paginatorPageIndexOfPrmUsr == null ? 0  : paginatorPageIndexOfPrmUsr;

    this.setCdTipInvSelected( cdTipInvInit );
    this.setCdEspcSelected( cdEspcInit );
    this.setNmTicker( filterNameInit );
    this._paginatorPageSize  = paginatorPageSizeInit;
    this._paginatorPageIndex = paginatorPageIndexInit;

    // Carrega os filtros.
    this.initFilters( cdTipInvInit );

    //Atualiza lista de tickers.
    this.refresh();
  }

  private createForm(){
    return this.formBuilder.group(
      { nmTicker              : '',
        isOnlyEmprMonitored   : new FormControl( true ),
        cdTipInvSelected      : 0,
        cdEspcSelected        : 0 }
    );
  }

  private getNmTicker(){
    return this._tickerForm.controls[ 'nmTicker' ].value;
  }

  private setNmTicker( nmTicker: string ){
    this._tickerForm.patchValue( { nmTicker: nmTicker } );
  }

  private isOnlyEmprMonitoredSelected(){
    return this._tickerForm.controls[ 'isOnlyEmprMonitored' ].value;
  }

  private setOnlyEmprMonitoredSelected( isOnlyEmprMonitored: Boolean ){
    this._tickerForm.patchValue( { isOnlyEmprMonitored: isOnlyEmprMonitored } );
  }

  private getCdTipInvSelected(){
    return this._tickerForm.controls[ 'cdTipInvSelected' ].value;
  }

  private setCdTipInvSelected( cdTipInv: number ){
    this._tickerForm.patchValue( { cdTipInvSelected: cdTipInv } );
  }

  private getCdEspcSelected(){
    return this._tickerForm.controls[ 'cdEspcSelected' ].value;
  }

  private setCdEspcSelected( cdEspc: number ){
    this._tickerForm.patchValue( { cdEspcSelected: cdEspc } );
  }

  refresh(){
    let inPermtAtlzCotc = this.isOnlyEmprMonitoredSelected();

    const cdTipInvSelected = this.getCdTipInvSelected();
    const cdEspcSelected   = this.getCdEspcSelected();

    //console.log( 'inPermtAtlzCotc/tipInv/espc: ' + inPermtAtlzCotc + '/' + cdTipInvSelected + '/' + cdEspcSelected );

    this.invService.list( inPermtAtlzCotc, cdTipInvSelected, cdEspcSelected )
      .then( dataResp => {
        //console.log( 'dataRespSize: ' + ( dataResp != null ? dataResp.length : 0 ) );

        this._invListDataSource = new MatTableDataSource< CrctInv >( dataResp );
        this._invListDataSource.paginator = this.paginator;

        this._invListDataSource.filterPredicate = (d: CrctInv, filter: string) => {
          try{
            const nmInvData  = d.nmInv.toLowerCase() || '';
            const nmTipInv   = d.tipInv.nmTipInv.toLowerCase() || '';
            const nmAbrvEmpr = d.empr != null ? d.empr.nmAbrvEmpr : null;
            const nmEmpr     = d.empr != null ? d.empr.nmEmpr     : null;
            const nmSetor    = ( d.empr != null && d.empr.segmEcnm != null && d.empr.segmEcnm.subSetorEcnm != null && d.empr.segmEcnm.subSetorEcnm.setorEcnm != null ) ? d.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm : null;
            const nmSubSetor = ( d.empr != null && d.empr.segmEcnm != null && d.empr.segmEcnm.subSetorEcnm != null && d.empr.segmEcnm.subSetorEcnm != null           ) ? d.empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm        : null;
            const nmSegm     = ( d.empr != null && d.empr.segmEcnm != null                                                                                           ) ? d.empr.segmEcnm.nmSegmEcnm                         : null;

            const nmAbrvEmprToTest = nmAbrvEmpr == null ? '' : nmAbrvEmpr.toLowerCase() || '';
            const nmEmprToTest     = nmEmpr     == null ? '' : nmEmpr.toLowerCase() || '';
            const nmSetorToTest    = nmSetor    == null ? '' : nmSetor.toLowerCase()    || '';
            const nmSubSetorToTest = nmSubSetor == null ? '' : nmSubSetor.toLowerCase() || '';
            const nmSegmToTest     = nmSegm     == null ? '' : nmSegm.toLowerCase()     || '';

            return nmInvData.indexOf( filter )        !== -1 ||
                   nmTipInv.indexOf( filter )         !== -1 ||
                   nmAbrvEmprToTest.indexOf( filter ) !== -1 ||
                   nmEmprToTest.indexOf( filter )     !== -1 ||
                   nmSetorToTest.indexOf( filter )    !== -1 ||
                   nmSubSetorToTest.indexOf( filter ) !== -1 ||
                   nmSegmToTest.indexOf( filter )     !== -1;
          }
          catch( error ){
            console.log( error );
            return false;
          }
        };

        this.execFilterNmTicker( this.getNmTicker() );
      });

    //console.log( 'TickerDialogSelectComponent.refresh().' );
  }

  onSubmit(){
    console.log( 'TickerDialogSelectComponent.onSubmit()...' );

    // stop here if form is invalid
    if( this._tickerForm.invalid ){
      console.log( '_tickerForm.invalid!' );

      return;
    }
    else{
      //const nmTipItemOrcm = this._tickerForm.controls.nmTipItemOrcm.value;

      //this.insertTio( nmTipItemOrcm );
    }
  }

  private addTickerSelected( ticker: CrctInv ){
    const indexOfTicker = this.getIndexOfTickerSelected( ticker );

    if( indexOfTicker < 0 ){
      if( this._invListSelectedsDataSource == null ){
        this._invListSelectedsDataSource = new MatTableDataSource< CrctInv >( [] );
        this._invListSelectedsDataSource.paginator = this._invListSelectedsPaginator;
      }

      let data = this._invListSelectedsDataSource.data;
      data.push( ticker );
      this._invListSelectedsDataSource.data = data;
    }
  }

  public removeTickerSelected( ticker: CrctInv ){
    const indexOfTicker = this.getIndexOfTickerSelected( ticker );

    if( indexOfTicker >= 0 ){
      let data = this._invListSelectedsDataSource.data;
      data.splice( indexOfTicker, 1 );
      this._invListSelectedsDataSource.data = data;
    }
  }

  public getIndexOfTickerSelected( ticker: CrctInv ){
    let indexFound = -1;

    if( this._invListSelectedsDataSource != null ){
      const tickers = this._invListSelectedsDataSource.data;
      const countTickers = tickers.length;

      for( let iTicker = 0; iTicker < countTickers; iTicker++ ){
          const tickerIterate: CrctInv = tickers[ iTicker ];

          if( ticker.cdInv === tickerIterate.cdInv ){
            indexFound = iTicker;
            break;
          }
      }
    }

    return indexFound;
  }

  private loadTipInvList(){
    this.invService.listTipInv()
      .then( dataResp => {
        this.tipInvList = dataResp;

        //Adiciona o tipo de investimento 0-Todos.
        const tipInvTodos = new TipInv();
        tipInvTodos.cdTipInv = 0;
        tipInvTodos.nmTipInv = 'Todos';

        this.tipInvList.unshift( tipInvTodos );
      });
  }

  private loadEspcList( cdTipInv: number ){
    if( cdTipInv > 0 ){
      this.invService.listTipEspc( cdTipInv )
        .then( dataResp => {
          this.tipEspcList = dataResp;
          this.addEspcTodas();
        });
    }
    else{
      this.tipEspcList = [];
      this.addEspcTodas();
    }
  }

  private initFilters( cdTipInv: number ){
    this.loadTipInvList();
    this.loadEspcList( cdTipInv );
  }

  public applyFilterNmTicker( event: Event ){
    const filterValue = (event.target as HTMLInputElement).value;

    this.execFilterNmTicker( filterValue );

    this.prmUsrService.setValue( this.PRM_USR_FILTER_NAME, filterValue );
  }

  private execFilterNmTicker( filterValue: string ){
    if( this._invListDataSource && this._invListDataSource != null ){
      this._invListDataSource.filter = filterValue && filterValue != null ? filterValue.trim().toLowerCase() : '';
    }
  }

  public updateOnlyEmprMonitored(){
    this.refresh();
  }

  public changePageSize( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }

  private addEspcTodas(){
    //Adiciona a espécie de investimento 0-Todas.
    const espcAcaoTodos = new EspcAcao();
    espcAcaoTodos.cdEspcAcao = 0;
    espcAcaoTodos.nmEspcAcao = 'Todas';

    this.tipEspcList.unshift( espcAcaoTodos );
  }

  public onChangeUpdateCdTipInvSelected( event: MatSelectChange ){
    const cdTipInvSelected = event != null ? event.value : 0;

    this.setCdEspcSelected( 0 );

    this.prmUsrService.setValue( this.PRM_USR_CD_TIP_INV_SELECTED, cdTipInvSelected );

    this.loadEspcList( cdTipInvSelected );

    this.refresh();
  }

  public onChangeUpdateCdEspcSelected( event: MatSelectChange ){
    const cdEspcSelected = event != null ? event.value : 0;

    this.prmUsrService.setValue( this.PRM_USR_CD_ESPC_SELECTED, cdEspcSelected );

    this.refresh();
  }

  public onChangeClickedTicker( event: MatCheckboxChange, ticker: CrctInv ){
    //console.log( 'event: ' + event.checked + ' - ticker: ' + ticker.nmInv );

    if( event.checked === true ){
      this.addTickerSelected( ticker );
    }
    else{
      this.removeTickerSelected( ticker );
    }
  }

  public onRetryTickersSelecteds(){
    this.closeDialog( this._invListSelectedsDataSource.data );
  }

  public onClickCancelDialog(){
    this.closeDialog( null );
  }

  private closeDialog( crctInvList: CrctInv[] ){
    this.dialogRef.close( crctInvList );
  }
}

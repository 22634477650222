import { Component, OnInit      } from '@angular/core';
import { DomSanitizer           } from '@angular/platform-browser';
import { AplicationStateService } from '../../../_services/aplication-state/aplication-state.service';
import { SessionService         } from '../../../_services/session/session.service';
import { AuthenticationService  } from '../../../_services/authentication/authentication.service';
import { HeaderComponent        } from '../header.component';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header.desktop.component.html',
  styleUrls: ['./header.desktop.component.css']
})
export class HeaderDesktopComponent extends HeaderComponent implements OnInit {
  constructor( protected sanitizer: DomSanitizer,
               aplicationStateService: AplicationStateService,
               public sessionService: SessionService,
               authenticationService: AuthenticationService ){
    super( sanitizer, aplicationStateService, sessionService, authenticationService );
  }

  ngOnInit(){
  }
}

<!-- ================================= CONTENT ================================= -->

<h2>
	<span mat-dialog-title>Carteiras</span>
	<mat-progress-bar *ngIf="this._controlMain.isShowProgressBar" [mode]="this._controlMain.modeProgressBar" value="this._controlMain.valueProgressBar"></mat-progress-bar>
</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="true">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Minhas Carteiras
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlMinhasCarteiras.isShowProgressBar" [mode]="this._controlMinhasCarteiras.modeProgressBar" value="this._controlMinhasCarteiras.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_list_minhas">
			<div class="grid_item_gcia_list_minhas_filters_removed">
				<mat-checkbox (change)="onLoadGrCrctInvList()" formControlName="isShowGrCrctInvAll" >
					Mostrar também as Carteiras excluídas
				</mat-checkbox>
			</div>

			<div class="grid_item_gcia_list_minhas_filters_sdo">
				<mat-checkbox (change)="onClickShowGrWithSdo()" formControlName="isShowGrCrctInvWithSdo" >
					Mostrar somente Carteiras com saldo
				</mat-checkbox>
			</div>

			<div class="grid_item_gcia_list_minhas_table" *ngIf="this._grCrctInvAcaoListDataSource != null && this._grCrctInvAcaoListDataSource.data != null && this._grCrctInvAcaoListDataSource.data.length > 0">
				<table mat-table [dataSource]="_grCrctInvAcaoListDataSource">
					<ng-container matColumnDef="nm" sticky>
						<th mat-header-cell *matHeaderCellDef> Nome </th>
						<td mat-cell *matCellDef="let element">
							{{element.nmGrCrctInvAcao}}
						</td>
					</ng-container>

					<ng-container matColumnDef="txDescr">
						<th mat-header-cell *matHeaderCellDef> Descrição </th>
						<td mat-cell *matCellDef="let element"> 
							<div class="ql-editor" [innerHTML]="element.txDescrGrCrctInvAcao"></div>
						</td>
					</ng-container>

					<ng-container matColumnDef="sdoGr">
						<th mat-header-cell *matHeaderCellDef> Investido </th>
						<td mat-cell *matCellDef="let element"> 
							<ng-container *ngIf="element.vlSdoInvt != null && element.vlSdoInvt != 0">
								{{element.vlSdoInvt | number:'1.2-2'}}
								<span>
									<mat-icon class="icon_help" 
											  matTooltip="Em Caixa: R$ {{element.vlSdoCx | number:'1.2-2'}}
														  Investido: R$ {{element.vlSdoInvt | number:'1.2-2'}}
														  Resultado: R$ {{element.vlRstdGrCrctInvAcao | number:'1.2-2'}}"
											  [matTooltipClass]="'multline-tooltip'" >info
									</mat-icon>
								</span>
							</ng-container>
						</td>
					</ng-container>

					<ng-container matColumnDef="options">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options_block">
								<button mat-icon-button color="accent" (click)="this.openMvtcGrCrctInv( $event, element )" *ngIf="element.inGrCrctInvAcaoAtivo == true" >
									<mat-icon matTooltip="Movimentações financeiras da Carteira">attach_money</mat-icon>
								</button>

								<button mat-icon-button color="accent" (click)="this.openDialogEditGrCrctInv( element )" *ngIf="element.inGrCrctInvAcaoAtivo == true" >
									<mat-icon matTooltip="Editar a Carteira">settings</mat-icon>
								</button>

								<button mat-icon-button color="accent" (click)="compareTickers( $event, element )" *ngIf="element.inGrCrctInvAcaoAtivo == true" >
								    <mat-icon matTooltip="Comparar Ativos da Carteira">compare_arrows</mat-icon>
								</button>								

								<button mat-icon-button color="accent" (click)="this.onDeleteGrCrctInvAcao( element )" *ngIf="element.inGrCrctInvAcaoAtivo == true" >
								    <mat-icon matTooltip="Excluir a Carteira">delete</mat-icon>
								</button>								

								<button mat-icon-button color="accent" (click)="this.onReactivateGrCrctInvAcao( element )" *ngIf="element.inGrCrctInvAcaoAtivo == false" >
								    <mat-icon matTooltip="Reativar a Carteira">toggle_off</mat-icon>
								</button>								
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_grCrctInvAcaoListDisplayedColumns; sticky: true"></tr>
					<tr mat-row        *matRowDef="let row; columns: _grCrctInvAcaoListDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	class="mat-paginator-sticky"
								[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this._grCrctInvAcaoListPaginatorPageSize"
								[pageIndex]="this._grCrctInvAcaoListPaginatorPageIndex"
								(page)="onChangeGrCrctInvAcaoListPage($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div class="grid_item_gcia_list_minhas_table_empty" *ngIf="this._grCrctInvAcaoListDataSource == null || this._grCrctInvAcaoListDataSource.data == null || this._grCrctInvAcaoListDataSource.data.length == 0">
				Você ainda não criou nenhuma Carteira.
			</div>

			<div class="grid_item_gcia_list_minhas_buttons">
				<button mat-icon-button class="column_options_element" color="accent" (click)="this.openDialogAddGrCrctInv()">
					<mat-icon matTooltip="Criar nova Carteira.">add_circle</mat-icon>
				</button>
			</div>
		</div>
	</mat-expansion-panel>


	<mat-expansion-panel class="mat-expansion-panel-spacing" [expanded]="false"
						 (opened)="onOpenedPanelComptGrCrctInvAcaoList()"
						 (closed)="onClosedPanelComptGrCrctInvAcaoList()">
    	<mat-expansion-panel-header class="label_title">
      		<mat-panel-title>
        		Carteiras Compartilhadas Comigo
      		</mat-panel-title>

			<mat-panel-description>
				<span></span>
				<mat-progress-bar *ngIf="this._controlCompt.isShowProgressBar" [mode]="this._controlCompt.modeProgressBar" value="this._controlCompt.valueProgressBar"></mat-progress-bar>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="grid_container_gcia_list_compt">
			<div class="grid_item_gcia_list_compt_table" *ngIf="this._comptGrCrctInvAcaoListDataSource != null && _comptGrCrctInvAcaoListDataSource.data != null && _comptGrCrctInvAcaoListDataSource.data.length > 0">
				<table mat-table [dataSource]="_comptGrCrctInvAcaoListDataSource">
					<ng-container matColumnDef="nmGrCompt" sticky>
						<th mat-header-cell *matHeaderCellDef> Nome </th>
						<td mat-cell *matCellDef="let element">
							{{element.nmGrCrctInvAcao}}
						</td>
					</ng-container>

					<ng-container matColumnDef="sdoGrCompt">
						<th mat-header-cell *matHeaderCellDef> Saldo </th>
						<td mat-cell *matCellDef="let element"> 
							<ng-container *ngIf="element.vlSdoInvtAtlzd != null && element.vlSdoInvtAtlzd != 0">
								{{element.vlSdoInvtAtlzd | number:'1.2-2'}}
								<span>
									<mat-icon class="icon_help" 
											  matTooltip="Em Caixa: R$ {{element.vlSdoCx | number:'1.2-2'}}
														  Investido: R$ {{element.vlSdoInvt | number:'1.2-2'}}
														  Resultado: R$ {{element.vlRstdGrCrctInvAcao | number:'1.2-2'}}"
											  [matTooltipClass]="'multline-tooltip'" >info
									</mat-icon>
								</span>
							</ng-container>
						</td>
					</ng-container>

					<ng-container matColumnDef="optionsGrCompt">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options_block">
								<button mat-icon-button color="accent" (click)="this.openMvtcComptGrCrctInv( $event, element )" *ngIf="element.inGrCrctInvAcaoAtivo == true" >
									<mat-icon matTooltip="Movimentações financeiras da Carteira">attach_money</mat-icon>
								</button>

								<button mat-icon-button color="accent" (click)="compareTickers( $event, element )" *ngIf="element.inGrCrctInvAcaoAtivo == true" >
								    <mat-icon matTooltip="Comparar Ativos da Carteira">compare_arrows</mat-icon>
								</button>								
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="_comptGrCrctInvAcaoListDisplayedColumns; sticky: true"></tr>
					<tr mat-row        *matRowDef="let row; columns: _comptGrCrctInvAcaoListDisplayedColumns;"></tr>
				</table>

				<mat-paginator 	class="mat-paginator-sticky"
								[pageSizeOptions]="[10, 20, 50, 100, 200]" 
								[pageSize]="this._comptGrCrctInvAcaoListPaginatorPageSize"
								[pageIndex]="this._comptGrCrctInvAcaoListPaginatorPageIndex"
								(page)="onChangeGrCrctInvAcaoListPage($event)" 
								showFirstLastButtons>
				</mat-paginator>
			</div>

			<div class="grid_item_gcia_list_compt_table_empty" *ngIf="this._comptGrCrctInvAcaoListDataSource == null || _comptGrCrctInvAcaoListDataSource.data == null || _comptGrCrctInvAcaoListDataSource.data.length == 0">
				Não há nenhuma Carteira compartilhada com você.
			</div>
		</div>
	</mat-expansion-panel>
</form>
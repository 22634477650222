/**
 * Controle da apresentacao (show) dos dados.
 */
export class ControlShow{
  //Um nome para este objeto.
  public nm                   : string;

  //O nome deste objeto que pode ser mostrado ao usuario.
  public nmDisplayable        : string;

  //Permitido mostrar?
  public isAllowedShow        : boolean = false;

  //Permitido alterar o isAllowedShow?
  public isAllowedChange      : boolean = true;

  public constructor( nm: string ){
    this.nm            = nm;
    this.isAllowedShow = true;
  }
}

import { Component, ViewEncapsulation, Inject     } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AplicationStateService             } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService              } from '@services/authentication/authentication.service';
import { PrmUsrService                      } from '@services/prmUsr/prmUsr.service';
import { InvService                         } from '@services/inv/inv.service';

import { InputMessageDataParm               } from '../models/inputMessageDataParm';
import { GeneralDialogInputMessageComponent } from '../general-dialog-inputMessage.component';


@Component({
  selector: 'app-general-dialog-inputMessage-desktop',
  templateUrl: './general-dialog-inputMessage-desktop.component.html',
  styleUrls: ['./general-dialog-inputMessage-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralDialogInputMessageDesktopComponent extends GeneralDialogInputMessageComponent {
  constructor( public dialogRef: MatDialogRef< GeneralDialogInputMessageDesktopComponent >,
               protected formBuilder           : FormBuilder            ,
               protected dialog                : MatDialog              ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService             ,
               @Inject( MAT_DIALOG_DATA ) public data: InputMessageDataParm ){
    super( dialogRef, formBuilder, dialog, aplicationStateService, authenticationService, prmUsrService, invService, data );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

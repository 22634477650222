import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router                 } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AplicationStateService } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '../../../../_services/authentication/authentication.service';
import { UsrService             } from 'src/app/_services/usr/usr.service';
import { LoginForgotComponent } from '../login-forgot.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-forgot-desktop',
  templateUrl: './login-forgot-desktop.component.html',
  styleUrls: ['./login-forgot-desktop.component.css']
})
export class LoginForgotDesktopComponent extends LoginForgotComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected usrService            : UsrService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, aplicationStateService, authenticationService, snackBar, usrService );
  }
}

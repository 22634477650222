import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { Empr, InfComplEmpr } from 'src/app/_models/empr';
import { SegmEcnm } from 'src/app/_models/setorEcnm';


@Injectable({
  providedIn: 'root'
})
export class SetorService extends HttpBasicService< SegmEcnm, SegmEcnm >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe      : DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Detalha setor/sub-setor/segmento economico.
   */
  detail( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ): Promise< SegmEcnm >{
    let reqData = {};

    reqData[ 'cd_setor_ecnm'     ] = cdSetorEcnm    == null ? 0 : cdSetorEcnm;
    reqData[ 'cd_sub_setor_ecnm' ] = cdSubSetorEcnm == null ? 0 : cdSubSetorEcnm;
    reqData[ 'cd_segm_ecnm'      ] = cdSegmEcnm     == null ? 0 : cdSegmEcnm;

    return super.detailGeneric( '/SETOR/DET', reqData, false );
  }

  /**
   * Lista setores/sub-setores/segmentos economicos.
   */
  list( cdSetorEcnm: number, cdSubSetorEcnm: number, cdSegmEcnm: number ): Promise< SegmEcnm[] >{
    let reqData = {};

    if( cdSetorEcnm != null ){
      reqData[ 'cd_setor_ecnm' ] = cdSetorEcnm;
    } 

    if( cdSubSetorEcnm != null ){
      reqData[ 'cd_sub_setor_ecnm' ] = cdSubSetorEcnm;
    } 

    if( cdSegmEcnm != null ){
      reqData[ 'cd_segm_ecnm' ] = cdSegmEcnm;
    } 

    return super.listGeneric( '/SETOR/LIST', reqData, false );
  }
}

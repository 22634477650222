<div class="grid_container_header_mobile">
  <div class="grid_item_header_mobile_title">
    <span class="header_mobile_title">INVEST RÁPIDO</span>
  </div>

  <div class="grid_item_header_mobile_logo">
    <a routerLink="" routerLinkActive="active" class="header_menu_link">
      <img alt="Invest Rápido Logo" height="50" src="/assets/img/logo_560x500_transp.png">
    </a>
  </div>

  <div class="grid_item_header_mobile_telefone">
    <span>
    </span>

    <span class="header_mobile_num_telefone">
    </span>
  </div>
</div>

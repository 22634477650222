<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Cadastrar Usuário</h2>

<form [formGroup]="this._form">
  <div class="grid_container_login">
    <div class="grid_item_login_header">
			Preencha os campos abaixo para cadastrar-se no Invest Rápido.
    </div>

    <div class="grid_item_login_name">
		<mat-form-field>
			<mat-label>Nome Completo:</mat-label>
			<input matInput placeholder="Exemplo: João Silva" [formControl]="this._nameForm" required>
		</mat-form-field>
    </div>

    <div class="grid_item_login_user">
		<mat-form-field>
			<mat-label>Usuário:</mat-label>
			<input matInput placeholder="Exemplo: joao1234" [formControl]="this._usernameForm" required>
			<mat-error *ngIf="this._usernameForm.invalid">{{this.getErrorMessageOfEmailForm()}}</mat-error>
		</mat-form-field>
    </div>

    <div class="grid_item_login_email">
		<mat-form-field>
			<mat-label>E-mail:</mat-label>
			<input matInput placeholder="Exemplo: joao1234@exemplo.com" [formControl]="this._emailForm" required>
			<mat-error *ngIf="this._emailForm.invalid">{{this.getErrorMessageOfEmailForm()}}</mat-error>
		</mat-form-field>
    </div>

    <div class="grid_item_login_pass">
			<mat-form-field appearance="fill">
				<mat-label>Senha:</mat-label>
				<input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._pwdForm" required>
				<button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
					<mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
    </div>

    <div class="grid_item_login_passRepeat">
			<mat-form-field appearance="fill">
				<mat-label>Confirmar Senha:</mat-label>
				<input matInput [type]="this._isHidePassword ? 'password' : 'text'" [formControl]="this._pwdRepeatForm" required>
				<button mat-icon-button matSuffix (click)="this._isHidePassword = !this._isHidePassword" [attr.aria-label]="'Ocultar senha'" [attr.aria-pressed]="this._isHidePassword">
					<mat-icon>{{this._isHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
    </div>

    <div class="grid_item_login_button">
			<button (click)="this.onAddUser()" class="button">Cadastre-se</button>
    </div>
  </div>
</form>
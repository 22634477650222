import { Component, OnInit, ViewChild       } from '@angular/core';
import { DomSanitizer                       } from '@angular/platform-browser';
import { NavigationExtras, Router           } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { MatTableDataSource                 } from '@angular/material/table';
import { MatPaginator                       } from '@angular/material/paginator';

import { AplicationStateService             } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService              } from '../../../_services/authentication/authentication.service';
import { InvService                         } from '../../../_services/inv/inv.service';
import { PrmUsrService                      } from '../../../_services/prmUsr/prmUsr.service';

import { AbstractMainComponent      } from '../../abstract-main.component';
import { CrctInv, TipInv, EspcAcao  } from 'src/app/_models/inv';
import { MatSelectChange            } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';


export abstract class InvListComponent extends AbstractMainComponent{
  public displayedColumns: string[] = [ 'inv', 'tipInv', 'empr', 'setor', 'options' ];
  public dataSourceInvList;
  @ViewChild( MatPaginator, {static: true} ) paginator: MatPaginator;

  public tipInvList : TipInv[];
  public tipEspcList: EspcAcao[];

  public _form;

  public paginatorPageSize;
  public paginatorPageIndex;

  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_CD_TIP_INV_SELECTED  = 'InvList_cdTipInvSelected';
  private PRM_USR_CD_ESPC_SELECTED     = 'InvList_cdEspcSelected';
  private PRM_USR_FILTER_NAME          = 'InvList_filterName';
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'InvList_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'InvList_paginatorPageIndex';

  constructor( protected router               : Router                ,
               protected formBuilder          : FormBuilder           ,
               protected dialog               : MatDialog             ,
               sanitizer                      : DomSanitizer          ,
               aplicationStateService         : AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected prmUsrService        : PrmUsrService         ,
               protected snackBar             : MatSnackBar           ,
               protected invService           : InvService ) { 
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    super.ngOnInit();

    this._form = this.createForm();

    //Verifica se usuario possui parametro armazenado.
    const cdTipInvSelectedOfPrmUsr   = this.prmUsrService.getValue( this.PRM_USR_CD_TIP_INV_SELECTED );
    const cdEspcSelectedOfPrmUsr     = this.prmUsrService.getValue( this.PRM_USR_CD_ESPC_SELECTED );
    const filterNameOfPrmUsr         = this.prmUsrService.getValue( this.PRM_USR_FILTER_NAME );
    const paginatorPageSizeOfPrmUsr  = this.prmUsrService.getValue( this.PRM_USR_PAGINATOR_PAGE_SIZE );
    const paginatorPageIndexOfPrmUsr = this.prmUsrService.getValue( this.PRM_USR_PAGINATOR_PAGE_INDEX );

    //Define valores iniciais.
    const cdTipInvInit           = cdTipInvSelectedOfPrmUsr   == null ? 7  : Number( cdTipInvSelectedOfPrmUsr );
    const cdEspcInit             = cdEspcSelectedOfPrmUsr     == null ? 0  : Number( cdEspcSelectedOfPrmUsr );
    const filterNameInit         = filterNameOfPrmUsr         == null ? '' : filterNameOfPrmUsr;
    const paginatorPageSizeInit  = paginatorPageSizeOfPrmUsr  == null ? 20 : paginatorPageSizeOfPrmUsr;
    const paginatorPageIndexInit = paginatorPageIndexOfPrmUsr == null ? 0  : paginatorPageIndexOfPrmUsr;

    this.setCdTipInvSelected( cdTipInvInit );
    this.setCdEspcSelected( cdEspcInit );
    this.setNmTicker( filterNameInit );
    this.paginatorPageSize  = paginatorPageSizeInit;
    this.paginatorPageIndex = paginatorPageIndexInit;

    // Carrega os filtros.
    this.initFilters( cdTipInvInit );

    //Atualiza lista de tickers.
    this.refresh();
  }

  private createForm(){
    return this.formBuilder.group(
      { nmTicker              : '',
        isOnlyEmprMonitored   : new FormControl( true ),
        cdTipInvSelected      : 0,
        cdEspcSelected        : 0 }
    );
  }

  private isOnlyEmprMonitoredSelected(){
    return this._form.controls[ 'isOnlyEmprMonitored' ].value;
  }

  private setOnlyEmprMonitoredSelected( isOnlyEmprMonitored: Boolean ){
    this._form.patchValue( { isOnlyEmprMonitored: isOnlyEmprMonitored } );
  }

  private getNmTicker(){
    return this._form.controls[ 'nmTicker' ].value;
  }

  private setNmTicker( nmTicker: string ){
    this._form.patchValue( { nmTicker: nmTicker } );
  }

  private getCdTipInvSelected(){
    return this._form.controls[ 'cdTipInvSelected' ].value;
  }

  private setCdTipInvSelected( cdTipInv: number ){
    this._form.patchValue( { cdTipInvSelected: cdTipInv } );
  }

  private getCdEspcSelected(){
    return this._form.controls[ 'cdEspcSelected' ].value;
  }

  private setCdEspcSelected( cdEspc: number ){
    this._form.patchValue( { cdEspcSelected: cdEspc } );
  }

  refresh(){
    let inPermtAtlzCotc = this.isOnlyEmprMonitoredSelected();

    const cdTipInvSelected = this.getCdTipInvSelected();
    const cdEspcSelected   = this.getCdEspcSelected();

    this.invService.list( inPermtAtlzCotc, cdTipInvSelected, cdEspcSelected )
      .then( dataResp => {
        this.dataSourceInvList = new MatTableDataSource< CrctInv >( dataResp );
        this.dataSourceInvList.paginator = this.paginator;

        this.dataSourceInvList.filterPredicate = (d: CrctInv, filter: string) => {
          try{
            const nmInvData  = d.nmInv.toLowerCase() || '';
            const nmTipInv   = d.tipInv.nmTipInv.toLowerCase() || '';
            const nmAbrvEmpr = d.empr != null ? d.empr.nmAbrvEmpr : null;
            const nmEmpr     = d.empr != null ? d.empr.nmEmpr     : null;
            const nmSetor    = ( d.empr != null && d.empr.segmEcnm != null && d.empr.segmEcnm.subSetorEcnm != null && d.empr.segmEcnm.subSetorEcnm.setorEcnm != null ) ? d.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm : null;
            const nmSubSetor = ( d.empr != null && d.empr.segmEcnm != null && d.empr.segmEcnm.subSetorEcnm != null && d.empr.segmEcnm.subSetorEcnm != null           ) ? d.empr.segmEcnm.subSetorEcnm.nmSubSetorEcnm        : null;
            const nmSegm     = ( d.empr != null && d.empr.segmEcnm != null                                                                                           ) ? d.empr.segmEcnm.nmSegmEcnm                         : null;

            const nmAbrvEmprToTest = nmAbrvEmpr == null ? '' : nmAbrvEmpr.toLowerCase() || '';
            const nmEmprToTest     = nmEmpr     == null ? '' : nmEmpr.toLowerCase() || '';
            const nmSetorToTest    = nmSetor    == null ? '' : nmSetor.toLowerCase()    || '';
            const nmSubSetorToTest = nmSubSetor == null ? '' : nmSubSetor.toLowerCase() || '';
            const nmSegmToTest     = nmSegm     == null ? '' : nmSegm.toLowerCase()     || '';

            return nmInvData.indexOf( filter )        !== -1 ||
                   nmTipInv.indexOf( filter )         !== -1 ||
                   nmAbrvEmprToTest.indexOf( filter ) !== -1 ||
                   nmEmprToTest.indexOf( filter )     !== -1 ||
                   nmSetorToTest.indexOf( filter )    !== -1 ||
                   nmSubSetorToTest.indexOf( filter ) !== -1 ||
                   nmSegmToTest.indexOf( filter )     !== -1;
          }
          catch( error ){
            console.log( error );
            return false;
          }
        };

        this.execFilterNmTicker( this.getNmTicker() );
      });
  }

  onSubmit(){
  }

  private initFilters( cdTipInv: number ){
    this.loadTipInvList();
    this.loadEspcList( cdTipInv );
  }

  public applyFilterNmTicker( event: Event ){
    const filterValue = (event.target as HTMLInputElement).value;

    this.execFilterNmTicker( filterValue );

    this.prmUsrService.setValue( this.PRM_USR_FILTER_NAME, filterValue );
  }

  private execFilterNmTicker( filterValue: string ){
    if( this.dataSourceInvList && this.dataSourceInvList != null ){
      this.dataSourceInvList.filter = filterValue && filterValue != null ? filterValue.trim().toLowerCase() : '';
    }
  }

  public updateOnlyEmprMonitored(){
    this.refresh();
  }

  public updateCdTipInvSelected( event: MatSelectChange ){
    const cdTipInvSelected = event != null ? event.value : 0;

    this.setCdEspcSelected( 0 );

    this.prmUsrService.setValue( this.PRM_USR_CD_TIP_INV_SELECTED, cdTipInvSelected );

    this.loadEspcList( cdTipInvSelected );

    this.refresh();
  }

  public updateCdEspcSelected( event: MatSelectChange ){
    const cdEspcSelected = event != null ? event.value : 0;

    this.prmUsrService.setValue( this.PRM_USR_CD_ESPC_SELECTED, cdEspcSelected );

    this.refresh();
  }

  private loadTipInvList(){
    this.invService.listTipInv()
      .then( dataResp => {
        this.tipInvList = dataResp;

        //Adiciona o tipo de investimento 0-Todos.
        const tipInvTodos = new TipInv();
        tipInvTodos.cdTipInv = 0;
        tipInvTodos.nmTipInv = 'Todos';

        this.tipInvList.unshift( tipInvTodos );
      });
  }

  private loadEspcList( cdTipInv: number ){
    if( cdTipInv > 0 ){
      this.invService.listTipEspc( cdTipInv )
        .then( dataResp => {
          this.tipEspcList = dataResp;
          this.addEspcTodas();
        });
    }
    else{
      this.tipEspcList = [];
      this.addEspcTodas();
    }
  }

  public changePageSize( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }

  private addEspcTodas(){
    //Adiciona a espécie de investimento 0-Todas.
    const espcAcaoTodos = new EspcAcao();
    espcAcaoTodos.cdEspcAcao = 0;
    espcAcaoTodos.nmEspcAcao = 'Todas';

    this.tipEspcList.unshift( espcAcaoTodos );
  }

  detail( event, cdInv: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    const queryParams = {
      cdInv: cdInv,
    };

    this.goToPage( '/inv-detail', queryParams, isOpenNewTab );
  }

  compareTickers( cdInv: number ){
    const queryParams = {
      cdInv: cdInv,
    };

    this.goToPage( '/inv-chart', queryParams, true );
  }

  public detailEmpr( event, cdEmpr: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    const queryParams = {
      cdEmpr: cdEmpr,
    };

    this.goToPage( '/empr-detail', queryParams, isOpenNewTab );
  }

  public detailSetorEcnm( event, cdSetorEcnm: number ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    this.goToPage( '/setor-detail', { 'cdSetorEcnm': cdSetorEcnm }, isOpenNewTab );
  }
}

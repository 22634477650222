export class Ettgst {
  public sglEttgst: string;
  public nmEttgst: string;
  public txDescrEttgst: string;
  public estrtgPend: number;
  public estrtgInv: number;
  public estrtgIns: number;
  public estrtgSuc: number;
  public qtdEstrtgTtl: number;
  public qtdEstrtgVld: number;
  public percPendTtl: number;
  public percInvTtl: number;
  public percInsTtl: number;
  public percSucTtl: number;
  public percInsVld: number;
  public percSucVld: number;
}


import { Component, OnInit                      } from '@angular/core';
import { DomSanitizer                           } from '@angular/platform-browser';
import { ActivatedRoute, Router                 } from '@angular/router';

import { FormBuilder } from '@angular/forms';

import { AbstractMainComponent  } from '../abstract-main.component';

import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService  } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService          } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { CrctInvDetail, CrctInv } from 'src/app/_models/inv';
import { ControlLoad } from 'src/app/_models/control/controlLoad';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class PageNotFoundComponent extends AbstractMainComponent{
  //Controls de cada seção da pagina.
  public _controlMain           : ControlLoad;

  public _form;

  private _crctInvFoundList: CrctInv[] = [];

  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService              ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    //Inicializa os controles.
    this._controlMain            = new ControlLoad( 'main' );

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitGeneral = this.initGeneral();
    promises.push( pInitGeneral );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    //Tentar identificar algum ativo com o nome do PATH da URL.
    const pInitSearchingCrctInvList = this.initSearchingCrctInvList();
    promises.push( pInitSearchingCrctInvList );

    this.monitoryInit( promises );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._form = _this.formBuilder.group(
        {  }
      );

      resolve();
    } );

    return p;
  }

  private initGeneral(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initSearchingCrctInvList(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Obtem o PATH da URL.
      const path = _this.router.url;

      //Quebra o PATH
      if( path != null && path.length > 0 ){
        const arrayOfPath = path.split( '/' );

        //Guarda as Promises de cada tentativa de identificar o ticker.
        let promisesSearch: Promise< any >[] = [];

        //Lista dos tickers identificados.
        const crctInvFoundList: CrctInv[] = [];

        arrayOfPath.forEach( element => {
          if( element != null && element.length > 0 ){
            //Verifica se ha algum ativo (ticker) com este nome.
            const pIterate = _this.invService.detailTicker( element );
            promisesSearch.push( pIterate );
          }
        } );

        const qtPromises   = promisesSearch.length;
        let promisesFinish = 0;

        //Monitora cada tentativa de busca pelo ticker.
        promisesSearch.forEach( promise => {
          promise
            .then( crctInvDetail => {
              if( crctInvDetail != null && crctInvDetail.crctInv != null && crctInvDetail.crctInv.cdInv != null ){
                crctInvFoundList.push( crctInvDetail.crctInv );
              }

              promisesFinish++;

              _this._controlMain.valueProgressBar = promisesFinish / qtPromises * 100;
            } )
            .catch( error => {
            } );
        } );

        //Monitora a finalizacao de todas as tarefas.
        Promise.all( promisesSearch )
          .then( response => {
            const qtCrctInvFound = crctInvFoundList.length;

            if( qtCrctInvFound > 0 ){
              _this._crctInvFoundList = crctInvFoundList;
            }

            _this._controlMain.loaded();
            resolve();
          } );
      }
    } );

    return p;
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    const _this = this;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          _this._controlMain.valueProgressBar = promisesFinish / qtPromises * 100;
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        _this._controlMain.loaded();
        const qtCrctInvFound = _this._crctInvFoundList != null ? _this._crctInvFoundList.length : 0;

        if( qtCrctInvFound > 0 ){
          if( qtCrctInvFound === 1 ){
            const crctInvFound = _this._crctInvFoundList[ 0 ];

            const cdInvFound = crctInvFound.cdInv;

            if( cdInvFound != null && cdInvFound > 0 ){
              _this.detailTicker( cdInvFound );
            }
          }
          else{

          }
        }
      } );
  }

  private detailTicker( cdInv: number ){
    this.goToPage( '/inv-detail', { 'cdInv': cdInv }, false );
  }

  private detailEmpr( cdEmpr: number ){
    this.goToPage( '/empr-detail', { 'cdEmpr': cdEmpr }, false );
  }
}

<!-- ================================= CONTENT ================================= -->

<form [formGroup]="this._carteiraForm">
	<mat-dialog-content>
		<div class="grid_container_carteira_dialog_edit_desktop">
			<div class="grid_item_carteira_dialog_edit_desktop_title">
				<h2 mat-dialog-title>Carteira</h2>
			</div>

			<div class="grid_item_carteira_dialog_edit_desktop_buttonClose">
				<button mat-icon-button color="accent" (click)="this.closeDialog()">
					<mat-icon class="material-icons-round" matTooltip="Fechar janela.">close</mat-icon>
				</button>
			</div>

			<div class="grid_item_carteira_dialog_edit_desktop_nmGr">
				<mat-form-field>
					<mat-label>Nome da Carteira:</mat-label>
					<input matInput (change)="onChangeNmGrCrctInv( $event.target.value )" formControlName="nmGrCrctInv">
					<mat-hint *ngIf="this.isNmGrCrctInvFilled() === false">Informe um nome para a sua carteira de ativos.</mat-hint>
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_dialog_edit_desktop_descrGr">
				<mat-label>Descrição da Carteira:</mat-label>
				<div *ngIf="this._isEditingTxDescrGrCrctInv == false" class="ql-editor" [innerHTML]="this.getTxDescrGrCrctInv()"></div>
				<quill-editor *ngIf="this._isEditingTxDescrGrCrctInv == true" theme="snow" formControlName="txDescrGrCrctInv" ></quill-editor>
			</div>

			<div class="grid_item_carteira_dialog_edit_desktop_descrGr_buttons">
				<button mat-icon-button *ngIf="this._isEditingTxDescrGrCrctInv == false" color="accent" (click)="this.onClickEditTxDescrGrCrctInv()">
					<mat-icon matTooltip="Editar descrição da Carteira.">edit</mat-icon>
				</button>

				<button mat-icon-button *ngIf="this._isEditingTxDescrGrCrctInv == true" color="accent" (click)="this.onClickCancelTxDescrGrCrctInv()">
					<mat-icon matTooltip="Cancelar alteração da descrição da Carteira.">cancel</mat-icon>
				</button>

				<button mat-icon-button *ngIf="this._isEditingTxDescrGrCrctInv == true" color="accent" (click)="this.onClickSaveTxDescrGrCrctInv()">
					<mat-icon matTooltip="Salvar descrição da Carteira.">save</mat-icon>
				</button>
			</div>

			<!-- Lista de tickers -->
			<div class="grid_item_carteira_dialog_edit_desktop_invList">
				<div>
					Ativos selecionados para compor a carteira:
				</div>

				<table mat-table [dataSource]="_invListDataSource">
					<ng-container matColumnDef="inv">
						<th mat-header-cell *matHeaderCellDef> Ticker </th>
						<td mat-cell *matCellDef="let element"> {{element.nmInv}} </td>
					</ng-container>

					<ng-container matColumnDef="tipInv">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.tipInv.nmTipInv}} </td>
					</ng-container>

					<ng-container matColumnDef="empr">
						<th mat-header-cell *matHeaderCellDef> Empresa </th>
						<td mat-cell *matCellDef="let element"> {{element.empr.nmAbrvEmpr}} </td>
					</ng-container>

					<ng-container matColumnDef="setor">
						<th mat-header-cell *matHeaderCellDef> Setor </th>
						<td mat-cell *matCellDef="let element"> {{element.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}} </td>
					</ng-container>

					<ng-container matColumnDef="options">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options">
								<button mat-icon-button color="accent" (click)="this.onRemoveTickerOfGrCrctInv( element )">
									<mat-icon matTooltip="Excluir ativo da carteira.">delete</mat-icon>
								</button>
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="this._invListDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: this._invListDisplayedColumns;"></tr>
				</table>

				<mat-paginator #invListPaginator [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
			</div>

			<div class="grid_item_carteira_dialog_edit_desktop_buttons">
				<button mat-icon-button color="accent" (click)="openDialogAddTicker()">
					<mat-icon matTooltip="Adicionar ativo à carteira.">playlist_add</mat-icon>
				</button>
			</div>
		</div>
	</mat-dialog-content>
</form>


import { LOCALE_ID, NgModule                    } from '@angular/core';
import { BrowserModule                          } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS    } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule       } from '@angular/forms';
import { registerLocaleData, DecimalPipe        } from '@angular/common';
import { MAT_DIALOG_DEFAULT_OPTIONS             } from '@angular/material/dialog';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DatePipe                               } from '@angular/common';

import localePt from '@angular/common/locales/pt';

/* ----------------- Biblioteca Material ----------------- */
import { MatButtonModule 		      } from '@angular/material/button';
import { MatCheckboxModule        } from '@angular/material/checkbox';
import { MatChipsModule           } from '@angular/material/chips';
import { MatDatepickerModule      } from '@angular/material/datepicker';
import { MatDialogModule          } from '@angular/material/dialog';
import { MatExpansionModule       } from '@angular/material/expansion';
import { MatFormFieldModule       } from '@angular/material/form-field';
import { MatIconModule 			      } from '@angular/material/icon';
import { MatInputModule 		      } from '@angular/material/input';
import { MatListModule 			      } from '@angular/material/list';
import { MatMenuModule            } from '@angular/material/menu';
import { MatNativeDateModule      } from '@angular/material/core';
import { MatPaginatorModule       } from '@angular/material/paginator';
import { MatProgressBarModule     } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule           } from '@angular/material/radio';
import { MatSelectModule 		      } from '@angular/material/select';
import { MatSidenavModule 	      } from '@angular/material/sidenav';
import { MatSnackBarModule        } from '@angular/material/snack-bar';
import { MatSortModule            } from '@angular/material/sort';
import { MatTableModule 		      } from '@angular/material/table';
import { MatToolbarModule 	      } from '@angular/material/toolbar';
import { MatTooltipModule         } from '@angular/material/tooltip';
import { MatTreeModule            } from '@angular/material/tree';


/* ----------------- Biblioteca Flex-Layout ----------------- */
import { FlexLayoutModule 		    } from '@angular/flex-layout';


/* ----------------- Biblioteca Google Charts ----------------- */
import { GoogleChartsModule 	    } from 'angular-google-charts';


/* ----------------- Biblioteca Quill - RichText ----------------- */
import { QuillModule  	          } from 'ngx-quill';


/* ----------------- Biblioteca NgX ----------------- */
/* Veja dicas de uso em https://angular-slider.github.io/ngx-slider/demos#range-slider */
import { NgxSliderModule } from '@angular-slider/ngx-slider';


import { AppRoutingModule         } from './app-routing.module';

import { BrowserAnimationsModule  } from '@angular/platform-browser/animations';


/* ----------------- Services ----------------- */
import { AplicationStateService } from './_services/aplication-state/aplication-state.service';
import { DateSimpleService      } from './_services/date-simple/date-simple.service';
import { DtqService             } from './_services/dtq/dtq.service';
import { EstrtgService          } from './_services/estrtg/estrtg.service';
import { EttgstService          } from './_services/ettgst/ettgst.service';
import { InfCrctInvService      } from './_services/infCrctInv/infCrctInv.service';
import { InvService             } from './_services/inv/inv.service';
import { PrmUsrService          } from './_services/prmUsr/prmUsr.service';
import { SearchGeneralService   } from './_services/search-general/search-general.service';
import { SessionService         } from './_services/session/session.service';


/* ----------------- Interceptors ----------------- */
import { ErrorInterceptor       } from './_helpers/error/error.interceptor';


/* ----------------- Componentes Gerais ----------------- */
import { AppComponent 					} from './_components/app/app.component';

/* ----------------- Componentes ----------------- */
import { CarteiraListDesktopComponent       } from '@components/carteira/list/desktop/carteira-list-desktop.component';
import { CarteiraListMobileComponent        } from '@components/carteira/list/mobile/carteira-list-mobile.component';
import { CarteiraMvtcDesktopComponent       } from '@components/carteira/mvtc/desktop/carteira-mvtc-desktop.component';
import { CarteiraMvtcMobileComponent        } from '@components/carteira/mvtc/mobile/carteira-mvtc-mobile.component';
import { DdcListDesktopComponent            } from '@components/ddc/list/desktop/ddc-list-desktop.component';
import { DdcListMobileComponent             } from '@components/ddc/list/mobile/ddc-list-mobile.component';
import { DestaquesListDesktopComponent      } from '@components/destaques/list/desktop/destaques-list-desktop.component';
import { DestaquesListMobileComponent       } from '@components/destaques/list/mobile/destaques-list-mobile.component';
import { EmprDetailDesktopComponent         } from '@components/empr/detail/desktop/empr-detail-desktop.component';
import { EmprDetailMobileComponent          } from '@components/empr/detail/mobile/empr-detail-mobile.component';
import { EstrtgPendDesktopComponent         } from '@components/estrtg/pend/desktop/estrtg-pend-desktop.component';
import { EstrtgPendMobileComponent          } from '@components/estrtg/pend/mobile/estrtg-pend-mobile.component';
import { EttgstListDesktopComponent         } from '@components/ettgst/list/desktop/ettgst-list-desktop.component';
import { EttgstListMobileComponent          } from '@components/ettgst/list/mobile/ettgst-list-mobile.component';
import { FooterDesktopComponent             } from '@components/footer/desktop/footer.desktop.component';
import { FooterMobileComponent 						  } from '@components/footer/mobile/footer.mobile.component';
import { HeaderDesktopComponent	 					  } from '@components/header/desktop/header.desktop.component';
import { HeaderMobileComponent	 					  } from '@components/header/mobile/header.mobile.component';
import { HomeDesktopComponent 						  } from '@components/home/desktop/home-desktop.component';
import { HomeMobileComponent 						    } from '@components/home/mobile/home-mobile.component';
import { InvListDesktopComponent            } from '@components/ticker/list/desktop/inv-list-desktop.component';
import { InvListMobileComponent             } from '@components/ticker/list/mobile/inv-list-mobile.component';
import { LoginAddDesktopComponent           } from '@components/login/add/desktop/login-add-desktop.component';
import { LoginAddMobileComponent            } from '@components/login/add/mobile/login-add-mobile.component';
import { LoginDesktopComponent              } from '@components/login/desktop/login-desktop.component';
import { LoginMobileComponent               } from '@components/login/mobile/login-mobile.component';
import { LoginForgotDesktopComponent        } from '@components/login/forgot/desktop/login-forgot-desktop.component';
import { LoginForgotMobileComponent         } from '@components/login/forgot/mobile/login-forgot-mobile.component';
import { LoginRecoverDesktopComponent       } from '@components/login/recover/desktop/login-recover-desktop.component';
import { LoginRecoverMobileComponent        } from '@components/login/recover/mobile/login-recover-mobile.component';
import { LogoutDesktopComponent             } from '@components/logout/desktop/logout-desktop.component';
import { LogoutMobileComponent              } from '@components/logout/mobile/logout-mobile.component';
import { PageNotFoundDesktopComponent       } from '@components/page-not-found/desktop/page-not-found.desktop.component';
import { SetorDetailDesktopComponent        } from '@components/setor/detail/desktop/setor-detail-desktop.component';
import { SetorDetailMobileComponent         } from '@components/setor/detail/mobile/setor-detail-mobile.component';
import { TickerChartDesktopComponent        } from '@components/ticker/chart/desktop/ticker-chart-desktop.component';
import { TickerChartMobileComponent         } from '@components/ticker/chart/mobile/ticker-chart-mobile.component';
import { TickerDetailDesktopComponent       } from '@components/ticker/detail/desktop/ticker-detail-desktop.component';
import { TickerDetailMobileComponent        } from '@components/ticker/detail/mobile/ticker-detail-mobile.component';


/* ----------------- Dialogs ----------------- */
import { CarteiraDialogAddDesktopComponent         } from '@components/carteira/dialog/add/desktop/carteira-dialog-add-desktop.component';
import { CarteiraDialogEditDesktopComponent        } from '@components/carteira/dialog/edit/desktop/carteira-dialog-edit-desktop.component';
import { CarteiraMvtcDialogAddDesktopComponent     } from '@components/carteira/mvtc/dialog/add/desktop/carteira-mvtc-dialog-add-desktop.component';
import { CarteiraMvtcDialogAddMobileComponent      } from '@components/carteira/mvtc/dialog/add/mobile/carteira-mvtc-dialog-add-mobile.component';
import { GeneralDialogConfirmDesktopComponent      } from '@components/general/dialog/confirm/desktop/general-dialog-confirm-desktop.component';
import { GeneralDialogInputMessageDesktopComponent } from '@components/general/dialog/inputMessage/desktop/general-dialog-inputMessage-desktop.component';
import { GeneralDialogInputMessageMobileComponent  } from '@components/general/dialog/inputMessage/mobile/general-dialog-inputMessage-mobile.component';
import { TickerDialogSelectDesktopComponent        } from '@components/ticker/dialog/select/desktop/ticker-dialog-select-desktop.component';
import { TickerDialogSelectMobileComponent         } from '@components/ticker/dialog/select/mobile/ticker-dialog-select-mobile.component';


/* ----------------- Pipes ----------------- */
import { CnpjNumberPipe                        } from './_pipes/cnpj-number/cnpj-number.pipe';
import { ShortNumberPipe                       } from './_pipes/short-number/short-number.pipe';


registerLocaleData( localePt );
@NgModule({
  declarations: [
    AppComponent                				,
    CarteiraListDesktopComponent   	 	  ,
    CarteiraListMobileComponent   	 	  ,
    CarteiraMvtcDesktopComponent   	 	  ,
    CarteiraMvtcMobileComponent   	 	  ,
    DdcListDesktopComponent             ,
    DdcListMobileComponent              ,
    DestaquesListDesktopComponent   		,
    DestaquesListMobileComponent   		  ,
    EmprDetailDesktopComponent   		    ,
    EmprDetailMobileComponent   		    ,
    EstrtgPendDesktopComponent          ,
    EstrtgPendMobileComponent           ,
    EttgstListDesktopComponent          ,
    EttgstListMobileComponent           ,
    FooterDesktopComponent      				,
    FooterMobileComponent       				,
    HeaderDesktopComponent      				,
    HeaderMobileComponent       				,
    HomeDesktopComponent        				,
    HomeMobileComponent        				  ,
    InvListDesktopComponent             ,
    InvListMobileComponent              ,
    LoginAddDesktopComponent   		      ,
    LoginAddMobileComponent   		      ,
    LoginDesktopComponent               ,
    LoginMobileComponent                ,
    LoginForgotDesktopComponent   		  ,
    LoginForgotMobileComponent   		    ,
    LoginRecoverDesktopComponent   		  ,
    LoginRecoverMobileComponent   		  ,
    LogoutDesktopComponent              ,
    LogoutMobileComponent               ,
    PageNotFoundDesktopComponent				,
    SetorDetailDesktopComponent   		  ,
    SetorDetailMobileComponent   		    ,
    TickerChartDesktopComponent         ,
    TickerChartMobileComponent          ,
    TickerDetailDesktopComponent        ,
    TickerDetailMobileComponent         ,

    CarteiraDialogAddDesktopComponent        ,
    CarteiraDialogEditDesktopComponent       ,
    CarteiraMvtcDialogAddDesktopComponent    ,
    CarteiraMvtcDialogAddMobileComponent     ,
    GeneralDialogInputMessageDesktopComponent,
    GeneralDialogInputMessageMobileComponent ,
    GeneralDialogConfirmDesktopComponent     ,
    TickerDialogSelectDesktopComponent       ,
    TickerDialogSelectMobileComponent        ,

    CnpjNumberPipe,
    ShortNumberPipe
  ],

  imports: [
    BrowserModule          	,
	  HttpClientModule		    ,
    AppRoutingModule       	,
    FormsModule             ,
    ReactiveFormsModule    	,
    BrowserAnimationsModule	,
    
    MatButtonModule        	,
    MatCheckboxModule       ,
    MatChipsModule          ,
    MatDatepickerModule     ,
    MatDialogModule         ,
    MatExpansionModule      ,
    MatFormFieldModule     	,
    MatIconModule          	,
    MatInputModule			    ,
	  MatListModule		      	,
	  MatMenuModule		      	,
    MatNativeDateModule     ,
    MatPaginatorModule		  ,
    MatProgressBarModule    ,
    MatProgressSpinnerModule,
    MatRadioModule          ,
    MatSelectModule        	,
    MatSidenavModule		    ,
    MatSnackBarModule		    ,
    MatSortModule           ,
    MatTableModule         	,
	  MatToolbarModule		    ,
    MatTooltipModule		    ,
    MatTreeModule           ,

    FlexLayoutModule        ,

    GoogleChartsModule.forRoot(),

    QuillModule.forRoot()       ,

    NgxSliderModule
  ],

  providers: [
    { provide: LocationStrategy	         , useClass: HashLocationStrategy           },
    { provide: LOCALE_ID                 , useValue: 'pt-PT'                        },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}           },
    { provide: HTTP_INTERCEPTORS         , useClass: ErrorInterceptor, multi: true  },
    { provide: DecimalPipe               , useClass: DecimalPipe                    },
    PrmUsrService    ,
    SessionService   ,
    DatePipe         ,
    CnpjNumberPipe,
    ShortNumberPipe
  ],

  bootstrap: [
    AppComponent
  ],

  entryComponents:[
    CarteiraListMobileComponent           ,
    CarteiraMvtcMobileComponent           ,
    DdcListMobileComponent                ,
    DestaquesListMobileComponent          ,
    EmprDetailMobileComponent             ,
    EstrtgPendDesktopComponent            ,
    EstrtgPendMobileComponent             ,
    EttgstListDesktopComponent            ,
    EttgstListMobileComponent             ,
    HomeDesktopComponent                  ,
    HomeMobileComponent                   ,
    InvListMobileComponent                ,
    LoginAddMobileComponent               ,
    LoginMobileComponent                  ,
    LoginForgotMobileComponent            ,
    LoginRecoverMobileComponent           ,
    LogoutMobileComponent                 ,
    SetorDetailMobileComponent            ,
    TickerDetailDesktopComponent          ,
    TickerDetailMobileComponent           ,
    TickerChartMobileComponent            ,

    CarteiraDialogAddDesktopComponent        ,
    CarteiraDialogEditDesktopComponent       ,
    CarteiraMvtcDialogAddDesktopComponent    ,
    CarteiraMvtcDialogAddMobileComponent     ,
    GeneralDialogConfirmDesktopComponent     ,
    GeneralDialogInputMessageDesktopComponent,
    GeneralDialogInputMessageMobileComponent ,
    TickerDialogSelectDesktopComponent       ,
    TickerDialogSelectMobileComponent
  ]
})
export class AppModule { }

import { Component, OnInit, ViewChild         } from '@angular/core';
import { DomSanitizer                         } from '@angular/platform-browser';
import { ActivatedRoute, Router               } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { MatDialog                            } from '@angular/material/dialog';

import { AplicationStateService } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService  } from '../../../_services/authentication/authentication.service';
import { AbstractMainComponent  } from '../../abstract-main.component';
import { UsrService             } from 'src/app/_services/usr/usr.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class LoginForgotComponent extends AbstractMainComponent{
  public _form;

  public _usernameForm : FormControl = new FormControl( '', [] );
  public _emailForm    : FormControl = new FormControl( '', [ Validators.email ] );

  constructor( protected router: Router,
               protected activatedRoute: ActivatedRoute,
               protected sanitizer: DomSanitizer,
               protected formBuilder: FormBuilder,
               protected dialog: MatDialog,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected usrService: UsrService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    this._form = this.createForm();
  }

  private createForm(){
    return this.formBuilder.group(
      {  }
    );
  }

  public getErrorMessageOfEmailForm() {
    if( this._emailForm.hasError( 'required' ) ){
      return 'Informe um e-mail.';
    }

    return this._emailForm.hasError( 'email' ) ? 'E-mail informado não é válido' : '';
  }

  onSubmit(){
    console.log( 'onSubmit()...' );

    console.log( 'onSubmit().' );
  }

  protected refresh(){
  }

  public onRecoverPassword(): void{
    const nmUsr     = this._usernameForm.value;
    const email     = this._emailForm.value;

    if( ( nmUsr != null && nmUsr.length > 0 ) || ( email != null && email.length > 0 ) ){
      if( this._usernameForm.valid || this._emailForm.valid ){
        this.usrService.recoverPassword( nmUsr, email )
          .then( response => {
            console.log( 'usrService.recoverPassword - response: ' + Object.keys( response ) );

            window.alert( 'Solicitado o envio de e-mail para recuperação da senha. Favor verificar o recebimento.' );
          } )
          .catch( error => {
            window.alert( error.msgErr );
          } );
      }
    }
    else{
      window.alert( 'Informe o nome de usuário ou e-mail.' );
    }
  }
}

<!-- ================================= CONTENT ================================= -->

<h2 mat-dialog-title>Esqueceu o Usuário e/ou Senha?</h2>

<form [formGroup]="this._form">
  <div class="grid_container_login">
    <div class="grid_item_login_header">
			Preencha um dos campos abaixo para receber um e-mail permitindo cadastrar uma nova senha.
    </div>

    <div class="grid_item_login_user">
			<mat-form-field>
				<mat-label>Usuário:</mat-label>
				<input matInput placeholder="Exemplo: joao1234" [formControl]="this._usernameForm">
				<mat-error *ngIf="this._usernameForm.invalid">{{this.getErrorMessageOfEmailForm()}}</mat-error>
			</mat-form-field>
    </div>

    <div class="grid_item_login_email">
			<mat-form-field>
				<mat-label>E-mail:</mat-label>
				<input matInput placeholder="Exemplo: joao1234@exemplo.com" [formControl]="this._emailForm">
				<mat-error *ngIf="this._emailForm.invalid">{{this.getErrorMessageOfEmailForm()}}</mat-error>
			</mat-form-field>
    </div>

    <div class="grid_item_login_button">
			<button (click)="this.onRecoverPassword()" class="button">Receber E-mail</button>
    </div>
  </div>
</form>
<!-- ================================= CONTENT ================================= -->

<form [formGroup]="this._carteiraForm">
	<mat-dialog-content>
		<div class="grid_container_carteira_dialog_add_desktop">
			<div class="grid_item_carteira_dialog_add_desktop_title">
				<h2 mat-dialog-title>Nova Carteira</h2>
			</div>

			<div class="grid_item_carteira_dialog_add_desktop_buttonClose">
				<button mat-icon-button color="accent" (click)="this.closeDialog( null )">
					<mat-icon class="material-icons-round" matTooltip="Fecha a janela, cancelando a criação da nova Carteira.">close</mat-icon>
				</button>
			</div>

			<div class="grid_item_carteira_dialog_add_desktop_nmGr">
				<mat-form-field>
					<mat-label>Nome da Carteira:</mat-label>
					<input matInput formControlName="nmGrCrctInv">
					<mat-hint *ngIf="this.isNmGrCrctInvFilled() === false">Informe um nome para a sua Carteira de ativos.</mat-hint>
				</mat-form-field>
			</div>

			<div class="grid_item_carteira_dialog_add_desktop_descrGr">
				<mat-label>Descrição da Carteira:</mat-label>
				<quill-editor theme="snow" formControlName="txDescrGrCrctInv" ></quill-editor>
			</div>

			<!-- Lista de tickers -->
			<div class="grid_item_carteira_dialog_add_desktop_invList">
				<div>
					Ativos selecionados para compor a Carteira:
				</div>

				<table mat-table [dataSource]="_invListDataSource">
					<ng-container matColumnDef="inv">
						<th mat-header-cell *matHeaderCellDef> Ticker </th>
						<td mat-cell *matCellDef="let element"> {{element.nmInv}} </td>
					</ng-container>

					<ng-container matColumnDef="tipInv">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.tipInv.nmTipInv}} </td>
					</ng-container>

					<ng-container matColumnDef="empr">
						<th mat-header-cell *matHeaderCellDef> Empresa </th>
						<td mat-cell *matCellDef="let element"> {{element.empr.nmAbrvEmpr}} </td>
					</ng-container>

					<ng-container matColumnDef="setor">
						<th mat-header-cell *matHeaderCellDef> Setor </th>
						<td mat-cell *matCellDef="let element"> {{element.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}} </td>
					</ng-container>

					<ng-container matColumnDef="options">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<span class="column_options">
								<button mat-icon-button color="accent" (click)="this.onRemoveTickerOfList( element )">
									<mat-icon matTooltip="Excluir ativo da carteira.">delete</mat-icon>
								</button>
							</span>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="this._invListDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: this._invListDisplayedColumns;"></tr>
				</table>

				<mat-paginator #invListPaginator [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
			</div>

			<div class="grid_item_carteira_dialog_add_desktop_buttons">
				<button mat-icon-button color="accent" (click)="openDialogAddTicker()">
					<mat-icon matTooltip="Adicionar ativo à carteira.">playlist_add</mat-icon>
				</button>
			</div>

			<div class="grid_item_carteira_dialog_add_desktop_buttonSave">
				<button mat-stroked-button class="link" matTooltip="Criar nova Carteira." (click)="onAddNewGrCrctInv()">
					Criar Nova Carteira
				</button>
			</div>
		</div>
	</mat-dialog-content>
</form>


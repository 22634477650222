import { MAT_DIALOG_DATA, MatDialog                          } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild     } from '@angular/core';
import { DomSanitizer                             } from '@angular/platform-browser';
import { ActivatedRoute                           } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl       } from '@angular/forms';

import { MatDialogRef                             } from '@angular/material/dialog';

import { AplicationStateService             } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PrmUsrService } from 'src/app/_services/prmUsr/prmUsr.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { CrctInv, CrctInvAcao } from 'src/app/_models/inv';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GrCrctInvService } from 'src/app/_services/grCrctInv/grCrctInv.service';
import { GrupoCrctInvAcao } from 'src/app/_models/grCrctInv/grCrctInv';
import { TickerDialogSelectDesktopComponent } from 'src/app/_components/ticker/dialog/select/desktop/ticker-dialog-select-desktop.component';
import { CarteiraDialogAddDesktopComponent } from './desktop/carteira-dialog-add-desktop.component';


export abstract class CarteiraDialogAddComponent implements OnInit {
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'CarteiraDialogEdit_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'CarteiraDialogEdit_paginatorPageIndex';

  //Lista de ativos da carteira.
  public _invListDisplayedColumns: string[] = [ 'inv', 'tipInv', 'empr', 'setor', 'options' ];
  public _invListDataSource: MatTableDataSource< CrctInv >;
  @ViewChild( 'invListPaginator', {static: true} ) paginator: MatPaginator;

  public _carteiraForm;

  public _paginatorPageSize;
  public _paginatorPageIndex;

  constructor( public dialogRef: MatDialogRef< CarteiraDialogAddDesktopComponent >,
               protected formBuilder           : FormBuilder            ,
               protected dialog                : MatDialog              ,
               protected aplicationStateService: AplicationStateService ,
               protected authenticationService : AuthenticationService  ,
               protected prmUsrService         : PrmUsrService          ,
               protected invService            : InvService             ,
               protected grCrctInvService      : GrCrctInvService ){
  }

  backdropClick(){
    console.log( 'backdropClick.' );
  }

  ngOnInit() {
    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    this.monitoryInit( promises );
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          //console.log( 'Finalizadas ' + promisesFinish + '/' + qtPromises + ' tarefas.' );
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._carteiraForm = _this.formBuilder.group(
        { nmGrCrctInv     : '',
          txDescrGrCrctInv: ''  }
      );

      resolve();
    });

    return p;
  }

  private getNmGrCrctInv(){
    return this._carteiraForm.controls[ 'nmGrCrctInv' ].value;
  }

  private setNmGrCrctInv( nmGrCrctInv: string ){
    this._carteiraForm.patchValue( { nmGrCrctInv: nmGrCrctInv } );
  }

  private getTxDescrGrCrctInv(){
    return this._carteiraForm.controls[ 'txDescrGrCrctInv' ].value;
  }

  private setTxDescrGrCrctInv( txDescrGrCrctInv: string ){
    this._carteiraForm.patchValue( { txDescrGrCrctInv: txDescrGrCrctInv } );
  }

  public isNmGrCrctInvFilled(): boolean{
    const nmGrCrctInv = this.getNmGrCrctInv();
    const isFilled = ( nmGrCrctInv && nmGrCrctInv != null && nmGrCrctInv.trim().length > 0 ) ? true : false

    return isFilled;
  }

  public isTxDescrGrCrctInvFilled(): boolean{
    const value = this.getTxDescrGrCrctInv()
    const isFilled = ( value && value != null && value.trim().length > 0 ) ? true : false

    return isFilled;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private detailCrctInv( cdInv: number ): Promise< CrctInv >{
    //console.log( 'detailCrctInv( ' + cdInv + ' )...' );

    const _this = this;

    const p = new Promise< CrctInv >( function( resolve, reject ){
      if( cdInv && cdInv != null ){
        _this.invService.detail( cdInv )
          .then( ticker => {
            if( ticker != null ){
              resolve( ticker.crctInv );
            }
            else{
              resolve( null );
            }
          } )
          .catch( error => {
            console.log( error );
            reject( error );
          } );
      }
      else{
        resolve( null );
      }
    } );

    return p;
  }

  onSubmit(){
    // stop here if form is invalid
    if( this._carteiraForm.invalid ){
      console.log( '_carteiraForm.invalid!' );

      return;
    }
    else{
      //const nmTipItemOrcm = this._tickerForm.controls.nmTipItemOrcm.value;

      //this.insertTio( nmTipItemOrcm );
    }
  }

  private addTicker( ticker: CrctInv, isUpdateCarteira: boolean ): void{
    this.addTickerList( [ ticker ], isUpdateCarteira );
  }

  private addTickerList( tickerList: CrctInv[], isUpdateCarteira: boolean ): void{
    if( this._invListDataSource == null ){
      this._invListDataSource = new MatTableDataSource< CrctInv >( [] );
      this._invListDataSource.paginator = this.paginator;
    }

    let data = this._invListDataSource.data;
    const crctInvToUpdateList: CrctInv[] = [];

    if( tickerList != null ){
      tickerList.forEach( ticker => {
        const indexOfTicker = this.getIndexOfTicker( ticker );

        if( indexOfTicker < 0 ){
          data.push( ticker );
          crctInvToUpdateList.push( ticker );
        }
      } );
    }

    this._invListDataSource.data = data;
  }

  public onRemoveTickerOfList( ticker: CrctInv ): void{
    //console.log( 'onRemoveTickerOfList( ' + ticker.cdInv + ' )...' );

    const indexOfTicker = this.getIndexOfTicker( ticker );

    if( indexOfTicker >= 0 ){
      let data = this._invListDataSource.data;
      data.splice( indexOfTicker, 1 );
      this._invListDataSource.data = data;
    }
  }

  public onAddNewGrCrctInv(): void{
    const nmGrCrctInv      = this.getNmGrCrctInv();
    const txDescrGrCrctInv = this.getTxDescrGrCrctInv();

    //console.log( 'nm/descr.: ' + nmGrCrctInv + '/' + txDescrGrCrctInv );

    if( nmGrCrctInv == null || nmGrCrctInv.trim().length == 0 ){
      window.alert( 'Nome da Carteira não informado.' );
    }
    else{
      if( txDescrGrCrctInv == null || txDescrGrCrctInv.trim().length == 0 ){
        window.alert( 'Descrição para a Carteira não informada.' );
      }
      else{
        this.addNewGrCrctInv( nmGrCrctInv, txDescrGrCrctInv );
      }
    }
  }

  private addNewGrCrctInv( nmGrCrctInv: string, txDescrGrCrctInv: string ): void{
    const cdUsr: number = this.authenticationService.getSessionService().currentUserValue != null ? this.authenticationService.getSessionService().currentUserValue.id : null;

    const _this = this;

    this.grCrctInvService.addGrupo( cdUsr, nmGrCrctInv, txDescrGrCrctInv )
      .then( grCrctInv => {
        const tickers: CrctInv[] = _this._invListDataSource.data;
        const cdGrCrctInv = grCrctInv.cdGrCrctInvAcao;

        if( tickers != null ){
          tickers.forEach( tickerIterate => {
            _this.grCrctInvService.addItem( cdGrCrctInv, tickerIterate.cdInv );
          } );
        }

        window.alert( 'Carteira criada.' );

        const grCrctInvAdded = new GrupoCrctInvAcao();
        grCrctInvAdded.cdGrCrctInvAcao      = cdGrCrctInv;
        grCrctInvAdded.nmGrCrctInvAcao      = nmGrCrctInv;
        grCrctInvAdded.txDescrGrCrctInvAcao = txDescrGrCrctInv;

        _this.closeDialog( grCrctInvAdded );
      } )
      .catch( error => {
        window.alert( error );
      } );
  }

  public getIndexOfTicker( ticker: CrctInv ){
    let indexFound = -1;

    if( this._invListDataSource != null ){
      const tickers = this._invListDataSource.data;
      const countTickers = tickers.length;

      for( let iTicker = 0; iTicker < countTickers; iTicker++ ){
          const tickerIterate: CrctInv = tickers[ iTicker ];

          if( ticker.cdInv === tickerIterate.cdInv ){
            indexFound = iTicker;
            break;
          }
      }
    }

    return indexFound;
  }

  public openDialogAddTicker(): void{
    const dialogRef = this.dialog.open( TickerDialogSelectDesktopComponent, { disableClose: false, width: '80%' } );

    const _this = this;

    dialogRef.afterClosed().subscribe( tickers => {
      _this.addTickerList( tickers, true );
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'backdropClick' );
    });
  }

  public closeDialog( grCrctInvAdded: GrupoCrctInvAcao ){
    this.dialogRef.close( grCrctInvAdded );
  }

  public changePageSize( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }
}

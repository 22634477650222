import { Injectable               } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpBasicService } from '../http-basic.service';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class SearchGeneralService extends HttpBasicService< any, any >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService ) {
    super( http, sessionService );
  }


  /**
   * Pesquisa lista de tickers que atendam aa string informada.
   */
  public async searchTickers( keySearch: string ){
    console.log( 'searchTickers()...' );

    return new Promise( resolve => { 
      this.searchTickersJson( keySearch )
      .then( ( data: any[] ) => {
        console.log( data );

        const resultList = new Array();

        for( const d of ( data as any ) ){
          const result = new Object();

          result[ 'cdInv' ]       = d[ 'cdInv' ];
          result[ 'nmInv' ]       = d[ 'nmInv' ];
          result[ 'cdTipInv' ]    = d[ 'cdTipInv' ];
          result[ 'cdEmpr' ]      = d[ 'cdEmpr' ];
          result[ 'nmAbrvEmpr' ]  = d[ 'nmAbrvEmpr' ];

          console.log( 'cdInv/nmInv/cdTipInv/cdEmpr/nmAbrvEmpr: ' + result[ 'cdInv' ] + ', ' + result[ 'nmInv' ] + ', ' + result[ 'cdTipInv' ] + ', ' + result[ 'cdEmpr' ] + ', ' + result[ 'nmAbrvEmpr' ] );

          resultList.push( result );
        }

        resolve( resultList );
      });
    }); 
  }


  /**
   * Pesquisa lista de tickers que atendam aa string informada.
   */
  public async searchEmpresas( keySearch: string ){
    return new Promise( resolve => { 
      this.searchEmpresasJson( keySearch )
      .then( ( data: any[] ) => {
        const resultList = new Array();

        for( const d of ( data as any ) ){
          const result = new Object();

          result[ 'cdEmpr' ]     = d[ 'cdEmpr' ];
          result[ 'nmEmpr' ]     = d[ 'nmEmpr' ];
          result[ 'nmAbrvEmpr' ] = d[ 'nmAbrvEmpr' ];

          resultList.push( result );
        }

        resolve( resultList );
      });
    }); 
  }

  private searchTickersJson( keySearch: string ){
    const SERVICE = '/SEARCH/TICK?keySearch=' + keySearch;

    return this.execServiceJson( SERVICE );
  }

  private searchEmpresasJson( keySearch: string ){
    const SERVICE = '/SEARCH/EMPR?keySearch=' + keySearch;

    return this.execServiceJson( SERVICE );
  }

  private execServiceJson( serviceStr: string ){
    const protocolOfPage = window.location.protocol;

    const SERVICE_URL = protocolOfPage + '//invest-rapido-services-project.uc.r.appspot.com' + serviceStr;

    return new Promise( resolve => {
      this.http.get( SERVICE_URL )
        .subscribe( data => {
          resolve( data );
        } );
    });
  }
}
import { HstCotcAcao } from '../cotc';
import { TipInfCrctInv, BasicInfCrctInvAbstract, InfCrctInvInterface, TipViewInfCrctInv } from './infCrctInv';
import { CotcService } from 'src/app/_services/cotc/cotc.service';
import { CrctInv } from '../inv';

export class InfCrctInvCotc extends BasicInfCrctInvAbstract< HstCotcAcao >{
	private cotcService: CotcService;

	constructor( ticker: CrctInv, cotcService: CotcService ){
		super( ticker );

		this.cotcService = cotcService;
	}

	public copy(): InfCrctInvInterface< HstCotcAcao >{
		let copyObj = new InfCrctInvCotc( super.getTicker(), this.cotcService );
		copyObj.setDtFromValues( this.getDtFromValues() );
		copyObj.setDtEndValues( this.getDtEndValues() );
		copyObj.setQtLimitValues( this.getQtLimitValues() );
		copyObj.setTipViewSelectedInfCrctInv( this.getTipViewSelectedInfCrctInv() );

		return copyObj;
	}

	public isEqualCotc( infCrctInv: InfCrctInvCotc ): boolean{
		if( infCrctInv !== null && infCrctInv instanceof InfCrctInvCotc ){
			return true;
		}
		else{
			return false;
		}
	}

	public getNmInfCrctInv(){
		let nmInf = 'Cotação';

		return nmInf;
	}

	public getNmInfCrctInvViewed(){
		let nmInf = this.getNmInfCrctInv();

		const tipView = this.getTipViewSelectedInfCrctInv();

		if( tipView !== null ){
			nmInf += ' - ' + tipView.toString();
		}

		return nmInf;
	}

	public getTipInfCrctInv(): TipInfCrctInv{
		return TipInfCrctInv.COTC;
	}

	public getPerdcInfCrctInv(): number{
		return null;
	}

	public setPerdcListInfCrctInv( perdcList: number[] ): void{
	}

	public addPerdcListInfCrctInv( perdc: number ): void{
	}

	public getPerdcListInfCrctInv(): number[]{
		return [];
	}

	public getPerdcSelectedInfCrctInv(): number{
		return null;
	}

	public setPerdcSelectedInfCrctInv( perdc: number ): void{
	}

	public getDateRefOfValueOfIndex( index: number ): Date{
		const value: HstCotcAcao = this.getValueOfIndex( index );

		return value != null ? value.dtCotc : null;
	}

	public nextValueOfPointerIfEqualDateRef( dateRef: Date ): HstCotcAcao{
		const maxIndexOfPointer = super.getQtValues();
		const indexOfPointer = super.getIndexOfPointer();

		if( indexOfPointer < maxIndexOfPointer ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer + 1 );
			const dateOfValue    = valueInPointer.dtCotc;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue != null && dateRef != null && dateOfValue.getTime() === dateRef.getTime() ) ){
				return super.nextValueOfPointer();
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public getValueOfPointerIfEqualDateRefAndMovePointerToNext( dateRef: Date ): HstCotcAcao{
		const maxIndexOfPointer = super.getQtValues();
		const indexOfPointer = super.getIndexOfPointer();

		if( indexOfPointer <= maxIndexOfPointer ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer );
			const dateOfValue    = valueInPointer.dtCotc;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue != null && dateRef != null && dateOfValue.getTime() === dateRef.getTime() ) ){
				super.incrPointer( false );
				return valueInPointer;
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public prevValueOfPointerIfEqualDateRef( dateRef: Date ): HstCotcAcao{
		const indexOfPointer = super.getIndexOfPointer();

		if( indexOfPointer > 0 ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer - 1 );
			const dateOfValue    = valueInPointer.dtCotc;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue != null && dateRef != null && dateOfValue.getTime() === dateRef.getTime() ) ){
				return super.prevValueOfPointer();
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public getValueOfPointerIfEqualDateRefAndMovePointerToPrev( dateRef: Date ): HstCotcAcao{
		const indexOfPointer = super.getIndexOfPointer();

		if( indexOfPointer >= 0 ){
			const valueInPointer = super.getValueOfIndex( indexOfPointer );
			const dateOfValue    = valueInPointer != null ? valueInPointer.dtCotc : null;

			if( ( dateOfValue === null && dateRef === null ) || 
				( dateOfValue !== null && dateRef !== null && dateOfValue.getTime() === dateRef.getTime() ) ){
				super.decrPointer( false );
				return valueInPointer;
			}
			else{
				return null;
			}
		}
		else{
			return null;
		}
	}

	public refreshValues(): Promise< HstCotcAcao[] >{
		super.setValues( null );

		const ticker 		= super.getTicker();
		const dtFromValues 	= super.getDtFromValues();
		const dtEndValues 	= super.getDtEndValues();
		const qtLimitValues = super.getQtLimitValues();

		const _this = this;

		return new Promise( function( resolve, reject ){
			_this.cotcService.listBetweenDatesWithLimit( ticker.cdInv, dtFromValues, dtEndValues, qtLimitValues, null )
				.then( cotcAcaoList => {
					cotcAcaoList.forEach( ( cotc, indexCotc ) => {
						if( cotc != null && ( typeof cotc.dtCotc === 'string' || cotc.dtCotc instanceof String ) ){
							cotcAcaoList[indexCotc].dtCotc = new Date( cotc.dtCotc );
						}
					} );

					_this.setValues( cotcAcaoList );
					resolve( cotcAcaoList );
				})
				.catch( error => {
					reject( error );
				})
			});
	}
}

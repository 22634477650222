import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Router                         } from '@angular/router';
import { EstrtgPendComponent            } from '../estrtg-pend.component';
import { FormBuilder                    } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { MatDialog } from '@angular/material/dialog';

import { EstrtgService                  } from '@services/estrtg/estrtg.service';
import { AplicationStateService } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { EttgstService } from '@services/ettgst/ettgst.service';

@Component({
  selector: 'app-estrtg-pend-desktop',
  templateUrl: './estrtg-pend-desktop.component.html',
  styleUrls: ['./estrtg-pend-desktop.component.css']
})
export class EstrtgPendDesktopComponent extends EstrtgPendComponent {
  constructor( protected router               : Router                ,
               protected formBuilder          : FormBuilder           ,
               protected dialog               : MatDialog             ,
               sanitizer                      : DomSanitizer          ,
               aplicationStateService         : AplicationStateService,
               protected authenticationService: AuthenticationService ,
               protected snackBar             : MatSnackBar           ,
               protected estrtgService        : EstrtgService         ,
               protected ettgstService        : EttgstService ) {
    super( router, formBuilder, dialog, sanitizer, aplicationStateService, authenticationService, snackBar, estrtgService, ettgstService );
  }
}

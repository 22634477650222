import { Component, OnInit, ViewChild   } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { EttgstListComponent } from '../ettgst-list.component';

import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { EttgstService } from 'src/app/_services/ettgst/ettgst.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-ettgst-list-desktop',
  templateUrl: './ettgst-list-desktop.component.html',
  styleUrls: ['./ettgst-list-desktop.component.css']
})
export class EttgstListDesktopComponent extends EttgstListComponent {
  constructor( protected router: Router                                ,
               protected formBuilder: FormBuilder                      ,
               protected dialog                : MatDialog             ,
               sanitizer: DomSanitizer                                 ,
               aplicationStateService          : AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected ettgstService         : EttgstService ) { 
    super( router, formBuilder, dialog, sanitizer, aplicationStateService, authenticationService, snackBar, ettgstService );
  }

  ngOnInit() {
    console.log( 'MarcaListDesktopComponent.ngOnInit()...' );
    super.ngOnInit();

    console.log( 'MarcaListDesktopComponent.ngOnInit().' );
  }
}

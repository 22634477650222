import { Component, OnInit, ViewChild         } from '@angular/core';
import { DomSanitizer                         } from '@angular/platform-browser';
import { ActivatedRoute, Router               } from '@angular/router';
import { FormBuilder, FormControl             } from '@angular/forms';

import { DatePipe, DecimalPipe                } from '@angular/common';

import { MatDialog                            } from '@angular/material/dialog';
import { MatTableDataSource                   } from '@angular/material/table';
import { MatPaginator                         } from '@angular/material/paginator';

import { AbstractMainComponent                } from '../../abstract-main.component';

import { AplicationStateService               } from '../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService                } from '../../../_services/authentication/authentication.service';
import { InvService                           } from '../../../_services/inv/inv.service';
import { PrmUsrService                        } from '../../../_services/prmUsr/prmUsr.service';
import { GrCrctInvService                     } from 'src/app/_services/grCrctInv/grCrctInv.service';

import { ShortNumberPipe                      } from '../../../_pipes/short-number/short-number.pipe';

import { GrupoCrctInvAcao                     } from 'src/app/_models/grCrctInv/grCrctInv';
import { ControlLoad                          } from 'src/app/_models/control/controlLoad';

import { CarteiraDialogEditDesktopComponent   } from '../dialog/edit/desktop/carteira-dialog-edit-desktop.component';
import { CarteiraDialogAddDesktopComponent } from '../dialog/add/desktop/carteira-dialog-add-desktop.component';
import { GeneralDialogConfirmDesktopComponent } from '../../general/dialog/confirm/desktop/general-dialog-confirm-desktop.component';
import { ConfirmDataParm } from '../../general/dialog/confirm/models/confirmDataParm';
import { MatSnackBar } from '@angular/material/snack-bar';

export abstract class CarteiraListComponent extends AbstractMainComponent{
  //Parametros do usuario utilizados pelo componente.
  private PRM_USR_PAGINATOR_PAGE_SIZE  = 'CarteiraList_paginatorPageSize';
  private PRM_USR_PAGINATOR_PAGE_INDEX = 'CarteiraList_paginatorPageIndex';

  //Controls de cada seção da pagina.
  public _controlMain           : ControlLoad;
  public _controlMinhasCarteiras: ControlLoad;
  public _controlCompt          : ControlLoad;

  //Lista das Carteiras vinculadas ao usuario.
  public _grCrctInvAcaoListDisplayedColumns: string[] = [ 'nm', 'txDescr', 'sdoGr', 'options' ];
  public _grCrctInvAcaoListDataSource      : MatTableDataSource< GrupoCrctInvAcao >;
  @ViewChild( MatPaginator, {static: true} ) _grCrctInvAcaoListPaginator: MatPaginator;
  public _grCrctInvAcaoListPaginatorPageSize  = 20;
  public _grCrctInvAcaoListPaginatorPageIndex = 1;

  //Lista das Carteiras compartilhadas com o usuario.
  public _comptGrCrctInvAcaoListDisplayedColumns: string[] = [ 'nmGrCompt', 'sdoGrCompt', 'optionsGrCompt' ];
  public _comptGrCrctInvAcaoListDataSource      : MatTableDataSource< GrupoCrctInvAcao >;
  @ViewChild( MatPaginator, {static: true} ) _comptGrCrctInvAcaoListPaginator: MatPaginator;
  public _comptGrCrctInvAcaoListPaginatorPageSize  = 20;
  public _comptGrCrctInvAcaoListPaginatorPageIndex = 1;

  public _form;

  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected datepipe              : DatePipe              ,
               protected decimalPipe           : DecimalPipe           ,
               protected shortNumberPipe       : ShortNumberPipe       ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService            ,
               protected grCrctInvService      : GrCrctInvService ){
    super( router, aplicationStateService, snackBar );
  }

  ngOnInit() {
    this._controlMain            = new ControlLoad( 'main' );
    this._controlMinhasCarteiras = new ControlLoad( 'minhasCarteiras' );
    this._controlCompt           = new ControlLoad( 'compt' );

    this._controlMain.initializingLoad();

    //Cada tarefa deve retornar um Promise, os quais serao monitorados.
    let promises  : Promise< any >[] = [];

    const pCreateForm = this.createForm();
    promises.push( pCreateForm );

    const pInitGeneral = this.initGeneral();
    promises.push( pInitGeneral );

    const pInitPrmUsr = this.initPrmUsr();
    promises.push( pInitPrmUsr );

    const pInitParm = this.initParm();
    promises.push( pInitParm );

    //Busca lista das carteiras vinculadas ao usuario.
    const pInitGrCrctInvList = this.initGrCrctInvList();
    promises.push( pInitGrCrctInvList );

    //
    const pInitFilters = this.initFilters();
    promises.push( pInitFilters );

    const pInitPanels = this.initPanels();
    promises.push( pInitPanels );

    this.monitoryInit( promises );
  }

  private monitoryInit( promises: Promise< any >[] ){
    const qtPromises = promises.length;
    let promisesFinish = 0;

    const _this = this;

    promises.forEach( promise => {
      promise
        .then( response => {
          promisesFinish++;

          _this._controlMain.valueProgressBar = promisesFinish / qtPromises * 100;
        } );
    } );

    //Monitora a finalizacao de todas as tarefas.
    Promise.all( promises )
      .then( response => {
        _this._controlMain.loaded();
        //console.log( 'Todas tarefas da inicialização foram finalizadas.' );
      } );
  }

  private createForm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this._form = _this.formBuilder.group(
        { isShowGrCrctInvAll    : false,
          isShowGrCrctInvWithSdo: true }
      );

      resolve();
    } );

    return p;
  }

  private isShowGrCrctInvAll(){
    return this._form.controls[ 'isShowGrCrctInvAll' ].value;
  }

  private setShowGrCrctInvAll( isShowGrCrctInvAll: boolean ){
    this._form.patchValue( { 'isShowGrCrctInvAll': isShowGrCrctInvAll } );
  }

  private isShowGrCrctInvWithSdo(){
    return this._form.controls[ 'isShowGrCrctInvWithSdo' ].value;
  }

  private setShowGrCrctInvWithSdo( isShowGrCrctInvWithSdo: boolean ){
    this._form.patchValue( { 'isShowGrCrctInvWithSdo': isShowGrCrctInvWithSdo } );
  }

  private initGeneral(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initPrmUsr(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      //Recupera os ativos que estao salvos como prmUsr.
      const paginatorPageSizeOfPrmUsr  = _this.prmUsrService.getValueInt( _this.PRM_USR_PAGINATOR_PAGE_SIZE  );
      const paginatorPageIndexOfPrmUsr = _this.prmUsrService.getValueInt( _this.PRM_USR_PAGINATOR_PAGE_INDEX );

      if( paginatorPageSizeOfPrmUsr != null ){
        _this._grCrctInvAcaoListPaginatorPageSize = paginatorPageSizeOfPrmUsr;
      }

      if( paginatorPageIndexOfPrmUsr != null ){
        _this._grCrctInvAcaoListPaginatorPageIndex = paginatorPageIndexOfPrmUsr;
      }

      resolve();
    } );

    return p;
  }

  private initParm(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initGrCrctInvList(): Promise< any >{
    const _this = this;

    const pLoadGrCrctInvList = this.loadGrCrctInvList();

    pLoadGrCrctInvList
      .then( resp => {
        _this.execFilterGrCrctInvAcaoList();
      } );

    return pLoadGrCrctInvList;
  }

  private initFilters(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  private initPanels(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      resolve();
    } );

    return p;
  }

  onSubmit(){
  }

  public onLoadGrCrctInvList(): void{
    if( this.isShowGrCrctInvAll() === false ){
      let grCrctInvAcaoList = this._grCrctInvAcaoListDataSource != null ? this._grCrctInvAcaoListDataSource.data : null;
      const qtGrCrctInvAcao   = grCrctInvAcaoList != null ? grCrctInvAcaoList.length : 0;

      for( let indexGrCrctInvAcao = qtGrCrctInvAcao-1; indexGrCrctInvAcao >= 0; indexGrCrctInvAcao-- ){
        const grCrctInvAcaoIterated = grCrctInvAcaoList[ indexGrCrctInvAcao ];

        if( grCrctInvAcaoIterated.inGrCrctInvAcaoAtivo == false ){
          grCrctInvAcaoList.splice( indexGrCrctInvAcao, 1 );
        }
      }

      this._grCrctInvAcaoListDataSource.data = grCrctInvAcaoList;
    }
    else{
      this.loadGrCrctInvList();
    }
  }

  private loadGrCrctInvList(): Promise< any >{
    console.log( 'loadGrCrctInvList()...' );

    this._controlMinhasCarteiras.initializingLoad();

    const _this = this;

    const isShowGrCrctInvAll = this.isShowGrCrctInvAll();

    if( this._grCrctInvAcaoListDataSource == null ){
      this._grCrctInvAcaoListDataSource = new MatTableDataSource< GrupoCrctInvAcao >( [] );
      this._grCrctInvAcaoListDataSource.paginator = this._grCrctInvAcaoListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      //Obtem a lista de Carteiras.
      _this.grCrctInvService.listGrupos( !isShowGrCrctInvAll, false, false, false, false, false, false )
        .then( grCrctInvList => {
          //_this._grCrctInvAcaoListDataSource.data = grCrctInvList;
          _this._grCrctInvAcaoListDataSource.data      = grCrctInvList;
          _this._grCrctInvAcaoListDataSource.paginator = _this._grCrctInvAcaoListPaginator;

          _this._grCrctInvAcaoListDataSource.filterPredicate = ( d: GrupoCrctInvAcao, filter: string) => {
            try{
              //console.log( '_grCrctInvAcaoListDataSource.filterPredicate...' );

              const isShowGrCrctInvAcaoWithSdo = _this.isShowGrCrctInvWithSdo();

              const vlSdo = d.vlSdoInvt;

              return ( isShowGrCrctInvAcaoWithSdo === false ) || 
                     ( isShowGrCrctInvAcaoWithSdo === true && vlSdo != null && vlSdo != 0 );
            }
            catch( error ){
              console.error( error );
              return false;
            }
          };
        } )
        .catch( errListGr => {
          console.error( errListGr );
        } )
        .finally( () => {
          _this._controlMinhasCarteiras.loaded();

          resolve();
        } );
    } );

    return p;
  }

  public onLoadComptGrCrctInvAcaoList(): void{
    if( this._controlCompt.isCanShow === true ){
      this.loadComptGrCrctInvAcaoList();
    }
  }

  private loadComptGrCrctInvAcaoList(): Promise< any >{
    console.log( 'loadComptGrCrctInvAcaoList()...' );

    this._controlCompt.initializingLoad();

    const _this = this;

    if( this._comptGrCrctInvAcaoListDataSource == null ){
      this._comptGrCrctInvAcaoListDataSource = new MatTableDataSource< GrupoCrctInvAcao >( [] );
      this._comptGrCrctInvAcaoListDataSource.paginator = this._comptGrCrctInvAcaoListPaginator;
    }

    const p = new Promise( function( resolve, reject ){
      //Obtem a lista de Carteiras.
      _this.grCrctInvService.listGruposCompt()
        .then( comptGrCrctInvList => {
          _this._comptGrCrctInvAcaoListDataSource.data      = comptGrCrctInvList;
          _this._comptGrCrctInvAcaoListDataSource.paginator = _this._comptGrCrctInvAcaoListPaginator;
        } )
        .catch( errListGr => {
          console.error( errListGr );
        } )
        .finally( () => {
          _this._controlCompt.loaded();

          resolve();
        } );
    } );

    return p;
  }


  private reloadGrCrctInvAcaoAdded( grCrctInvAcaoUpdated: GrupoCrctInvAcao ): void{
    this.reloadGrCrctInvAcao( grCrctInvAcaoUpdated, true );
  }


  private reloadGrCrctInvAcaoRemoved( grCrctInvAcaoUpdated: GrupoCrctInvAcao ): void{
    this.reloadGrCrctInvAcao( grCrctInvAcaoUpdated, false );
  }


  private reloadGrCrctInvAcao( grCrctInvAcaoUpdated: GrupoCrctInvAcao, isInclude: boolean ): void{
    let grCrctInvAcaoList = this._grCrctInvAcaoListDataSource != null ? this._grCrctInvAcaoListDataSource.data : null;
    const qtGrCrctInvAcao   = grCrctInvAcaoList != null ? grCrctInvAcaoList.length : 0;

    for( let indexGrCrctInvAcao = 0; indexGrCrctInvAcao < qtGrCrctInvAcao; indexGrCrctInvAcao++ ){
      const grCrctInvAcaoIterated = grCrctInvAcaoList[ indexGrCrctInvAcao ];

      if( grCrctInvAcaoIterated.cdGrCrctInvAcao === grCrctInvAcaoUpdated.cdGrCrctInvAcao ){
        if( isInclude === true ){
          grCrctInvAcaoList.splice( indexGrCrctInvAcao, 1, grCrctInvAcaoUpdated );
        }
        else{
          grCrctInvAcaoList.splice( indexGrCrctInvAcao, 1 );
        }

        break;
      }
    }

    this._grCrctInvAcaoListDataSource.data = grCrctInvAcaoList;
  }


  private addGrCrctInvAcao( grCrctInvAcaoAdded: GrupoCrctInvAcao ): void{
    //console.log( 'addGrCrctInvAcao( ' + grCrctInvAcaoAdded.cdGrCrctInvAcao + '/' + grCrctInvAcaoAdded.nmGrCrctInvAcao + '/' + grCrctInvAcaoAdded.txDescrGrCrctInvAcao + ' )...' );

    const grCrctInvAcaoList = this._grCrctInvAcaoListDataSource != null ? this._grCrctInvAcaoListDataSource.data : [];

    grCrctInvAcaoList.push( grCrctInvAcaoAdded );

    this._grCrctInvAcaoListDataSource.data = grCrctInvAcaoList;
  }

  public onClickShowGrWithSdo(): void{
    this.execFilterGrCrctInvAcaoList();
  }

  private execFilterGrCrctInvAcaoList(): void{
    if( this._grCrctInvAcaoListDataSource.data !=null ){
      this._grCrctInvAcaoListDataSource.filter = 'execFilterGrCrctInvAcaoList';
    }
  }

  public openDialogEditGrCrctInv( grCrctInvAcao: GrupoCrctInvAcao ): void{
    const cdGrCrctInvSelected = grCrctInvAcao.cdGrCrctInvAcao;

    //console.log( 'Informando a carteira ' + cdGrCrctInvSelected );

    const dialogRef = this.dialog.open( CarteiraDialogEditDesktopComponent, { disableClose: false, width: '80%', data: { cdGrCrctInv: cdGrCrctInvSelected } } );

    const _this = this;

    dialogRef.afterClosed().subscribe( grCrctInvAcaoEdited => {
      _this._controlMinhasCarteiras.initializingLoad();

      if( grCrctInvAcaoEdited != null && grCrctInvAcaoEdited.cdGrCrctInvAcao != null ){
        _this.reloadGrCrctInvAcaoAdded( grCrctInvAcaoEdited );
      }

      _this._controlMinhasCarteiras.loaded();
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'editGrCrctInv - backdropClick' );
    });
  }

  public openMvtcGrCrctInv( event, grCrctInvAcaoSelected: GrupoCrctInvAcao ): void{
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    const queryParams = {
      cdGrCrctInvAcao: grCrctInvAcaoSelected.cdGrCrctInvAcao,
    };

    this.goToPage( '/gcia-mvtc', queryParams, isOpenNewTab );
  }

  public openMvtcComptGrCrctInv( event, grCrctInvAcaoSelected: GrupoCrctInvAcao ): void{
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    const queryParams = {
      cdGrCrctInvAcao: grCrctInvAcaoSelected.cdGrCrctInvAcao,
      cdUsrCompt     : this.authenticationService.getSessionService().currentUserValue.id
    };

    this.goToPage( '/gcia-mvtc', queryParams, isOpenNewTab );
  }

  public openDialogAddGrCrctInv(): void{
    const dialogRef = this.dialog.open( CarteiraDialogAddDesktopComponent, { disableClose: false, width: '80%' } );

    const _this = this;

    dialogRef.afterClosed().subscribe( grCrctInvAdded => {
      //console.log( 'grCrctInvAdded: ' + grCrctInvAdded );

      if( grCrctInvAdded != null ){
        _this.addGrCrctInvAcao( grCrctInvAdded );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'addGrCrctInv - backdropClick' );
    });
  }

  public onChangeGrCrctInvAcaoListPage( event ){
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_SIZE , event.pageSize );
    this.prmUsrService.setValue( this.PRM_USR_PAGINATOR_PAGE_INDEX, event.pageIndex );
  }

  public compareTickers( event, grCrctInvAcaoSelected: GrupoCrctInvAcao ){
    //Abre nova aba, caso o usuario tenha pressionado CTRL.
    const isOpenNewTab = event.ctrlKey;

    const queryParams = {
      cdGrCrctInvAcao: grCrctInvAcaoSelected.cdGrCrctInvAcao,
    };

    this.goToPage( '/inv-chart', queryParams, isOpenNewTab );
  }

  public onDeleteGrCrctInvAcao( grCrctInvAcaoSelected: GrupoCrctInvAcao ){
    const dataParm: ConfirmDataParm = new ConfirmDataParm();
    dataParm.isReturnMsgObs = false;
    dataParm.msgConfirm = 'Confirma a exclusão da Carteira <b><u>' + grCrctInvAcaoSelected.nmGrCrctInvAcao + '</u></b>?';

    const dialogRef = this.dialog.open( GeneralDialogConfirmDesktopComponent, { disableClose: false, width: '50%', data: dataParm } );

    const _this = this;

    dialogRef.afterClosed().subscribe( returnDataParm => {
      const isConfirm = returnDataParm.isConfirm;

      if( isConfirm != null && isConfirm === true ){
        _this.deleteGrCrctInvAcao( grCrctInvAcaoSelected );
      }
    } );

    dialogRef.backdropClick().subscribe( result => {
      console.log( 'addGrCrctInv - backdropClick' );
    });
  }

  private deleteGrCrctInvAcao( grCrctInvAcao: GrupoCrctInvAcao ): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this.grCrctInvService.removeGrupo( grCrctInvAcao.cdGrCrctInvAcao )
        .then( response => {
          _this.reloadGrCrctInvAcaoRemoved( grCrctInvAcao );

          window.alert( 'Carteira \"' + grCrctInvAcao.nmGrCrctInvAcao + '\" excluída com sucesso.' );

          resolve();
        } )
        .catch( error => {
          console.error( error );

          reject();
        } );
    } );

    return p;
  }

  public onReactivateGrCrctInvAcao( grCrctInvAcao: GrupoCrctInvAcao ){
    this.reactivateGrCrctInvAcao( grCrctInvAcao );
  }

  private reactivateGrCrctInvAcao( grCrctInvAcao: GrupoCrctInvAcao ): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
      _this.grCrctInvService.reactivateGrupo( grCrctInvAcao.cdGrCrctInvAcao )
        .then( response => {
          grCrctInvAcao.inGrCrctInvAcaoAtivo = true;

          window.alert( 'Carteira \"' + grCrctInvAcao.nmGrCrctInvAcao + '\" reativada com sucesso.' );

          resolve();
        } )
        .catch( error => {
          console.error( error );

          reject();
        } );
    } );

    return p;
  }


  public onOpenedPanelComptGrCrctInvAcaoList(): void{
    const controlLoad = this._controlCompt;

    controlLoad.isCanShow = true;

    if( controlLoad.isDataLoaded === false ){
      this.onLoadComptGrCrctInvAcaoList();
    }
  }


  public onClosedPanelComptGrCrctInvAcaoList(): void{
    this._controlCompt.isCanShow    = false;
    this._controlCompt.isDataLoaded = false;
  }
}
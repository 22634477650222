import { SegmEcnm } from './setorEcnm';
import { RstdEmprJoinInfRstdEmpr, TipInfRstdEmprJoinInfRstdEmpr } from './rstdEmpr';
import { SetorAtvdd } from './setorAtvdd';
import { CrctInv } from './inv';
import { HstCotcAcao } from './cotc';

export class Empr {
	cdEmpr        : number;
	nmEmpr        : string;
	nmAbrvEmpr    : string;
	cnpj   	      : number;
	dtConstEmpr   : Date;
	tipSitEmpr    : TipSitEmpr;
	segmEcnm      : SegmEcnm;
	rstdEmpr      : RstdEmprJoinInfRstdEmpr;
	setorAtvdd    : SetorAtvdd;
	infComplEmpr  : InfComplEmprList;
	invList       : CrctInv[];
	tipInfRstdList: TipInfRstdEmprJoinInfRstdEmpr[];
}

export class TipSitEmpr {
	sglTipSitEmpr  : string;
	nmTipSitEmpr   : string;
	descrTipSitEmpr: string;
}

export class InfComplEmpr{
	empr: Empr;
	tipInf: TipInfEmpr;
	tsInfComplEmpr: Date;
	dtRefInfComplEmpr: Date;
	txInfComplEmpr: string;
	vlInfComplEmpr: number;
	dtInfComplEmpr: Date;
	dtPblccRef: Date;
}

export class InfComplEmprByCotc{
	infComplEmpr: InfComplEmpr;
	cotc: HstCotcAcao;
}

export class TipInfEmpr{
	sglTipInf: string;
	nmTipInf: string;
	txDescrTipInf: string;
}

export class InfComplEmprList {
  public vpa	: InfComplEmpr; //Valor patrimonial da ação
  public mc 	: InfComplEmpr; //Valor de mercado
  public ev 	: InfComplEmpr; //Enterprise Value
  public ebitda	: InfComplEmpr; //EBITDA
  public la 	: InfComplEmpr; //Lucro por ação
  public roa	: InfComplEmpr; //Retorno sobre o ativo total.    ROA = Lucro / AtivoTotal
  public roe	: InfComplEmpr; //Retorno sobre o patrimonio liq. ROE = Lucro / PatrLiq
}

import { Component, OnInit, ViewChild   } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { DdcListComponent } from '../ddc-list.component';

import { AplicationStateService } from 'src/app/_services/aplication-state/aplication-state.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { DdcService } from 'src/app/_services/ddc/ddc.service';
import { PdcService } from 'src/app/_services/pdc/pdc.service';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-ddc-list-mobile',
  templateUrl: '../desktop/ddc-list-desktop.component.html',
  styleUrls: ['./ddc-list-mobile.component.css']
})
export class DdcListMobileComponent extends DdcListComponent {
  constructor( protected router: Router                                ,
               protected formBuilder: FormBuilder                      ,
               protected dialog                : MatDialog             ,
               sanitizer: DomSanitizer                                 ,
               aplicationStateService          : AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected ddcService            : DdcService            ,
               protected pdcService            : PdcService            ,
               protected rstdEmprService       : RstdEmprService ) { 
    super( router, formBuilder, dialog, sanitizer, aplicationStateService, authenticationService, snackBar, ddcService, pdcService, rstdEmprService );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

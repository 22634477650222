import { Perdc } from '../perdc';
import { CrctInvAcao, CrctInv } from '../inv';

export class TipoDestaque{
	sglTipDtq    : string;
	nmTipDtq     : string;
	txDescrTipDtq: string;
}

export class Destaque{
	tipDtq     : TipoDestaque;
	perdc      : Perdc;
	dtRef      : Date;
	nrSeqDtq   : number;
	crctInv    : CrctInv;
	vlDtq 	   : number;
	dtIniPerd  : Date;
	dtFimPerd  : Date;
	txDescrObs : string;
}

export class DestaquesByDate{
	dtRef  : Date;
	dtqList: Destaque[];
}

export class DestaquesBySeq{
	nrSeqDtq: number;
	dtqList: Destaque[];
}

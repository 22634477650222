import { Component                          } from '@angular/core';
import { Router, ActivatedRoute             } from '@angular/router';
import { FormBuilder                        } from '@angular/forms';

import { LogoutComponent                    } from '../logout.component';

import { AuthenticationService              } from '../../../_services/authentication/authentication.service';
import { SessionService                     } from '../../../_services/session/session.service';

@Component({
  selector: 'app-logout-mobile',
  templateUrl: '../desktop/logout-desktop.component.html',
  styleUrls: ['./logout-mobile.component.css']
})
export class LogoutMobileComponent extends LogoutComponent {
    constructor( public formBuilder: FormBuilder,
                 public route: ActivatedRoute,
                 public router: Router,
                 public sessionService: SessionService,
                 public authenticationService: AuthenticationService ) { 
        super( formBuilder, route, router, sessionService, authenticationService );
    }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../_services/authentication/authentication.service';
import { SessionService } from 'src/app/_services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private router: Router, 
               private sessionService: SessionService ) {
    console.log( 'AuthGuard()...' ); 
    console.log( 'AuthGuard().' ); 
  }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log( 'canActivate()...' );

    let _isLoged = false;

    const currentUser = this.sessionService.currentUserValue;

    if (currentUser) {
      console.log( 'current user is loged.' );

      // logged in so return true
      _isLoged = true;
    }
    else{
      // not logged in so redirect to login page with the return url
      console.log( 'current user is\'nt loged.' );

      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }

    console.log( 'isLoged: ' + _isLoged );
    console.log( 'canActivate().' );

    return _isLoged;
  }
}

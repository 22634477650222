import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router                 } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { DatePipe } from '@angular/common';

import { ScriptLoaderService } from 'angular-google-charts';

import { TickerDetailComponent } from '../ticker-detail.component';

import { AplicationStateService   } from '@services/aplication-state/aplication-state.service';
import { AuthenticationService    } from '@services/authentication/authentication.service';
import { InvService               } from '@services/inv/inv.service';
import { CotcService              } from '@services/cotc/cotc.service';
import { DdcService               } from '@services/ddc/ddc.service';
import { IndTcnService            } from '@services/indTcn/indTcn.service';
import { RstdEmprService          } from '@services/rstdEmpr/rstdEmpr.service';
import { PrmUsrService            } from '@services/prmUsr/prmUsr.service';
import { PrvtService } from '@services/prvt/prvt.service';

@Component({
  selector: 'app-ticker-detail-mobile',
  templateUrl: '../desktop/ticker-detail-desktop.component.html',
  styleUrls: ['./ticker-detail-mobile.component.css']
})
export class TickerDetailMobileComponent extends TickerDetailComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected datepipe              : DatePipe              ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected prmUsrService         : PrmUsrService         ,
               protected snackBar              : MatSnackBar           ,
               protected invService            : InvService            ,
               protected cotcService           : CotcService           ,
               protected ddcService            : DdcService            ,
               protected indTcnService         : IndTcnService         ,
               protected rstdEmprService       : RstdEmprService       ,
               protected prvtService           : PrvtService           ,
               protected loaderService: ScriptLoaderService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, datepipe, aplicationStateService, authenticationService, prmUsrService, snackBar, invService, cotcService, ddcService, indTcnService, rstdEmprService, prvtService, loaderService );
  }
}

<h2 mat-dialog-title>Estratégias Ativas até o Momento</h2>

<form [formGroup]="this._form" (ngSubmit)="onSubmit()" >
	<div class="grid_container_estrtg_pnd">
		<div class="grid_item_estrtg_pnd_header">
			Quando o robô <u>sugere</u> a realização de uma ordem, 
			esse especifica qual o <i>ticker</i> alvo, 
			o tipo de ordem a ser realizada, a data que foi utilizada como base para a estratégia,
			o valor <u>sugerido</u> para a ordem, e os valores de stop de ganho e de perda.
		</div>
	
		<div class="grid_item_estrtg_pnd_filters_ettgst">
			<mat-form-field>
				<mat-label>Robô:</mat-label>
					<mat-select matNativeControl (selectionChange)="updateSglEttgstSelected( $event )" formControlName="sglEttgstSelected">
						<mat-option *ngFor="let ettgst of _ettgstList" [value]="ettgst.sglEttgst" >{{ ettgst.nmEttgst }} ({{ ettgst.estrtgPend }})</mat-option>
					</mat-select>
			</mat-form-field>
		</div>
	
		<div class="grid_item_estrtg_pnd_itens_progress">
			<ng-container *ngIf="this._controlEttgstList.isShowProgressBar;else itemTableRefresh">
				<mat-progress-bar mode="this._controlEttgstList.modeProgressBar" value="this._controlEttgstList.valueProgressBar"></mat-progress-bar>
			</ng-container>
			<ng-template #itemTableRefresh>
				<button mat-icon-button color="accent" (click)="this.onRefreshEstrtgList()">
					<mat-icon matTooltip="Atualizar lista de ordens.">refresh</mat-icon>
				</button>
			</ng-template>
		</div>

		<div class="grid_item_estrtg_pnd_estrtgList">
			<table mat-table [dataSource]="dataSourceEstrtgList" #estrtgListSort="matSort" matSort>
				<ng-container matColumnDef="nmInv" sticky>
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Ticker </th>
					<td mat-cell *matCellDef="let element">
						<a [routerLink]="" routerLinkActive="active" (click)="detailTicker( element.cdInv )" class="linkInTable"> 
							{{element.nmInv}}
						</a> 
					</td>
				</ng-container>
	
				<ng-container matColumnDef="sglTipRsltdOrdem">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo<br>de<br>Ordem </th>
					<td mat-cell *matCellDef="let element"> {{element.tipOrdem.nmTipOrdem}} </td>
				</ng-container>
	
				<ng-container matColumnDef="dtRefEstrtg">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Data de<br>Ref. </th>
					<td mat-cell *matCellDef="let element"> {{element.dtRefEstrtg | date:'dd/MM/yyyy':'+0000'}} </td>
				</ng-container>
	
				<ng-container matColumnDef="vlStart">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Start<br>(R$) </th>
					<td mat-cell *matCellDef="let element"> {{element.vlStart | number:'1.2-2'}} </td>
				</ng-container>
	
				<ng-container matColumnDef="vlStopPerda">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Stop<br>Perda<br>(R$) </th>
					<td mat-cell *matCellDef="let element"> {{element.vlStopPerda | number:'1.2-2'}} </td>
				</ng-container>
	
				<ng-container matColumnDef="vlStopGanho">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Stop<br>Ganho<br>(R$) </th>
					<td mat-cell *matCellDef="let element"> {{element.vlStopGanho | number:'1.2-2'}} </td>
				</ng-container>
	
				<ng-container matColumnDef="sglEttgst">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Robô </th>
					<td mat-cell *matCellDef="let element"> {{element.sglEttgst}} </td>
				</ng-container>
	
				<ng-container matColumnDef="options">
			      <th mat-header-cell *matHeaderCellDef></th>
	      			<td mat-cell *matCellDef="let element">
						<button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
	                		<mat-icon>more_vert</mat-icon>
	            		</button>
						<mat-menu #appMenu="matMenu">
							<button mat-menu-item (click)="detailEstrtg( element.dtRefEstrtg, element.cdInv, element.sglEttgst )" type="submit">Detalhar Ordem</button>
							<button mat-menu-item (click)="detailTicker( element.cdInv )" type="submit">Detalhar Ticker</button>
						</mat-menu>
	      			</td>
	    		</ng-container>
	
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
	
			<mat-paginator [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
		</div>
	
		<div *ngIf="isEstrtgListEmpty" class="grid_item_estrtg_pnd_estrtgList_empty">
			<label class="label_list_empty">Não há nenhuma estratégia ativa no momento.</label>
		</div>
	
		<div class="grid_item_estrtg_pnd_buttons">
			<div class="links">
				<button mat-stroked-button class="link" (click)="listEttgst()" type="submit">Ver Estatística dos Robôs</button>
			</div>
		</div>
	</div>
</form>
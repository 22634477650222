import { Injectable                   } from '@angular/core';
import { HttpClient, HttpHeaders      } from '@angular/common/http';

import { User                         } from '../../_models/user';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService               } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpBasicService< any, any >{
  constructor( protected http          : HttpClient    ,
               protected sessionService: SessionService ) {
    super( http, sessionService );
  }

  public getSessionService(){
    return this.sessionService;
  }

  logout() {
    if( this.sessionService.currentUserValue != null && this.sessionService.currentUserValue.id != null && this.sessionService.currentUserValue.token != null ){
      //Obtem os dados da sessao.
      const objData = { 'cd_usr': this.sessionService.currentUserValue.id   ,
                        'token' : this.sessionService.currentUserValue.token  };

      // remove user from local storage to log user out
      localStorage.removeItem( 'currentUser' );
      this.sessionService.currentUserSubject.next(null);
      this.sessionService.clearPrmUsr();

      //Executa servico para registrar o logout no servidor.
      super.reqGetJson( '/AUTH/LOGOUT', objData );
    }
  }

  /**
   * Realiza o login do usuário.
   * @param userStr
   * @param passStr
   */
  public login( userStr: string, passStr: string ){
    var user = new User();

    const objData = { cd_usr: userStr,
                      password: passStr };

    var pLoginJson = super.reqPostJson( '/AUTH/LOGIN', objData );

    var _this = this;
    return new Promise( function( resolve, reject ) {
      pLoginJson.then( data => {
          const codRet = data[ 'codRet' ];
          const msgRet = data[ 'msgRet' ];

          if( codRet === 200 ){
            user.id        = data[ 'id' ];
            user.username  = data[ 'userName' ];
            user.name      = data[ 'name' ];
            user.token     = data[ 'token' ];
            user.email     = data[ 'email' ];

            localStorage.setItem('currentUser', JSON.stringify(user));
            _this.sessionService.currentUserSubject.next(user);

            resolve( user );
          }
          else{
            reject( codRet + ' - ' + msgRet );
          }
        });
    });
  }

  /**
   * Altera a senha do usuario
   * @param userStr
   * @param passActualStr
   */
  public changePassword( cdUsrNumber: number, passActualStr: string, passNewStr: string ){
    let user = new User();

    const objData = { cd_usr: cdUsrNumber,
                      pass_old: passActualStr,
                      pass_new: passNewStr };

    var pLoginJson = super.reqPostJson( '/AUTH/CHANGE_PASS', objData );

    var _this = this;
    return new Promise( function( resolve, reject ) {
      pLoginJson.then( data => {
          const codRet = data[ 'codRet' ];
          const msgRet = data[ 'msgRet' ];

          if( codRet === 200 ){
            resolve( true );
          }
          else{
            reject( codRet + ' - ' + msgRet );
          }
        });
    });
  }
}

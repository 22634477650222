import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateSimpleService{
  private constructor() {
  }

  /**
   * Retorna o maior dia util que seja menor ou igual a data de referencia informada.
   * Baseado nas soluções: 
   *    - https://stackoverflow.com/a/36609165
   *    - https://www.tutorialspoint.com/How-to-subtract-days-from-a-date-in-JavaScript
   */
  public getLastBusinessDay( dtRef: Date ): Date {
    let workday: Date = dtRef;

    let day = workday.getDay();
    let diff = 0;

    switch( day ){
      //Domingo
      case 0:
        diff = 2;
        break;

      //Sabado
      case 6:
        diff = 1;
        break;

      //Demais dias da semana
      default:
        diff = 0;
        break;
    }

    return new Date( workday.setDate( workday.getDate() - diff ) );
  }
}
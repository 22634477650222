import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRoute, Router                 } from '@angular/router';
import { FormBuilder                    } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AplicationStateService } from '../../../../_services/aplication-state/aplication-state.service';
import { AuthenticationService } from '../../../../_services/authentication/authentication.service';
import { EmprService } from 'src/app/_services/empr/empr.service';
import { SetorDetailComponent } from '../setor-detail.component';
import { SetorService } from 'src/app/_services/setor/setor.service';
import { SetorAtvddService } from 'src/app/_services/setor-atvdd/setor-atvdd.service';
import { InvService } from 'src/app/_services/inv/inv.service';
import { CotcService } from 'src/app/_services/cotc/cotc.service';
import { IceService } from 'src/app/_services/ice/ice.service';
import { IndTcnService } from 'src/app/_services/indTcn/indTcn.service';
import { PdcService } from 'src/app/_services/pdc/pdc.service';
import { RstdEmprService } from 'src/app/_services/rstdEmpr/rstdEmpr.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-setor-detail-desktop',
  templateUrl: './setor-detail-desktop.component.html',
  styleUrls: ['./setor-detail-desktop.component.css']
})
export class SetorDetailDesktopComponent extends SetorDetailComponent {
  constructor( protected router                : Router                ,
               protected activatedRoute        : ActivatedRoute        ,
               protected sanitizer             : DomSanitizer          ,
               protected formBuilder           : FormBuilder           ,
               protected dialog                : MatDialog             ,
               protected aplicationStateService: AplicationStateService,
               protected authenticationService : AuthenticationService ,
               protected snackBar              : MatSnackBar           ,
               protected setorService          : SetorService          ,
               protected setorAtvddService     : SetorAtvddService     ,
               protected emprService           : EmprService,
               protected invService            : InvService,
               protected cotcService           : CotcService,
               protected iceService            : IceService,
               protected indTcnService         : IndTcnService        ,
               protected rstdEmprService       : RstdEmprService ) {
    super( router, activatedRoute, sanitizer, formBuilder, dialog, aplicationStateService, authenticationService, snackBar, setorService, setorAtvddService, emprService, invService, cotcService, iceService, indTcnService, rstdEmprService );
  }
}

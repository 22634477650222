<!-- ================================= CONTENT ================================= -->

<form [formGroup]="this._tickerForm">
	<mat-dialog-content>
		<div class="grid_container_ticker_dialog_select">
			<div class="grid_item_ticker_dialog_select_title">
				<h2 mat-dialog-title>Selecionar Ativo</h2>
			</div>

			<div class="grid_item_ticker_dialog_select_buttonClose">
				<button mat-icon-button color="accent" (click)="this.onClickCancelDialog()">
					<mat-icon class="material-icons-round" matTooltip="Fecha a janela, cancelando a seleção de ativos.">close</mat-icon>
				</button>
			</div>

			<div class="grid_item_ticker_dialog_select_filters_tipInv">
				<mat-form-field>
					<mat-label>Tipo de Investimento:</mat-label>
						<mat-select matNativeControl (selectionChange)="onChangeUpdateCdTipInvSelected( $event )" formControlName="cdTipInvSelected">
							<mat-option *ngFor="let tipInv of tipInvList" [value]="tipInv.cdTipInv" >{{ tipInv.nmTipInv }}</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_ticker_dialog_select_filters_espc">
				<mat-form-field>
					<mat-label>Espécie:</mat-label>
						<mat-select matNativeControl (selectionChange)="onChangeUpdateCdEspcSelected( $event )" formControlName="cdEspcSelected">
							<mat-option *ngFor="let tipEspc of tipEspcList" [value]="tipEspc.cdEspcAcao" >{{ tipEspc.nmEspcAcao }}</mat-option>
						</mat-select>
				</mat-form-field>
			</div>

			<div class="grid_item_ticker_dialog_select_filters_nmInv">
				<mat-form-field>
					<mat-label>Filtrar Lista de Ativos:</mat-label>

					<input matInput type="text" (keyup)="applyFilterNmTicker( $event )" placeholder="petr4, banco, índice, materiais..." formControlName="nmTicker" >
				</mat-form-field>
			</div>

			<div class="grid_item_ticker_dialog_select_filters_checkIsOnlyMonitored">
				<mat-checkbox (change)="updateOnlyEmprMonitored()" formControlName="isOnlyEmprMonitored" >
					Mostrar apenas ativos monitorados
				</mat-checkbox>
			</div>

			<!-- Lista de tickers -->
			<div class="grid_item_ticker_dialog_select_invList">
				<table mat-table [dataSource]="_invListDataSource">
					<ng-container matColumnDef="select" sticky>
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<mat-checkbox (change)='onChangeClickedTicker( $event, element )'></mat-checkbox>
						</td>
					</ng-container>

					<ng-container matColumnDef="inv" sticky>
						<th mat-header-cell *matHeaderCellDef> Ticker </th>
						<td mat-cell *matCellDef="let element"> {{element.nmInv}} </td>
					</ng-container>

					<ng-container matColumnDef="tipInv">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.tipInv.nmTipInv}} </td>
					</ng-container>

					<ng-container matColumnDef="empr">
						<th mat-header-cell *matHeaderCellDef> Empresa </th>
						<td mat-cell *matCellDef="let element">
							<ng-container *ngIf="element.empr.nmAbrvEmpr;else nmEmprTemplate">
								{{element.empr.nmAbrvEmpr}}
							</ng-container>
							<ng-template #nmEmprTemplate>
								{{element.empr.nmEmpr}}
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="setor">
						<th mat-header-cell *matHeaderCellDef> Setor </th>
						<td mat-cell *matCellDef="let element"> {{element.empr.segmEcnm.subSetorEcnm.setorEcnm.nmSetorEcnm}} </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="this._invListDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: this._invListDisplayedColumns;"></tr>
				</table>

				<mat-paginator #invListPaginator [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
			</div>

			<!-- Lista de tickers marcados para serem retornados -->
			<div class="grid_item_ticker_dialog_select_invListSelecteds">
				<div>Ativos Selecionados:</div>
				<table mat-table [dataSource]="this._invListSelectedsDataSource">
					<ng-container matColumnDef="tickerSelected">
						<th mat-header-cell *matHeaderCellDef> Ticker </th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button color="accent" (click)="removeTickerSelected( element )" aria-label="Desmarcar ativo selecionado">
								<mat-icon>delete</mat-icon>
							</button>
							{{element.nmInv}} 
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="this._invListSelectedsDisplayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: this._invListSelectedsDisplayedColumns;"></tr>
				</table>

				<mat-paginator class="paginator-range-label-hidden" #invListSelectedsPaginator [pageSizeOptions]="[10]" [hidePageSize]="true" showFirstLastButtons ></mat-paginator> 
			</div>

			<div class="grid_item_ticker_dialog_select_button">
				<button mat-icon-button color="accent" (click)="onRetryTickersSelecteds()" aria-label="Retornar ativos selecionados"><mat-icon>reply</mat-icon></button>
			</div>
		</div>
	</mat-dialog-content>
</form>


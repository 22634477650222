import { Injectable                   } from '@angular/core';
import { HttpClient                   } from '@angular/common/http';

import { Estrtg } from 'src/app/_models/estrtg';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { Ettgst } from 'src/app/_models/ettgst';


@Injectable({
  providedIn: 'root'
})
export class EttgstService extends HttpBasicService< Ettgst, Ettgst >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService ) {
    super( http, sessionService );

    console.log( 'EttgstService()...' );

    console.log( 'EttgstService().' );
  }

  /**
   * Lista estrategistas.
   */
  list(): Promise< Ettgst[] >{
    const objData = {};

    //Executa servico.
    const respJsonGen = super.reqGetJsonGeneric( '/ETTGST/LIST', objData );

    let _this = this;

    let objList = new Array< Ettgst >();

    return new Promise< Ettgst[] >( function( resolve, reject ) {
      respJsonGen.then( rag => {
        let dataResp = rag.dataResp;

        for( const dR of (dataResp as any) ){
          objList.push( dR );
        }

        resolve( objList );
      });
    });
  }

  /**
   * Detalha um estrategista.
   */
  detail( sglEttgst: string ) : Promise< Estrtg > {
    console.log( 'EttgstService.detail()...' );

    const reqData = { 'sgl_ettgst': sglEttgst };

    //Executa servico.
    const respJson = super.reqGetJsonGeneric( '/ETTGST/DET', reqData );

    let _this = this;

    return new Promise< Estrtg >( function( resolve, reject ) {
      respJson.then( dataResp => {
        let d = new Estrtg();

        d = dataResp[ 'dataResp' ];

        resolve( d );
      });
    });
  }
}
